import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const TrashCan: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4725 3.99994H21C21.5523 3.99994 22 4.44765 22 4.99994C22 5.55222 21.5523 5.99994 21 5.99994H19.9079L18.9114 16.2975C18.8404 17.0304 18.7817 17.6377 18.6945 18.1318C18.6037 18.6468 18.4703 19.1162 18.2097 19.5523C17.8033 20.2321 17.2042 20.776 16.4884 21.115C16.0293 21.3324 15.5492 21.42 15.0279 21.4608C14.5276 21.5 13.9176 21.5 13.1812 21.4999H8.81892C8.08255 21.5 7.47235 21.5 6.97215 21.4608C6.4508 21.42 5.97072 21.3324 5.51163 21.115C4.79579 20.776 4.19672 20.2321 3.79033 19.5523C3.5297 19.1162 3.39632 18.6468 3.30548 18.1318C3.21832 17.6377 3.15956 17.0304 3.08863 16.2974L2.0921 5.99994H1C0.447715 5.99994 0 5.55222 0 4.99994C0 4.44765 0.447715 3.99994 1 3.99994H6.52747C6.77622 1.74999 8.68374 0 11 0C13.3163 0 15.2238 1.74999 15.4725 3.99994ZM7.48884 5.99994H4.10145L5.07558 16.066C5.15121 16.8475 5.20312 17.3765 5.27507 17.7844C5.34487 18.1801 5.42158 18.3832 5.50701 18.5261C5.71021 18.866 6.00974 19.138 6.36766 19.3075C6.51814 19.3787 6.72763 19.4355 7.12823 19.4669C7.54116 19.4992 8.07268 19.4999 8.85791 19.4999H13.1421C13.9273 19.4999 14.4588 19.4992 14.8718 19.4669C15.2724 19.4355 15.4819 19.3787 15.6323 19.3075C15.9903 19.138 16.2898 18.866 16.493 18.5261C16.5784 18.3832 16.6551 18.1801 16.7249 17.7844C16.7969 17.3765 16.8488 16.8475 16.9244 16.066L17.8986 5.99994H14.5112C14.5074 5.99998 14.5037 6 14.5 6H7.5C7.49627 6 7.49255 5.99998 7.48884 5.99994ZM13.45 3.99994C13.2183 2.85885 12.2095 2 11 2C9.79054 2 8.78168 2.85885 8.55002 3.99994H13.45Z"
        fill="white"
      />
    </svg>
  );
};

export default TrashCan;
