import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Email: FC<ISVG> = ({
  width = 16,
  height = 16,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M1.5 5L8.5 9L15.5 5M2.5 2H14.5C15.0523 2 15.5 2.44772 15.5 3V13C15.5 13.5523 15.0523 14 14.5 14H2.5C1.94772 14 1.5 13.5523 1.5 13V3C1.5 2.44772 1.94772 2 2.5 2Z"
      stroke={color}
      strokeOpacity="1"
      strokeWidth="1"
    />
  </svg>
);

export default Email;
