import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Avatar: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.749 2.72048C24.1398 2.869 27.4119 4.01205 30.158 6.00726C32.9041 8.00248 35.0027 10.7617 36.1926 13.9413L38.7829 13.0984C37.4148 9.372 34.9696 6.13682 31.7583 3.80439C28.547 1.47196 24.7148 0.147682 20.749 0V2.72048Z"
      fill="#FFA519"
    />
    <path
      d="M36.6517 15.3638C37.0695 16.87 37.2825 18.4256 37.2848 19.9886C37.2873 22.5904 36.7015 25.1591 35.5712 27.5023C34.4409 29.8455 32.7954 31.9027 30.7578 33.5197L32.3539 35.7228C34.7387 33.8528 36.6665 31.4642 37.9913 28.738C39.3161 26.0119 40.003 23.0198 39.9999 19.9886C40.0007 18.141 39.7441 16.3022 39.2375 14.5254L36.6517 15.3638Z"
      fill="#FFA519"
    />
    <path
      d="M3.80708 13.9413C4.99697 10.7617 7.09563 8.00248 9.8417 6.00726C12.5878 4.01205 15.8599 2.869 19.2507 2.72048V0C15.2852 0.148858 11.4536 1.47359 8.24249 3.80586C5.03143 6.13813 2.58596 9.37266 1.2168 13.0984L3.80708 13.9413Z"
      fill="#D8DFE1"
    />
    <path
      d="M29.5405 34.4023C26.7134 36.2808 23.3937 37.2802 19.9997 37.2745C16.6058 37.2802 13.2861 36.2808 10.4589 34.4023L8.8584 36.6055C12.1522 38.8234 16.0337 40.0053 20.0042 39.9994C23.9744 40.0037 27.8555 38.8219 31.15 36.6055L29.5405 34.4023Z"
      fill="#D8DFE1"
    />
    <path
      d="M9.24654 33.5197C7.20898 31.9027 5.56346 29.8455 4.43315 27.5023C3.30284 25.1591 2.71704 22.5904 2.71958 19.9886C2.72046 18.4258 2.93191 16.8702 3.3482 15.3638L0.762375 14.5254C0.255769 16.3022 -0.000819497 18.141 6.96151e-06 19.9886C-0.00252391 23.0202 0.685053 26.0125 2.0106 28.7387C3.33615 31.4648 5.26487 33.8533 7.65047 35.7228L9.24654 33.5197Z"
      fill="#D8DFE1"
    />
  </svg>
);

export default Avatar;
