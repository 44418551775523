import React, { FC } from "react";
import {
  StyledHead,
  StyledHeadCell,
  StyledRow,
  StyledHeadIcon,
} from "components/organisms/AbraTable/table";
import Icon from "components/atoms/Icon";
import Checkbox from "components/atoms/Checkbox";
import { ITableHead } from "components/organisms/AbraTable/interfaces";
import { TableSortDirection } from "@types/enums/Table.enum";
import classNames from "classnames";
import { palette } from "lib/theme";
import styled from "styled-components";
import { Text } from "components/atoms/Typography";
import Loading from "components/atoms/Loading";
import { SpinnerSizeEnum } from "@types/enums/Loading.enum";

const Head: FC<ITableHead> = ({
  columns,
  sortSettings,
  isAllDataSelected = false,
  enableSelect,
  onSelectAll,
  onSortColumn,
  rowSidePadding,
  smallCorners,
  isSorting = false,
}) => {
  const getSortIcon = (accessor: string) => {
    const accessorStatus = sortSettings?.find(
      (setting) => setting.accessor === accessor
    )?.status;
    switch (accessorStatus) {
      case TableSortDirection.UNSORTED:
        return "Sort";
      case TableSortDirection.ASCENDING:
        return "SortUp";
      case TableSortDirection.DESCENDING:
        return "SortDown";
      default:
        return "";
    }
  };

  return (
    <StyledHead>
      <StyledRow height="58px">
        <StyledHeadCell
          style={{
            boxShadow: `0px -1px inset ${palette.background.elevation1}`,
            width: `${rowSidePadding ? rowSidePadding : "24px"}`,
          }}
          // <StyledCell width={rowSidePadding ? rowSidePadding : "24px"} />
        />
        {enableSelect && (
          <StyledHeadCell key="table-head-sort">
            <Checkbox
              value={isAllDataSelected.toString()}
              checked={isAllDataSelected}
              onChange={onSelectAll}
            />
          </StyledHeadCell>
        )}
        {columns.map(
          (
            {
              title,
              icon,
              accessor,
              enableSort,
              sortDirection,
              className,
              alignment,
              width,
              wrapText,
              style,
              hideOnBreakpoints,
            },
            indx
          ) => (
            <StyledHeadCell
              data-cy={`table_head_${title}_${indx}`
                .toLowerCase()
                .replace(/ /g, "_")}
              key={`table-head-${indx}`}
              className={classNames({
                [className]: className,
              })}
              alignment={alignment}
              onClick={() =>
                enableSort && !isSorting && onSortColumn(accessor, indx)
              }
              enableSort={enableSort && !isSorting}
              wrapText={wrapText}
              style={style}
              hideOnBreakpoints={hideOnBreakpoints}
            >
              <Container>
                {icon && (
                  <StyledHeadIcon>
                    <Icon
                      name={icon}
                      size={14}
                      color={palette.darkBackgroundContrast.light70}
                      iconProps={{
                        style: { position: "relative", top: "2px" },
                      }}
                    />
                  </StyledHeadIcon>
                )}
                <Text
                  size="tiny"
                  color={palette.darkBackgroundContrast.light70}
                >
                  {title}
                </Text>
                {enableSort && (
                  <StyledHeadIcon>
                    <Icon
                      name={getSortIcon(accessor)}
                      size={12}
                      color={palette.darkBackgroundContrast.light70}
                      iconProps={{
                        style: { position: "relative", top: "3px" },
                        className: "sortIcon",
                      }}
                    ></Icon>
                  </StyledHeadIcon>
                )}
                {enableSort &&
                  isSorting &&
                  getSortIcon(accessor) !== "Sort" && (
                    <Loading
                      showText={false}
                      size={SpinnerSizeEnum.SORTING}
                      showRoundSpinner
                    />
                  )}
              </Container>
            </StyledHeadCell>
          )
        )}
        <StyledHeadCell
          cornerCell={true}
          style={{
            boxShadow: `0px -1px inset ${palette.background.elevation1}`,
            width: `${rowSidePadding ? rowSidePadding : "24px"}`,
          }}
        />
      </StyledRow>
    </StyledHead>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 6px;

  width: 100%;
`;

export default Head;
