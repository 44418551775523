import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {}

const RegisterUserRegistered: FC<Props> = () => {
  return (
    <RegisterUserRegisteredBox>
      <p>We just sent you a verification email. </p>
      <p>Please click on the link enclosed in the email to continue to the mandatory identity verification step.</p>
    </RegisterUserRegisteredBox>
  );
};

const RegisterUserRegisteredBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export default RegisterUserRegistered;
