import React from 'react'
import { PendingTransaction } from 'state/store/custodyApi'
import styled from 'styled-components'
import useAssets from 'shared/useAssets'
import { CustodyMainText, CustodyStyledTable } from '../CustodyStyles'
import { formatDate } from 'shared'
import Loading from 'components/atoms/Loading'
import { Text } from 'components/atoms/Typography';
import useLoan from '../loan/useLoan'

const LoanDetailsPendingActivity = ({loanTransactions}) => {

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      width: "100%"
    }}>
      <div style={{
        height: "1.5rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "24px",
      }}>
        <Text size='large' bold letterSpacing='-0.5'>
          Pending Activity
        </Text>
      </div>
      {loanTransactions.isLoading && <Loading showText={false} />}
      {!loanTransactions.isLoading && loanTransactions.data?.length! === 0 && 
        <CenterText>No data to display</CenterText>
      }
      {!loanTransactions.isLoading && loanTransactions.data?.length! > 0 && (
        <LoanStyledTable>
          <thead>
            <tr>
              <th>Date</th>
              <th>Transaction Id</th>
              <th>Network</th>
              <th>Quantity</th>
              <th>Action</th>
              <th>Status</th>
              <th>Strategy</th>
            </tr>
          </thead>
          <tbody>
            {loanTransactions.data?.map((record: PendingTransaction, index: number) => 
              <TokenSummaryRow 
                key={`${record.abraTxUID}-${index}`} 
                record={record} 
              />
            )}
          </tbody>
        </LoanStyledTable>
      )}
    </div>
  )
}

interface TokenSummaryRowProps {
  record: PendingTransaction,
}
const TokenSummaryRow: React.FC<TokenSummaryRowProps> = ({record}) => {
  
  const {getPriceFormattedI} = useAssets()

  return (
    <tr>
      <td>
        <CustodyMainText>
          {formatDate(record.dateTime)}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {record.abraTxUID}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {record.networkName}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {getPriceFormattedI(record.amount.asset, record.amount.amount)}
        </CustodyMainText>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText>{record.actionName}</CustodyMainText>
        </TextGroup>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText>
            {record.status}
          </CustodyMainText>
        </TextGroup>
      </td>
      <td style={{
        overflow: 'hidden'
      }}>
        <TextGroup> 
          <CustodyMainText>
            {record.strategyName}
          </CustodyMainText>
        </TextGroup>
      </td>
    </tr>
  )
}


const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const CenterText = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px;
`;

const LoanStyledTable = styled(CustodyStyledTable)`
  table-layout: fixed;
`;

export default LoanDetailsPendingActivity
