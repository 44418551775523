import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Search: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.74992 15.9166C12.4318 15.9166 15.4166 12.9319 15.4166 9.24998C15.4166 5.56808 12.4318 2.58331 8.74992 2.58331C5.06802 2.58331 2.08325 5.56808 2.08325 9.24998C2.08325 12.9319 5.06802 15.9166 8.74992 15.9166Z"
      stroke={color}
      strokeOpacity="0.7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1083 11.6083C10.7985 11.9178 10.4308 12.1632 10.0262 12.3305C9.62152 12.4978 9.18787 12.5837 8.75 12.5833"
      stroke={color}
      strokeOpacity="0.7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.41675 9.25002C5.41675 8.36597 5.76794 7.51812 6.39306 6.893C7.01818 6.26788 7.86603 5.91669 8.75008 5.91669"
      stroke={color}
      strokeOpacity="0.7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8333 16.3333L18.3333 18.8333"
      stroke={color}
      strokeOpacity="0.7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Search;
