import React, { useRef, useState } from 'react';
import { Heading, Text } from 'components/atoms/Typography';
import { palette } from 'lib/theme';
import { SpinnerSizeEnum } from '@types/enums/Loading.enum';
import { toast, ToastType } from 'components/organisms/Toast';
import { TypesNamesEnum, SizeNamesEnum } from 'enums/Button.enum';
import { useSendTransactionReportByEmailMutation } from 'state/store/api';
import Button from 'components/atoms/Button';
import Checkbox from 'components/atoms/Checkbox';
import CustomDatePicker from 'components/organisms/DatePicker';
import Icon from 'components/atoms/Icon';
import Loading from 'components/atoms/Loading';
import moment from 'moment';
import Panel from 'components/atoms/Panel';
import styled from 'styled-components';

const ExportPanel = ({ onClose, reportType }: { onClose: Function; reportType: string }) => {
  const [startDateInput, setStartDateInput] = useState(null);
  const [endDateInput, setEndDateInput] = useState(null);
  const [legalCheck, setLegalCheck] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [sendReport, { isLoading }] = useSendTransactionReportByEmailMutation();

  const handleSendReport = async () => {
    try {
      const dateFrom = moment(startDateInput).format('DD-MM-YYYY').toString();
      const dateTo = moment(endDateInput).format('DD-MM-YYYY').toString();
      await sendReport({
        type: reportType,
        datesReportRequest: { dateFrom, dateTo },
      }).unwrap();

      setShowSuccessModal(true);
    } catch (error) {
      toast.show({
        type: ToastType.Fail,
        title: 'Export data',
        content: error?.response?.data?.message || "Your request hasn't been execute, try again",
      });
    }
  };

  const handleClose = () => {
    setShowSuccessModal(false);
    onClose && onClose();
  };
  const DateSelectedBody = (
    <Container>
      <Text size='large' bold>
        Export transaction history
      </Text>
      <div style={{ height: '385px' }}>
        <CustomDatePicker
          onSelectedDates={({ startDate, endDate }) => {
            startDate && setStartDateInput(startDate);
            endDate && setEndDateInput(endDate);
          }}
        />
      </div>
      <RowCheckbox>
        <Checkbox dontUseDefaultMargin checked={legalCheck} name='checked' text='' onChange={(e) => setLegalCheck((_check) => !_check)} />
        <Text size='small' color={palette.darkBackgroundContrast.light70}>
          By using this feature, you acknowledge that these reports do not constitute tax or legal advice
        </Text>
      </RowCheckbox>
      <Button
        buttonType={TypesNamesEnum.ACCENT}
        size={SizeNamesEnum.EXTRA_LARGE}
        width='327'
        label={isLoading ? <Loading showText={false} size={SpinnerSizeEnum.INPUT} showRoundSpinner /> : 'Export'}
        onClick={() => handleSendReport()}
        disabled={!startDateInput || !endDateInput || !legalCheck || isLoading}
      ></Button>
    </Container>
  );

  const SuccessBody = (
    <Container gap='110px'>
      <Text size='large' bold>
        Export transaction history
      </Text>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <Icon name='Success' size={100} color='#17B96B' />
        <Info variant='h3'>Your report is on the way!</Info>
        <SubTitle>Check your spam folder or contact support@abra.com if you have not received your report within 30 minutes</SubTitle>
      </div>
      <Button
        buttonType={TypesNamesEnum.ACCENT}
        size={SizeNamesEnum.EXTRA_LARGE}
        width='327'
        label='Done'
        onClick={() => handleClose()}
        disabled={false}
      ></Button>
    </Container>
  );
  return (
    <Panel width='375px' padding='20px 24px 24px 24px' height='600px' hasBorder>
      {showSuccessModal ? SuccessBody : DateSelectedBody}
    </Panel>
  );
};

const Container = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '24px'};
  justify-content: center;
  align-items: center;
`;
const RowCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const Info = styled(Heading)`
  text-align: center;
  padding-top: 12px;
`;

const SubTitle = styled(Text)`
  text-align: center;
  color: ${palette.white.light70};
`;
export default ExportPanel;
