import React, { VFC, useState, useEffect } from "react"
import { Text } from "../../atoms/Typography"
import Button from "../../atoms/Button"
import Link from "../../atoms/Link"
import DigitVerification from "../../atoms/DigitVerification"
import {
  SizeNamesEnum,
  TypesNamesEnum,
} from "../../../@types/enums/Button.enum"
import { getText } from "shared/locale-helper"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { PagesUrl } from "lib/constants/config.constant"
import { RootState, useTypedSelector } from "state/store"
import SignInBlockedModal from "../SignIn/SignInBlockedModal"
import { useValidateMfaMutation } from "state/store/api"
import { selectTokenToValidate, setCredentials } from "state/slice/auth.slice"
import { toast, ToastType } from "components/organisms/Toast"

const TwoFactorVerification: VFC = ({ }) => {
  const [validateCode, { isLoading }] = useValidateMfaMutation()
  const { accessToken } = useSelector(selectTokenToValidate)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const [code, setCode] = useState<string[]>([])
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if(!accessToken) {
      navigate(PagesUrl.AUTHENTICATION)
    }
  }, [accessToken])

  const errorsDetails = {
    tooManyAtemps: {
      icon: "SignInBlocked",
      title: getText("sign_in_suspended"),
      details: getText("wrong_2fa_code"),
      confirmText: getText("contact_customer_support_capital_s"),
      onConfirm: () => { },
      onClose: () => {
        navigate(`${PagesUrl.AUTHENTICATION}`)
        // dispatch(clearPartial({}));
      },
    },
    tooManyAtempsSignUp: {
      icon: "SignInBlocked",
      title: getText("access_suspended"),
      details: getText("wrong_2fa_code_signup"),
      confirmText: getText("got_it"),
      onConfirm: () => { },
    },
    blockedSignUp: {
      icon: "SignInBlocked",
      title: getText("access_suspended"),
      details: getText("wrong_2fa_code_signup_too_many_times"),
      confirmText: getText("contact_customer_support_capital_s"),
      onConfirm: () => { },
    },
    blockSignIn: {
      icon: "SignInBlockedWhite",
      title: getText("confirm_sign_in_block"),
      details: getText("confirm_sign_in_block_details"),
      confirmText: getText("confirm_sign_in_block_confirm"),
      onConfirm: () => handleBlockSignIn(),
      cancelText: getText("go_back"),
      onCancel: () => setVisible(false),
    },
    blockSignInSuccess: {
      icon: "SignInBlocked",
      title: getText("access_suspended"),
      details: getText("restore_access"),
      confirmText: getText("contact_customer_support_capital_s"),
      onConfirm: () => { },
      onClose: () => {
        navigate(`${PagesUrl.AUTHENTICATION}`)
        // dispatch(clearPartial({}));
      },
    },
    default: {
      icon: "SignInBlocked",
      title: "",
      details: "",
      confirmText: "",
      onConfirm: () => { },
    },
  }

  const [errorModal, setErrorModal] = useState<
    {
      icon: string
      title: string
      details: string
      confirmText: string
      onConfirm: () => void
      cancelText?: string
      onCancel?: () => void
      onClose?: () => void
    }

  >(errorsDetails.default)

  const handleOnSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    try {
      if (!e) return
      e.preventDefault()

      if (errorMessage) return

      const data = (await validateCode({
        mfaAuthenticationRequest: {
          mfaToken: accessToken,
          otpCode: code.join(""),
        },
      }).unwrap()) as { accessToken: string }
      dispatch(setCredentials({ accessToken: data.accessToken }))
      navigate(PagesUrl.CUSTODY)
    } catch (error) {
      setCode([])
      toast.show({
        title: "An error has ocurred",
        content: "Incorrect 2FA code. Please enter a valid code",
        type: ToastType.Fail,
      })
    }
  }

  const handleOnChange = (event: any) => {
    let updatedValue = [...code]
    for(const value of event?.target?.value) {
      updatedValue = [...updatedValue, value]
    }
    setCode(updatedValue.slice(0, 6))
  }

  const handleOnKeyDown = (event: any, indx: number) => {
    setErrorMessage(undefined)
    if (event.key === "Backspace") {
      if (code[indx]) {
        const updatedCurrentValue = code.slice(0, indx)
        setCode(updatedCurrentValue)
        return
      }
      const updatedValue = code.slice(0, indx - 1)
      setCode(updatedValue)
    }
  }

  const handleShowModalBlockSignIn = () => {
    setErrorModal(errorsDetails.blockSignIn)
    setVisible(true)
  }

  const handleBlockSignIn = () => { }

  return (
    <React.Fragment>
      <form onSubmit={async (e) => await handleOnSubmit(e)}>
        <div style={{ marginBottom: "36px" }}>
          <DigitVerification
            code={code}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            errorMessage={errorMessage}
          />
        </div>
        <div style={{ marginBottom: "16px" }}>
          <Button
            buttonType={TypesNamesEnum.ACCENT}
            height='40'
            width='100%'
            onClick={async (e) => await handleOnSubmit(e)}
            disabled={code?.length < 6}
            isLoading={isLoading}
            type="submit"
          >
            Confirm
          </Button>
        </div>
      </form>
      <Text size="small" >
        <Link
          label={getText("verify_identity_dont_have_access")}
          onClick={handleShowModalBlockSignIn}
        />
      </Text>

      <SignInBlockedModal
        visible={visible}
        loading={false}
        onClose={() =>
          errorModal?.onClose ? errorModal.onClose() : setVisible(false)
        }
        {...errorModal}
      />
    </React.Fragment>
  )
}

export default TwoFactorVerification
