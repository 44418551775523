import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const EmptyPsw: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="128"
    height="4"
    viewBox="0 0 128 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="4" rx="1" fill="white" fillOpacity="0.2" />
    <rect x="44" width="40" height="4" rx="1" fill="white" fillOpacity="0.2" />
    <rect x="88" width="40" height="4" rx="1" fill="white" fillOpacity="0.2" />
  </svg>
);

export default EmptyPsw;
