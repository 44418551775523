import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const EmptyTransfer: FC<ISVG> = ({
  size = 150,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="150"
    height="157"
    viewBox="0 0 150 157"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M141.774 56.8993L146.631 33.0894C147.346 29.5829 145.083 26.1606 141.576 25.4446L27.2902 2.13199C23.7837 1.41676 20.3615 3.67992 19.6454 7.18641L3.13199 88.1394C2.41676 91.6459 4.67989 95.0681 8.18638 95.7842L122.473 119.097C125.979 119.812 129.401 117.55 130.117 114.042L134.65 91.8201"
      stroke="white"
      strokeWidth="3.24"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M119.165 80.1939C124.534 80.1939 128.885 75.8421 128.885 70.4739C128.885 65.1057 124.534 60.7539 119.165 60.7539C113.797 60.7539 109.445 65.1057 109.445 70.4739C109.445 75.8421 113.797 80.1939 119.165 80.1939Z"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M136.401 91.3502L115.766 87.1406C106.561 85.263 100.621 76.2785 102.499 67.0745C104.376 57.8696 113.361 51.9299 122.565 53.8075L143.2 58.0171C144.953 58.3751 146.084 60.0858 145.727 61.8395L140.222 88.8238C139.865 90.5766 138.153 91.7074 136.401 91.3502Z"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.7578 24.355L137.045 47.6676"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3867 45.7837L98.5459 62.1351"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2871 112.923L25.0258 127.459"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.8887 124.061L28.4258 116.322"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.4004 141.331L41.2658 145.458"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.7695 147.826L38.8965 138.961"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.2383 130.978L66.8892 142.849"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.627 140.239L69.4983 133.587"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M76.0352 154.183L81.5691 152.349"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.7183 156.034L77.8828 150.499"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M93.1113 129.286L100.558 132.313"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95.3203 134.523L98.348 127.076"
      stroke="white"
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EmptyTransfer;
