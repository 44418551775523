import { createGlobalStyle } from "styled-components";
import ExtendedDefaultStyle from "interfaces/theme/theme";
import { palette } from "../lib/theme";

const NativeGlobalStyles = createGlobalStyle<ExtendedDefaultStyle>`
  // settings
  
  *, :before, :after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: 200;
  }

  // Layout

  body {
    margin: 0;
    color: ${palette.darkBackgroundContrast.white};
    background-color: ${palette.black.main};
    font-feature-settings: 'ss02' on, 'liga' off;
    @media print {
      background-color: white !important;
    }
  }

  // Text
  .green__text {
    color: ${palette.green.main}
  }

  .red__text {
    color: ${palette.red.main}
  }

  mark {
    background-color: transparent;
     color: white;
    font-weight: 600;
  }

  .input-control-2 {
    width: 100%
  }
  .pac-container.pac-logo {
    position: fixed !important;
    z-index: 5000;
    top: 350px !important;
    &:last-child::after {
      display: none;
    }
  }
  
`;

export default NativeGlobalStyles;
