import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'state/slice/auth.slice'
import { useGetCustodyInvoiceQuery } from 'state/store/custodyApi'
import { YearMonth } from 'state/store/statementsApi'


const useCustodyInvoice = (props: YearMonth) => {
  const year = props.year
  const month = props.month?.padStart(2, '0')
  const yearMonth = {year, month}

  const user = useSelector(selectCurrentUser)
  const smaAccountNumber = user?.organization?.smaAccountNumber ?? "-"

  const data = useGetCustodyInvoiceQuery(yearMonth)
  
  return { smaAccountNumber, data }
}

export default useCustodyInvoice
