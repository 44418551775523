import palette from "../../lib/theme/palette";
import styled, { css } from "styled-components";
import { Text } from "../../components/atoms/Typography";

export const LinkBase = styled(Text)`
  color: ${palette.accent.light};
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.51px;
  &:hover {
    color: linear-gradient(
        0deg,
        ${palette.darkBackgroundContrast.light20},
        ${palette.darkBackgroundContrast.light20}
      ),
      linear-gradient(0deg, ${palette.accent.light}, ${palette.accent.light});
  }
  ${({ disabled }: { disabled: boolean }) =>
    disabled
      ? css`
          color: ${palette.darkBackgroundContrast.light20};
          cursor: no-drop;
          &:hover {
            color: ${palette.darkBackgroundContrast.light20};
          }
        `
      : ""}
`;
