import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

interface AlertState {
  showWithdrawAlert: boolean;
}
const initialState: AlertState = {
  showWithdrawAlert: false,
};

export const alertSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    toggleAlert: (state) => {
      state.showWithdrawAlert = !state.showWithdrawAlert;
    },
    turnAlertOff: (state) => {
      state.showWithdrawAlert = false
    }
  },
});

export const { toggleAlert, turnAlertOff } = alertSlice.actions;

export const alertSelector = createSelector(
  (state: RootState) => state.alerts,
  (state: AlertState) => state
);
