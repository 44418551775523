import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Cash: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = 'black',
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <g filter="url(#filter0_b_516_6292)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.18342 5.68342C1.5 6.36684 1.5 7.46678 1.5 9.66667V14.3333C1.5 16.5332 1.5 17.6332 2.18342 18.3166C2.86684 19 3.96678 19 6.16667 19H17.8333C20.0332 19 21.1332 19 21.8166 18.3166C22.5 17.6332 22.5 16.5332 22.5 14.3333V9.66667C22.5 7.46678 22.5 6.36684 21.8166 5.68342C21.1332 5 20.0332 5 17.8333 5H6.16667C3.96678 5 2.86684 5 2.18342 5.68342ZM12 14.3333C13.2887 14.3333 14.3334 13.2886 14.3334 12C14.3334 10.7113 13.2887 9.66663 12 9.66663C10.7114 9.66663 9.66669 10.7113 9.66669 12C9.66669 13.2886 10.7114 14.3333 12 14.3333ZM4.41668 7.33337C4.09451 7.33337 3.83334 7.59454 3.83334 7.91671C3.83334 8.23887 4.09451 8.50004 4.41668 8.50004H6.75001C7.07218 8.50004 7.33334 8.23887 7.33334 7.91671C7.33334 7.59454 7.07218 7.33337 6.75001 7.33337H4.41668ZM16.6667 16.0833C16.6667 15.7612 16.9279 15.5 17.25 15.5H19.5834C19.9055 15.5 20.1667 15.7612 20.1667 16.0833C20.1667 16.4055 19.9055 16.6667 19.5834 16.6667H17.25C16.9279 16.6667 16.6667 16.4055 16.6667 16.0833Z"
        fill={color}
      />
    </g>
    <defs>
      <filter
        id="filter0_b_516_6292"
        x="-16"
        y="-12.5"
        width="56"
        height="49"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImage" stdDeviation="8.75" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_516_6292"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_516_6292"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default Cash;
