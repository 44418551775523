import { useEffect, useState } from "react";

// useWindowSize Hook
// This provides updated window sizes to handle responsive layouts.
// It also makes React update faster the UI when the user changes screen sizes.
function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      window.addEventListener("resize", handleResize);

      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  }

  export default useWindowSize;