import { createAsyncThunk, createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { Notification, PaginationResponse } from "services/models";
import { NotificationsControllerApi } from "services";
import { UpdateNotificationRequest } from "services/models";
import { RootState } from "state/store";

interface NotificationState {
  status: string;
  loading: boolean;
  loadingHistory: boolean;
  notifications: { notifications: Notification[]; paginationResponse: PaginationResponse };
  notificationHistory: { notifications: Notification[]; paginationResponse: PaginationResponse };
  notificationsError: boolean;
}

const initialState = {
  status: "unprocessed",
  loading: false,
  loadingHistory: false,
  notifications: { notifications: [], paginationResponse: { nextPage: 0, previousPage: 0, totalPages: 0, totalRecords: 0 } },
  notificationHistory: { notifications: [], paginationResponse: { nextPage: 0, previousPage: 0, totalPages: 0, totalRecords: 0 } },
  notificationsError: false,
} as NotificationState;

const NotificationsService = new NotificationsControllerApi();

export const NotificationHistory = createSelector(
  (state: RootState) => state.notifications,
  (notifications: NotificationState) => notifications.notificationHistory
);

export const getNotifications =
  (
    type: string = "unprocessed",
    sortField: string = "date",
    sortDirection: string = "desc",
    page?: string,
    size?: string,
    daysBefore?: string
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const { token } = getState().auth;
      const { status } = getState();
      const { data } = await NotificationsService.getNotifications(
        token,
        status || type,
        sortField,
        sortDirection,
        page,
        size,
        daysBefore || "5",
      );

      const notifications: Notification[] = data?.notifications ?? new Array(0);
      const pagination: PaginationResponse = data?.paginationResponse ?? {};

      dispatch(
        setNotifications({ notifications: notifications, paginationResponse: pagination })
      );
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  export const getNotificationHistory =
  (
    type: string = "unprocessed",
    sortField: string = "date",
    sortDirection: string = "desc",
    page: string = "1",
    size: string = "20",
    daysBefore?: string
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoadingHistory(true));
      const { token } = getState().auth;
      const { data } = await NotificationsService.getNotifications(
        token,
        type,
        sortField,
        sortDirection,
        page,
        size,
        daysBefore,
      );

      const notifications: Notification[] = data?.notifications ?? new Array(0);
      const pagination: PaginationResponse = data?.paginationResponse ?? {};

      dispatch(
        setNotificationHistory({ notifications: notifications, paginationResponse: pagination })
      );
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoadingHistory(false));
    }
  };

  export const updateNotification = createAsyncThunk(
    "notification/updateNotification",
    async (request: UpdateNotificationRequest, { getState, rejectWithValue }) => {
      const {
        auth: { token },
      } = getState() as RootState;
      try {
        const { data } = await NotificationsService.updateNotifications(
          request,
          token
        );
        return data;
      } catch (err) {
        const error = err as AxiosError;
        if (error?.response?.data?.message)
          return rejectWithValue(error.response.data.message);
      }
    }
  );

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (
      state,
      action: PayloadAction<{ notifications: Notification[]; paginationResponse: PaginationResponse }>
    ) => {
      state.notifications = action.payload;
    },
    setNotificationHistory: (
      state,
      action: PayloadAction<{ notifications: Notification[]; paginationResponse: PaginationResponse }>
    ) => {
      state.notificationHistory = action.payload;
    },
    setLoadingHistory: (state, action: PayloadAction<boolean>) => {
      state.loadingHistory = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    }
  },
  extraReducers: {
    [updateNotification.pending as any]: (state) => {
      state.loading = true;
      state.loadingHistory = true;
      state.notificationsError = false;
    },
    [updateNotification.rejected as any]: (state) => {
      state.loading = false;
      state.loadingHistory = false;
      state.notificationsError = true;
    },
    [updateNotification.fulfilled.toString()]: (state) => {
      state.loading = false;
      state.loadingHistory = false;
      state.notificationsError = false;
    },
  },
});

export const {
  setNotifications,
  setLoading,
  setLoadingHistory,
  setNotificationHistory,
} = notificationsSlice.actions;

export const notificationsReducer = notificationsSlice.reducer;