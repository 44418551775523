import React, { FC } from 'react';
import ISVG from './@types/icons/svg';

export const New: FC<ISVG> = ({
  size = 24,
  width = 48,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}>
    <rect width="48" height="24" rx="12" fill="#6F2ACD" />
    <path
      d="M13.288 12.368L12.364 10.592H12.328V17H11.032V8.624H12.544L15.28 13.256L16.204 15.032H16.24V8.624H17.536V17H16.024L13.288 12.368ZM20.0984 17V8.624H25.4624V9.824H21.4544V12.152H25.0904V13.352H21.4544V15.8H25.4624V17H20.0984ZM28.9477 17L26.9677 8.624H28.3477L29.1997 12.656L29.7877 15.44H29.8237L30.4957 12.656L31.4917 8.624H33.0037L33.9877 12.656L34.6477 15.428H34.6837L35.2957 12.656L36.1717 8.624H37.5037L35.4397 17H33.9157L32.8477 12.668L32.2237 10.124H32.1997L31.5637 12.668L30.4717 17H28.9477Z"
      fill="white"
    />
  </svg>
);

export default New;
