import React, { FC, useRef } from "react"

import Grid from "./Grid"

import Navigation from "components/molecules/Navigation"
import Header from "components/molecules/Header"

import { PagesUrl } from "lib/constants/config.constant"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "state/slice/auth.slice"
import usePrices from 'shared/pricesUpdater'
import { INavigationItemProps } from 'interfaces/components/atoms/Atom.interface'

interface LayoutProps {
  pageHeader?: React.ReactNode
  customGrid?: { [key: string]: GridSystem }
  children?: React.ReactNode
  hidePrimeTrustAndFooter?: boolean
  headerPadding?: string
}

const NAVIGATION_ITEMS: INavigationItemProps[] = [
  { id: "Custody", title: "Accounts", icon: "Overview", links: PagesUrl.CUSTODY },
  { id: "Boost", title: "Boost", icon: "Earn", links: PagesUrl.BOOST },
  { id: "Borrow", title: "Borrow", icon: "Borrow", links: PagesUrl.BORROW },
]

const Layout: FC<LayoutProps> = ({
  pageHeader,
  children,
  customGrid,
  hidePrimeTrustAndFooter = false,
  headerPadding = "20px",
}) => {
  const user = useSelector(selectCurrentUser)

  const headerRef = useRef()
  usePrices()
  const handleToggleMenu = (): void => {
    headerRef?.current?.classList?.toggle("responsive__sidebar")
  }

  const menus: INavigationItemProps[] = NAVIGATION_ITEMS.map(item => ({ ...item, isHidden: user?.organization?.products ? !user?.organization?.products?.includes(item.id) : false }))

  return (
    <AppContainer>
      <Header onToggleSideBar={handleToggleMenu} />
      <Navigation
        items={menus}
        onToggleSideBar={handleToggleMenu}
        navRef={headerRef}
      />
      <MainContainer>
        <PageContainer>
          {pageHeader ? (
            <HeaderContainer headerPadding={headerPadding}>
              {pageHeader}
            </HeaderContainer>
          ) : null}
          <PageColumnsContainer customGrid={customGrid}>
            {children}
          </PageColumnsContainer>
        </PageContainer>
      </MainContainer>
    </AppContainer>
  )
}

const AppContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 15px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f122;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(111, 42, 205);
    border-radius: 8px;
  }

`

const MainContainer = styled.main`
  margin: 0px 2vw 0px 299px;  
  max-width: 100vw;
  @media print{
    max-width: 100vw !important;
    margin: 0 !important;
    width: 100vw !important;
    background-color: white !important;
  }

  @media only screen and (max-width: 1200px) {
    margin-left: 20px;
    margin-right: 20px;
  }

`

const PageContainer = styled.div`
  padding-top: 55px;
`

const HeaderContainer = styled.div<{ headerPadding?: string }>`
  padding-top: ${({ headerPadding }) => headerPadding};
`

const PageColumnsContainer = styled(Grid) <{ customGrid?: string }>`
  display: flex;
  flex-direction: column;
`

export default Layout



interface GridSystem {
  columsTemplate: string
  rowsTemplate: string
  gap: string
  margin: string
  columnStart?: number
  columnEnd?: number
  gridAutoRows?: string
}
