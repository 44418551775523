import React from 'react';
import Image from 'components/atoms/Image'
import { AssetDto } from 'state/store/api';
import styled from 'styled-components';
import useAssets from 'shared/useAssets'

interface CustodyAssetBalanceComponentProps {
    asset?: AssetDto;
    isClickable?: boolean;
    onClick?: () => any;
    balance?: string;
    dollar?: string;
}

const CustodyAssetBalanceComponent: React.FC<CustodyAssetBalanceComponentProps> = ({ balance, dollar, asset, onClick }) => {
  const {getPriceFormattedI} = useAssets()
    return (
        <TokenImageBox onClick={onClick}>
            <ImageBox>
                <Image width='100%' height='100%' src={asset?.icon} />
            </ImageBox>
            <TextGroup>
                <MainRowText>{asset?.longName}</MainRowText>
                <SubRowText>{getPriceFormattedI(asset?.identifier, balance)} (${dollar})</SubRowText>
            </TextGroup>
        </TokenImageBox>
    );
};



const TokenImageBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.6vw;
  flex-direction: column; // Display items vertically
  align-items: flex-start;
  min-width: 12vw;
  width: fit-content; // Set the container width to fit the content
`


const TokenImageBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6vw;
  padding: 4px 6px;
  height: 55px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%; // Set the width to fit the content
  &:hover {
    background-color: rgba(0,0,0,0.3);
  }
`

const ImageBox = styled.div`
  width: 45px;
  height: 45px;
`

const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const MainRowText = styled.span`
  font-size: 1.2rem;
`

const SubRowText = styled.span`
  font-weight: 400;
  color: #FFFFFF;
  font-size: 80%;
  opacity: 0.7;
  cursor: inherit;
`

export const CustodyAssetBalanceComponentContainer = TokenImageBoxContainer;

export default CustodyAssetBalanceComponent;
