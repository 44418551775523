import React, { FC } from 'react';
import { Heading, Text } from 'components/atoms/Typography';
import AnimatedWidgetStep from 'components/atoms/Animated/AnimatedWidgetStep';
import Loading from 'components/atoms/Loading';
import LoanToValueRatio from './LoanToValueRatio';
import palette from 'lib/theme/palette';
import styled from 'styled-components';
import Widget from 'components/templates/Widget';
import { OrderDto, useGetAssetsQuery } from 'state/store/api';
import { formatNumberDisplay, shortDateformatter } from 'shared';
import getAssetPrice from 'shared/getAssetPrice';

export const LoanDetails: FC<{
  order: OrderDto;
  prices: [];
  isLoading: boolean;
  responsive?: boolean;
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
  xxl?: string;
}> = ({ order, prices, isLoading, xs, sm, md, lg, xl, xxl }) => {
  const getPrice = (currency: string) => {
    return prices?.find((m) => m.identifier.toUpperCase() === currency)?.price;
  };
  const hidePaymentInfo = ['settled', 'closed', 'off', 'cancelled'].includes(order?.status);

  const item = (title: string, value: string, subValue?: string, indx?: number) => (
    <Column key={indx}>
      <Title>{title?.toLocaleUpperCase()}</Title>
      <Row alignItems='baseline' justifyContent='flex-start' gap='8px'>
        <Text size='large'> {value}</Text>
        {subValue && (
          <Text size='small' color={palette.darkBackgroundContrast.light70}>
            {subValue}
          </Text>
        )}
      </Row>
    </Column>
  );

  const { data: assets } = useGetAssetsQuery();

  const getAssetFromIdentifier = (identifier?: string) => {
    return assets?.assets?.find((asset) => asset.identifier === identifier);
  };

  return (
    <Widget xxl={xxl} xl={xl} lg={lg} md={md} sm={sm} xs={xs} title={`Loan #${order?.id1Token} `}>
      {isLoading ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loading />
        </div>
      ) : (
        <AnimatedWidgetStep name='loanDetails'>
          <Column height='100%'>
            <Column gap='23.67px'>
              <Column justifyContent='space-between' paddingTop='8.5px'>
                <Title>Outstanding Balance</Title>
                <Column justifyContent='space-between' paddingTop='8px'>
                  <Heading variant='h1'>${formatNumberDisplay(getPrice(order?.currency.toUpperCase()) * parseFloat(order?.balance) || 0)}</Heading>
                </Column>
              </Column>
              {!hidePaymentInfo && (
                <Column>
                  <LoanToValueRatio
                    order={order}
                    chartMeasures={{
                      warning: parseFloat(order?.marginLineLtv ?? '0'),
                      collateralCall: parseFloat(order?.warningLtv ?? '0'),
                      liquidation: parseFloat(order?.riskyLtv ?? '0'),
                    }}
                    currentLtv={parseFloat(order?.currentLtv ?? '0')}
                  />
                </Column>
              )}
              <GridInfo>
                {!hidePaymentInfo &&
                  order?.loanRepayment?.nextPayment?.dueDate &&
                  item(
                    'Next payment',
                    `$${formatNumberDisplay(
                      getPrice(order?.currency.toUpperCase()) * parseFloat(order?.loanRepayment?.nextPayment?.interestAmountExpected || 0)
                    )} on ${shortDateformatter(new Date(order?.loanRepayment?.nextPayment?.dueDate))}`
                  )}
                {!hidePaymentInfo &&
                  order?.loanRepayment?.finalPaymentDate &&
                  order?.termType !== 'flexible' &&
                  item('Final payment date', shortDateformatter(new Date(order?.loanRepayment?.finalPaymentDate)))}
                {order?.startDate && item('Opened on', shortDateformatter(new Date(order?.startDate)))}
                {!hidePaymentInfo &&
                  order?.loanRepayment?.totalInterestPaid &&
                  item(
                    'Total interest paid',
                    `${getAssetPrice(
                      getAssetFromIdentifier(order.currency?.toUpperCase()),
                      order?.loanRepayment?.totalInterestPaid
                    )} ${order?.currency.toUpperCase()}`,
                    `$${formatNumberDisplay(
                      getPrice(order?.currency.toUpperCase()) * parseFloat(order?.loanRepayment?.totalInterestPaid || 0),
                      'decimal',
                      'auto',
                      2
                    )}`
                  )}
                {!hidePaymentInfo && order?.collateral?.map((co, indx) => item('collateral amount', `${co?.amount} ${co?.moreInfo.toUpperCase()}`, '', indx))}
                {item(order?.interestType.toUpperCase(), `${order?.interestRate}%`)}
                {!hidePaymentInfo &&
                  item(
                    'Principal paid',
                    `${order?.paidPrincipal} ${order?.currency.toUpperCase()}`,
                    `$${formatNumberDisplay(getPrice(order?.currency.toUpperCase()) * parseFloat(order?.paidPrincipal))}`
                  )}
              </GridInfo>
            </Column>
          </Column>
        </AnimatedWidgetStep>
      )}
    </Widget>
  );
};

const Column = styled.div<{
  gap?: string;
  height?: string;
  justifyContent?: string;
  paddingTop?: string;
  width?: string;
}>`
  display: flex;
  flex-direction: column;
  ${(props) => props?.width && `width: ${props?.width};`}
  ${(props) => props?.height && `height: ${props?.height};`}
  ${(props) => props?.gap && `gap: ${props?.gap};`}
  ${(props) => props?.justifyContent && `justify-content: ${props?.justifyContent};`}
  ${(props) => props?.paddingTop && `padding-top: ${props?.paddingTop};`}
`;

const Row = styled.div<{
  gap?: string;
  height?: string;
  justifyContent?: string;
  paddingTop?: string;
  alignItems?: string;
}>`
  display: flex;
  flex-direction: row;
  ${(props) => props?.height && `height: ${props?.height};`}
  ${(props) => props?.gap && `gap: ${props?.gap};`}
  ${(props) => props?.justifyContent && `justify-content: ${props?.justifyContent};`}
  ${(props) => props?.paddingTop && `padding-top: ${props?.paddingTop};`}
  ${(props) => props?.alignItems && `align-items: ${props?.alignItems};`}
`;
const GridInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
`;
const Title = styled.h5`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: ${palette.secondary.secondaryFont};
`;
