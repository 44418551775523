import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const TickCircleBig: FC<ISVG> = ({
  size = 100,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M27.9477 48.4636C27.0991 47.7565 25.838 47.8711 25.1309 48.7197C24.4237 49.5682 24.5384 50.8294 25.3869 51.5365L27.9477 48.4636ZM74.8957 34.5828C75.5857 33.7202 75.4459 32.4617 74.5834 31.7716C73.7208 31.0816 72.4623 31.2215 71.7722 32.084L74.8957 34.5828ZM45.8836 66.0136L44.6032 67.55L45.8836 66.0136ZM47.3046 65.8701L45.7429 64.6207L47.3046 65.8701ZM25.3869 51.5365L44.6032 67.55L47.1639 64.4771L27.9477 48.4636L25.3869 51.5365ZM48.8664 67.1195L74.8957 34.5828L71.7722 32.084L45.7429 64.6207L48.8664 67.1195ZM50.0006 94.6667C25.3319 94.6667 5.33398 74.6688 5.33398 50H1.33398C1.33398 76.8779 23.1228 98.6667 50.0006 98.6667V94.6667ZM94.6673 50C94.6673 74.6688 74.6694 94.6667 50.0006 94.6667V98.6667C76.8785 98.6667 98.6673 76.8779 98.6673 50H94.6673ZM50.0006 5.33337C74.6694 5.33337 94.6673 25.3313 94.6673 50H98.6673C98.6673 23.1222 76.8785 1.33337 50.0006 1.33337V5.33337ZM50.0006 1.33337C23.1228 1.33337 1.33398 23.1222 1.33398 50H5.33398C5.33398 25.3313 25.3319 5.33337 50.0006 5.33337V1.33337ZM44.6032 67.55C45.8941 68.6258 47.8167 68.4316 48.8664 67.1195L45.7429 64.6207C46.0928 64.1833 46.7337 64.1186 47.1639 64.4771L44.6032 67.55Z"
      fill="#17B96B"
    />
  </svg>
);

export default TickCircleBig;
