import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "state/store";
import { CreditCardInformation, CreditCardInformationState } from "./creditCard.interface";

const creditApi = {
  getCreditCardInformation: (userId: string): Promise<{ data: CreditCardInformation }> =>
    Promise.resolve({
      data: {
        limit: {
          currency: "USD",
          value: "100.00",
        },
        apr: 10,
        fee: {
          currency: "USD",
          value: "10.00",
        },
        address: {
          line1: "123 Mission ST",
          city: "San Francisco",
          postalCode: "94105",
          region: "CA",
          country: "US",
        },
      },
    }),
};

export const getCreditCard = (state: RootState): CreditCardInformationState =>
  state.creditCardStatus;
export const getCreditCardData = createSelector(getCreditCard, (creditCard) => creditCard.data);

export const getCreditCardInformation = createAsyncThunk(
  "creditCardStatus/getCreditCardInformation",
  async (userId: string) => {
    const response = await creditApi.getCreditCardInformation(userId);
    return response.data;
  }
);

const initialState: CreditCardInformationState = {
  data: null,
  isLoading: false,
  error: null,
};

export const creditCardInformation = createSlice({
  name: "creditCardStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCreditCardInformation.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getCreditCardInformation.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(getCreditCardInformation.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const creditCardInformationReducer = creditCardInformation.reducer;
