import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const LoadingBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress <= 100) {
      const timer = setTimeout(() => setProgress(progress + 1), Math.random() * 50);
      return () => clearTimeout(timer);
    }
  }, [progress]);

  return (
    <LinearProgress progress={progress}>
      <div></div>
    </LinearProgress>
  );
};

interface ProgressBar {
  progress: number;
}
const LinearProgress = styled.div<ProgressBar>`
  width: 100%;
  background-color: rgba(111, 42, 205, 0.2);
  height: 12px;
  border-radius: 12px;
  overflow: hidden;
  div {
    width: ${(props) => props.progress}%;
    height: 100%;
    background-color: rgba(111, 42, 205, 1);
  }
`;

export default LoadingBar;
