import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const Filter: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
    <path d="M4.69709 6.3128H12.7381C12.9394 7.05262 13.4568 7.66602 14.152 7.9892C14.8473 8.31237 15.6497 8.31237 16.3451 7.9892C17.0404 7.66603 17.5577 7.05265 17.7589 6.3128H19.2892C19.5382 6.3128 19.7683 6.17994 19.8928 5.96432C20.0172 5.74869 20.0172 5.48298 19.8928 5.26736C19.7683 5.05173 19.5382 4.91887 19.2892 4.91887H17.7532C17.5519 4.17892 17.0347 3.56551 16.3393 3.24237C15.644 2.91922 14.8416 2.9192 14.1462 3.24237C13.451 3.56554 12.9336 4.17892 12.7324 4.91887H4.69716C4.44819 4.91887 4.21808 5.05173 4.09356 5.26736C3.96914 5.48298 3.96914 5.74869 4.09356 5.96432C4.21811 6.17994 4.44821 6.3128 4.69716 6.3128H4.69709ZM15.2571 4.39468C15.5808 4.39669 15.8903 4.52714 16.1178 4.75735C16.3454 4.98744 16.4722 5.29856 16.4705 5.62214C16.4688 5.94585 16.3387 6.25551 16.1088 6.4833C15.8789 6.71111 15.5679 6.83823 15.2443 6.83686C14.9206 6.83552 14.6108 6.70574 14.3828 6.47607C14.1548 6.24638 14.0273 5.93552 14.0283 5.61196C14.0299 5.28759 14.1602 4.97713 14.3905 4.74878C14.621 4.52057 14.9326 4.3932 15.257 4.39468H15.2571Z" fill={color}/>
    <path d="M19.2892 10.675H11.2461C11.0458 9.93459 10.5287 9.32054 9.83338 8.99698C9.13803 8.67343 8.33526 8.67341 7.63987 8.99698C6.94449 9.32055 6.42756 9.93463 6.2272 10.675H4.69692C4.44794 10.675 4.21784 10.8078 4.09331 11.0234C3.9689 11.2391 3.9689 11.5048 4.09331 11.7204C4.21787 11.936 4.44797 12.0689 4.69692 12.0689H6.23295C6.43331 12.8092 6.95028 13.4233 7.64563 13.7468C8.34098 14.0704 9.14375 14.0704 9.83914 13.7468C10.5345 13.4233 11.0516 12.8092 11.2518 12.0689H19.2891C19.5381 12.0689 19.7682 11.936 19.8927 11.7204C20.0171 11.5048 20.0171 11.2391 19.8927 11.0234C19.7681 10.8078 19.538 10.675 19.2891 10.675H19.2892ZM8.72913 12.5931C8.40569 12.5911 8.09632 12.4607 7.86877 12.2309C7.64135 12.001 7.51425 11.6903 7.51572 11.3668C7.51706 11.0434 7.64657 10.7338 7.876 10.5058C8.10542 10.2779 8.41586 10.1501 8.73932 10.1509C9.06276 10.1516 9.37268 10.2804 9.60116 10.5094C9.82964 10.7383 9.95794 11.0485 9.95794 11.3719C9.9574 11.697 9.82749 12.0085 9.59701 12.2375C9.36638 12.4666 9.0542 12.5945 8.72917 12.5931H8.72913Z" fill={color}/>
    <path d="M19.2891 16.4312H17.7531C17.5518 15.6914 17.0345 15.078 16.3392 14.7548C15.6438 14.4316 14.8415 14.4316 14.1461 14.7548C13.4509 15.078 12.9335 15.6913 12.7322 16.4312H4.69704C4.44806 16.4312 4.21796 16.564 4.09344 16.7796C3.96902 16.9953 3.96902 17.261 4.09344 17.4766C4.21799 17.6922 4.44809 17.8251 4.69704 17.8251H12.7381C12.9394 18.565 13.4567 19.1784 14.1519 19.5016C14.8473 19.8247 15.6497 19.8247 16.345 19.5016C17.0404 19.1784 17.5576 18.565 17.7589 17.8251H19.2892C19.5381 17.8251 19.7682 17.6922 19.8928 17.4766C20.0172 17.261 20.0172 16.9953 19.8928 16.7796C19.7682 16.564 19.5381 16.4312 19.2892 16.4312H19.2891ZM15.2571 18.3512C14.9327 18.3533 14.6208 18.2261 14.3903 17.9978C14.1598 17.7696 14.0295 17.4591 14.0283 17.1345C14.0271 16.8102 14.1551 16.4986 14.3839 16.2687C14.6128 16.0387 14.9237 15.9094 15.2481 15.909C15.5725 15.9087 15.8837 16.0374 16.1131 16.2668C16.3424 16.4963 16.471 16.8076 16.4706 17.132C16.4686 17.4536 16.3402 17.7615 16.1131 17.9891C15.8861 18.217 15.5786 18.3462 15.2571 18.3493L15.2571 18.3512Z" fill={color}/>
  </svg>
);

export default Filter;
