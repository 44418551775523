import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const LocationNotSupported: FC<ISVG> = ({
  size = 88,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="88"
    height="88"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3183_124123)">
      <path
        d="M10.1543 36.1773C10.1543 17.2769 25.4761 1.95508 44.3765 1.95508C63.277 1.95508 78.5987 17.2769 78.5987 36.1773C78.5987 65.5106 44.3765 86.044 44.3765 86.044C44.3765 86.044 10.1543 65.5106 10.1543 36.1773Z"
        stroke="#EC396B"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.3766 55.7322C55.1768 55.7322 63.9322 46.9769 63.9322 36.1766C63.9322 25.3764 55.1768 16.6211 44.3766 16.6211C33.5764 16.6211 24.821 25.3764 24.821 36.1766C24.821 46.9769 33.5764 55.7322 44.3766 55.7322Z"
        stroke="#EC396B"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.2043 22.3496L30.5488 50.0051"
        stroke="#EC396B"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3183_124123">
        <rect width="88" height="88" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default LocationNotSupported;
