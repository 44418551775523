import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Plane: FC<ISVG> = ({
  size = 180,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M92.0974 58.9894C88.9649 58.9901 85.9633 60.2444 83.7517 62.4728C81.54 64.7014 80.2995 67.7217 80.2995 70.8686V72.3686H81.7995H102.434H103.934L103.934 70.8686C103.934 69.3069 103.628 67.7604 103.033 66.3175C102.439 64.8746 101.567 63.5633 100.468 62.4592C99.3687 61.3551 98.0633 60.4798 96.6264 59.8842C95.1901 59.2889 93.651 58.9847 92.0974 58.9894ZM92.0974 58.9894C92.0982 58.9894 92.0991 58.9894 92.0999 58.9894V60.4894M92.0974 58.9894C92.0966 58.9894 92.0958 58.9895 92.0951 58.9895L92.0999 60.4894M92.0999 60.4894C93.4554 60.485 94.7984 60.7502 96.052 61.2699C97.3055 61.7895 98.445 62.5533 99.405 63.5176C100.365 64.4818 101.127 65.6275 101.646 66.8889C101.975 67.6857 102.202 68.5188 102.326 69.3686H102.434V70.8685C102.434 70.8685 102.434 70.8685 102.434 70.8686H83.2995H81.7995V69.3686H81.9076C82.2262 67.1706 83.2391 65.1188 84.8164 63.5294C86.7481 61.583 89.3681 60.4894 92.0999 60.4894ZM81.4672 44.6194L82.7882 45.33L91.7928 28.5895L91.7941 28.5872C91.8093 28.5588 91.8309 28.5367 91.8554 28.522C91.8799 28.5073 91.9069 28.5 91.9338 28.5C91.9606 28.5 91.9876 28.5072 92.0121 28.522C92.0369 28.5369 92.0585 28.559 92.0736 28.5872L92.0736 28.5872L92.077 28.5935L101.147 45.3315L101.148 45.3341L107.791 57.6498C107.791 57.6502 107.791 57.6507 107.791 57.6511C110.208 62.1544 111.482 67.1906 111.5 72.3112V79.0037V79.0045L111.467 144.5H72.5006L72.5006 79.0379L72.5006 72.3417L72.5006 72.3325C72.4691 67.2181 73.7221 62.1793 76.1424 57.6852L76.1428 57.6845L82.7882 45.33L81.4672 44.6194Z"
      stroke="white"
      strokeWidth="3"
    />
    <path
      d="M41.7079 119.578L41.7351 119.556L41.7612 119.532C47.648 114.191 52.8332 108.146 57.2024 101.542C57.348 101.322 57.6331 101.198 58.0016 101.3C58.1765 101.348 58.3056 101.437 58.381 101.525C58.4454 101.6 58.49 101.694 58.4898 101.841L58.4351 144.001C58.4347 144.277 58.211 144.5 57.9351 144.5H36.0001C35.7239 144.5 35.5001 144.276 35.5001 144L35.5001 132.765L35.5 132.761C35.494 130.245 36.0471 127.759 37.1201 125.48C38.1931 123.201 39.7595 121.186 41.7079 119.578Z"
      stroke="white"
      strokeWidth="3"
    />
    <path
      d="M141.477 119.512L141.503 119.537L141.53 119.56C143.398 121.163 144.905 123.177 145.938 125.46C146.972 127.743 147.506 130.236 147.5 132.761V132.765V144C147.5 144.276 147.276 144.5 147 144.5H126.031C125.755 144.5 125.531 144.276 125.531 144L125.505 102.107C125.505 101.959 125.55 101.865 125.616 101.79C125.693 101.7 125.826 101.611 126.005 101.563C126.386 101.461 126.673 101.592 126.815 101.814C130.977 108.303 135.899 114.249 141.477 119.512Z"
      stroke="white"
      strokeWidth="3"
    />
    <rect x="32" y="151" width="117" height="3" fill="white" />
  </svg>
);

export default Plane;
