export enum BreakpointsEnum {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
  ExtraExtraLarge = 'xxl',
}

export enum BreakpointsTableEnum {
  '768to768' = 'sm',
  '769to1078' = 'smM',
  '1079to1079' = 'md',
  '1080to1278' = 'mdM',
  '1279to1279' = 'lg',
  '1280to1438' = 'lgM',
  '1439to1439' = 'xl',
  '1440to1920' = 'xxL',
}
