import React, { FC, useEffect, useState } from 'react';

import { Sizes } from './Boost';
import { Heading, Text } from 'components/atoms/Typography';
import PanelWithHeader from 'components/molecules/PanelWithHeader';
import Currency from 'components/atoms/Number/Currency';
import { Number } from 'components/atoms/Number';
import AbraTable from 'components/organisms/AbraTable';
import Row from 'components/atoms/Row/Row';
import { TransactionDto, useGetAssetsQuery, useGetTransactionsQuery } from 'state/store/api';
import Icon, { IconBackground } from 'components/atoms/Icon/Icon';
import { capitalize } from 'shared';
import LoadingStage from 'pages/LoadingStage';
import { TableSortDirection } from '@types/enums/Table.enum';
import { DefaultSettings, PagesUrl } from 'lib/constants/config.constant';
import { Column } from 'components/organisms/AbraTable/interfaces';
import { palette } from 'lib/theme';
import { useNavigate } from 'react-router-dom';
import { TypeReferenceTxrCompleted } from 'shared/transactionTypeReference';
import getAssetPrice from 'shared/getAssetPrice';
import { pricesSelector } from 'state/slice/prices.slice';
import { useSelector } from 'react-redux';
import TransactionSidebar from 'pages/TransactionScreen/TransactionSidebar';

interface TransactionsItem {
  icon: string;
  assetName: string;
  transactionAmount: number;
  convenienceCurrencyBalance: number;
  transactionType: string;
  date: string;
  id: string;
  depositId: string;
  closingPrincipal: number;
  status: string;
  operationId: number;
}
interface TransactionsProps extends Sizes {}

const LoanTransactionsWidget: FC<TransactionsProps> = (props) => {
  const navigate = useNavigate();
  const [transactionsItems, setTransactionsItems] = useState<TransactionsItem[]>([]);

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('date');
  const [sortDirection, setSortDirection] = useState(TableSortDirection.DESCENDING);
  const recordsPerPage = DefaultSettings.RECORDS_PER_PAGE;

  const { data, isLoading, isFetching } = useGetTransactionsQuery(
    {
      type: 'loan',
      orderId: props?.orderId,
      sortField: sort,
      sortDirection: sortDirection,
      page: page.toString(),
      size: recordsPerPage.toString(),
    },
    { pollingInterval: 30000 }
  );

  const prices = useSelector(pricesSelector);

  useEffect(() => {
    if (!data && !prices) return;

    const response = !data?.transactions
      ? []
      : data.transactions.map((item: TransactionDto) => {
          const price = prices?.find((m) => m.identifier === item.currency)?.price;
          const typeReference = TypeReferenceTxrCompleted[item?.transactionType];
          return {
            icon: typeReference?.icon,
            assetName: item.currency,
            depositId: props?.orderId.toString() ?? '',
            date: item.date,
            transactionType: typeReference ? typeReference?.getReference(item) : '',
            transactionAmount: parseFloat(item.transactionAmount ?? '0'),
            convenienceCurrencyBalance: parseFloat(item.transactionAmount ?? '0') * parseFloat(price ?? '0'),
            closingPrincipal: parseFloat(item.initialAmount ?? '0') + parseFloat(item.transactionAmount ?? '0'),
            operationId: item?.operationId,
          } as TransactionsItem;
        });

    setTransactionsItems(response);
  }, [data, prices]);

  const [columns, setColumns] = useState<Column[]>([
    {
      title: 'Transaction type / Date',
      enableSort: true,
      accessor: 'date',
    },
    {
      title: 'Amount',
      enableSort: true,
      accessor: 'transactionAmount',
    },
    {
      title: 'Transaction Id',
      enableSort: true,
      accessor: 'operationId',
    },
  ]);
  const { data: assets } = useGetAssetsQuery();

  const getAssetFromIdentifier = (identifier?: string) => {
    return assets?.assets?.find((asset) => asset.identifier === identifier);
  };

  const rows = transactionsItems.map((item: TransactionsItem) => {
    const { icon, transactionType, date, transactionAmount, assetName, convenienceCurrencyBalance, depositId, operationId } = item;
    return {
      schema: item,
      date: () => (
        <Row gap='12px'>
          <IconBackground icon={icon} />
          <Row direction='column'>
            <Text>{transactionType}</Text>
            <Text size='tiny' opacity={0.7}>
              {date}
            </Text>
          </Row>
        </Row>
      ),
      transactionAmount: () => (
        <Row direction='column'>
          <div>
            {getAssetPrice(getAssetFromIdentifier(assetName.toUpperCase()), transactionAmount)} <Text>{assetName.toUpperCase()}</Text>
          </div>
          {convenienceCurrencyBalance ? (
            <Text size='tiny' opacity={0.7}>
              <Currency value={convenienceCurrencyBalance} />
            </Text>
          ) : (
            <></>
          )}
        </Row>
      ),
      orderId: () => <Text>{depositId}</Text>,
      operationId: () => <Text>{operationId}</Text>,
    };
  });

  const handlePaginationChange = (currentPage: number) => {
    setPage(currentPage);
  };

  const handleAscendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key === 'transactions' ? 'date' : key);
    setSortDirection(TableSortDirection.ASCENDING);
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: column.accessor === key ? TableSortDirection.ASCENDING : TableSortDirection.UNSORTED,
      }))
    );
    setPage(1);
  };

  const handleDescendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key === 'transactions' ? 'date' : key);
    setSortDirection(TableSortDirection.DESCENDING);
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: column.accessor === key ? TableSortDirection.DESCENDING : TableSortDirection.UNSORTED,
      }))
    );
    setPage(1);
  };

  const handleResetSort = (key: string, data: any, setTableData: any) => {
    setSort('date');
    setSortDirection(TableSortDirection.DESCENDING);
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: TableSortDirection.UNSORTED,
      }))
    );
    setPage(1);
  };

  const [showTransaction, setShowTransaction] = useState(false);
  const [transactionId, setTransactionId] = useState(0);
  const transactionRowClick = (_: any, schema: any) => {
    setTransactionId(schema.operationId);
    setShowTransaction(true);
  };

  return (
    <PanelWithHeader width='100%' height='563px' title='Transactions' {...props}>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <LoadingStage loadingText=' ' />
        </div>
      ) : data?.transactions?.length ? (
        <AbraTable
          columns={columns}
          data={rows}
          showPagination={true}
          isLoading={isLoading}
          isSorting={isFetching}
          activePageNumber={page}
          recordsPerPage={recordsPerPage}
          totalRecords={data?.paginationResponse?.totalRecords}
          onPaginationChange={handlePaginationChange}
          onAscendingSort={handleAscendingSort}
          onDescendingSort={handleDescendingSort}
          onResetSort={handleResetSort}
          showPaginationCaption={false}
          onRowClick={transactionRowClick}
          expandTable
        />
      ) : (
        <EmptyStage />
      )}
      <TransactionSidebar show={showTransaction} setShow={setShowTransaction} transactionId={transactionId} setTransactionId={setTransactionId} />
    </PanelWithHeader>
  );
};

const EmptyStage = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Icon name='NoTransactions' size={150} />
      <div style={{ marginBottom: '8px' }}>
        <Heading variant='h3' color={palette.gray.main}>
          No transactions yet
        </Heading>
      </div>
    </div>
  </div>
);

export default LoanTransactionsWidget;
