import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pricesSelector, setPrices } from 'state/slice/prices.slice'
import { useGetPricesQuery } from 'state/store/api'

const usePrices = () => {
  const query = useGetPricesQuery()
  const prices = useSelector(pricesSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!query.isLoading && query.isSuccess) {
      if (query.data && query.data.prices) {
        dispatch(setPrices(query.data.prices))
      }
    }
  }, [query])

  useEffect(() => {
    const interval = setInterval(() => {
      query.refetch()
    }, 30000)
    return () => clearInterval(interval)
  }, [])


  return prices
}

export default usePrices
