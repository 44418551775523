import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const SignUpImage: FC<ISVG> = ({
  size = 24,
  width = 658,
  height = 680,
  color,
  bgColor = "#A399F6",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 658 680"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M468.144 288.973C455.035 289.427 442.613 295.024 433.498 304.584C424.383 314.143 419.288 326.916 419.288 340.21C419.288 353.503 424.383 366.277 433.498 375.836C442.613 385.395 455.035 390.992 468.144 391.446C486.383 391.065 504.32 396.199 519.668 406.191C535.017 416.184 547.081 430.583 554.322 447.552C561.564 464.521 563.654 483.292 560.327 501.47C557 519.648 548.407 536.411 535.642 549.619C522.878 562.828 506.521 571.884 488.657 575.633C470.794 579.382 452.234 577.655 435.343 570.67C418.452 563.686 403.997 551.761 393.82 536.417C383.643 521.073 378.207 503.005 378.203 484.516V344.013C378.203 342.635 378.203 341.198 378.203 339.76V195.484C378.203 87.74 293.462 0 189.101 0C84.741 0 0 87.74 0 195.484C0 303.227 84.8592 391.027 189.101 391.027C202.21 390.573 214.632 384.976 223.747 375.416C232.862 365.857 237.957 353.084 237.957 339.79C237.957 326.497 232.862 313.723 223.747 304.164C214.632 294.605 202.21 289.008 189.101 288.554C165.157 287.938 142.398 277.865 125.677 260.484C108.957 243.103 99.5983 219.788 99.5983 195.513C99.5983 171.239 108.957 147.924 125.677 130.543C142.398 113.162 165.157 103.089 189.101 102.473C213.207 102.726 236.228 112.662 253.113 130.1C269.998 147.538 279.366 171.053 279.161 195.484V335.987C279.161 337.365 279.161 338.802 279.161 340.24V484.516C279.161 592.32 364.02 680 468.262 680C572.504 680 657.363 592.32 657.363 484.516C657.363 376.713 572.445 288.973 468.144 288.973Z"
      fill="url(#paint0_angular_897_31096)"
    />
    <defs>
      <radialGradient
        id="paint0_angular_897_31096"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(328.316 340) rotate(-51.3904) scale(400.346 358.925)"
      >
        <stop offset="0.228751" stop-color="#5224D4" stop-opacity="0" />
        <stop offset="0.582939" stop-color="#5224D4" />
        <stop offset="0.666277" stop-color="#5224D4" stop-opacity="0.698348" />
        <stop offset="1" stop-color="#5224D4" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export default SignUpImage;
