import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const SelectCheck: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 12.5L9 19.5L22 7.5"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SelectCheck;
