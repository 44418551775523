import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const WarningCircle: FC<ISVG> = ({
  size = 92,
  width = size,
  height = size,
  color = "#E99928",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M45.9998 89.2C69.8585 89.2 89.1998 69.8587 89.1998 46C89.1998 22.1413 69.8585 2.80005 45.9998 2.80005C22.1411 2.80005 2.7998 22.1413 2.7998 46C2.7998 69.8587 22.1411 89.2 45.9998 89.2Z"
      stroke={color}
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46 67.6001V71.2001"
      stroke={color}
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46 20.8V58.6"
      stroke={color}
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default WarningCircle;
