import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "state/store";
import { PersonalInformation, PersonalInformationState } from "./creditCard.interface";

const creditApi = {
  getPersonalInformation: (userId: string): Promise<{ data: PersonalInformation }> =>
    Promise.resolve({
      data: {
        fullName: "Wade Warren",
        phoneNumber: "(505) 555-0125",
        emailAddress: "example@gmail.com",
        snn: "1234",
        address: {
          line1: "123 Mission ST",
          city: "San Francisco",
          postalCode: "94105",
          region: "CA",
          country: "US",
        },
      },
    }),
};

export const getPersonalInformation = (state: RootState): PersonalInformationState =>
  state.personalInformation;
export const getPersonalInformationData = createSelector(
  getPersonalInformation,
  (personalInformation) => personalInformation.data
);

export const retrievePersonalInformation = createAsyncThunk(
  "personalInformation/getPersonalInformation",
  async (userId: string) => {
    const response = await creditApi.getPersonalInformation(userId);
    return response.data;
  }
);

const initialState: PersonalInformationState = {
  data: null,
  isLoading: false,
  error: null,
};

export const personalInformation = createSlice({
  name: "personalInformation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(retrievePersonalInformation.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(retrievePersonalInformation.fulfilled, (state, action) => {
      state.data = { ...state.data, ...action.payload };
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(retrievePersonalInformation.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const personalInformationReducer = personalInformation.reducer;
