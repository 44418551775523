import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const InterestBearingWithdrawalRequested: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
   <path fillRule="evenodd" clipRule="evenodd" d="M16 9V4H22V22H16V9ZM14 8V3C14 2.44772 14.4477 2 15 2H23C23.5523 2 24 2.44772 24 3V23C24 23.5523 23.5523 24 23 24H15H8H1C0.447715 24 0 23.5523 0 23V14C0 13.4477 0.447715 13 1 13H7V9C7 8.44772 7.44772 8 8 8H14ZM14 10H9V14V22H14V10ZM2 15H7V22H2V15Z" fill={color}/>
  </svg>
);

export default InterestBearingWithdrawalRequested;