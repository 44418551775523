import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const SignLoanContract: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 88 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69.52 87.24H18.48C16.5352 87.24 14.96 85.6648 14.96 83.72V6.28001C14.96 4.33521 16.5352 2.76001 18.48 2.76001H55.7391C56.6719 2.76001 57.5696 3.12965 58.2296 3.78965L72.0103 17.5704C72.6703 18.2304 73.04 19.128 73.04 20.0608V83.72C73.04 85.6648 71.4648 87.24 69.52 87.24Z"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M56.3198 8.04004V15.96C56.3198 17.9048 57.895 19.48 59.8398 19.48H73.0398"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.9305 63.1015L50.6001 65.0552L52.5537 57.7248L80.6609 29.6176C81.5057 28.7728 82.8873 28.7728 83.7321 29.6176L86.0377 31.9232C86.8825 32.768 86.8825 34.1496 86.0377 34.9944L57.9305 63.1015Z"
      fill="#07041C"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M78.8213 31.8088L82.0245 35.0121"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SignLoanContract;
