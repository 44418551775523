import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "state/store"
import { PricesDto } from 'state/store/api'

interface PricesState {
  prices: PricesDto[]
}
const initialState: PricesState = {
  prices: []
}


export const pricesSlice = createSlice({
  name: "prices",
  initialState,
  reducers: {
    setPrices: (state, action: PayloadAction<PricesDto[]>) => {
      state.prices = action.payload
    },
  },
})

export const { setPrices } = pricesSlice.actions
export const pricesSelector = createSelector(
  (state: RootState) => state.prices,
  (state: PricesState) => state.prices
)

