import React, { FC } from 'react';
import styled from 'styled-components';
import { palette } from '../../../lib/theme';

import { RadioButtonProps } from './RadioButton.interface';

const Input = styled.input`
  width: 12px;
  background-color: orange;
  height: 12px;
  cursor: pointer;
  position: relative;

  &:before,
  &:before {
    content: '';
    position: absolute;
    z-index: 50;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    transition: all 0.3s ease-in;
    border: 1px solid ${(props) => (props.checked ? palette.accent.main : palette.white.main)};
    border-radius: 100%;
    background: ${(props) => (props.checked ? palette.accent.main : 'transparent')};
  }

  &:after,
  &:after {
    content: '';
    width: 6px;
    height: 6px;
    background: #fff;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  &:not(&:checked):after {
    opacity: 0;
    border: 1px solid ${palette.white.main};
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  &:checked:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const RadioButtonBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const RadioButtonText = styled.p`
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
`;
const RadioButton: FC<RadioButtonProps> = ({ checked, disabled, name, onChange, text, value, onClick }) => {
  return (
    <RadioButtonBox onClick={() => onClick && onClick(value ?? '')}>
      <Input id='value' type='radio' checked={checked} disabled={disabled} name={name} value={value} onChange={onChange} />
      <label htmlFor={value}>
        <RadioButtonText>{text}</RadioButtonText>
      </label>
    </RadioButtonBox>
  );
};

export default RadioButton;
