import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { SelectItem } from "components/molecules/Select/Select.interface";
import { CountryCode } from "libphonenumber-js";
import { StartApplicationData } from "pages/PrimeTrust/PrimeTrust.interfaces";
import { IdentityControllerApi, KycPrimeControllerApi } from "services";
import {
  FullUserIdentityStatus,
  InputData,
  KycResponse,
  KycResponsePost,
  RequestInputDataRoot,
  UserIdentityInfoResponse,
} from "services/models";
import { RootState } from "state/store";

const kycAPI = new KycPrimeControllerApi();
const identityAPI = new IdentityControllerApi();
interface PrimeTrustState {
  country?: SelectItem;
  identification?: SelectItem;
  countryError: string | undefined;
  code: CountryCode;
  callingCode: string;
  flag: string;
  photoSize: { width: number; height: number };
  firstPhoto: string;
  secondPhoto: string;
  baseKycData: KycResponse | undefined;
  startApplicationData: StartApplicationData;
  userData: FullUserIdentityStatus;
  submitedData: InputData[];
}

const initialState = {} as PrimeTrustState;

export const getKycPrimeStep = createAsyncThunk(
  "primetrust/getKycPrimeStep",
  async (_: void, { getState }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    const response = (await kycAPI.nextStep1(
      token
    )) as AxiosResponse<KycResponsePost>;

    return response.data.kycResponse;
  }
);

export const postKycPrimeStep = createAsyncThunk(
  "primetrust/postKycPrimeStep",
  async (body: RequestInputDataRoot, { getState }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    const response = (await kycAPI.nextStep(
      body,
      token
    )) as AxiosResponse<KycResponsePost>;

    return response.data.kycResponse;
  }
);

export const postKycPrimePhotosStep = createAsyncThunk(
  "primetrust/postKycPrimeStep",
  async (body: { inputData?: string; photo?: Array<Blob> }, { getState }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    const response = (await kycAPI.uploadForm(
      token,
      body.inputData,
      body.photo
    )) as AxiosResponse<KycResponsePost>;

    return response.data.kycResponse;
  }
);

export const getUserPersonalInfoData = createAsyncThunk(
  "primetrust/getUserPersonalInfoData",
  async (_: void, { getState }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    const response = (await identityAPI.getStatus(
      token
    )) as AxiosResponse<UserIdentityInfoResponse>;

    return response.data.identityStatus;
  }
);

export const primeTrustSlice = createSlice({
  name: "prime-trust",
  initialState,
  reducers: {
    reset: () => initialState,
    setCountry: (state, action: PayloadAction<SelectItem | undefined>) => {
      state.country = action.payload;
    },
    setStartApplicationData: (
      state,
      action: PayloadAction<StartApplicationData>
    ) => {
      state.startApplicationData = action.payload;
    },
    setIdentification: (
      state,
      action: PayloadAction<SelectItem | undefined>
    ) => {
      state.identification = action.payload;
    },
    setCode: (state, action: PayloadAction<CountryCode>) => {
      state.code = action.payload;
    },
    setCallingCode: (state, action: PayloadAction<string>) => {
      state.callingCode = action.payload;
    },
    setFlag: (state, action: PayloadAction<string>) => {
      state.flag = action.payload;
    },
    setPhotoSize: (
      state,
      { payload }: PayloadAction<{ width: number; height: number }>
    ) => {
      state.photoSize = payload;
    },
    setFirstPhoto: (state, action: PayloadAction<string>) => {
      state.firstPhoto = action.payload;
    },
    setSecondPhoto: (state, action: PayloadAction<string>) => {
      state.secondPhoto = action.payload;
    },
    setData: (state, action: PayloadAction<InputData[]>) => {
      state.submitedData = action.payload;
    },
  },
  extraReducers: {
    [getUserPersonalInfoData.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<FullUserIdentityStatus>
    ) => {
      state.userData = payload;
    },
  },
});

export const {
  setStartApplicationData,
  setCountry,
  setCode,
  setCallingCode,
  setFlag,
  setIdentification,
  setPhotoSize,
  setFirstPhoto,
  setSecondPhoto,
  setData,
} = primeTrustSlice.actions;

export const primeTrustSelector = createSelector(
  (state: RootState) => state["prime-trust"],
  (primeTrust: PrimeTrustState) => primeTrust
);

export const primeTrustReducer = primeTrustSlice.reducer;
