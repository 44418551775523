import React, { useState } from 'react'
import { RateOfReturn, useGetRateOfReturnsQuery } from 'state/store/custodyApi'
import { Chart } from "react-google-charts";
import styled from 'styled-components'
import Modal from 'components/organisms/Modal'
import { TypesNamesEnum } from 'enums/Button.enum'
import { DefiBalanceRow } from 'state/store/investApi';
import { Percentage } from 'lib/utils/types';
import Tooltip from 'components/atoms/Tooltip/Tooltip';
import QuestionCircle from 'assets/icons/QuestionCircle';
import moment from 'moment';

interface Props {
  defiBalanceRow: DefiBalanceRow
}
const CustodyRateOfReturns: React.FC<Props> = ({defiBalanceRow}) => {
  const rateOfReturns = useGetRateOfReturnsQuery(defiBalanceRow.identifier)
  const [visible, setVisible] = useState(false)
  const returns = [...rateOfReturns?.data ?? []]
  
  const getMonthName = (yearMonth: string) => {
    const month = yearMonth.split('-')[1]
    const date = new Date(2024, +month - 1);
    return date.toLocaleString('default', { month: 'short' });
  }
  
  const format = (rateOfReturn: RateOfReturn) => {
    const month = getMonthName(rateOfReturn.month)
    const rate = +rateOfReturn.rate*100
    return {
      month, rate
    }
  }

  const formatMonth = (month: string | undefined) : string => {
    if (!!month) {
      return moment.utc(month).format("MMM YYYY");
    }
    return '';
  }
  const rateOfReturnText = (firstMonth: string | undefined, lastMonth: string | undefined) : string => {
    const prefix = "Rate of Return is your actual return ";
    const sufix = " after taking into account both income and fees."
    if (firstMonth === undefined || lastMonth === undefined) {
      return prefix + "during your investment period," + sufix;
    } if (firstMonth === lastMonth) {
      return prefix + "of " + formatMonth(firstMonth) + sufix;
    }
    return prefix + "between " + formatMonth(firstMonth) + " and " + formatMonth(lastMonth) + sufix;
  }

  const rawRateOfReturn = defiBalanceRow?.return?.rate;
  const rateOfReturn = (!!rawRateOfReturn) ? new Percentage(rawRateOfReturn).print() : null;
  const firstMonth = defiBalanceRow?.return?.firstMonth;
  const lastMonth = defiBalanceRow?.return?.lastMonth;
  returns?.sort((a,b) => a.month.localeCompare(b.month))
  const result = returns.map(format).map(r => [r.month, r.rate, `${r.rate.toFixed(2)}%`])
  return <ParentContainer onClick={() => setVisible(!visible)}>
    {rateOfReturn != null && 
      <>
        <Tooltip text={rateOfReturnText(firstMonth, lastMonth)} placement='top'>
          <HoverArea >
            <Button>{rateOfReturn}</Button>
            <QuestionCircle color='#fffc' />
          </HoverArea>
        </Tooltip>
      </>
    }
    {rateOfReturn == null && <>-</>}
    <Modal visible={visible} onClose={() => setVisible(false)} header='Rate of Return'>
      <Modal.Body>
        <Container hidden={!visible}>
        <Chart
          className='chart'
          chartType="LineChart"
          data={[
            [{type: "string", label: "Month"}, {type: "number", label:"Rate"}, { type: 'string', 'role': 'tooltip', 'p': {'html': true}}]
          , ...result]} 
          width="100%"
          height="400px"
          options={{
            legend: 'none',
            colors: ['#6F2ACD'],
            backgroundColor: 'transparent',
            hAxis: {
              title: 'Month',
              textStyle: {
                color: 'white'
              },
              titleTextStyle: {
                color: 'white'
              }
            },
            vAxis: {
              title: 'Rate (%)',
              textStyle: {
                color: 'white'
              },
              titleTextStyle: {
                color: 'white'
              }
            }
          }}
        />
        </Container>
      </Modal.Body>
    </Modal>
  </ParentContainer>
}

const Button = styled.button`
  background-color: #6f2acdcc;
  border-radius: 4px;
  color: white;
  font-size: 0.7rem;
  margin-left: 2px;
  border: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in;
  padding: 2px;
  &:hover {
    background-color: rgba(163, 153, 246, 0.8);
  }
`

const ParentContainer = styled.div`
`
const Container = styled.div`
  padding: 8px;
  width: 500px;
  height: 500px;
  border-radius: 8px;
  position: relative;
`

const HoverArea = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
export default CustodyRateOfReturns
