import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Earn: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7V2H22V20H16V7ZM14 6V1C14 0.447716 14.4477 0 15 0H23C23.5523 0 24 0.447715 24 1V21C24 21.5523 23.5523 22 23 22H15H8H1C0.447715 22 0 21.5523 0 21V12C0 11.4477 0.447715 11 1 11H7V7C7 6.44772 7.44772 6 8 6H14ZM14 8H9V12V20H14V8ZM2 13H7V20H2V13Z"
      fill={color}
    />
  </svg>
);

export default Earn;
