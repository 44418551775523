import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssetsControllerApi, TransactionsControllerApi } from "services";
import {
  Assets,
  TransferRequest,
  TransferTemplateResponse,
  TransferDepositProcessRequest,
  InterestBearingWithdrawalRequest,
} from "services/models";
import { RootState } from "state/store";

interface TransferState {
  holdings: Assets[];
  depostiAssets: Assets[];
  holdingInterest: Assets[]; 
  interestRates: Assets[];
  receive: Assets | undefined;
  destination: Assets | undefined;
  receiveValue: string;
  receiveAmount: number;
  lastTab: string;
  lastStep: string;
  currentStep: string;
  transfer: any;
  errorMessage: string | undefined;
  errorMessageConfirm: string | undefined;
  transferTemplate: TransferTemplateResponse;
  loading: boolean;
  assetsLoading: boolean;
}

const AssetsService = new AssetsControllerApi();
const TransactionsService = new TransactionsControllerApi();

const initialState = {
  receive: undefined,
  destination: undefined,
  currentStep: "Transfer",
  lastStep: "Transfer",
  lastTab: "From Trade",
  errorMessage: undefined,
  errorMessageConfirm: undefined,
  loading: false,
  assetsLoading: false,
} as TransferState;

export const transferSelector = createSelector(
  (state: RootState) => state.transfer,
  (transfer: TransferState) => transfer
);

export const transferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    reset: () => initialState,
    setLoading:  (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAssetsLoading:  (state, action: PayloadAction<boolean>) => {
      state.assetsLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.errorMessage = action.payload;
    },
    setErrorConfirm: (state, action: PayloadAction<string | undefined>) => {
      state.errorMessageConfirm = action.payload;
    },
    setLastTab: (state, action: PayloadAction<string>) => {
      state.lastTab = action.payload;
    },
    setLastStep: (state, action: PayloadAction<string>) => {
      state.lastStep = action.payload;
    },
    setReceiveAsset: (state, action: PayloadAction<Assets>) => {
      state.receive = { ...action.payload };
    },
    setDestinationAsset: (state, action: PayloadAction<Assets>) => {
      state.destination = { ...action.payload };
    },
    transfer: (state) => {
      state.receive = undefined;
      state.destination = undefined;
    },
    changeStep: (
      state,
      action: PayloadAction<{ step: string }>
    ) => {
      state.lastStep = state.currentStep;
      state.currentStep = action.payload.step;
    },
    changeReceiveAmount: (
      state,
      action: PayloadAction<{ amount: number; value: string }>
    ) => {
      state.receiveAmount = action.payload.amount;
      state.receiveValue = action.payload.value;
    },
    setHoldings: (state, action: PayloadAction<Assets[]>) => {
      state.holdings = action.payload;
    },
    setDepositAssets: (state, action: PayloadAction<Assets[]>) => {
      state.depostiAssets = action.payload;
    },
    setHoldingInterest: (state, action: PayloadAction<Assets[]>) => {
      state.holdingInterest = action.payload;
    },
    setInterestRates: (state, action: PayloadAction<Assets[]>) => {
      state.interestRates = action.payload;
    },
    setTransferTemplate: (
      state,
      action: PayloadAction<TransferTemplateResponse>
    ) => {
      state.transferTemplate = action.payload;
    },
  },
});


export const transferReducer = transferSlice.reducer;

export const getHoldings = (
  sortField: string = "allocation",
  sortDirection: string = "desc",
  page: string = "1",
  size: string = "10000",
) => async (dispatch: any, getState: any) => {
  try {
    dispatch(setAssetsLoading(true))
    const { token } = getState().auth;

    const { data } = await AssetsService.getHoldings(token, sortField, sortDirection, page, size);
    const assets: Assets[] = data?.assets ?? new Array(0);

    dispatch(setHoldings(assets.filter(asset => parseFloat(asset?.totalBalance) ?? -1 > 0)));
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(setAssetsLoading(false))
  }
};

export const getDepositAssets = (
  confiurationProperty: string,
  sortField?: string,
  sortDirection?: string,
  page: string = "1",
  size: string = "10000",
) => async (dispatch: any, getState: any) => {
  try {
    dispatch(setAssetsLoading(true))
    const { token } = getState().auth;

    const { data } = await AssetsService.getAssetsByProperty(token, confiurationProperty, sortField, sortDirection, page, size);
    const assets: Assets[] = data?.assets ?? new Array(0);

    dispatch(setDepositAssets(assets));
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(setAssetsLoading(false))
  }
};

export const getHoldingInterest = (
  sortField?: string,
  sortDirection?: string,
  page: string = "1",
  size: string = "10000",
) => async (dispatch: any, getState: any) => {
  try {
    dispatch(setAssetsLoading(true))
    const { token } = getState().auth;

    const { data } = await AssetsService.getHoldingInterest(token, sortField, sortDirection, page, size);
    const assets: Assets[] = data?.assets ?? new Array(0);

    dispatch(setHoldingInterest(assets.filter(asset => asset?.convenienceCurrencyBalance ?? -1 > 0)));
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(setAssetsLoading(false))
  }
};

export const getInterestRates = (
  sortField?: string,
  sortDirection?: string,
  page: string = "1",
  size: string = "10000",
) => async (dispatch: any, getState: any) => {
  try {
    dispatch(setAssetsLoading(true))
    const { token } = getState().auth;

    const { data } = await AssetsService.getInterestRates(token, sortField, sortDirection, page, size);
    const assets: Assets[] = data?.assets ?? new Array(0);

    dispatch(setInterestRates(assets.filter(asset => asset?.convenienceCurrencyBalance ?? -1 > 0)));
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(setAssetsLoading(false))
  }
};

export const transactionDepositTemplate =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true))
      const {
        transfer: { receive, destination, receiveAmount },
        auth: { token },
      } = getState();

      const body = {
        amount: receiveAmount,
        destinationAsset: destination?.identifier,
        asset: receive?.identifier,
      } as TransferRequest;
      const { data } = await TransactionsService.transactionDepositTemplate(
        body,
        token
      );

      if (data.code === "0") {
        dispatch(setTransferTemplate(data));
        if (data.message === "OK") dispatch(changeStep({ step: "Review" }));
      } else {
        dispatch(setError(data.message ?? undefined));
      }
    } catch (error: any) {
      dispatch(setError(error.toString()));
    } finally {
      dispatch(setLoading(false))
    }
  };

  export const processDeposit =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true))
      const {
        transfer: { transferTemplate },
        auth: { token },
      } = getState();
      const body = {
        transactionUID: transferTemplate?.transaction?.transactionId,
      } as TransferDepositProcessRequest;
      const { data } = await TransactionsService.processDeposit(
        body,
        token
      );

      if (data.code === "0") {
        dispatch(changeStep({ step: "Proccessing" }));
      } else {
        dispatch(setError(data.message ?? undefined));
      }
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false))
    }
  };

  export const interestBearingAssetWithdrawal =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true))
      const {
        transfer: { receive, receiveAmount },
        auth: { token },
      } = getState();
      const body = {
        amount: receiveAmount,
        asset: receive?.identifier,
      } as InterestBearingWithdrawalRequest;
      const { data } = await TransactionsService.interestBearingAssetWithdrawal(
        body,
        token
      );

      if (data.code === "0") {
        dispatch(changeStep({ step: "Proccessing" }));
      } else {
        dispatch(setError(data.message ?? undefined));
      }
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false))
    }
  };

export const {
  reset,
  setLoading,
  setAssetsLoading,
  setLastTab,
  setLastStep,
  transfer,
  changeStep,
  changeReceiveAmount,
  setHoldings,
  setDepositAssets,
  setHoldingInterest,
  setInterestRates,
  setReceiveAsset,
  setDestinationAsset,
  setError,
  setErrorConfirm,
  setTransferTemplate,
} = transferSlice.actions;
