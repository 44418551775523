import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const DownArrowMid: FC<ISVG> = ({
  size = 16,
  width = size,
  height = size,
  color,
  svgProps,
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.95049 10.9207C7.80049 10.9207 7.6495 10.8757 7.5205 10.7847L1.32049 6.44467C0.980486 6.20767 0.898488 5.73966 1.13549 5.40066C1.37249 5.05966 1.83949 4.97767 2.17949 5.21567L7.95049 9.25467L13.7215 5.21567C14.0605 4.97867 14.5295 5.06066 14.7655 5.40066C15.0025 5.73966 14.9205 6.20767 14.5805 6.44467L8.38048 10.7847C8.25148 10.8757 8.10049 10.9207 7.95049 10.9207Z"
        fill="white"
      />
    </svg>
  );
};
export default DownArrowMid;
