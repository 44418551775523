import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const UnUsedPhoto: FC<ISVG> = ({
  size = 117,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 116 117"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M17 116.5H99C108.374 116.5 116 108.874 116 99.5V17.5C116 8.126 108.374 0.5 99 0.5H17C7.626 0.5 0 8.126 0 17.5V99.5C0 108.874 7.626 116.5 17 116.5ZM99 114.5H17C11.936 114.5 7.45903 111.971 4.74103 108.116L114 31.409V99.5C114 107.771 107.271 114.5 99 114.5ZM17 2.5H99C104.373 2.5 109.081 5.34798 111.73 9.60498C111.672 9.63098 111.611 9.64303 111.557 9.68103L2 86.597V17.5C2 9.229 8.729 2.5 17 2.5ZM2 89.041L112.665 11.347C113.515 13.227 114 15.306 114 17.5V28.966L3.698 106.406C2.619 104.336 2 101.99 2 99.5V89.041Z"
      fill={color}
    />
    <path
      d="M8 80.33C8.552 80.33 9 79.883 9 79.33V19.5C9 13.986 13.486 9.5 19 9.5H20V20.812C20 21.77 20.441 22.647 21.209 23.219C21.736 23.612 22.357 23.815 22.992 23.815C23.282 23.815 23.575 23.772 23.862 23.686L29.862 21.886C31.14 21.502 32 20.347 32 19.012V9.5H97C99.566 9.5 102.01 10.479 103.88 12.257C104.28 12.639 104.914 12.622 105.294 12.221C105.675 11.822 105.659 11.188 105.258 10.808C103.014 8.67498 100.082 7.5 97 7.5H19C12.383 7.5 7 12.883 7 19.5V79.33C7 79.883 7.448 80.33 8 80.33ZM30 19.012C30 19.457 29.713 19.842 29.288 19.97L23.288 21.77C22.98 21.864 22.66 21.805 22.404 21.615C22.148 21.425 22.001 21.132 22.001 20.812V9.5H30.001V19.012H30Z"
      fill={color}
    />
    <path
      d="M108 37.6758C107.448 37.6758 107 38.1228 107 38.6758V97.4998C107 103.014 102.514 107.5 97.0002 107.5H19.0002C16.7092 107.5 14.4692 106.703 12.6922 105.256C12.2652 104.907 11.6342 104.973 11.2852 105.4C10.9362 105.829 11.0012 106.459 11.4292 106.807C13.5612 108.544 16.2502 109.5 18.9992 109.5H96.9992C103.616 109.5 108.999 104.117 108.999 97.4998V38.6758C109 38.1228 108.552 37.6758 108 37.6758Z"
      fill={color}
    />
    <path
      d="M103.838 23.3223C104.036 23.3223 104.237 23.2633 104.412 23.1403L109.677 19.4443C110.129 19.1273 110.238 18.5033 109.921 18.0513C109.604 17.5993 108.981 17.4883 108.528 17.8073L103.263 21.5033C102.811 21.8203 102.702 22.4443 103.019 22.8963C103.213 23.1743 103.523 23.3223 103.838 23.3223Z"
      fill={color}
    />
    <path
      d="M60.3911 53.8242C60.5891 53.8242 60.7901 53.7652 60.9651 53.6422L91.9821 31.8662C92.4341 31.5492 92.5431 30.9252 92.2261 30.4732C91.9091 30.0212 91.2851 29.9102 90.8331 30.2292L59.816 52.0052C59.364 52.3222 59.2551 52.9462 59.5721 53.3982C59.7661 53.6762 60.0761 53.8242 60.3911 53.8242Z"
      fill={color}
    />
    <path
      d="M7.18187 90.181C7.37587 90.458 7.68584 90.607 8.00084 90.607C8.19884 90.607 8.39988 90.5479 8.57488 90.4249L33.0359 73.2509C33.4879 72.9339 33.5969 72.3099 33.2799 71.8579C32.9629 71.4059 32.3399 71.295 31.8869 71.614L7.42589 88.788C6.97389 89.105 6.86387 89.729 7.18187 90.181Z"
      fill={color}
    />
    <path
      d="M57 19.5C44.318 19.5 34 29.817 34 42.5C34 47.936 35.936 53.213 39.451 57.36C39.649 57.593 39.93 57.714 40.214 57.714C40.443 57.714 40.672 57.636 40.86 57.477C41.281 57.121 41.334 56.489 40.976 56.068C37.768 52.28 36 47.462 36 42.5C36 30.921 45.42 21.5 57 21.5C64.485 21.5 71.461 25.538 75.205 32.038C75.48 32.517 76.092 32.682 76.571 32.405C77.05 32.13 77.214 31.518 76.939 31.04C72.838 23.922 65.199 19.5 57 19.5Z"
      fill={color}
    />
    <path
      d="M34.1841 91.5H89.0001C91.7571 91.5 94.0001 89.257 94.0001 86.5V75.5C94.0001 72.743 91.7571 70.5 89.0001 70.5H61.2471C60.6951 70.5 60.2471 70.947 60.2471 71.5C60.2471 72.053 60.6951 72.5 61.2471 72.5H89.0001C90.6541 72.5 92.0001 73.846 92.0001 75.5V86.5C92.0001 88.154 90.6541 89.5 89.0001 89.5H34.1841C33.6321 89.5 33.1841 89.947 33.1841 90.5C33.1841 91.053 33.6311 91.5 34.1841 91.5Z"
      fill={color}
    />
    <path
      d="M43 99.1035C42.448 99.1035 42 99.5505 42 100.104C42 100.657 42.448 101.104 43 101.104H73C73.552 101.104 74 100.657 74 100.104C74 99.5505 73.552 99.1035 73 99.1035H43Z"
      fill={color}
    />
    <path
      d="M89 86.5V75.5C89 74.947 88.552 74.5 88 74.5C87.448 74.5 87 74.947 87 75.5V86.5C87 87.053 87.448 87.5 88 87.5C88.552 87.5 89 87.053 89 86.5Z"
      fill={color}
    />
  </svg>
);

export default UnUsedPhoto;
