import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const DefaultAvatar: FC<ISVG> = ({
  size = 120,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M0 20.5C0 9.45431 8.95431 0.5 20 0.5C31.0457 0.5 40 9.45431 40 20.5C40 31.5457 31.0457 40.5 20 40.5C8.95431 40.5 0 31.5457 0 20.5Z"
      fill={color}
      fillOpacity="0.1"
    />
    <path
      d="M25.7541 22.5002C26.9961 22.5002 28.0029 23.507 28.0029 24.7491V25.3245C28.0029 26.2188 27.6833 27.0836 27.1018 27.763C25.5324 29.5965 23.1453 30.5013 19.9999 30.5013C16.854 30.5013 14.468 29.5962 12.9017 27.7619C12.3219 27.083 12.0034 26.2195 12.0034 25.3267V24.7491C12.0034 23.507 13.0103 22.5002 14.2523 22.5002H25.7541ZM25.7541 24.0002H14.2523C13.8387 24.0002 13.5034 24.3355 13.5034 24.7491V25.3267C13.5034 25.8624 13.6945 26.3805 14.0424 26.7878C15.2957 28.2555 17.2616 29.0013 19.9999 29.0013C22.7382 29.0013 24.7058 28.2555 25.9623 26.7876C26.3112 26.3799 26.5029 25.861 26.5029 25.3245V24.7491C26.5029 24.3355 26.1676 24.0002 25.7541 24.0002ZM19.9999 10.5049C22.7613 10.5049 24.9999 12.7435 24.9999 15.5049C24.9999 18.2663 22.7613 20.5049 19.9999 20.5049C17.2384 20.5049 14.9999 18.2663 14.9999 15.5049C14.9999 12.7435 17.2384 10.5049 19.9999 10.5049ZM19.9999 12.0049C18.0669 12.0049 16.4999 13.5719 16.4999 15.5049C16.4999 17.4379 18.0669 19.0049 19.9999 19.0049C21.9329 19.0049 23.4999 17.4379 23.4999 15.5049C23.4999 13.5719 21.9329 12.0049 19.9999 12.0049Z"
      fill={color}
    />
  </svg>
);

export default DefaultAvatar;
