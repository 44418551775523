import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Boost: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0123 4.64558C18.5822 4.64266 15.2282 5.65764 12.3748 7.5621C9.52133 9.46656 7.29665 12.1749 5.98219 15.3445C4.66773 18.5141 4.32254 22.0026 4.99031 25.3685C5.65807 28.7344 7.30878 31.8266 9.7336 34.2537C12.1584 36.6809 15.2484 38.334 18.6125 39.004C21.9767 39.6739 25.4639 39.3306 28.633 38.0174C31.802 36.7042 34.5106 34.4801 36.4159 31.6266C38.3212 28.7731 39.3376 25.4183 39.3367 21.9868C39.3315 17.3909 37.5049 12.9846 34.2573 9.73389C31.0098 6.48319 26.6063 4.65336 22.0123 4.64558Z"
      fill="#FFA519"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9976 39.3378C24.2637 39.3378 26.5192 38.8933 28.6331 38.0174C31.8022 36.7042 34.5107 34.4801 36.416 31.6266C38.3213 28.7731 39.3378 25.4183 39.3368 21.9868C39.3316 17.3909 37.505 12.9846 34.2575 9.73389C31.0099 6.48319 26.6065 4.65336 22.0125 4.64558C22.0075 4.64557 22.0025 4.64557 21.9976 4.64557V39.3378Z"
      fill="#D08715"
    />
    <path
      d="M22.8263 2.9924C26.5553 3.15482 30.1541 4.41181 33.1738 6.60665C36.1935 8.80148 38.5006 11.837 39.8074 15.3348L42.6613 14.4077C41.1554 10.3095 38.4656 6.75165 34.9339 4.18626C31.4021 1.62087 27.1878 0.163736 22.8263 0V2.9924Z"
      fill="#FFA519"
    />
    <path
      d="M40.3173 16.8997C41.3152 20.4984 41.2316 24.3115 40.077 27.863C38.9225 31.4145 36.748 34.5473 33.825 36.8703L35.5853 39.2936C39.0196 36.599 41.5742 32.9425 42.9235 28.79C44.2727 24.6375 44.3555 20.1772 43.1614 15.9774L40.3173 16.8997Z"
      fill="#FFA519"
    />
    <path
      d="M4.19273 15.3348C5.49982 11.8363 7.80765 8.80023 10.8283 6.60533C13.849 4.41043 17.4488 3.15387 21.1787 2.9924V0C16.8169 0.162443 12.6019 1.61908 9.06995 4.18464C5.53795 6.7502 2.84853 10.3087 1.34375 14.4077L4.19273 15.3348Z"
      fill="#FFA519"
    />
    <path
      d="M32.4961 37.8416C29.3831 39.9022 25.7328 41.001 22 41.001C18.2672 41.001 14.6169 39.9022 11.5039 37.8416L9.7533 40.2649C13.3784 42.6997 17.6459 44 22.0122 44C26.3786 44 30.6461 42.6997 34.2712 40.2649L32.4961 37.8416Z"
      fill="#FFA519"
    />
    <path
      d="M10.175 36.8703C7.25032 34.5487 5.07491 31.4159 3.92099 27.8637C2.76707 24.3115 2.68588 20.4978 3.68759 16.8997L0.838616 15.9774C-0.355544 20.1772 -0.272724 24.6375 1.07652 28.79C2.42577 32.9425 4.98033 36.599 8.41464 39.2936L10.175 36.8703Z"
      fill="#FFA519"
    />
    <path
      d="M25.1849 17.5178V16.5367C25.1871 15.7303 24.9882 14.9362 24.6062 14.2262L23.6255 12.3768L22.2623 9.8602C22.241 9.82019 22.2092 9.78673 22.1703 9.76341C22.1315 9.74008 22.087 9.72775 22.0417 9.72775C21.9963 9.72775 21.9519 9.74008 21.913 9.76341C21.8741 9.78673 21.8423 9.82019 21.821 9.8602L20.4529 12.3817L19.4477 14.2311C19.063 14.9403 18.8624 15.7347 18.8641 16.5416V17.5227V27.5938H25.2388L25.1849 17.5178ZM20.4676 16.2865C20.49 15.8873 20.6643 15.5117 20.9547 15.237C21.2452 14.9623 21.6297 14.8092 22.0294 14.8092C22.4291 14.8092 22.8137 14.9623 23.1041 15.237C23.3945 15.5117 23.5688 15.8873 23.5912 16.2865H20.4676Z"
      fill="white"
    />
    <path
      d="M23.3704 28.4965L22.831 31.3466L22.1642 34.8492C22.0612 35.3986 21.8945 35.3986 21.7915 34.8492L21.1442 31.3417L20.6146 28.4915L23.3704 28.4965Z"
      fill="white"
    />
    <path
      d="M17.2166 19.22C16.4674 20.5874 15.4964 21.8208 14.3431 22.8698C14.0281 23.1313 13.7744 23.4588 13.5998 23.8293C13.4253 24.1997 13.3342 24.604 13.333 25.0135V26.9757H17.197L17.2166 19.22Z"
      fill="white"
    />
    <path
      d="M26.813 19.2445C27.5574 20.6154 28.5234 21.8536 29.6718 22.909C29.9831 23.173 30.2324 23.5024 30.4019 23.8738C30.5714 24.2452 30.657 24.6494 30.6525 25.0576V27.0199H26.7885L26.813 19.2445Z"
      fill="white"
    />
  </svg>
);

export default Boost;
