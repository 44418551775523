import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { SwapFrequency } from "constant/constant";
import {
  AssetsControllerApi,
  BankControllerApi,
  TransactionsControllerApi,
} from "services";
import { OrdersControllerApi } from "services/apis/orders-controller-api";
import { RecurringTransactionsControllerApi } from "services/apis/recurring-transactions-controller-api";
import {
  Assets,
  BankAccount,
  DepositConfirmationRequest,
  DepositRequest,
  DepositTemplateResponse,
  ExchangeRate,
  OrdersRatesResponse,
  OrderTemplateRequest,
  OrderTemplateResponse,
  OrderTemplateSubmitRequest,
  RecurringDepositRequest,
  RecurringExchangeRequest,
  TransactionRateRequest,
  TransactionSubmitRequest,
  TransactionTemplateRequest,
  TransactionTemplateResponse,
  ValidateDataResponse,
  ValidateOrderRequest,
  ValidateOrderResponse,
} from "services/models";
import { fixedTo } from "shared";
import { RootState } from "state/store";

const AssetsService = new AssetsControllerApi();
const BankService = new BankControllerApi();
const TransactionsService = new TransactionsControllerApi();
const RecurringTransactionsService = new RecurringTransactionsControllerApi();
const OrderService = new OrdersControllerApi();

const initialState = {
  currentStep: "Swap",
  lastStep: "Buy",
  lastTab: "Buy",
  canReview: false,
  volumeWarning: false,

  receiveBuy: undefined,
  paywithBuy: undefined,
  bankAccount: undefined,
  bankAccountSelected: false,
  marketPriceBuy: true,
  priceSafetyBuy: true,
  errorMessageBuy: undefined,
  errorMessageConfirmBuy: undefined,
  frequencyBuy: "",

  needsKyc: false,
  assetsExchangeLoading: false,
} as TradeWidgetState;

export const getAssetsExchange = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setAssetsExchangeLoading(true));

    const { token } = getState().auth;
    const { data } = await AssetsService.getAssetsByProperty(
      token,
      "convert_asset_options",
      "assetName",
      "asc",
      "1",
      "1000"
    );

    const assets: Assets[] = data?.assets ?? new Array(0);

    dispatch(setAssetsExchange(assets));
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(setAssetsExchangeLoading(false));
  }
};

export const getDepositCryptoAssets =
  () => async (dispatch: any, getState: any) => {
    try {
      const { token } = getState().auth;
      const { data } = await AssetsService.getAssetsByProperty(
        token,
        "deposit_crypto_options",
        "assetLongName",
        "asc",
        "1",
        "1000"
      );

      const assets: Assets[] = data?.assets ?? new Array(0);

      dispatch(setDepositCryptoAssets(assets));
    } catch (error) {
      console.warn(error);
    }
  };

export const getHoldings = () => async (dispatch: any, getState: any) => {
  try {
    const { token } = getState().auth;

    const { data } = await AssetsService.getHoldings(token);
    const assets: Assets[] = data?.assets ?? new Array(0);

    dispatch(setHoldings(assets));
  } catch (error) {
    console.warn(error);
  }
};

export const getWatchlists = () => async (dispatch: any, getState: any) => {
  try {
    const { token } = getState().auth;

    const { data } = await AssetsService.getWatchlist(token);
    const assets: Assets[] = data?.assets ?? new Array(0);

    dispatch(setWatchlists(assets));
  } catch (error) {
    console.warn(error);
  }
};

export const getConversionRate =
  (origin: string, destination: string) =>
  async (dispatch: any, getState: () => RootState) => {
    const {
      auth: { token },
    } = getState();
    try {
      const {
        data: { rate },
      } = await AssetsService.getConversionRate(token, origin, destination);
      dispatch(setConversionRateBuy(rate));
    } catch (error) {
      console.warn(error);
    }
  };

// export const getOrderRate =
//   (origin: string, destination: string) =>
//   async (dispatch: any, getState: () => RootState) => {
//     const {
//       auth: { token },
//       tradeWidget: { depositCryptoAssets },
//     } = getState();
//     try {
//       const { data } = await OrderService.getOrdersRates(
//         token,
//         destination,
//         origin
//       );

//       dispatch(setOrderRate(data));
//     } catch (error) {}
//   };

export const getOrderRate = createAsyncThunk(
  "tradeWidget/getOrderRate",
  async (
    request: { origin: string; destination: string },
    { getState, rejectWithValue }
  ) => {
    const {
      auth: { token },
    } = getState() as RootState;
    try {
      const { data } = await OrderService.getOrdersRates(
        token,
        request.origin,
        request.destination
      );

      return data;
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.data) return rejectWithValue(error.response.data);
    }
  }
);

export const orderValidate = createAsyncThunk(
  "tradeWidget/orderValidateData",
  async (request: OrderTemplateRequest, { getState, rejectWithValue }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    try {
      const { data } = await OrderService.validateOrderData(request, token);
      return data;
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.data?.message)
        return rejectWithValue(error.response.data.message);
    }
  }
);

export const orderWarnings = createAsyncThunk(
  "tradeWidget/orderWarnings",
  async (request: ValidateOrderRequest, { getState, rejectWithValue }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    try {
      const { data } = await OrderService.validateOrderBeforeSend(
        request,
        token
      );
      return data;
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.data?.message)
        return rejectWithValue(error.response.data.message);
    }
  }
);

export const orderRequestTemplate = createAsyncThunk(
  "tradeWidget/orderRequestTemplate",
  async (request: OrderTemplateRequest, { getState, rejectWithValue }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    try {
      const { data } = await OrderService.createOrderTemplate(request, token);

      return data;
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.data?.message)
        return rejectWithValue(error.response.data.message);
    }
  }
);

export const orderSubmitRequest = createAsyncThunk(
  "tradeWidget/orderSubmitRequest",
  async (
    request: OrderTemplateSubmitRequest,
    { getState, rejectWithValue }
  ) => {
    const {
      auth: { token },
    } = getState() as RootState;
    try {
      const { data } = await OrderService.submitOrderTemplate(request, token);
      return data;
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.data?.message)
        return rejectWithValue(error.response.data.message);
    }
  }
);

export const getTransactionsRate =
  () => async (dispatch: any, getState: () => RootState) => {
    const {
      tradeWidget: { receiveBuy, paywithBuy, payWithAmountBuy, bankAccount },
      auth: { token, user },
    } = getState();

    try {
      dispatch(reviewing(true));

      let receiveIdentifier = undefined;
      let paywithIdentifier = undefined;

      receiveIdentifier = receiveBuy?.identifier;
      paywithIdentifier =
        bankAccount?.minimumDeposit?.currency ?? user.convenienceCurrency.value;

      if (paywithBuy?.bank) {
        const body = {
          destinationCurrency: receiveIdentifier,
          amount: payWithAmountBuy.toString(),
          accountId: paywithBuy?.uid,
        } as DepositRequest;

        const { data } = await BankService.requestDeposit(body, token);

        if (data.code === "0") {
          dispatch(setDeposit(data));
          dispatch(changeCanReview(true));
        } else {
          dispatch(setErrorBuy(data.message ?? undefined));
        }
      } else {
        const body = {
          destination: receiveIdentifier,
          origin: paywithIdentifier,
        } as TransactionRateRequest;

        const { data } = await TransactionsService.getTransactionRate(
          body,
          token
        );

        if (data.code === "0") {
          dispatch(setExchangeTransaction(data));
          dispatch(getTransactionsTemplate());
        } else {
          dispatch(setErrorBuy(data.message ?? undefined));
          dispatch(reviewing(false));
        }
      }
    } catch (err) {
      dispatch(reviewing(false));
      const error = err as AxiosError;
      dispatch(setErrorBuy(error.response?.data.message ?? undefined));
    }
  };

export const getTransactionsTemplate =
  () => async (dispatch: any, getState: () => RootState) => {
    const {
      tradeWidget: { payWithAmountBuy, paywithBuy, exchangeTransaction },
      auth: { token },
    } = getState();
    try {
      dispatch(reviewing(true));
      let paywith: string | undefined = undefined;
      let payWithAmount = undefined;

      paywith = paywithBuy?.identifier;
      payWithAmount = payWithAmountBuy;

      const body = {
        transactionId: exchangeTransaction?.transaction?.transactionId,
        amount: payWithAmount,
        asset: paywith,
      } as TransactionTemplateRequest;

      const { data } = await TransactionsService.getTransactionTemplate(
        body,
        token
      );

      if (data.code === "0") {
        dispatch(setExchangeTransactionTemplate(data));
        dispatch(changeCanReview(true));
      } else {
        dispatch(setErrorBuy(data.message ?? undefined));
      }

      dispatch(reviewing(false));
    } catch (err) {
      dispatch(reviewing(false));
      const error = err as AxiosError;
      dispatch(setErrorBuy(error.response?.data.message ?? undefined));
    }
  };

export const submitTransaction =
  () => async (dispatch: any, getState: () => RootState) => {
    const {
      tradeWidget: {
        exchangeTransactionTemplate: { transaction },
        frequencyBuy,
        frequencySell,
        frequencySwap,
        lastTab,
      },
      auth: { token },
    } = getState();

    try {
      const body = {
        transactionId: transaction?.transactionId,
      } as TransactionSubmitRequest;
      const { data } = await TransactionsService.submitTransaction(body, token);
      let frequency = "";
      frequency = frequencyBuy;

      if (data.code === "0") {
        if (frequency !== SwapFrequency.off.id) {
          const frequencyBody = {
            frequency: frequency,
            currency: transaction?.amountOrigin.currency,
            asset: transaction?.amountDestination.currency,
            amount: transaction?.amountDestination.value,
          } as RecurringExchangeRequest;

          const { data: frequencyResponse } =
            await RecurringTransactionsService.createRecurringExchangeTransaction(
              frequencyBody,
              token
            );
          if (frequencyResponse.code === "0") {
            dispatch(changeCanConfirm(true));
          } else {
            dispatch(setErrorConfirmBuy(data.message ?? undefined));

            dispatch(changeCanConfirm(false));
          }
        } else {
          dispatch(changeCanConfirm(true));
        }
      } else {
        dispatch(setErrorConfirmBuy(data.message ?? undefined));

        dispatch(changeCanConfirm(false));
      }
    } catch (error) {
      console.warn(error);
    }
  };

export const submitDeposit =
  () => async (dispatch: any, getState: () => RootState) => {
    const {
      tradeWidget: { frequencyBuy, depositRequest, paywithBuy },
      auth: { token },
    } = getState();

    try {
      const body = {
        transactionId: depositRequest?.template?.transaction?.transactionId,
        accountId: paywithBuy?.uid,
      } as DepositConfirmationRequest;

      const { data } = await BankService.submitDeposit(body, token);
      let frequency = "";
      frequency = frequencyBuy;

      if (data.code === "0") {
        if (frequency !== SwapFrequency.off.id) {
          const frequencyBody = {
            frequency: frequency,
            currency:
              depositRequest?.template?.transaction?.amountOrigin.currency,
            asset:
              depositRequest?.template?.transaction?.amountDestination.currency,
            amount:
              depositRequest?.template?.transaction?.amountDestination.value,
            bankAccountId: paywithBuy?.uid,
          } as RecurringDepositRequest;

          const { data: frequencyResponse } =
            await RecurringTransactionsService.createRecurringDepositTransaction(
              frequencyBody,
              token
            );
          if (frequencyResponse.code === "0") {
            dispatch(changeCanConfirm(true));
          } else {
            dispatch(setErrorConfirmBuy(data.message ?? undefined));

            dispatch(changeCanConfirm(false));
          }
        } else {
          dispatch(changeCanConfirm(true));
        }
      } else {
        dispatch(setErrorConfirmBuy(data.message ?? undefined));
        dispatch(changeCanConfirm(false));
      }
    } catch (error) {
      console.warn(error);
    }
  };

export const tradeWidgetSlice = createSlice({
  name: "tradeWidget",
  initialState,
  reducers: {
    reset: (state) => {
      state.receiveAmountBuy = initialState.receiveAmountBuy;
      state.payWithAmountBuy = initialState.payWithAmountBuy;
      state.priceAmountBuy = initialState.priceAmountBuy;
      state.fixedPriceBuy = initialState.fixedPriceBuy;
      state.receiveValueBuy = initialState.receiveValueBuy;
      state.payWithValueBuy = initialState.payWithValueBuy;
      state.priceValueBuy = initialState.priceValueBuy;
      state.receiveBuy = initialState.receiveBuy;
      state.paywithBuy = initialState.paywithBuy;
      state.conversionRateBuy = initialState.conversionRateBuy;
      state.marketPriceBuy = initialState.marketPriceBuy;
      state.priceSafetyBuy = initialState.priceSafetyBuy;
      state.errorMessageBuy = initialState.errorMessageBuy;
      state.errorMessageConfirmBuy = initialState.errorMessageConfirmBuy;
      state.frequencyBuy = initialState.frequencyBuy;
      state.frequencyDateBuy = initialState.frequencyDateBuy;
    },
    setLastTab: (state, action: PayloadAction<string>) => {
      state.lastTab = action.payload;
    },
    setLastStep: (state, action: PayloadAction<string>) => {
      state.lastStep = action.payload;
    },
    changeStep: (
      state,
      action: PayloadAction<{ step: string; searchReceive?: boolean }>
    ) => {
      setLastStep(state.currentStep);
      state.currentStep = action.payload.step;
      state.searchReceive = action.payload.searchReceive ?? false;
    },
    setErrorBuy: (state, action: PayloadAction<string | undefined>) => {
      state.errorMessageBuy = action.payload;
    },
    setFrequencyBuy: (state, action: PayloadAction<string>) => {
      state.frequencyBuy = action.payload;

      const currentDate = new Date();
      const year = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
      }).format(currentDate);

      const month = new Intl.DateTimeFormat("en-GB", {
        month: "long",
      }).format(currentDate);

      const day = new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
      }).format(currentDate);

      state.frequencyDateBuy = `${month} ${day}, ${year}`;
    },
    setErrorConfirmBuy: (state, action: PayloadAction<string | undefined>) => {
      state.errorMessageConfirmBuy = action.payload;
    },
    setBankSelected: (state, action: PayloadAction<boolean>) => {
      state.bankAccountSelected = action.payload;
    },
    changeReceiveAmountBuy: (
      state,
      action: PayloadAction<{ amount: number; value: string }>
    ) => {
      const rate = +(
        (state.conversionRateBuy?.isReverse
          ? state.conversionRateBuy?.rate
          : state.conversionRateBuy?.rateReversed) ?? "0"
      );

      state.receiveAmountBuy = action.payload.amount;
      state.receiveValueBuy = action.payload.value;

      const result = fixedTo(state.receiveAmountBuy * rate, 8);
      state.payWithAmountBuy = isNaN(result) ? 0 : result;
      state.payWithValueBuy = state.payWithAmountBuy.toString();
    },
    changePayWithAmountBuy: (
      state,
      action: PayloadAction<{ amount: number; value: string }>
    ) => {
      const rate = +(
        (state.conversionRateBuy?.isReverse
          ? state.conversionRateBuy?.rateReversed
          : state.conversionRateBuy?.rate) ?? "0"
      );

      state.payWithAmountBuy = action.payload.amount;
      state.payWithValueBuy = action.payload.value;
      const result = fixedTo(state.payWithAmountBuy * rate, 8);
      state.receiveAmountBuy = isNaN(result) ? 0 : result;
      state.receiveValueBuy = state.receiveAmountBuy.toString();
    },
    changePriceAmountBuy: (
      state,
      action: PayloadAction<{ amount: number; value: string }>
    ) => {
      state.priceAmountBuy = action.payload.amount;
      state.priceValueBuy = action.payload.value;

      const paywith = isNaN(state.receiveAmountBuy * state.priceAmountBuy)
        ? 0
        : state.receiveAmountBuy * state.priceAmountBuy;

      const receive = isNaN(paywith * state.priceAmountBuy)
        ? 0
        : paywith * state.priceAmountBuy;

      state.payWithAmountBuy = fixedTo(paywith, 8);
      state.receiveAmountBuy = fixedTo(receive, 8);
    },
    setReceiveAssetBuy: (state, action: PayloadAction<Assets>) => {
      state.receiveBuy = { ...action.payload };
    },
    setPaywithAssetBuy: (state, action: PayloadAction<Assets>) => {
      state.paywithBuy = { ...action.payload };
    },
    setBankAccount: (state, action: PayloadAction<BankAccount>) => {
      state.bankAccount = { ...action.payload };
    },
    setFixedPriceBuy: (state, action: PayloadAction<number>) => {
      state.fixedPriceBuy = action.payload;
    },
    setConversionRateBuy: (
      state,
      action: PayloadAction<ExchangeRate | undefined>
    ) => {
      if (state.marketPriceBuy) {
        if (action.payload === undefined) return;

        const {
          payload: { isReverse, rate, rateReversed },
        } = action;

        const rateResponse = (isReverse ? rateReversed : rate) ?? "0";
        const priceAmount = +rateResponse;
        state.fixedPriceBuy = +(rate ?? "0");
        state.conversionRateBuy = action.payload;

        state.priceAmountBuy = priceAmount;
        state.priceValueBuy = priceAmount.toString();

        if (state.receiveAmountBuy && state.payWithAmountBuy) {
          const paywith = state.receiveAmountBuy * state.priceAmountBuy;
          const receive = paywith / state.priceAmountBuy;

          state.payWithAmountBuy = isNaN(fixedTo(paywith, 8))
            ? 0
            : fixedTo(paywith, 8);
          state.receiveAmountBuy = isNaN(fixedTo(receive, 8))
            ? 0
            : fixedTo(receive, 8);

          state.payWithValueBuy = state.payWithAmountBuy.toString();
          state.receiveValueBuy = state.receiveAmountBuy.toString();
        }
      }
    },
    setOrderRate: (state, action: PayloadAction<OrdersRatesResponse>) => {
      state.orderRate = action.payload;

      if (state.marketPriceBuy) {
        if (action.payload === undefined) return;

        const {
          payload: {
            orderRates: {
              priceSafetyRate: { isReverse, rateReversed, rate, quote },
            },
          },
        } = action;
        const priceRate = isReverse
          ? quote === "USD"
            ? rate
            : rateReversed
          : rate;
        const priceAmount = +priceRate;
        state.fixedPriceBuy = +(priceRate ?? "0");
        state.conversionRateBuy = {
          isReverse,
          rateReversed,
          rate,
          quote,
        } as ExchangeRate;
        state.priceAmountBuy = priceAmount;
        state.priceValueBuy = priceAmount.toString();
      }
    },
    setOrderRequest: (state, action: PayloadAction<OrderTemplateRequest>) => {
      state.orderRequest = action.payload;
    },
    changePriceSafetyBuy: (state, action: PayloadAction<boolean>) => {
      state.priceSafetyBuy = action.payload;
    },
    changeVolumeWarning: (state, action: PayloadAction<boolean>) => {
      state.volumeWarning = action.payload;
    },
    changeMarketPriceBuy: (state, action: PayloadAction<boolean>) => {
      state.marketPriceBuy = action.payload;
    },
    setErrorSell: (state, action: PayloadAction<string | undefined>) => {
      state.errorMessageSell = action.payload;
    },
    setFrequencySell: (state, action: PayloadAction<string>) => {
      state.frequencySell = action.payload;

      const currentDate = new Date();
      const year = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
      }).format(currentDate);

      const month = new Intl.DateTimeFormat("en-GB", {
        month: "long",
      }).format(currentDate);

      const day = new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
      }).format(currentDate);

      state.frequencyDateSell = `${month} ${day}, ${year}`;
    },
    setErrorConfirmSell: (state, action: PayloadAction<string | undefined>) => {
      state.errorMessageConfirmSell = action.payload;
    },
    changeReceiveAmountSell: (
      state,
      action: PayloadAction<{ amount: number; value: string }>
    ) => {
      state.receiveAmountSell = action.payload.amount;
      state.receiveValueSell = action.payload.value;

      const result = fixedTo(
        state.receiveAmountSell * state.priceAmountSell,
        8
      );
      state.payWithAmountSell = isNaN(result) ? 0 : result;
      state.payWithValueSell = state.payWithAmountSell.toString();
    },
    changePayWithAmountSell: (
      state,
      action: PayloadAction<{ amount: number; value: string }>
    ) => {
      state.payWithAmountSell = action.payload.amount;
      state.payWithValueSell = action.payload.value;
      const result = fixedTo(
        state.payWithAmountSell / state.priceAmountSell,
        8
      );
      state.receiveAmountSell = isNaN(result) ? 0 : result;
      state.receiveValueSell = state.receiveAmountSell.toString();
    },
    changePriceAmountSell: (
      state,
      action: PayloadAction<{ amount: number; value: string }>
    ) => {
      state.priceAmountSell = action.payload.amount;
      state.priceValueSell = action.payload.value;

      const paywith = isNaN(state.receiveAmountSell * state.priceAmountSell)
        ? 0
        : state.receiveAmountSell * state.priceAmountSell;

      const receive = isNaN(paywith / state.priceAmountSell)
        ? 0
        : paywith / state.priceAmountSell;

      state.payWithAmountSell = fixedTo(paywith, 8);
      state.receiveAmountSell = fixedTo(receive, 8);
    },
    setReceiveAssetSell: (state, action: PayloadAction<Assets>) => {
      state.receiveSell = { ...action.payload };
    },
    setPaywithAssetSell: (state, action: PayloadAction<Assets>) => {
      state.paywithSell = { ...action.payload };
    },
    setConversionRateSell: (
      state,
      action: PayloadAction<ExchangeRate | undefined>
    ) => {
      if (state.marketPriceSell) {
        if (action.payload === undefined) return;

        const {
          payload: { isReverse, rate, rateReversed },
        } = action;

        const rateResponse = (isReverse ? rateReversed : rate) ?? "0";
        const priceAmount = +rateResponse;
        state.fixedPriceSell = +(rate ?? "0");
        state.conversionRateSell = action.payload;
        state.priceAmountSell = priceAmount;
        state.priceValueSell = priceAmount.toString();

        if (state.receiveAmountSell && state.payWithAmountSell) {
          const paywith = state.receiveAmountSell * state.priceAmountSell;
          const receive = paywith / state.priceAmountSell;

          state.payWithAmountSell = isNaN(fixedTo(paywith, 8))
            ? 0
            : fixedTo(paywith, 8);
          state.receiveAmountSell = isNaN(fixedTo(receive, 8))
            ? 0
            : fixedTo(receive, 8);

          state.payWithValueSell = state.payWithAmountSell.toString();
          state.receiveValueSell = state.receiveAmountSell.toString();
        }
      }
    },
    changePriceSafetySell: (state, action: PayloadAction<boolean>) => {
      state.priceSafetySell = action.payload;
    },
    changeMarketPriceSell: (state, action: PayloadAction<boolean>) => {
      state.marketPriceSell = action.payload;
    },
    setFixedPriceSell: (state, action: PayloadAction<number>) => {
      state.fixedPriceSell = action.payload;
    },

    setFixedPriceSwap: (state, action: PayloadAction<number>) => {
      state.fixedPriceSwap = action.payload;
    },
    setErrorSwap: (state, action: PayloadAction<string | undefined>) => {
      state.errorMessageSwap = action.payload;
    },
    setFrequencySwap: (state, action: PayloadAction<string>) => {
      state.frequencySwap = action.payload;

      const currentDate = new Date();
      const year = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
      }).format(currentDate);

      const month = new Intl.DateTimeFormat("en-GB", {
        month: "long",
      }).format(currentDate);

      const day = new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
      }).format(currentDate);

      state.frequencyDateSwap = `${month} ${day}, ${year}`;
    },
    setErrorConfirmSwap: (state, action: PayloadAction<string | undefined>) => {
      state.errorMessageConfirmSwap = action.payload;
    },
    changeReceiveAmountSwap: (
      state,
      action: PayloadAction<{ amount: number; value: string }>
    ) => {
      state.receiveAmountSwap = action.payload.amount;
      state.receiveValueSwap = action.payload.value;

      const result = fixedTo(
        state.receiveAmountSwap * state.priceAmountSwap,
        8
      );
      state.payWithAmountSwap = isNaN(result) ? 0 : result;
      state.payWithValueSwap = state.payWithAmountSwap.toString();
    },
    changePayWithAmountSwap: (
      state,
      action: PayloadAction<{ amount: number; value: string }>
    ) => {
      state.payWithAmountSwap = action.payload.amount;
      state.payWithValueSwap = action.payload.value;
      const result = fixedTo(
        state.payWithAmountSwap / state.priceAmountSwap,
        8
      );
      state.receiveAmountSwap = isNaN(result) ? 0 : result;
      state.receiveValueSwap = state.receiveAmountSwap.toString();
    },
    changePriceAmountSwap: (
      state,
      action: PayloadAction<{ amount: number; value: string }>
    ) => {
      state.priceAmountSwap = action.payload.amount;
      state.priceValueSwap = action.payload.value;

      const paywith = isNaN(state.receiveAmountSwap * state.priceAmountSwap)
        ? 0
        : state.receiveAmountSwap * state.priceAmountSwap;

      const receive = isNaN(paywith / state.priceAmountSwap)
        ? 0
        : paywith / state.priceAmountSwap;

      state.payWithAmountSwap = fixedTo(paywith, 8);
      state.receiveAmountSwap = fixedTo(receive, 8);
    },
    setReceiveAssetSwap: (state, action: PayloadAction<Assets>) => {
      state.receiveSwap = { ...action.payload };
    },
    setPaywithAssetSwap: (state, action: PayloadAction<Assets>) => {
      state.paywithSwap = { ...action.payload };
    },
    setConversionRateSwap: (
      state,
      action: PayloadAction<ExchangeRate | undefined>
    ) => {
      if (state.marketPriceSwap) {
        if (action.payload === undefined) return;

        const {
          payload: { isReverse, rate, rateReversed },
        } = action;

        const rateResponse = (isReverse ? rateReversed : rate) ?? "0";
        const priceAmount = +rateResponse;
        state.fixedPriceSwap = +(rate ?? "0");
        state.conversionRateSwap = action.payload;
        state.priceAmountSwap = priceAmount;
        state.priceValueSwap = priceAmount.toString();

        if (state.receiveAmountSwap && state.payWithAmountSwap) {
          const paywith = state.receiveAmountSwap * state.priceAmountSwap;
          const receive = paywith / state.priceAmountSwap;

          state.payWithAmountSwap = isNaN(fixedTo(paywith, 8))
            ? 0
            : fixedTo(paywith, 8);
          state.receiveAmountSwap = isNaN(fixedTo(receive, 8))
            ? 0
            : fixedTo(receive, 8);

          state.payWithValueSwap = state.payWithAmountSwap.toString();
          state.receiveValueSwap = state.receiveAmountSwap.toString();
        }
      }
    },
    changePriceSafetySwap: (state, action: PayloadAction<boolean>) => {
      state.priceSafetySwap = action.payload;
    },
    changeMarketPriceSwap: (state, action: PayloadAction<boolean>) => {
      state.marketPriceSwap = action.payload;
    },
    swap: (state) => {
      const oldReceiveAmount = state.receiveAmountSwap;
      const oldReceiveValue = state.receiveValueSwap;

      state.receiveValueSwap = state.payWithValueSwap;
      state.payWithValueSwap = oldReceiveValue;

      state.receiveAmountSwap = state.payWithAmountSwap;
      state.payWithAmountSwap = oldReceiveAmount;

      if (state.conversionRateSwap)
        setConversionRateSwap(state.conversionRateSwap);
    },
    setAssetsExchange: (state, action: PayloadAction<Assets[]>) => {
      state.assetsExchange = action.payload;
    },
    setDepositCryptoAssets: (state, action: PayloadAction<Assets[]>) => {
      state.depositCryptoAssets = action.payload;
    },
    setHoldings: (state, action: PayloadAction<Assets[]>) => {
      state.holdings = action.payload;
    },
    setWatchlists: (state, action: PayloadAction<Assets[]>) => {
      state.watchlists = action.payload;
    },
    changeCanReview: (state, action: PayloadAction<boolean>) => {
      state.canReview = action.payload;
    },
    reviewing: (state, action: PayloadAction<boolean>) => {
      state.reviewing = action.payload;
    },
    changeCanConfirm: (state, action: PayloadAction<boolean>) => {
      state.canConfirm = action.payload;
    },
    setExchangeTransaction: (
      state,
      action: PayloadAction<TransactionTemplateResponse>
    ) => {
      state.exchangeTransaction = action.payload;
    },
    setDeposit: (state, action: PayloadAction<DepositTemplateResponse>) => {
      state.depositRequest = action.payload;
    },
    setExchangeTransactionTemplate: (
      state,
      action: PayloadAction<TransactionTemplateResponse>
    ) => {
      state.exchangeTransactionTemplate = action.payload;
    },
    changeNeedsKyc: (state, action: PayloadAction<boolean>) => {
      state.needsKyc = action.payload;
    },
    setAssetsExchangeLoading: (state, action: PayloadAction<boolean>) => {
      state.assetsExchangeLoading = action.payload;
    },
  },
  extraReducers: {
    [orderValidate.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<ValidateDataResponse>
    ) => {
      state.orderValidations = payload;
    },
    [orderWarnings.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<ValidateOrderResponse>
    ) => {
      state.validatedOrderResponse = payload;
    },

    [orderRequestTemplate.pending.toString()]: (state) => {
      state.reviewing = true;
    },
    [orderRequestTemplate.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<OrderTemplateResponse>
    ) => {
      state.orderTemplate = payload;
      state.reviewing = false;
    },
    [orderRequestTemplate.rejected.toString()]: (state) => {
      state.reviewing = false;
    },
    [getOrderRate.fulfilled.toString()]: (state, action) => {
      state.orderRate = action.payload;

      if (state.marketPriceBuy) {
        if (action.payload === undefined) return;

        const {
          payload: {
            orderRates: {
              priceSafetyRate: { isReverse, rateReversed, rate, quote },
            },
          },
        } = action;
        const priceRate = isReverse ? rateReversed : rate;
        const priceAmount = +priceRate;
        state.fixedPriceBuy = +(rate ?? "0");
        state.conversionRateBuy = {
          isReverse,
          rateReversed,
          rate,
          quote,
        } as ExchangeRate;
        state.priceAmountBuy = priceAmount;
        state.priceValueBuy = priceAmount.toString();
      }
    },
    [getOrderRate.rejected.toString()]: (state) => {
      state.receiveBuy = state.depositCryptoAssets[0];
    },
  },
});

export const tradeWidgetSelector = createSelector(
  (state: RootState) => state.tradeWidget,
  (tradeWidget: TradeWidgetState) => tradeWidget
);

export const {
  reset,
  setLastTab,
  setLastStep,
  changeStep,
  setAssetsExchange,
  setAssetsExchangeLoading,
  setDepositCryptoAssets,
  setHoldings,
  setWatchlists,
  reviewing,
  changeCanReview,
  changeCanConfirm,
  setExchangeTransaction,
  setExchangeTransactionTemplate,
  setDeposit,
  changeNeedsKyc,
  setOrderRate,
  setOrderRequest,
  changeVolumeWarning,
  setBankSelected,

  setFrequencyBuy,
  setFixedPriceBuy,
  setErrorBuy,
  setErrorConfirmBuy,
  changeReceiveAmountBuy,
  changePayWithAmountBuy,
  changePriceAmountBuy,
  setReceiveAssetBuy,
  setPaywithAssetBuy,
  setBankAccount,
  setConversionRateBuy,
  changeMarketPriceBuy,
  changePriceSafetyBuy,

  setFrequencySell,
  setFixedPriceSell,
  setErrorSell,
  setErrorConfirmSell,
  changeReceiveAmountSell,
  changePayWithAmountSell,
  changePriceAmountSell,
  setReceiveAssetSell,
  setPaywithAssetSell,
  setConversionRateSell,
  changeMarketPriceSell,
  changePriceSafetySell,

  setFrequencySwap,
  setFixedPriceSwap,
  setErrorSwap,
  setErrorConfirmSwap,
  changeReceiveAmountSwap,
  changePayWithAmountSwap,
  changePriceAmountSwap,
  setReceiveAssetSwap,
  setPaywithAssetSwap,
  setConversionRateSwap,
  changeMarketPriceSwap,
  changePriceSafetySwap,
  swap,
} = tradeWidgetSlice.actions;

export const tradeWidgetReducer = tradeWidgetSlice.reducer;

interface TradeWidgetState {
  receiveAmountBuy: number;
  payWithAmountBuy: number;
  priceAmountBuy: number;
  fixedPriceBuy: number;
  receiveValueBuy: string;
  payWithValueBuy: string;
  priceValueBuy: string;
  receiveBuy: Assets | undefined;
  paywithBuy: Assets | undefined;
  bankAccount: BankAccount | undefined;
  bankAccountSelected: boolean;
  orderRate: OrdersRatesResponse | undefined;
  orderRequest: OrderTemplateRequest;
  orderTemplate: OrderTemplateResponse;
  conversionRateBuy: ExchangeRate | undefined;
  marketPriceBuy: boolean;
  priceSafetyBuy: boolean;
  errorMessageBuy: string | undefined;
  errorMessageConfirmBuy: string | undefined;
  frequencyBuy: string;
  frequencyDateBuy: string;

  receiveAmountSell: number;
  payWithAmountSell: number;
  priceAmountSell: number;
  fixedPriceSell: number;
  receiveValueSell: string;
  payWithValueSell: string;
  priceValueSell: string;
  receiveSell: Assets | undefined;
  paywithSell: Assets | undefined;
  conversionRateSell: ExchangeRate | undefined;
  marketPriceSell: boolean;
  priceSafetySell: boolean;
  errorMessageSell: string | undefined;
  errorMessageConfirmSell: string | undefined;
  frequencySell: string;
  frequencyDateSell: string;

  receiveAmountSwap: number;
  payWithAmountSwap: number;
  priceAmountSwap: number;
  fixedPriceSwap: number;
  receiveValueSwap: string;
  payWithValueSwap: string;
  priceValueSwap: string;
  receiveSwap: Assets | undefined;
  paywithSwap: Assets | undefined;
  conversionRateSwap: ExchangeRate | undefined;
  marketPriceSwap: boolean;
  priceSafetySwap: boolean;
  errorMessageSwap: string | undefined;
  errorMessageConfirmSwap: string | undefined;
  frequencySwap: string;
  frequencyDateSwap: string;

  assetsExchange: Assets[];
  assetsExchangeLoading: boolean;
  depositCryptoAssets: Assets[];
  holdings: Assets[];
  watchlists: Assets[];

  lastTab: string;
  lastStep: string;
  currentStep: string;
  searchReceive: boolean;
  canReview: boolean;
  reviewing: boolean;
  volumeWarning: boolean;
  canConfirm: boolean;
  orderValidations: ValidateDataResponse;
  validatedOrderResponse: ValidateOrderResponse;
  exchangeTransaction: TransactionTemplateResponse;
  exchangeTransactionTemplate: TransactionTemplateResponse;
  depositRequest: DepositTemplateResponse;

  needsKyc: boolean;
}
