import { Copy, Info } from 'assets/icons';
import LoadingBar from 'components/atoms/Loading/LoadingBar';
import { toast, ToastType } from 'components/organisms/Toast';
import Layout from 'components/templates/Layout';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import CustodyNavigationWidget from './CustodyNavigationWidget';
import useDeposits from './extraCustodyHooks/useDeposits';
import CustodyAssetComponent, { CustodyAssetComponentContainer } from './CustodyAssetComponent';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import { MarginContainer } from './Custody';
import useCustodyRedirect from './extraCustodyHooks/useCustodyRedirect';
import CustodyQRCode from './CustodyQRCode';
import { useGetDefiBalancesQuery, useGetDefiStrategyDetailsQuery } from 'state/store/investApi'
import Button from 'components/atoms/Button';
import { SizeNamesEnum, TypesNamesEnum } from 'enums/Button.enum';
import { palette } from 'lib/theme';

const CustodyDeposit = () => {
  const {
    assetsForDeposit,
    asset,
    setAsset,
    selectedNetwork,
    setNetwork,
    availableNetworks,
    walletInformation,
    restart,
    loadingNetworks,
    loadingWallet,
  } = useDeposits();

  useEffect(() => {
    restart();
    return () => {
      restart();
    };
  }, []);

  useCustodyRedirect();
  const copyAddress = (address: string) => {
    try {
      navigator.clipboard.writeText(address);
      toast.show({
        title: 'Address copied',
        content: 'The deposit address value was copied to the clipboard',
        duration: 5000,
        type: ToastType.Info,
      });
    } catch (e) {}
  };

  const selectedAsset = assetsForDeposit.find((a) => a?.identifier === asset);

  const params = useParams();
  useEffect(() => {
    const asset = params.asset;
    if (asset) {
      setAsset(asset);
    }
  }, [params, setAsset]);

  const loadedAssets = assetsForDeposit.filter((a) => a);
  const balances = useGetDefiBalancesQuery(null)
  const balance = balances.data?.find(b => b?.balanceCurrency === asset)
  const details = useGetDefiStrategyDetailsQuery(balance?.identifier ?? '', { skip: !balance?.identifier})

  const canGoBack = selectedAsset && !loadingNetworks && !loadingWallet;
  return (
    <Layout customGrid={alternativeGridSystem}>
      <MarginContainer>
        <CustodyNavigationWidget action={canGoBack ? () => restart() : undefined} textAction='Change Asset'>
          <h2>Deposit Crypto</h2>
          <DepositContainer>
          <AssetNetworkContainer>
            {!loadedAssets.length && <LoadingBar />}
            {!!loadedAssets.length && (
              <>
                <CustodyAssetComponentContainer>
                  {asset ? (
                    <>
                      <h3 style={{ fontWeight: 400 }}>Selected asset</h3>
                      <CustodyAssetComponent asset={selectedAsset} onClick={params.asset ? () => {} : restart} />
                    </>
                  ) : (
                    <>
                      <h3 style={{ fontWeight: 400 }}>Select the asset</h3>
                      {assetsForDeposit.map((asset) => (
                        <CustodyAssetComponent
                          key={asset?.identifier}
                          asset={asset}
                          onClick={() => {
                            setAsset(asset?.identifier!);
                          }}
                        />
                      ))}
                    </>
                  )}
                </CustodyAssetComponentContainer>
              </>
            )}
            {!loadingNetworks && (
              <>
                {selectedAsset ? (
                  <>
                    {selectedNetwork ? (
                      <>
                        <NetworkContainer>
                          <h3 style={{ fontWeight: 400 }}>Network for deposit</h3>
                          <NetworkCard>
                            <MainRowText>{selectedNetwork.name}</MainRowText>
                          </NetworkCard>
                        </NetworkContainer>
                      </>
                    ) : (
                      <>
                        <NetworkContainer>
                          <h3>Select the network</h3>
                          {availableNetworks.length > 0 &&
                            availableNetworks.map((network) => (
                              <NetworkCard key={network.network} hideBorder>
                                <Button 
                                  buttonType={TypesNamesEnum.ACCENT}
                                  size={SizeNamesEnum.NORMAL}
                                  onClick={() => setNetwork(network)}
                                >
                                  {network.name}
                                </Button>
                              </NetworkCard>
                            ))}
                          {availableNetworks.length === 0 && <p>There are no networks available for the selected asset</p>}
                        </NetworkContainer>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
            </AssetNetworkContainer>
            {!!loadedAssets.length && loadingNetworks && <LoadingBar />}
            {loadingWallet && <LoadingBar />}
            {!loadingWallet && (
              <>
                {selectedAsset && selectedNetwork && walletInformation && (
                  <>
                    <WalletInformationContainer>
                      <DivIcon>
                        <Info />
                        <div>
                          {walletInformation.depositNotes.map((note) => (
                            <Notes key={note}>
                              <span>{note}</span>
                            </Notes>
                          ))}
                        </div>
                      </DivIcon>
                      <h2>Wallet Address</h2>
                      <QrImageBox>
                        <CustodyQRCode address={walletInformation.address}></CustodyQRCode>
                      </QrImageBox>
                      <CopyAddress onClick={() => copyAddress(walletInformation.address)}>
                        <Copy />
                        {walletInformation?.address}
                      </CopyAddress>
                      {walletInformation.tag && (
                        <>
                          <h2>Destination Tag / Memo</h2>
                          <QrImageBox>
                            <CustodyQRCode address={walletInformation.tag}></CustodyQRCode>
                          </QrImageBox>
                          <CopyAddress onClick={() => copyAddress(walletInformation.tag)}>
                            <Copy />
                            {walletInformation?.tag}
                          </CopyAddress>
                        </>
                      )}
                      <p>Minimum number of confirmations required: {walletInformation.minConfirmations}</p>
                      <p>Please note, this deposit will be subject to a custody fee of {walletInformation.custodyBps} bps per year</p>
                    </WalletInformationContainer>
                  </>
                )}
              </>
            )}
          </DepositContainer>
        </CustodyNavigationWidget>
      </MarginContainer>
    </Layout>
  );
};

const DivIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
const Notes = styled.p`
  color: #e99928;
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 6px 12px 6px 4px;
  border-radius: 8px;
`;

const CopyAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  cursor: pointer;
`;

const QrImageBox = styled.div`
  height: 280px;
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

const WalletInformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1vh;
`;

interface NetworkCardProps {
  hideBorder?: boolean
}

const NetworkCard = styled.div<NetworkCardProps>`
  display: flex;
  align-items: center;
  gap: 0.6vw;
  padding: 4px 6px;
  height: 55px;
  border-radius: 8px;
  border: ${({hideBorder}) => hideBorder ? "unset" : `1px solid ${palette.accent.light10}`};
`;

const DepositContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: stretch;
  margin-top: 3vh;
`;

const NetworkContainer = styled.div`
  display: flex;
  margin-left: 18px;
  margin-bottom: 18px;
  flex-direction: column;
  align-items: flex-start;
`;

const MainRowText = styled.span`
  font-size: 1.2rem;
`;

const AssetNetworkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: stretch;
  margin-bottom: 24px;
`;

export default CustodyDeposit;
