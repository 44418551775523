import React, { FC } from "react";

import Icon from "components/atoms/Icon";
import styled from "styled-components";
import { palette } from "../../lib/theme";

export const IconBackgroundStyled = styled.div`
  height: 2.5rem;
  display: flex;
  flex: 0 0 2.5rem;
  justify-content: space-evenly;
  align-content: center;
  flex-direction: column;
  align-items: center;
  background: ${palette.background.elevation3};
  border-radius: 0.625rem;
  padding: 0.05rem;
  background-image: linear-gradient(
      ${palette.background.elevation3},
      ${palette.background.elevation3}
    ),
    radial-gradient(
      circle at top left,
      ${palette.accent.light15},
      ${palette.accent.light0}
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

export const IconBackground: FC<{ icon: string }> = ({ icon }) => {
  return (
    <IconBackgroundStyled style={{ width: '2.5rem' }}>
      <Icon name={icon} size={24} color={palette.accent.light} />
    </IconBackgroundStyled>
  );
};
