/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApiError } from '../models';
import { PriceAlertRequest } from '../models';
import { PriceAlertsResponse } from '../models';
import { ResponseBase } from '../models';
/**
 * PriceAlertsControllerApi - axios parameter creator
 * @export
 */
export const PriceAlertsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create user's price alerts
         * @param {PriceAlertRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPriceAlert: async (body: PriceAlertRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createPriceAlert.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling createPriceAlert.');
            }
            const localVarPath = `/price-alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an user's price alert
         * @param {string} token 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePriceAlert: async (token: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling deletePriceAlert.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePriceAlert.');
            }
            const localVarPath = `/price-alerts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes many user's price alert at once
         * @param {Array<string>} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePriceAlertBulk: async (body: Array<string>, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling deletePriceAlertBulk.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling deletePriceAlertBulk.');
            }
            const localVarPath = `/price-alerts/bulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user's price alerts
         * @param {string} token 
         * @param {string} [asset] 
         * @param {string} [sortField] 
         * @param {string} [sortDirection] 
         * @param {string} [page] 
         * @param {string} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceAlerts: async (token: string, asset?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getPriceAlerts.');
            }
            const localVarPath = `/price-alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (asset !== undefined) {
                localVarQueryParameter['asset'] = asset;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an user's price alert
         * @param {PriceAlertRequest} body 
         * @param {string} token 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePriceAlert: async (body: PriceAlertRequest, token: string, id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updatePriceAlert.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling updatePriceAlert.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePriceAlert.');
            }
            const localVarPath = `/price-alerts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceAlertsControllerApi - functional programming interface
 * @export
 */
export const PriceAlertsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create user's price alerts
         * @param {PriceAlertRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPriceAlert(body: PriceAlertRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await PriceAlertsControllerApiAxiosParamCreator(configuration).createPriceAlert(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes an user's price alert
         * @param {string} token 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePriceAlert(token: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await PriceAlertsControllerApiAxiosParamCreator(configuration).deletePriceAlert(token, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Deletes many user's price alert at once
         * @param {Array<string>} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePriceAlertBulk(body: Array<string>, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await PriceAlertsControllerApiAxiosParamCreator(configuration).deletePriceAlertBulk(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user's price alerts
         * @param {string} token 
         * @param {string} [asset] 
         * @param {string} [sortField] 
         * @param {string} [sortDirection] 
         * @param {string} [page] 
         * @param {string} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPriceAlerts(token: string, asset?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PriceAlertsResponse>>> {
            const localVarAxiosArgs = await PriceAlertsControllerApiAxiosParamCreator(configuration).getPriceAlerts(token, asset, sortField, sortDirection, page, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates an user's price alert
         * @param {PriceAlertRequest} body 
         * @param {string} token 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePriceAlert(body: PriceAlertRequest, token: string, id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await PriceAlertsControllerApiAxiosParamCreator(configuration).updatePriceAlert(body, token, id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PriceAlertsControllerApi - factory interface
 * @export
 */
export const PriceAlertsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create user's price alerts
         * @param {PriceAlertRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPriceAlert(body: PriceAlertRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return PriceAlertsControllerApiFp(configuration).createPriceAlert(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an user's price alert
         * @param {string} token 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePriceAlert(token: string, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return PriceAlertsControllerApiFp(configuration).deletePriceAlert(token, id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes many user's price alert at once
         * @param {Array<string>} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePriceAlertBulk(body: Array<string>, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return PriceAlertsControllerApiFp(configuration).deletePriceAlertBulk(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user's price alerts
         * @param {string} token 
         * @param {string} [asset] 
         * @param {string} [sortField] 
         * @param {string} [sortDirection] 
         * @param {string} [page] 
         * @param {string} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPriceAlerts(token: string, asset?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PriceAlertsResponse>> {
            return PriceAlertsControllerApiFp(configuration).getPriceAlerts(token, asset, sortField, sortDirection, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an user's price alert
         * @param {PriceAlertRequest} body 
         * @param {string} token 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePriceAlert(body: PriceAlertRequest, token: string, id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return PriceAlertsControllerApiFp(configuration).updatePriceAlert(body, token, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PriceAlertsControllerApi - object-oriented interface
 * @export
 * @class PriceAlertsControllerApi
 * @extends {BaseAPI}
 */
export class PriceAlertsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create user's price alerts
     * @param {PriceAlertRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceAlertsControllerApi
     */
    public async createPriceAlert(body: PriceAlertRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return PriceAlertsControllerApiFp(this.configuration).createPriceAlert(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Deletes an user's price alert
     * @param {string} token 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceAlertsControllerApi
     */
    public async deletePriceAlert(token: string, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return PriceAlertsControllerApiFp(this.configuration).deletePriceAlert(token, id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Deletes many user's price alert at once
     * @param {Array<string>} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceAlertsControllerApi
     */
    public async deletePriceAlertBulk(body: Array<string>, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return PriceAlertsControllerApiFp(this.configuration).deletePriceAlertBulk(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get user's price alerts
     * @param {string} token 
     * @param {string} [asset] 
     * @param {string} [sortField] 
     * @param {string} [sortDirection] 
     * @param {string} [page] 
     * @param {string} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceAlertsControllerApi
     */
    public async getPriceAlerts(token: string, asset?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PriceAlertsResponse>> {
        return PriceAlertsControllerApiFp(this.configuration).getPriceAlerts(token, asset, sortField, sortDirection, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates an user's price alert
     * @param {PriceAlertRequest} body 
     * @param {string} token 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceAlertsControllerApi
     */
    public async updatePriceAlert(body: PriceAlertRequest, token: string, id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return PriceAlertsControllerApiFp(this.configuration).updatePriceAlert(body, token, id, options).then((request) => request(this.axios, this.basePath));
    }
}
