import { AssetAmountPair } from "interfaces/AssetAmountPair.interface";
import React from "react";
import useAssets from "shared/useAssets";
import { GainLossSummaryRow, GainLossTotals } from "state/store/reportApi";
import styled from "styled-components";
import { sharedStyles } from "../income/styles";

interface Props {
    year: number;
    summaryData: GainLossSummaryRow[] | undefined;
    totalGainLoss: GainLossTotals | undefined;
}

const CustodyGainLossSummary: React.FC<Props> = ({ year, summaryData, totalGainLoss }) => {
    const assets = useAssets();
    return (<sharedStyles.Container>
        <Header>
            <TaxInfoContainer>
                <TaxInfoCard>
                    <TaxInfoLabel>Tax Year</TaxInfoLabel>
                    <TaxInfoValue>{year}</TaxInfoValue>
                </TaxInfoCard>
                <TaxInfoCard>
                    <TaxInfoLabel>Cost Basis Method</TaxInfoLabel>
                    <TaxInfoValue>
                        FIFO (first in, first out)
                    </TaxInfoValue>
                </TaxInfoCard>
                <TaxInfoCard>
                    <TaxInfoLabel>Total Realized Gains/Losses</TaxInfoLabel>
                    <TaxInfoValue>
                        {totalGainLoss?.total ? assets.getPriceFormattedI(totalGainLoss?.total.asset, totalGainLoss?.total.amount) : '$0.00'}
                    </TaxInfoValue>
                </TaxInfoCard>
            </TaxInfoContainer>

            <TaxInfoContainer>
                <TaxInfoCard>
                    <TaxInfoLabel>Short Term Realized Gains/Losses</TaxInfoLabel>
                    <TaxInfoValue>
                        {totalGainLoss?.total ? assets.getPriceFormattedI(totalGainLoss?.shortTerm.asset, totalGainLoss?.shortTerm.amount) : '$0.00'}
                    </TaxInfoValue>
                </TaxInfoCard>
                <TaxInfoCard>
                    <TaxInfoLabel>Long Term Realized Gains/Losses</TaxInfoLabel>
                    <TaxInfoValue>
                        {totalGainLoss?.total ? assets.getPriceFormattedI(totalGainLoss?.longTerm.asset, totalGainLoss?.longTerm.amount) : '$0.00'}
                    </TaxInfoValue>
                </TaxInfoCard>
                <TaxInfoCard>
                    <TaxInfoLabel>Unknown Duration Realized Gains/Losses</TaxInfoLabel>
                    <TaxInfoValue>
                        {totalGainLoss?.total ? assets.getPriceFormattedI(totalGainLoss?.unknown.asset, totalGainLoss?.unknown.amount) : '$0.00'}
                    </TaxInfoValue>
                </TaxInfoCard>
            </TaxInfoContainer>
            <TitleSection>
                <Title>Gain/Loss Summary</Title>
            </TitleSection>
        </Header>

        <sharedStyles.TableContainer>
            <sharedStyles.Table>
                <thead>
                    <tr>
                        <sharedStyles.Th>Strategy Name</sharedStyles.Th>
                        <sharedStyles.Th>Short Term</sharedStyles.Th>
                        <sharedStyles.Th>Long Term</sharedStyles.Th>
                        <sharedStyles.Th>Unknown</sharedStyles.Th>
                        <sharedStyles.Th align='right'>Realized Gain/Loss (YTD)</sharedStyles.Th>
                    </tr>
                </thead>
                <tbody>
                    {summaryData?.map((row, index) => (
                        <sharedStyles.TableRow key={index}>
                            <sharedStyles.Td>{row.description}</sharedStyles.Td>
                            <sharedStyles.Td>
                                <QuantityCell>
                                    <div>
                                    {assets.getPriceFormattedI(row.shortTerm.asset, row.shortTerm.amount)}
                                    </div>
                                </QuantityCell>
                            </sharedStyles.Td>
                            <sharedStyles.Td>
                                <QuantityCell>
                                    <div>
                                    {assets.getPriceFormattedI(row.longTerm.asset, row.longTerm.amount)}
                                    </div>
                                </QuantityCell>
                            </sharedStyles.Td>
                            <sharedStyles.Td>
                                <QuantityCell>
                                    <div>
                                    {assets.getPriceFormattedI(row.unknown.asset, row.unknown.amount)}
                                    </div>
                                </QuantityCell>
                            </sharedStyles.Td>
                            <sharedStyles.Td>
                                <div>${parseFloat(row.total.amount).toFixed(2)}</div>
                            </sharedStyles.Td>
                        </sharedStyles.TableRow>
                    ))}
                </tbody>
            </sharedStyles.Table>
        </sharedStyles.TableContainer>
    </sharedStyles.Container>);
};


const Header = styled.div`
    margin-bottom: 32px;
`;

const TitleSection = styled.div`
    margin-bottom: 24px;
`;

const Title = styled.h2`
    color: white;
    font-size: 20px;
    margin-bottom: 8px;
`;

const TaxInfoContainer = styled.div`
    display: flex;
    gap: 24px;
    margin-bottom: 32px;
`;

const TaxInfoCard = styled.div`
    background: rgba(255, 255, 255, 0.05);
    padding: 16px 24px;
    border-radius: 8px;
    min-width: 200px;
`;

const TaxInfoLabel = styled.div`
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    margin-bottom: 4px;
`;

const TaxInfoValue = styled.div`
    color: white;
    font-size: 24px;
    font-weight: 600;
`;

const QuantityCell = styled.div`
    display: flex;
    flex-direction: column;
`;


export default CustodyGainLossSummary;