import React, { FC, useEffect } from "react"
import Layout from "components/templates/Layout"
import alternativeGridSystem from "lib/theme/alternativeGridSystem"
import { GridItemSetting } from "components/templates/Widget/interfaces"
import Portfolio from "./Portfolio"
import TokenSummary from "./TokenSummary"
import DepositDetails from "./DepositDetails"
import Transactions from "./Transactions"
import Statements from "./Statements"
import { useGetOrdersSummaryQuery } from "state/store/api"
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'state/slice/auth.slice'
import { useNavigate } from 'react-router-dom'
import { PagesUrl } from 'lib/constants/config.constant'
import usePermissions from 'shared/usePermissions'

export interface Sizes {
  xs?: GridItemSetting
  sm?: GridItemSetting
  md?: GridItemSetting
  lg?: GridItemSetting
  xl?: GridItemSetting
  xxl?: GridItemSetting
}

const Boost: FC = () => {
  const { data: orders, isLoading, isFetching } = useGetOrdersSummaryQuery()
  const user = useSelector(selectCurrentUser)
  const hasProducts = user?.products?.length
  const hasBoost = hasProducts && user.products?.includes('Boost')
  const navigate = useNavigate()
  const permissions = usePermissions()
  useEffect(() => {
    if (permissions.isAccountManager) return
    if (hasProducts) {
      if (!hasBoost) {
        navigate(PagesUrl.BORROW)
      }
    }
  }, [hasProducts, hasBoost, permissions])
  return (
    <Layout customGrid={alternativeGridSystem}>
      <Portfolio
        isLoading={isLoading}
        isFetching={isFetching}
        orders={orders ?? {}}
      />
      <TokenSummary
        isLoading={isLoading}
        isFetching={isFetching}
        orders={orders ?? {}}
      />
      <DepositDetails />
      <Transactions />
    </Layout>
  )
}

export default Boost
