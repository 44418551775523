// Makes it easier to change Profile Layout in one file:
export const ProfileScreenGridSizes = {
  linkedAccount: {
    xxl: { area: "2 / 2 / 55 / 2" },
    xl: { area: "2 / 2 / 55 / 2" },
    lg: { area: "2 / 2 / 55 / 2" },
    md: { area: "2 / 1 / 53 / 2", columns: "1 / -1" },
    sm: { area: "2 / 1 / 53 / 2", columns: "1 / -1" },
    xs: { area: "2 / 1 / 53 / 2", columns: "1 / -1" },
  },
  walletSecurity: {
    xxl: { area: "2 / 1 / 86 / 1" },
    xl: { area: "2 / 1 / 86 / 1" },
    lg: { area: "2 / 1 / 97 / 1" },
    md: { area: "58 / 1 / 142 / 2", columns: "1 / -1" },
    sm: { area: "58 / 1 / 142 / 2", columns: "1 / -1" },
    xs: { area: "58 / 1 / 142 / 2", columns: "1 / -1" },
  },
  sendMoneyWidget: {
    xxl: { area: "60 / 2 / 192 / 2" },
    xl: { area: "60 / 2 / 192 / 2" },
    lg: { area: "60 / 2 / 192 / 2" },
    md: { area: "147 / 1 / 274 / 2", columns: "1 / -1" },
    sm: { area: "147 / 1 / 274 / 2", columns: "1 / -1" },
    xs: { area: "147 / 1 / 274 / 2", columns: "1 / -1" },
  },
  exportTransactionWidget: {
    xxl: { area: "91 / 1 / 168 / 1" },
    xl: { area: "91 / 1 / 168 / 1" },
    lg: { area: "102 / 1 / 207 / 1" },
    md: { area: "279 / 1 / 356 / 2", columns: "1 / -1" },
    sm: { area: "279 / 1 / 356 / 2", columns: "1 / -1"},
    xs: { area: "279 / 1 / 356 / 2", columns: "1 / -1" },
  },
  referralWidget: {
    xxl: { area: "197 / 2 / 252 / 2" },
    xl: { area: "197 / 2 / 252 / 2" },
    lg: { area: "197 / 2 / 252 / 2" },
    md: { area: "361 / 1 / 416 / 2", columns: "1 / -1" },
    sm: { area: "361 / 1 / 416 / 2", columns: "1 / -1" },
    xs: { area: "361 / 1 / 416 / 2", columns: "1 / -1" },
  },
  beneficiaryWidget: {
    xxl: { area: "173 / 1 / 199 / 2" },
    xl: { area: "173 / 1 / 199 / 2" },
    lg: { area: "212 / 1 / 244 / 2" },
    md: { area: "421 / 1 / 447 / 1", columns: "1 / -1" },
    sm: { area: "421 / 1 / 447 / 1", columns: "1 / -1" },
    xs: { area: "421 / 1 / 447 / 1", columns: "1 / -1" },
  },
  withoutBeneficiaryWidget: {
    xxl: { area: "173 / 1 / 205 / 2" },
    xl: { area: "173 / 1 / 205 / 2" },
    lg: { area: "212 / 1 / 244 / 2" },
    md: { area: "421 / 1 / 453 / 1", columns: "1 / -1" },
    sm: { area: "421 / 1 / 453 / 1", columns: "1 / -1" },
    xs: { area: "421 / 1 / 453 / 1", columns: "1 / -1" },
  },
  currencyWidget: {
    xxl: { area: "204 / 1 / 230 / 1" },
    xl: { area: "204 / 1 / 230 / 1" },
    lg: { area: "249 / 1 / 275 / 1" },
    md: { area: "452 / 1 / 478 / 2", columns: "1 / -1" },
    sm: { area: "452 / 1 / 478 / 2", columns: "1 / -1" },
    xs: { area: "452 / 1 / 478 / 2", columns: "1 / -1" },
  },
  withoutBeneficiaryCurrencyWidget: {
    xxl: { area: "210 / 1 / 236 / 1" },
    xl: { area: "210 / 1 / 236 / 1" },
    lg: { area: "249 / 1 / 275 / 1" },
    md: { area: "458 / 1 / 484 / 2", columns: "1 / -1" },
    sm: { area: "458 / 1 / 484 / 2", columns: "1 / -1" },
    xs: { area: "458 / 1 / 484 / 2", columns: "1 / -1" },
  },
  moreThanOneLinkedAccount: {
    xxl: { area: "2 / 2 / 75 / 2" },
    xl: { area: "2 / 2 / 75 / 2" },
    lg: { area: "2 / 2 / 75 / 2" },
    md: { area: "2 / 1 / 75 / 2", columns: "1 / -1" },
    sm: { area: "2 / 1 / 75 / 2", columns: "1 / -1" },
    xs: { area: "2 / 1 / 75 / 2", columns: "1 / -1" },
  },
  walletSecurityWithMoreThanOneLinkedAccount: {
    xxl: { area: "2 / 1 / 86 / 1" },
    xl: { area: "2 / 1 / 86 / 1" },
    lg: { area: "2 / 1 / 97 / 1" },
    md: { area: "80 / 1 / 164 / 2", columns: "1 / -1" },
    sm: { area: "80 / 1 / 164 / 2", columns: "1 / -1" },
    xs: { area: "80 / 1 / 164 / 2", columns: "1 / -1" },
  },
  sendMoneyWidgetWithMoreThanOneLinkedAccount: {
    xxl: { area: "80 / 2 / 212 / 2" },
    xl: { area: "80 / 2 / 212 / 2" },
    lg: { area: "80 / 2 / 212 / 2" },
    md: { area: "169 / 1 / 296 / 2", columns: "1 / -1" },
    sm: { area: "169 / 1 / 296 / 2", columns: "1 / -1" },
    xs: { area: "169 / 1 / 296 / 2", columns: "1 / -1" },
  },
  exportTransactionWidgetWithMoreThanOneLinkedAccount: {
    xxl: { area: "91 / 1 / 168 / 1" },
    xl: { area: "91 / 1 / 168 / 1" },
    lg: { area: "102 / 1 / 207 / 1" },
    md: { area: "301 / 1 / 378 / 2", columns: "1 / -1" },
    sm: { area: "301 / 1 / 378 / 2", columns: "1 / -1"},
    xs: { area: "301 / 1 / 378 / 2", columns: "1 / -1" },
  },
  referralWidgetWithMoreThanOneLinkedAccount: {
    xxl: { area: "217 / 2 / 272 / 2" },
    xl: { area: "217 / 2 / 272 / 2" },
    lg: { area: "217 / 2 / 272 / 2" },
    md: { area: "383 / 1 / 438 / 2", columns: "1 / -1" },
    sm: { area: "383 / 1 / 438 / 2", columns: "1 / -1" },
    xs: { area: "383 / 1 / 438 / 2", columns: "1 / -1" },
  },
  withoutBeneficiaryWidgetWithMoreThanOneLinkedAccount: {
    xxl: { area: "173 / 1 / 199 / 2" },
    xl: { area: "173 / 1 / 199 / 2" },
    lg: { area: "212 / 1 / 244 / 2" },
    md: { area: "443 / 1 / 469 / 1", columns: "1 / -1" },
    sm: { area: "443 / 1 / 469 / 1", columns: "1 / -1" },
    xs: { area: "443 / 1 / 469 / 1", columns: "1 / -1" },
  },
  beneficiaryWidgetWithMoreThanOneLinkedAccount: {
    xxl: { area: "173 / 1 / 205 / 2" },
    xl: { area: "173 / 1 / 205 / 2" },
    lg: { area: "212 / 1 / 244 / 2" },
    md: { area: "443 / 1 / 475 / 1", columns: "1 / -1" },
    sm: { area: "443 / 1 / 475 / 1", columns: "1 / -1" },
    xs: { area: "443 / 1 / 475 / 1", columns: "1 / -1" },
  },
  withoutBeneficiaryCurrencyWidgetWithMoreThanOneLinkedAccount: {
    xxl: { area: "204 / 1 / 230 / 1" },
    xl: { area: "204 / 1 / 230 / 1" },
    lg: { area: "249 / 1 / 275 / 1" },
    md: { area: "474 / 1 / 500 / 2", columns: "1 / -1" },
    sm: { area: "474 / 1 / 500 / 2", columns: "1 / -1" },
    xs: { area: "474 / 1 / 500 / 2", columns: "1 / -1" },
  },
  CurrencyWidgetWithMoreThanOneLinkedAccount: {
    xxl: { area: "210 / 1 / 236 / 1" },
    xl: { area: "210 / 1 / 236 / 1" },
    lg: { area: "249 / 1 / 275 / 1" },
    md: { area: "480 / 1 / 506 / 2", columns: "1 / -1" },
    sm: { area: "480 / 1 / 506 / 2", columns: "1 / -1" },
    xs: { area: "480 / 1 / 506 / 2", columns: "1 / -1" },
  },
  AccreditedInverstorVerificationWidget: {
    xxl: { area: "235 / 1 / 261 / 1" },
    xl: { area: "235 / 1 / 261 / 1" },
    lg: { area: "280 / 1 / 306 / 1" },
    md: { area: "483 / 1 / 509 / 2", columns: "1 / -1" },
    sm: { area: "483 / 1 / 509 / 2", columns: "1 / -1" },
    xs: { area: "483 / 1 / 509 / 2", columns: "1 / -1" },
  },
  withoutBeneficiaryAccreditedInverstorVerificationWidget: {
    xxl: { area: "241 / 1 / 277 / 1" },
    xl: { area: "241 / 1 / 277 / 1" },
    lg: { area: "280 / 1 / 306 / 1" },
    md: { area: "489 / 1 / 515 / 2", columns: "1 / -1" },
    sm: { area: "489 / 1 / 515 / 2", columns: "1 / -1" },
    xs: { area: "489 / 1 / 515 / 2", columns: "1 / -1" },
  },
  withoutBeneficiaryAccreditedInverstorVerificationWidgetWithMoreThanOneLinkedAccount: {
    xxl: { area: "235 / 1 / 261 / 1" },
    xl: { area: "235 / 1 / 261 / 1" },
    lg: { area: "280 / 1 / 306 / 1" },
    md: { area: "505 / 1 / 531 / 2", columns: "1 / -1" },
    sm: { area: "505 / 1 / 531 / 2", columns: "1 / -1" },
    xs: { area: "505 / 1 / 531 / 2", columns: "1 / -1" },
  },
  AccreditedInverstorVerificationWidgetWithMoreThanOneLinkedAccount: {
    xxl: { area: "239 / 1 / 265 / 1" },
    xl: { area: "239 / 1 / 265 / 1" },
    lg: { area: "280 / 1 / 306 / 1" },
    md: { area: "511 / 1 / 537 / 2", columns: "1 / -1" },
    sm: { area: "511 / 1 / 537 / 2", columns: "1 / -1" },
    xs: { area: "511 / 1 / 537 / 2", columns: "1 / -1" },
  },
};
