import styled from "styled-components";

interface StrongTextProps {
    fontWeight?: number
}

const StrongText = styled.strong<StrongTextProps>`
  font-weight: ${({fontWeight}) => fontWeight || 500};
`

export default StrongText