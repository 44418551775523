import Icon from 'components/atoms/Icon';
import LoadingBar from 'components/atoms/Loading/LoadingBar';
import TransactionSidebar from 'pages/TransactionScreen/TransactionSidebar';
import React, { FC, useState } from 'react';
import { capitalize } from 'shared';
import { TransferAddress, WithdrawRequest } from 'state/store/withdrawApi';
import styled from 'styled-components';
import ConfirmValidAddress from './ConfirmValidAddress';

interface AddressBoxProperties {
  address: TransferAddress;
  requests: WithdrawRequest[];
  action: (address: TransferAddress) => void;
  expandAction: (id: number) => void;
  markAsVerified: (address: TransferAddress) => void;
  expanded: boolean;
  loading: boolean;
}
const AddressBox: FC<AddressBoxProperties> = ({ address, action, markAsVerified, expanded, expandAction, loading, requests }) => {
  const [transactionId, setTransactionId] = useState(0);
  const [show, setShow] = useState(false);
  const sortedRequests = [...requests]
  sortedRequests.sort((r1, r2) => r1.transactionId && r2.transactionId ? r2.transactionId.toString().localeCompare(r1.transactionId.toString()) : 0)
  const select = (id: number) => {
    setShow(true);
    setTransactionId(id);
  };

  return (
    <AddressBoxContainer address={address} expanded={expanded}>
      <Card>
        <InfoContainer>
          <InfoArea>
            <div>
              <strong>Asset: </strong> {address.asset ?? ' --- '} &nbsp;
              <strong>Network: </strong> {address.networkName ?? ' --- '}
            </div>
            <div>
              <strong>Address: </strong>
              {address.address}
            </div>
            {address.memo && (
              <div>
                <strong>Memo: </strong>
                {address.memo}
              </div>
            )}
            {address.notes && (
              <div>
                <strong>Notes: </strong>
                {address.notes}
              </div>
            )}
            {address.verified && (
              <div>
                <strong>Status: </strong>
                {address.verified ? 'Verified' : 'Not Verified'}
              </div>
            )}
          </InfoArea>
          <ConfirmValidAddress address={address} markAsVerified={markAsVerified} button />
        </InfoContainer>
        <ActionArea>
          <Button onClick={() => expandAction(expanded ? 0 : address.id ?? 0)}>{expanded ? <Icon name='UpArrowMid' /> : <Icon name='DownArrowMid' />}</Button>
        </ActionArea>
      </Card>
      {expanded && (
        <WithdrawRequestsBox>
          {loading && <LoadingBar />}
          {!loading && requests.length === 0 && (
            <>
              <HeaderExpanded>
                {address.usedWithdrawRequest && <InfoText>There is a transaction currently being processed using this address, please wait.</InfoText>}
                {!address.usedWithdrawRequest && <InfoText>There are no records of transactions for this address</InfoText>}
                <DeleteHeader onClick={() => action(address)}>
                  <p>Delete Address</p>
                  <HeaderActionArea>
                    <Button>
                      <Icon name='Trash' />
                    </Button>
                  </HeaderActionArea>
                </DeleteHeader>
              </HeaderExpanded>
            </>
          )}
          {!loading && requests.length > 0 && (
            <>
              <HeaderExpanded>
                <h4>Transaction History</h4>
                <DeleteHeader onClick={() => action(address)}>
                  <p>Delete Address</p>
                  <HeaderActionArea>
                    <Button>
                      <Icon name='Trash' />
                    </Button>
                  </HeaderActionArea>
                </DeleteHeader>
              </HeaderExpanded>
              {sortedRequests.map((request) => (
                <WithdrawCards key={request.id}>
                  <WithdrawCard>
                    <p>
                      <>
                        {request.amount} {request.currency?.toUpperCase()}
                      </>
                      {request.status === 'SUCCESS' && request.transactionId && (
                        <>
                          {' '}
                          Withdraw - <TransactionLink onClick={() => select(request.transactionId ?? 0)}>Transaction #{request.transactionId}</TransactionLink>
                        </>
                      )}

                      {request.status === 'SUCCESS' && !request.transactionId && (
                        <>
                          {' '}
                          Withdraw - <TransactionNumber>Transaction {request.externalRequestUid}</TransactionNumber>
                        </>
                      )}
                    </p>
                    <StatusParagraph color={request.status === 'SUCCESS' ? 'green' : 'red'}>{capitalize(request.status ?? '')}</StatusParagraph>
                  </WithdrawCard>
                </WithdrawCards>
              ))}
            </>
          )}
        </WithdrawRequestsBox>
      )}
      <TransactionSidebar transactionId={transactionId} setTransactionId={setTransactionId} show={show} setShow={setShow} />
    </AddressBoxContainer>
  );
};

interface AddressProps {
  address: TransferAddress;
  expanded: boolean;
}

const InfoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 1vw;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const DeleteHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    font-size: 0.6rem;
    color: #ffffffcc;
  }
  &:hover {
    p {
      color: #ffffffaa;
    }
  }
`;
const HeaderExpanded = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;
const TransactionLink = styled.span`
  cursor: pointer;
  color: #eb92ff;
  background-color: #ffffff00;
  transition: all 0.3s ease-in;
  border-radius: 1px;
  &:hover {
    background-color: #ffffff55;
  }
`;

const TransactionNumber = styled.span`
  color: #eb92ff;
  background-color: #ffffff00;
  border-radius: 1px;
`;

const StatusParagraph = styled.p`
  color: ${({ color }) => (color === 'green' ? '#17B96B' : '#EC396B')};
  font-size: 0.8rem;
  font-weight: bolder;
`;

const WithdrawCards = styled.div`
  display: flex;
  flex-direction: column;
`;

const WithdrawCard = styled.div`
  display: flex;
  gap: 1vw;
  margin-bottom: 1vh;
  border-radius: 6px;
  align-items: center;
  padding: 0.5vh 1vw;
  background-color: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const InfoText = styled.p`
  font-family: 'IBM Plex Sans';
  font-size: 0.8rem;
  font-weight: 300;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1vw;
`;

const WithdrawRequestsBox = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  border-radius: 8px;
  padding: 4px 8px;
`;

const AddressBoxContainer = styled.div<AddressProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1vw;
  background-color: ${({ address }) => (address.verified ? '#17B96B99' : address.usedWithdrawCompleted ? '#ccc2' : '#ffffff19')};
  font-family: 'IBM Plex Sans';
  font-weight: 300;
  padding: 1vh 1vw;
  border-radius: 12px;
  word-break: break-all;
  font-size: 0.7rem;
  transition: all 0.1s ease-out;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  opacity: 1;
  color: rgba(255, 255, 255, 0.9);
  transition: background-color 0.5s ease-in-out;
  border-radius: 12px;
  div {
    font-family: 'Courier';
    font-size: 0.7rem;
    word-break: break-all;
  }
  &:hover {
    background-color: ${({ address }) => (address.verified ? '#17B96Bbb' : address.usedWithdrawCompleted ? '#ccc3' : '#ffffff39')};
  }
`;
const HeaderActionArea = styled.div`
  cursor: pointer;
  display: flex;
  gap: 3px;
`;
const ActionArea = styled.div`
  display: flex;
  align-items: center;
  gap: 4vw;
  margin-right: 8px;
`;
const Button = styled.div`
  min-width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: background-color 0.5s ease-in-out;
  border-radius: 50%;
  margin-left: 1vh;
  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
  }
`;

const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
  flex-shrink: 1;
`;
export default AddressBox;
