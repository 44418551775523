import Layout from "components/templates/Layout";
import React from "react";
import { useParams } from 'react-router-dom';
import { MarginContainer } from "../../Custody";
import CustodyNavigationWidget from "../../CustodyNavigationWidget";
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import { useGetAnnualGainLossDetailsQuery, useGetAnnualGainLossSummaryQuery, useGetAnnualGainLossTotalQuery, useGetGainLossReportCSVMutation } from "state/store/reportApi";
import CustodyGainLossSummary from "./CustodyGainLossSummary";
import CustodyGainLossTransactionDetails from "./CustodyGainLossTransactionDetails";
import { HeaderContainer } from "components/organisms/Modal/Modal.styles";
import DownloadCsvButton from "../DownloadCsvButton";

const CustodyGainLoss: React.FC = () => {
    const { year } = useParams();
    const numericYear = year ? parseInt(year, 10) : new Date().getFullYear();
    const { data: transactions, isLoading: isDetailsLoading } =
        useGetAnnualGainLossDetailsQuery(numericYear);
    const { data: totalGainLoss, isLoading: isTotalLoading } =
        useGetAnnualGainLossTotalQuery(numericYear);
    const { data: summaryData, isLoading: isSummaryLoading } =
        useGetAnnualGainLossSummaryQuery(numericYear);
    const isLoading = isDetailsLoading || isTotalLoading || isSummaryLoading;

    return (<>
        <Layout customGrid={alternativeGridSystem}>
            <MarginContainer id='pdf-area'>
                <CustodyNavigationWidget>
                    <DownloadCsvButton
                        title="Gain Loss Report"
                        year={numericYear}
                        csvMutation={useGetGainLossReportCSVMutation}
                    />
                    <CustodyGainLossSummary
                        summaryData={summaryData}
                        year={numericYear}
                        totalGainLoss={totalGainLoss}
                        >
                    </CustodyGainLossSummary>
                    <CustodyGainLossTransactionDetails
                        transactions={transactions}
                        >

                    </CustodyGainLossTransactionDetails>
                </CustodyNavigationWidget>
            </MarginContainer>
        </Layout>
    </>);
};

export default CustodyGainLoss;