import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Warning: FC<ISVG> = ({
  size = 100,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.0006 94.6667C25.3319 94.6667 5.33398 74.6688 5.33398 50H1.33398C1.33398 76.8779 23.1228 98.6667 50.0006 98.6667V94.6667ZM94.6673 50C94.6673 74.6688 74.6694 94.6667 50.0006 94.6667V98.6667C76.8785 98.6667 98.6673 76.8779 98.6673 50H94.6673ZM50.0006 5.33337C74.6694 5.33337 94.6673 25.3313 94.6673 50H98.6673C98.6673 23.1222 76.8785 1.33337 50.0006 1.33337V5.33337ZM50.0006 1.33337C23.1228 1.33337 1.33398 23.1222 1.33398 50H5.33398C5.33398 25.3313 25.3319 5.33337 50.0006 5.33337V1.33337ZM48.0006 20.9999V60.4999H52.0006V20.9999H48.0006ZM52.0006 77.4999V75.9999H48.0006V77.4999H52.0006ZM48.0006 75.9999V79.4999H52.0006V75.9999H48.0006Z"
      fill="#E99928"
    />
  </svg>
);

export default Warning;
