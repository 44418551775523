import styled, { keyframes } from "styled-components";
import { palette } from "lib/theme";

const onAutoFillStart = keyframes`
 from {/\*\*/} to {/\*\*/}}
`;

export const StyledInputContainer = styled.div<{
  text?: string | number;
  haveStatus?: boolean;
  marginTop?: string;
  disabled?: boolean;
  readOnly?: boolean;
  height?: string;
  isPrefixWithStatus?: boolean;
}>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  .input {
    &__container {
      width: 100%;
    }
    &__label {
      display: block;
      color: ${(props) =>
        props.disabled
          ? palette.darkBackgroundContrast.light20
          : palette.darkBackgroundContrast.light70};
      font-size: 16px;
      font-weight: 400;
      text-overflow: ellipsis;
      position: absolute;
      top: ${(props) => (props.marginTop ? props.marginTop : "18px")};
      left: 12px;
      letter-spacing: -0.25px;
      z-index: 1;
      transition: 0.2s;

      &--hidden {
        position: absolute;
        text-indent: -9999px;
      }

      &--show-default-color {
        color: ${palette.white.main};
        font-size: 14px;
      }

      &--show-failed-color {
        color: #ec396b;
        font-size: 14px;
      }

      &--show-collateral-needed-color {
        color: #e99928;
        font-size: 14px;
      }

      &.prefix {
        padding-left: 0px;
      }
      &.prefix-status {
        padding-left: 53px;
      }
    }
    &__wrapper {
      display: flex;
      & > * {
        flex: 2 1;
      }
      position: relative;
      .icon {
        position: absolute;
        width: 20px;
        height: auto;
        top: 50%;
        transform: translateY(-50%);
        left: 2px;
        svg {
          vertical-align: middle;
        }
      }
    }
    &__suffix {
      position: absolute;
      transform: translateY(-50%);
      font-size: 13px;
      padding-left: 25px;
      top: ${({ haveStatus }) => (haveStatus ? "calc(55px/2)" : "50%")};
      right: 10px;
      div {
        display: flex;
        align-items: center;
      }
    }

    &__additionalText {
      position: absolute;
      font-size: 13px;
      top: 58%;
      left: calc(
        ${({ text }) => {
          return `${text}px + 40px`;
        }}
      );
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 125%;
      color: ${(props) =>
        props.disabled || props.readOnly
          ? palette.darkBackgroundContrast.light20
          : palette.darkBackgroundContrast.light70};

      div {
        display: flex;
        align-items: center;
      }
    }

    .suffixSelector &__container {
      input {
        border-radius: 8px 0 0 8px;
      }
    }

    .swapUp &__suffix {
      top: 40%;
    }
    .swapDown &__suffix {
      top: 60%;
    }

    &__prefix {
      position: absolute;
      transform: translateY(-50%);
      font-size: 13px;
      width: 50px;
      padding-right: 25px;
      top: 70%;
      left: 12px;
      div {
        display: flex;
        align-items: center;
      }
    }

    &__show {
      cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
      font-size: 0.675rem;
      text-decoration: underline;
      line-height: 1;
      font-weight: 300;
      position: absolute;
      top: 0;
      right: 10px;
      width: auto;
      height: ${({ height }) => (height ? height : "56px")};
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &.suffix {
    input {
      padding: 25px 55px 8px 12px;
    }
  }
  &.prefix {
    input {
      padding: 28px 8px 8px 50px;
      padding: ${({ haveStatus }) => (haveStatus ? "28px 8px 8px 63px" : "28px 8px 8px 50px")};
    }
  }

  &.smallIcon {
    input {
      padding: 28px 8px 8px 39px;
    }
  }

  &.hasList,
  &.swapUp,
  &.swapDown {
    margin: 0;
  }

  &.swapUp {
    input {
      height: 66px;
    }
  }

  &.swapDown {
    input {
      height: 68px;
    }
  }

  &.swapUp {
    input {
      padding: 15px 55px 10px 11px;
    }
  }

  &.swapDown {
    input {
      padding: 40px 55px 10px 12px;
    }
  }

  &.container_alert.error {
    .input {
      &__label {
        color: ${palette.red.light70};
      }
      &__wrapper {
        input {
          background-color: ${palette.red.light15};
          border: solid 1px ${palette.red.light15};
        }
      }
    }
  }

  &.container_alert {
    .input {
      &__wrapper {
        input {
          border-radius: 0 0 8px 8px;
        }
      }
    }
  }

  &.show_error {
    .input {
      &__wrapper {
        input {
          border-radius: 8px;
        }
      }
    }
  }

  &.container_alert.swapUp {
    .input {
      &__wrapper {
        input {
          border-radius: 0;
          border-bottom: 0;
        }
      }
    }
  }

  &.container_alert.suffixSelector {
    .input {
      &__wrapper {
        input {
          border-radius: 0 0 0 8px;
        }
      }
    }
  }

  &.container_alert.swapDown {
    .input {
      &__wrapper {
        input {
          border-top: 0;
        }
      }
    }
  }

  ${({ whiteDisabled }) =>
    whiteDisabled
      ? ``
      : `&.disabled {
    .input {
      &__label {
        color: ${palette.darkBackgroundContrast.light10};
      }
      &__suffix {
        color: ${palette.darkBackgroundContrast.light10};
      }
      &__wrapper {
        input {
          background-color: ${palette.darkBackgroundContrast.light5};
          border: solid 1px transparent;
        }
      }
    }
  }`}

  &.read_only {
    input {
      color: ${palette.darkBackgroundContrast.light20};
      &:focus,
      &:active {
        border-color: transparent;
      }
    }
  }

  &.readonly_select {
    input {
      color: ${palette.darkBackgroundContrast.light20};
      &:focus,
      &:active {
        border: 1px solid ${palette.accent.main};
      }
    }
  }

  &.container_focus,
  &.filled {
    .input__label {
      top: ${({ haveStatus }) => (haveStatus ? "12px" : "8px")};
      font-size: ${({ isPrefixWithStatus }) =>
        isPrefixWithStatus ? "14px" : "12px"};
    }
  }

  &.container_focus.swapUp,
  &.filled.swapUp {
    .input__label {
      top: 6px;
    }
  }

  &.container_focus.swapDown,
  &.filled.swapDown {
    .input__label {
      top: 18px;
    }
  }
`;

export const StyledInput = styled.input`
  border: 1px solid transparent;
  font-size: 16px;
  letter-spacing: -0.5px;
  background-color: ${({ disabled }) =>
    disabled ? palette.darkBackgroundContrast.light5 : palette.white.light10};
  background-clip: padding-box;
  display: block;
  width: 100%;
  height: ${({ height }) => (height ? height : "56px")};
  padding: ${({ height }) => (height ? "10px 12px 8px" : "25px 12px 8px")};
  border-radius: 8px;
  outline: none;
  color: ${({ disabled, readOnly, whiteDisabled }) =>
    disabled || readOnly
      ? whiteDisabled
        ? palette.darkBackgroundContrast.white
        : palette.darkBackgroundContrast.light20
      : palette.darkBackgroundContrast.white};
  border: 1px solid
    ${({ disabled }) =>
      disabled
        ? palette.darkBackgroundContrast.light5
        : palette.background.elevation2};
  font-weight: 400;
  &:focus,
  &:active,
  &.container_focus {
    border: 1px solid ${palette.accent.main};
  }
  &:-webkit-autofill {
    animation-name: ${onAutoFillStart};
    transition: background-color 50000s ease-in-out 0s;
    border: 1px solid transparent;
    -webkit-text-fill-color: ${({ disabled, whiteDisabled }) =>
      disabled
        ? whiteDisabled
          ? palette.darkBackgroundContrast.white
          : palette.darkBackgroundContrast.light20
        : palette.darkBackgroundContrast.white};
    caret-color: ${palette.darkBackgroundContrast.white};
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
  }

  &:focus.hasList,
  &.container_focus {
    border-radius: 8px 8px 0px 0px;
    background-clip: border-box;
  }
  &.swapUp {
    border-radius: 8px 8px 0px 0px;
    background-clip: border-box;
  }

  &.swapDown {
    border-radius: 0px 0px 8px 8px;
    background-clip: border-box;
  }
`;

export const StyledInputAdditionalInfo = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: ${palette.darkBackgroundContrast.light70};
`;

export const StyledListAdditionalInfo = styled.ul`
  position: relative;
  top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 20px;
  list-style: none;
  height: 50px;
  padding-left: 0;
  flex-wrap: wrap;
  li {
    display: block;
  }
`;
