import React, { FC } from "react";
import Modal from "components/organisms/Modal/Modal";
import { TypesNamesEnum } from "enums/Button.enum";
import Button from "components/atoms/Button/Button";

interface ModalProps {
    abraTxUID?: string | null;
    showModal: boolean;
    onConfirm: () => void;
    onClose: () => void;
}

const CustodyInvestCancelModal: FC<ModalProps> = ({abraTxUID, showModal, onConfirm, onClose}) => {
    return (
        <Modal visible={showModal} header="Are you sure you want to cancel this transaction?">
            <Modal.Body>
                <br />
                <p>Transaction ID: {abraTxUID}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button height='44' buttonType={TypesNamesEnum.SECONDAY} label='Close' onClick={() => onClose()}>Cancel</Button>
                <Button height='44' buttonType={TypesNamesEnum.ACCENT} label='Confirm' onClick={() => onConfirm()}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CustodyInvestCancelModal;