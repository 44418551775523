import React, { FC } from "react";
import ISVG from "@types/icons/svg";
import { palette } from "lib/theme";

export const UpArrowMid: FC<ISVG> = ({
  size = 16,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1494 10.8415C14.0014 10.8415 13.8514 10.7975 13.7204 10.7055L7.95037 6.6665L2.17936 10.7055C1.84136 10.9435 1.37136 10.8615 1.13536 10.5205C0.898363 10.1815 0.980369 9.7135 1.32037 9.4765L7.52037 5.1365C7.77837 4.9545 8.12237 4.9545 8.37937 5.1365L14.5794 9.4765C14.9194 9.7135 15.0014 10.1815 14.7644 10.5205C14.6194 10.7305 14.3864 10.8415 14.1494 10.8415Z"
      fill="white"
    />
  </svg>
);

export default UpArrowMid;
