import React, { FC } from "react";
import styled, { css } from "styled-components";
import { palette } from "lib/theme";
import Icon from "components/atoms/Icon";

interface NotificationProps {
  type?: "warning" | "danger" | "default" | "success" | string;
  icon?: string;
  height?: string;
  minHeight?: string;
  width?: string;
  backgroundColor?: string;
  padding?: string;
  paddingContainer?: string;
  gap?: string;
  withBorder?: boolean;
  small?: boolean;
  alignItems?: string;
  onClose?: () => void;
}

const NOTIFICATION_COLOR = {
  success: palette.green.main,
  warning: palette.orange.main,
  danger: palette.red.main,
  default: palette.purple.main,
  neutral: palette.darkBackgroundContrast.white
} as { [key: string]: string };

const Notification: FC<NotificationProps> = ({
  icon,
  height = "80px",
  minHeight,
  width = "100%",
  backgroundColor = palette.background.elevation3,
  type,
  padding,
  paddingContainer,
  gap,
  onClose,
  withBorder,
  small = false,
  alignItems,
  children,
}) => {
  const color = NOTIFICATION_COLOR[type || "default"];
  return (
    <Wrapper
      withBorder={withBorder}
      color={backgroundColor}
      height={height}
      width={width}
      minHeight={minHeight}
    >
      <Stripe background={color} />
      <ContentContainer
        paddingContainer={paddingContainer}
        gap={gap}
        small={small}
        alignItems={alignItems}
      >
        <IconContainer padding={padding}>
          <Icon color={color} name={icon ?? "FireNoCrossed"} size={"24"} />
        </IconContainer>
        <Container padding={padding}>{children}</Container>
        {onClose ? (
          <CrossContainer onClick={() => onClose()}>
            <Icon name="Cross" size={"24"} />
          </CrossContainer>
        ) : null}
      </ContentContainer>
    </Wrapper>
  );
};

const ContentContainer = styled.div<{
  paddingContainer?: string;
  gap: string;
  small?: boolean;
  alignItems?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  padding: ${({ paddingContainer }) =>
    paddingContainer ? paddingContainer : "21.5px 24px 22.5px 0px"};
  gap: ${({ gap }) => (gap ? gap : "16px")};
  width: 100%;

  ${({ small }) =>
    small
      ? css`
          padding: 12px 16px 12px 0px;
        `
      : ""}
`;

const Wrapper = styled.div<{
  color: string;
  height: string;
  minHeight?: string;
  width: string;
  withBorder?: boolean;
}>`
  height: ${(props) => props.height};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};

  width: ${(props) => props.width};
  background: ${(props) => props.color};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 18px;

  ${(props) =>
    props.withBorder
      ? css`
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 8px;
        `
      : ""}
`;

const Stripe = styled.div<{ background: string }>`
  border-radius: 8px 0px 0px 8px;
  width: 7px;
  flex: 0 0 7px;
  background: ${(props) =>
    props.background ? props.background : palette.purple.light};
`;

const IconContainer = styled.div<{ padding?: string }>`
  margin-top: ${(props) => props.padding};
`;

const CrossContainer = styled.div`
  cursor: pointer;
`;

const Container = styled.div<{ padding?: string }>`
  margin-top: ${(props) => props.padding};
  border-radius: 0px 8px 8px 0px;
  height: inherit;
  display: flex;
  align-items: center;
  flex: 1;
`;

export default Notification;
