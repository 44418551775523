import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const LoanBeingProcessedClock: FC<ISVG> = ({
  size = 100,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M44.9998 87.2398C68.3283 87.2398 87.2398 68.3283 87.2398 44.9998C87.2398 21.6713 68.3283 2.75977 44.9998 2.75977C21.6713 2.75977 2.75977 21.6713 2.75977 44.9998C2.75977 68.3283 21.6713 87.2398 44.9998 87.2398Z"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45 11.5605V45.0005H62.6"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LoanBeingProcessedClock;
