import styled, { css, keyframes } from 'styled-components';
import { palette, zIndex } from '../../../lib/theme';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const modalSettings = (visible: boolean) => css`
  visibility: ${visible ? 'visible' : 'hidden'};
  z-index: ${zIndex.modal};
  animation: ${visible ? fadeIn : fadeOut} 0.15s ease-out;
  transition: visibility 0.15s ease-out;
`;

const Overlay = styled.div<{ visible: boolean }>`
  width: 100%;
  height: 100%;
  background: ${palette.black.light80};
  color: ${palette.darkBackgroundContrast.white};
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  ${(props) => modalSettings(props.visible)}
`;

const Wrapper = styled.div<{
  visible: boolean;
  width?: number;
  minHeight?: number;
  height?: number;
}>`
  width: ${({ width }) => (width ? width : 530)}px;
  margin: 1.75rem auto;
  position: relative;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: ${palette.night.light10};
  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    position: absolute;
    backdrop-filter: blur(50px);
    border-radius: inherit;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
  ${({ height }) => height && `height: ${height}px`};
  ${(props) => modalSettings(props.visible)}
`;
const HeaderContainer = styled.div`
  ${(props) =>
    props?.isScrolled &&
    `height: 83px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  backdrop-filter: blur(25px);
  background: ${palette.darkBackgroundContrast.light5};`}
`;

const Header = styled.div<{ padding: string; textAling: string }>`
  padding: ${({ padding }) => (padding ? padding : '48px 24px 12px 24px')};
  margin: 0;
  text-align: ${({ textAling }) => textAling || 'left'}; ;
`;

interface IModalBody {
  isTxtModal?: boolean;
  hasScrollBar?: boolean;
  hasButtonPadding?: boolean;
}

const Body = styled.div<IModalBody>`
  ${({ isTxtModal }) => !isTxtModal && `padding: 0px 24px 24px 24px;`}

  ${({ hasScrollBar }) => hasScrollBar && `overflow-y: scroll;`}

  margin: 0;
  div.modalButtons {
    ${({ hasButtonPadding }) => hasButtonPadding && 'padding: 24px 0px 0px 0px'};

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    button {
      flex: 1 1 auto;
    }
  }
  /* max-width: ${({ hasScrollBar }) => (hasScrollBar ? '99.7%' : '100%')}; */
  height: 100%;
`;

const Footer = styled.div<{ backgroundColor?: string; backdropFilter?: string }>`
  padding: 0px 24px 24px 24px;
  margin: 0;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  button {
    flex: 1 1 auto;
  }
  background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '')};
  backdrop-filter: ${({ backdropFilter }) => (backdropFilter ? backdropFilter : '')};
`;

const IconContainer = styled.button`
  width: 22px;
  height: 22px;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
`;

export { Overlay, Wrapper, IconContainer, Header, Body, Footer, HeaderContainer };
