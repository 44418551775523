import React, { useState, VFC } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AT_LEAST_NINE_CHARACTERS,
  AT_LEAT_ONE_LOWERCASE_LATTER,
  AT_LEAT_ONE_NUMBER,
  AT_LEAT_ONE_UPPERCASE_LATTER,
} from "lib/constants/regexp";
import Button from "components/atoms/Button";
import Input from "components/atoms/Input";
import { palette } from "lib/theme";
import {
  emailValidation,
  emptyStringValidation,
  passwordComplexityValidation,
} from "lib/utils/validations";
import { useDispatch } from "react-redux";
import { getText } from "shared/locale-helper";
import { PagesUrl } from "lib/constants/config.constant";
import { useLoginMutation, useSetupPasswordMutation } from "state/store/api";
import { saveTokenToValidate, setCredentials } from "state/slice/auth.slice";
import EmailUs from 'components/atoms/EmailUs/EmailUs';
import { TypesNamesEnum } from 'enums/Button.enum';
import { toast, ToastType } from 'components/organisms/Toast'

const SetupPassword: VFC = () => {
  const [searchParams] = useSearchParams();

  const emailUrl = searchParams.get("email") ?? "";
  const tokenUrl = searchParams.get("token") ?? "";

  const [email, setEmail] = useState<string>(emailUrl);
  const [password, setPassword] = useState("");

  const [pwdEnforce, setpwdEnforce] = useState([
    {
      text: getText("1_upper_case_letter"),
      icon: "Tick",
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAT_ONE_UPPERCASE_LATTER,
    },
    {
      text: getText("9_characters"),
      icon: "Tick",
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAST_NINE_CHARACTERS,
    },
    {
      text: getText("1_lower_case_letter"),
      icon: "Tick",
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAT_ONE_LOWERCASE_LATTER,
    },
    {
      text: getText("1_number"),
      icon: "Tick",
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAT_ONE_NUMBER,
    },
  ]);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [setupPassword, { isLoading }] = useSetupPasswordMutation();
  const [login, { isLoading: loginLoading }] = useLoginMutation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const errorsDetails = {
    tooManyAtempsSignUp: {
      icon: "SignInBlocked",
      title: getText("access_suspended"),
      details: getText("wrong_2fa_code_signup"),
      confirmText: getText("got_it"),
      onConfirm: () => { },
    },
    blockedSignUp: {
      icon: "SignInBlocked",
      title: getText("access_suspended"),
      details: getText("wrong_2fa_code_signup_too_many_times"),
      confirmText: getText("contact_customer_support_capital_s"),
      onConfirm: () => { },
    },
    default: {
      icon: "SignInBlocked",
      title: "",
      details: "",
      confirmText: "",
      onConfirm: () => { },
    },
  };

  const getPasswordRegexMatchMessage = (_password: any[]): string => {
    const rulesMatched = _password.find(
      (el) => el.color === palette.darkBackgroundContrast.light20
    );

    return rulesMatched
      ? `${getText("you_re_missing")} ${rulesMatched.text}`
      : "";
  };

  const validateSignUp = (): number => {
    let validationCount = 0;
    setEmailError("");
    setPasswordError("");

    if (emptyStringValidation(email)) {
      validationCount += 1;
      setEmailError(getText("add_your_email_address_to_continue"));
    }

    if (emptyStringValidation(password)) {
      validationCount += 1;
      setPasswordError(getText("create_a_password_to_continue"));
    }

    if (email && !emailValidation(email)) {
      validationCount += 1;
      setEmailError(getText("hmm_that_doesnt_look_right"));
    }

    if (password && !passwordComplexityValidation(password)) {
      validationCount += 1;
      setPasswordError(getPasswordRegexMatchMessage(pwdEnforce));
    }
    return validationCount;
  };

  const handlePasswordOnChange = (event: any) => {
    const paswordValue = event.target.value;
    const patternCheck = pwdEnforce.map((item) => {
      return {
        ...item,
        color: item.pattern.exec(paswordValue)
          ? palette.accent.main
          : palette.darkBackgroundContrast.light20,
      };
    });
    setPassword(paswordValue);
    setpwdEnforce(patternCheck);
  };

  const handleOnSubmit = async () => {
    try {
      if (validateSignUp() > 0) return;

      await setupPassword({
        setupPasswordRequest: {
          email: emailUrl,
          password: password,
          token: tokenUrl,
        },
      }).unwrap();

      const loginData = await login({
        usernameAndPasswordAuthenticationRequest: {
          username: emailUrl,
          password: password,
        },
      }).unwrap();

      dispatch(saveTokenToValidate(loginData));

      toast.show({
        type: ToastType.Success,
        title: "Success",
        content:
          "Your password has been set! please proceed with the two factor authentication to log in!",
      });

      if (loginData.otpIsActive)
          navigate(`${PagesUrl.AUTHENTICATION}${PagesUrl.TWO_FACTOR_AUTH}`);
        else if (!loginData.otpIsActive && loginData.barcodeUri == null) {
          dispatch(
            setCredentials({ accessToken: `Bearer ${loginData.accessToken}` })
          );
          navigate(PagesUrl.CUSTODY);
        } else {
          navigate(`${PagesUrl.AUTHENTICATION}${PagesUrl.ENABLE_2FA}`);
        }
    } catch (error) { }
  };

  return (
    <div style={{ paddingLeft: "4px", marginTop: "3vh" }}>
      <Input
        label={getText("Email")}
        className="input"
        readOnly
        inputProps={{
          value: email,
          id: "email",
          name: "email",
          type: "email",
          onChange: (event: any) => setEmail(event?.target?.value),
          onBlur: (event: any) => {
            setEmailError("");
            if (
              event?.target?.value &&
              !emailValidation(event?.target?.value)
            ) {
              if (event?.target?.value.includes("@"))
                setEmailError(getText("invalid_email_format"));
              else setEmailError(getText("invalid_email_format_at_symbol"));
            }
          },
        }}
        errorMessage={emailError}
        dismissAlert={() => setEmailError("")}
        dismissAlertButton={false}
      />
      <Input
        label={getText("Password")}
        className="input"
        inputProps={{
          value: password,
          id: "password",
          name: "password",
          type: "password",
          onChange: (event: any) => {
            handlePasswordOnChange(event);
            if (passwordComplexityValidation(event?.target?.value)) {
              setPasswordError("");
            }
          },
          onBlur: (event: any) => {
            setPasswordError("");
            if (
              event?.target?.value &&
              !passwordComplexityValidation(event?.target?.value)
            ) {
              setPasswordError(getPasswordRegexMatchMessage(pwdEnforce));
            }
          },
        }}
        additionalInfo={{
          info: pwdEnforce,
        }}
        errorMessage={passwordError}
        dismissAlert={() => setPasswordError("")}
        dismissAlertButton={false}
      />
      <div style={{ marginTop: "2rem" }}>
        <Button
          buttonType={TypesNamesEnum.ACCENT}
          height='48'
          width='100%'
          onClick={handleOnSubmit}
          disabled={Boolean(emailError || passwordError)}
          isLoading={isLoading || loginLoading}
        >
          Continue
        </Button>
      </div>
      <div style={{ padding: "16px 0px" }}>
        <EmailUs />
      </div>
    </div>
  );
};

export default SetupPassword;
