import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Reset: FC<ISVG> = ({ size = 16, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_373_10294)">
      <path
        d="M1 8C1 11.866 4.134 15 8 15C10.589 15 12.845 13.59 14.056 11.5M14.063 15V11.5H10.563"
        stroke={color}
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 8C15 4.134 11.866 1 7.99997 1C5.41097 1 3.15497 2.41 1.94397 4.5M1.93604 1V4.5H5.43604"
        stroke={color}
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_373_10294">
        <rect width="16" height="16" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export default Reset;
