import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Welcome: FC<ISVG> = ({
  size = 88,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="88"
    height="88"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.5998 73.48C20.5804 73.48 14.0798 66.9794 14.0798 58.96"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5999 79.64C17.1784 79.64 7.91992 70.3815 7.91992 58.96"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.96 14.0801C66.9794 14.0801 73.48 20.5806 73.48 28.6001"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.96 7.91992C70.3815 7.91992 79.64 17.1784 79.64 28.5999"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.7199 47.0801L22.4434 30.8036C20.9853 29.3454 18.6216 29.3454 17.1634 30.8036C15.7053 32.2617 15.7053 34.6254 17.1634 36.0836L21.5599 40.4801"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.9998 41.7995L24.0731 21.8719C22.6149 20.4138 20.2512 20.4138 18.7931 21.8719C17.3349 23.3301 17.3349 25.6938 18.7931 27.1519L23.3198 31.6795"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.7999 40.0398L49.2799 27.7198C48.3075 24.3177 50.2778 20.7722 53.6799 19.7998L59.8399 34.7598L65.3804 42.6516C70.5715 50.0454 69.6985 60.1012 63.3106 66.4891C56.048 73.7517 44.2718 73.7517 37.0092 66.4891L16.2799 45.7598C14.8217 44.3016 14.8217 41.938 16.2799 40.4798C17.7381 39.0216 20.1017 39.0216 21.5599 40.4798L33.4399 52.3598"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.1688 25.9684C26.7107 24.5102 26.7107 22.1465 28.1688 20.6883C29.627 19.2302 31.9907 19.2302 33.4488 20.6883L55.44 42.6795"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Welcome;
