/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApiError } from '../models';
import { KycResponsePost } from '../models';
import { NextstepPhotoBody } from '../models';
import { RequestInputDataRoot } from '../models';
/**
 * KycPrimeControllerApi - axios parameter creator
 * @export
 */
export const KycPrimeControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Post the kyc prime next
         * @param {RequestInputDataRoot} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextStep: async (body: RequestInputDataRoot, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling nextStep.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling nextStep.');
            }
            const localVarPath = `/kycprime/next-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get kyc prime next step
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextStep1: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling nextStep1.');
            }
            const localVarPath = `/kycprime/next-step`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post the kyc prime phone next step
         * @param {string} token 
         * @param {NextstepPhotoBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upload: async (token: string, body?: NextstepPhotoBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling upload.');
            }
            const localVarPath = `/kycprime/next-step/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Post the kyc prime phone next step
         * @param {string} token 
         * @param {string} [inputData] 
         * @param {Array<Blob>} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadForm: async (token: string, inputData?: string, photo?: Array<Blob>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling uploadForm.');
            }
            const localVarPath = `/kycprime/next-step/photo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }


            if (inputData !== undefined) { 
                localVarFormParams.append('inputData', inputData as any);
            }
            if (photo) {
                photo.forEach((element) => {
                    localVarFormParams.append('photo', element as any);
                })
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KycPrimeControllerApi - functional programming interface
 * @export
 */
export const KycPrimeControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Post the kyc prime next
         * @param {RequestInputDataRoot} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextStep(body: RequestInputDataRoot, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<KycResponsePost>>> {
            const localVarAxiosArgs = await KycPrimeControllerApiAxiosParamCreator(configuration).nextStep(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get kyc prime next step
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextStep1(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<KycResponsePost>>> {
            const localVarAxiosArgs = await KycPrimeControllerApiAxiosParamCreator(configuration).nextStep1(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Post the kyc prime phone next step
         * @param {string} token 
         * @param {NextstepPhotoBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload(token: string, body?: NextstepPhotoBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<KycResponsePost>>> {
            const localVarAxiosArgs = await KycPrimeControllerApiAxiosParamCreator(configuration).upload(token, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Post the kyc prime phone next step
         * @param {string} token 
         * @param {string} [inputData] 
         * @param {Array<Blob>} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadForm(token: string, inputData?: string, photo?: Array<Blob>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<KycResponsePost>>> {
            const localVarAxiosArgs = await KycPrimeControllerApiAxiosParamCreator(configuration).uploadForm(token, inputData, photo, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * KycPrimeControllerApi - factory interface
 * @export
 */
export const KycPrimeControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Post the kyc prime next
         * @param {RequestInputDataRoot} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextStep(body: RequestInputDataRoot, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<KycResponsePost>> {
            return KycPrimeControllerApiFp(configuration).nextStep(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get kyc prime next step
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextStep1(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<KycResponsePost>> {
            return KycPrimeControllerApiFp(configuration).nextStep1(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post the kyc prime phone next step
         * @param {string} token 
         * @param {NextstepPhotoBody} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upload(token: string, body?: NextstepPhotoBody, options?: AxiosRequestConfig): Promise<AxiosResponse<KycResponsePost>> {
            return KycPrimeControllerApiFp(configuration).upload(token, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Post the kyc prime phone next step
         * @param {string} token 
         * @param {string} [inputData] 
         * @param {Array<Blob>} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadForm(token: string, inputData?: string, photo?: Array<Blob>, options?: AxiosRequestConfig): Promise<AxiosResponse<KycResponsePost>> {
            return KycPrimeControllerApiFp(configuration).uploadForm(token, inputData, photo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KycPrimeControllerApi - object-oriented interface
 * @export
 * @class KycPrimeControllerApi
 * @extends {BaseAPI}
 */
export class KycPrimeControllerApi extends BaseAPI {
    /**
     * 
     * @summary Post the kyc prime next
     * @param {RequestInputDataRoot} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycPrimeControllerApi
     */
    public async nextStep(body: RequestInputDataRoot, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<KycResponsePost>> {
        return KycPrimeControllerApiFp(this.configuration).nextStep(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get kyc prime next step
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycPrimeControllerApi
     */
    public async nextStep1(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<KycResponsePost>> {
        return KycPrimeControllerApiFp(this.configuration).nextStep1(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Post the kyc prime phone next step
     * @param {string} token 
     * @param {NextstepPhotoBody} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycPrimeControllerApi
     */
    public async upload(token: string, body?: NextstepPhotoBody, options?: AxiosRequestConfig) : Promise<AxiosResponse<KycResponsePost>> {
        return KycPrimeControllerApiFp(this.configuration).upload(token, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Post the kyc prime phone next step
     * @param {string} token 
     * @param {string} [inputData] 
     * @param {Array<Blob>} [photo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KycPrimeControllerApi
     */
    public async uploadForm(token: string, inputData?: string, photo?: Array<Blob>, options?: AxiosRequestConfig) : Promise<AxiosResponse<KycResponsePost>> {
        return KycPrimeControllerApiFp(this.configuration).uploadForm(token, inputData, photo, options).then((request) => request(this.axios, this.basePath));
    }
}
