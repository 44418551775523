import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BankAccount } from "services/models";
import { BankControllerApi } from "services";
import { RootState } from "state/store";
import { mappingLinkedAccountProfile } from "shared";

const initialState = {
  bankAccounts: undefined,
  bankProfileAccounts: [],
  loading: false,
} as BankState;

interface BankState {
  bankAccounts: BankAccount[] | null | undefined;
  bankProfileAccounts: BankAccount[] | null | undefined;
  loading: boolean;
}

const BankService = new BankControllerApi();

export const loadingAccountSelector = createSelector(
  (state: RootState) => state.bank,
  (bank: BankState) => bank.loading
);

export const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    setBankaccounts: (state, action: PayloadAction<BankAccount[] | undefined>) => {
      state.bankAccounts = action.payload;
    },
    setBankProfileaccounts: (state, action: PayloadAction<BankAccount[] | undefined>) => {
      state.bankProfileAccounts = action.payload;
    },
    setLoading: (state, action: any) => {
      state.loading = action.payload;
    },
  },
});

export const bankSelector = createSelector(
  (state: RootState) => state.bank,
  (bank: BankState) => bank
);

export const gerUserProfileAccountSelector = createSelector(
  (state: RootState) => state.bank,
  (bank: BankState) => bank.bankProfileAccounts
);

export const getBankAccounts = () => async (dispatch: any, getState: any) => {
  try {
    const { token } = getState().auth;
    dispatch(setLoading(true));
    const { data } = await BankService.getBankAccounts(token);
    dispatch(setBankaccounts(data.bankAccounts));
    dispatch(setBankProfileaccounts(mappingLinkedAccountProfile(data.bankAccounts)));
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const { setBankaccounts, setLoading, setBankProfileaccounts } = bankSlice.actions;

export const bankReducer = bankSlice.reducer;
