/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import globalAxios, { AxiosInstance, AxiosRequestConfig } from "axios";
import axiosRetry from "axios-retry";
import { DOMAIN, URLS } from "constant/urls.constant";
import history from "shared/history";
import { DefaultSettings } from "lib/constants/config.constant";

export const BASE_PATH = URLS?.BE?.replace(/\/+$/, "");

const config: AxiosRequestConfig = {
  baseURL: BASE_PATH,
};

const client: AxiosInstance = globalAxios.create(config);

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (globalAxios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status == 401) {
          // localStorage.removeItem("user");
          // localStorage.removeItem("token");
          // history.replace("/login");
        }
      }
    }

    return Promise.reject(error);
  }
);

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
  csv: ",",
  ssv: " ",
  tsv: "\t",
  pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  protected configuration: Configuration | undefined;

  constructor(
    configuration?: Configuration,
    protected basePath: string = BASE_PATH,
    protected axios: AxiosInstance = client
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.basePath = configuration.basePath || this.basePath;
    }
  }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: "RequiredError" = "RequiredError";
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}
