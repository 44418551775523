import React, { FC } from "react";
import { formatNumberDisplay } from "shared/helper";
import { NumberProps } from "./Number.interface";

const NumberFormatter: FC<NumberProps> = ({
  value,
  stylenumber = "decimal",
  signDisplay = "auto",
  maximumFractionDigits,
  minimunFractionDigits,
  avoidDecimalValue,
}) => {
  let formattedNumber = formatNumberDisplay(
    value,
    stylenumber,
    signDisplay,
    maximumFractionDigits,
    minimunFractionDigits,
    avoidDecimalValue
  );

  return isNaN(value) ? null : <span>{formattedNumber}</span>;
};

export default NumberFormatter;
