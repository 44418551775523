import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import useStrategy from './useStrategy';
import { SpecificDefiBalance, StrategyDetails } from 'state/store/investApi';
import useAssets from 'shared/useAssets';
import { CustodyMainText, CustodyStyledTable } from '../CustodyStyles';
import getUsDollar from 'shared/dollarFormat';
import { Percentage } from 'lib/utils/types';
import TokenQuantity from 'components/atoms/TokenQuantity/TokenQuantity';
import { isNegative } from 'utils/isNegative';

const StrategyDetailsBalance = () => {
  const { strategyIdentifier: encodedStrategy } = useParams();
  const strategyIdentifier = decodeURIComponent(encodedStrategy ?? '');
  const { strategyBalance, strategyDetails } = useStrategy(strategyIdentifier ?? '');
  const isLoading = strategyBalance.isLoading || strategyDetails.isLoading;

  return (
    <div>
      <TokenSummaryTitle>Balance Details</TokenSummaryTitle>
      {!isLoading && !!strategyBalance.data?.length && (
        <CustodyStyledTable>
          <thead>
            <tr>
              <th>Asset</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Current Balance</th>
              <th>Cost Basis</th>
              <th>Unrealized G/L</th>
            </tr>
          </thead>
          <tbody>
            {strategyBalance?.data?.map((record, index) => (
              <SummaryRow key={index} record={record} details={strategyDetails.data} />
            ))}
          </tbody>
        </CustodyStyledTable>
      )}
      {!isLoading && !strategyBalance.data?.length && <CustodyMainText>You don't have any balance records</CustodyMainText>}
      {strategyDetails.data?.dashboardURL && (
        <DeBankUrlBox>
          <StrategyDetailsDescription>
            <StrategyLink href={strategyDetails.data?.dashboardURL} target='_blank'>
              Click here to see your balances on the blockchain
            </StrategyLink>
          </StrategyDetailsDescription>
        </DeBankUrlBox>
      )}
    </div>
  );
};

interface SummaryRowInterface {
  record: SpecificDefiBalance;
  details?: StrategyDetails;
}

const DeBankUrlBox = styled.div`
  display: flex;
  align-items: center;
  gap: 1ch;
  margin-top: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 0px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;
const SummaryRow: React.FC<SummaryRowInterface> = ({ record }) => {
  const { getPriceI, getAssetByIdentifier } = useAssets();
  const asset = getAssetByIdentifier(record.quantity.asset);
  return (
    <tr>
      <td>
        <CustodyMainText>
          {asset?.name ?? record.quantity.asset} <i> {record.pending ? 'Pending' : ''}</i>
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>{getUsDollar(record.conveniencePrice.rate)}</CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          <TokenQuantity asset={record.quantity.asset} quantity={record.quantity.amount} />
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText isNegative={isNegative(record.balance?.amount as string)}>{getUsDollar(record.balance?.amount)}</CustodyMainText>
      </td>
      <td>
        <CustodyMainText>{getUsDollar(record.cost?.amount)}</CustodyMainText>
      </td>
      <td>
        {!!record.unrealizedAmount && (
          <>
            <CustodyMainText isNegative={isNegative(record.unrealizedAmount?.amount)}>
              {getUsDollar(getPriceI(record.unrealizedAmount?.asset, record.unrealizedAmount?.amount))} ({new Percentage(record.unrealizedPercentage).print()})
            </CustodyMainText>
          </>
        )}
      </td>
    </tr>
  );
};

const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 10px;
  letter-spacing: -0.5px;
`;

const StrategyLink = styled.a`
  color: #a399f6;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 0.51px;
`;

const StrategyDetailsName = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  -webkit-letter-spacing: 0.045em;
  -moz-letter-spacing: 0.045em;
  -ms-letter-spacing: 0.045em;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  font-feature-settings: 'ss02' on, 'liga' off;
  color: rgba(255, 255, 255, 0.7);
`;

const StrategyDetailsDescription = styled.p`
  font-weight: 400;
  color: #ffffffee;
  font-size: 18px;
  -webkit-letter-spacing: -0.48px;
  -moz-letter-spacing: -0.48px;
  -ms-letter-spacing: -0.48px;
  letter-spacing: -0.48px;
  line-height: 130%;
`;

export default StrategyDetailsBalance;
