import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const PhotoOff: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M20 19C22.7614 19 25 16.7614 25 14C25 11.2386 22.7614 9 20 9C17.2386 9 15 11.2386 15 14C15 16.7614 17.2386 19 20 19Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0001 23H28.0001C28.0001 23 32.0001 31 20.0001 31C8.00011 31 12.0001 23 12.0001 23Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PhotoOff;
