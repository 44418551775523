import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Credit: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 5H1C0.448 5 0 4.553 0 4C0 3.447 0.448 3 1 3H23C23.552 3 24 3.447 24 4C24 4.553 23.552 5 23 5ZM21 20H3C1.346 20 0 18.654 0 17V8C0 7.447 0.448 7 1 7H23C23.552 7 24 7.447 24 8V17C24 18.654 22.654 20 21 20ZM22 9H2V17C2 17.552 2.448 18 3 18H21C21.552 18 22 17.552 22 17V9ZM16 16H20C20.552 16 21 15.553 21 15C21 14.447 20.552 14 20 14H16C15.448 14 15 14.447 15 15C15 15.553 15.448 16 16 16ZM13 16H4C3.448 16 3 15.553 3 15C3 14.447 3.448 14 4 14H13C13.552 14 14 14.447 14 15C14 15.553 13.552 16 13 16Z"
      fill={color}
    />
  </svg>
);

export default Credit;
