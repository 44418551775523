import React, {FC, useEffect, useState} from 'react';
import PanelWithHeader from 'components/molecules/PanelWithHeader';
import styled from 'styled-components';
import Row from 'components/atoms/Row/Row';
import LoadingStage from 'pages/LoadingStage';
import DefiBorrowPortfolio from './DefiBorrowPortifolio';
import ReviewButton from 'components/atoms/ReviewButton';
import Image from 'components/atoms/Image';
import Column from 'components/atoms/Column/Column';
import { cardData, CardData, loanTransactionMapper, Operation, panelTitle, SectionMapper } from './DefiBorrow.constants';
import LoanDetailsPendingActivity from 'components/pages/custody/invest/LoanDetailsPendingActivity';
import { LoanStatus, LtvStatus, useCommitLoanTransactionMutation, useCreateLoanTransactionRequestMutation, useLazyGetAvailableLoanAssetsQuery, useLazyGetLoanBalancesQuery, useLazyGetLoanPendingTransactionsQuery, useLazyGetLoansQuery } from 'state/store/loanApi';
import { SelectedAsset } from 'interfaces/SelectedAsset.interface';
import LoanOperationModal from 'components/organisms/LoanOperationModal';
import LoanReviewModal from 'components/organisms/LoanReviewModal';
import bigDecimal from 'js-big-decimal';
import LtvMeter from 'components/organisms/LtvMeter/LtvMeter';
import { LoanType } from 'enums/LoanType.enum';
import DefiPortfolio from './DefiPortfolio';
import LoanDetailsActivity from 'components/pages/custody/invest/LoanDetailsActivity';
import { toast, ToastType } from 'components/organisms/Toast';
import usePermissions from 'shared/usePermissions';
import { MarginContainer } from 'components/pages/custody/Custody';
import LtvNotification from './LtvNotification';

interface BorrowPortfolioProps {}

export const BorrowPortfolio: FC<BorrowPortfolioProps> = () => {
  const { canBorrowCustody } = usePermissions();
  const [commitLoanTransaction] = useCommitLoanTransactionMutation()
  const [createLoanTransaction] = useCreateLoanTransactionRequestMutation()

  const [getLoans] = useLazyGetLoansQuery()

  const [transactionPublicUID, setTransactionPublicUID] = useState<string | null>(null);

  const [selectedAsset, setSelectedAsset] = useState<SelectedAsset | null>(null)

  const [isPrincipal , setIsPrincipal] = useState<boolean>()
  const [operation, setOperation] = useState<Operation | null>(null)
  const [step, setStep] = useState<number>(0)

  const [investmentAmount, setInvestmentAmount] = useState('0');

  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openReviewModal, setOpenReviewModal] = useState<boolean>(false)

  const [getAvailableCollateral, availableCollateral] = useLazyGetAvailableLoanAssetsQuery()
  const [getAvailablePrincipal, availablePrincipal] = useLazyGetAvailableLoanAssetsQuery()
  const [getLoanBalances, loanBalances] = useLazyGetLoanBalancesQuery()

  const [getPendingTransactions, loanTransactions] = useLazyGetLoanPendingTransactionsQuery({
    pollingInterval: 300000
  })

  useEffect(() => {
    if(selectedAsset) {
      setSelectedAsset(selectedAsset)
    }
  }, [selectedAsset])

  useEffect(() => {
    getLoanBalances()
    getPendingTransactions("aave")
  }, [])

  const handleStartLoan = async () =>  {
    try {
      await getLoans().unwrap()
      setStep(1)
    } catch (error) {
      console.error('Error fetching loan data:', error)
    }
  }

  useEffect(() => {
    if(loanBalances?.data) {
      const summary = loanBalances.data
      if(summary.type === LoanType.MonitorOnly) {
        setStep(2);
        return
      }

      if(summary.hasLoan && summary.status !== LoanStatus.INACTIVE && canBorrowCustody) {
        setStep(1);
        return;
      }

      setStep(0);
    }
  }, [loanBalances?.data])

  useEffect(() => {
    if(step === 1) {
      getLoanBalances()
      getAvailableCollateral("collateral")
      getAvailablePrincipal("principal")
    }
  }, [step, openReviewModal])

  useEffect(() => {
    setTimeout(() => {
      getPendingTransactions("aave")
    }, 30000)
  }, [openReviewModal])

  const handleReviewModalClose = () => {
    setTransactionPublicUID(null);
    setOpenReviewModal(false)
  }

  const handleOperationModalClose = () => {
    setTransactionPublicUID(null);
    setOpenModal(false)
  }

  const handleCommitTransaction = async () => {
    if(selectedAsset && operation) {
      try {
        if(transactionPublicUID) {
          await commitLoanTransaction(transactionPublicUID).unwrap();
          toast.show({
            type: ToastType.Success,
            title: 'Your request was successful',
            content: '',
          });
        }
      } catch (e) {
        throw e
      } finally {
        setOpenReviewModal(false)
        setOpenModal(false)
      }
    }
  }

  const handleReviewTransaction = async () => {
    try {
      if(selectedAsset && operation) {     
        const transaction = await createLoanTransaction({
          amount: new bigDecimal(investmentAmount).getValue(), 
          currency: selectedAsset.asset?.identifier as string,
          network: selectedAsset.details.network, 
          transactionType: loanTransactionMapper[operation][isPrincipal ? 'principal' : 'collateral']
        }).unwrap();
        setTransactionPublicUID(transaction.publicUID)
      }
    } catch (e) {
      setTransactionPublicUID(null);
      throw e;
    } finally {
      setOpenReviewModal(true)
      setOpenModal(false)
    }
  }

  const Card: React.FC<CardData> = React.memo(({ icon, title, description }) => (
    <CardContainer>
        <CardHeader>
            <Image width={42} height={42} src={icon} alt={`${title} icon`} />
            <CardTitle>{title}</CardTitle>
        </CardHeader>
        <CryptoCard>
            <p>{description}</p>
        </CryptoCard>
    </CardContainer>
  ));

  const sectionMapper: SectionMapper = {
    suppliedCollateral: {
      title: 'Committed Collateral',
      mainOperationButtonTitle: 'Withdraw',
      placeholderCondition: availableCollateral?.data?.collateralBalances?.length === 0,
      placeholderText: 'Select an asset from the "Supply Collateral" section below',
      disabledTooltip: '',
    },
    assetsBorrowed: {
      title: 'Assets Borrowed',
      mainOperationButtonTitle: 'Repay',
      placeholderCondition: availablePrincipal?.data?.principalBalances?.length === 0,
      placeholderText: 'Select an asset from the "Borrow Assets" section below',
      disabledTooltip: '',
    },
    availableCollateral: {
      title: 'Supply Collateral',
      mainOperationButtonTitle: 'Supply',
      placeholderCondition: false,
      placeholderText: '',
      disabledTooltip: 'To use this asset as collateral, please first deposit it in your SMA account',
    },
    availableLoan: {
      title: 'Borrow Assets',
      mainOperationButtonTitle: 'Borrow',
      placeholderCondition: false,
      placeholderText: 'Select an asset as collateral first before choosing assets to loan',
      disabledTooltip: 'To borrow this asset, please first supply and commit collateral from your SMA account'
    }

  }

  const handleOpenModal = () => {
    setInvestmentAmount("0")
    setOpenModal(true)
  }

  return (<MarginContainer>
    <LtvNotification ltvStatus={loanBalances?.data?.ltvStatus} />
    <PanelWithHeader 
      padding='24px'
      width='100%' 
      minHeight='174px' 
      title={panelTitle[step]}
      fullPage
    > 
      {loanBalances.isLoading && (
        <LoaderWrapper>
          <LoadingStage loadingText=' ' />
        </LoaderWrapper>
      )}
      {!loanBalances.isLoading && step === 0 && (
        <>
          <CryptoGrid>
              {cardData.map((card, index) => (
                <Card key={index} {...card} />
              ))}
          </CryptoGrid>
          <StartLoanColumn>
            { canBorrowCustody ? 
              (<ReviewButton               
                handleOnClick={() => handleStartLoan()}
                isDisabled={false}
                text='Take a Loan'
              />
            ) : (
              <ContactButton>
                  Contact{' '}
                  <StyledLink
                      href='mailto:globalsales@abra.com'
                      aria-label='Email global sales'
                  >
                      globalsales@abra.com
                  </StyledLink>
              </ContactButton>
            )}
          </StartLoanColumn>
        </>
      )}
      {!loanBalances.isLoading && !availablePrincipal.isLoading && !availableCollateral.isLoading && step === 1 && (
        <>
          {(availableCollateral.isLoading || availablePrincipal.isLoading) && (
            <LoaderWrapper>
              <LoadingStage loadingText=' ' />
            </LoaderWrapper>
          )} 
          {
            ((availableCollateral.data?.collateralBalances || []).length > 0 || 
            (availablePrincipal.data?.principalBalances || []).length > 0) && (
              <LtvMeter loanBalances={loanBalances}/>
            )}
          <Row justifyContent="space-between">
            <DefiBorrowPortfolio 
              title={sectionMapper["suppliedCollateral"].title}
              mainOperationButtonTitle={sectionMapper["suppliedCollateral"].mainOperationButtonTitle}
              placeholderCondition={sectionMapper["suppliedCollateral"].placeholderCondition}
              placeholderText={sectionMapper["suppliedCollateral"].placeholderText}
              transactionInProgress={loanTransactions.data && loanTransactions.data.length > 0}           
              openModal={handleOpenModal}
              selectAsset={setSelectedAsset}
              handleOperation={() => setOperation('remove')}
              isPrincipal={() => setIsPrincipal(false)}
              availableAssets={availableCollateral.data?.collateralBalances || []}
              step={step}
              width='49%'
              hasBorder
            />
            <DefiBorrowPortfolio 
              title={sectionMapper["assetsBorrowed"].title}
              mainOperationButtonTitle={sectionMapper["assetsBorrowed"].mainOperationButtonTitle}
              placeholderCondition={sectionMapper["assetsBorrowed"].placeholderCondition}
              placeholderText={sectionMapper["assetsBorrowed"].placeholderText}
              transactionInProgress={loanTransactions.data && loanTransactions.data.length > 0} 
              openModal={handleOpenModal}
              selectAsset={setSelectedAsset}
              handleOperation={() => setOperation('remove')}
              isPrincipal={() => setIsPrincipal(true)}
              availableAssets={availablePrincipal.data?.principalBalances || []}
              step={step}
              width='49%'
              hasBorder
            />
          </Row>
          <Row justifyContent="space-between" >
            <DefiBorrowPortfolio 
              title={sectionMapper["availableCollateral"].title}
              mainOperationButtonTitle={sectionMapper["availableCollateral"].mainOperationButtonTitle}
              disabledTooltip={sectionMapper["availableCollateral"].disabledTooltip}
              transactionInProgress={loanTransactions.data && loanTransactions.data.length > 0} 
              openModal={handleOpenModal}
              selectAsset={setSelectedAsset}
              handleOperation={() => setOperation('add')}
              isPrincipal={() => setIsPrincipal(false)}
              availableAssets={availableCollateral.data?.custodyBalances|| []}
              width='49%'
              hasBorder
            />
            <DefiBorrowPortfolio 
              title={sectionMapper["availableLoan"].title}
              mainOperationButtonTitle={sectionMapper["availableLoan"].mainOperationButtonTitle}
              mainOperationButtonDisabled={availableCollateral.data?.collateralBalances?.length === 0}
              placeholderCondition={sectionMapper["availableLoan"].placeholderCondition}
              placeholderText={sectionMapper["availableLoan"].placeholderText}
              disabledTooltip={sectionMapper["availableLoan"].disabledTooltip}
              transactionInProgress={loanTransactions.data && loanTransactions.data.length > 0} 
              openModal={handleOpenModal}
              selectAsset={setSelectedAsset}
              handleOperation={() => setOperation('add')}
              isPrincipal={() => setIsPrincipal(true)}
              availableAssets={availablePrincipal.data?.availableForBorrowBalances || []}
              width='49%'
              hasBorder
            />
          </Row>
          <PaddedRow hasBorder>
            <LoanDetailsPendingActivity loanTransactions={loanTransactions}/>
          </PaddedRow>
          <PaddedRow hasBorder style={{
            marginTop: 10
          }}>
            <LoanDetailsActivity />
          </PaddedRow>
        </>
      )}
      {!loanBalances.isLoading && !availablePrincipal.isLoading && !availableCollateral.isLoading && step === 2 && (
        <DefiPortfolio />
      )}
      {openModal && 
        <LoanOperationModal 
          handleClick={handleReviewTransaction} 
          openModal={openModal} 
          selectedAsset={selectedAsset as SelectedAsset} 
          handleOperationModalClose={handleOperationModalClose}
          investmentAmount={investmentAmount}
          setInvestmentAmount={setInvestmentAmount}
          isPrincipal={isPrincipal || false} 
          operation={operation as Operation}
        />
      }
      {openReviewModal && 
        <LoanReviewModal 
          handleClick={handleCommitTransaction} 
          investmentAmount={investmentAmount}
          isPrincipal={isPrincipal || false} 
          openReviewModal={openReviewModal} 
          operation={operation as Operation}
          selectedAsset={selectedAsset as SelectedAsset}
          handleReviewModalClose={handleReviewModalClose}
          transactionId={transactionPublicUID}
        />}
    </PanelWithHeader>
  </MarginContainer>);
};

const PaddedRow = styled(Row)`
  padding: 24px;
`

export const CustodyClickableText = styled.span`
  cursor: pointer;
  color: #a58bf2;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 50px;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 1rem;
  width: 100%; 
  max-width: 300px;
  margin: 0 auto;
`;

const CardTitle = styled.h2`
  font-size: 1.85rem;
  font-weight: 500;
  margin: 0;
`;

const CryptoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: stretch;
  width: 100%;
`;

const CryptoCard = styled.div`
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
`;


const StartLoanColumn = styled(Column)`
  width: 50%;
  text-align: center;
  align-self: center;
  padding: 24px;
  > div {
    padding: 24px;
  }
`

const LoaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: '100%';
`

const ContactButton = styled.div`
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.text};
    padding: 0.5rem 1rem;
    border-radius: 5px;
    display: inline-block;
    margin-bottom: 2rem;
    font-size: 1.2rem;
`;

const StyledLink = styled.a`
    color: ${props => props.theme.colors.text};
    font-weight: bold;
    text-decoration: underline;
`;





