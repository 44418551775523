import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const InfoCircleBig: FC<ISVG> = ({
  size = 100,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M50 99C76.5097 99 98 77.5097 98 51C98 24.4903 76.5097 3 50 3C23.4903 3 2 24.4903 2 51C2 77.5097 23.4903 99 50 99Z"
      stroke="#E99928"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50 23V27"
      stroke="#E99928"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50 37V79"
      stroke="#E99928"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InfoCircleBig;
