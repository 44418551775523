import React, { FC, useEffect, useRef, useState } from "react"

import { Sizes } from "./DepositDetails"
import { Heading, Text } from "components/atoms/Typography"
import PanelWithHeader from "components/molecules/PanelWithHeader"
import Currency from "components/atoms/Number/Currency"
import { Number } from "components/atoms/Number"
import AbraTable from "components/organisms/AbraTable"
import Row from "components/atoms/Row/Row"
import {
  AttachmentDto,
  OrderDto,
  TransactionDto,
  useDownloadAttachmentMutation,
  useGetTransactionsQuery,
} from "state/store/api"
import Icon, { IconBackground } from "components/atoms/Icon/Icon"
import { capitalize } from "shared"
import LoadingStage from "pages/LoadingStage"
import { TableSortDirection } from "@types/enums/Table.enum"
import { DefaultSettings } from "lib/constants/config.constant"
import { Column } from "components/organisms/AbraTable/interfaces"
import { palette } from "lib/theme"
import axios from "axios"
import { selectCurrentToken } from "state/slice/auth.slice"
import { useSelector } from "react-redux"

interface AttachmentsItem {
  id: string
  depositId: string
  fileName: string
}

interface AttachmentsProps extends Sizes {
  deposit?: OrderDto
  isLoading: boolean
  isFetching: boolean
}

const Attachments: FC<AttachmentsProps> = (props) => {
  const { deposit, isLoading, isFetching } = props
  const token = useSelector(selectCurrentToken)

  const [items, setItems] = useState<AttachmentsItem[]>([])

  const [page, setPage] = useState(1)
  const [sort, setSort] = useState("date")
  const [sortDirection, setSortDirection] = useState(
    TableSortDirection.DESCENDING
  )
  const recordsPerPage = DefaultSettings.RECORDS_PER_PAGE

  useEffect(() => {
    if (!deposit) return

    const response = !deposit?.attachments
      ? []
      : deposit?.attachments
        .slice(page - 1, page + 5)
        .map((item: AttachmentDto) => {
          return {
            id: item.id ?? "-",
            fileName: item.filename ?? "-",
            depositId: deposit.id ?? "-",
          } as AttachmentsItem
        })

    setItems(response)
  }, [deposit])

  const [columns, setColumns] = useState<Column[]>([
    {
      title: "Deposit ID",
      enableSort: true,
      accessor: "orderId",
    },
    {
      title: "File ID",
      enableSort: true,
      accessor: "id",
    },

    {
      title: "File name",
      enableSort: true,
      accessor: "filename",
    },
    {
      title: "",
      enableSort: false,
      accessor: "action",
    },
  ])

  const rows = items.map((item: AttachmentsItem) => {
    const { id, fileName, depositId } = item

    return {
      schema: item,
      orderId: () => <Text>{depositId}</Text>,
      id: () => <Text>{id}</Text>,
      filename: () => <Text>{fileName}</Text>,
      action: () => (
        <div onClick={async () => await handleDownloadAttachment(item)}>
          <Icon name="Download" size={24} color={palette.accent.light} />
        </div>
      ),
    }
  })

  const handleDownloadAttachment = async (item: AttachmentsItem) => {
    try {
      const response = await axios.post(
        `/api/order/${+item.depositId}/download/${+item.id}`,
        {},
        {
          headers: {
            Authorization: token ?? "",
          },
          responseType: "arraybuffer",
        }
      )
      const link = document.createElement("a")
      const url = window.URL.createObjectURL(new Blob([response.data]))
      link.href = url
      link.download = `${item.fileName}.pdf`
      link.click()
    } catch (error) { }
  }

  const handlePaginationChange = (currentPage: number) => {
    setPage(currentPage)
  }

  const handleAscendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key === "transactions" ? "date" : key)
    setSortDirection(TableSortDirection.ASCENDING)
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection:
          column.accessor === key
            ? TableSortDirection.ASCENDING
            : TableSortDirection.UNSORTED,
      }))
    )
    setPage(1)
  }

  const handleDescendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key === "transactions" ? "date" : key)
    setSortDirection(TableSortDirection.DESCENDING)
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection:
          column.accessor === key
            ? TableSortDirection.DESCENDING
            : TableSortDirection.UNSORTED,
      }))
    )
    setPage(1)
  }

  const handleResetSort = (key: string, data: any, setTableData: any) => {
    setSort("date")
    setSortDirection(TableSortDirection.DESCENDING)
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: TableSortDirection.UNSORTED,
      }))
    )
    setPage(1)
  }

  return (
    <PanelWithHeader width="100%" height="563px" title="Attachments" {...props}>
      {isLoading || isFetching ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <LoadingStage loadingText=" " />
        </div>
      ) : deposit?.attachments?.length ? (
        <AbraTable
          columns={columns}
          data={rows}
          showPagination={true}
          isLoading={isLoading}
          isSorting={isFetching}
          activePageNumber={page}
          recordsPerPage={recordsPerPage}
          totalRecords={deposit?.attachments?.length + 1}
          onPaginationChange={handlePaginationChange}
          onAscendingSort={handleAscendingSort}
          onDescendingSort={handleDescendingSort}
          onResetSort={handleResetSort}
          showPaginationCaption={false}
          expandTable
        />
      ) : (
        <EmptyStage />
      )}
    </PanelWithHeader>
  )
}

const EmptyStage = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Icon name="SignUpLoanContract" size={150} />
      <div style={{ marginBottom: "8px" }}>
        <Heading variant="h3" color={palette.gray.main as string}>
          No attachments yet
        </Heading>
      </div>
    </div>
  </div>
)

export default Attachments
