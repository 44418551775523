import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Rocket: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="97"
    height="100"
    viewBox="0 0 99 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.7472 26.9079C48.0701 26.9085 45.5051 27.9806 43.6155 29.8846C41.7257 31.7888 40.6661 34.3691 40.6661 37.0571V38.5571H42.1661H59.3613H60.8613L60.8613 37.0572C60.8613 35.7231 60.5999 34.402 60.0921 33.1693C59.5842 31.9367 58.8396 30.8164 57.9004 29.873C56.961 28.9295 55.8455 28.1816 54.6175 27.6726C53.3902 27.1638 52.075 26.9039 50.7472 26.9079ZM50.7472 26.9079C50.7481 26.9079 50.7489 26.9079 50.7498 26.9079V28.4079M50.7472 26.9079C50.7465 26.9079 50.7457 26.9079 50.7449 26.9079L50.7498 28.4079M50.7498 28.4079C51.8793 28.4042 52.9985 28.6252 54.0431 29.0582C55.0878 29.4913 56.0374 30.1278 56.8374 30.9313C57.6374 31.7348 58.2721 32.6896 58.7052 33.7408C58.9465 34.3264 59.1226 34.9356 59.2312 35.5571H59.3613V37.0571H43.6661H42.1661V35.5571H42.2961C42.5997 33.8202 43.4257 32.2052 44.6802 30.9412C46.29 29.3191 48.4732 28.4079 50.7498 28.4079ZM34.667 98.1667L34.667 43.8649L34.667 38.2848L34.667 38.2755C34.641 34.0553 35.675 29.8977 37.6719 26.1895L37.6723 26.1889L43.2102 15.8934L43.2102 15.8934L50.6117 2.13319L58.0687 15.8949L58.0701 15.8975L63.6051 26.1597C63.6053 26.1601 63.6056 26.1606 63.6058 26.161C65.6 29.8774 66.6517 34.0337 66.6665 38.2598V43.8363V43.8371L66.6395 98.1667H34.667Z"
      stroke="white"
      strokeWidth="3"
    />
    <path
      d="M8.91532 77.8414L8.94254 77.8189L8.96868 77.7952C13.5595 73.6299 17.64 68.9525 21.1324 63.8613C21.2826 63.6425 21.5861 63.5114 21.9789 63.6213C22.1638 63.673 22.2995 63.7662 22.3785 63.8581C22.4461 63.9367 22.4897 64.0308 22.4895 64.1743L22.446 97.6673C22.4457 97.9432 22.2219 98.1666 21.946 98.1666H4.33305C4.05691 98.1666 3.83305 97.9428 3.83305 97.6666L3.83306 88.6374L3.83305 88.6338C3.8281 86.5742 4.28087 84.5388 5.15926 82.6732C6.03766 80.8075 7.3201 79.1574 8.91532 77.8414Z"
      stroke="white"
      strokeWidth="3"
    />
    <path
      d="M91.7256 77.7753L91.7514 77.7996L91.7783 77.8227C93.3073 79.1345 94.5408 80.7832 95.3872 82.6528C96.2337 84.5226 96.6711 86.5652 96.6663 88.6339V88.6374V97.6666C96.6663 97.9428 96.4424 98.1666 96.1663 98.1666H79.3588C79.0828 98.1666 78.859 97.943 78.8588 97.667L78.8381 64.44C78.838 64.2964 78.8819 64.2023 78.9511 64.123C79.0322 64.0299 79.172 63.9353 79.3625 63.8837C79.7685 63.7735 80.0753 63.9124 80.2222 64.133C83.5368 69.1099 87.3945 73.6885 91.7256 77.7753Z"
      stroke="white"
      strokeWidth="3"
    />
    <rect x="0.666504" y="103.833" width="97.5" height="2.49999" fill="white" />
  </svg>
);

export default Rocket;
