import { CheckboxProps } from 'components/atoms/Checkbox/Checkbox.interface';
import { palette } from '../../lib/theme';
import styled from 'styled-components';

export const CheckboxContainer = styled.div<CheckboxProps>`
  margin: ${({ dontUseDefaultMargin }) => (dontUseDefaultMargin ? 0 : 5)}px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const Icon = styled.svg<CheckboxProps>`
  fill: none;
  stroke: white;
  strokewidth: 3px;
`;

export const StyledCheckbox = styled.div<CheckboxProps>`
  margin: ${({ dontUseDefaultMargin }) => (dontUseDefaultMargin ? '0px 8px 0px 0px' : '5px 8px 5px 5px')};
  width: 34px;
  display: inline-block;
  width: ${({ size }) => (size ? size : 20)}px;
  height: ${({ size }) => (size ? size : 20)}px;
  transition: width 2s, height 2s;
  border-radius: 2px;
  color: white;

  background: ${({ checked, disabled }) =>
    disabled ? (checked ? palette.black.disabledBackgroundToggle : '') : checked ? palette.accent.main : palette.darkBackgroundContrast.light0};

  border: ${({ checked, disabled, error }) =>
    disabled
      ? `1px solid ${checked ? palette.black.disabledBackgroundToggle : palette.darkBackgroundContrast.light20}`
      : checked
      ? `1px solid ${error ? palette.red.main : palette.accent.main}`
      : `1px solid ${error ? palette.red.main : palette.darkBackgroundContrast.white}`};

  &:focus {
    border: ${({ checked, disabled }) => (checked ? `1px solid ${palette.accent.main}` : '')};
  }
  &:hover {
    border: ${({ checked, disabled }) => (checked ? `1px solid ${palette.accent.main}` : '')};
  }
  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;
