import React, { Dispatch, SetStateAction } from 'react';
import Button from 'components/atoms/Button';
import Tooltip from 'components/atoms/Tooltip';
import { Info } from 'assets/icons';
import { TypesNamesEnum } from 'enums/Button.enum';
import ConfirmValidAddress from 'pages/Withdraw/ConfirmValidAddress';
import { AssetDto } from 'state/store/api';
import { TransferAddress } from 'state/store/withdrawApi';
import bigDecimal from 'js-big-decimal';
import styled from 'styled-components';
import { num } from './helper';
import { InfoText } from './styles';
import { WarningAmount } from './types';
import useAssets from 'shared/useAssets';
import { ErrorMessage, WarningMessage } from '../Custody';
import { ValueInput } from 'components/atoms/ValueInput/ValueInput';
import FormInputBox from 'components/atoms/FormInputBox';

interface WithdrawFormProps {
    selectedAsset: AssetDto | null;
    selectedNetwork: any; // Replace 'any' with the correct type
    address: TransferAddress | undefined;
    amount: string;
    setAmount: Dispatch<SetStateAction<string>>;
    minError: boolean;
    maxError: boolean;
    warningAmount: WarningAmount;
    generateMaximumAmount: () => bigDecimal;
    calculateAmountStep: (asset: AssetDto) => string;
    getMaximumWithdrawalAmount: () => string;
    startWithdraw: () => void;
    markAsVerified: (address: TransferAddress) => void;
}

const WithdrawForm: React.FC<WithdrawFormProps> = ({
    selectedAsset,
    selectedNetwork,
    address,
    amount,
    setAmount,
    minError,
    maxError,
    warningAmount,
    generateMaximumAmount,
    calculateAmountStep,
    getMaximumWithdrawalAmount,
    startWithdraw,
    markAsVerified,
}) => {
    const assets = useAssets();
    if (!selectedAsset || !selectedNetwork) return null;
    const processInputValue = (inputValue: string) => {
        if (inputValue !== "") {
            inputValue = inputValue.includes(".")
                ? inputValue.replace(/^0+(?=\d)/, "")  // Remove leading zeros before digits
                : String(Number(inputValue));  // Convert to number to remove all leading zeros
        }
        return inputValue;
    };
    const isAmountValid = amount && amount !== '';
    const isAddressValid = address && (
        address.verified || 
        !address.usedWithdrawRequest || 
        (address.usedWithdrawRequest && address.usedWithdrawError)
      );
    const isWarningFree = !warningAmount.amountNotEnough && !warningAmount.estimatedFeeSurpassAvailable;

    const isDisabled = !isAmountValid || !isAddressValid || !isWarningFree || minError || maxError;

    const onChangeAmout = (amount: string) => {
        const inputValue = processInputValue(amount);
        setAmount(num(inputValue).getValue())
    }

    const setMax = () => {
        setAmount(getMaximumWithdrawalAmount())
    }

    return (
        <InputActionsBox>
            <LabelInputBox>
                {address && !address.verified && (
                    <InfoText>
                        For security measures, you may only withdraw up to
                        <Tooltip text='For your security, withdrawals to unverified addresses are capped at $100. Once funds have been successfully transferred, this restriction will be lifted.'>
                            <strong>
                                {' '}
                                {assets.getPriceFormatted(
                                    selectedAsset.name,
                                    generateMaximumAmount().getValue()
                                )}{' '}
                                <Info color='#fffc' size={15} />{' '}
                            </strong>
                        </Tooltip>
                    </InfoText>
                )}
                <ConfirmValidAddress
                    address={address}
                    markAsVerified={markAsVerified}
                />
                <LabelAmount htmlFor='inputAmount'>
                    Select the amount of {selectedAsset.name} to withdraw
                </LabelAmount>
                <FormInputBox>
                    <ValueInput
                        assetName={selectedAsset.name}
                        changeInvestmentAmount={onChangeAmout}
                        error={ !isWarningFree || minError || maxError}
                        inputAmountSize={100}
                        inputAreaSize={100}
                        investmentAmount={amount}
                        renderMaxButton
                        setMax={setMax}   
                    />
                </FormInputBox>
            </LabelInputBox>
            <Button
                height='44'
                disabled={isDisabled}
                error={minError || maxError}
                buttonType={TypesNamesEnum.ACCENT}
                label='Next'
                onClick={startWithdraw}
            />
            {renderWarnings()}
        </InputActionsBox>
    );

    function renderWarnings() {
        return (
            <>
                {!minError && !maxError && warningAmount &&
                    warningAmount.estimatedFeeSurpassAvailable &&
                    !warningAmount.amountNotEnough && (
                        <ErrorMessage>
                            This withdrawal request will fail since the SMA
                            account balance available for fee is lower than the estimated network
                            fee. Please add funds to fee asset before retrying the
                            transaction
                        </ErrorMessage>
                    )}
                {!minError && !maxError && warningAmount && warningAmount.amountExactlyTheSame && (
                    <WarningMessage>
                        This transaction could fail if actual network fee ends
                        up being higher than the estimate due to an unexpected
                        change in the network activity
                    </WarningMessage>
                )}
                {!minError && !maxError && warningAmount && warningAmount.amountNotEnough && (
                    <ErrorMessage>
                        The requested withdrawal amount, combined with the
                        estimated network fee, exceeds the available balance in
                        your SMA account. Kindly revise the withdrawal amount to
                        proceed with the transaction.
                    </ErrorMessage>
                )}
                {address &&
                    !address.verified &&
                    address.usedWithdrawRequest && 
                    !address.usedWithdrawCompleted &&
                    !address.usedWithdrawError && (
                        <ErrorMessage>
                            Unable to withdraw to this address until you have
                            confirmed the receipt of test funds
                        </ErrorMessage>
                    )}
                {minError && (
                    <ErrorMessage>
                        This amount is lower than the transaction minimum
                        allowed.
                    </ErrorMessage>
                )}
                {maxError && (
                    <ErrorMessage>
                        Exceeds maximum amount of{' '}
                        {generateMaximumAmount().getValue()}
                    </ErrorMessage>
                )}
            </>
        );
    }
};


const LabelInputBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1vh;
    margin-bottom: 2vh;
`;

const LabelAmount = styled.label`
    font-size: 0.7rem;
    opacity: 0.9;
`;

const InputActionsBox = styled.div`
    background-color: rgba(163, 153, 246, 0.1);
    padding: 1vw 1vw;
    border-radius: 0px 0px 12px 12px;
    flex-grow: 1;
    justify-content: space-between;
    gap: 1vh;
    display: flex;
    flex-direction: column;
`;


export default WithdrawForm;
