import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import {
  PaginationResponse,
  Referral,
  RewardsCampaignInfo,
  RewardsInfo,
  RewardsTransactionsEarned,
} from "services/models";
import { ReferralsResponse } from "services/models/referrals-response";
import { RewardsControllerApi } from "services";
import { RootState } from "state/store";
import { AxiosResponse } from "axios";
import { RewardsTransactionsResponse } from "services/models/rewards-transactions-response";
import { RewardsInfoResponse } from "services/models/rewards-info-response";

const initialState = {
  info: undefined,
  rewardsUserInfo: undefined,
  rewardTransactions: undefined,
  campaings: undefined,
  userJoined: false,
  rewards: [],
  loading: false,
  referralsComplete: {
    data: [],
    pagination: {
      totalRecords: 0,
      nextPage: 0,
      previousPage: 0,
      totalPages: 0,
    },
  },
  referralsPending: {
    data: [],
    pagination: {
      totalRecords: 0,
      nextPage: 0,
      previousPage: 0,
      totalPages: 0,
    },
  },
} as RewardsState;

interface RewardsState {
  info: RewardsInfo | null | undefined;
  rewardsUserInfo: RewardsInfoResponse | null | undefined;
  rewardTransactions: RewardsTransactionsResponse[];
  campaings: RewardsCampaignInfo | null | undefined;
  userJoined: boolean;
  rewards: RewardsTransactionsResponse[];
  loading: boolean;
  referralsComplete: {
    data: Referral[];
    pagination: PaginationResponse;
  };
  referralsPending: {
    data: Referral[];
    pagination: PaginationResponse;
  };
}

interface TransactionsHistoryParams {
  sortField?: string;
  sortDirection?: string;
  page?: string;
  size?: string;
  status?: string;
}

const RewardsService = new RewardsControllerApi();

export const getTransactions = createAsyncThunk(
  "rewards/transactions",
  async (
    {
      sortField,
      sortDirection,
      page = "1",
      size = "5",
    }: TransactionsHistoryParams,
    { getState }
  ) => {
    const {
      auth: { token },
    } = getState() as RootState;
    const response = (await RewardsService.getRewardsTransactions(
      token,
      sortField,
      sortDirection,
      page,
      size
    )) as AxiosResponse<RewardsTransactionsResponse>;

    return response.data;
  }
);

export const getReferralsComplete = createAsyncThunk(
  "rewards/referralscomplete",
  async (
    {
      sortField,
      sortDirection,
      page = "1",
      size = "5",
    }: TransactionsHistoryParams,
    { getState }
  ) => {
    const {
      auth: { token },
    } = getState() as RootState;
    const response = (await RewardsService.getReferrals(
      token,
      "complete",
      sortField,
      sortDirection,
      page,
      size
    )) as AxiosResponse<ReferralsResponse>;

    return response.data;
  }
);
export const getReferralsPending = createAsyncThunk(
  "rewards/referralspending",
  async (
    {
      sortField,
      sortDirection,
      page = "1",
      size = "5",
    }: TransactionsHistoryParams,
    { getState }
  ) => {
    const {
      auth: { token },
    } = getState() as RootState;
    const response = (await RewardsService.getReferrals(
      token,
      "pending",
      sortField,
      sortDirection,
      page,
      size
    )) as AxiosResponse<ReferralsResponse>;

    return response.data;
  }
);

export const rewardsSlice = createSlice({
  name: "rewards",
  initialState,
  reducers: {
    setRewardInfo: (state, action: PayloadAction<RewardsInfo | undefined>) => {
      state.info = action.payload;
    },
    setRewardUserInfo: (
      state,
      action: PayloadAction<RewardsInfoResponse | undefined>
    ) => {
      state.rewardsUserInfo = action.payload;
    },
    setRewardTransactions: (
      state,
      action
    ) => {
      state.rewardTransactions = action.payload;
    },
    setUserJoined: (state, action: PayloadAction<boolean>) => {
      state.userJoined = action.payload;
    },
    setCampaings: (
      state,
      action: PayloadAction<RewardsCampaignInfo | undefined>
    ) => {
      state.campaings = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getTransactions.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getTransactions.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<RewardsTransactionsResponse[]>
    ) => {
      state.rewards = payload ?? [];
      state.loading = false;
    },
    [getTransactions.rejected.toString()]: (state) => {
      state.loading = false;
    },
    [getReferralsComplete.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getReferralsComplete.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<ReferralsResponse>
    ) => {
      state.referralsComplete = {
        data: payload.referrals,
        pagination: payload.paginationResponse,
      };
      state.loading = false;
    },
    [getReferralsComplete.rejected.toString()]: (state) => {
      state.loading = false;
    },

    [getReferralsPending.pending.toString()]: (state) => {
      state.loading = true;
    },
    [getReferralsPending.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<ReferralsResponse>
    ) => {
      state.referralsPending = {
        data: payload.referrals,
        pagination: payload.paginationResponse,
      };
      state.loading = false;
    },
    [getReferralsPending.rejected.toString()]: (state) => {
      state.loading = false;
    },
  },
});

export const rewardsSelector = createSelector(
  (state: RootState) => state.rewards,
  (rewards: RewardsState) => rewards
);

export const getRewardsTransactions =
  () => async (dispatch: any, getState: any) => {
    try {
      const { token } = getState().auth;
      const { data } = await RewardsService.getRewardsTransactions(token);

      const rewardTransactionsFromRequest = data?.rewardsTransactionsEarned;
      dispatch(setRewardTransactions(rewardTransactionsFromRequest));
    } catch (error) {
      console.warn(error);
    }
  };

  export const getRewardsTransactionsWithParameters =
  (status, sortField, sortDirection, page, size) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const { token } = getState().auth;     
      
      const { data } = await RewardsService.getRewardsTransactions(token, status, sortField, sortDirection, page, size);
      
      const rewardTransactionsFromRequest = data;
      if(rewardTransactionsFromRequest) {
        dispatch(setRewardTransactions(rewardTransactionsFromRequest));
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.warn(error);
    }
  };

export const getRewardInfo = () => async (dispatch: any, getState: any) => {
  try {
    const { token } = getState().auth;
    const { data } = await RewardsService.getRewardInfo(token);

    const rewardInfo = data?.userRewardsInfo;
    dispatch(setRewardInfo(rewardInfo));
    dispatch(setCampaings(data?.userRewardsCampaignInfo));
  } catch (error) {
    console.warn(error);
  }
};

export const getUserRewardInfo = () => async (dispatch: any, getState: any) => {
  try {
    const { token } = getState().auth;
    const { data } = await RewardsService.getRewardInfo(token);

    dispatch(setRewardUserInfo(data));
  } catch (error) {
    console.warn(error);
  }
};

export const joinProgram = () => async (dispatch: any, getState: any) => {
  try {
    const { token } = getState().auth;
    await RewardsService.joinRewardsProgram(token);
    dispatch(setUserJoined(true));
  } catch (error) {
    console.warn(error);
  }
};

export const {
  setRewardInfo,
  setRewardUserInfo,
  setRewardTransactions,
  setCampaings,
  setUserJoined,
  setLoading
} = rewardsSlice.actions;

export const rewardsReducer = rewardsSlice.reducer;
