import { LinkDefaultProps } from "./Link.interface";
import React, { FC } from "react";
import { LinkBase } from "styles/components/link";
import { generateDataCyFullID } from "shared/locale-helper";

const Link: FC<LinkDefaultProps> = ({
  disabled = false,
  label,
  onClick,
  children,
  linkProps,
  size = "small",
  dataCy = "",
  dataCyPrefix = ""
}) => {
  
  const linkLabel = label || children;
  const linkDataCy = generateDataCyFullID(dataCy, dataCyPrefix, linkLabel, "link");

  return (
    <LinkBase
      data-cy={linkDataCy}
      onClick={onClick}
      size={size}
      disabled={disabled}
      {...linkProps}
    >
      {label || children}
    </LinkBase>
  );
};

export default Link;
