import React, { FC } from "react";
import ISVG from "@types/icons/svg";
import { palette } from "lib/theme";
import styled from "styled-components";

export const Unread: FC<ISVG> = ({
  size = 20,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <StyledUnreadIcon
    height={height}
    width={width}
    fill="none"
    viewBox="0 0 20 20"
    {...svgProps}
  >
    <circle
      cx="10"
      cy="10"
      r="9.5"
      stroke={palette.accent.light}
      className="outer-arc"
    />
    <circle cx="10" cy="10" r="4" fill={color || palette.accent.light} />
  </StyledUnreadIcon>
);

const StyledUnreadIcon = styled.svg`
  cursor: pointer;
  & circle.outer-arc {
    transition: all ease 200ms;
    transform: scale(0);
    transform-origin: center;
  }
  &:hover circle.outer-arc {
    transform: scale(1);
  }
`;

export default Unread;
