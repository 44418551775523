import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Sort: FC<ISVG> = ({
  size = 12,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M9.38499 4.9156C9.23699 4.9156 9.08699 4.87161 8.95599 4.77961L5.95 2.6766L2.945 4.7806C2.606 5.0196 2.13799 4.93661 1.90099 4.59561C1.66299 4.25661 1.746 3.7886 2.085 3.5516L5.521 1.14761C5.78 0.965608 6.123 0.965608 6.381 1.14761L9.816 3.5516C10.156 3.7886 10.238 4.25661 10.001 4.59561C9.854 4.80461 9.621 4.9156 9.38499 4.9156Z"
      fill={color}
    />
    <path
      d="M5.9499 10.9895C5.7999 10.9895 5.6489 10.9445 5.5199 10.8535L2.0839 8.44955C1.7449 8.21255 1.6619 7.74455 1.8999 7.40555C2.1369 7.06455 2.6039 6.98255 2.9439 7.22055L5.9499 9.32455L8.95589 7.22055C9.2969 6.98255 9.7639 7.06655 9.9999 7.40555C10.2369 7.74455 10.1549 8.21255 9.8149 8.44955L6.3799 10.8535C6.2509 10.9445 6.0999 10.9895 5.9499 10.9895Z"
      fill={color}
    />
  </svg>
);

export default Sort;
