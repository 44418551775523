import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const RightArrowAllCrypto: FC<ISVG> = ({
  size = 24,
  width = 8,
  height = 15,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M7.53998 6.78998L1.87998 1.13998C1.78702 1.04625 1.67642 0.971853 1.55456 0.921084C1.4327 0.870316 1.30199 0.844177 1.16998 0.844177C1.03797 0.844177 0.907264 0.870316 0.785405 0.921084C0.663546 0.971853 0.552945 1.04625 0.459982 1.13998C0.273731 1.32734 0.169189 1.58079 0.169189 1.84498C0.169189 2.10916 0.273731 2.36261 0.459982 2.54998L5.40998 7.54998L0.459982 12.5C0.273731 12.6873 0.169189 12.9408 0.169189 13.205C0.169189 13.4692 0.273731 13.7226 0.459982 13.91C0.552597 14.0045 0.663042 14.0796 0.784917 14.1311C0.906792 14.1826 1.03767 14.2094 1.16998 14.21C1.30229 14.2094 1.43317 14.1826 1.55505 14.1311C1.67692 14.0796 1.78737 14.0045 1.87998 13.91L7.53998 8.25998C7.64149 8.16633 7.7225 8.05268 7.7779 7.92618C7.83331 7.79968 7.86191 7.66308 7.86191 7.52498C7.86191 7.38687 7.83331 7.25027 7.7779 7.12377C7.7225 6.99727 7.64149 6.88362 7.53998 6.78998Z"
      fill={color}
      fillOpacity="0.7"
    />
  </svg>
);

export default RightArrowAllCrypto;
