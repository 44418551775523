import {  CustodyWallet } from './../../../../state/store/custodyApi';
import { useCallback, useEffect, useState } from 'react';
import useAssets from 'shared/useAssets'
import { useCreateCustodyWalletMutation, useGetCustodyAssetsForDepositQuery } from 'state/store/custodyApi'
import useNetworks from 'pages/WhitelistedAddresses/useNetworks'

const useDeposits = () => {
  const [walletInformation, setWalletInformation] = useState<CustodyWallet>();
  const networksHook = useNetworks({ custodyDeposit: true, custodyWithdraw: false, boost: false})
  const assets = useAssets()
  const getCustodyAssets = useGetCustodyAssetsForDepositQuery(null)
  const loadingAsset = getCustodyAssets.isLoading
  const availableAssets = getCustodyAssets.data
  const [createCustodyWallet] = useCreateCustodyWalletMutation()
  const {depositAsset, setDepositAsset, setDepositNetwork, selectedDepositNetwork, restart: restartNetworks, loadingNetworks, loadingWallet} = networksHook

  const availableNetworks = networksHook.custodyDepositNetworks ?? []

  const restart = () => {
    restartNetworks()
    setWalletInformation(undefined)
  }

  const retrieveOrCreateWallet = useCallback(async () => {
    const pair = {
      asset: depositAsset!,
      network: selectedDepositNetwork?.network!
    }
    const wallet = await createCustodyWallet(pair).unwrap()
    setWalletInformation(wallet)
  }, [createCustodyWallet, depositAsset, selectedDepositNetwork])


  useEffect(() => {
    if(depositAsset && selectedDepositNetwork) {
      retrieveOrCreateWallet()
    }
  }, [depositAsset, selectedDepositNetwork, retrieveOrCreateWallet])
  
  const identifierAssetsList = availableAssets ?? []
  const assetsForDeposit = identifierAssetsList.map(a => assets.getAssetByIdentifier(a))

  return {
    assetsForDeposit,
    asset: depositAsset,
    setAsset: setDepositAsset,
    availableNetworks,
    walletInformation,
    loadingAsset,
    loadingNetworks,
    loadingWallet,
    selectedNetwork: selectedDepositNetwork,
    setNetwork: setDepositNetwork,
    restart
  }
}

export default useDeposits
