import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const SortUp: FC<ISVG> = ({
  size = 12,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M9.38499 7.9156C9.23699 7.9156 9.08699 7.87161 8.95599 7.77961L5.95 5.6766L2.945 7.7806C2.606 8.0196 2.13799 7.93661 1.90099 7.59561C1.66299 7.25661 1.746 6.7886 2.085 6.5516L5.521 4.14761C5.78 3.96561 6.123 3.96561 6.381 4.14761L9.816 6.5516C10.156 6.7886 10.238 7.25661 10.001 7.59561C9.854 7.80461 9.621 7.9156 9.38499 7.9156Z"
      fill={color}
    />
  </svg>
);

export default SortUp;
