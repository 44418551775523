import mParticle from "@mparticle/web-sdk";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { SelectItem } from "components/molecules/Select/Select.interface";
import { CountryCode } from "libphonenumber-js";
import { userInfo } from "os";
import {
  AssetsControllerApi,
  TransactionsControllerApi,
  UsersControllerApi,
} from "services";
import {
  SendFundsRequest,
  SendFundsSubmitRequest,
  TransactionModel,
  User,
  UserResponse,
} from "services/models";
import { fixedTo } from "shared";
import { RootState } from "state/store";
import { Assets } from "state/store/api";

const initialState = {
  currentStep: "Send",
  canReview: false,
  canSubmit: false,
  isLoadingSendFunds: false,
  isLoadingSubmitFunds: false,
} as SendMoneyState;

const AssetsService = new AssetsControllerApi();
const UsersService = new UsersControllerApi();
const TransactionsService = new TransactionsControllerApi();

export const sendMoneySlice = createSlice({
  name: "sendMoney",
  initialState,
  reducers: {
    reset: () => initialState,
    setStep: (state, action: PayloadAction<string>) => {
      state.currentStep = action.payload;
    },
    setCountry: (state, action: PayloadAction<SelectItem | undefined>) => {
      state.country = action.payload;
    },
    setCountryError: (state, action: PayloadAction<string | undefined>) => {
      state.countryError = action.payload;
    },
    setCode: (state, action: PayloadAction<CountryCode>) => {
      state.code = action.payload;
    },
    setCallingCode: (state, action: PayloadAction<string>) => {
      state.callingCode = action.payload;
    },
    setFlag: (state, action: PayloadAction<string>) => {
      state.flag = action.payload;
    },
    setPhoneNumber: (
      state,
      action: PayloadAction<{ phoneNumber: string; phoneNumberValue?: string }>
    ) => {
      state.phoneNumber = action.payload.phoneNumber;
      state.phoneNumberValue = action.payload.phoneNumberValue;
    },
    setPhoneNumberError: (state, action: PayloadAction<string | undefined>) => {
      state.phoneNumberError = action.payload;
    },
    setFriend: (state, action: PayloadAction<User | undefined>) => {
      state.friend = action.payload;
    },
    setSendAmount: (
      state,
      action: PayloadAction<{ amount: number; value: string }>
    ) => {
      state.sendAmount = action.payload.amount;
      state.sendValue = action.payload.value;
    },
    setSendAmountError: (state, action: PayloadAction<string | undefined>) => {
      state.sendAmountError = action.payload;
    },
    setNote: (state, action: PayloadAction<string>) => {
      state.note = action.payload;
    },
    setHoldings: (state, action: PayloadAction<Assets[]>) => {
      state.holdings = action.payload;

      if (state.send === undefined) {
        state.send = state.holdings[0];
      }
    },
    setSend: (state, action: PayloadAction<Assets | undefined>) => {
      state.send = action.payload;
    },
    setCanReview: (state, action: PayloadAction<boolean>) => {
      state.canReview = action.payload;
    },
    setTransaction: (
      state,
      action: PayloadAction<TransactionModel | undefined>
    ) => {
      state.transaction = action.payload;
    },
    setCanSubmit: (state, action: PayloadAction<boolean>) => {
      state.canSubmit = action.payload;
    },
    setIsLoadingSendFunds: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSendFunds = action.payload;
    },
    setIsLoadingSubmitFunds: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSubmitFunds = action.payload;
    },
  },
});

export const sendMoneySelector = createSelector(
  (state: RootState) => state.sendMoney,
  (sendMoney: SendMoneyState) => sendMoney
);

export const {
  reset,
  setStep,
  setCountry,
  setCountryError,
  setCode,
  setCallingCode,
  setFlag,
  setPhoneNumber,
  setPhoneNumberError,
  setFriend,
  setSendAmount,
  setSendAmountError,
  setNote,
  setHoldings,
  setSend,
  setCanReview,
  setTransaction,
  setCanSubmit,
  setIsLoadingSendFunds,
  setIsLoadingSubmitFunds,
} = sendMoneySlice.actions;

export const getHoldings = () => async (dispatch: any, getState: any) => {
  try {
    const { token } = getState().auth;

    const { data } = await AssetsService.getHoldings(
      token,
      "allocation",
      "desc",
      "1",
      "20"
    );

    const assets: Assets[] =
      data?.assets?.filter(
        (m) => m.fiatCurrency === true || m.stableCoin === true
      ) ?? new Array(0);
    dispatch(setHoldings(assets));
  } catch (error) {
    console.warn(error);
  }
};

export const getFriend = () => async (dispatch: any, getState: any) => {
  try {
    const {
      auth: { token },
      sendMoney: { phoneNumberValue },
    } = getState();

    const { data } = await UsersService.lookupByPhone(token, phoneNumberValue);
    const user = data?.user;

    dispatch(setFriend(user));
  } catch (err) {
    const error = err as AxiosError;
    dispatch(setPhoneNumberError(error.response?.data.message ?? undefined));
  }
};

export const sendFunds =
  () => async (dispatch: any, getState: () => RootState) => {
    dispatch(setIsLoadingSendFunds(true));
    try {
      const {
        auth: { token, user },
        sendMoney: { friend, sendAmount, send },
      } = getState();
      const body = {
        recipientId: friend?.id,
        amount: sendAmount.toString(),
        originAsset: send?.identifier,
        destinationAsset: send?.identifier,
      } as SendFundsRequest;

      const { data } = await TransactionsService.sendFunds(body, token);
      dispatch(setTransaction(data?.transaction));
      dispatch(setCanReview(true));
      
    } catch (err) {
      const error = err as AxiosError;


      switch (error.response?.data.code) {
        case 1505:
        case 1524:
          dispatch(
            setSendAmountError(error.response?.data.message ?? undefined)
          );
          break;
        case 1551:
          dispatch(setCountryError(error.response?.data.message ?? undefined));
          break;
        default:
          dispatch(
            setPhoneNumberError(error.response?.data.message ?? undefined)
          );
          break;
      }
    } finally {
      dispatch(setIsLoadingSendFunds(false));
    }
  };

export const submitFunds =
  () => async (dispatch: any, getState: () => RootState) => {
    dispatch(setIsLoadingSubmitFunds(true));
    try {
      const {
        auth: { token, user },
        sendMoney: { transaction, friend, note },
      } = getState();
      const body = {
        transactionId: transaction?.transactionId,
        notes: note,
      } as SendFundsSubmitRequest;

      await TransactionsService.sendFundsSubmit(body, token);
      mParticle.logEvent(
        'send_money_succeeded',
        mParticle.EventType.Transaction,
        {'sender_uid': user.id
        ,'receiver_amount_received': transaction.quantityDestination.value
        ,'sender_amount_sent': transaction.quantityOrigin.value
        ,'receiver_country': friend.country
        ,'sender_currency': user.userDefaultCurrency.label
        ,'sender_country': user.country
        ,'receiver_currency': transaction.quantityDestination.asset
        ,'network_fee_currency': transaction.networkFee?.currency
        ,'network_fee': transaction.networkFee?.value
        ,'note': note}
      );
      dispatch(setCanSubmit(true));
    } catch (err) {
      const error = err as AxiosError;
      mParticle.logEvent(
        'send_money_failed',
        mParticle.EventType.Transaction,
        {}
      );
      switch (error.response?.data.code) {
        case 1505:
        case 1524:
          dispatch(
            setSendAmountError(error.response?.data.message ?? undefined)
          );
          break;
        case 1551:
          dispatch(setCountryError(error.response?.data.message ?? undefined));
          break;
        default:
          dispatch(
            setPhoneNumberError(error.response?.data.message ?? undefined)
          );
          break;
      }
    } finally {
      dispatch(setIsLoadingSubmitFunds(false));
    }
  };

export const sendMoneyReducer = sendMoneySlice.reducer;

interface SendMoneyState {
  currentStep: string;
  country?: SelectItem;
  countryError: string | undefined;
  code: CountryCode;
  callingCode: string;
  flag: string;
  phoneNumber: string;
  phoneNumberValue?: string;
  phoneNumberError: string | undefined;
  sendAmount: number;
  sendValue: string;
  sendAmountError: string | undefined;
  send: Assets | undefined;
  holdings: Assets[];
  canReview: boolean;
  note: string;
  friend: User | undefined;
  transaction?: TransactionModel;
  canSubmit: boolean;
  isLoadingSendFunds: boolean;
  isLoadingSubmitFunds: boolean;
}
