import { CustodyTransaction, useGetCustodyTransactionsQuery, CustodyPageable } from './../../../../state/store/custodyApi';
import { useState, useEffect } from 'react'

const useTransactions = () => {
  const [loadingTransactions, setLoadingTransactions] = useState(true)
  const [fetchingTransactions, setFetchingTransactions] = useState(true)
  const [transactions, setTransactions] = useState<CustodyTransaction[]>([])
  const [transactionsPageInformation, setTransactionsPageInformation] = useState<CustodyPageable>()
  const [transactionsPage, setTransactionsPage] = useState(1)
  const getCustodyTransactions = useGetCustodyTransactionsQuery({page: transactionsPage}, { pollingInterval: 60000 * 5 })

  useEffect(() => {
    setLoadingTransactions(getCustodyTransactions.isLoading)
    setFetchingTransactions(getCustodyTransactions.isFetching)
  }, [getCustodyTransactions])

  useEffect(() => {
    if(getCustodyTransactions.currentData) {
      setTransactions(getCustodyTransactions.currentData.content)
      const pages = { ...getCustodyTransactions.currentData }
      setTransactionsPageInformation(pages)
    }
  }, [getCustodyTransactions])

  return { transactions, transactionsPageInformation, setTransactionsPage, loadingTransactions, fetchingTransactions }
}

export default useTransactions
