import React, { FC, useEffect, useState } from 'react';

import { Sizes } from './DepositDetails';
import { Heading, Text, Title } from 'components/atoms/Typography';
import PanelWithHeader from 'components/molecules/PanelWithHeader';
import Currency from 'components/atoms/Number/Currency';
import Row from 'components/atoms/Row/Row';
import { OrderDto, useGetAssetsQuery } from 'state/store/api';
import LoadingStage from 'pages/LoadingStage';
import { capitalize, formatNumberDisplay } from 'shared';
import { palette } from 'lib/theme';
import { OrderTypeReference } from 'shared/transactionTypeReference';
import getAssetPrice from 'shared/getAssetPrice';
import { pricesSelector } from 'state/slice/prices.slice';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import WithdrawButton from 'pages/Withdraw/WithdrawButton';

interface DepositBalanceProps extends Sizes {
  deposit?: OrderDto;
  isLoading: boolean;
  isFetching: boolean;
}

const DepositBalance: FC<DepositBalanceProps> = (props) => {
  const { deposit, isLoading, isFetching } = props;
  const [legends, setLegends] = useState<{ title: string; detail: string }[]>([]);
  const { data: assets } = useGetAssetsQuery();

  const getAssetFromIdentifier = (identifier: string = '') => {
    return assets?.assets?.find((asset) => asset.identifier === identifier.toUpperCase());
  };
  const [balance, setBalance] = useState<{
    assetAmount: number;
    currencyAmount: number;
  }>({ assetAmount: 0, currencyAmount: 0 });
  const prices = useSelector(pricesSelector);

  useEffect(() => {
    if (!deposit && !prices) return;
    const price = prices?.find((m) => m.identifier === deposit?.currency)?.price;

    const assetAmount = getAssetPrice(getAssetFromIdentifier(deposit?.currency), deposit?.balance);
    const currencyAmount = parseFloat(deposit?.balance ?? '0') * parseFloat(price ?? '0');

    setBalance({ assetAmount, currencyAmount });

    const currentLegends = [
      {
        title: 'Open date',
        detail: deposit?.startDate ?? '-',
      },
      { title: 'Investment ID', detail: deposit?.id?.toString() ?? '-' },
      {
        title: 'Status',
        detail: capitalize(deposit?.status ?? '', true) ?? '-',
      },
      { title: 'Currency', detail: deposit?.currency?.toUpperCase() ?? '-' },
      {
        title: 'Initial amount',
        detail: `${deposit?.initialPrincipal} ${deposit?.currency?.toUpperCase()}` ?? '-',
      },
      {
        title: 'Accrued interest',
        detail: `${getAssetPrice(getAssetFromIdentifier(deposit?.currency), deposit?.interestAccrued)}  ${deposit?.currency?.toUpperCase()}` ?? '-',
      },
      { title: 'APR', detail: deposit?.interestRate ?? '-' },
      {
        title: 'Term',
        detail: capitalize(deposit?.termType ?? '', true) === '' ? '-' : capitalize(deposit?.termType ?? '', true),
      },
      {
        title: 'Type',
        detail: OrderTypeReference[deposit?.repaymentMethod] ?? '',
      },
      { title: '', detail: '' },
    ];

    setLegends(currentLegends);
  }, [deposit, prices]);

  return (
    <PanelWithHeader width='100%' minHeight='174px' title={`Balance on deposit #${deposit?.id1Token ?? ''}`} {...props}>
      {isLoading || isFetching ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <LoadingStage loadingText=' ' />
        </div>
      ) : (
        <>
          <Row gap='8px' alignItems='baseline'>
            <BalanceBox>
              <Heading variant='h1'>
                {balance.assetAmount} {deposit?.currency?.toUpperCase()}
              </Heading>
              <Heading variant='h3' color={palette.gray.main as string}>
                <Currency value={balance.currencyAmount} />
              </Heading>
              <WithdrawButton id={deposit?.id} />
            </BalanceBox>
          </Row>
          <Row margin='24px 0px 43px 0px'>
            <Details legends={legends} />
          </Row>
        </>
      )}
    </PanelWithHeader>
  );
};

const Details: FC<{ legends: { title: string; detail: string }[] }> = ({ legends }) => {
  return (
    <Row direction='row' gap='40px' wrap='wrap'>
      {legends.map((m) => {
        return <Detail key={m.title} {...m} />;
      })}
    </Row>
  );
};

const Detail: FC<{ title: string; detail: string }> = ({ title, detail }) => {
  return (
    <Row flex='1 0 16%' direction='column' gap='8px'>
      <Title>{title}</Title>
      <Text size='large' letterSpacing='-0.48px'>
        {detail}
      </Text>
    </Row>
  );
};

const BalanceBox = styled.div`
  display: flex;
  gap: 2vw;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;
export default DepositBalance;
