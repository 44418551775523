import styled from "styled-components";
import { palette, zIndex, breakpoints } from "lib/theme";
import { BreakpointsEnum } from "@types/enums/GridSystem";

export const StyledNav = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ bgColor }: { bgColor: string }) =>
    bgColor || palette.black.light10};
  color: ${palette.darkBackgroundContrast.light20};
  border-right: solid 1px ${palette.darkBackgroundContrast.light10};
  width: 260px;
  display: block;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.menu};
  transition: all ease 300ms;
  @media print{
       display:none;
       width: 0;
  }
  
  @media only screen and (max-width: 1200px) {
    width: 260px;
    left: -500px;

    &.responsive__sidebar {
      left: 0;
    }
  }
`;

export const StyledNavHeader = styled.div<{bigLogo: string; smallLogo: string;}>`
  height: 104px;
  margin: 0;
  background-image: ${({ bigLogo }) => `url("${bigLogo}")`};
  background-repeat: no-repeat;
  background-position-y: 32px;
  background-position-x: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledContactSupport = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 32px;
  gap: 11px;
  cursor: pointer;
  &:hover {
    span {
      color: ${palette.white.main};
    }
    svg path {
      stroke: ${palette.white.main};
    }
  }

  @media only screen and (max-width: 1200px) {
    margin: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 88px;
    svg {
      width: 24px;
      height: 24px;
    }
    div:nth-child(2) {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: row;
    margin-left: 32px;
    width: 150px;
    justify-content: space-between;
    svg {
      width: 22px;
      height: 22px;
    }
    div:nth-child(2) {
      display: block !important;
    }
  }
`;

export const HideMenuButton = styled.div`
  display: none;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  margin-top: -5px;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    display: block;
  }
`;
