import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'state/slice/auth.slice'
import { YearMonth,
  useGetBalanceYearMonthQuery,
  useGetRealizedQuery,
  useGetRealizedWithdrawalsQuery,
  useGetStatementDefiBalancesQuery,
  useGetStatementExpenseSummaryQuery,
  useGetStatementTotalsQuery,
  useGetTransactionsYearMonthQuery
} from 'state/store/statementsApi'


const useCustodyStatement = (props: YearMonth) => {
  const year = props.year
  const month = props.month?.padStart(2, '0')
  const yearMonth = {year, month}

  const user = useSelector(selectCurrentUser)
  const smaAccountNumber = user?.organization?.smaAccountNumber ?? "-"
  const totalStatement = useGetStatementTotalsQuery(yearMonth)
  const realizedGainLoss = useGetRealizedQuery(yearMonth)
  const realizedWithdrawals = useGetRealizedWithdrawalsQuery(yearMonth)
  const transactions = useGetTransactionsYearMonthQuery(yearMonth)
  const balance = useGetBalanceYearMonthQuery(yearMonth)
  const statementDefiBalance = useGetStatementDefiBalancesQuery(yearMonth)
  const totalInvestmentBalance = statementDefiBalance.data?.map(d => d.endingValue?.amount).reduce((a,b) => +a + +b, 0).toFixed(2)
  const beginningInvestmentBalance = statementDefiBalance.data?.map(d => d.startingValue?.amount).reduce((a,b) => +a + +b, 0).toFixed(2)
  const expenseSummary = useGetStatementExpenseSummaryQuery(yearMonth)

  const totalBalancePeriod = balance.data?.map(d => d.endingValue.amount).reduce((a,b) => +a + +b, 0).toFixed(2)
  return { smaAccountNumber, totalInvestmentBalance, balance, realizedGainLoss, realizedWithdrawals, totalStatement, transactions, totalBalancePeriod, expenseSummary, beginningInvestmentBalance }
}

export default useCustodyStatement
