export const VALID_EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const AT_LEAT_ONE_UPPERCASE_LATTER = /(?=.*[A-Z])/;
export const AT_LEAT_ONE_LOWERCASE_LATTER = /(?=.*[a-z])/;
export const AT_LEAT_ONE_NUMBER = /(?=.*\d)/;
export const AT_LEAST_NINE_CHARACTERS = /( *?. *?){9,}/;

export const VALID_PASSWORD_REGEXP =
  `${AT_LEAT_ONE_UPPERCASE_LATTER.source}${AT_LEAT_ONE_LOWERCASE_LATTER.source}${AT_LEAT_ONE_NUMBER.source}${AT_LEAST_NINE_CHARACTERS.source}`.replace(
    "\\/",
    ""
  );

export const VALID_ADDRESS_REGEX = /^[0-9a-zA-Z,./\-/\/\//\s/]+$/;
export const VALID_CITY_REGEX = /^[a-zA-Z/\-//\s/]+$/;
