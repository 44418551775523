import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Docs: FC<ISVG> = ({
  size = 100,
  width = size,
  height = size,
  color = "white",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="26" y="16" width="48" height="4" rx="2" fill="white" />
    <rect x="26" y="31" width="40" height="4" rx="2" fill="white" />
    <rect x="26" y="46" width="33" height="4" rx="2" fill="white" />
    <circle cx="37.5" cy="72.5" r="9.5" stroke="white" strokeWidth="4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84 96L84 4L16 4L16 96L84 96ZM88 4C88 1.79086 86.2091 0 84 0L16 0C13.7909 0 12 1.79086 12 4L12 96C12 98.2091 13.7909 100 16 100L84 100C86.2091 100 88 98.2091 88 96L88 4Z"
      fill={color}
    />
  </svg>
);

export default Docs;
