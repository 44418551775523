import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { LanguageWrapper } from "./components/atoms/LanguageWrapper";
import { Provider } from "react-redux";
import { store } from "./state/store";
import NativeGlobalStyles from "styles/global-styles";

ReactDOM.render(<>
  <NativeGlobalStyles />
  <Provider store={store}>
    <LanguageWrapper>
      <App />
    </LanguageWrapper>
  </Provider>
</>,
  document.getElementById("app")
);
