import React from 'react';
import { MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget'
import Layout from 'components/templates/Layout'
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect'
import InvestHeader from './InvestHeader'
import Widget from 'components/templates/Widget'
import CustodyInvestmentSummary from '../CustodyInvestmentSummary'

const InvestmentMainPage = () => {

  useCustodyRedirect()
  return (
    <Layout>
      <MarginContainer>
        <CustodyNavigationWidget>
          <InvestHeader />
        </CustodyNavigationWidget>
      </MarginContainer>
      <MarginContainer>
      <Widget>
        <CustodyInvestmentSummary includeAllBalances={true} />
      </Widget>
    </MarginContainer>
    </Layout>
  );
};


export default InvestmentMainPage;
