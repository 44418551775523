import { BalanceExplanation, CustodyTransactionsResponse, PendingTransaction } from './custodyApi';
import { emptySplitApi as api } from './emptyApi';
import { AssetAmountPair } from 'interfaces/AssetAmountPair.interface';
import { PendingTransactionRequest } from './loanApi';

export const investApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDefiBalances: build.query<DefiBalanceRow[], null>({
      query: () => ({ url: `/custody/defi/balances?all=true` }),
    }),
    getDefiStrategyDetails: build.query<StrategyDetails, string>({
      query: (strategyIdentifier) => ({ url: `/custody/defi/strategy/${strategyIdentifier}/details` }),
    }),
    getDefiStrategyBalance: build.query<SpecificDefiBalance[], string>({
      query: (strategyIdentifier) => ({ url: `/custody/defi/strategy/${strategyIdentifier}/balances` }),
    }),
    getDefiTransactions: build.query<CustodyTransactionsResponse, DefiTransactionarguments>({
      query: ({ page, strategyIdentifier }) => ({
        url: `/custody/transactions?page=${page}&size=5&strategyIdentifier=${strategyIdentifier}`,
      }),
    }),
    getDefiPendingTransactions: build.query<PendingTransaction[], PendingTransactionRequest>({
      query: ({strategyIdentifier}) => ({ url: `/custody/transactions/pending?strategyIdentifier=${strategyIdentifier}` }),
    }),
    getDefiTransactionsInfo: build.query<DefiTransactionInformation, string>({
      query: (strategy) => ({
        url: `/custody/defi/transaction/info?strategyIdentifier=${strategy}&transactionType=DEPOSIT`,
      }),
    }),
    getDivestTransactionsInfo: build.query<DefiTransactionInformation, string>({
      query: (strategy) => ({
        url: `/custody/defi/transaction/info?strategyIdentifier=${strategy}&transactionType=WITHDRAWAL`,
      }),
    }),
    createDefiTransactionRequest: build.mutation<CreatedTransaction, TransactionRequest>({

      query: (body) => ({ url: `/custody/defi/transaction/request`, body, method: 'POST' }),

    }),
    confirmDefiTransactionRequest: build.mutation<void, string>({
      query: (id) => ({ url: `/custody/defi/transaction/confirm?abraTxUid=${id}`, method: 'POST' }),
    }),
    cancelDefiTransactionRequest: build.mutation<void, string>({
      query: (id) => ({ url: `/custody/defi/transaction/cancel?abraTxUid=${id}`, method: 'POST' }),
    }),
  }),
  overrideExisting: false,
});

export interface CreatedTransaction {
  publicUID: string;
}

export interface TransactionRequest {
  strategyIdentifier: string;
  amount: string;
  currency: string;
  transactionType: 'DEPOSIT' | 'WITHDRAWAL';
}
export interface DefiTransactionInformation {
  availableAmountInAsset: AssetAmountPair;
  totalAmountInAsset: AssetAmountPair;
  availableAmountsInFeeAsset: AssetAmountPair[]
  network: string;
  totalEstimatedFeeAmounts: AssetAmountPair[];
  totalEstimatedFeeInConvenience: AssetAmountPair;
  minimumTransactionAmount: AssetAmountPair;
  stepDetails: StepDetail[];
  strategyFactSheetUrl: string
  nextInvestmentCutoff: string
  lastEffectiveDate: string
  firstExpectedReturnDate: string
  lastExpectedReturnDate: string
  estimatedMinTransactionFeeBasisPoints?: number,
  estimatedMaxTransactionFeeBasisPoints?: number,
  estimatedTransactionFeeIntervals: number,
  nextEffectiveDate?: string,
  explanation?: BalanceExplanation
}

interface StepDetail {
  description: string;
  estimatedFeeAmount: {
    asset: string;
    amount: string;
  };
  estimatedConvenienceFeeAmount: {
    asset: string;
    amount: string;
  };
}
export interface DefiTransactionarguments {
  page: number | string;
  strategyIdentifier: string;
}

export interface DefiTransactionPending {}

export interface StrategyDetails {
  strategyIdentifier: string;
  description: string;
  name: string;
  url: string;
  interval: string;
  annualFeeBasisPoints: string;
  dashboardURL: string;
}

export interface SpecificDefiBalance {
  network: string;
  quantity: {
    asset: string;
    amount: string;
  };
  conveniencePrice: {
    baseCurrency: string;
    quoteCurrency: string;
    rate: string;
    timestamp: string;
  };
  balance?: {
    asset: string;
    amount: string;
  };
  cost?: {
    asset: string;
    amount: string;
  };
  pending: boolean;
  unrealizedPercentage: string;
  unrealizedAmount?: {
    asset: string;
    amount: string;
  };
}
export interface DefiBalanceRow {
  identifier: string;
  network: string;
  strategyName: string;
  strategySource: string;
  yield: {
      apr: string;
      method: string;
      asOf: string;
  };
  balanceCurrency: string;
  valueCurrency: string;
  currentBalance: {
      asset: string;
      amount: string;
  };
  currentPrice: {
      baseCurrency: string;
      quoteCurrency: string;
      rate: string;
      live: boolean;
      timestamp: string;
  };
  currentValue: {
      asset: string;
      amount: string;
  };
  costBasis: {
      asset: string;
      amount: string;
  };
  realizedYTD: {
      asset: string;
      amount: string;
  };
  incomeYTD: {
      asset: string;
      amount: string;
  };
  yieldYTD: {
      asset: string;
      amount: string;
  };
  unrealizedAmount: {
      asset: string;
      amount: string;
  };
  return: {
      firstMonth: string;
      lastMonth: string;
      numMonths: number;
      rate: string;
  }
  unrealizedPercentage: string;
  canInvest: boolean;
  canDivest: boolean;
}

export const {
  useGetDefiBalancesQuery,
  useGetDefiStrategyBalanceQuery,
  useGetDefiStrategyDetailsQuery,
  useGetDefiTransactionsQuery,
  useGetDefiPendingTransactionsQuery,
  useGetDefiTransactionsInfoQuery,
  useCreateDefiTransactionRequestMutation,
  useConfirmDefiTransactionRequestMutation,
  useCancelDefiTransactionRequestMutation,
  useGetDivestTransactionsInfoQuery
} = investApi;
