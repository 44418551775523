import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePermissions from 'shared/usePermissions';
import { setLoadingWithdrawableOrders, setWithdrawableOrders, withdrawSelector } from 'state/slice/withdrawReducer';
import { useGetDepositsForWithdrawQuery } from 'state/store/withdrawApi';

const useWithdrawableOrders = () => {
  const { loadingWithdrawableOrders, withdrawableOrders, hasFetchedWithdrawableOrders } = useSelector(withdrawSelector);
  const permissions = usePermissions();
  const dispatch = useDispatch();
  const skip = !permissions.canWithdraw || hasFetchedWithdrawableOrders || loadingWithdrawableOrders;
  const getDepositsForWithdraw = useGetDepositsForWithdrawQuery(null, { skip });

  const isWithdrawableOrder = (id?: number) => {
    return !!withdrawableOrders.find((o) => o.id == id);
  };

  useEffect(() => {
    const orders = getDepositsForWithdraw.data?.orders;
    if (orders) dispatch(setWithdrawableOrders(orders));
  }, [getDepositsForWithdraw]);

  return {
    withdrawableOrders,
    loadingWithdrawableOrders,
    isWithdrawableOrder,
  };
};

export default useWithdrawableOrders;
