import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { useTypedSelector, RootState } from "state/store";
import {
  UserIdentityInfoRequest,
  UserIdentityInfoResponse,
  UserAddress,
  UserIdentityBasicInfo,
  BasicInfo,
  JurisdictionChecks,
  Country,
} from "../../services/models";
import { IdentityControllerApi } from "../../services/";

interface StepError {
  status: string;
  error: string | undefined;
}

interface StepsErrors {
  address: StepError;
  basicInfo: StepError;
  email: StepError;
  jurisdictionCheck: StepError;
  scan: StepError;
  ssn: StepError;
}

const initialState = {
  basicInfoRequest: undefined,
  address: undefined,
  scanId: undefined,
  jurisdictionCheck: undefined,
  ssn: undefined,
} as {
  basicInfoRequest: BasicInfo | undefined;
  basicInfo: UserIdentityBasicInfo | undefined;
  address: UserAddress | undefined;
  jurisdictionCheck: JurisdictionChecks | undefined;
  scanId: string | undefined;
  ssn: string | undefined;
  blockedCountries: Country[];
  stepsErrors: StepsErrors;
};

const IdentityService = new IdentityControllerApi();

export const kycSlice = createSlice({
  name: "kyc",
  initialState,
  reducers: {
    setScanId: (state, action: PayloadAction<string>) => {
      state.scanId = action.payload;
    },
    setJurisdictionCheck: (
      state,
      action: PayloadAction<JurisdictionChecks>
    ) => {
      state.jurisdictionCheck = action.payload;
    },
    setSsn: (state, action: PayloadAction<string>) => {
      state.ssn = action.payload;
    },
    setPersonalInfo: (
      state,
      { payload }: PayloadAction<UserIdentityBasicInfo | undefined>
    ) => {
      state.basicInfo = payload;
      state.basicInfoRequest = {
        first_name: payload?.firstName,
        last_name: payload?.lastName,
        // TODO: Handle in a diferent wawy
        dateOfBirth: `${
          payload?.dateOfBirth?.dayOfMonth
        }-${payload?.dateOfBirth?.month?.slice(0, 3)}-${
          payload?.dateOfBirth?.year
        }`,
      } as BasicInfo;
    },
    setAddress: (state, action: PayloadAction<UserAddress>) => {
      state.address = action.payload;
      // TODO: Need to get the abbreviations of the country
      state.address.country = "US";
      state.address.line2 = state.address.line2 ?? "";
    },
    setErrors: (state, action: PayloadAction<StepsErrors>) => {
      state.stepsErrors = action.payload;
    },
    setBlockedCountries: (state, action: PayloadAction<Country[]>) => {
      state.blockedCountries = action.payload;
    },
  },
});

export const getStatus = () => async (dispatch: any, getState: any) => {
  try {
    const { token } = getState().auth;
    const { data } = await IdentityService.getStatus(token);

    dispatch(setSsn(data.identityStatus?.ssnStep?.ssn));
    dispatch(setPersonalInfo(data.identityStatus?.basicInfoStep?.basicInfo));
    dispatch(setAddress(data.identityStatus?.addressStep?.address));
  } catch (error) {
    console.warn(error);
  }
};

export const getBlockedCountries =
  () => async (dispatch: any, getState: any) => {
    try {
      const { token } = getState().auth;
      const { data } = await IdentityService.getBlockedCountries(token);

      dispatch(setBlockedCountries(data?.countries ?? []));
    } catch (error) {
      console.warn(error);
    }
  };

export const updateUserInfo = () => async (dispatch: any, getstate: any) => {
  try {
    const {
      kyc: { basicInfoRequest, jurisdictionCheck, ssn, scanId, address },
      auth: { token },
    } = getstate();

    var request = {
      basicInfo: basicInfoRequest,
      jurisdictionCheck,
      address,
      ssn,
      scanId,
    } as UserIdentityInfoRequest;

    const { data } = await IdentityService.updateUserInfo(request, token);

    const { identityStatus } = data;

    const errors: StepsErrors = {
      address: {
        status: identityStatus?.addressStep?.status?.toString() ?? "",
        error: identityStatus?.addressStep?.error,
      },
      basicInfo: {
        status: identityStatus?.basicInfoStep?.status?.toString() ?? "",
        error: identityStatus?.basicInfoStep?.error,
      },
      email: {
        status: identityStatus?.emailVerificationStep?.status?.toString() ?? "",
        error: identityStatus?.emailVerificationStep?.error,
      },
      jurisdictionCheck: {
        status: identityStatus?.jurisdictionCheckStep?.status?.toString() ?? "",
        error: identityStatus?.jurisdictionCheckStep?.error,
      },
      scan: {
        status: identityStatus?.scanStep?.status?.toString() ?? "",
        error: identityStatus?.scanStep?.error,
      },
      ssn: {
        status: identityStatus?.ssnStep?.status?.toString() ?? "",
        error: identityStatus?.ssnStep?.error,
      },
    };

    setErrors(errors);
  } catch (error) {
    console.warn(error);
  }
};

export const {
  setScanId,
  setPersonalInfo,
  setAddress,
  setJurisdictionCheck,
  setSsn,
  setErrors,
  setBlockedCountries,
} = kycSlice.actions;

export const kycReducer = kycSlice.reducer;
