import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const NoTier: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.993 4.64581C17.3981 4.6523 12.9933 6.48164 9.74469 9.73263C6.49605 12.9836 4.66876 17.3909 4.66357 21.9878C4.66876 26.5852 6.49589 30.993 9.74439 34.2447C12.9929 37.4965 17.3977 39.3269 21.993 39.3347C26.5913 39.3321 31.0006 37.5038 34.2526 34.2513C37.5045 30.9989 39.3333 26.5881 39.3372 21.9878C39.332 17.3883 37.5026 12.9788 34.2508 9.72744C30.999 6.47603 26.5905 4.64841 21.993 4.64581Z"
      fill="#C0C0C0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.998 39.3347C26.5945 39.3308 31.0018 37.5026 34.2526 34.2513C37.5045 30.9989 39.3333 26.5881 39.3372 21.9878C39.332 17.3883 37.5026 12.9788 34.2508 9.72744C31.0002 6.47721 26.5937 4.64974 21.998 4.64581V39.3347Z"
      fill="#9D9D9D"
      style={{ mixBlendMode: "darken" }}
    />
    <path
      d="M22.8264 2.99254C26.5555 3.15496 30.1543 4.41202 33.1741 6.60695C36.1938 8.80189 38.5009 11.8376 39.8077 15.3355L42.6616 14.4083C41.1557 10.31 38.4659 6.75196 34.9341 4.18646C31.4023 1.62095 27.1879 0.163744 22.8264 0V2.99254Z"
      fill="#EEEEEE"
    />
    <path
      d="M40.3174 16.9005C40.7752 18.5575 41.0078 20.2687 41.0088 21.9878C41.0106 24.8501 40.3654 27.6758 39.1213 30.2533C37.8773 32.8308 36.0667 35.0935 33.825 36.872L35.5853 39.2955C38.2084 37.2382 40.3292 34.6108 41.7871 31.6121C43.2449 28.6135 44.0017 25.3223 44 21.9878C44.0009 19.9554 43.7187 17.9327 43.1615 15.9782L40.3174 16.9005Z"
      fill="#EEEEEE"
    />
    <path
      d="M4.19251 15.3355C5.49962 11.8368 7.80747 8.80063 10.8282 6.60564C13.8489 4.41064 17.4488 3.15402 21.1787 2.99254V0C16.8168 0.162451 12.6018 1.61916 9.06977 4.18484C5.53774 6.75052 2.8483 10.3092 1.34351 14.4083L4.19251 15.3355Z"
      fill="#EEEEEE"
    />
    <path
      d="M32.4965 37.8434C29.3842 39.9116 25.7293 41.011 21.993 41.0027C18.2585 41.0088 14.6057 39.9096 11.4943 37.8434L9.73389 40.2668C13.3572 42.7055 17.6261 44.0055 21.993 44.0002C26.36 44.0067 30.6293 42.7066 34.252 40.2668L32.4965 37.8434Z"
      fill="#EEEEEE"
    />
    <path
      d="M10.175 36.872C7.93332 35.0935 6.12269 32.8308 4.87864 30.2533C3.6346 27.6758 2.98935 24.8501 2.9912 21.9878C2.99123 20.2683 3.22549 18.5568 3.68751 16.9005L0.838504 15.9782C0.283838 17.9332 0.00168569 19.9555 2.84352e-06 21.9878C-0.00169284 25.3223 0.755023 28.6135 2.21289 31.6121C3.67076 34.6108 5.79154 37.2382 8.41462 39.2955L10.175 36.872Z"
      fill="#EEEEEE"
    />
    <path
      d="M25.2541 19.1669V18.1856C25.2514 17.3991 25.0543 16.6255 24.6803 15.9336L23.6995 14.1281L22.3607 11.6749C22.3397 11.636 22.3085 11.6035 22.2705 11.5808C22.2325 11.5581 22.1891 11.5461 22.1449 11.5461C22.1006 11.5461 22.0572 11.5581 22.0192 11.5808C21.9813 11.6035 21.9501 11.636 21.9291 11.6749L20.6001 14.1281L19.6192 15.9385C19.2444 16.6296 19.0504 17.4043 19.0553 18.1905V19.1718V28.9845H25.2492L25.2541 19.1669ZM20.6491 17.9747C20.6491 17.5713 20.8093 17.1844 21.0944 16.8992C21.3795 16.6139 21.7662 16.4537 22.1694 16.4537C22.3695 16.4531 22.5677 16.4919 22.7527 16.5681C22.9377 16.6442 23.1059 16.7561 23.2476 16.8974C23.3893 17.0387 23.5017 17.2066 23.5784 17.3915C23.6551 17.5763 23.6946 17.7745 23.6946 17.9747H20.6491Z"
      fill="white"
    />
    <path
      d="M17.4808 20.8448C16.7538 22.1811 15.809 23.3866 14.6854 24.4117C14.3777 24.667 14.1302 24.9872 13.9606 25.3494C13.7911 25.7116 13.7036 26.1068 13.7046 26.5068V28.4398H17.471L17.4808 20.8448Z"
      fill="white"
    />
    <path
      d="M26.8478 20.8448C27.5748 22.1811 28.5196 23.3866 29.6432 24.4117C29.9509 24.667 30.1984 24.9872 30.3679 25.3494C30.5375 25.7116 30.6249 26.1068 30.624 26.5068V28.4398H26.8527L26.8478 20.8448Z"
      fill="white"
    />
    <rect x="12" y="31" width="20" height="1" fill="white" />
  </svg>
);

export default NoTier;
