import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const NoHoldings: FC<ISVG> = ({
  size = 158,
  width = 151,
  height = 158,
  color,
  svgProps,
}) => {
  return (
    <svg
      width={svgProps?.width || width}
      height={svgProps?.height || height}
      viewBox="0 0 151 158"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M142.274 57.3993L147.131 33.5894C147.846 30.0829 145.583 26.6606 142.076 25.9446L27.7902 2.63199C24.2837 1.91676 20.8615 4.17992 20.1454 7.68641L3.63199 88.6394C2.91676 92.1459 5.17989 95.5681 8.68638 96.2842L122.973 119.597C126.479 120.312 129.901 118.05 130.617 114.542L135.15 92.3201"
        stroke={color}
        strokeWidth="3.24"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M119.665 80.6939C125.034 80.6939 129.385 76.3421 129.385 70.9739C129.385 65.6057 125.034 61.2539 119.665 61.2539C114.297 61.2539 109.945 65.6057 109.945 70.9739C109.945 76.3421 114.297 80.6939 119.665 80.6939Z"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.901 91.8502L116.266 87.6406C107.061 85.763 101.121 76.7785 102.999 67.5745C104.876 58.3696 113.861 52.4299 123.065 54.3075L143.7 58.5171C145.453 58.8751 146.584 60.5858 146.227 62.3395L140.722 89.3238C140.365 91.0766 138.653 92.2074 136.901 91.8502Z"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2578 24.8555L137.545 48.1681"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.8867 46.2832L99.0459 62.6347"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7891 113.424L25.5278 127.96"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3867 124.561L28.9238 116.822"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9023 141.83L41.7677 145.957"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.2695 148.326L39.3965 139.461"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.7383 131.479L67.3892 143.35"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.1289 140.74L70.0003 134.088"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.5352 154.684L82.0691 152.85"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.2183 156.533L78.3828 150.998"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M93.6094 129.785L101.056 132.813"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.8203 135.023L98.848 127.576"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoHoldings;
