import ZIndex from "@types/theme/zIndex";

const zIndex: ZIndex = {
  outOfRange: -1,
  base: 1,
  swapIcon: 2,
  header: 10,
  menu: 1000,
  sidebar: 1000,
  modal: 1001,
  toast: 1002,
  autoComplete: 1003,
  popover: 1005,
  globalIcon: 1006,
};

export default zIndex;
