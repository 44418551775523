import React, { FC } from "react";
import { Text } from "components/atoms/Typography";
import { IAlert, AlertType } from "./Alert.interface";
import { palette, zIndex } from "lib/theme";
import styled from "styled-components";

const Alert: FC<IAlert> = (props) => {
  const { message, variant, isVisible } = props;

  if (!message) return null;

  return (
    <div style={{ position: "relative" }}>
      <Container variant={variant} isVisible={isVisible}>
        {typeof message === "string" ? (
          <Message size="tiny">{message}</Message>
        ) : (
          message
        )}
      </Container>
    </div>
  );
};

interface ContainerProps {
  isVisible: boolean;
  variant: string;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  backdrop-filter: blur(15px);
  border-radius: 8px 8px 0px 0px;
  padding: 8.5px 8px;
  justify-content: space-between;
  visibility: ${({ isVisible }: { isVisible: boolean }) =>
    isVisible ? "visible" : "hidden"};
  background: ${({ variant }: { variant: string }) => {
    switch (variant) {
      case AlertType.ERROR:
        return palette.red.light70;
      case AlertType.WARNING:
        return palette.orange.main;
    }
  }};
  position: absolute;
  bottom: 0px;
  width: 100%;
  font-size: 12px;
  z-index: ${zIndex.base};
`;
const Message = styled(Text)`
  word-wrap: break-word;
`;

export default Alert;
