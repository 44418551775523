/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, {
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
import { ApiError } from "../models";
import { AssetsAddressResponse } from "../models";
import { AssetsResponse } from "../models";
import { ExchangeRateResponse } from "../models";
import { HistoricMarketDataResponse } from "../models";
import { ResponseBase } from "../models";
/**
 * AssetsControllerApi - axios parameter creator
 * @export
 */
export const AssetsControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @param {string} token
     * @param {string} idAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addUserAssets: async (
      token: string,
      idAsset: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling addUserAssets."
        );
      }
      // verify required parameter 'idAsset' is not null or undefined
      if (idAsset === null || idAsset === undefined) {
        throw new RequiredError(
          "idAsset",
          "Required parameter idAsset was null or undefined when calling addUserAssets."
        );
      }
      const localVarPath = `/assets/{idAsset}`.replace(
        `{${"idAsset"}}`,
        encodeURIComponent(String(idAsset))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} token
     * @param {string} idAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserAssets: async (
      token: string,
      idAsset: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling deleteUserAssets."
        );
      }
      // verify required parameter 'idAsset' is not null or undefined
      if (idAsset === null || idAsset === undefined) {
        throw new RequiredError(
          "idAsset",
          "Required parameter idAsset was null or undefined when calling deleteUserAssets."
        );
      }
      const localVarPath = `/assets/{idAsset}`.replace(
        `{${"idAsset"}}`,
        encodeURIComponent(String(idAsset))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get assets list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {string} [assetType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAll: async (
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      assetType?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling get."
        );
      }
      const localVarPath = `/assets/list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sortField !== undefined) {
        localVarQueryParameter["sortField"] = sortField;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (assetType !== undefined) {
        localVarQueryParameter["assetType"] = assetType;
      }

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get assets list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {string} [assetType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      assetType?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling get."
        );
      }
      const localVarPath = `/assets`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sortField !== undefined) {
        localVarQueryParameter["sortField"] = sortField;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (assetType !== undefined) {
        localVarQueryParameter["assetType"] = assetType;
      }

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Asset Address
     * @param {string} token
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssetAddress: async (
      token: string,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getAssetAddress."
        );
      }
      // verify required parameter 'id' is not null or undefined
      if (id === null || id === undefined) {
        throw new RequiredError(
          "id",
          "Required parameter id was null or undefined when calling getAssetAddress."
        );
      }
      const localVarPath = `/assets/{id}/address`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get list of assets based on property request
     * @param {string} token
     * @param {string} configurationProperty
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssetsByProperty: async (
      token: string,
      configurationProperty: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getAssetsByProperty."
        );
      }
      // verify required parameter 'configurationProperty' is not null or undefined
      if (
        configurationProperty === null ||
        configurationProperty === undefined
      ) {
        throw new RequiredError(
          "configurationProperty",
          "Required parameter configurationProperty was null or undefined when calling getAssetsByProperty."
        );
      }
      const localVarPath = `/assets/list-by-property`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (configurationProperty !== undefined) {
        localVarQueryParameter["configurationProperty"] = configurationProperty;
      }

      if (sortField !== undefined) {
        localVarQueryParameter["sortField"] = sortField;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get asset information
     * @param {string} token
     * @param {string} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getByAssetId: async (
      token: string,
      assetId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getByAssetId."
        );
      }
      // verify required parameter 'assetId' is not null or undefined
      if (assetId === null || assetId === undefined) {
        throw new RequiredError(
          "assetId",
          "Required parameter assetId was null or undefined when calling getByAssetId."
        );
      }
      const localVarPath = `/assets/{assetId}`.replace(
        `{${"assetId"}}`,
        encodeURIComponent(String(assetId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} token
     * @param {string} originAsset
     * @param {string} destinationAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConversionRate: async (
      token: string,
      originAsset: string,
      destinationAsset: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getConversionRate."
        );
      }
      // verify required parameter 'originAsset' is not null or undefined
      if (originAsset === null || originAsset === undefined) {
        throw new RequiredError(
          "originAsset",
          "Required parameter originAsset was null or undefined when calling getConversionRate."
        );
      }
      // verify required parameter 'destinationAsset' is not null or undefined
      if (destinationAsset === null || destinationAsset === undefined) {
        throw new RequiredError(
          "destinationAsset",
          "Required parameter destinationAsset was null or undefined when calling getConversionRate."
        );
      }
      const localVarPath = `/assets/conversion-rates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (originAsset !== undefined) {
        localVarQueryParameter["originAsset"] = originAsset;
      }

      if (destinationAsset !== undefined) {
        localVarQueryParameter["destinationAsset"] = destinationAsset;
      }

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get market data of given asset for a specific interval.
     * @param {string} token
     * @param {string} asset Asset to get historic data
     * @param {string} interval Interval of historic data (day/week/month/year/all_the_time)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoricMarketData: async (
      token: string,
      asset: string,
      interval: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getHistoricMarketData."
        );
      }
      // verify required parameter 'asset' is not null or undefined
      if (asset === null || asset === undefined) {
        throw new RequiredError(
          "asset",
          "Required parameter asset was null or undefined when calling getHistoricMarketData."
        );
      }
      // verify required parameter 'interval' is not null or undefined
      if (interval === null || interval === undefined) {
        throw new RequiredError(
          "interval",
          "Required parameter interval was null or undefined when calling getHistoricMarketData."
        );
      }
      const localVarPath = `/assets/market-data/{asset}/{interval}`
        .replace(`{${"asset"}}`, encodeURIComponent(String(asset)))
        .replace(`{${"interval"}}`, encodeURIComponent(String(interval)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get holdings interest list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHoldingInterest: async (
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getHoldingInterest."
        );
      }
      const localVarPath = `/assets/holdings-interest`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sortField !== undefined) {
        localVarQueryParameter["sortField"] = sortField;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get holdings with balance list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHoldings: async (
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getHoldings."
        );
      }
      const localVarPath = `/assets/holdings`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sortField !== undefined) {
        localVarQueryParameter["sortField"] = sortField;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get interest rates
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInterestRates: async (
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getInterestRates."
        );
      }
      const localVarPath = `/assets/interest-rates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sortField !== undefined) {
        localVarQueryParameter["sortField"] = sortField;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all holdings list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWatchlist: async (
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getWatchlist."
        );
      }
      const localVarPath = `/assets/watchlist`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sortField !== undefined) {
        localVarQueryParameter["sortField"] = sortField;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssetsControllerApi - functional programming interface
 * @export
 */
export const AssetsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} token
     * @param {string} idAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addUserAssets(
      token: string,
      idAsset: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).addUserAssets(token, idAsset, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} token
     * @param {string} idAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUserAssets(
      token: string,
      idAsset: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).deleteUserAssets(token, idAsset, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get assets list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {string} [assetType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAll(
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      assetType?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<AssetsResponse>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).getAll(token, sortField, sortDirection, page, size, assetType, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get assets list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {string} [assetType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      assetType?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<AssetsResponse>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).get(token, sortField, sortDirection, page, size, assetType, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get Asset Address
     * @param {string} token
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssetAddress(
      token: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<AssetsAddressResponse>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).getAssetAddress(token, id, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get list of assets based on property request
     * @param {string} token
     * @param {string} configurationProperty
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssetsByProperty(
      token: string,
      configurationProperty: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<AssetsResponse>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).getAssetsByProperty(
        token,
        configurationProperty,
        sortField,
        sortDirection,
        page,
        size,
        options
      );
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get asset information
     * @param {string} token
     * @param {string} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByAssetId(
      token: string,
      assetId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<AssetsResponse>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).getByAssetId(token, assetId, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} token
     * @param {string} originAsset
     * @param {string} destinationAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConversionRate(
      token: string,
      originAsset: string,
      destinationAsset: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ExchangeRateResponse>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).getConversionRate(token, originAsset, destinationAsset, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get market data of given asset for a specific interval.
     * @param {string} token
     * @param {string} asset Asset to get historic data
     * @param {string} interval Interval of historic data (day/week/month/year/all_the_time)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHistoricMarketData(
      token: string,
      asset: string,
      interval: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<HistoricMarketDataResponse>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).getHistoricMarketData(token, asset, interval, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get holdings interest list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHoldingInterest(
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<AssetsResponse>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).getHoldingInterest(
        token,
        sortField,
        sortDirection,
        page,
        size,
        options
      );
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get holdings with balance list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHoldings(
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<AssetsResponse>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).getHoldings(token, sortField, sortDirection, page, size, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get interest rates
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInterestRates(
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<AssetsResponse>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).getInterestRates(token, sortField, sortDirection, page, size, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all holdings list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlist(
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<AssetsResponse>>
    > {
      const localVarAxiosArgs = await AssetsControllerApiAxiosParamCreator(
        configuration
      ).getWatchlist(token, sortField, sortDirection, page, size, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * AssetsControllerApi - factory interface
 * @export
 */
export const AssetsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} token
     * @param {string} idAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addUserAssets(
      token: string,
      idAsset: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return AssetsControllerApiFp(configuration)
        .addUserAssets(token, idAsset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} token
     * @param {string} idAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUserAssets(
      token: string,
      idAsset: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return AssetsControllerApiFp(configuration)
        .deleteUserAssets(token, idAsset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get assets list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {string} [assetType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      assetType?: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<AssetsResponse>> {
      return AssetsControllerApiFp(configuration)
        .get(token, sortField, sortDirection, page, size, assetType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Asset Address
     * @param {string} token
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssetAddress(
      token: string,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<AssetsAddressResponse>> {
      return AssetsControllerApiFp(configuration)
        .getAssetAddress(token, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get list of assets based on property request
     * @param {string} token
     * @param {string} configurationProperty
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssetsByProperty(
      token: string,
      configurationProperty: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<AssetsResponse>> {
      return AssetsControllerApiFp(configuration)
        .getAssetsByProperty(
          token,
          configurationProperty,
          sortField,
          sortDirection,
          page,
          size,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get asset information
     * @param {string} token
     * @param {string} assetId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getByAssetId(
      token: string,
      assetId: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<AssetsResponse>> {
      return AssetsControllerApiFp(configuration)
        .getByAssetId(token, assetId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @param {string} token
     * @param {string} originAsset
     * @param {string} destinationAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConversionRate(
      token: string,
      originAsset: string,
      destinationAsset: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ExchangeRateResponse>> {
      return AssetsControllerApiFp(configuration)
        .getConversionRate(token, originAsset, destinationAsset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get market data of given asset for a specific interval.
     * @param {string} token
     * @param {string} asset Asset to get historic data
     * @param {string} interval Interval of historic data (day/week/month/year/all_the_time)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHistoricMarketData(
      token: string,
      asset: string,
      interval: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<HistoricMarketDataResponse>> {
      return AssetsControllerApiFp(configuration)
        .getHistoricMarketData(token, asset, interval, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get holdings interest list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHoldingInterest(
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<AssetsResponse>> {
      return AssetsControllerApiFp(configuration)
        .getHoldingInterest(
          token,
          sortField,
          sortDirection,
          page,
          size,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get holdings with balance list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHoldings(
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<AssetsResponse>> {
      return AssetsControllerApiFp(configuration)
        .getHoldings(token, sortField, sortDirection, page, size, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get interest rates
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInterestRates(
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<AssetsResponse>> {
      return AssetsControllerApiFp(configuration)
        .getInterestRates(token, sortField, sortDirection, page, size, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all holdings list
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWatchlist(
      token: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<AssetsResponse>> {
      return AssetsControllerApiFp(configuration)
        .getWatchlist(token, sortField, sortDirection, page, size, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AssetsControllerApi - object-oriented interface
 * @export
 * @class AssetsControllerApi
 * @extends {BaseAPI}
 */
export class AssetsControllerApi extends BaseAPI {
  /**
   *
   * @param {string} token
   * @param {string} idAsset
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async addUserAssets(
    token: string,
    idAsset: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return AssetsControllerApiFp(this.configuration)
      .addUserAssets(token, idAsset, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {string} token
   * @param {string} idAsset
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async deleteUserAssets(
    token: string,
    idAsset: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return AssetsControllerApiFp(this.configuration)
      .deleteUserAssets(token, idAsset, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get assets list
   * @param {string} token
   * @param {string} [sortField]
   * @param {string} [sortDirection]
   * @param {string} [page]
   * @param {string} [size]
   * @param {string} [assetType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async getAll(
    token: string,
    sortField?: string,
    sortDirection?: string,
    page?: string,
    size?: string,
    assetType?: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AssetsResponse>> {
    return AssetsControllerApiFp(this.configuration)
      .getAll(token, sortField, sortDirection, page, size, assetType, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get assets list
   * @param {string} token
   * @param {string} [sortField]
   * @param {string} [sortDirection]
   * @param {string} [page]
   * @param {string} [size]
   * @param {string} [assetType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async get(
    token: string,
    sortField?: string,
    sortDirection?: string,
    page?: string,
    size?: string,
    assetType?: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AssetsResponse>> {
    return AssetsControllerApiFp(this.configuration)
      .get(token, sortField, sortDirection, page, size, assetType, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get Asset Address
   * @param {string} token
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async getAssetAddress(
    token: string,
    id: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AssetsAddressResponse>> {
    return AssetsControllerApiFp(this.configuration)
      .getAssetAddress(token, id, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get list of assets based on property request
   * @param {string} token
   * @param {string} configurationProperty
   * @param {string} [sortField]
   * @param {string} [sortDirection]
   * @param {string} [page]
   * @param {string} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async getAssetsByProperty(
    token: string,
    configurationProperty: string,
    sortField?: string,
    sortDirection?: string,
    page?: string,
    size?: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AssetsResponse>> {
    return AssetsControllerApiFp(this.configuration)
      .getAssetsByProperty(
        token,
        configurationProperty,
        sortField,
        sortDirection,
        page,
        size,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get asset information
   * @param {string} token
   * @param {string} assetId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async getByAssetId(
    token: string,
    assetId: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AssetsResponse>> {
    return AssetsControllerApiFp(this.configuration)
      .getByAssetId(token, assetId, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {string} token
   * @param {string} originAsset
   * @param {string} destinationAsset
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async getConversionRate(
    token: string,
    originAsset: string,
    destinationAsset: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ExchangeRateResponse>> {
    return AssetsControllerApiFp(this.configuration)
      .getConversionRate(token, originAsset, destinationAsset, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get market data of given asset for a specific interval.
   * @param {string} token
   * @param {string} asset Asset to get historic data
   * @param {string} interval Interval of historic data (day/week/month/year/all_the_time)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async getHistoricMarketData(
    token: string,
    asset: string,
    interval: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<HistoricMarketDataResponse>> {
    return AssetsControllerApiFp(this.configuration)
      .getHistoricMarketData(token, asset, interval, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get holdings interest list
   * @param {string} token
   * @param {string} [sortField]
   * @param {string} [sortDirection]
   * @param {string} [page]
   * @param {string} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async getHoldingInterest(
    token: string,
    sortField?: string,
    sortDirection?: string,
    page?: string,
    size?: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AssetsResponse>> {
    return AssetsControllerApiFp(this.configuration)
      .getHoldingInterest(token, sortField, sortDirection, page, size, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get holdings with balance list
   * @param {string} token
   * @param {string} [sortField]
   * @param {string} [sortDirection]
   * @param {string} [page]
   * @param {string} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async getHoldings(
    token: string,
    sortField?: string,
    sortDirection?: string,
    page?: string,
    size?: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AssetsResponse>> {
    return AssetsControllerApiFp(this.configuration)
      .getHoldings(token, sortField, sortDirection, page, size, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get interest rates
   * @param {string} token
   * @param {string} [sortField]
   * @param {string} [sortDirection]
   * @param {string} [page]
   * @param {string} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async getInterestRates(
    token: string,
    sortField?: string,
    sortDirection?: string,
    page?: string,
    size?: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AssetsResponse>> {
    return AssetsControllerApiFp(this.configuration)
      .getInterestRates(token, sortField, sortDirection, page, size, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get all holdings list
   * @param {string} token
   * @param {string} [sortField]
   * @param {string} [sortDirection]
   * @param {string} [page]
   * @param {string} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsControllerApi
   */
  public async getWatchlist(
    token: string,
    sortField?: string,
    sortDirection?: string,
    page?: string,
    size?: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AssetsResponse>> {
    return AssetsControllerApiFp(this.configuration)
      .getWatchlist(token, sortField, sortDirection, page, size, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
