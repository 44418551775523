import StrongText from "components/atoms/StrongText"
import { AssetAmountPair } from "interfaces/AssetAmountPair.interface"
import React from "react"
import useAssets from "shared/useAssets"

interface GetEstimatedFeeProps {
    totalEstimatedFeeAmount?: AssetAmountPair,
    totalEstimatedFeeInConvenience?: AssetAmountPair
}

const GetEstimatedFee = ({totalEstimatedFeeAmount, totalEstimatedFeeInConvenience}: GetEstimatedFeeProps) => {
    const {getPriceFormattedI} = useAssets();

    if(!totalEstimatedFeeAmount && !totalEstimatedFeeInConvenience) return null
    return (    
        <p>
            Estimated network fee is{' '}
            <StrongText>
                {getPriceFormattedI(totalEstimatedFeeAmount?.asset, totalEstimatedFeeAmount?.amount)}
            </StrongText>
            {' '}(${totalEstimatedFeeInConvenience?.amount})
        </p>
    )
}

export default GetEstimatedFee