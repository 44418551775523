import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const EmptySchedule: FC<ISVG> = ({ size = 180 }) => (
  <svg
    width="121"
    height="120"
    viewBox="0 0 121 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.9836 92.5679C72.22 92.5679 91.8675 72.9205 91.8675 48.6841C91.8675 24.4477 72.22 4.79999 47.9836 4.79999C23.7472 4.79999 4.09961 24.4477 4.09961 48.6841C4.09961 72.9205 23.7472 92.5679 47.9836 92.5679Z"
      stroke="white"
      strokeOpacity="0.7"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.9272 80.7071C65.9861 80.7071 80.626 66.3198 80.626 48.5722M15.2285 48.5722C15.2285 30.8245 29.8682 16.4372 47.9272 16.4372"
      stroke="white"
      strokeOpacity="0.7"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M85.0855 74.5051L113.941 103.361C116.878 106.298 116.878 111.084 113.941 114.021C111.003 116.959 106.217 116.959 103.28 114.021L74.4248 85.1662"
      stroke="white"
      strokeOpacity="0.7"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EmptySchedule;
