import { MutationDefinition } from "@reduxjs/toolkit/dist/query";
import { UseMutation } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { Download } from "assets/icons";
import Button from "components/atoms/Button";
import WhiteSpinner from "components/atoms/Spinner/WhiteSpinner";
import Modal from "components/organisms/Modal";
import { toast, ToastType } from "components/organisms/Toast";
import { SizeNamesEnum, TypesNamesEnum } from "enums/Button.enum";
import React, { useState } from "react";
import styled from "styled-components";


interface Props {
    title: string;
    year: number;
    csvMutation: UseMutation<any>;
}

const DownloadCsvButton: React.FC<Props> = ({title, year, csvMutation}) => {
    const [openModal, setOpenModal] = useState(false);
    const [csv, csvMeta] = csvMutation();

    const getCSV = async () => {
        try {
            await csv(year).unwrap();
            toast.show({
                type: ToastType.Success,
                title: 'File download successfully',
                content: 'Check your download folder',
            });
            setOpenModal(false);
        } catch (error) {
            toast.show({
                type: ToastType.Fail,
                title: 'Download failed',
                content: 'Please try again',
            });
        }
    }
    return (<>
        <Modal
            visible={openModal}
            onClose={() => setOpenModal(false)}
            header={`Download ${title}`}
        >
            <ModalBody>
                <ModalDescription>
                    Download {title} for {year}
                </ModalDescription>
                <ButtonRow>
                    <Button
                        buttonType={TypesNamesEnum.SECONDAY}
                        size={SizeNamesEnum.EXTRA_LARGE_PLUS}
                        label='Cancel'
                        onClick={() => setOpenModal(false)}
                    />
                    <Button
                        isLoading={csvMeta.isLoading}
                        buttonType={TypesNamesEnum.ACCENT}
                        size={SizeNamesEnum.EXTRA_LARGE_PLUS}
                        label='Download'
                        onClick={getCSV}
                    />
                </ButtonRow>
            </ModalBody>
        </Modal>
        <HeaderContainer>
            <DownloadButton onClick={() => setOpenModal(true)}>
                {csvMeta.isLoading ? (
                    <WhiteSpinner />
                ) : (
                    <>
                        <Download color='white' size={15} />
                        <span>Download</span>
                    </>
                )}
            </DownloadButton>
        </HeaderContainer>
    </>);
}

const NavigationContainer = styled.div`
    position: relative;
    width: 100%;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
`;

const DownloadButton = styled.button`
    background-color: #6f2acd80;
    border: none;
    border-radius: 6px;
    font-size: 80%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease-in;
    
    &:hover {
        background-color: #292749;
    }
`;

const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: 2vh 2vw;
`;

const ModalDescription = styled.p``;

const ButtonRow = styled.div`
    display: flex;
    justify-content: stretch;
    gap: 1vw;
`;

export default DownloadCsvButton;