import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Notification: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = "",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M12 14V22C9.52567 22.0029 7.13813 21.0884 5.29891 19.4333C3.45968 17.7782 2.29938 15.4999 2.0423 13.039C1.78523 10.5781 2.44963 8.10923 3.90708 6.10972C5.36452 4.1102 7.51151 2.722 9.93301 2.21349C12.3545 1.70497 14.8785 2.11225 17.0172 3.35659C19.1559 4.60093 20.7573 6.59396 21.5118 8.95041C22.2664 11.3069 22.1206 13.8594 21.1025 16.1145C20.0844 18.3697 18.2665 20.1674 16 21.16"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 11C12.5523 11 13 10.5523 13 10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10C11 10.5523 11.4477 11 12 11Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Notification;
