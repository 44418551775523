import { DownArrow, UpArrow } from 'assets/icons';
import Checkbox from 'components/atoms/Checkbox';
import React, { useEffect } from 'react';
import styled from 'styled-components';

interface TransactionFilterDropDownProps {
  stateChanged: (state: string[]) => void;
  options: string[];
}

interface FilterAreaProps {
  expanded: boolean;
}

const TransactionFilterDropDown: React.FC<TransactionFilterDropDownProps> = ({ stateChanged, options }) => {
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>(options);
  const [expanded, setExpanded] = React.useState(false);

  const handleSelect = (option: string) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const everythingIsSelected = () => {
    const excludedOptions: any[] = [];
    const selectedOptionsFiltered = selectedOptions.filter(option => !excludedOptions.includes(option));
    const optionsFiltered = options.filter(option => !excludedOptions.includes(option));
    return selectedOptionsFiltered.length === optionsFiltered.length;
  };

  const toggleSelectAll = () => {
    const excludedOptions: any[] = [];
    const optionsFiltered = options.filter(option => !excludedOptions.includes(option));

    if (everythingIsSelected()) {
      // Keep Income and Interest if they're selected
      const specialOptions = selectedOptions.filter(option => excludedOptions.includes(option));
      setSelectedOptions(specialOptions);
    } else {
      // Keep Income and Interest status when selecting all others
      const specialOptions = selectedOptions.filter(option => excludedOptions.includes(option));
      setSelectedOptions([...optionsFiltered, ...specialOptions]);
    }
  };

  useEffect(() => {
    stateChanged(selectedOptions);
  }, [selectedOptions, stateChanged]);

  return (
      <Container>
        <FilterContainer>
          <RelativeBox>
            <FilterArea expanded={expanded}>
              <CheckboxDiv>
                <Checkbox
                    text="ALL"
                    checked={everythingIsSelected()}
                    onChange={toggleSelectAll}
                />
              </CheckboxDiv>
              <ExpandButton onClick={() => setExpanded(!expanded)}>
                <span style={{ fontSize: '80%', display: 'inline-flex', alignItems: 'center', userSelect: 'none', WebkitUserSelect: 'none'}}>Transaction Filter</span>
                <div>
                  {expanded ? <UpArrow/> : <DownArrow/>}
                </div>
              </ExpandButton>
            </FilterArea>
            <ExpansibleBox expanded={expanded}>
              {options
                  // .filter(o => !['Income', 'Interest'].includes(o))
                  .map((option) => (
                      <Checkbox
                          key={option}
                          text={option}
                          checked={selectedOptions.includes(option)}
                          onChange={() => handleSelect(option)}
                      />
                  ))}
            </ExpansibleBox>
          </RelativeBox>
        </FilterContainer>
      </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const RelativeBox = styled.div`
  position: relative;
`;

const CheckboxDiv = styled.div`
  flex-grow: 1;
  align-self: stretch;
  transition: all 0.3s ease-in;
  &:hover {
    cursor: pointer;
    background-color: #4643a0;
  }
  padding-right: 0px;
`;

const FilterArea = styled.div<FilterAreaProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  border-radius: ${props => props.expanded ? '8px 8px 0 0' : '8px'};
  overflow: hidden;
  color: white;
  background-color: rgba(111, 42, 205, 0.5);
  gap: 5px;
  border: '1px solid #403f6e';
`;

const ExpandButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  flex-grow: 1;
  transition: all 0.3s ease-in;
  padding: 4px;
  &:hover {
    cursor: pointer;
    background-color: #4643a0;
  }
`;

const SpecialOptionsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  color: white;
`;

interface Expandable {
  expanded: boolean;
}

const ExpansibleBox = styled.div<Expandable>`
  height: ${(props) => (props.expanded ? '400px' : '0')};
  position: absolute;
  padding-left: 4px;
  overflow: hidden;
  z-index: 100;
  transition: height 0.3s;
  background-color: #222140;
  width: 200px;
  box-sizing: border-box;
  border: ${props => props.expanded ? '1px solid #403f6e': 'none'};
  border-top: none;
  border-radius: 0 0 8px 8px;
`;

export default TransactionFilterDropDown;