import React, { useEffect } from 'react';
import { Heading } from 'components/atoms/Typography';
import {
  StyledHeading,
  StyledFormWrapper,
  StyledForm,
  StyledAuthenticationContainer,
  StyleAuthenticationFormContainer,
  StyleAuthenticationCarouselContainer,
} from 'styles/components/authentication';
import Footer from 'components/molecules/Footer/Footer';
import styled from 'styled-components';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading';
import useOnboarding from './useOnboarding'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PagesUrl } from 'lib/constants/config.constant';

const RedirectFromOnboardingEmail = () => {
  const { onboardingStatus } = useOnboarding()



  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const s = searchParams.toString()
  
  useEffect(() => {
    if(onboardingStatus.data){
      const status = onboardingStatus.data.status
      if(status === 'IDENTITY'){
        navigate(`${PagesUrl.ONBOARDING}/kyc?`+s)
      } else if (status === 'TOS_DOCUMENT_SIGNATURE') {
        navigate(`${PagesUrl.ONBOARDING}/sign?`+s)
      } else if (status === 'SMA_ACCOUNT_SETUP') {
        navigate(`${PagesUrl.ONBOARDING}/password?`+s)
      } else if (status === 'LOGIN_SETUP') {
        navigate(`${PagesUrl.ONBOARDING}/password?`+s)
      } else if (status === 'DONE') {
        navigate(`${PagesUrl.AUTHENTICATION}/sign-in?onboarding=done`)
      } else if (status === 'FAILED') {
        navigate(`${PagesUrl.AUTHENTICATION}/sign-in?onboarding=failed`)
      } 
    } else if(onboardingStatus.error) {
      navigate(`${PagesUrl.AUTHENTICATION}/sign-in`)
    }

  }, [onboardingStatus, navigate, s])
  
  return (
    <StyledAuthenticationContainer>
      <CarrouselContainer>
        <StyleAuthenticationCarouselContainer />
      </CarrouselContainer>
      <StyleAuthenticationFormContainer>
        <FormContainer>
          <StyledFormWrapper>
            <StyledHeading>
              <Heading variant='h2'></Heading>
            </StyledHeading>
            <StyledForm>
              <Loading showText={false} size={SpinnerSizeEnum.LARGE} />
            </StyledForm>
          </StyledFormWrapper>
          <Footer />
        </FormContainer>
      </StyleAuthenticationFormContainer>
    </StyledAuthenticationContainer>
  );
};

const CarrouselContainer = styled.div`
  width: 50vw;
  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2vh;
`;

export default RedirectFromOnboardingEmail;
