import GridSystem from "@types/theme/gridSystem";
import { BreakpointsEnum } from "@types/enums/GridSystem";

const alternativeGridSystem: {
  [BreakpointsEnum.ExtraSmall]: GridSystem;
  [BreakpointsEnum.Small]: GridSystem;
  [BreakpointsEnum.Medium]: GridSystem;
  [BreakpointsEnum.Large]: GridSystem;
  [BreakpointsEnum.ExtraLarge]: GridSystem;
  [BreakpointsEnum.ExtraExtraLarge]: GridSystem;
} = {
  [BreakpointsEnum.ExtraExtraLarge]: {
    columsTemplate: `1fr`,
    rowsTemplate: "auto",
    gridAutoRows: "4px",
    gap: "0 20px",
    margin: "20px 0 0",
    columnStart: 1,
    columnEnd: 2,
  },
  [BreakpointsEnum.ExtraLarge]: {
    columsTemplate: `1fr`,
    rowsTemplate: "auto",
    gridAutoRows: "4px",
    gap: "0 20px",
    margin: "20px 0 0",
    columnStart: 1,
    columnEnd: 2,
  },
  [BreakpointsEnum.Large]: {
    columsTemplate: `1fr`,
    rowsTemplate: "auto",
    gridAutoRows: "4px",
    gap: "0 20px",
    margin: "20px 0 0",
    columnStart: 1,
    columnEnd: 2,
  },
  [BreakpointsEnum.Medium]: {
    columsTemplate: `1fr`,
    rowsTemplate: "auto",
    gridAutoRows: "4px",
    gap: "0 20px",
    margin: "20px 0 0",
    columnStart: 1,
    columnEnd: 2,
  },
  [BreakpointsEnum.Small]: {
    columsTemplate: `1fr`,
    rowsTemplate: "auto",
    gridAutoRows: "4px",
    gap: "0 20px",
    margin: "20px 0 0",
    columnStart: 1,
    columnEnd: 2,
  },
  [BreakpointsEnum.ExtraSmall]: {
    columsTemplate: `1fr`,
    rowsTemplate: "auto",
    gridAutoRows: "4px",
    gap: "0 20px",
    margin: "20px 0 0",
    columnStart: 1,
    columnEnd: 2,
  },
};

export default alternativeGridSystem;
