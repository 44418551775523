import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const IgniteDisabled: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9927 4.64578C17.3978 4.65227 12.993 6.48161 9.74441 9.73259C6.4958 12.9836 4.66852 17.3908 4.66333 21.9877C4.66852 26.5851 6.49564 30.9928 9.74411 34.2446C12.9926 37.4964 17.3973 39.3268 21.9927 39.3346C26.5909 39.332 31.0002 37.5037 34.2521 34.2512C37.5041 30.9988 39.3328 26.5881 39.3367 21.9877C39.3315 17.3883 37.5022 12.9788 34.2504 9.72739C30.9986 6.47599 26.5901 4.64838 21.9927 4.64578Z"
      fill="#C0C0C0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9976 39.3346C26.5942 39.3307 31.0016 37.5026 34.2524 34.2512C37.5043 30.9988 39.333 26.588 39.3369 21.9877C39.3317 17.3883 37.5024 12.9788 34.2507 9.72739C31 6.47709 26.5933 4.64961 21.9976 4.64578V39.3346Z"
      fill="#9D9D9D"
    />
    <path
      d="M22.8264 2.99253C26.5555 3.15496 30.1543 4.41201 33.174 6.60694C36.1937 8.80187 38.5008 11.8376 39.8076 15.3355L42.6615 14.4083C41.1556 10.3099 38.4658 6.75194 34.9341 4.18644C31.4023 1.62095 27.1879 0.163743 22.8264 0V2.99253Z"
      fill="#C0C0C0"
    />
    <path
      d="M40.3173 16.9004C40.7752 18.5574 41.0078 20.2686 41.0087 21.9877C41.0106 24.85 40.3653 27.6757 39.1213 30.2532C37.8773 32.8307 36.0667 35.0934 33.825 36.8719L35.5853 39.2953C38.2084 37.2381 40.3292 34.6106 41.787 31.612C43.2449 28.6134 44.0016 25.3222 43.9999 21.9877C44.0008 19.9553 43.7186 17.9326 43.1614 15.9781L40.3173 16.9004Z"
      fill="#EEEEEE"
    />
    <path
      d="M4.1925 15.3355C5.4996 11.8368 7.80743 8.80061 10.8281 6.60562C13.8488 4.41062 17.4487 3.15401 21.1786 2.99253V0C16.8167 0.16245 12.6017 1.61915 9.06973 4.18482C5.53771 6.7505 2.84829 10.3092 1.34351 14.4083L4.1925 15.3355Z"
      fill="#EEEEEE"
    />
    <path
      d="M32.4961 37.8432C29.3839 39.9114 25.7289 41.0108 21.9926 41.0025C18.2582 41.0086 14.6054 39.9094 11.494 37.8432L9.73364 40.2667C13.3569 42.7053 17.6258 44.0053 21.9926 44C26.3597 44.0065 30.6289 42.7064 34.2516 40.2667L32.4961 37.8432Z"
      fill="#EEEEEE"
    />
    <path
      d="M10.175 36.8719C7.93327 35.0934 6.12265 32.8307 4.87861 30.2532C3.63458 27.6757 2.98933 24.85 2.99119 21.9877C2.99122 20.2682 3.22547 18.5567 3.68749 16.9004L0.838499 15.9781C0.283837 17.9332 0.00168568 19.9555 2.8435e-06 21.9877C-0.00169283 25.3222 0.755018 28.6134 2.21288 31.612C3.67074 34.6106 5.79151 37.2381 8.41457 39.2953L10.175 36.8719Z"
      fill="#EEEEEE"
    />
    <path
      d="M20.0852 12.9905L21.5562 10.2972C21.5979 10.2189 21.6601 10.1533 21.7361 10.1076C21.8121 10.0618 21.8991 10.0375 21.9878 10.0372C22.0751 10.0379 22.1608 10.0618 22.2358 10.1067C22.3108 10.1515 22.3724 10.2156 22.4144 10.2923L23.8855 12.9856L24.7534 14.5849V11.5825C24.7567 10.9257 24.5876 10.2795 24.263 9.70853L23.3902 8.20246L22.7331 7.06431C22.6574 6.94264 22.5519 6.84228 22.4266 6.7727C22.3014 6.70311 22.1605 6.6666 22.0172 6.6666C21.8739 6.6666 21.733 6.70311 21.6078 6.7727C21.4825 6.84228 21.377 6.94264 21.3013 7.06431L20.6491 8.20246L19.7566 9.72325C19.4325 10.2962 19.2635 10.9438 19.2663 11.6022V14.5456L20.0852 12.9905Z"
      fill="white"
    />
    <path
      d="M17.6724 20.8398C16.9883 22.1034 16.0965 23.2427 15.0343 24.2101C14.7465 24.4498 14.5148 24.7497 14.3555 25.0888C14.1963 25.4278 14.1133 25.7977 14.1124 26.1724V28.0022H17.6724V20.8398Z"
      fill="white"
    />
    <path
      d="M17.6725 19.368V16.1351C17.6733 15.8267 17.6063 15.5219 17.4764 15.2423L17.1331 14.526L16.8781 13.9864C16.8557 13.9313 16.8174 13.8841 16.768 13.8509C16.7187 13.8177 16.6606 13.7999 16.6011 13.7999C16.5416 13.7999 16.4835 13.8177 16.4341 13.8509C16.3848 13.8841 16.3465 13.9313 16.324 13.9864L16.069 14.5309L15.7307 15.2472C15.6028 15.5256 15.5375 15.8287 15.5395 16.1351V21.8405C15.6168 21.7419 15.7057 21.653 15.8043 21.5756"
      fill="white"
    />
    <path
      d="M26.3274 20.8398C27.0152 22.1022 27.9084 23.2413 28.9704 24.2101C29.2572 24.4506 29.4882 24.7508 29.6474 25.0896C29.8066 25.4285 29.8901 25.798 29.8923 26.1724V28.0022H26.3323L26.3274 20.8398Z"
      fill="white"
    />
    <path
      d="M26.3324 19.368V16.1351C26.3316 15.8267 26.3986 15.5219 26.5286 15.2423L26.8669 14.526L27.1219 13.9864C27.1453 13.9316 27.1843 13.885 27.234 13.8522C27.2836 13.8194 27.3419 13.8019 27.4014 13.8019C27.4609 13.8019 27.5192 13.8194 27.5688 13.8522C27.6185 13.885 27.6575 13.9316 27.6809 13.9864L27.931 14.5309L28.2693 15.2472C28.3989 15.5252 28.4659 15.8283 28.4655 16.1351V21.8405C28.388 21.7437 28.3009 21.6549 28.2056 21.5756"
      fill="white"
    />
    <path
      d="M24.935 19.2552V18.3329C24.9315 17.5892 24.7445 16.8579 24.3907 16.2038L23.4639 14.5015L22.1841 12.1762C22.164 12.1404 22.1349 12.1107 22.0995 12.0899C22.0642 12.0692 22.024 12.0583 21.983 12.0583C21.942 12.0583 21.9019 12.0692 21.8665 12.0899C21.8312 12.1107 21.802 12.1404 21.782 12.1762L20.5217 14.5064L19.5999 16.2136C19.244 16.8671 19.0553 17.5986 19.0507 18.3427V19.265V28.5468H24.9007L24.935 19.2552ZM20.5806 18.1318C20.5805 17.7543 20.7283 17.3918 20.9924 17.1222C21.2564 16.8525 21.6156 16.6972 21.9928 16.6895C22.1846 16.6856 22.3752 16.72 22.5535 16.7907C22.7319 16.8614 22.8943 16.9671 23.0313 17.1014C23.1683 17.2357 23.2771 17.396 23.3514 17.573C23.4257 17.7499 23.4639 17.9399 23.4639 18.1318H20.5806Z"
      fill="white"
    />
    <path
      d="M16.6477 31.5638H16.8046C16.7844 31.0371 16.5612 30.5387 16.1819 30.1729C15.8026 29.8071 15.2965 29.6024 14.7697 29.6015C14.2282 29.6028 13.7093 29.8189 13.3269 30.2024C12.9445 30.5859 12.7297 31.1055 12.7297 31.6472C12.7317 32.0202 12.8355 32.3855 13.03 32.7037C13.2244 33.0219 13.5021 33.2808 13.833 33.4525C14.0635 32.8949 14.4537 32.418 14.9546 32.0819C15.4555 31.7458 16.0446 31.5655 16.6477 31.5638Z"
      fill="white"
    />
    <path
      d="M17.7854 31.7993C18.3223 32.0147 18.7868 32.3789 19.1241 32.8491C19.2763 32.3875 19.3943 31.9153 19.4771 31.4363L19.6193 30.4551C19.3307 30.2555 18.9894 30.1463 18.6386 30.1411C18.2597 30.1419 17.8914 30.2658 17.5892 30.4944C17.7397 30.8583 17.8163 31.2485 17.8148 31.6423C17.7903 31.6865 17.7854 31.7404 17.7854 31.7993Z"
      fill="white"
    />
    <path
      d="M28.7008 33.0552C28.6867 33.0395 28.6702 33.0262 28.6518 33.0159C28.527 32.912 28.3903 32.8231 28.2448 32.751C28.1814 32.7214 28.1158 32.6968 28.0486 32.6774L27.926 32.6382C27.8602 32.6172 27.7931 32.6008 27.725 32.5891C27.6311 32.5718 27.536 32.562 27.4406 32.5597H27.3768C27.106 32.559 26.8376 32.6119 26.5872 32.7151C26.3367 32.8184 26.1091 32.97 25.9174 33.1614C25.7256 33.3528 25.5735 33.5802 25.4696 33.8305C25.3658 34.0808 25.3124 34.3491 25.3124 34.6201C25.3124 34.6201 24.2434 35.1696 23.5765 31.6031L23.3657 30.1313H20.6736L20.4677 31.6031C19.7959 35.1696 18.7269 34.6201 18.7269 34.6201C18.7269 34.0737 18.5099 33.5496 18.1237 33.1632C17.7374 32.7768 17.2136 32.5597 16.6674 32.5597H16.5987C16.5033 32.5622 16.4082 32.572 16.3143 32.5891C16.2477 32.6002 16.1822 32.6167 16.1182 32.6382L15.9907 32.6774C15.9235 32.6968 15.8579 32.7214 15.7945 32.751C15.6496 32.8242 15.5132 32.9131 15.3875 33.0159L15.3434 33.0552C15.1186 33.2462 14.9378 33.4837 14.8134 33.7513C14.6889 34.0189 14.6238 34.3103 14.6226 34.6054V35.3462H29.4658V34.6054C29.4594 34.3067 29.3877 34.0131 29.2555 33.7452C29.1233 33.4774 28.934 33.2418 28.7008 33.0552Z"
      fill="white"
    />
    <path
      d="M29.2304 29.5917C28.7036 29.5926 28.1975 29.7973 27.8182 30.1631C27.4389 30.5289 27.2157 31.0273 27.1954 31.554H27.3573C27.9589 31.5539 28.547 31.7324 29.0472 32.0669C29.5473 32.4013 29.937 32.8767 30.167 33.4329C30.4985 33.2614 30.7769 33.0027 30.9722 32.6845C31.1675 32.3664 31.2723 32.0009 31.2752 31.6276C31.2726 31.0867 31.0561 30.5689 30.6729 30.1874C30.2897 29.8059 29.7711 29.5917 29.2304 29.5917Z"
      fill="white"
    />
    <path
      d="M24.8809 32.8393C25.2195 32.3727 25.6838 32.012 26.2196 31.7993C26.2196 31.7502 26.2196 31.6963 26.2196 31.6472C26.2205 31.2536 26.2971 30.8639 26.4451 30.4992C26.1433 30.2701 25.7747 30.146 25.3958 30.146C25.0451 30.152 24.704 30.2612 24.415 30.46L24.5572 31.4412C24.6307 31.9145 24.7389 32.3818 24.8809 32.8393Z"
      fill="white"
    />
  </svg>
);

export default IgniteDisabled;
