import React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { Heading, Text } from "components/atoms/Typography"
import Widget from "components/templates/Widget"
import { palette } from "lib/theme"
import useWindowSize from "shared/useWindowSize"
import { selectCurrentUser } from "state/slice/auth.slice"
import UsersContainer from './UsersContainer'

const ProfileHeader = () => {
  const user = useSelector(selectCurrentUser)

  useWindowSize()
  return (
    <Widget
      background={palette.background.elevation3 as string}
      padding="24px"
    >
      <Wrapper>
        <InfoContainer>
          <Heading variant="h2">
            {user?.firstName} {user?.lastName}
          </Heading>
          <ContactContainer>
            <ContactInfo>
              <Text size="small" color={palette.secondary.secondaryFont}>
                Organization Name
              </Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "8px",
                  marginTop: "3px",
                }}
              >
                <div style={{ marginTop: "-3px", marginRight: "-3px" }}>
                  <Text bold>{user?.organization?.alias}</Text>
                </div>
              </div>
            </ContactInfo>
          </ContactContainer>
          <ContactContainer>
            <ContactInfo>
              <Text size="small" color={palette.secondary.secondaryFont}>
                Email
              </Text>
              <div style={{ marginTop: "3px" }}>
                <Text bold>{user?.email}</Text>
              </div>
            </ContactInfo>
          </ContactContainer>
        </InfoContainer>
        <UsersContainer />
      </Wrapper>
    </Widget>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2vw;
  flex-wrap: wrap;
  width: 100%;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-grow: 1;
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 50%;
`

export default ProfileHeader
