import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const CashBack: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <g clipPath="url(#clip0_5782_656951)">
      <path
        d="M9.3999 14.93L12.9999 11.93L16.5999 14.93"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 12.93V17.108C13 20.422 10.314 23.108 7 23.108C3.686 23.108 1 20.422 1 17.108V3C1 1.895 1.895 1 3 1H21C22.105 1 23 1.895 23 3V12.931C23 14.036 22.105 14.931 21 14.931H20"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 14.93H6"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.9021 5.92999H19.0001"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5782_656951">
        <rect width="24" height="24" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export default CashBack;
