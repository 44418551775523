import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const PriceAlertFired: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
    <path d="M14 23H10C9.448 23 9 22.553 9 22C9 21.447 9.448 21 10 21H14C14.552 21 15 21.447 15 22C15 22.553 14.552 23 14 23Z" fill={color} />
    <path d="M21 17H6V9C6 5.691 8.691 3 12 3C15.309 3 18 5.691 18 9V14.498C18 15.051 18.448 15.498 19 15.498C19.552 15.498 20 15.051 20 14.498V9C20 4.589 16.411 1 12 1C7.589 1 4 4.589 4 9V17H3C2.448 17 2 17.447 2 18C2 18.553 2.448 19 3 19H21C21.552 19 22 18.553 22 18C22 17.447 21.552 17 21 17Z" fill={color} />
  </svg>
);

export default PriceAlertFired;