import { useSelector } from 'react-redux'
import usePermissions from 'shared/usePermissions';
import { selectCurrentUser } from 'state/slice/auth.slice'
import { useGetDefiBalancesQuery } from 'state/store/investApi';

const useInvest = () => {
  const { isCustodyInvestEnabled } = usePermissions()
  const skip = !isCustodyInvestEnabled;
  const defiBalances = useGetDefiBalancesQuery(null, { pollingInterval: 30000, skip })
  const user = useSelector(selectCurrentUser)
  const smaAccountNumber = user?.organization?.smaAccountNumber

  const balances = defiBalances.data ?? []
  const totalBalance = balances.reduce((total, next) => total + +(next.currentValue?.amount ?? '0'), 0);

  const canInvestAsset = (asset: string) => {
    const balances = defiBalances.data ?? []
    const investableAssets = balances?.filter(balance => balance.canInvest)
    const foundStrategy = investableAssets?.filter(balance => balance.strategySource === asset)
    return !!foundStrategy?.length
  }

  return {
    defiBalances,
    totalBalance,
    canInvestAsset,
    smaAccountNumber,
    isCustodyInvestEnabled
  }
}

export default useInvest
