import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const SubstractActive: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => {
  return (
    <svg
      width="78"
      height="144"
      viewBox="0 0 78 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5802 53.9653C58.2138 25.8446 31.5216 5.62461 0 4.80157V0C33.7432 0.828012 62.3041 22.5494 73.2362 52.7178L68.5802 53.9653ZM44.3176 139.223C61.9 125.458 73.1999 104.036 73.1999 79.9755C73.1999 72.2429 72.0328 64.783 69.8652 57.7621L74.5059 56.5187C76.7775 63.9367 77.9999 71.8134 77.9999 79.9755C77.9999 105.663 65.8932 128.523 47.0745 143.16L44.3176 139.223Z"
        fill="#FFA519"
      />
    </svg>
  );
};

export default SubstractActive;
