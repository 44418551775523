import { useSearchParams } from 'react-router-dom'
import onboardingApi from 'state/store/onboardingApi'

const useOnboarding = () => {
  const [searchParams] = useSearchParams();
  const registrationId = searchParams.get('registrationId') ?? '';
  const email = searchParams.get('email') ?? '';
  const onboardingStatus = onboardingApi.useGetUserOnboardingStatusQuery({email, registrationId})

  return {
    onboardingStatus
  }
}

export default useOnboarding
