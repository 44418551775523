import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const Marker: FC<ISVG> = ({
  size = 48,
  width = size,
  height = size,
  color = "#6F2ACD",
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
    <g filter="url(#filter0_d_819_221291)">
      <circle cx="24" cy="20" r="8" fill={color} />
      <circle cx="24" cy="20" r="7" stroke="white" strokeWidth="2"/>
    </g>
    <defs>
      <filter id="filter0_d_819_221291" x="0" y="0" width={width} height={height} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="8"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.435294 0 0 0 0 0.164706 0 0 0 0 0.803922 0 0 0 0.3 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_819_221291"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_819_221291" result="shape"/>
      </filter>
    </defs>
  </svg>
);

export default Marker;