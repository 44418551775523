import React, { FC } from "react";
import {
  StyledWidgetContainer,
  StyledWidgetHeading,
  StyledHeaderAction,
} from "./style";

import Panel from "components/atoms/Panel";
import Icon from "components/atoms/Icon";
import Loading from "components/atoms/Loading";
import { Heading, Text } from "components/atoms/Typography";
import { BreakpointsEnum } from "@types/enums/GridSystem";

import { isFunction } from "lib/utils/helpers";
import { palette } from "lib/theme";
import { GridItemSetting, Sizes } from "./interfaces";

interface WidgetDefaultProps extends Sizes {
  title?: string;
  textVariant?: "text" | "heading";
  padding?: string;
  margin?: string;
  titleMargin?: string;
  children?: React.ReactNode;
  customHeading?: (event?: any) => any;
  headerAction?: (event?: any) => void;
  isLoading?: boolean;
  width?: string;
  height?: string;
  minHeight?: string;
  background?: string;
  widgetProps?: any;
}

const Widget: FC<WidgetDefaultProps> = ({
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  padding = '24px',
  margin = '0',
  title = '',
  titleMargin,
  textVariant = 'text',
  children,
  customHeading,
  headerAction,
  isLoading,
  width = '100%',
  height = '100%',
  minHeight,
  background,
  widgetProps,
}) => {
  const handlingHeaderAction = (event?: any) => {
    if (isFunction(headerAction)) {
      headerAction(event);
    }
  }

  return (
    <StyledWidgetContainer
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    xxl={xxl}
    {...widgetProps}>
      <Panel
        padding={padding}
        width={width}
        height={height}
        minHeight={minHeight}
        background={background}
        margin={margin}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <StyledWidgetHeading marginBottom={Boolean(title) ? titleMargin ?? '1rem' : '0' }>
              <>
                {textVariant === 'text' && (<Text size="large" bold>{title}</Text>)}
                {textVariant === 'heading' && (<Heading variant="h2">{title}</Heading>)}
                {isFunction(headerAction) && (
                  <StyledHeaderAction
                    onClick={handlingHeaderAction}
                  >
                    <Icon
                      name="RightArrowBig"
                      size={24}
                      color={palette.white.light70}
                      changeColorOnHover={true}
                    />
                  </StyledHeaderAction>
                )}
                {isFunction(customHeading) && (
                  <StyledHeaderAction
                    onClick={handlingHeaderAction}
                  >
                    {customHeading()}
                  </StyledHeaderAction>
                )}
              </>
            </StyledWidgetHeading>
            {children}
          </React.Fragment>
        )}
      </Panel>
    </StyledWidgetContainer>
  );
};

export default Widget;
