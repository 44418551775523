import Loading from "components/atoms/Loading";
import Row from "components/atoms/Row/Row";
import LoanToValueRatio, { ChartMeasures } from "pages/LoanScreen/LoanToValueRatio";
import React, { useEffect, useState } from "react"
import { formatNumberDisplay } from "shared";
import styled from "styled-components";

interface LtvMeterProps {
    loanBalances: any
}

type Legends = "loanOutstanding" | "collateral" | "averageLTV" | "healthFactor"

interface CurrentLegendsContent {
    title: string,
    detail: string,
    raw: string
}

const LtvMeter = ({loanBalances}: LtvMeterProps) => {
    const [notificationType, setNotificationType] = useState<string>("")
    const [currentLegends, setCurrentLegends] = useState<Record<Legends, CurrentLegendsContent>>({})
    const [chartMeasures, setChartMeasures] = useState<ChartMeasures>({
        safe: 0.55,
        warning: 0.55,
        danger: 0.55,
    })

    useEffect(() => {
        if (!loanBalances?.data) return;
        const summary = loanBalances?.data
    
        const outstandingBalance = summary?.totalPrincipalValue?.amount || 0
        const collateral = summary?.totalCollateralValue?.amount || 0
        const averageLTV = summary?.ltv
        const healthFactor = summary?.healthFactor
    
        const makeCurrentLegends = {
            loanOutstanding: {
                title: 'Loan Outstanding',
                detail: formatNumberDisplay(outstandingBalance, 'currency') ?? '-',
                raw: outstandingBalance
            },
            collateral: {
                title: 'Collateral',
                detail: formatNumberDisplay(collateral, 'currency') ?? '-',
                raw: collateral
            },
            averageLTV: {
                title: 'Average LTV',
                detail: formatNumberDisplay(averageLTV, 'percent') ?? '-',
                raw: averageLTV
            },
            healthFactor: {
                title: 'Health Factor',
                detail: healthFactor ? `${formatNumberDisplay(healthFactor, 'decimal')}` : '-',
                raw: healthFactor
            }
        }
    
        setCurrentLegends(makeCurrentLegends)
        setChartMeasures({
            safe: summary.ltvWarningThreshold - 0.01,
            warning: summary.ltvDangerThreshold - summary.ltvWarningThreshold - 0.01,
            danger: 1 - summary.ltvDangerThreshold - 0.01,
        })

        let type = "danger"
        if(averageLTV < summary.ltvWarningThreshold) {
            type="safe"
        } else if(averageLTV < summary.ltvDangerThreshold) {
            type="warning"
        }

        setNotificationType(type)
    }, [loanBalances.data]);

    return (
        <>
            {Object.keys(currentLegends).length > 0 ? 
            (
                <div style={{
                    padding: '0px 24px'
                }}>
                    <LoanToValueRatio chartMeasures={chartMeasures} currentLtv={Number(currentLegends.averageLTV.raw)} incomingNotificationType={notificationType} />
                    <SpacedItemRow>
                        <div>
                            <LoanPropertiesTitle>{currentLegends.loanOutstanding.title}</LoanPropertiesTitle>
                            <LoanPropertiesValues>{currentLegends.loanOutstanding.detail}</LoanPropertiesValues>
                        </div> 
                        <div>
                            <LoanPropertiesTitle>{currentLegends.collateral.title}</LoanPropertiesTitle>
                            <LoanPropertiesValues>{currentLegends.collateral.detail}</LoanPropertiesValues>
                        </div>   
                        <div>
                            <LoanPropertiesTitle>{currentLegends.averageLTV.title}</LoanPropertiesTitle>
                            <LoanPropertiesValues>{currentLegends.averageLTV.detail}</LoanPropertiesValues>
                        </div> 
                        <div>
                            <LoanPropertiesTitle>{currentLegends.healthFactor.title}</LoanPropertiesTitle>
                            <LoanPropertiesValues>{currentLegends.healthFactor.detail}</LoanPropertiesValues>
                        </div> 
                    </SpacedItemRow>
                </div>
            )
            : <Loading showText={false}/>}
        </>
    )
}

const SpacedItemRow = styled(Row)`
    padding: 24px 0px;
    justify-content: space-between;
`

const LoanPropertiesTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.045em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
`;

const LoanPropertiesValues = styled.p`
  font-weight: 400;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.48px;
  margin-bottom: 30px;
`;

export default LtvMeter