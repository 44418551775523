import styled from "styled-components";

export const sharedStyles = {
    Table: styled.table`
        width: 100%;
        border-collapse: collapse;
    `,
    Th: styled.th`
        text-align: left;
        padding: 12px 16px;
        color: rgb(179, 157, 219);
        font-weight: normal;
        background: rgb(44, 28, 77);
    `,
    TableRow: styled.tr`
        &:hover {
            background: rgba(255, 255, 255, 0.03);
        }
    `,
    Td: styled.td`
        padding: 12px 16px;
        color: white;
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        text-align: ${(props) => props.align || 'left'};
    `,
    TableContainer: styled.div`
        overflow-x: auto;
    `,
    Container: styled.div`
        padding: 24px;
        color: white;
        font-family: system-ui;
        background: rgb(22, 21, 46);
        margin-bottom: 24px;
    `,
};