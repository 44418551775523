import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const DownArrow: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = 'white',
  svgProps,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7526 15.5515L18.4026 9.89148C18.4964 9.79852 18.5707 9.68792 18.6215 9.56606C18.6723 9.4442 18.6984 9.31349 18.6984 9.18148C18.6984 9.04947 18.6723 8.91876 18.6215 8.7969C18.5707 8.67504 18.4964 8.56444 18.4026 8.47148C18.2153 8.28523 17.9618 8.18069 17.6976 8.18069C17.4334 8.18069 17.18 8.28523 16.9926 8.47148L11.9926 13.4215L7.04263 8.47148C6.85526 8.28523 6.60181 8.18069 6.33763 8.18069C6.07344 8.18069 5.81999 8.28523 5.63263 8.47148C5.53814 8.56409 5.46297 8.67454 5.41147 8.79641C5.35998 8.91829 5.33318 9.04917 5.33263 9.18148C5.33318 9.31379 5.35998 9.44467 5.41147 9.56654C5.46297 9.68842 5.53814 9.79886 5.63263 9.89148L11.2826 15.5515C11.3763 15.653 11.4899 15.734 11.6164 15.7894C11.7429 15.8448 11.8795 15.8734 12.0176 15.8734C12.1557 15.8734 12.2923 15.8448 12.4188 15.7894C12.5453 15.734 12.659 15.653 12.7526 15.5515Z"
        fill={color}
      />
    </svg>
  );
};
export default DownArrow;
