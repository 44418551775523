import { useEffect } from 'react'
import { useSelector } from 'react-redux';

const useUserConvenienceCurrency = () => {
    const {
        auth: { user },
        assets: { assets }, 
      }: any = useSelector((state: any) => state);
    
      const asset = assets.find(
        (as) => as.identifier === user?.convenienceCurrency?.value
      );
    return {asset};
}
export default useUserConvenienceCurrency