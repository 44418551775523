import {
  MarketInfoEnum,
  MarketListingLatestEnum,
  MarketQuotesHistoricalEnum,
  MarketQuotesLatestEnum,
  MarketListingGainerEnum,
  MarketListingLooserEnum,
  MarketListingLatestVolumenEnum,
} from "enums/Reducer.enum";
import { ReducerActioInterface } from "../../interfaces/reducers/ReducerAction.interface";

const initialState = {
  isLoading: false,
  depositCryptoOptions: [],
  moneyOptions: [],
};

const marketReducer = (
  state: any = initialState,
  action: ReducerActioInterface
) => {
  const dataPayload =
    action && action.payload && action.payload.data
      ? { ...action.payload.data }
      : action.payload;
  let newState = { ...state, ...dataPayload };
  switch (action.type) {
    case MarketInfoEnum.LOADING:
      return newState;
    case MarketInfoEnum.GET:
      return newState;
    case MarketInfoEnum.ERROR:
      return newState;
    case MarketQuotesHistoricalEnum.LOADING:
      return newState;
    case MarketQuotesHistoricalEnum.GET:
      return newState;
    case MarketQuotesHistoricalEnum.ERROR:
      return newState;
    case MarketQuotesLatestEnum.LOADING:
      return newState;
    case MarketQuotesLatestEnum.GET:
      return newState;
    case MarketQuotesLatestEnum.ERROR:
      return newState;
    case MarketListingLatestEnum.LOADING:
      return newState;
    case MarketListingLatestEnum.GET:
      return newState;
    case MarketListingLatestEnum.ERROR:
      return newState;
    case MarketListingGainerEnum.LOADING:
      return newState;
    case MarketListingGainerEnum.GET:
      return newState;
    case MarketListingGainerEnum.ERROR:
      return newState;
    case MarketListingLooserEnum.LOADING:
      return newState;
    case MarketListingLooserEnum.GET:
      return newState;
    case MarketListingLooserEnum.ERROR:
      return newState;
    case MarketListingLatestVolumenEnum.LOADING:
      return newState;
    case MarketListingLatestVolumenEnum.GET:
      return newState;
    case MarketListingLatestVolumenEnum.ERROR:
      return newState;
    default:
      return state;
  }
};

export default marketReducer;
