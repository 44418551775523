import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const ClockThin: FC<ISVG> = ({
  size = 90,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 90 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path d="M45 87.24C68.3285 87.24 87.24 68.3285 87.24 45C87.24 21.6715 68.3285 2.76001 45 2.76001C21.6715 2.76001 2.76001 21.6715 2.76001 45C2.76001 68.3285 21.6715 87.24 45 87.24Z" stroke={color} strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M45 11.5601V45.0001H62.6" stroke={color} strokeWidth="4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default ClockThin;
