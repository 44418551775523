import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const CruiseIcon: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9933 4.22345C15.8161 4.22935 11.8118 5.89238 8.85854 8.84782C5.90526 11.8033 4.2441 15.8098 4.23938 19.9889C4.24409 24.1683 5.90511 28.1753 8.85827 31.1315C11.8114 34.0876 15.8157 35.7516 19.9933 35.7587C24.1736 35.7564 28.182 34.0943 31.1383 31.1375C34.0946 28.1807 35.7571 24.171 35.7606 19.9889C35.7559 15.8075 34.0929 11.7989 31.1367 8.8431C28.1806 5.88728 24.1728 4.22581 19.9933 4.22345Z"
      fill="#33CCCC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9978 35.7587C24.1764 35.7551 28.1829 34.0932 31.1381 31.1375C34.0944 28.1807 35.7569 24.171 35.7604 19.9889C35.7557 15.8075 34.0927 11.7989 31.1365 8.84309C28.1815 5.88838 24.1756 4.22705 19.9978 4.22345V35.7587Z"
      fill="#2AA7A7"
    />
    <path
      d="M22.8196 14.8021V13.9101C22.8202 13.1953 22.6425 12.4917 22.3025 11.8631L21.4109 10.2263L20.194 7.99641C20.1745 7.96209 20.1463 7.93354 20.1123 7.91368C20.0782 7.89383 20.0395 7.88336 20.0001 7.88336C19.9606 7.88336 19.9219 7.89383 19.8878 7.91368C19.8538 7.93354 19.8256 7.96209 19.8061 7.99641L18.5981 10.2263L17.7065 11.8675C17.3693 12.4987 17.1932 13.2034 17.1938 13.919V14.811V23.7306H22.8241L22.8196 14.8021ZM18.6293 13.7228C18.6293 13.3549 18.7753 13.0022 19.0353 12.742C19.2953 12.4819 19.648 12.3358 20.0156 12.3358C20.1996 12.3358 20.3817 12.3724 20.5514 12.4435C20.7211 12.5146 20.8749 12.6187 21.0039 12.7499C21.133 12.8811 21.2346 13.0366 21.303 13.2075C21.3713 13.3783 21.405 13.5611 21.402 13.7451L18.6293 13.7228Z"
      fill="white"
    />
    <path
      d="M15.7539 16.3273C15.0927 17.543 14.234 18.6402 13.213 19.5741C12.9329 19.8048 12.7076 20.095 12.5534 20.4236C12.3992 20.7522 12.32 21.111 12.3214 21.4739V23.2579H15.7495L15.7539 16.3273Z"
      fill="white"
    />
    <path
      d="M24.2639 16.3273C24.9252 17.5442 25.7856 18.6416 26.8093 19.5741C27.0885 19.8057 27.3131 20.096 27.4672 20.4244C27.6213 20.7528 27.7011 21.1112 27.7009 21.4739V23.2579H24.2728L24.2639 16.3273Z"
      fill="white"
    />
    <path
      d="M21.2638 24.4486V24.4888L19.9933 27.3832L18.754 24.4798C18.754 24.4798 18.754 24.4531 18.754 24.4397C18.4727 24.7004 18.2788 25.0418 18.199 25.4171C18.1192 25.7923 18.1574 26.1831 18.3083 26.5358L20.0156 30.5273L21.7185 26.5358C21.8682 26.1837 21.9049 25.7937 21.8235 25.4197C21.742 25.0458 21.5464 24.7065 21.2638 24.4486Z"
      fill="white"
    />
    <path d="M27.1749 25.024H26.5062V27.9585H27.1749V25.024Z" fill="white" />
    <path d="M25.4095 26.3619H24.7408V31.1562H25.4095V26.3619Z" fill="white" />
    <path d="M29.5776 21.1306H28.9089V25.9248H29.5776V21.1306Z" fill="white" />
    <path d="M13.4894 25.024H12.8207V27.9585H13.4894V25.024Z" fill="white" />
    <path d="M15.2591 26.3619H14.5905V31.1562H15.2591V26.3619Z" fill="white" />
    <path d="M11.0866 21.1306H10.418V25.9248H11.0866V21.1306Z" fill="white" />
  </svg>
);

export default CruiseIcon;
