/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { AccountLimitsResponse } from '../models';
import { AddBankAccountRequest } from '../models';
import { ApiError } from '../models';
import { BankAccountDepositInstructions } from '../models';
import { BankAccountResponse } from '../models';
import { BankAccountsResponse } from '../models';
import { BankNextStepResponse } from '../models';
import { BankResponse } from '../models';
import { BankWireWithdrawProcessRequest } from '../models';
import { BankWireWithdrawProcessResponse } from '../models';
import { BanksByCountryResponse } from '../models';
import { DeleteBankAccountResponse } from '../models';
import { DepositConfirmationRequest } from '../models';
import { DepositRequest } from '../models';
import { DepositResultResponse } from '../models';
import { DepositTemplateResponse } from '../models';
import { PlaidBankTokenRequest } from '../models';
import { PlaidBankTokenResponse } from '../models';
import { PlaidTokenResponse } from '../models';
import { RequestInputDataRoot } from '../models';
import { WithdrawRequest } from '../models';
import { WithdrawTemplateResponse } from '../models';
/**
 * BankControllerApi - axios parameter creator
 * @export
 */
export const BankControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add bank account plaid account
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBankAccount: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling addBankAccount.');
            }
            const localVarPath = `/bank/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add new bank account with bank id apply for philippines
         * @param {AddBankAccountRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBanks: async (body: AddBankAccountRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addBanks.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling addBanks.');
            }
            const localVarPath = `/bank/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a wire bank account for deposit.
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDepositWireAccount: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling addDepositWireAccount.');
            }
            const localVarPath = `/bank/deposit/wire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To confirm bank account in adding flow
         * @param {PlaidBankTokenRequest} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPlaidBankToken: async (body: PlaidBankTokenRequest, token: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addPlaidBankToken.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling addPlaidBankToken.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling addPlaidBankToken.');
            }
            const localVarPath = `/bank/account/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a wire bank account for withdraw.
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addWithdrawWireAccount: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling addWithdrawWireAccount.');
            }
            const localVarPath = `/bank/withdraw/wire`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To delete an existing bank account
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBankAccount: async (token: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling deleteBankAccount.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling deleteBankAccount.');
            }
            const localVarPath = `/bank/account/{accountId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets bank account Limits
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccountLimits: async (token: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getBankAccountLimits.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getBankAccountLimits.');
            }
            const localVarPath = `/bank/account/{accountId}/limits`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank account list
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankAccounts: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getBankAccounts.');
            }
            const localVarPath = `/bank/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get bank account list
         * @param {string} token 
         * @param {string} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBanksByCountry: async (token: string, country: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getBanksByCountry.');
            }
            // verify required parameter 'country' is not null or undefined
            if (country === null || country === undefined) {
                throw new RequiredError('country','Required parameter country was null or undefined when calling getBanksByCountry.');
            }
            const localVarPath = `/bank/{country}`
                .replace(`{${"country"}}`, encodeURIComponent(String(country)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the instructions to deposit on given bank account.
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepositBankInstructions: async (token: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getDepositBankInstructions.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getDepositBankInstructions.');
            }
            const localVarPath = `/bank/{accountId}/deposit/instructions`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the instructions to deposit on given wire bank account.
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstructions: async (token: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getInstructions.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getInstructions.');
            }
            const localVarPath = `/bank/{accountId}/deposit/wire/instructions`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get next step to add account
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextStep: async (token: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getNextStep.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getNextStep.');
            }
            const localVarPath = `/bank/account/{accountId}/next-step`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary To get token and start with PLAID flow
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (token: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getToken.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling getToken.');
            }
            const localVarPath = `/bank/account/{accountId}/token`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates deposit request template
         * @param {DepositRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestDeposit: async (body: DepositRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling requestDeposit.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling requestDeposit.');
            }
            const localVarPath = `/bank/account/request-deposit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates withdraw request template
         * @param {WithdrawRequest} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestWithdraw: async (body: WithdrawRequest, token: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling requestWithdraw.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling requestWithdraw.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling requestWithdraw.');
            }
            const localVarPath = `/bank/account/{accountId}/withdraw-request`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirms deposit request based on transaction ID (Template)
         * @param {DepositConfirmationRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitDeposit: async (body: DepositConfirmationRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling submitDeposit.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling submitDeposit.');
            }
            const localVarPath = `/bank/account/submit-deposit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submits information to withdraw in a wire account
         * @param {RequestInputDataRoot} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitNextStep: async (body: RequestInputDataRoot, token: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling submitNextStep.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling submitNextStep.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling submitNextStep.');
            }
            const localVarPath = `/bank/{accountId}/next-step`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submits a bank wire withdraw transaction to be processed.
         * @param {BankWireWithdrawProcessRequest} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        withdrawBankWireProcess: async (body: BankWireWithdrawProcessRequest, token: string, accountId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling withdrawBankWireProcess.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling withdrawBankWireProcess.');
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError('accountId','Required parameter accountId was null or undefined when calling withdrawBankWireProcess.');
            }
            const localVarPath = `/bank/account/{accountId}/withdraw-submit`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BankControllerApi - functional programming interface
 * @export
 */
export const BankControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add bank account plaid account
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBankAccount(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BankResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).addBankAccount(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add new bank account with bank id apply for philippines
         * @param {AddBankAccountRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBanks(body: AddBankAccountRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BankAccountResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).addBanks(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates a wire bank account for deposit.
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDepositWireAccount(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BankResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).addDepositWireAccount(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary To confirm bank account in adding flow
         * @param {PlaidBankTokenRequest} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPlaidBankToken(body: PlaidBankTokenRequest, token: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PlaidBankTokenResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).addPlaidBankToken(body, token, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates a wire bank account for withdraw.
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWithdrawWireAccount(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BankResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).addWithdrawWireAccount(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary To delete an existing bank account
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankAccount(token: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<DeleteBankAccountResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).deleteBankAccount(token, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets bank account Limits
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankAccountLimits(token: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<AccountLimitsResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).getBankAccountLimits(token, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get bank account list
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankAccounts(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BankAccountsResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).getBankAccounts(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get bank account list
         * @param {string} token 
         * @param {string} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBanksByCountry(token: string, country: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BanksByCountryResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).getBanksByCountry(token, country, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieves the instructions to deposit on given bank account.
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepositBankInstructions(token: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BankAccountDepositInstructions>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).getDepositBankInstructions(token, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieves the instructions to deposit on given wire bank account.
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstructions(token: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BankNextStepResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).getInstructions(token, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get next step to add account
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextStep(token: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BankNextStepResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).getNextStep(token, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary To get token and start with PLAID flow
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(token: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<PlaidTokenResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).getToken(token, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates deposit request template
         * @param {DepositRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestDeposit(body: DepositRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<DepositTemplateResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).requestDeposit(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Creates withdraw request template
         * @param {WithdrawRequest} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestWithdraw(body: WithdrawRequest, token: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<WithdrawTemplateResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).requestWithdraw(body, token, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Confirms deposit request based on transaction ID (Template)
         * @param {DepositConfirmationRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitDeposit(body: DepositConfirmationRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<DepositResultResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).submitDeposit(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Submits information to withdraw in a wire account
         * @param {RequestInputDataRoot} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitNextStep(body: RequestInputDataRoot, token: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BankNextStepResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).submitNextStep(body, token, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Submits a bank wire withdraw transaction to be processed.
         * @param {BankWireWithdrawProcessRequest} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawBankWireProcess(body: BankWireWithdrawProcessRequest, token: string, accountId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BankWireWithdrawProcessResponse>>> {
            const localVarAxiosArgs = await BankControllerApiAxiosParamCreator(configuration).withdrawBankWireProcess(body, token, accountId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * BankControllerApi - factory interface
 * @export
 */
export const BankControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Add bank account plaid account
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBankAccount(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BankResponse>> {
            return BankControllerApiFp(configuration).addBankAccount(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add new bank account with bank id apply for philippines
         * @param {AddBankAccountRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addBanks(body: AddBankAccountRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BankAccountResponse>> {
            return BankControllerApiFp(configuration).addBanks(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a wire bank account for deposit.
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addDepositWireAccount(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BankResponse>> {
            return BankControllerApiFp(configuration).addDepositWireAccount(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To confirm bank account in adding flow
         * @param {PlaidBankTokenRequest} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPlaidBankToken(body: PlaidBankTokenRequest, token: string, accountId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PlaidBankTokenResponse>> {
            return BankControllerApiFp(configuration).addPlaidBankToken(body, token, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a wire bank account for withdraw.
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addWithdrawWireAccount(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BankResponse>> {
            return BankControllerApiFp(configuration).addWithdrawWireAccount(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To delete an existing bank account
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBankAccount(token: string, accountId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<DeleteBankAccountResponse>> {
            return BankControllerApiFp(configuration).deleteBankAccount(token, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets bank account Limits
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankAccountLimits(token: string, accountId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<AccountLimitsResponse>> {
            return BankControllerApiFp(configuration).getBankAccountLimits(token, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank account list
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankAccounts(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BankAccountsResponse>> {
            return BankControllerApiFp(configuration).getBankAccounts(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get bank account list
         * @param {string} token 
         * @param {string} country 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBanksByCountry(token: string, country: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BanksByCountryResponse>> {
            return BankControllerApiFp(configuration).getBanksByCountry(token, country, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the instructions to deposit on given bank account.
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepositBankInstructions(token: string, accountId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BankAccountDepositInstructions>> {
            return BankControllerApiFp(configuration).getDepositBankInstructions(token, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the instructions to deposit on given wire bank account.
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstructions(token: string, accountId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BankNextStepResponse>> {
            return BankControllerApiFp(configuration).getInstructions(token, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get next step to add account
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextStep(token: string, accountId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BankNextStepResponse>> {
            return BankControllerApiFp(configuration).getNextStep(token, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary To get token and start with PLAID flow
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(token: string, accountId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<PlaidTokenResponse>> {
            return BankControllerApiFp(configuration).getToken(token, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates deposit request template
         * @param {DepositRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestDeposit(body: DepositRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<DepositTemplateResponse>> {
            return BankControllerApiFp(configuration).requestDeposit(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates withdraw request template
         * @param {WithdrawRequest} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestWithdraw(body: WithdrawRequest, token: string, accountId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<WithdrawTemplateResponse>> {
            return BankControllerApiFp(configuration).requestWithdraw(body, token, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirms deposit request based on transaction ID (Template)
         * @param {DepositConfirmationRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitDeposit(body: DepositConfirmationRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<DepositResultResponse>> {
            return BankControllerApiFp(configuration).submitDeposit(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submits information to withdraw in a wire account
         * @param {RequestInputDataRoot} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitNextStep(body: RequestInputDataRoot, token: string, accountId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BankNextStepResponse>> {
            return BankControllerApiFp(configuration).submitNextStep(body, token, accountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submits a bank wire withdraw transaction to be processed.
         * @param {BankWireWithdrawProcessRequest} body 
         * @param {string} token 
         * @param {string} accountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async withdrawBankWireProcess(body: BankWireWithdrawProcessRequest, token: string, accountId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<BankWireWithdrawProcessResponse>> {
            return BankControllerApiFp(configuration).withdrawBankWireProcess(body, token, accountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BankControllerApi - object-oriented interface
 * @export
 * @class BankControllerApi
 * @extends {BaseAPI}
 */
export class BankControllerApi extends BaseAPI {
    /**
     * 
     * @summary Add bank account plaid account
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async addBankAccount(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BankResponse>> {
        return BankControllerApiFp(this.configuration).addBankAccount(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add new bank account with bank id apply for philippines
     * @param {AddBankAccountRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async addBanks(body: AddBankAccountRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BankAccountResponse>> {
        return BankControllerApiFp(this.configuration).addBanks(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Creates a wire bank account for deposit.
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async addDepositWireAccount(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BankResponse>> {
        return BankControllerApiFp(this.configuration).addDepositWireAccount(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary To confirm bank account in adding flow
     * @param {PlaidBankTokenRequest} body 
     * @param {string} token 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async addPlaidBankToken(body: PlaidBankTokenRequest, token: string, accountId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PlaidBankTokenResponse>> {
        return BankControllerApiFp(this.configuration).addPlaidBankToken(body, token, accountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Creates a wire bank account for withdraw.
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async addWithdrawWireAccount(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BankResponse>> {
        return BankControllerApiFp(this.configuration).addWithdrawWireAccount(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary To delete an existing bank account
     * @param {string} token 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async deleteBankAccount(token: string, accountId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<DeleteBankAccountResponse>> {
        return BankControllerApiFp(this.configuration).deleteBankAccount(token, accountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Gets bank account Limits
     * @param {string} token 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async getBankAccountLimits(token: string, accountId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<AccountLimitsResponse>> {
        return BankControllerApiFp(this.configuration).getBankAccountLimits(token, accountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get bank account list
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async getBankAccounts(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BankAccountsResponse>> {
        return BankControllerApiFp(this.configuration).getBankAccounts(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get bank account list
     * @param {string} token 
     * @param {string} country 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async getBanksByCountry(token: string, country: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BanksByCountryResponse>> {
        return BankControllerApiFp(this.configuration).getBanksByCountry(token, country, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Retrieves the instructions to deposit on given bank account.
     * @param {string} token 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async getDepositBankInstructions(token: string, accountId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BankAccountDepositInstructions>> {
        return BankControllerApiFp(this.configuration).getDepositBankInstructions(token, accountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Retrieves the instructions to deposit on given wire bank account.
     * @param {string} token 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async getInstructions(token: string, accountId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BankNextStepResponse>> {
        return BankControllerApiFp(this.configuration).getInstructions(token, accountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get next step to add account
     * @param {string} token 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async getNextStep(token: string, accountId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BankNextStepResponse>> {
        return BankControllerApiFp(this.configuration).getNextStep(token, accountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary To get token and start with PLAID flow
     * @param {string} token 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async getToken(token: string, accountId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<PlaidTokenResponse>> {
        return BankControllerApiFp(this.configuration).getToken(token, accountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Creates deposit request template
     * @param {DepositRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async requestDeposit(body: DepositRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<DepositTemplateResponse>> {
        return BankControllerApiFp(this.configuration).requestDeposit(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Creates withdraw request template
     * @param {WithdrawRequest} body 
     * @param {string} token 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async requestWithdraw(body: WithdrawRequest, token: string, accountId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<WithdrawTemplateResponse>> {
        return BankControllerApiFp(this.configuration).requestWithdraw(body, token, accountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Confirms deposit request based on transaction ID (Template)
     * @param {DepositConfirmationRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async submitDeposit(body: DepositConfirmationRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<DepositResultResponse>> {
        return BankControllerApiFp(this.configuration).submitDeposit(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Submits information to withdraw in a wire account
     * @param {RequestInputDataRoot} body 
     * @param {string} token 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async submitNextStep(body: RequestInputDataRoot, token: string, accountId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BankNextStepResponse>> {
        return BankControllerApiFp(this.configuration).submitNextStep(body, token, accountId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Submits a bank wire withdraw transaction to be processed.
     * @param {BankWireWithdrawProcessRequest} body 
     * @param {string} token 
     * @param {string} accountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BankControllerApi
     */
    public async withdrawBankWireProcess(body: BankWireWithdrawProcessRequest, token: string, accountId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<BankWireWithdrawProcessResponse>> {
        return BankControllerApiFp(this.configuration).withdrawBankWireProcess(body, token, accountId, options).then((request) => request(this.axios, this.basePath));
    }
}
