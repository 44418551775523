import styled from "styled-components";
import breakpoints from "lib/theme/breakpoints";
import gridSystem from "lib/theme/gridSystem";
import { BreakpointsEnum } from "@types/enums/GridSystem";

const Grid = styled.div<{ gap?: string; margin?: string; customGrid?: any; }>`
  ${({ gap, margin, customGrid }) => {
    let styles = '';

    const _gridSystem = customGrid || gridSystem;

    styles = `display: grid;
      grid-template-columns: ${_gridSystem[BreakpointsEnum.ExtraExtraLarge]?.columsTemplate};
      grid-template-rows: ${_gridSystem[BreakpointsEnum.ExtraExtraLarge]?.rowsTemplate};
      ${_gridSystem[BreakpointsEnum.ExtraExtraLarge]?.gridAutoRows && `grid-auto-rows: ${_gridSystem[BreakpointsEnum.ExtraExtraLarge]?.gridAutoRows};`}
      gap: ${gap ?? _gridSystem[BreakpointsEnum.ExtraExtraLarge]?.gap};
      margin: ${margin ?? _gridSystem[BreakpointsEnum.ExtraExtraLarge]?.margin};
    `;
    
    styles += breakpoints.keys.map((key, indx, arr) => {
      const prevBreakpoint = breakpoints.values[breakpoints.keys[indx - 1 ]];

      const mediaQuery = prevBreakpoint
        ? `@media only screen and (min-width: ${prevBreakpoint + 1}${breakpoints.measurement}) and (max-width: ${breakpoints.values[key]}${breakpoints.measurement})` 
        : `@media only screen and (max-width: ${breakpoints.values[key]}${breakpoints.measurement})`;
      
      const gridStyles = prevBreakpoint ? `
      {
        display: grid;
        grid-template-columns: ${_gridSystem[key]?.columsTemplate};
        grid-template-rows: ${_gridSystem[key]?.rowsTemplate};
        ${_gridSystem[key]?.gridAutoRows && `grid-auto-rows: ${_gridSystem[key]?.gridAutoRows};`}
        gap: ${gap ?? _gridSystem[key]?.gap};
        margin: ${margin ?? _gridSystem[key]?.margin};
      }` : `{
        display: grid;
        grid-template-columns: ${_gridSystem[BreakpointsEnum.ExtraSmall]?.columsTemplate};
        grid-template-rows: ${_gridSystem[BreakpointsEnum.ExtraSmall]?.rowsTemplate};
        ${_gridSystem[BreakpointsEnum.ExtraSmall]?.gridAutoRows && `grid-auto-rows: ${_gridSystem[BreakpointsEnum.ExtraSmall]?.gridAutoRows};`}
        gap: ${gap ?? _gridSystem[BreakpointsEnum.ExtraSmall]?.gap};
        margin: ${margin ?? _gridSystem[BreakpointsEnum.ExtraSmall]?.margin};
      }`;

      return mediaQuery + gridStyles;
    }).toString().replaceAll(',', '');
    return styles;
  }}
`;

export default Grid;