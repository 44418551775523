import React, { FC } from "react";
import styled from "styled-components";
import { palette, typography } from "../../../lib/theme";
import { HeadingProps } from "./Typography.interface";

const HeadingBase: FC<HeadingProps> = ({
  variant = "h1",
  children,
  display = "inline",
  wrapText = true,
  ...otherProps
}) => {
  return <span {...otherProps}>{children}</span>;
};

export const Heading = styled(HeadingBase)<HeadingProps>`
  color: ${(props) =>
    props.color ? props.color : palette.darkBackgroundContrast.white};
  ${(props) => typography[props.variant]};
  display: ${(props) => props.display};
  ${({ wrapText }) => {
    if(wrapText) {
      return `white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;`;
    }
  }}
  text-align: ${(props) => props.align ?? "left"};
  letter-spacing ${(props) => props.letterSpacing ?? "-0.025rem"};
`;
