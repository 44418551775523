import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Fire: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M20.5 15.5C20.5 20 16.694 23 12 23C7.306 23 3.5 20 3.5 15.5C3.5 9 8.5 9 8.5 1C14.5 3 14.5 10 14.5 10L16.5 6C16.5 6 20.5 9 20.5 15.5Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    ;
  </svg>
);

export default Fire;
