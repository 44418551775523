import breakpoints from "./breakpoints";
import breakpointsTable from "./breakpointsTable";
import palette from "./palette";
import typography from "./typography";
import zIndex from "./zIndex";
import gridSystem from "./gridSystem";

const FONT_NAMES = ["h1", "h2", "h3", "h4", "h5", "p"];

FONT_NAMES.forEach((name) => {
  const css: any[] = [];
  Object.keys(typography[name]).forEach((styleKey) => {
    const dashKey = styleKey.replace(/.[A-Z]/, (match) => {
      return `${match[0]}-${match[1].toLowerCase()}`;
    });
    css.push(`${dashKey}: ${typography[name][styleKey]}`);
  });
  typography[name].css = css.join(";\n");
});

export { breakpoints, palette, typography, zIndex, breakpointsTable, gridSystem };
export * from "./hover";
