import React, { useState } from "react";
import "react-dates/initialize";
import moment from "moment";
import { DateRangePicker, isInclusivelyBeforeDay } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "./style.css";

const CustomDatePicker = ({
  onSelectedDates,
}: {
  onSelectedDates: Function;
}) => {
  moment.locale("us");
  const START_DATE = "startDate";
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState(START_DATE);

  return (
    <DateRangePicker
      readOnly
      startDate={startDate}
      startDateId="startDateId"
      endDate={endDate}
      endDateId="endDateId"
      onDatesChange={({ startDate, endDate }) => {
        setEndDate(endDate);
        setStartDate(startDate);
        onSelectedDates({ startDate, endDate });
      }}
      focusedInput={focusedInput}
      onFocusChange={(focusedInput) =>
        setFocusedInput(focusedInput || START_DATE)
      }
      isOutsideRange={(day) => !isInclusivelyBeforeDay(day, moment())}
      numberOfMonths={1}
      keepOpenOnDateSelect={true}
      noBorder
      hideKeyboardShortcutsPanel
      regular
    />
  );
};

export default CustomDatePicker;
