import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const LoanCoinOverHand: FC<ISVG> = ({
  size = 100,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 151 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M3 123.555L19.522 123.44C24.214 123.407 28.858 124.391 33.133 126.324L66.476 141.395C72.15 143.96 78.715 143.62 84.094 140.482L144.761 105.093C148.168 103.105 149.046 98.5719 146.627 95.4569C144.793 93.0939 141.586 92.282 138.848 93.486L99.344 110.854"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.641 110.27L95.919 111.803C99.257 111.907 102.02 109.229 102.02 105.889C102.02 103.464 100.54 101.284 98.285 100.389L51.809 81.9482C40.835 77.5932 28.593 77.698 17.695 82.238L3 88.3601"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.694 78.7573C34.172 71.3833 31.564 62.7083 31.564 53.4233C31.564 26.6223 53.29 4.89648 80.091 4.89648C106.892 4.89648 128.618 26.6223 128.618 53.4233C128.618 74.2643 115.48 92.0374 97.034 98.9104"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.2081 74.0486C42.3381 67.8916 40.1001 60.6056 40.1001 52.7976C40.1001 30.7116 58.0051 12.8066 80.0911 12.8066C102.177 12.8066 120.082 30.7116 120.082 52.7976C120.082 71.1006 107.786 86.5327 91.0031 91.2817"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M99.0888 57.2276C98.3412 60.6383 96.6508 63.8687 94.0142 66.5054C86.556 73.9635 74.3492 73.8492 66.7502 66.2502C59.1511 58.6511 59.0368 46.4443 66.495 38.9861C72.6756 32.8055 82.1175 31.8249 89.4957 35.9957"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LoanCoinOverHand;
