import React from "react";
import ReactDOM from "react-dom";
import { ToastOptions, ToastProps } from ".";
import Toast from "./Toast";
import { zIndex } from "../../../lib/theme";

export class ToastManager {
  private static instance: ToastManager;

  private containerRef: HTMLDivElement;
  private toasts: ToastProps[] = [];

  private constructor() {
    const body = document.getElementsByTagName("body")[0] as HTMLBodyElement;
    const toastContainer = document.createElement("div") as HTMLDivElement;
    toastContainer.id = "toast-container";
    toastContainer.style.setProperty("position", "fixed");
    toastContainer.style.setProperty("box-sizing", "border-box");
    toastContainer.style.setProperty("z-index", `${zIndex.toast}`);
    toastContainer.style.setProperty("top", "20px");
    toastContainer.style.setProperty("right", "20px");
    body.insertAdjacentElement("beforeend", toastContainer);
    this.containerRef = toastContainer;
  }

  public show(options: ToastOptions): void {
    const toastId = Math.random().toString(36).substr(2, 9);
    const toast: ToastProps = {
      id: toastId,
      ...options,
      destroy: () => this.destroy(options.id ?? toastId),
    };

    this.toasts = [toast, ...this.toasts];
    this.render();
  }

  public destroy(id: string): void {
    this.toasts = this.toasts.filter((toast: ToastProps) => toast.id !== id);
    this.render();
  }

  private render(): void {
    const toastsList = this.toasts.map((toastProps: ToastProps) => (
      <Toast key={toastProps.id} {...toastProps} />
    ));
    ReactDOM.render(toastsList, this.containerRef);
  }

  public static getInstance(): ToastManager {
    if (!ToastManager.instance) {
      ToastManager.instance = new ToastManager();
    }

    return ToastManager.instance;
  }
}

export const toast = ToastManager.getInstance();
