import GridSystem from "@types/theme/gridSystem";
import { BreakpointsEnum } from "@types/enums/GridSystem";
import { DefaultSettings } from "lib/constants/config.constant";

const gridSystem: {
  [BreakpointsEnum.ExtraSmall]: GridSystem,
  [BreakpointsEnum.Small]: GridSystem,
  [BreakpointsEnum.Medium]: GridSystem,
  [BreakpointsEnum.Large]: GridSystem,
  [BreakpointsEnum.ExtraLarge]: GridSystem,
  [BreakpointsEnum.ExtraExtraLarge]: GridSystem,
  ["Default"]: GridSystem,
} = {
  [BreakpointsEnum.ExtraExtraLarge]: {
    columsTemplate: `1fr 400px`,
    rowsTemplate: 'auto',
    gridAutoRows: '10px',
    gap: '10px 20px',
    margin: '20px 0 0',
    columnStart: 1,
    columnEnd: 2
  },
  [BreakpointsEnum.ExtraLarge]: {
    columsTemplate: `1fr 400px`,
    rowsTemplate: 'auto',
    gridAutoRows: '10px',
    gap: '10px 20px',
    margin: '20px 0 0',
    columnStart: 1,
    columnEnd: 2,
  },
  [BreakpointsEnum.Large]: {
    columsTemplate: `1fr 400px`,
    rowsTemplate: 'auto',
    gridAutoRows: '10px',
    gap: '10px 20px',
    margin: '20px 0 0',
    columnStart: 1,
    columnEnd: 2,
  },
  [BreakpointsEnum.Medium]: {
    columsTemplate: `1fr 1fr`,
    rowsTemplate: 'auto',
    gridAutoRows: '10px',
    gap: '10px 20px',
    margin: '20px 0 0',
    columnStart: 1,
    columnEnd: 2,
  },
  [BreakpointsEnum.Small]: {
    columsTemplate: `1fr 1fr`,
    rowsTemplate: 'auto',
    gridAutoRows: '10px',
    gap: '10px 20px',
    margin: '20px 0 0',
    columnStart: 1,
    columnEnd: 2,
  },
  [BreakpointsEnum.ExtraSmall]: {
    columsTemplate: `1fr 1fr`,
    rowsTemplate: 'auto',
    gridAutoRows: '10px',
    gap: '10px 20px',
    margin: '20px 0 0',
    columnStart: 1,
    columnEnd: 2,
  },
  ["Default"]: {
    columsTemplate: `1fr repeat(${DefaultSettings?.TOTAL_COLUMNS_DESKTOP}, minmax(auto, ${DefaultSettings?.MAX_COLUMNS_WIDTH})) 1fr`,
    rowsTemplate: 'max-content',
    gap: '1rem 1rem',
    margin: '20px 0 0',
    columnStart: 1,
    columnEnd: 12,
  },
};

export default gridSystem;