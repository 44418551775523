import React, { useState } from 'react';
import {  QuestionCircle } from 'assets/icons';
import { AssetNetworkInformation } from 'state/store/custodyApi';
import styled from 'styled-components';
import { Heading } from 'components/atoms/Typography';
import LoadingBar from 'components/atoms/Loading/LoadingBar';
import useAssets from 'shared/useAssets';
import BalanceExplanationPopup from './BalanceExplanationPopup';

interface AccountInfoProps {
    smaAccountNumber?: string;
    loadingWallet: boolean;
    custodyAssetNetworkInformation: AssetNetworkInformation | undefined;
    asset: string;
    availableAmountIsDifferent: boolean;
    feeSameAsAsset: boolean;
}

const AccountInfo: React.FC<AccountInfoProps> = ({
    smaAccountNumber,
    loadingWallet,
    custodyAssetNetworkInformation,
    asset,
    availableAmountIsDifferent,
    feeSameAsAsset,
}) => {
    const assets = useAssets();
    const [showExplanation, setShowExplanation] = useState(false);

    return (
        <>
            <Heading variant='h3'>
                Withdraw{' '}
                {smaAccountNumber && `from SMA Account ${smaAccountNumber}`}
            </Heading>
            <Container>
                {loadingWallet && <LoadingBar />}
                {custodyAssetNetworkInformation && (
                    <>
                        <Paragraph>
                            Current account balance is{' '}
                            <strong>
                                {assets.getPriceFormattedI(
                                    asset,
                                    custodyAssetNetworkInformation
                                        ?.totalAmountInAsset?.amount
                                )}
                            </strong>
                        </Paragraph>
                        {availableAmountIsDifferent && (
                            <>
                            {showExplanation && (
                                <BalanceExplanationPopup
                                    show={showExplanation}
                                    explanation={custodyAssetNetworkInformation?.explanation}
                                    currentBalance={custodyAssetNetworkInformation?.totalAmountInAsset}
                                    onClose={() => {
                                        setShowExplanation(false)
                                    }}
                                />
                            )}
                           
                                <Paragraph>Available amount for withdrawal is
                                    {' '}
                                    {assets.getPriceFormattedI(
                                        asset,
                                        custodyAssetNetworkInformation
                                            ?.availableAmountInAsset?.amount
                                    )}{' '}
                                </Paragraph>
                                {' '}
                                <InfoButton 
                                    onClick={() => setShowExplanation(true)}>
                                    Learn More <QuestionCircle color='#7b6fe9' />
                                </InfoButton>
 
                            </>
                        )}
                        {!feeSameAsAsset && (
                            <Paragraph>
                                This withdraw has an estimated fee of{' '}
                                {assets.getPriceFormattedI(
                                    custodyAssetNetworkInformation?.estimatedFee
                                        ?.asset,
                                    custodyAssetNetworkInformation?.estimatedFee
                                        ?.amount
                                )}{' '}
                                and your available{' '}
                                {
                                    assets.getAssetByIdentifier(
                                        custodyAssetNetworkInformation
                                            ?.estimatedFee?.asset
                                    )?.name
                                }{' '}
                                balance is{' '}
                                {assets.getPriceFormattedI(
                                    custodyAssetNetworkInformation?.estimatedFee
                                        ?.asset,
                                    custodyAssetNetworkInformation
                                        ?.availableAmountInFeeAsset?.amount
                                )}
                            </Paragraph>
                        )}
                    </>
                )}
            </Container>
        </>
    );
};

const Paragraph = styled.p`
    opacity: 0.9;
    font-size: 0.8rem;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
`;

const InfoButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #7b6fe9;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.51px;
  z-index: 1000;
`;

export default AccountInfo;
