import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Export: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = "white",
  bgColor = "#A399F6",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.707 5.70719L11 4.41422V17.0002H8C7.448 17.0002 7 17.4472 7 18.0002C7 18.5532 7.448 19.0002 8 19.0002H16C16.552 19.0002 17 18.5532 17 18.0002C17 17.4472 16.552 17.0002 16 17.0002H13V4.41422L14.293 5.70719C14.488 5.90219 14.744 6.00022 15 6.00022C15.256 6.00022 15.512 5.90219 15.707 5.70719C16.098 5.31619 16.098 4.68425 15.707 4.29325L12.707 1.29325C12.317 0.90225 11.683 0.90225 11.293 1.29325L8.293 4.29325C7.902 4.68425 7.902 5.31619 8.293 5.70719C8.683 6.09819 9.317 6.09819 9.707 5.70719Z"
      fill={color}
    />
    <path
      d="M17 8H15C14.448 8 14 8.447 14 9C14 9.553 14.448 10 15 10H17C19.206 10 21 11.794 21 14V17C21 19.206 19.206 21 17 21H7C4.794 21 3 19.206 3 17V14C3 11.794 4.794 10 7 10H9C9.552 10 10 9.553 10 9C10 8.447 9.552 8 9 8H7C3.691 8 1 10.691 1 14V17C1 20.309 3.691 23 7 23H17C20.309 23 23 20.309 23 17V14C23 10.691 20.309 8 17 8Z"
      fill={color}
    />
  </svg>
);

export default Export;
