import Layout from 'components/templates/Layout';
import alternativeGridSystem from 'lib/theme/alternativeGridSystem';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CustodyStyledTable } from './CustodyStyles';
import CustodyNavigationWidget from './CustodyNavigationWidget';
import styled from 'styled-components';
import { MarginContainer } from './Custody';
import { PagesUrl } from 'lib/constants/config.constant';
import useCustodyRedirect from './extraCustodyHooks/useCustodyRedirect';
import { 
  useGetAvailableAnnualDocumentsQuery, 
  useGetAvailableMonthlyDocumentsQuery,
  AnnualDocumentsAvailability,
  MonthlyDocumentsAvailability 
} from 'state/store/custodyApi';
import SharedLoadingState from './document/SharedLoadingState';

const STATEMENT_URL = PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.STATEMENT;
const INVOICE_URL = PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.INVOICE;
const INCOME_URL = PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.INCOME;
const GAIN_LOSS_URL = PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.GAIN_LOSS;
const EXPENSES_URL = PagesUrl.CUSTODY + PagesUrl.DOCUMENT + PagesUrl.EXPENSES;

const CustodyDocuments: React.FC = () => {
  const monthlyDocumentsQuery = useGetAvailableMonthlyDocumentsQuery();
  const annualDocumentsQuery = useGetAvailableAnnualDocumentsQuery();
  const monthlyDocuments = monthlyDocumentsQuery.data ?? [];
  const annualDocuments = annualDocumentsQuery.data ?? [];
  const navigate = useNavigate();
  useCustodyRedirect();

  const getDate = (yearMonth: string): Date => {
    const [yearString, monthString] = yearMonth.split('-');
    return new Date(parseInt(yearString), parseInt(monthString) - 1);
  };

  const formatMonthYear = (yearMonth: string) => {
    const date = getDate(yearMonth);
    const month = date.toLocaleString('default', { month: 'short' });
    const capitalMonth = month.charAt(0).toUpperCase() + month.slice(1);
    const year = date.getFullYear();
    return `${capitalMonth} ${year}`;
  };

  const getMonthlyRedirectUrl = (baseUrl: string, yearMonth: string): string => {
    return `${baseUrl}/${yearMonth.split('-')[0]}/${yearMonth.split('-')[1]}`;
  };

  const getAnnualRedirectUrl = (baseUrl: string, year: string): string => {
    return `${baseUrl}/${year}`;
  };

  const isLoading = monthlyDocumentsQuery.isLoading || annualDocumentsQuery.isLoading;

  return (
    <Layout customGrid={alternativeGridSystem}>
      <MarginContainer>
        <CustodyNavigationWidget>
          <StatementsTitle>Custody Documents</StatementsTitle>
          {isLoading ? <SharedLoadingState /> : (
          <>
          <DocumentSection>
            <SectionTitle>Monthly Documents</SectionTitle>
            {monthlyDocuments.length > 0 ? (
              <CustodyStyledTable>
                <thead>
                  <tr>
                    <th>Statement</th>
                    <th>Invoice</th>
                  </tr>
                </thead>
                <tbody>{displayMonthlyDocumentsTable(monthlyDocuments)}</tbody>
              </CustodyStyledTable>
            ) : (
              <EmptyStateContainer>
                <EmptyStateText>No monthly documents available</EmptyStateText>
              </EmptyStateContainer>
            )}
          </DocumentSection>

          <DocumentSection>
            <SectionTitle>Annual Documents</SectionTitle>
            {annualDocuments.length > 0 ? (
              <CustodyStyledTable>
                <thead>
                  <tr>
                    <th>Income Statement</th>
                    <th>Gain/Loss Report</th>
                    <th>Expenses Report</th>
                  </tr>
                </thead>
                <tbody>{displayAnnualDocumentsTable(annualDocuments)}</tbody>
              </CustodyStyledTable>
            ) : (
              <EmptyStateContainer>
                <EmptyStateText>No annual documents available</EmptyStateText>
              </EmptyStateContainer>
            )}
          </DocumentSection>
          </>
          )}  
        </CustodyNavigationWidget>
      </MarginContainer>
    </Layout>
  );

  function displayMonthlyDocumentsTable(documents: MonthlyDocumentsAvailability[]): React.ReactNode {
    return documents.map((docs) => {
      const yearMonth = docs.yearMonth;
      return (
        <tr key={docs.yearMonth}>
          <td>
            <ButtonsArea>
              <ActionButton isVisible={docs.hasStatement} onClick={() => navigate(getMonthlyRedirectUrl(STATEMENT_URL, yearMonth))}>
                <span>{formatMonthYear(yearMonth)}</span>
              </ActionButton>
            </ButtonsArea>
          </td>
          <td>
            <ButtonsArea>
              <ActionButton isVisible={docs.hasInvoice} onClick={() => navigate(getMonthlyRedirectUrl(INVOICE_URL, yearMonth))}>
                <span>{formatMonthYear(yearMonth)}</span>
              </ActionButton>
            </ButtonsArea>
          </td>
        </tr>
      );
    });
  }

  function displayAnnualDocumentsTable(documents: AnnualDocumentsAvailability[]): React.ReactNode {
    return documents.map((docs) => (
      <tr key={docs.year}>
        <td>
          <ButtonsArea>
            <ActionButton isVisible={docs.hasIncome} onClick={() => navigate(getAnnualRedirectUrl(INCOME_URL, docs.year))}>
              <span>{docs.year}</span>
            </ActionButton>
          </ButtonsArea>
        </td>
        <td>
          <ButtonsArea>
            <ActionButton isVisible={docs.hasGainLoss} onClick={() => navigate(getAnnualRedirectUrl(GAIN_LOSS_URL, docs.year))}>
              <span>{docs.year}</span>
            </ActionButton>
          </ButtonsArea>
        </td>
        <td>
          <ButtonsArea>
            <ActionButton isVisible={docs.hasExpenses} onClick={() => navigate(getAnnualRedirectUrl(EXPENSES_URL, docs.year))}>
              <span>{docs.year}</span>
            </ActionButton>
          </ButtonsArea>
        </td>
      </tr>
    ));
  }
};

interface ActionButtonProps {
  isVisible?: boolean;
}

const ActionButton = styled.div<ActionButtonProps>`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-around;
  background-color: #6f2acd;
  border-radius: 0.5vw;
  padding: 0.5vw 1vw;
  width: 8vw;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #292749;
  }
`;

const StatementsTitle = styled.div`
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  letter-spacing: -0.5px;
  margin-bottom: 12px;
`;

const DocumentSection = styled.div`
  margin-bottom: 24px;
`;

const SectionTitle = styled.div`
  font-weight: 500;
  color: #FFFFFF;
  font-size: 16px;
  margin-bottom: 12px;
`;

ActionButton.defaultProps = {
  isVisible: true,
};

const ButtonsArea = styled.div`
  display: flex;
  gap: 0.5vw;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background: rgba(111, 42, 205, 0.1);
  border-radius: 8px;
`;

const EmptyStateText = styled.p`
  color: #8E8EA8;
  font-size: 14px;
  text-align: center;
  margin: 0;
`;

export default CustodyDocuments;