import React, { FC } from 'react';
import ISVG from '@types/icons/svg';

export const Info: FC<ISVG> = ({ size = 24, width = size, height = size, color = '#E99928', svgProps }) => (
  <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...svgProps}>
    <path
      d='M8 1.33333C6.68146 1.33333 5.39252 1.72432 4.2962 2.45686C3.19987 3.18941 2.34539 4.2306 1.8408 5.44877C1.33622 6.66695 1.20419 8.00739 1.46143 9.3006C1.71866 10.5938 2.3536 11.7817 3.28595 12.714C4.2183 13.6464 5.40619 14.2813 6.6994 14.5386C7.9926 14.7958 9.33305 14.6638 10.5512 14.1592C11.7694 13.6546 12.8106 12.8001 13.5431 11.7038C14.2757 10.6075 14.6667 9.31854 14.6667 8C14.6647 6.23249 13.9617 4.53794 12.7119 3.28812C11.4621 2.0383 9.7675 1.33529 8 1.33333ZM8 13.3333C6.94516 13.3333 5.91402 13.0205 5.03696 12.4345C4.15989 11.8485 3.47631 11.0155 3.07264 10.041C2.66897 9.06643 2.56336 7.99408 2.76914 6.95951C2.97493 5.92495 3.48288 4.97464 4.22876 4.22876C4.97464 3.48288 5.92495 2.97493 6.95952 2.76914C7.99408 2.56335 9.06644 2.66897 10.041 3.07264C11.0155 3.47631 11.8485 4.15989 12.4345 5.03695C13.0205 5.91402 13.3333 6.94516 13.3333 8C13.3317 9.41399 12.7693 10.7696 11.7694 11.7694C10.7696 12.7693 9.41399 13.3317 8 13.3333ZM8 7.66666C7.82319 7.66666 7.65362 7.7369 7.52859 7.86192C7.40357 7.98695 7.33333 8.15652 7.33333 8.33333V10.3333C7.33333 10.5101 7.40357 10.6797 7.52859 10.8047C7.65362 10.9298 7.82319 11 8 11C8.17681 11 8.34638 10.9298 8.4714 10.8047C8.59643 10.6797 8.66666 10.5101 8.66666 10.3333V8.33333C8.66666 8.15652 8.59643 7.98695 8.4714 7.86192C8.34638 7.7369 8.17681 7.66666 8 7.66666ZM8 4.99999C7.83518 4.99999 7.67406 5.04887 7.53702 5.14044C7.39998 5.232 7.29317 5.36215 7.2301 5.51443C7.16703 5.6667 7.15052 5.83425 7.18268 5.9959C7.21483 6.15755 7.2942 6.30604 7.41074 6.42258C7.52729 6.53913 7.67577 6.61849 7.83742 6.65065C7.99907 6.6828 8.16663 6.6663 8.3189 6.60323C8.47117 6.54015 8.60132 6.43334 8.69289 6.2963C8.78446 6.15926 8.83333 5.99815 8.83333 5.83333C8.83333 5.61231 8.74553 5.40035 8.58925 5.24407C8.43297 5.08779 8.22101 4.99999 8 4.99999Z'
      fill={color}
    />
  </svg>
);

export default Info;
