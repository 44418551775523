import { privacyPolicyURL, termsOfServiceURL } from 'lib/constants/urls';
import React, { useState } from 'react';
import Link from '../../atoms/Link';
import { StyledBottomLinks } from 'styles/components/authentication';
import styled from 'styled-components';
import { openInNewTab } from 'shared/openInNewTab';
import Modal from 'components/organisms/Modal';
import { palette } from 'lib/theme';
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const [supportOpen, setSupportOpen] = useState(false);
  const navigate = useNavigate()
  return (
    <Row>
      <StyledBottomLinks>
        <Link
          label='Privacy policy'
          onClick={() => {
            openInNewTab(privacyPolicyURL);
          }}
        />
        <Link
          label={'Terms of service'}
          onClick={() => {
            openInNewTab(termsOfServiceURL);
          }}
        />
        <Link
          label={'Support'}
          onClick={() => {
            setSupportOpen(!supportOpen);
          }}
        />
        <Link
          label={'Disclaimer'}
          onClick={() => {
            navigate('/disclaimer');
          }}
        />
        <Modal visible={supportOpen} onClose={() => setSupportOpen(false)} header='Support'>
          <Modal.Body>
            <MarginContainer>
              If you have any questions, please email <ModalLink href='mailto:"support@abra.com"'>support@abra.com</ModalLink>
            </MarginContainer>
          </Modal.Body>
        </Modal>
      </StyledBottomLinks>
    </Row>
  );
};

export default Footer;

const MarginContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
`;
const Row = styled.div`
  flex-direction: column;
  align-items: start;
  padding-left: 7px;
`;
const ModalLink = styled.a`
  color: ${palette.accent.light};
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.51px;
`;
