import React, { FC, useEffect, useState } from 'react';

import { Sizes } from './Boost';
import { Heading, Text } from 'components/atoms/Typography';
import PanelWithHeader from 'components/molecules/PanelWithHeader';
import Currency from 'components/atoms/Number/Currency';
import { Number } from 'components/atoms/Number';
import Image from 'components/atoms/Image';
import AbraTable from 'components/organisms/AbraTable';
import Row from 'components/atoms/Row/Row';
import { OrdersSummaryResponse, useGetAssetsQuery } from 'state/store/api';
import LoadingStage from 'pages/LoadingStage';
import Icon from 'components/atoms/Icon';
import { palette } from 'lib/theme';
import getAssetPrice from 'shared/getAssetPrice';
import { useSelector } from 'react-redux';
import { pricesSelector } from 'state/slice/prices.slice';
import { PagesUrl } from 'lib/constants/config.constant';
import { useNavigate } from 'react-router-dom';

interface TokenSummaryItem {
  icon: string;
  assetName: string;
  assetLongName: string;
  price: number;
  totalBalance: number;
  convenienceCurrencyBalance: number;
  totalInterest: number;
  totalInterestBalance: number;
  totalInterestYear: number;
  totalInterestBalanceYear: number;
  openDeposits: number;
  amount?: number
}
interface TokenSummaryProps extends Sizes {
  orders: OrdersSummaryResponse;
  isLoading: boolean;
  isFetching: boolean;
}

const TokenSummary: FC<TokenSummaryProps> = (props) => {
  const { orders, isLoading, isFetching } = props;
  const prices = useSelector(pricesSelector);
  const { data: assetsInfo, isLoading: assetsLoading } = useGetAssetsQuery();
  const navigate = useNavigate();
  const [items, setItems] = useState<TokenSummaryItem[]>([]);

  useEffect(() => {
    if (!orders && !assetsInfo && !prices) return;

    const response = !orders?.assets
      ? []
      : orders?.assets.map((item) => {
          const asset = assetsInfo?.assets?.find((m) => m.name?.toUpperCase() === item.asset?.toUpperCase());
          const price = prices?.find((m) => m.identifier === item.asset)?.price;
          return {
            icon: asset?.icon,
            assetName: asset?.name,
            assetLongName: asset?.longName,
            price: parseFloat(price ?? '0'),
            amount: getAssetPrice(asset, item.amount),
            totalBalance: getAssetPrice(asset, item.initialPrincipalDeposit),
            convenienceCurrencyBalance: getAssetPrice(asset, parseFloat(item.amount ?? '0') * parseFloat(price ?? '0')),
            totalInterestYear: getAssetPrice(asset, item.interestAccruedYearToDate),
            totalInterestBalanceYear: parseFloat(item.interestAccruedYearToDate ?? '0') * parseFloat(price ?? '0'),
            openDeposits: item?.openOrders ?? 0,
          } as TokenSummaryItem;
        });

    setItems(response);
  }, [orders, assetsInfo, prices]);

  const columns = [
    {
      title: 'Currency',
      enableSort: false,
      accessor: 'currency',
    },
    {
      title: 'Price',
      enableSort: false,
      accessor: 'price',
    },
    {
      title: 'Current Balance',
      enableSort: false,
      accessor: 'amount',
    },
    {
      title: 'Interest accrued',
      enableSort: false,
      accessor: 'interestAccrued',
    },
    {
      title: 'Open deposits',
      enableSort: false,
      accessor: 'openDeposits',
    },
  ];

  const rows = items.map((item) => {
    const { icon, assetName, assetLongName, price, amount, convenienceCurrencyBalance, totalInterestYear, totalInterestBalanceYear, openDeposits } = item;
    return {
      schema: item,
      currency: () => (
        <Row gap='12px'>
          <Image width={40} height={40} src={icon} />
          <Row direction='column'>
            <Text>{assetLongName}</Text>
            <Text size='tiny' opacity={0.7}>
              {assetName}
            </Text>
          </Row>
        </Row>
      ),
      price: () => (
        <Text>
          <Currency value={price} />
        </Text>
      ),
      amount: () => (
        <Row direction='column'>
          <div>
            {amount} <Text>{assetName}</Text>
          </div>
          <Text size='tiny' opacity={0.7}>
            <Currency value={convenienceCurrencyBalance} />
          </Text>
        </Row>
      ),
      interestAccrued: () => (
        <Row direction='column'>
          <div>
            {totalInterestYear} <Text>{assetName}</Text>
          </div>
          <Text size='tiny' opacity={0.7}>
            <Currency value={totalInterestBalanceYear} />
          </Text>
        </Row>
      ),
      openDeposits: () => <Text>{openDeposits}</Text>,
    };
  });

  return (
    <PanelWithHeader width='100%' minHeight='292px' title='Token Summary' {...props}>
      {isLoading || assetsLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <LoadingStage loadingText=' ' />
        </div>
      ) : orders.assets?.length ? (
        <AbraTable columns={columns} data={rows} showPagination={false} recordsPerPage={Infinity} isLoading={isLoading} isSorting={isFetching} expandTable />
      ) : (
        <EmptyStage />
      )}
    </PanelWithHeader>
  );
};

const EmptyStage = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Icon name='NoHoldings' size={150} />
      <div
        style={{
          marginBottom: '8px',
          display: 'flex',
          gap: '8px',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Heading variant='h3' color={palette.gray.main as string}>
          No holdings yet
        </Heading>
      </div>
    </div>
  </div>
);

export default TokenSummary;
