import React, { FC, useEffect } from 'react';
import { palette } from 'lib/theme';
import styled from 'styled-components';
import Divider from 'components/atoms/Divider';
import { Heading, Text } from 'components/atoms/Typography';
import Currency from 'components/atoms/Number/Currency';
import { capitalize } from 'shared';
import { TypeReferenceTxrCompleted } from 'shared/transactionTypeReference';
import { getText } from 'shared/locale-helper';
import { TransactionDto } from 'state/store/api';

const SidebarTransactionsDetails: FC<{
  onClick?: Function;
  data: TransactionDto;
}> = ({ onClick, data }) => {
  const getHeader = (_data: TransactionDto) => {
    const { transactionAmount, currency, price } = _data;

    return (
      <Row style={{ flexDirection: 'column' }} p='0 0 32px 0'>
        <Heading variant='h1'>
          {transactionAmount} {currency?.toUpperCase()}
        </Heading>
        <Heading variant='h2' color={palette.darkBackgroundContrast.light70}>
          <Currency value={price} />
        </Heading>
      </Row>
    );
  };

  const getTransactionBody = (_data: TransactionDto) => {
    if (_data?.transactionType && Object.keys(TypeReferenceTxrCompleted).includes(_data?.transactionType)) {
      const typeReference = TypeReferenceTxrCompleted[_data?.transactionType];

      if (typeReference?.getDetail === undefined) return <></>;

      const transactionInfo = typeReference?.getDetail(_data) ?? {};
      const entries = Object.entries(transactionInfo);
      return entries?.map((entrie, index) => (
        <Row key={`txr-${index}`}>
          <Item>{capitalize(getText(entrie[0]), true)}</Item>
          <Value>{entrie[1]}</Value>
        </Row>
      ));
    }
  };
  return (
    <Container>
      {getHeader(data)}
      <Divider />
      <ScrollContainer>
        <div style={{ marginRight: '20px', marginLeft: '20px' }}>
          <InfoContainer>
            <Row style={{ justifyContent: 'flex-start' }} m='0 0 24px 0'>
              <Text bold size='large'>
                Order details
              </Text>
            </Row>
            {getTransactionBody(data)}
          </InfoContainer>
        </div>
      </ScrollContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: transparent;
  color: ${palette.darkBackgroundContrast.white};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const Row = styled.div<{ p?: string; m?: string }>`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: ${(props) => props?.p || '0px 0px'};
  margin: ${(props) => props?.m || '0px 0px'};
`;
const Item = styled(Text)`
  text-align: left;
  padding-bottom: 13px;
  flex-grow: 1;
  width: 40%;
`;
const Value = styled(Item)`
  width: 60%;
  text-align: right;
  word-wrap: break-word;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-top: 20px;
`;
const TxrContainer = styled.div`
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  height: 0px;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: overlay;
  overflow-x: hidden;
  flex: 1 1 auto;
  min-height: 0px;
  margin-right: -20px;
  margin-left: -20px;
  ::-webkit-scrollbar {
    display: initial !important;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    border: none;
    margin-top: 20vh;
    margin-bottom: 20vh;
  }

  ::-webkit-scrollbar-thumb {
    background: ${palette.white.light20};
    border-radius: 100px;
    border: none;
  }
`;
export default SidebarTransactionsDetails;
