import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AssetsControllerApi } from "services";
import { Assets } from "services/models/assets";

const token = localStorage.getItem("token") ?? "";

interface AssetsDetailState {
  assets: Assets;
}

const initialState = {} as AssetsDetailState;

const AssetsService = new AssetsControllerApi();

export const assetDetailsSlice = createSlice({
  name: "assetDetails",
  initialState,
  reducers: {
    reset: () => initialState,
    setAsset: (state, action: PayloadAction<Assets>) => {
      state.assets = action.payload;
    },
  },
});

export const getAssets = () => async (dispatch: any) => {
  try {
    const response: any = await AssetsService.get(token);
    dispatch(setAsset(response?.data?.assets));
  } catch (error) {
    console.warn(error);
  }
};

export const { setAsset } = assetDetailsSlice.actions;

export const assetDetailsReducer = assetDetailsSlice.reducer;