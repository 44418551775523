import React, { FC } from "react"
import Layout from "components/templates/Layout"
import {BorrowPortfolio} from './BorrowPortfolio'

const DefiBorrow: FC = () => {

  return (
    <Layout>
      <BorrowPortfolio />
    </Layout>
  )
}

export default DefiBorrow
