import { useGetLoanBalancesQuery, useGetLoanPendingTransactionsQuery } from "state/store/loanApi";


const useLoan = (loanProvider: string) => {
  const loanTransactions = useGetLoanPendingTransactionsQuery(loanProvider);
  const loanBalances = useGetLoanBalancesQuery(null)
  
  return {
    loanTransactions,
    loanBalances
  }
}

export default useLoan
