import React, { FC, useEffect, useState } from "react"
import { capitalize, shortDateformatter } from "shared"
import { Column } from "components/organisms/AbraTable/interfaces"
import { DefaultSettings } from "lib/constants/config.constant"
import { Heading, Text } from "components/atoms/Typography"
import { Number } from "components/atoms/Number"
import { palette } from "lib/theme"
import { TableSortDirection } from "@types/enums/Table.enum"
import { useNavigate } from "react-router-dom"
import {
  useGetAssetsQuery,
  useGetCompoundScheduleQuery
} from "state/store/api"
import AbraTable from "components/organisms/AbraTable"
import Currency from "components/atoms/Number/Currency"
import Icon from "components/atoms/Icon/Icon"
import LoadingStage from "pages/LoadingStage"
import PanelWithHeader from "components/molecules/PanelWithHeader"
import styled from "styled-components"
import getAssetPrice from "shared/getAssetPrice"
import { pricesSelector } from 'state/slice/prices.slice'
import { useSelector } from 'react-redux'

const CompoundWidget = (props) => {
  const navigate = useNavigate()
  const [transactionsItems, setTransactionsItems] = useState([])

  const [page, setPage] = useState(1)
  const [sort, setSort] = useState("status")
  const [sortDirection, setSortDirection] = useState(
    TableSortDirection.DESCENDING
  )
  const recordsPerPage = DefaultSettings.RECORDS_PER_PAGE

  const { data, isLoading, isFetching } = useGetCompoundScheduleQuery({
    orderId: props?.orderId,
  })

  const { data: assets } = useGetAssetsQuery()

  const getAssetFromIdentifier = (identifier: string = "") => {
    return assets?.assets?.find(
      (asset) => asset.identifier === identifier.toUpperCase()
    )
  }

  const prices = useSelector(pricesSelector)

  useEffect(() => {
    if (!data) return
    const response = data.data ? [...data.data] : []
    response.sort(r => r.dueDate ? -new Date(r.dueDate).getTime() : 1)
    const mappedResponse = response.map((item: PaymentResponse) => {
      const price = prices?.find(
        (m) => m.identifier === item.currency
      )?.price
      return {
        dueDate: item?.dueDate
          ? shortDateformatter(new Date(item?.dueDate))
          : "",
        paymentDate: item?.paymentDate
          ? shortDateformatter(new Date(item?.paymentDate))
          : "",
        openingBalance: getAssetPrice(
          getAssetFromIdentifier(item.currency),
          item.beginPrincipal
        ),
        openingBalancePrice:
          parseFloat(item.beginPrincipal ?? "0") * price,
        interestAmountPaid: getAssetPrice(
          getAssetFromIdentifier(item.currency),
          item.paidInterest
        ),
        interestAmountPaidPrice:
          parseFloat(item.paidInterest ?? "0") * price,
        closingBalance: getAssetPrice(
          getAssetFromIdentifier(item.currency),
          item.closePrincipal
        ),
        closingBalancePrice:
          parseFloat(item.closePrincipal ?? "0") * price,
        status: item.status,
        currency: item?.currency?.toUpperCase(),
      }
    })

    setTransactionsItems(mappedResponse)
  }, [data, prices, assets])

  const [columns, setColumns] = useState<Column[]>([
    {
      title: "Due Date",
      enableSort: false,
      accessor: "dueDate",
    },
    {
      title: "Payment Date",
      enableSort: false,
      accessor: "paymentDate",
    },
    {
      title: "Opening Balance",
      enableSort: false,
      accessor: "openingBalance",
    },
    {
      title: "Interest Amount to be added",
      enableSort: false,
      accessor: "interestAmountPaid",
    },
    {
      title: "Closing Balance",
      enableSort: false,
      accessor: "closingBalance",
    },
    {
      title: "Status",
      enableSort: false,
      accessor: "status",
    },
  ])

  const rows = transactionsItems.map((item) => {
    const {
      dueDate,
      paymentDate,
      openingBalance,
      openingBalancePrice,
      interestAmountPaid,
      interestAmountPaidPrice,
      closingBalance,
      closingBalancePrice,
      status,
      currency,
    } = item

    return {
      schema: item,
      dueDate: () => <Text>{dueDate}</Text>,
      paymentDate: () => <Text>{paymentDate}</Text>,
      openingBalance: () => (
        <ColumnWrapp>
          <div>
            {openingBalance} {currency}
          </div>
          <Text
            size="tiny"
            opacity={0.7}
            color={palette.darkBackgroundContrast.light70}
          >
            <Currency value={openingBalancePrice} />
          </Text>
        </ColumnWrapp>
      ),
      interestAmountPaid: () => (
        <ColumnWrapp>
          <div>
            {interestAmountPaid} {currency}
          </div>
          <Text
            size="tiny"
            opacity={0.7}
            color={palette.darkBackgroundContrast.light70}
          >
            <Currency value={interestAmountPaidPrice} />
          </Text>
        </ColumnWrapp>
      ),
      closingBalance: () => (
        <ColumnWrapp>
          <div>
            {closingBalance} {currency}
          </div>
          <Text
            size="tiny"
            opacity={0.7}
            color={palette.darkBackgroundContrast.light70}
          >
            <Currency value={closingBalancePrice} />
          </Text>
        </ColumnWrapp>
      ),
      status: () => <Text>{capitalize(status)}</Text>,
    }
  })

  const handlePaginationChange = (currentPage: number) => {
    setPage(currentPage)
  }

  const handleAscendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key ? key : "dueDate")
    setSortDirection(TableSortDirection.ASCENDING)
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection:
          column.accessor === key
            ? TableSortDirection.ASCENDING
            : TableSortDirection.UNSORTED,
      }))
    )
    setPage(1)
  }

  const handleDescendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key ? key : "dueDate")
    setSortDirection(TableSortDirection.DESCENDING)
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection:
          column.accessor === key
            ? TableSortDirection.DESCENDING
            : TableSortDirection.UNSORTED,
      }))
    )
    setPage(1)
  }

  const handleResetSort = (key: string, data: any, setTableData: any) => {
    setSort("dueDate")
    setSortDirection(TableSortDirection.DESCENDING)
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: TableSortDirection.UNSORTED,
      }))
    )
    setPage(1)
  }

  return (
    <PanelWithHeader width="100%" title="Payment schedule" {...props}>
      {isLoading ? (
        <LoadingStage loadingText=" " />
      ) : data?.data?.length ? (
        <AbraTable
          columns={columns}
          data={rows}
          showPagination={false}
          isLoading={isLoading}
          isSorting={isFetching}
          activePageNumber={page}
          recordsPerPage={recordsPerPage}
          totalRecords={data?.data?.length}
          onPaginationChange={handlePaginationChange}
          onAscendingSort={handleAscendingSort}
          onDescendingSort={handleDescendingSort}
          onResetSort={handleResetSort}
          showPaginationCaption={false}
          expandTable
        />
      ) : (
        <EmptyStage />
      )}
    </PanelWithHeader>
  )
}

const EmptyStage = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Icon name="EmptySchedule" size={150} />
      <div style={{ marginBottom: "8px" }}>
        <Heading variant="h3" color={palette.gray.main}>
          No payments in schedule
        </Heading>
      </div>
    </div>
  </div>
)

const ColumnWrapp = styled.div`
  display: flex;
  flex-direction: column;
`
export default CompoundWidget
