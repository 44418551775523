import { useEffect, useState } from 'react';
import { useGetCompletedTransactionsQuery, WithdrawRequest } from 'state/store/withdrawApi';

const useWithdrawRequests = () => {
  const [addressId, setAddressId] = useState<number>(0);
  const [withdrawRequests, setWithdrawRequests] = useState<WithdrawRequest[]>([]);
  const getCompletedTransactions = useGetCompletedTransactionsQuery(addressId, { skip: !addressId, refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (getCompletedTransactions.data) {
      const data = getCompletedTransactions.data.withdrawRequests;
      setWithdrawRequests(data);
    } else {
      setWithdrawRequests([]);
    }
  }, [getCompletedTransactions]);

  return {
    addressId,
    setAddressId,
    withdrawRequests,
    loading: getCompletedTransactions.isLoading || getCompletedTransactions.isFetching,
  };
};

export default useWithdrawRequests;
