import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Key: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = "white",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <g clipPath="url(#clip0_994_25430)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7071 2.70711C23.0976 2.31658 23.0976 1.68342 22.7071 1.29289C22.3166 0.902369 21.6834 0.902369 21.2929 1.29289L8.61752 13.9683C7.73148 13.3576 6.6575 13 5.5 13C2.46243 13 0 15.4624 0 18.5C0 21.5376 2.46243 24 5.5 24C8.53757 24 11 21.5376 11 18.5C11 17.3425 10.6424 16.2685 10.0317 15.3825L17.5 7.91421L19.2929 9.70711C19.6834 10.0976 20.3166 10.0976 20.7071 9.70711C21.0976 9.31658 21.0976 8.68342 20.7071 8.29289L18.9142 6.5L20.5 4.91421L22.2929 6.70711C22.6834 7.09763 23.3166 7.09763 23.7071 6.70711C24.0976 6.31658 24.0976 5.68342 23.7071 5.29289L21.9142 3.5L22.7071 2.70711ZM2 18.5C2 16.567 3.567 15 5.5 15C7.433 15 9 16.567 9 18.5C9 20.433 7.433 22 5.5 22C3.567 22 2 20.433 2 18.5Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_994_25430">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Key;
