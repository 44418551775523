import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const SearchBig: FC<ISVG> = ({
  size = 180,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 180 180'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
    <path
      d='M144.139 145.149L109.967 110.977C118.381 101.524 122.781 89.1645 122.231 76.5214C121.681 63.8783 116.224 51.9476 107.019 43.263C97.8156 34.5778 85.5875 29.8216 72.934 30.0051C60.2798 30.1889 48.1949 35.2966 39.2454 44.2451C30.2969 53.1935 25.189 65.2785 25.0051 77.9333C24.8214 90.5863 29.5776 102.814 38.2632 112.018C46.9485 121.223 58.8796 126.679 71.5221 127.229C84.1654 127.779 96.5255 123.38 105.979 114.966L140.15 149.137C140.667 149.687 141.389 150 142.144 150C142.898 150 143.62 149.687 144.137 149.137C144.687 148.621 145 147.898 145 147.144C145 146.389 144.687 145.667 144.137 145.15L144.139 145.149ZM73.802 121.513C62.4738 121.513 51.6095 117.013 43.5992 109.002C35.5882 100.992 31.0882 90.1284 31.0882 78.8C31.0882 67.4719 35.5882 56.6078 43.5992 48.5977C51.6103 40.5875 62.4745 36.0868 73.802 36.0868C85.1313 36.0868 95.9956 40.5867 104.005 48.5977C112.016 56.6086 116.516 67.4727 116.516 78.8C116.516 86.2983 114.542 93.6637 110.793 100.157C107.044 106.65 101.652 112.042 95.1586 115.791C88.6652 119.54 81.2997 121.514 73.8013 121.514L73.802 121.513Z'
      fill={color}
    />
  </svg>
);

export default SearchBig;