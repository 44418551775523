import React from "react";
import { TypesNamesEnum } from "enums/Button.enum";
import { SizeNamesEnum } from "@types/enums/Button.enum";
import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import { Text, Heading } from "components/atoms/Typography";
import { getText } from "shared/locale-helper";

const EmptyStage = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "96px",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "24px",
        width: "248px",
        textAlign: "center",
      }}
    >
      <Icon
        name="EmptyTransaction"
        size={150}
      />
      <div style={{ marginBottom: "8px", marginTop: "16px" }}>
        <Heading variant="h2">{getText("no_transactions_yet")}</Heading>
      </div>
      <Text opacity={0.7}>{getText("buy_your_first_crypto_to_get_things_rolling")}</Text>
    </div>

    <div style={{ marginBottom: "87px" }}>
      <Button
        dataCyPrefix="transactions"
        buttonType={TypesNamesEnum.SECONDAY}
        size={SizeNamesEnum.MEDIUM}
        label={getText("explore_market")}
      ></Button>
    </div>
  </div>
);

export default EmptyStage;
