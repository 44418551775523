import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const ZoomIn: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.75 43.7513L34.4042 34.3888M21.875 14.5846V21.8763M21.875 21.8763V29.168M21.875 21.8763H29.1667M21.875 21.8763H14.5834M39.5834 21.8763C39.5834 26.5728 37.7177 31.077 34.3967 34.398C31.0757 37.7189 26.5716 39.5846 21.875 39.5846C17.1785 39.5846 12.6743 37.7189 9.35334 34.398C6.03238 31.077 4.16669 26.5728 4.16669 21.8763C4.16669 17.1798 6.03238 12.6756 9.35334 9.35462C12.6743 6.03366 17.1785 4.16797 21.875 4.16797C26.5716 4.16797 31.0757 6.03366 34.3967 9.35462C37.7177 12.6756 39.5834 17.1798 39.5834 21.8763Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);

export default ZoomIn;
