import React, { FC } from "react";
import styled, { css } from "styled-components";

import {
  StyledRow,
  StyledCell,
  StyledCellAdditionalInfo,
  IconCellContainer,
  StyledCellAux,
  CellWrapper,
} from "components/organisms/AbraTable/table";
import Checkbox from "components/atoms/Checkbox";
import { Text } from "components/atoms/Typography";
import Image from "components/atoms/Image";
import { palette } from "lib/theme";
import { isFunction } from "lib/utils/helpers";
import { ITableRow } from "components/organisms/AbraTable/interfaces";
import classNames from "classnames";

const Rows: FC<ITableRow> = ({
  columns,
  data,
  enableSelect = false,
  onRowClick,
  onRowSelection,
  rowClassName,
  rowHeight,
  rowSidePadding,
  index,
  disableBoxShadow,
  notHideOnOverflow = false,
}) => {
  return (
    <StyledRow
      className={classNames({
        [rowClassName]: rowClassName,
      })}
      onClick={onRowClick}
      height={rowHeight}
      diableBoxShadow={disableBoxShadow}
    >
      <StyledCellAux width={rowSidePadding ? rowSidePadding : "24px"} />
      {enableSelect && (
        <StyledCell key="table-cell-sort">
          <Checkbox checked={data.isSelected} onChange={onRowSelection} />
        </StyledCell>
      )}
      {columns.map(({ accessor, width, wrapText, hideOnBreakpoints, sizes }, indx) => (
        <StyledCell
          key={`table-cell-${indx}`}
          hideOnBreakpoints={hideOnBreakpoints}
          notHideOnOverflow={notHideOnOverflow}
          className={classNames({
            [data[accessor]?.cellClassName]: data[accessor]?.cellClassName,
          })}
          width={width}
          wrapText={wrapText}
        >
          <CellWrapper sizes={sizes} style={{
              whiteSpace: "nowrap",
              overflow: notHideOnOverflow ? "none" : "hidden",
              textOverflow: "ellipsis",
            }}
            data-cy={`table_cell_${accessor}_${index}`}>
            {data[accessor]?.icon && (
              <IconCellContainer>
                <Image circle src={data[accessor].icon} />
              </IconCellContainer>
            )}

            {(isFunction(data[accessor]) && data[accessor]()) ||
              data[accessor]?.value ||
              data[accessor]}

            {data[accessor]?.additionalInfo && (
              <StyledCellAdditionalInfo>
                <Text size={12} color={palette.darkBackgroundContrast.light70}>
                  {data[accessor].additionalInfo}
                </Text>
              </StyledCellAdditionalInfo>
            )}
          </CellWrapper>
        </StyledCell>
      ))}
      <StyledCellAux width={rowSidePadding ? rowSidePadding : "24px"} />
    </StyledRow>
  );
};
export default Rows;
