import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Percentage } from 'lib/utils/types';
import getUsDollar from 'shared/dollarFormat';
import { DefiBalanceStatementRow, useGetStatementDefiBalancesQuery } from 'state/store/statementsApi';
import { isNegative } from 'utils/isNegative';
import { palette } from 'lib/theme';
import InkindAssetRenderer from "../../../atoms/asset/InkindAssetRenderer";
import { Container, Table, TableContainer, TableRow, Th, Td, Title } from './sharedStyles';

const CustodyStatementInvestmentSummary = () => {
  const { month, year } = useParams();
  const yearMonth = {year, month: month?.padStart(2, '0')}
  const statementDefiBalance = useGetStatementDefiBalancesQuery(yearMonth)
  
  const data = statementDefiBalance.data ?? []
  return (
    <Container>
      <Title>Investment Summary</Title>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <Th>Strategy Name</Th>
              <Th>Income (YTD)</Th>
              <Th>Cost Basis</Th>
              <Th>Unrealized G/L*</Th>
              <Th>Realized G/L (YTD)</Th>
              <Th>Beginning Balance</Th>
              <Th>Ending Balance</Th>
            </TableRow>
          </thead>
          <tbody>
            {data.map((record, index) => (
              <CustodySummaryRow key={index} record={record} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const CustodySummaryRow = ({record}: {record: DefiBalanceStatementRow}) => {
  return (
    <TableRow>
      <Td>{record.strategyName}</Td>
      <Td>
        <InkindAssetRenderer
          balance={record.yieldYTD} 
          value={record.incomeYTD}
        />
      </Td>
      <Td>{getUsDollar(record.costBasis?.amount ?? '0')}</Td>
      <Td>
        <GainLossArea isNegative={isNegative(record.unrealizedAmount?.amount)}>
          {getUsDollar(record.unrealizedAmount?.amount ?? '0')}
          <SubText isNegative={isNegative(record.unrealizedAmount?.amount)}>
            ({new Percentage(record.unrealizedPercentage).print()})
          </SubText> 
        </GainLossArea>
      </Td>
      <Td>
        <GainLossArea isNegative={isNegative(record.realizedYTD?.amount)}>
          {getUsDollar(record.realizedYTD?.amount ?? '0')} 
        </GainLossArea>
      </Td>
      <Td>
        <InkindAssetRenderer
          balance={record.startingBalance}
          value={record.startingValue}
        />
      </Td>
      <Td>
        <InkindAssetRenderer
          balance={record.endingBalance}
          value={record.endingValue}
        />
      </Td>
    </TableRow>
  );
};

const GainLossArea = styled.div<{isNegative: boolean}>`
  color: ${({isNegative}) => isNegative ? palette.red.main : palette.white.light80};
  display: flex;
  flex-direction: column;
`;

const SubText = styled.div<{isNegative: boolean}>`
  color: ${({isNegative}) => isNegative ? palette.red.main : 'rgba(255, 255, 255, 0.6)'};
  font-size: 12px;
`;

export default CustodyStatementInvestmentSummary;