import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const EmptyTransaction: FC<ISVG> = ({
  size = 150,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M44.4482 52.3849C51.3732 43.0449 62.4803 36.991 75.0013 36.991C95.9933 36.991 113.011 54.0089 113.011 75.0009"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M105.555 97.613C98.6302 106.955 87.5222 113.01 75.0002 113.01C54.0082 113.01 36.9902 95.992 36.9902 75"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.5811 33.915C55.4531 28.739 64.8751 25.728 75.0001 25.728C79.1251 25.728 83.1321 26.228 86.9661 27.17"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.965 111.859C50.004 110.085 47.275 107.965 44.833 105.554"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.3622 42.6199C41.5972 28.8209 57.5912 20.689 74.9512 20.689C103.162 20.689 126.437 42.096 129.02 69.879L132.946 73.291C133.119 72.978 133.215 72.619 133.197 72.236C131.742 41.088 106.158 16.689 74.9503 16.689C55.0193 16.689 36.7552 26.6609 25.9482 43.3929L31.3612 38.6229V42.6199H31.3622Z"
      fill={color}
    />
    <path
      d="M118.639 111.373V107.259C108.406 121.132 92.3558 129.31 74.9508 129.31C46.7008 129.31 23.4308 107.627 20.8818 80.032L16.6748 76.376C17.4108 107.893 43.2598 133.31 74.9508 133.31C94.7948 133.31 113.002 123.413 123.825 106.802L118.639 111.373Z"
      fill={color}
    />
    <path
      d="M105.267 52.476L31.362 52.366V38.624L2.5 64.058L31.838 89.553L32.17 74.999L117.83 74.998L118.163 60.444L147.5 85.939L118.639 111.373V97.632L44.733 97.524"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EmptyTransaction;
