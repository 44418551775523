import bigDecimal from "js-big-decimal";

export class Percentage {
    private value: bigDecimal;

    constructor(value: string) {
        this.value = new bigDecimal(value);
    }

    public print(): string {
        const percentage = this.value.multiply(new bigDecimal('100'));
        return `${percentage.round(2).getValue()}%`;
    }
}
