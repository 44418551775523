import React, { FC, useEffect, useState } from 'react';
import { Transaction } from 'services/models';
import Currency from 'components/atoms/Number/Currency';
import { Text } from 'components/atoms/Typography';
import AbraTable from 'components/organisms/AbraTable';
import EmptyStage from './EmptyStage';
import { BreakpointsEnum, BreakpointsTableEnum } from '@types/enums/GridSystem';
import { PaginationTypeEnum } from '@types/enums/Pagination.enum';
import { TableSortDirection } from '@types/enums/Table.enum';
import { Column, ITableDefaultProps } from 'components/organisms/AbraTable/interfaces';
import { DefaultSettings, API_URL_GET_TRANSACTIONS } from 'lib/constants/config.constant';
import { breakpoints, palette } from 'lib/theme';
import { capitalize, formatNumberDisplay, fullDateformatter } from 'shared/helper';
import { mediaQueryMatches } from 'shared/mediaQueryDetector';
import { TypeReferenceTxrCompleted } from 'shared/transactionTypeReference';
import useWindowSize from 'shared/useWindowSize';
import { useGetTransactionsQuery, useGetAssetsQuery } from 'state/store/api';
import { IconBackground } from './styles';
import { usePrefetchImmediately } from 'hooks/usePrefetchImmediately';
import LoadingScreen from './LoadingScreen';
import { useParams } from 'react-router-dom';
import getAssetPrice from 'shared/getAssetPrice';
import { useSelector } from 'react-redux';
import { pricesSelector } from 'state/slice/prices.slice';
interface ICompleted {
  onClick: (trx: Transaction) => Transaction;
}
const Completed: FC<ICompleted> = ({ onClick }) => {
  const defaultSortColumn = 'date';

  const [page, setPage] = useState(1);
  const [isPaginating, setIsPaginating] = useState(false);
  const [start, setStart] = useState(1);
  const [sort, setSort] = useState(defaultSortColumn);
  const [sortDirection, setSortDirection] = useState(TableSortDirection.DESCENDING);

  const recordsPerPage = DefaultSettings.RECORDS_PER_PAGE_FULL_SCREEN;
  const { type } = useParams();

  const {
    data,
    isLoading: loading,
    isFetching,
  } = useGetTransactionsQuery(
    {
      type,
      sortField: sort,
      sortDirection,
      page: start.toString(),
      size: recordsPerPage.toString(),
    },
    { pollingInterval: 30000 }
  );

  for (let index = 1; index < 4; index++) {
    usePrefetchImmediately(API_URL_GET_TRANSACTIONS, {
      type,
      sortField: sort,
      sortDirection,
      page: index.toString(),
      size: recordsPerPage.toString(),
    });
  }

  const [tableColumns, setTableColumns] = useState<Column[]>([
    {
      title: (
        <Text size='small' color={palette.darkBackgroundContrast.light70}>
          Transaction Type / Date
        </Text>
      ),
      enableSort: true,
      accessor: 'transactionType',
      sizes: {
        [BreakpointsTableEnum['768to768']]: '284px',
        [BreakpointsTableEnum['769to1078']]: '260px',
        [BreakpointsTableEnum['1079to1079']]: '298px',
        [BreakpointsTableEnum['1080to1278']]: '278px',
        [BreakpointsTableEnum['1279to1279']]: '348px',
        [BreakpointsTableEnum['1280to1438']]: '348px',
        [BreakpointsTableEnum['1439to1439']]: '377px',
        [BreakpointsTableEnum['1440to1920']]: '319px',
      },
    },
    {
      title: (
        <Text size='small' color={palette.darkBackgroundContrast.light70}>
          Transaction ID
        </Text>
      ),
      enableSort: true,
      accessor: 'operationId',
      sizes: {
        [BreakpointsTableEnum['768to768']]: '163px',
        [BreakpointsTableEnum['769to1078']]: '140px',
        [BreakpointsTableEnum['1079to1079']]: '175px',
        [BreakpointsTableEnum['1080to1278']]: '175px',
        [BreakpointsTableEnum['1279to1279']]: '225px',
        [BreakpointsTableEnum['1280to1438']]: '225px',
        [BreakpointsTableEnum['1439to1439']]: '265px',
        [BreakpointsTableEnum['1440to1920']]: '184px',
      },
    },
    {
      title: (
        <Text size='small' color={palette.darkBackgroundContrast.light70}>
          Amount
        </Text>
      ),
      enableSort: true,
      accessor: 'transactionAmount',
      sizes: {
        [BreakpointsTableEnum['768to768']]: '233px',
        [BreakpointsTableEnum['769to1078']]: '193px',
        [BreakpointsTableEnum['1079to1079']]: '197px',
        [BreakpointsTableEnum['1080to1278']]: '197px',
        [BreakpointsTableEnum['1279to1279']]: '242px',
        [BreakpointsTableEnum['1280to1438']]: '242px',
        [BreakpointsTableEnum['1439to1439']]: '290px',
        [BreakpointsTableEnum['1440to1920']]: '274px',
      },
    },
    {
      title: (
        <Text size='small' color={palette.darkBackgroundContrast.light70}>
          Order ID
        </Text>
      ),
      enableSort: true,
      accessor: 'orderId',
      sizes: {
        [BreakpointsTableEnum['768to768']]: '163px',
        [BreakpointsTableEnum['769to1078']]: '140px',
        [BreakpointsTableEnum['1079to1079']]: '175px',
        [BreakpointsTableEnum['1080to1278']]: '175px',
        [BreakpointsTableEnum['1279to1279']]: '225px',
        [BreakpointsTableEnum['1280to1438']]: '225px',
        [BreakpointsTableEnum['1439to1439']]: '265px',
        [BreakpointsTableEnum['1440to1920']]: '184px',
      },
    },
  ]);

  useEffect(() => {
    if (isPaginating && !isFetching) setIsPaginating(false);
  }, [isFetching]);

  const handlePaginationChange = (currentPage: number) => {
    setIsPaginating(true);
    setStart(currentPage);
    setPage(currentPage);
  };

  const handleAscendingSort = (key: string, data: any, setTableData: any) => {
    setIsPaginating(false);
    setSort(key === 'transactions' ? defaultSortColumn : key);
    setSortDirection(TableSortDirection.ASCENDING);
    setTableColumns(
      tableColumns.map((column) => ({
        ...column,
        sortDirection: column.accessor === key ? TableSortDirection.ASCENDING : TableSortDirection.UNSORTED,
      }))
    );
    setStart(1);
    setPage(1);
  };

  const handleDescendingSort = (key: string, data: any, setTableData: any) => {
    setIsPaginating(false);
    setSort(key === 'transactions' ? defaultSortColumn : key);
    setSortDirection(TableSortDirection.DESCENDING);
    setTableColumns(
      tableColumns.map((column) => ({
        ...column,
        sortDirection: column.accessor === key ? TableSortDirection.DESCENDING : TableSortDirection.UNSORTED,
      }))
    );
    setStart(1);
    setPage(1);
  };

  const handleResetSort = (key: string, data: any, setTableData: any) => {
    setIsPaginating(false);
    setSort(defaultSortColumn);
    setSortDirection(TableSortDirection.DESCENDING);
    setTableColumns(
      tableColumns.map((column) => ({
        ...column,
        sortDirection: TableSortDirection.UNSORTED,
      }))
    );
    setStart(1);
    setPage(1);
  };

  const handleOnRowClick = (event: any, data: any): void => {
    onClick && onClick(data);
  };

  return (loading && isFetching) || (isFetching && isPaginating) ? (
    <LoadingScreen />
  ) : data?.transactions?.length ? (
    <CompletedTable
      columns={tableColumns}
      data={data}
      activePageNumber={page}
      recordsPerPage={recordsPerPage}
      onPaginationChange={handlePaginationChange}
      onAscendingSort={handleAscendingSort}
      onDescendingSort={handleDescendingSort}
      onResetSort={handleResetSort}
      showPaginationCaption={true}
      onRowClick={handleOnRowClick}
      isSorting={Boolean(loading || isFetching)}
    />
  ) : (
    <EmptyStage />
  );
};

const CompletedTable: FC<ITableDefaultProps> = ({
  columns,
  data,
  activePageNumber,
  onPaginationChange,
  recordsPerPage,
  onAscendingSort,
  onDescendingSort,
  onResetSort,
  onRowClick,
  showPaginationCaption,
  isSorting,
}) => {
  const prices = useSelector(pricesSelector);

  const rows = data?.transactions?.map((m) => {
    const { transactionType, date, transactionAmount, initialAmount, currency, orderId, operationId } = m;
    const { data: assets } = useGetAssetsQuery();

    const getAssetFromIdentifier = (identifier?: string) => {
      return assets?.assets?.find((asset) => asset.identifier === identifier);
    };
    const typeReference = TypeReferenceTxrCompleted[transactionType];
    const dateFormatted = date && fullDateformatter(new Date(date));
    const label = typeReference?.getReference(m) ?? transactionType;

    const assetPrice = prices.find((as) => as.identifier === currency);

    const price = (assetPrice?.price || 1) * transactionAmount;
    const closingPrincipal = formatNumberDisplay(parseFloat(transactionAmount) + parseFloat(initialAmount), 'decimal', 'auto', 8, 8);

    return {
      schema: { ...m, price, label, closingPrincipal },
      transactionType: () => (
        <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
          <IconBackground icon={typeReference?.icon ?? null} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
            <Text>{label}</Text>
            <Text color={palette.darkBackgroundContrast.light70}>{dateFormatted}</Text>
          </div>
        </div>
      ),
      transactionAmount: () => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text>
            {getAssetPrice(getAssetFromIdentifier(currency.toUpperCase()), transactionAmount)} <Text>{currency.toUpperCase()}</Text>
          </Text>
          <div>
            <Text size='tiny' opacity={0.7} color={palette.darkBackgroundContrast.light70}>
              <Currency value={price} />
            </Text>
          </div>
        </div>
      ),
      orderId: () => (
        <div>
          <Text color={palette.darkBackgroundContrast.light70}>{orderId}</Text>
        </div>
      ),
      operationId: () => (
        <div>
          <Text color={palette.darkBackgroundContrast.light70}>{operationId}</Text>
        </div>
      ),
    };
  });

  return (
    <AbraTable
      columns={columns}
      data={rows}
      fullScreenTable={true}
      showPagination={true}
      totalRecords={data?.paginationResponse?.totalRecords}
      onAscendingSort={onAscendingSort}
      onDescendingSort={onDescendingSort}
      onResetSort={onResetSort}
      onRowClick={onRowClick}
      activePageNumber={activePageNumber}
      recordsPerPage={recordsPerPage}
      onPaginationChange={onPaginationChange}
      showPaginationCaption={showPaginationCaption}
      paginationVariant={PaginationTypeEnum.NUMBERS}
      isSorting={isSorting}
    />
  );
};

export default Completed;
