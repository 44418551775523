import React, { FC, useEffect, useState } from 'react';

import { Sizes } from './Boost';
import { Heading, Text } from 'components/atoms/Typography';
import PanelWithHeader from 'components/molecules/PanelWithHeader';
import Currency from 'components/atoms/Number/Currency';
import { Number } from 'components/atoms/Number';
import AbraTable from 'components/organisms/AbraTable';
import Percent from 'components/atoms/Number/Percent';
import Tabs from 'components/molecules/Tabs';
import { OrderDto, PricesDto, useGetOrdersQuery } from 'state/store/api';
import Icon from 'components/atoms/Icon';
import { palette } from 'lib/theme';
import { Column } from 'components/organisms/AbraTable/interfaces';
import { DefaultSettings, PagesUrl } from 'lib/constants/config.constant';
import { TableSortDirection } from '@types/enums/Table.enum';
import LoadingStage from 'pages/LoadingStage';
import { capitalize } from 'shared';
import { useNavigate } from 'react-router-dom';
import { OrderTypeReference } from 'shared/transactionTypeReference';
import useAssetFormatHook from 'shared/useAssetFormatHook';
import { useSelector } from 'react-redux';
import { pricesSelector } from 'state/slice/prices.slice';
import Button from 'components/atoms/Button';
import usePermissions from 'shared/usePermissions';
import WithdrawButton from 'pages/Withdraw/WithdrawButton';

interface DepositDetailsItem {
  id: number;
  depositId?: string;
  openDate?: string;
  initialPrincipal?: number;
  initialPrincipalPrice?: number;
  assetName?: string;
  apr?: number;
  term?: string;
  type?: string;
  duration?: string;
  maturity?: string;
  interestAccrued?: number;
  interestAccruedPrice?: number;
  closedDate?: string;
  currentPrincipal?: number;
  currentPrincipalPrice?: number;
}
interface DepositDetailsProps extends Sizes {}

const DepositDetails: FC<DepositDetailsProps> = (props) => {
  const prices = useSelector(pricesSelector);

  return (
    <PanelWithHeader width='100%' height='668px' title='Deposit details' {...props}>
      <Tabs activeTabName='Active' width='633px' isTableParent>
        <div label='Active'>
          <DepositDetailsTable prices={prices ?? []} status={['accrual', 'reviewing', 'closing', 'ongoing']} />
        </div>
        <div label='Closed'>
          <DepositDetailsTable prices={prices ?? []} status={['expired', 'settled', 'off', 'cancelled', 'closed']} closeDetails />
        </div>
      </Tabs>
    </PanelWithHeader>
  );
};

const DepositDetailsTable: FC<{
  prices: PricesDto[];
  status: string[];
  closeDetails?: boolean;
}> = ({ prices, status, closeDetails = false }) => {
  const navigate = useNavigate();
  const [items, setItems] = useState<DepositDetailsItem[]>([]);

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('startDate');
  const [sortDirection, setSortDirection] = useState(TableSortDirection.DESCENDING);
  const recordsPerPage = DefaultSettings.RECORDS_PER_PAGE;
  const assetFormat = useAssetFormatHook();

  const { data, isLoading, isFetching } = useGetOrdersQuery({
    type: 'deposit',
    sortField: sort,
    sortDirection: sortDirection,
    page: page.toString(),
    size: recordsPerPage.toString(),
    status: status,
  });

  useEffect(() => {
    if (!data && !prices) return;

    const response = !data?.orders
      ? []
      : data.orders.map((item: OrderDto) => {
          const price = prices?.find((m) => m.identifier === item.currency)?.price;

          return {
            id: item.id,
            depositId: item.id1Token,
            openDate: item.startDate,
            assetName: item.currency?.toUpperCase(),
            initialPrincipal: assetFormat.getAssetFormatted(item.currency, item.initialPrincipal),
            initialPrincipalPrice: parseFloat(item.initialPrincipal ?? '0') * parseFloat(price ?? '0'),
            apr: parseFloat(item.interestRate ?? '0'),
            term: capitalize(item?.termType ?? '', true),
            type: item?.repaymentMethod ? capitalize(item.repaymentMethod.replaceAll('-', ' '), true) : '',
            duration: `${item.days} ${parseFloat(item?.days) > 1 ? 'days' : 'day'}`,
            maturity: item.maturity,
            interestAccrued: assetFormat.getAssetFormatted(item.currency, item.interestAccrued ?? '0'),
            interestAccruedPrice: parseFloat(item.interestAccrued ?? '0') * parseFloat(price ?? '0'),
            closedDate: item.closeDate,
            currentPrincipal: assetFormat.getAssetFormatted(item.currency, item.balance ?? '0'),
            currentPrincipalPrice: parseFloat(item.balance ?? '0') * parseFloat(price ?? '0'),
          } as DepositDetailsItem;
        });
    setItems(response);
  }, [data, prices, assetFormat.isLoading]);

  const [columns, setColumns] = useState<Column[]>(
    closeDetails
      ? [
          {
            title: 'Deposit id / Open date',
            enableSort: true,
            accessor: 'orderId',
          },
          {
            title: 'Current balance',
            enableSort: true,
            accessor: 'currentPrincipal',
          },
          {
            title: 'Initial amount',
            enableSort: true,
            accessor: 'initialPrincipal',
          },
          {
            title: 'APR',
            enableSort: true,
            accessor: 'interestRate',
          },
          {
            title: 'Term',
            enableSort: true,
            accessor: 'termType',
          },
        ]
      : [
          {
            title: 'Deposit id / Open date',
            enableSort: true,
            accessor: 'orderId',
          },
          {
            title: 'Current balance',
            enableSort: true,
            accessor: 'currentPrincipal',
          },
          {
            title: 'Initial amount',
            enableSort: true,
            accessor: 'initialPrincipal',
          },
          {
            title: 'APR',
            enableSort: true,
            accessor: 'interestRate',
          },
          {
            title: 'Term',
            enableSort: true,
            accessor: 'termType',
          },
          {
            title: '',
            enableSort: false,
            accessor: 'actions',
          },
        ]
  );

  const rows = items.map((item) => {
    const { depositId, initialPrincipal, initialPrincipalPrice, openDate, assetName, apr, term, type, currentPrincipal, currentPrincipalPrice } = item;
    return {
      schema: item,
      orderId: () => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <div>{depositId}</div>
          <Text size='tiny' opacity={0.7}>
            {openDate}
          </Text>
        </div>
      ),
      currentPrincipal: () => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            {currentPrincipal} <Text>{assetName}</Text>
          </div>
          <Text size='tiny' opacity={0.7}>
            <Currency value={currentPrincipalPrice} />
          </Text>
        </div>
      ),
      initialPrincipal: () => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            {initialPrincipal} <Text>{assetName}</Text>
          </div>
          <Text size='tiny' opacity={0.7}>
            <Currency value={initialPrincipalPrice} />
          </Text>
        </div>
      ),
      interestRate: () => (
        <Text>
          <Number value={apr} /> %
        </Text>
      ),
      termType: () => <Text>{term}</Text>,
      orderType: () => <Text>{type}</Text>,
      actions: () => <WithdrawButton id={item.id} />,
    };
  });

  const handlePaginationChange = (currentPage: number) => {
    setPage(currentPage);
  };

  const handleAscendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key === 'transactions' ? 'startDate' : key);
    setSortDirection(TableSortDirection.ASCENDING);
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: column.accessor === key ? TableSortDirection.ASCENDING : TableSortDirection.UNSORTED,
      }))
    );
    setPage(1);
  };

  const handleDescendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key === 'transactions' ? 'startDate' : key);
    setSortDirection(TableSortDirection.DESCENDING);
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: column.accessor === key ? TableSortDirection.DESCENDING : TableSortDirection.UNSORTED,
      }))
    );
    setPage(1);
  };

  const handleResetSort = (key: string, data: any, setTableData: any) => {
    setSort('startDate');
    setSortDirection(TableSortDirection.DESCENDING);
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: TableSortDirection.UNSORTED,
      }))
    );
    setPage(1);
  };

  const handleOnRowClick = (event: any, data: DepositDetailsItem): void => {
    navigate(`${PagesUrl.BOOST}/${data.id}${PagesUrl.DEPOSIT_DETAILS}`);
  };

  return isLoading ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <LoadingStage loadingText=' ' />
    </div>
  ) : data?.orders?.length ? (
    <AbraTable
      columns={columns}
      data={rows}
      showPagination={true}
      showPaginationCaption={false}
      isLoading={isLoading}
      isSorting={isFetching}
      recordsPerPage={recordsPerPage}
      totalRecords={data?.paginationResponse?.totalRecords}
      onPaginationChange={handlePaginationChange}
      onAscendingSort={handleAscendingSort}
      onDescendingSort={handleDescendingSort}
      onResetSort={handleResetSort}
      onRowClick={handleOnRowClick}
    />
  ) : (
    <EmptyStage />
  );
};

const EmptyStage = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Icon name='NoTransactions' size={150} />
      <div style={{ marginBottom: '8px' }}>
        <Heading variant='h3' color={palette.gray.main as string}>
          No details yet
        </Heading>
      </div>
    </div>
  </div>
);

export default DepositDetails;
