import Icon from "components/atoms/Icon";
import { palette } from "lib/theme";
import React, { FC } from "react";

import { Heading, Text } from "../../atoms/Typography";
import Modal from "../../organisms/Modal";

interface SignInBlockedModalProps {
  visible: boolean;
  loading: boolean;
  title: string;
  details: string;
  icon?: string;
  confirmText?: string;
  onConfirm?: () => void;
  cancelText?: string;
  onCancel?: () => void;
  onClose?: () => void;
}

const SignInBlockedModal: FC<SignInBlockedModalProps> = ({
  visible,
  title,
  details,
  icon = "SignInBlocked",
  onClose,
  confirmText,
  loading,
  onConfirm,
  cancelText,
  onCancel,
}) => {
  return (
    <Modal visible={visible} onClose={onClose}>
      <Modal.Body
        confirmText={confirmText}
        loading={loading}
        onConfirm={onConfirm}
        onCancel={onCancel}
        cancelText={cancelText}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <div style={{ paddingBottom: "10px" }}>
            <Icon name={icon} size={90} />
          </div>
          <Heading variant="h3">{title}</Heading>
          <Text
            align="center"
            letterSpacing="-0.5"
            color={palette.secondary.secondaryFont}
          >
            {details}
          </Text>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SignInBlockedModal;
