import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const IdVerification: FC<ISVG> = ({
  size = 40,
  width = size,
  height = size,
  color,
  svgProps,
}) => {
  const viewBox = `0 0 ${size} ${size}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.7335 4.02045H20.2014C19.8543 4.02045 19.5728 4.30259 19.5728 4.65056V7.17103H15.801V4.65056C15.801 4.48347 15.7347 4.32313 15.6169 4.20498C15.499 4.08684 15.339 4.02045 15.1723 4.02045H9.64029C8.18177 4.02045 7 5.43205 7 7.17103V32.3757C7 34.1146 8.18177 35.5262 9.64029 35.5262H22.6154C22.4992 35.2649 22.4027 34.9954 22.3261 34.7198C22.2803 34.5712 22.2444 34.4196 22.2192 34.266H9.64635C8.88581 34.266 8.25717 33.4154 8.25717 32.3757V7.17104C8.25717 6.13131 8.88581 5.28069 9.64017 5.28069H14.5436V7.80116C14.5436 7.96825 14.6098 8.12859 14.7277 8.24674C14.8455 8.36488 15.0055 8.43127 15.1722 8.43127H20.2013C20.368 8.43127 20.528 8.36488 20.6459 8.24674C20.7637 8.12859 20.83 7.96825 20.83 7.80116V5.28069H25.7334C26.4877 5.28069 27.1164 6.13131 27.1164 7.17104V26.8307C27.53 26.7465 27.9513 26.7041 28.3737 26.7046V7.17104C28.3737 5.43206 27.1919 4.02046 25.7334 4.02046L25.7335 4.02045Z"
        fill={color}
      />
      <path
        d="M20.2015 8.43127H15.1723C14.8252 8.43127 14.5437 8.14912 14.5437 7.80115V2.13012C14.5437 1.78215 14.8252 1.5 15.1723 1.5H20.2015C20.3682 1.5 20.5281 1.56639 20.646 1.68453C20.7639 1.80268 20.8301 1.96302 20.8301 2.13012V7.80115C20.8301 7.96825 20.7639 8.12859 20.646 8.24674C20.5281 8.36488 20.3682 8.43127 20.2015 8.43127ZM15.801 7.17104H19.5728V2.76023H15.801V7.17104Z"
        fill={color}
      />
      <path
        d="M17.6869 21.0336C16.6864 21.0336 15.7272 20.6353 15.0196 19.9264C14.3124 19.2172 13.915 18.2558 13.915 17.2529C13.915 16.25 14.3124 15.2886 15.0196 14.5794C15.7272 13.8705 16.6864 13.4722 17.6869 13.4722C18.6874 13.4722 19.6466 13.8705 20.3541 14.5794C21.0613 15.2886 21.4587 16.25 21.4587 17.2529C21.4587 18.2558 21.0613 19.2172 20.3541 19.9264C19.6466 20.6353 18.6874 21.0336 17.6869 21.0336ZM17.6869 14.7324C17.0201 14.7324 16.3805 14.998 15.9087 15.4706C15.4372 15.9434 15.1723 16.5845 15.1723 17.2529C15.1723 17.9212 15.4372 18.5624 15.9087 19.0352C16.3805 19.5078 17.0201 19.7734 17.6869 19.7734C18.3537 19.7734 18.9933 19.5078 19.465 19.0352C19.9365 18.5624 20.2014 17.9212 20.2014 17.2529C20.2014 16.5845 19.9365 15.9434 19.465 15.4706C18.9933 14.998 18.3537 14.7324 17.6869 14.7324Z"
        fill={color}
      />
      <path
        d="M20.8301 27.9648H14.5437C14.1965 27.9648 13.915 27.6827 13.915 27.3347V24.8143C13.915 23.9788 14.2462 23.1774 14.8355 22.5863C15.4251 21.9956 16.2247 21.6637 17.0582 21.6637H18.3155C19.1491 21.6637 19.9485 21.9956 20.5382 22.5863C21.1276 23.1773 21.4587 23.9788 21.4587 24.8143V27.3347C21.4587 27.5018 21.3925 27.6622 21.2746 27.7803C21.1568 27.8984 20.9968 27.9648 20.8301 27.9648ZM15.1723 26.7046H20.2014V24.8143C20.2014 24.313 20.0027 23.8319 19.6491 23.4776C19.2955 23.1232 18.8156 22.924 18.3156 22.924H17.0583C16.5582 22.924 16.0782 23.1231 15.7247 23.4776C15.3712 23.832 15.1724 24.3131 15.1724 24.8143L15.1723 26.7046Z"
        fill={color}
      />
      <path
        d="M22.6154 30.4853C22.4353 30.8892 22.3003 31.3118 22.213 31.7455H11.4004C11.0532 31.7455 10.7717 31.4634 10.7717 31.1154C10.7717 30.7674 11.0532 30.4853 11.4004 30.4853H22.6154Z"
        fill={color}
      />
      <path
        d="M28.3737 38.0467C27.2795 38.0455 26.2156 37.6872 25.3433 37.0252C24.4708 36.3633 23.8377 35.4345 23.5394 34.3793C23.4094 33.9332 23.3438 33.4707 23.3446 33.0057C23.3443 31.7399 23.8195 30.5204 24.6752 29.5894C25.5306 28.6585 26.7045 28.0844 27.9632 27.9809C29.2219 27.8777 30.473 28.2526 31.4684 29.0316C32.4638 29.8105 33.1307 30.9362 33.3358 32.1852C33.5413 33.4342 33.2704 34.715 32.577 35.7729C31.8835 36.8309 30.8185 37.5884 29.5935 37.8954C29.195 37.9972 28.785 38.0481 28.3738 38.0467L28.3737 38.0467ZM28.3737 29.2251C27.3732 29.2251 26.414 29.6234 25.7065 30.3323C24.9993 31.0414 24.6019 32.0029 24.6019 33.0057C24.6008 33.3532 24.6493 33.6992 24.7464 34.0328C24.9695 34.8249 25.4447 35.5226 26.0991 36.0196C26.7538 36.5167 27.5525 36.7859 28.3737 36.7864C28.681 36.7873 28.9875 36.7493 29.2853 36.6731C30.4853 36.3715 31.4617 35.4992 31.8978 34.3388C32.3342 33.1785 32.175 31.8774 31.4717 30.8569C30.7684 29.8366 29.6108 29.2267 28.3737 29.225V29.2251Z"
        fill={color}
      />
      <path
        d="M20.8301 10.3216H14.5437C14.0436 10.3216 13.5637 10.1224 13.2101 9.768C12.8566 9.41356 12.6578 8.93251 12.6578 8.43129C12.6578 7.93008 12.8565 7.44896 13.2101 7.09459C13.5638 6.74021 14.0437 6.54099 14.5437 6.54099H15.1724C15.3391 6.54099 15.499 6.60737 15.6169 6.72552C15.7348 6.84366 15.801 7.00401 15.801 7.1711H19.5728C19.5728 6.82313 19.8543 6.54099 20.2015 6.54099H20.8301C21.3302 6.54099 21.8102 6.74014 22.1637 7.09459C22.5173 7.44903 22.716 7.93008 22.716 8.43129C22.716 8.93251 22.5173 9.41362 22.1637 9.768C21.8101 10.1224 21.3302 10.3216 20.8301 10.3216ZM14.5437 7.80114C14.381 7.80339 14.2255 7.86894 14.1098 7.98399C13.9878 8.10073 13.9173 8.26192 13.9151 8.43126C13.9151 8.59835 13.9813 8.75869 14.0992 8.87684C14.2171 8.99498 14.377 9.06137 14.5437 9.06137H20.8301C20.9929 9.05912 21.1484 8.99358 21.264 8.87852C21.3861 8.76178 21.4565 8.6006 21.4588 8.43126C21.4588 8.26417 21.3925 8.10382 21.2747 7.98568C21.1568 7.86753 20.9968 7.80114 20.8301 7.80114C20.8301 7.96823 20.7639 8.12858 20.646 8.24672C20.5282 8.36487 20.3682 8.43126 20.2015 8.43126H15.1724C14.8252 8.43126 14.5437 8.14911 14.5437 7.80114Z"
        fill={color}
      />
      <path
        d="M30.1526 33.8944C30.2716 34.0128 30.3387 34.174 30.3387 34.342C30.3387 34.5099 30.2716 34.6711 30.1526 34.7892C30.0339 34.9071 29.8734 34.9729 29.7064 34.9721C29.5391 34.9729 29.3786 34.9071 29.2599 34.7892L28.3736 33.9009L27.4873 34.7892C27.3686 34.9071 27.2081 34.9729 27.0409 34.9721C26.8739 34.9729 26.7133 34.9071 26.5946 34.7892C26.4756 34.6711 26.4086 34.5099 26.4086 34.342C26.4086 34.174 26.4756 34.0128 26.5946 33.8944L27.4809 33.0061L26.5946 32.1177C26.3479 31.8704 26.3479 31.4699 26.5946 31.2229C26.841 30.9756 27.2407 30.9756 27.4874 31.2229L28.3736 32.1112L29.2599 31.2229C29.5066 30.9756 29.9062 30.9756 30.1527 31.2229C30.3993 31.4698 30.3993 31.8704 30.1527 32.1177L29.2664 33.0061L30.1526 33.8944Z"
        fill={color}
      />
    </svg>
  );
};

export default IdVerification;
