import React, { useEffect } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { PagesUrl } from "lib/constants/config.constant"
import {
  selectCurrentToken,
  selectCurrentUser,
  setCurrentUser,
} from "state/slice/auth.slice"
import { useGetUserInformationQuery } from "state/store/api"

const ProtectedRoutes = () => {
  const dispatch = useDispatch()
  const token = useSelector(selectCurrentToken)

  const { data } = useGetUserInformationQuery()

  useEffect(() => {
    if (data) dispatch(setCurrentUser(data))
  }, [data])

  return token ? <Outlet /> : <Navigate to={PagesUrl.AUTHENTICATION} />
}

export default ProtectedRoutes
