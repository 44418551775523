export enum ToastType {
  Fail,
  Info,
  Success,
  Warning,
}

export interface ToastOptions {
  id?: string;
  type: ToastType;
  title: string;
  content: React.ReactElement | string;
  duration?: number;
  icon?: string;
}

export interface ToastProps extends ToastOptions {
  destroy?: () => void;
}
