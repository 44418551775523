import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Substract: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => {
  return (
    <svg
      width="160"
      height="161"
      viewBox="0 0 160 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.0001 0.5C44.1696 1.33005 15.5482 23.1618 4.67927 53.4519L9.33392 54.6991C19.6384 26.4567 46.3912 6.12679 78.0001 5.30157V0.5ZM150.58 54.4653C140.214 26.3446 113.522 6.12461 82.0001 5.30158V0.500006C115.743 1.32802 144.304 23.0494 155.236 53.2178L150.58 54.4653ZM126.318 139.723C143.9 125.958 155.2 104.536 155.2 80.4755C155.2 72.743 154.033 65.283 151.865 58.2622L156.506 57.0187C158.778 64.4367 160 72.3134 160 80.4755C160 106.163 147.893 129.023 129.075 143.66L126.318 139.723ZM37.124 142.263C49.2865 150.719 64.0644 155.675 80 155.675C96.0347 155.675 110.897 150.657 123.103 142.105L125.856 146.037C112.871 155.136 97.0592 160.475 80 160.475C63.04 160.475 47.3126 155.198 34.3706 146.195L37.124 142.263ZM8.06191 58.4994C5.94097 65.4509 4.8 72.83 4.8 80.4755C4.8 104.636 16.1935 126.135 33.9006 139.893L31.1441 143.829C12.2005 129.199 0 106.262 0 80.4755C0 72.4004 1.19642 64.6047 3.42167 57.256L8.06191 58.4994Z"
        fill="white"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default Substract;
