import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const DefaultHeaderAvatar: FC<ISVG> = ({
  size = 40,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="16" fill="white" fillOpacity="0.1" />
    <path
      d="M20.749 2.72048C24.1398 2.869 27.4119 4.01205 30.158 6.00726C32.9041 8.00248 35.0027 10.7617 36.1926 13.9413L38.7829 13.0984C37.4148 9.372 34.9696 6.13682 31.7583 3.80439C28.547 1.47196 24.7148 0.147682 20.749 0V2.72048Z"
      fill="#FFA519"
    />
    <path
      d="M36.6517 15.3638C37.0695 16.87 37.2825 18.4256 37.2848 19.9886C37.2873 22.5904 36.7015 25.1591 35.5712 27.5023C34.4409 29.8455 32.7954 31.9027 30.7578 33.5197L32.3539 35.7228C34.7387 33.8528 36.6665 31.4642 37.9913 28.738C39.3161 26.0119 40.003 23.0198 39.9999 19.9886C40.0007 18.141 39.7441 16.3022 39.2375 14.5254L36.6517 15.3638Z"
      fill="#FFA519"
    />
    <path
      d="M3.80732 13.9413C4.99722 10.7617 7.09587 8.00248 9.84195 6.00726C12.588 4.01205 15.8602 2.869 19.2509 2.72048V0C15.2855 0.148858 11.4538 1.47359 8.24274 3.80586C5.03167 6.13813 2.5862 9.37266 1.21704 13.0984L3.80732 13.9413Z"
      fill="#D8DFE1"
    />
    <path
      d="M29.5408 34.4028C26.7136 36.2813 23.3939 37.2807 20 37.2749C16.606 37.2807 13.2863 36.2813 10.4592 34.4028L8.85864 36.606C12.1524 38.8239 16.0339 40.0058 20.0044 39.9999C23.9747 40.0042 27.8557 38.8224 31.1502 36.606L29.5408 34.4028Z"
      fill="#D8DFE1"
    />
    <path
      d="M9.24654 33.5197C7.20898 31.9027 5.56346 29.8455 4.43315 27.5023C3.30284 25.1591 2.71704 22.5904 2.71958 19.9886C2.72046 18.4258 2.93191 16.8702 3.3482 15.3638L0.762375 14.5254C0.255769 16.3022 -0.000819497 18.141 6.96151e-06 19.9886C-0.00252391 23.0202 0.685053 26.0125 2.0106 28.7387C3.33615 31.4648 5.26487 33.8533 7.65047 35.7228L9.24654 33.5197Z"
      fill="#D8DFE1"
    />
    <path
      d="M25.7541 22.0002C26.9961 22.0002 28.0029 23.007 28.0029 24.2491V24.8245C28.0029 25.7188 27.6833 26.5836 27.1018 27.263C25.5324 29.0965 23.1453 30.0013 19.9999 30.0013C16.854 30.0013 14.468 29.0962 12.9017 27.2619C12.3219 26.583 12.0034 25.7195 12.0034 24.8267V24.2491C12.0034 23.007 13.0103 22.0002 14.2523 22.0002H25.7541ZM25.7541 23.5002H14.2523C13.8387 23.5002 13.5034 23.8355 13.5034 24.2491V24.8267C13.5034 25.3624 13.6945 25.8805 14.0424 26.2878C15.2957 27.7555 17.2616 28.5013 19.9999 28.5013C22.7382 28.5013 24.7058 27.7555 25.9623 26.2876C26.3112 25.8799 26.5029 25.361 26.5029 24.8245V24.2491C26.5029 23.8355 26.1676 23.5002 25.7541 23.5002ZM19.9999 10.0049C22.7613 10.0049 24.9999 12.2435 24.9999 15.0049C24.9999 17.7663 22.7613 20.0049 19.9999 20.0049C17.2384 20.0049 14.9999 17.7663 14.9999 15.0049C14.9999 12.2435 17.2384 10.0049 19.9999 10.0049ZM19.9999 11.5049C18.0669 11.5049 16.4999 13.0719 16.4999 15.0049C16.4999 16.9379 18.0669 18.5049 19.9999 18.5049C21.9329 18.5049 23.4999 16.9379 23.4999 15.0049C23.4999 13.0719 21.9329 11.5049 19.9999 11.5049Z"
      fill="white"
    />
  </svg>
);

export default DefaultHeaderAvatar;
