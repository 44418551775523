import React, { FC, useEffect, useRef, useState } from 'react';

import Input from 'components/atoms/Input';
import Icon from 'components/atoms/Icon';
import Divider from 'components/atoms/Divider';
import { Text } from 'components/atoms/Typography';

import { SelectItem, SelectProps } from './Select.interface';
import { Container, OptionRow, Options } from './Select.style';
import Highlighted from 'components/atoms/Highlighted';
import useOnClickOutside from 'shared/useOnClickOutside';
import styled from 'styled-components';
import { palette } from 'lib/theme';

const Select: FC<SelectProps> = ({
  label,
  prefix: defaultPrefix,
  onSelect,
  data,
  selectedItem,
  errorMessage = '',
  dimissErrorAlert,
  disabled,
  readOnly = false,
  width,
  smallIcon,
  dataCy = '',
  dataCyPrefix = '',
  additionalText = null,
  val,
  ...props
}) => {
  const [showList, setShowList] = useState(false);
  const [item, setItem] = useState<SelectItem | null | undefined>();
  const [value, setValue] = useState<any>(val);
  const [options, setOptions] = useState<JSX.Element[]>();
  const [prefix, setPrefix] = useState<
    | {
        prefixAction: () => void;
      }
    | undefined
  >({
    prefixAction: defaultPrefix,
  });

  const selectContainerRef = useRef<HTMLDivElement>(null);

  const handleItemSelected = (item: SelectItem) => {
    setItem(item);
    setPrefix({ prefixAction: item.prefix });
    setShowList(false);

    if (onSelect) {
      onSelect(item);
    }
  };

  const clickOutsideHandler = () => setShowList(false);

  useOnClickOutside(selectContainerRef, clickOutsideHandler);

  const filterOptions = (text: string) => {
    setOptions(
      data
        .filter((m) => item || text === undefined || m?.label?.toLowerCase()?.includes(text?.toLowerCase()))
        .map((m, index) => {
          return (
            <React.Fragment key={index}>
              <OptionRow onClick={() => handleItemSelected(m)}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '0.25rem',
                    padding: '0 0.75rem',
                    width: '100%',
                  }}
                >
                  {m['prefix'] ? m.prefix() : null}
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.25rem',
                    }}
                  >
                    <div>
                      <Text onClick={() => handleItemSelected(m)}>
                        <Highlighted text={m.label} highlight={text} />
                      </Text>
                    </div>
                    {m.additionalInfo ? (
                      <div>
                        <Text size='tiny' opacity={0.7} onClick={() => handleItemSelected(m)}>
                          {m.additionalInfo}
                        </Text>
                      </div>
                    ) : null}
                  </div>
                  {m?.id === item?.id && (
                    <div style={{ marginLeft: 'auto' }}>
                      <Icon name={'SelectCheck'} size={24} color={palette.darkBackgroundContrast.white} />
                    </div>
                  )}
                </div>
              </OptionRow>
              {index !== data.length - 1 ? <Divider /> : <></>}
            </React.Fragment>
          );
        })
    );
  };

  useEffect(() => {
    if (selectedItem) {
      setItem(selectedItem);
      setValue(selectedItem.label);
      setPrefix({ prefixAction: selectedItem.prefix });
    }
  }, [selectedItem]);

  useEffect(() => {
    if (item) {
      setValue(item.label);
    }
  }, [item]);

  useEffect(() => {
    filterOptions('');
  }, [data]);

  useEffect(() => {
    filterOptions(value);
  }, [value, data]);
  return (
    <SelectContainer width={width} ref={selectContainerRef}>
      <Input
        {...props}
        dataCy={dataCy}
        dataCyPrefix={dataCyPrefix}
        label={label}
        readOnly={readOnly}
        className={`hasList ${showList ? 'container_focus' : ''} ${readOnly ? 'readonly_select' : ''}`}
        prefix={prefix?.prefixAction}
        additionalText={additionalText}
        suffix={() => (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (!disabled && !readOnly) setShowList((prev) => !prev);
            }}
          >
            <Icon
              name={showList ? 'UpArrowSelect' : 'DownArrowSelect'}
              size={24}
              color={disabled || readOnly ? palette.darkBackgroundContrast.light20 : palette.darkBackgroundContrast.white}
            />
          </div>
        )}
        inputProps={{
          ...props.inputProps,
          disabled,
          className: `hasList ${showList ? 'container_focus' : ''} ${readOnly ? 'readonly_select' : ''}`,
          value: value,
          smallIcon: value && prefix && selectedItem && smallIcon,
          onChange: (e: any) => {
            setValue(e.target.value);
            setItem(undefined);
            setPrefix(undefined);
          },
          onFocus: () => {
            if (!disabled && !readOnly) setShowList(true);
          },
          onBlur: (e: any) => {
            if (!value && onSelect) {
              setValue(e.target.value);
              onSelect(e.target.value);
            }
          },
        }}
        errorMessage={errorMessage}
        dismissAlert={dimissErrorAlert}
      />

      <Container width={selectContainerRef?.current?.offsetWidth ?? 0}>{showList && <Options className='select-option'>{options}</Options>}</Container>
    </SelectContainer>
  );
};

const SelectContainer = styled.div<{
  width?: string;
  height?: string;
  minHeight?: string;
  padding?: string;
}>`
  width: ${(props) => props.width || ''};
  height: ${(props) => props.height || ''};
  min-height: ${(props) => props.minHeight || ''};
  padding: ${(props) => props.padding || ''};
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export default Select;
