import Button from "components/atoms/Button";
import Divider from "components/atoms/Divider";
import Icon from "components/atoms/Icon";
import { Text } from "components/atoms/Typography";
import Modal from "components/organisms/Modal";
import { SizeNamesEnum, TypesNamesEnum } from "enums/Button.enum";
import { palette } from "lib/theme";
import React, { FC, useState } from "react";
import styled from "styled-components";
import ChangePasswordModalBody from "./ChangePasswordModalBody";
import TwoFAModalBody from "./TwoFAModalBody";
import { useDispatch, useSelector } from "react-redux";
import { getText } from "shared/locale-helper";
import Widget from "components/templates/Widget";
import { User } from "services/models";
import {
  selectCurrentUser,
  selectTokenToValidate,
} from "state/slice/auth.slice";
import { Sizes } from "pages/Boost/Boost";

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Abra2FAStatusText = (props: { active: any }) => {
  const { active } = props;

  if (active) {
    return (
      <Text letterSpacing="-0.5" size="large" color={palette.green.main}>
        {getText("active")}
      </Text>
    );
  } else {
    return (
      <Text letterSpacing="-0.5" size="large" color={palette.red.main}>
        {getText("inactive")}
      </Text>
    );
  }
};

const Abra2FASmallText = (props: { active: any }) => {
  const { active } = props;

  if (active) {
    return (
      <Text
        hasLineHeight={false}
        letterSpacing="-0.5"
        color={palette.darkBackgroundContrast.light70}
      >
        Authentication app
      </Text>
    );
  } else {
    return (
      <Text
        hasLineHeight={false}
        letterSpacing="-0.5"
        color={palette.darkBackgroundContrast.light70}
      >
        Add an extra security for your account
      </Text>
    );
  }
};

interface WalletSecurityWidgetProps extends Sizes {}

const WalletSecurityWidget: FC<WalletSecurityWidgetProps> = (props) => {
  const user = useSelector(selectCurrentUser);

  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [sharedKey, setSharedKey] = useState("");
  const [title, setTitle] = useState("");

  const handleGenerateKey = async () => {};

  return (
    <Widget {...props}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            height: "1.5rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text size="large" letterSpacing="-0.5" bold>
            Account security
          </Text>
        </div>
        <Row style={{ paddingTop: "1.5rem" }}>
          <div style={{ display: "flex", gap: "8px" }}>
            <Icon
              name="Union"
              size={24}
              color={palette.darkBackgroundContrast.white}
            ></Icon>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "2px" }}
            >
              <Text letterSpacing="-0.5" size="large">
                Abra 2FA: <Abra2FAStatusText active={true} />
              </Text>

              <Abra2FASmallText active={true} />
            </div>
          </div>

          <CustomButton
            dataCy="profile_turn_on_button1"
            buttonType={TypesNamesEnum.SECONDAY}
            size={SizeNamesEnum.SMALL}
            label={true ? "Reset" : "Turn on"}
            onClick={async () => {
              await handleGenerateKey();
              setShowTwoFAModal((_showTwoFAModal) => !_showTwoFAModal);
            }}
          ></CustomButton>

          <Modal
            visible={showTwoFAModal}
            modalProps={{ width: 484, height: 369, padding: 24 }}
          >
            <Modal.Body>
              <TwoFAModalBody
                setShowTwoFAModal={setShowTwoFAModal}
                sharedKey={sharedKey}
                email={user?.email ?? ""}
                setSharedKey={setSharedKey}
              />
            </Modal.Body>
          </Modal>
        </Row>
        <Divider top={1.5} bottom={1.5} />

        {true && (
          <Row>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Icon
                name="Key"
                size={24}
                color={palette.darkBackgroundContrast.white}
              ></Icon>
              <div>
                <Text letterSpacing="-0.5" size="large">
                  {getText("change_password")}
                </Text>
              </div>
            </div>

            <CustomButton
              dataCy="profile_change_button"
              buttonType={TypesNamesEnum.SECONDAY}
              size={SizeNamesEnum.SMALL}
              label={getText("change")}
              onClick={() => {
                setShowPasswordModal(
                  (_showPasswordModal) => !_showPasswordModal
                );
              }}
            ></CustomButton>
          </Row>
        )}

        {!true && (
          <Row style={{}}>
            <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Icon
                name="Key"
                size={24}
                color={palette.darkBackgroundContrast.white}
              />
              <div>
                <Text size="large">{getText("change_password")}</Text>
              </div>
            </div>

            <CustomButton
              dataCy="profile_change_button"
              buttonType={TypesNamesEnum.SECONDAY}
              size={SizeNamesEnum.SMALL}
              label={getText("change")}
              onClick={() =>
                setShowPasswordModal(
                  (_showPasswordModal) => !_showPasswordModal
                )
              }
            ></CustomButton>
          </Row>
        )}
        <Modal
          header={getText("change_password")}
          visible={showPasswordModal}
          onClose={() => {
            setShowPasswordModal(false);
          }}
        >
          <Modal.Body>
            <ChangePasswordModalBody
              setShowModal={setShowPasswordModal}
              setTitle={setTitle}
            />
          </Modal.Body>
        </Modal>
      </div>
    </Widget>
  );
};

const CustomButton = styled(Button)`
  align-self: center;
`;

export default WalletSecurityWidget;
