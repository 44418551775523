import React, { FC } from "react";
import { ButtonDefaultProps } from "interfaces/components/atoms/Atom.interface";
import { StyledButton } from "../../../styles/components/button";
import { TypesNamesEnum } from "../../../@types/enums/Button.enum";
import { generateDataCyFullID } from "shared/locale-helper";
import Loading from "../Loading";
import { SpinnerSizeEnum } from "@types/enums/Loading.enum";
import { palette } from "lib/theme";

const Button: FC<ButtonDefaultProps> = ({
  buttonType,
  size,
  width,
  height,
  label,
  error,
  disabled,
  className,
  onClick,
  children,
  dataCy,
  dataCyPrefix,
  isLoading = false,
  type = "button",
  ...otherProps
}) => {
  const handleOnclick = () => {
    onClick();
  };

  const getButtonType = (type: TypesNamesEnum): TypesNamesEnum =>
    !type ? TypesNamesEnum.PRIMARY : type;

  const buttonLabel = label || children;
  const buttonDataCy = generateDataCyFullID(
    dataCy,
    dataCyPrefix,
    buttonLabel,
    "button"
  );

  return (
    <StyledButton
      data-cy={buttonDataCy}
      type={type}
      buttonType={getButtonType(buttonType)}
      onClick={handleOnclick}
      size={size}
      width={width}
      height={height}
      disabled={disabled}
      className={className}
      isLoading={isLoading}
      {...otherProps}
    >
      {isLoading ? (
        <>
          <Loading
            showText={false}
            size={SpinnerSizeEnum.BUTTON}
            showRoundSpinner
            spinnerColor={palette.white.main}
          />
        </>
      ) : (
        label || children
      )}
    </StyledButton>
  );
};

export default Button;
