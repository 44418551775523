import React, { FC, useEffect, useState } from "react"

import { Sizes } from "./Borrow"
import { Heading, Text, Title } from "components/atoms/Typography"
import PanelWithHeader from "components/molecules/PanelWithHeader"
import styled from "styled-components"
import { BalancePieChart } from "pages/BalanceWidget"
import Percent from "components/atoms/Number/Percent"
import Currency from "components/atoms/Number/Currency"
import { palette } from "lib/theme"
import Row from "components/atoms/Row/Row"
import {
  LoanSummaryResponse,
  useGetAssetsQuery
} from "state/store/api"
import LoadingStage from "pages/LoadingStage"
import { formatNumberDisplay } from "shared"
import getAssetPrice from 'shared/getAssetPrice'
import { pricesSelector } from 'state/slice/prices.slice'
import { useSelector } from 'react-redux'

interface PortfolioProps extends Sizes {
  orders: LoanSummaryResponse
  isLoading: boolean
  isFetching: boolean
}

const Portfolio: FC<PortfolioProps> = (props) => {
  const { orders, isLoading } = props
  const [legends, setLegends] = useState<{ title: string; detail: string }[]>(
    []
  )
  const [loans, setLoans] = useState<PieChartItem[]>([])
  const [collaterals, setCollaterals] = useState<PieChartItem[]>([])
  const [balance, setBalance] = useState<number>(0)

  const prices = useSelector(pricesSelector)
  const { data: assetsInfo, isLoading: assetsLoading } = useGetAssetsQuery()

  useEffect(() => {
    if (!orders && !prices && !assetsInfo) return

    const outstandingBalance = !orders?.data
      ? 0
      : orders.data.reduce((previous, current) => {
        const price = prices?.find(
          (m) => m.identifier === current.currency
        )?.price
        const amount =
          parseFloat(current.outstandingBalance ?? "0") *
          parseFloat(price ?? "0")
        return previous + amount
      }, 0)

    const principalOutstanding = !orders?.data
      ? 0
      : orders.data.reduce((previous, current) => {
        const price = prices?.find(
          (m) => m.identifier === current.currency
        )?.price
        const amount =
          parseFloat(current.principalOutstanding ?? "0") *
          parseFloat(price ?? "0")
        return previous + amount
      }, 0)

    const interestOutstanding = !orders?.data
      ? 0
      : orders.data.reduce((previous, current) => {
        const price = prices?.find(
          (m) => m.identifier === current.currency
        )?.price
        const amount =
          parseFloat(current.interestOutstanding ?? "0") *
          parseFloat(price ?? "0")
        return previous + amount
      }, 0)

    const principalBorrowed = !orders?.data
      ? 0
      : orders.data.reduce((previous, current) => {
        const price = prices?.find(
          (m) => m.identifier === current.currency
        )?.price
        const amount =
          parseFloat(current.principalBorrowed ?? "0") *
          parseFloat(price ?? "0")
        return previous + amount
      }, 0)

    const collateral = !orders?.data
      ? 0
      : orders.data.reduce((previous, current) => {
        const price = prices?.find(
          (m) => m.identifier === current.currency
        )?.price
        const amount =
          parseFloat(current.collateral ?? "0") * parseFloat(price ?? "0")
        return previous + amount
      }, 0)

    const averageLTV = !orders?.data ? "0" : orders?.averageLtv ?? "0"

    const openLoans = !orders?.data
      ? 0
      : orders.data.reduce((previous, current) => {
        const amount = current.openLoans ?? 0
        return previous + amount
      }, 0)

    const currentLegends = [
      {
        title: "principal borrowed",
        detail: formatNumberDisplay(principalBorrowed, "currency") ?? "-",
      },
      {
        title: "principal outstanding",
        detail: formatNumberDisplay(principalOutstanding, "currency") ?? "-",
      },
      {
        title: "Interest outstanding",
        detail: formatNumberDisplay(interestOutstanding, "currency") ?? "-",
      },
      {
        title: "Collateral",
        detail: formatNumberDisplay(collateral, "currency") ?? "-",
      },
      {
        title: "Average LTV",
        detail: `${formatNumberDisplay(averageLTV, "percent")}` ?? "-",
      },
      { title: "Open loans", detail: openLoans.toString() ?? "-" },
    ]

    const loansSum =
      orders?.data
        ?.filter((m) => !m.collateral)
        ?.reduce(
          (previous, current) =>
            previous + parseFloat(current.principalOutstanding ?? "0"),
          0
        ) ?? 0

    const loans = !orders?.data
      ? []
      : orders.data
        .filter((m) => !m.collateral)
        ?.map((item) => {
          const price = prices?.find(
            (m) => m.identifier === item.currency
          )?.price

          const asset = assetsInfo?.assets?.find(
            (m) => m.name?.toUpperCase() === item.currency?.toUpperCase()
          )
          return {
            name: asset?.longName ?? item.currency?.toUpperCase(),
            amount: getAssetPrice(asset, item.principalOutstanding),
            currencyAmount:
              parseFloat(item.principalOutstanding ?? "0") *
              parseFloat(price ?? "0"),
            percent:
              (parseFloat(item.principalOutstanding ?? "0") *
                parseFloat(price ?? "0")) /
              loansSum,
          } as PieChartItem
        })
        .sort((m) => m.currencyAmount)

    const collaterals = !orders?.data
      ? []
      : orders.data
        .filter((m) => m.collateral)
        ?.map((item) => {
          const price = prices?.find(
            (m) => m.identifier === item.currency
          )?.price

          const asset = assetsInfo?.assets?.find(
            (m) => m.name?.toUpperCase() === item.currency?.toUpperCase()
          )

          return {
            name: asset?.longName ?? item.currency?.toUpperCase(),
            amount: getAssetPrice(asset, item.collateral),
            currencyAmount:
              parseFloat(item.collateral ?? "0") * parseFloat(price ?? "0"),
            percent:
              (parseFloat(item.collateral ?? "1") *
                parseFloat(price ?? "0")) /
              collateral,
          } as PieChartItem
        })
        .sort((m) => m.currencyAmount)

    setBalance(outstandingBalance)
    setLegends(currentLegends)
    setLoans(loans)
    setCollaterals(collaterals)
  }, [orders, prices, assetsInfo])

  return (
    <PanelWithHeader
      width="100%"
      minHeight="174px"
      title="Lending and collateral overview"
      {...props}
    >
      {isLoading || assetsLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <LoadingStage loadingText=" " />
        </div>
      ) : (
        <>
          <Row direction="column" gap="5px">
            <Title>Outstanding Balance</Title>
            <Heading variant="h1">
              <Currency value={balance} />
            </Heading>
          </Row>
          {true ? (
            // {balance > 0 ? (
            <>
              <Row margin="24px 0px 43px 0px">
                <Details legends={legends} />
              </Row>
              <Row gap="40px">
                <PieChartItems title="Loans" list={loans} />
                <PieChartItems
                  title="Collateral"
                  reverseColors
                  list={collaterals}
                />
              </Row>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </PanelWithHeader>
  )
}

const Details: FC<{ legends: { title: string; detail: string }[] }> = ({
  legends,
}) => {
  return (
    <Row direction="row" gap="40px">
      {legends.map((m) => {
        return <Detail key={m.title} {...m} />
      })}
    </Row>
  )
}

const Detail: FC<{ title: string; detail: string }> = ({ title, detail }) => {
  return (
    <Row direction="column" gap="8px">
      <Title>{title}</Title>
      <Text size="large" letterSpacing="-0.48px">
        {detail}
      </Text>
    </Row>
  )
}

interface PieChartItem {
  name: string
  amount: number
  currencyAmount: number
  percent: number
}
interface PieChartItemsProps {
  title?: string
  reverseColors?: boolean
  list: PieChartItem[]
}
const PieChartItems: FC<PieChartItemsProps> = ({
  title,
  reverseColors = false,
  list,
}) => {
  const values = list.map((item) => {
    return { value: item.currencyAmount }
  })

  const colors = reverseColors
    ? (palette.charts.balancePieChart as string[]).reverse()
    : (palette.charts.balancePieChart as string[])

  return (
    <Row justifyContent="flex-start" alignItems="center" gap="16px">
      <div style={{ position: "relative", width: "220px", height: "220px" }}>
        <div
          style={{
            top: "50%",
            left: " 50%",
            transform: "translateY(-50%) translateX(-50%)",
            margin: "0",
            padding: "10px 0",
            position: "absolute",
          }}
        >
          <Text size="large" bold>
            {title}
          </Text>
        </div>

        <div style={{ position: "absolute" }}>
          <BalancePieChart
            reverseColors
            boxSize={220}
            data={values}
            minRadius={2}
          />
        </div>
      </div>
      <Row
        direction="column"
        justifyContent="center"
        width="262.5px"
        height="282px"
        gap="11px"
      >
        {list.length ? (
          list.map(
            ({ name, amount, currencyAmount, percent }, index: number) => (
              <Row gap="12px" key={`${index}-coin`}>
                <Row
                  gap="0.50rem"
                  alignItems="center"
                  width="250px"
                  height="18px"
                >
                  <Dot color={colors[index]} />
                  <Text ellipsis size="small">
                    {` ${name}`}{" "}
                  </Text>
                  <Text size="small" color={palette.gray.main as string}>
                    {amount}
                  </Text>{" "}
                </Row>
                <Row gap="0.25rem" alignItems="center">
                  <Text size="small">
                    <Currency value={currencyAmount} />
                  </Text>
                  <Text
                    size="tiny"
                    color={palette.darkBackgroundContrast.light70}
                  >
                    (
                    <Percent
                      signDisplay="auto"
                      maximumFractionDigits={0}
                      value={percent}
                    />
                    )
                  </Text>
                </Row>
              </Row>
            )
          )
        ) : (
          <></>
        )}
      </Row>
    </Row>
  )
}

const Dot = styled.div<{ color?: string }>`
  background-color: ${({ color }) => color || "none"};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  flex: 0 0 auto;
  transition: 1s all;
`

export default Portfolio
