import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { AssetNetworkInformation, CustodyNetwork } from 'state/store/custodyApi'
import { BoostNetwork } from 'state/store/withdrawApi';

interface NetworksState {
  depositAsset: string;
  withdrawalAsset: string;
  asset: string
  networks: NetworkCustodyAndBoost[];
  boostNetworks: BoostNetwork[];
  custodyWithdrawalNetworks: CustodyNetwork[];
  custodyDepositNetworks: CustodyNetwork[];
  custodyAssetNetworkInformation?: AssetNetworkInformation;
  approvedBoostNetworks: string[];
  selectedNetwork?: NetworkCustodyAndBoost;
  selectedDepositNetwork?: NetworkCustodyAndBoost;
  selectedWithdrawalNetwork?: NetworkCustodyAndBoost;
  loadingNetworks: boolean;
  loadingWallet: boolean;
  maxUnverifiedWithdraw?: AssetAmount
  minWithdraw?: AssetAmount
  maxWithdraw?: AssetAmount
}
interface AssetAmount {
  amount: string,
  asset: string
}

const initialState: NetworksState = {
  depositAsset: '',
  withdrawalAsset: '',
  asset: '',
  networks: [],
  boostNetworks: [],
  custodyWithdrawalNetworks: [],
  custodyDepositNetworks: [],
  approvedBoostNetworks: [],
  loadingNetworks: false,
  loadingWallet: false
};

export interface NetworkCustodyAndBoost extends BoostNetwork {
  network: string,
  description: string
  name: string
  isBoost: boolean
  isCustody: boolean
}

export const networksSlice = createSlice({
  name: 'networks',
  initialState,
  reducers: {
    setNetworks: (state, action: PayloadAction<NetworkCustodyAndBoost[]>) => {
      state.networks = action.payload;
    },
    selectNetwork: (state, action: PayloadAction<NetworkCustodyAndBoost | undefined>) => {
      state.selectedNetwork = action.payload;
    },
    selectDepositNetwork: (state, action: PayloadAction<NetworkCustodyAndBoost | undefined>) => {
      state.selectedDepositNetwork = action.payload;
    },
    selectWithdrawalNetwork: (state, action: PayloadAction<NetworkCustodyAndBoost | undefined>) => {
      state.selectedWithdrawalNetwork = action.payload;
    },
    setBoostNetworks: (state, action: PayloadAction<BoostNetwork[]>) => {
      state.boostNetworks = action.payload;
    },
    setCustodyDepositNetworks: (state, action: PayloadAction<CustodyNetwork[]>) => {
      state.custodyDepositNetworks = action.payload;
    },
    setCustodyWithdrawalNetworks: (state, action: PayloadAction<CustodyNetwork[]>) => {
      state.custodyWithdrawalNetworks = action.payload;
    },
    setNetworksLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingNetworks = action.payload;
    },
    setWalletLoading: (state, action: PayloadAction<boolean>) => {
      state.loadingWallet = action.payload;
    },
    setNetworkAsset: (state, action: PayloadAction<string>) => {
      state.asset = action.payload;
    },
    setDepositNetworkAsset: (state, action: PayloadAction<string>) => {
      state.depositAsset = action.payload;
    },
    setWithdrawalNetworkAsset: (state, action: PayloadAction<string>) => {
      state.withdrawalAsset = action.payload;
    },
    setApprovedBoostNetworks: (state, action: PayloadAction<string[]>) => {
      state.approvedBoostNetworks = action.payload;
    },
    setCustodyAssetNetworkInformation: (state, action: PayloadAction<AssetNetworkInformation>) => {
      state.custodyAssetNetworkInformation = action.payload;
    },
    setMaxUnverifiedWithdraw: (state, action: PayloadAction<AssetAmount>) => {
      state.maxUnverifiedWithdraw = action.payload
    },
    setMinWithdraw: (state, action: PayloadAction<AssetAmount>) => {
      state.minWithdraw = action.payload
    },
    setMaxWithdraw: (state, action: PayloadAction<AssetAmount>) => {
      state.maxWithdraw = action.payload
    },
    restartNetworks: (state) => {
      return initialState
    }
  },
});

export const {
  setNetworks,
  selectNetwork,
  selectDepositNetwork,
  selectWithdrawalNetwork,
  setNetworksLoading,
  setApprovedBoostNetworks,
  setBoostNetworks,
  setCustodyDepositNetworks,
  setCustodyWithdrawalNetworks,
  setNetworkAsset,
  setCustodyAssetNetworkInformation,
  restartNetworks,
  setWalletLoading,
  setMaxUnverifiedWithdraw,
  setDepositNetworkAsset,
  setWithdrawalNetworkAsset,
  setMinWithdraw,
  setMaxWithdraw
  } = networksSlice.actions;

export const networkSelector = createSelector(
  (state: RootState) => state.networks,
  (state: NetworksState) => state
);
