import Modal from 'components/organisms/Modal';
import React, { useEffect } from 'react'
import useCustodyTerms from './useCustodyTerms';
import { useState } from 'react';
import PrimeTerms from 'assets/abra-prime.docx';
import Checkbox from 'components/atoms/Checkbox'
import styled from 'styled-components'
import Button from 'components/atoms/Button'
import { TypesNamesEnum } from 'enums/Button.enum'
import { logout } from 'state/slice/auth.slice'

const CustodyTermsOfService = () => {
  const { checkTerms, terms, checkTermsMeta } = useCustodyTerms();
  const [showTerms, setShowTerms] = useState(false);
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if(terms.currentData){
      setChecked(terms?.currentData?.checked)
    }
  }, [terms])

  const clickTermsAccepted = () => {
    setChecked(!checked)
    checkTerms(!checked)
  };
  return terms.isLoading ? null : <Modal visible={showTerms} header='Terms of Service'>
      <Modal.Body>
        <div>
          <p>
            You are required to review 
            <ExternalLink href={PrimeTerms} download='Abra Prime User Agreement' target='_blank'>
              {' '}
              Abra Prime User Agreement{' '}
            </ExternalLink>{' '}
            and accept our terms to continue.
          </p>
          <Checkbox text='Accept Terms' checked={checked} onChange={() => clickTermsAccepted()} />
        </div>
        <ButtonsArea>
        <Button
            height='44'
            width='220'
            buttonType={TypesNamesEnum.SECONDAY}
            label="Cancel"
            onClick={() => logout()}
            disabled={checkTermsMeta.isLoading}
          />
        <Button
            height='44'
            width='180'
            buttonType={TypesNamesEnum.ACCENT}
            label="Continue"
            onClick={() => setShowTerms(false)}
            disabled={!checked || checkTermsMeta.isLoading}
            isLoading={checkTermsMeta.isLoading}
          />
        </ButtonsArea>
      </Modal.Body>
    </Modal>
};

const ButtonsArea = styled.div`
  display: flex;
  justify-content: space-between;
`
const ExternalLink = styled.a`
  color: #a399f6;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.51px;
`;

export default CustodyTermsOfService;
