import React, { FC } from "react";
import {
  StyledInputAdditionalInfo,
  StyledListAdditionalInfo,
} from 'styles/components/input';
import Icon from "components/atoms/Icon";
import { IInputAdditionalInfoProps } from "interfaces/components/atoms/Atom.interface";
import { palette } from 'lib/theme';

const InputAdditionaInfo: FC<IInputAdditionalInfoProps> = ({ info }) => {
  return (
    <StyledInputAdditionalInfo style={{maxWidth: "280px"}}>{
      Array.isArray(info) ?
      <StyledListAdditionalInfo> {
        info.map((item, indx) =>
          (
            <li key={`addtional-info-${indx}`}  style={{marginTop: "-2px", minWidth: "119px"}}>
              {item?.icon &&
                <Icon size={16} name={item?.icon} color={item?.color ?? palette.darkBackgroundContrast.light20} iconProps={{
                  style: {
                    position: 'relative',
                    top: '3px',
                  }}} />
              } {item?.text}
            </li>
          )
        )
      }
      </StyledListAdditionalInfo>
      : <p>{info}</p>
    }</StyledInputAdditionalInfo>
  );
};

export default InputAdditionaInfo;
