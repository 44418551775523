import Icon from 'components/atoms/Icon';
import React, { useEffect, useRef, useState } from 'react';
import usePermissions from 'shared/usePermissions';
import styled from 'styled-components';

interface SimplerSelectorProps<T> {
  label: string;
  selectedItem?: T;
  data: T[];
  onSelect: (data: T) => void;
  labelParameter: string;
  labelFallback: string;
  transform?: (text: string) => string;
}

const defaultTransform = (text: string) => text;
const SimplerSelector = <T extends unknown>({ selectedItem, data, onSelect, labelFallback, transform = defaultTransform }: SimplerSelectorProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState<T[]>(data);
  const permissions = usePermissions();

  const organizationInput = useRef<HTMLInputElement>(null);
  const generateSize = () => {
    return data.reduce((total, next) => Math.max(total, secondaryLabel(next).length), 12);
  };
  const secondaryLabel = (item?: T) => {
    const convertedItem = (item as unknown as any) ?? {};
    return permissions.isAccountManager ? `${convertedItem[labelFallback]} / ${abbreviate(convertedItem.department)}` : convertedItem[labelFallback];
  };
  const abbreviate = (text: string = '') => {
    var matches = text.match(/\b(\w)/g);
    return matches ? matches.join('') : '';
  };

  useEffect(() => {
    const filteredResults = data.filter((c) => secondaryLabel(c).toUpperCase().includes(search.toUpperCase()));
    setFilteredData(filteredResults);
  }, [search, data]);

  const toggleOpen = (e: any) => {
    if (e?.target?.tagName?.toUpperCase() == 'INPUT') {
    } else {
      setIsOpen(!isOpen);
      setSearch('');
    }
  };

  const clickOnItem = (value: T) => {
    setIsOpen(false);
    onSelect(value);
  };

  return data.length ? (
    <SelectorContainer size={generateSize()}>
      <OptionSelected onClick={toggleOpen}>
        {isOpen ? (
          <OrganizationInput ref={organizationInput} value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search by Organization' />
        ) : (
          <p>{transform(secondaryLabel(selectedItem))}</p>
        )}
        <Icon name={isOpen ? 'UpArrowSelect' : 'DownArrowSelect'} size={24} color='#bbb' />
      </OptionSelected>
      {isOpen && (
        <OptionsContainer>
          {filteredData.map((value, index) => (
            <Option onClick={() => clickOnItem(value)} key={index}>
              <div>{transform(secondaryLabel(value))}</div>
            </Option>
          ))}
        </OptionsContainer>
      )}
    </SelectorContainer>
  ) : null;
};

interface SelectorContainerProps {
  size: number;
}
const SelectorContainer = styled.div<SelectorContainerProps>`
  font-size: 1.2rem;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 1vh;
  padding-bottom: 1vh;
  height: 8vh;
  width: ${({ size }) => size + 'ch'};
  user-select: none;
  border-radius: 8px;
  gap: 3vw;
  background-color: #1d1d4388;
`;

const OrganizationInput = styled.input`
  display: inline-block;
  border: 0;
  background: none;
  border: 1px solid transparent;
  color: white;
  font-size: 1.2rem;
  width: 100%;
  height: 100%;
  &:focus {
    outline: none;
  }
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.5);
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.5);
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.5);
  }
`;

const OptionSelected = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 1vw;
`;

const OptionsContainer = styled.div`
  position: absolute;
  left: 0;
  max-height: 25vh;
  overflow-y: auto;
  z-index: 1000;
  top: 8vh;
  width: 100%;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
`;
const Option = styled.div`
  min-height: 4vh;
  display: flex;
  align-items: center;
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-top: 0.3vh;
  width: 100%;
  height: 8vh;
  padding-bottom: 0.3vh;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;
export default SimplerSelector;
