import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const EmptyTeller: FC<ISVG> = ({
  size = 150,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M63.7339 122.147C95.6184 122.147 121.466 96.3 121.466 64.4156C121.466 32.5311 95.6184 6.68359 63.7339 6.68359C31.8495 6.68359 6.00195 32.5311 6.00195 64.4156C6.00195 96.3 31.8495 122.147 63.7339 122.147Z"
      stroke={color}
      strokeWidth="3.24"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.952 141.432L141.248 142.136C138.916 144.468 135.134 144.468 132.802 142.136L107.683 117.017L116.833 107.867L141.952 132.986C144.285 135.319 144.285 139.1 141.952 141.432Z"
      stroke={color}
      strokeWidth="3.24"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M115.839 108.863L108.373 101.398"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.871 115.83L101.405 108.365"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.8407 76.0209L93.4761 44.3848"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.7763 81.6456L67.5879 50.834"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.7337 18.627C89.0211 18.627 109.521 39.1265 109.521 64.4147C109.521 77.8178 103.763 89.8754 94.5841 98.2492"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5677 40.6842C30.4013 31.0768 39.6701 23.7859 50.6699 20.5176"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.4536 109.713C68.2601 110.036 66.0165 110.203 63.7339 110.203C38.4465 110.203 17.9462 89.7031 17.9462 64.4149C17.9462 59.6853 18.6631 55.1234 19.9947 50.832"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EmptyTeller;
