import styled, { css } from "styled-components";
import { TableHeadAlignment } from "@types/enums/Table.enum";
import { BreakpointsEnum, BreakpointsTableEnum } from "@types/enums/GridSystem";
import { palette, breakpoints, breakpointsTable } from "lib/theme";
import { isFunction } from "lib/utils/helpers";

interface ICellWrapper {
  sizes?: {
    [key in BreakpointsTableEnum]: string;
  };
}
export const CellWrapper = styled.div<ICellWrapper>`
  ${({ sizes }) => {
    let ancho: {
      minWidthVal: string;
      maxWidthVal: string;
      widthVal: string;
    }[] = sizes
      ? Object.entries(sizes).map(([key, val], indx) => {
          const prevVal =
            breakpointsTable.values[breakpointsTable.keys[indx - 1]];
          const currentVal = breakpointsTable.values[key];
          const minWidthVal = prevVal ? prevVal + 1 : currentVal;
          const maxWidthVal = currentVal;
          return {
            minWidthVal,
            maxWidthVal,
            widthVal: val,
          };
        })
      : [];
    return ancho?.map(({ minWidthVal, maxWidthVal, widthVal }) => {
      return `@media only screen and  (min-width: ${minWidthVal}${breakpointsTable.measurement}) and (max-width: ${maxWidthVal}${breakpointsTable.measurement}) {
      width: ${widthVal};
   }`;
    });
  }}
`;

export const StyledTableWrapper = styled.div<{
  leftScrolling?: boolean;
  rightScrolling?: boolean;
  longTable?: boolean;
}>`
  ${({ longTable, leftScrolling, rightScrolling }) =>
    longTable &&
    css`
      position: relative;
      min-height: 520px;
    `}
`;

export const StyledTableContainer = styled.div<{
  expandTable?: boolean;
  longTable?: boolean;
  fullScreenTable?: boolean;
}>`
  display: flex;
  ${({ fullScreenTable }) =>
    fullScreenTable &&
    css`
      margin-top: 5px;
    `}
  ${({ expandTable }) =>
    expandTable &&
    css`
      margin-right: -24px;
      margin-left: -24px;
    `}

  ${({ longTable }) =>
    longTable &&
    css`
      display: block;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      margin: 0 24px;
      min-height: 520px;
      position: relative;

      ::-webkit-scrollbar {
        width: 12px;
        height: 12px;
        display: block;
      }

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 64px rgba(163, 153, 246, 0.1);
        box-shadow: inset 0 0 64px rgba (163, 153, 246, 0.1);
        border-radius: 8px;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 8px;
        box-shadow: inset 0 0 64px rgba(255, 255, 255, 0.2);
        -webkit-box-shadow: inset 0 0 64px rgba(255, 255, 255, 0.2);
      }
    `}
`;

export const StyledTable = styled.table<{
  longTable?: boolean;
}>`
  width: ${({ longTable }) => (longTable ? "140%" : "100%")};
  background: ${palette.background.elevation1};
  text-align: left;
  box-sizing: border-box;
  border-collapse: collapse;
  position: relative;

  @media only screen and (max-width: ${breakpoints.values[
      BreakpointsEnum.Large
    ]}${breakpoints.measurement}) {
    width: -webkit-fill-available;
  }
`;

export const StyledHead = styled.thead`
  font-size: 12px;
  line-height: 125%;
  font-weight: 400;
  margin: 0 4px;
`;

export const StyledHeadIcon = styled.span<{ left?: boolean }>`
  ${({ left }) =>
    left &&
    css`
      margin-left: 0;
      margin-right: 5px;
    `}
`;

export const StyledHeadCell = styled.th<{
  enableSort?: boolean;
  alignment?: TableHeadAlignment;
  width?: string;
  wrapText?: boolean;
  cornerCell?: boolean;
  hideOnBreakpoints?: BreakpointsTableEnum[];
}>`
  padding-top: 4px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 100;
  font-style: normal;
  color: ${palette.darkBackgroundContrast.light70};
  ${({ enableSort }) =>
    enableSort &&
    css`
      cursor: default;
      &:hover .sortIcon {
        path {
          fill: ${palette.darkBackgroundContrast.white};
        }
      }
    `}
  ${({ alignment }) => alignment && `text-align: ${alignment};`}
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ wrapText }) =>
    wrapText &&
    css`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
  ${({ hideOnBreakpoints }) => {
    return breakpointsTable.keys
      ?.map((key, indx, arr) => {
        if (hideOnBreakpoints?.find((x) => x === key)) {
          const prevVal =
            breakpointsTable.values[breakpointsTable.keys[indx - 1]];
          const currentVal = breakpointsTable.values[key];
          const minWidthVal = prevVal ? prevVal + 1 : currentVal;
          const maxWidthVal = currentVal;

          return `@media only screen and  (min-width: ${minWidthVal}${breakpointsTable.measurement}) and (max-width: ${maxWidthVal}${breakpointsTable.measurement}) {
          display: none;
          width: 0;
        }`;
        }
      })
      .toString()
      .replaceAll(",", "");
  }}
`;

export const StyledBody = styled.tbody<{ isSorting: boolean }>`
  & > tr {
    box-shadow: 0 1px inset ${palette.darkBackgroundContrast.light10};
    background: ${palette.background.elevation1};
    transition: 0.15s background cubic-bezier(0.25, 0, 0.5, 1);
    &:hover {
      ${({ isSorting }) =>
        !isSorting && `background: ${palette.white.light015};`}
    }
    & td:first-child,
    & td:last-child {
      box-shadow: 0 2px inset ${palette.background.elevation1};
    }
  }
  ${({ isSorting }) =>
    isSorting &&
    ` -webkit-filter: blur(1px);
      -moz-filter: blur(1px);
      -o-filter: blur(1px);
      -ms-filter: blur(1px);
      filter: blur(1px);
      background: #13132B;
      opacity: 0.7; `}
`;

export const StyledRow = styled.tr<{
  height?: string;
  diableBoxShadow?: boolean;
  onClick: any;
}>`
  height: ${(props) => props.height || "80px"};
  border: none;
  color: ${palette.white.main};
  cursor: default;
  ${({ diableBoxShadow }) => diableBoxShadow && `box-shadow: none !important;`}
  ${({ onClick }) => {
    if (isFunction(onClick)) {
      return css`
        &:hover {
          cursor: pointer;
        }
      `;
    }
  }}
`;

export const StyledRowGroup = styled.tr`
  border: none;
  color: ${palette.white.light70};
  &.row-group {
    box-shadow: none;
    &:hover {
      background-color: ${palette.background.elevation1};
    }
  }
  td {
    padding: 20px 0;
  }
`;
interface IStyledCell {
  width?: string;
  wrapText?: boolean;
  hideOnBreakpoints?: BreakpointsTableEnum[];
  notHideOnOverflow?: boolean;
}

export const StyledCell = styled.td<IStyledCell>`
  word-wrap: break-word;
  box-sizing: border-box;
  font-size: 16px;
  ${({ wrapText, notHideOnOverflow }) =>
    !wrapText &&
    css`
      overflow: ${notHideOnOverflow ? "none" : "hidden"};
      white-space: nowrap;
      text-overflow: ellipsis;
      & *:not(img) *:not(input) *:not(label) *:not(label > span),
      div,
      span,
      p {
        overflow: ${notHideOnOverflow ? "none" : "hidden"};
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      & > div {
        max-width: 99%;
      }
    `}
  ${({ hideOnBreakpoints }) => {
    return breakpointsTable.keys
      ?.map((key, indx, arr) => {
        if (hideOnBreakpoints?.find((x) => x === key)) {
          const prevVal =
            breakpointsTable.values[breakpointsTable.keys[indx - 1]];
          const currentVal = breakpointsTable.values[key];
          const minWidthVal = prevVal ? prevVal + 1 : currentVal;
          const maxWidthVal = currentVal;

          return `@media only screen and  (min-width: ${minWidthVal}${breakpointsTable.measurement}) and (max-width: ${maxWidthVal}${breakpointsTable.measurement}) {
          display: none;
          width: 0;
        }`;
        }
      })
      .toString()
      .replaceAll(",", "");
  }}
`;

export const StyledCellAux = styled(StyledCell)`
  table-layout: fixed;
  width: 24px;
`;
export const StyledCellAdditionalInfo = styled.div`
  span {
    color: ${palette.darkBackgroundContrast.light70};
    font-size: 12px;
  }
`;
export const IconCellContainer = styled.div`
  display: flex;
  float: left;
  margin-right: 12px;
`;

export const StyledPagination = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  gap: 4px;
`;

export const StyledPaginationNav = styled.div<{ hide?: boolean }>`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  color: ${palette.darkBackgroundContrast.light20};

  :hover {
    svg > path {
      fill: ${palette.white.main};
    }
  }

  ${({ hide }) =>
    !hide &&
    css`
      visibility: hidden;
      opacity: 0;
    `}
`;

export const StyledPageNumber = styled.div`
  span {
    color: ${palette.white.main};
    padding: 7px 12px;
    border-radius: 8px;
    font-size: 16px;
    ${({ active }) =>
      active &&
      css`
        background-color: ${palette.darkBackgroundContrast.light20};
      `};
  }
`;

export const StyledPageDots = styled.div`
  svg {
    margin: 0 3px;
    > circle {
      fill: ${palette.darkBackgroundContrast.light20};
    }

    > circle:hover {
      fill: ${palette.white.main};
      cursor: pointer;
    }
  }
  ${({ active }) => active && `svg > circle { fill: ${palette.white.main}; }`}
`;

export const StyledPages = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;
`;

export const StyledPaginationCaption = styled.span`
  color: ${palette.darkBackgroundContrast.light70};
  font-size: 14px;
  text-align: left;
  position: absolute;
  left: 24px;
`;

export const StyledFooter = styled.tfoot<{
  widgetVariant: boolean;
  recordsPerPage: number;
}>`
  ${({ widgetVariant, recordsPerPage }) =>
    widgetVariant &&
    `
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: ${recordsPerPage * 81 + 53}px;
  `}
`;
