import { useGetNetworksQuery, useGetNetworksApprovedForAssetQuery } from 'state/store/withdrawApi';
import { useCallback, useEffect } from 'react';
import { NetworkCustodyAndBoost, networkSelector, restartNetworks, selectNetwork, setApprovedBoostNetworks, setBoostNetworks, setCustodyAssetNetworkInformation, setMaxUnverifiedWithdraw, setNetworkAsset, setNetworks, setNetworksLoading, setWalletLoading,
  setDepositNetworkAsset, setWithdrawalNetworkAsset, setCustodyDepositNetworks, setCustodyWithdrawalNetworks, selectDepositNetwork, selectWithdrawalNetwork, setMinWithdraw, setMaxWithdraw
} from 'state/slice/networksReducer';
import { useSelector, useDispatch } from 'react-redux';
import { useGetCustodyDepositNetworksQuery, useGetCustodyWithdrawInfoQuery, useGetCustodyWithdrawNetworksQuery } from 'state/store/custodyApi'

interface BoostOrCustody {
  boost: boolean
  custodyWithdraw: boolean
  custodyDeposit: boolean
}
const useNetworks = ({ boost }: BoostOrCustody) => {

  const {
    depositAsset,
    withdrawalAsset,
    asset,
    approvedBoostNetworks,
    boostNetworks,
    custodyDepositNetworks,
    custodyWithdrawalNetworks,
    loadingNetworks,
    loadingWallet,
    networks,
    selectedNetwork,
    selectedDepositNetwork,
    selectedWithdrawalNetwork,
    custodyAssetNetworkInformation,
    maxUnverifiedWithdraw,
    minWithdraw,
    maxWithdraw
  } = useSelector(networkSelector)
  
  const dispatch = useDispatch()

  const { currentData: custodyWithdrawNetworksData, isFetching: custodyWithdrawNetworkFetching } = useGetCustodyWithdrawNetworksQuery(withdrawalAsset , { skip: !withdrawalAsset, refetchOnMountOrArgChange: true})
  const { currentData: custodyDepositNetworksData, isFetching: custodyDepositNetworkFetching } = useGetCustodyDepositNetworksQuery(depositAsset , { skip: !depositAsset, refetchOnMountOrArgChange: true})
  const { currentData: boostNetworksData, isFetching: boostNetworkFetching } = useGetNetworksQuery(asset ?? '', { skip: !asset || !boost , refetchOnMountOrArgChange: true});
  const { currentData: boostApprovedNetworksData, isFetching: boostApprovedFetching } = useGetNetworksApprovedForAssetQuery(asset ?? '', { skip: !asset || !boost , refetchOnMountOrArgChange: true});
  const { currentData: custodyNetworkInformationData, isFetching: custodyInfoFetching } = useGetCustodyWithdrawInfoQuery({asset: withdrawalAsset, network: selectedWithdrawalNetwork?.network ?? '' }, {skip: !withdrawalAsset || !selectedWithdrawalNetwork, refetchOnMountOrArgChange: true})
  
  const restart = useCallback(() => {
    dispatch(restartNetworks())
  }, [dispatch])

  const setNetwork = useCallback((network: NetworkCustodyAndBoost) => {
    dispatch(selectNetwork(network))
  }, [dispatch])

  const setDepositNetwork = useCallback((network: NetworkCustodyAndBoost) => {
    if (network !== selectedDepositNetwork) {
      dispatch(selectDepositNetwork(network))
    }
  }, [dispatch, selectedDepositNetwork])

  const setWithdrawalNetwork = useCallback((network: NetworkCustodyAndBoost) => {
    if (network !== selectedWithdrawalNetwork) {
      dispatch(selectWithdrawalNetwork(network))
    }
  }, [dispatch, selectedWithdrawalNetwork])

  const setAsset = useCallback((asset: string) => {
    dispatch(setNetworkAsset(asset))
  }, [dispatch])

  const setDepositAsset = useCallback((asset: string) => {
    dispatch(selectDepositNetwork(undefined))
    dispatch(setDepositNetworkAsset(asset))
  }, [dispatch])

  const setWithdrawalAsset = useCallback((asset: string) => {
    dispatch(selectWithdrawalNetwork(undefined))
    dispatch(setWithdrawalNetworkAsset(asset))
  }, [dispatch])

  useEffect(() => {
    const loadingNetwork = custodyWithdrawNetworkFetching || boostNetworkFetching || boostApprovedFetching || custodyDepositNetworkFetching
    dispatch(setNetworksLoading(loadingNetwork))
  }, [dispatch, custodyWithdrawNetworkFetching, boostNetworkFetching, boostApprovedFetching, custodyDepositNetworkFetching])

  useEffect(() => {
    const loadingWallet = custodyInfoFetching
    dispatch(setWalletLoading(loadingWallet))
  }, [custodyInfoFetching, dispatch])

  useEffect(() => {
    boostNetworksData && dispatch(setBoostNetworks(boostNetworksData.networks))
  }, [boostNetworksData, dispatch])

  useEffect(() => {
      custodyWithdrawNetworksData && dispatch(setCustodyWithdrawalNetworks(custodyWithdrawNetworksData))
      custodyDepositNetworksData && dispatch(setCustodyDepositNetworks(custodyDepositNetworksData))
  }, [custodyWithdrawNetworksData, custodyDepositNetworksData, dispatch])

  useEffect(() => {
    boostApprovedNetworksData && dispatch(setApprovedBoostNetworks(boostApprovedNetworksData.networks))
  }, [boostApprovedNetworksData, dispatch])

  useEffect(() => {
    custodyNetworkInformationData && dispatch(setCustodyAssetNetworkInformation(custodyNetworkInformationData.balanceWithEstimatedFee))
    custodyNetworkInformationData && dispatch(setMaxUnverifiedWithdraw(custodyNetworkInformationData?.maxUnverifiedWithdrawal))
    custodyNetworkInformationData && dispatch(setMinWithdraw(custodyNetworkInformationData?.minWithdrawal))
    custodyNetworkInformationData && dispatch(setMaxWithdraw(custodyNetworkInformationData?.maxWithdrawal))

  }, [custodyNetworkInformationData, dispatch])

  useEffect(() => {
    const allNetworks: NetworkCustodyAndBoost[] = boostNetworks
      .filter((n) => approvedBoostNetworks.includes(n.network!)).map(n => ({
      isBoost: true,
      isCustody: false,
      ...n
    }))
    custodyDepositNetworks.forEach((a) => {
      const alreadySaved = allNetworks.find(network => network.network === a.name)
      if(alreadySaved) {
        alreadySaved.isCustody = true
      } else {
        allNetworks.push({
          isBoost: false,
          isCustody: true,
          ...a
        })
      }
    })
    custodyWithdrawalNetworks.forEach((a) => {
      const alreadySaved = allNetworks.find(network => network.network === a.name)
      if(alreadySaved) {
        alreadySaved.isCustody = true
      } else {
        allNetworks.push({
          isBoost: false,
          isCustody: true,
          ...a
        })
      }
    })
    dispatch(setNetworks(allNetworks))
  }, [boostNetworks, approvedBoostNetworks, custodyDepositNetworks, custodyWithdrawalNetworks, dispatch])

  useEffect(() => {
    if(!boost) {
      let onlyOneElement = custodyWithdrawalNetworks.length === 1
      if(onlyOneElement) {
        setWithdrawalNetwork(networks[0])
      }
      onlyOneElement = custodyDepositNetworks.length === 1
      if(onlyOneElement) {
        setDepositNetwork(networks[0])
      }
    } else {
      const onlyOneElement = networks.filter(n => n.isBoost).length === 1
      if(onlyOneElement) {
        setNetwork(networks[0])
      }
    }
  }, [networks, boost, setNetwork, setDepositNetwork, setWithdrawalNetwork, custodyDepositNetworks, custodyWithdrawalNetworks ])

  return {
    selectedNetwork,
    setNetwork, 
    setAsset,
    setDepositAsset,
    setWithdrawalAsset,
    setDepositNetwork,
    setWithdrawalNetwork,
    selectedDepositNetwork,
    selectedWithdrawalNetwork,
    depositAsset,
    withdrawalAsset,
    asset,
    networks,
    custodyWithdrawalNetworks,
    custodyDepositNetworks,
    loadingNetworks,
    loadingWallet,
    restart,
    custodyAssetNetworkInformation,
    maxUnverifiedWithdraw,
    minWithdraw,
    maxWithdraw
  }
}

export default useNetworks
