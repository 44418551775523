import React, { useEffect } from "react";
import LtvNotification from "./LtvNotification";
import { useLazyGetLoanBalancesQuery } from "state/store/loanApi";


const CustodyPortfolioLtvNotification: React.FC = () => {

    const [getLoanBalances, loanBalances] = useLazyGetLoanBalancesQuery();

    useEffect(() => {
        getLoanBalances();
    }, [])

    return (<>
        <LtvNotification ltvStatus={loanBalances?.data?.ltvStatus} />
    </>);
};

export default CustodyPortfolioLtvNotification;