import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const NotificationBell: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = "",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path d="M54.4475 49.3875C53.9063 48.4107 53.6176 47.3073 53.6176 46.1858V29.6711C53.6176 18.809 44.8325 10 34 10C23.1675 10 14.3824 18.809 14.3824 29.6711V46.1858C14.3824 47.3073 14.0937 48.4107 13.5525 49.3875L11 54H57L54.4475 49.3875Z" stroke={color} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M42 54C42 58.419 38.419 62 34 62C29.581 62 26 58.419 26 54" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M38 10.5V9.16849C38 6.86962 36.206 5 34 5C31.794 5 30 6.86962 30 9.16849L30 10.5" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default NotificationBell;

