import React, { FC } from "react"
import Layout from "components/templates/Layout"
import alternativeGridSystem from "lib/theme/alternativeGridSystem"
import { GridItemSetting } from "components/templates/Widget/interfaces"
import { useGetOrderDetailsQuery } from "state/store/api"
import { LoanDetails } from "./LoanDetails"
import LoanTransactionsWidget from "./LoanTransactionsWidget"
import { useNavigate, useParams } from "react-router-dom"
import { PagesUrl } from "lib/constants/config.constant"
import InterestMonthlyWidget from "./InterestMonthlyWidget"
import AttarchmentWidget from "./AttarchmentWidget"
import { NotificationLTV } from "./alerts/NotificationLTV"
import CompoundWidget from "./CompoundWidget"
import { pricesSelector } from 'state/slice/prices.slice'
import { useSelector } from 'react-redux'

export interface Sizes {
  xs?: GridItemSetting
  sm?: GridItemSetting
  md?: GridItemSetting
  lg?: GridItemSetting
  xl?: GridItemSetting
  xxl?: GridItemSetting
}

const LoanScreen = () => {

  const { orderId } = useParams()
  const navigate = useNavigate()

  const id = parseInt(orderId ?? '')
  if (!orderId || isNaN(id)) navigate(PagesUrl?.BORROW);;

  const {
    data: orderData,
    isLoading,
    isFetching,
  } = useGetOrderDetailsQuery({
    orderId: id,
  })
  const prices = useSelector(pricesSelector)

  return (
    <Layout customGrid={alternativeGridSystem} pageHeader={<NotificationLTV orderResponse={orderData?.order} />}>
      <LoanDetails prices={prices} isLoading={isLoading} order={orderData?.order} />
      <LoanTransactionsWidget orderId={id} />
      {orderData?.order?.repaymentMethod === "monthly-repay-interest" && <InterestMonthlyWidget orderId={id} data={orderData?.order} />}
      {orderData?.order?.repaymentMethod === "period-compounding" && <CompoundWidget orderId={id} data={orderData?.order} />}
      <AttarchmentWidget orderId={id} isLoading={isLoading} data={orderData?.order?.attachments} />
    </Layout>
  )
}

export default LoanScreen
