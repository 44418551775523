import { palette } from "lib/theme";
import React, { FC, useRef, useState, MouseEvent } from "react";
import styled, { css } from "styled-components";
import Icon from "../Icon";
import Portal from "../Portal";
import { Text } from "../Typography";

interface TooltipProps {
  text: React.ReactNode;
  placement?: "bottom" | "top" | "left" | "right";
  space?: number;
  visible?: boolean;
  displayAsText?: boolean;
  maxWidth?: number;
  autoDismiss?: boolean;
}

const position = (placement: "bottom" | "top" | "left" | "right") => ({
  current: placement,
  negate() {
    if (this.current === "left") return "right";
    else if (this.current === "right") return "left";
    else if (this.current === "top") return "bottom";
    else if (this.current === "bottom") return "top";
    else return this.current;
  },
  isHorizontal() {
    return this.current === "left" || this.current === "right";
  },
  isVertical() {
    return this.current === "top" || this.current === "bottom";
  },
});

const defaultPoint = () => ({
  x: 0,
  y: 0,
  reset(position: { x: number; y: number }) {
    this.x = position.x;
    this.y = position.x;
  },
  restrict(boundaries: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  }) {
    if (this.x < boundaries.left) this.x = boundaries.left;
    else if (this.x > boundaries.right) this.x = boundaries.right - 24;

    if (this.y < boundaries.top) this.y = boundaries.top;
    else if (this.y > boundaries.bottom) this.y = boundaries.bottom;
  },
});

const getPoint = (
  element: EventTarget & HTMLDivElement,
  tooltip: React.RefObject<HTMLDivElement>,
  placement: "bottom" | "top" | "left" | "right" = "bottom",
  space: number
): { x: number; y: number } => {
  if (!tooltip.current) return { x: 0, y: 0 };
  let recursiveCount = 0;
  const point = defaultPoint();
  const rect = element.getBoundingClientRect();
  const boundaries = {
    left: space,
    top: space,
    right: document.body.clientWidth - (tooltip.current.clientWidth + space),
    bottom: window.innerHeight - (tooltip.current.clientHeight + space),
  };

  return (function recursive(placement: "bottom" | "top" | "left" | "right"): {
    x: number;
    y: number;
  } {
    recursiveCount++;
    const pos = position(placement);

    const positions = {
      bottom: {
        x: rect.left + (element.offsetWidth - tooltip.current.offsetWidth) / 2,
        y: rect.bottom + space,
      },
      top: {
        x: rect.left + (element.offsetWidth - tooltip.current.offsetWidth) / 2,
        y: rect.top - (tooltip.current.offsetHeight + space),
      },
      left: {
        x: rect.left - (tooltip.current.offsetWidth + space),
        y: rect.top + (element.offsetHeight - tooltip.current.offsetHeight) / 2,
      },
      right: {
        x: rect.right + space,
        y: rect.top + (element.offsetHeight - tooltip.current.offsetHeight) / 2,
      },
    };

    const currentPosition = positions[placement];

    point.x = currentPosition.x;
    point.y = currentPosition.y;

    if (recursiveCount < 3) {
      if (
        (pos.isHorizontal() &&
          (point.x < boundaries.left || point.x > boundaries.right)) ||
        (pos.isVertical() &&
          (point.y < boundaries.top || point.y > boundaries.bottom))
      ) {
        point.reset(recursive(pos.negate()));
      }
    }

    point.restrict(boundaries);

    return point;
  })(placement);
};

const Tooltip: FC<TooltipProps> = ({
  text,
  placement = "bottom",
  space = 4,
  children,
  visible = true,
  displayAsText = false,
  maxWidth = 352,
  autoDismiss = true,
}) => {
  const [show, setShow] = useState(false);
  const positionRef = useRef({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);
  let timeoutEnter = setTimeout(() => {}, 0);

  const handleOnMouseOver = (e: MouseEvent<HTMLDivElement>) => {
    clearInterval(timeoutEnter);
    setShow(true);
    positionRef.current = getPoint(
      e.currentTarget,
      tooltipRef,
      placement,
      space
    );
  };

  const handleOnMouseOut = () => {
    timeoutEnter = setTimeout(() => {
      if (autoDismiss) setShow(false);
    }, 400);
  };

  const clonedElement = React.cloneElement(
    children as React.ReactElement<any>,
    {
      onMouseEnter: handleOnMouseOver,
      onMouseLeave: handleOnMouseOut,
    }
  );

  return (
    <>
      {clonedElement}
      <Portal>
        {visible ? (
          <TooltipStyled
            ref={tooltipRef}
            positionRef={positionRef}
            show={show}
            placement={placement}
            maxWidth={maxWidth}
          >
            <Arrow placement={placement} />
            <ContentContainer
              onMouseLeave={() => {
                if (!autoDismiss) setTimeout(() => setShow(false), 2000);
              }}
            >
              {(displayAsText ? displayAsText : typeof text === "string") ? (
                <Text size="small" opacity={0.7}>
                  {text}
                </Text>
              ) : (
                text
              )}
              {!autoDismiss ? (
                <Cross onClick={() => setShow(false)}>
                  <Icon size={16} name="Cross" changeColorOnHover={true} />
                </Cross>
              ) : (
                <></>
              )}
            </ContentContainer>
          </TooltipStyled>
        ) : (
          <></>
        )}
      </Portal>
    </>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Cross = styled.div``;

const Arrow = styled.div<{ placement: "bottom" | "top" | "left" | "right" }>`
  position: absolute;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;

  ${(props) =>
    props.placement === "bottom"
      ? css`
          top: -5px;
          left: 48.5%;
          border-width: 0 5px 5px;
          border-bottom-color: ${palette.night.light10};
        `
      : ""}

  ${(props) =>
    props.placement === "top"
      ? css`
          top: 100%;
          left: 48.5%;
          border-width: 5px 5px 0px;
          border-top-color: ${palette.night.light10};
        `
      : ""}

  &::before {
    content: "";
    left: 0px;
    position: absolute;
    backdrop-filter: blur(50px);
    border-radius: inherit;
    z-index: -4;
    width: 100%;
    height: 100%;
  }
`;

const TooltipStyled = styled.div<{
  show: boolean;
  placement: "bottom" | "top" | "left" | "right";
  positionRef: React.MutableRefObject<{ x: number; y: number }>;
  maxWidth?: number;
}>`
  position: fixed;
  white-space: pre-wrap;
  max-width: ${(props) => `${props.maxWidth}px` || '352px'};
  ${(props) => props?.positionRef?.current?.x && `left: ${props.positionRef.current.x}px`};
  top: ${(props) => props.positionRef.current.y}px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.02em;
  background: #292749;
  color: white;
  border-radius: 8px;
  z-index: 99999;
  display: inline-block;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? "visible" : "hidden")};

  transition-property: transform, opacity !important;
  transition-duration: 0.06s !important;
  transition-timing-function: cubic-bezier(0.33, 1, 0.61, 1) !important;
  transition-delay: 0.02s !important;

  transform-origin: ${(props) => position(props.placement).negate()};
  transform: scale(${(props) => (props.show ? 1 : 0.7)});

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px;
  gap: 8px;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  &::before {
    content: "";
    left: 0px;
    position: absolute;
    backdrop-filter: blur(50px);
    border-radius: inherit;
    z-index: -4;
    width: 100%;
    height: 100%;
  }
`;

export default Tooltip;
