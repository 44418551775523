import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Google: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9999 9.20419C17.9999 8.56601 17.9415 7.95237 17.833 7.36328H9.18359V10.8446H14.1261C13.9132 11.9696 13.2662 12.9228 12.2935 13.561V15.8192H15.2615C16.9981 14.2524 17.9999 11.9451 17.9999 9.20419Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.18357 18.0005C11.6632 18.0005 13.742 17.1945 15.2615 15.82L12.2935 13.5618C11.4711 14.1018 10.4192 14.4209 9.18357 14.4209C6.79164 14.4209 4.76705 12.8377 4.04487 10.7104H0.976685V13.0423C2.48782 15.9836 5.59358 18.0005 9.18357 18.0005Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.045 10.7103C3.86133 10.1703 3.75697 9.5935 3.75697 9.00031C3.75697 8.40713 3.86133 7.83031 4.045 7.29031V4.9585H0.976812C0.354825 6.1735 0 7.54804 0 9.00031C0 10.4526 0.354825 11.8271 0.976812 13.0421L4.045 10.7103Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.18357 3.57955C10.5319 3.57955 11.7425 4.03364 12.6943 4.92546L15.3283 2.34409C13.7379 0.891818 11.659 0 9.18357 0C5.59358 0 2.48782 2.01682 0.976685 4.95818L4.04488 7.29C4.76705 5.16273 6.79164 3.57955 9.18357 3.57955Z"
      fill="white"
    />
  </svg>
);

export default Google;
