import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Transaction: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={svgProps?.width || width}
    height={svgProps?.height || height}
    viewBox="0 0 98 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M50.2475 26.4079C47.5703 26.4085 45.0053 27.4806 43.1157 29.3846C41.2259 31.2888 40.1663 33.8691 40.1663 36.5571V38.0571H41.6663H58.8615H60.3615L60.3615 36.5572C60.3615 35.2231 60.1002 33.902 59.5923 32.6693C59.0845 31.4367 58.3399 30.3164 57.4006 29.373C56.4613 28.4295 55.3458 27.6816 54.1178 27.1726C52.8905 26.6638 51.5752 26.4039 50.2475 26.4079ZM50.2475 26.4079C50.2483 26.4079 50.2492 26.4079 50.25 26.4079V27.9079M50.2475 26.4079C50.2467 26.4079 50.2459 26.4079 50.2451 26.4079L50.25 27.9079M50.25 27.9079C51.3796 27.9042 52.4988 28.1252 53.5434 28.5582C54.588 28.9913 55.5376 29.6278 56.3376 30.4313C57.1376 31.2348 57.7723 32.1896 58.2054 33.2408C58.4467 33.8264 58.6228 34.4356 58.7314 35.0571H58.8615V36.5571H43.1663H41.6663V35.0571H41.7964C42.0999 33.3202 42.926 31.7052 44.1804 30.4412C45.7902 28.8191 47.9735 27.9079 50.25 27.9079ZM34.1673 97.6667L34.1673 43.3649L34.1673 37.7848L34.1672 37.7755C34.1412 33.5553 35.1752 29.3977 37.1722 25.6895L37.1725 25.6889L42.7104 15.3934L42.7105 15.3934L50.112 1.63319L57.5689 15.3949L57.5703 15.3975L63.1053 25.6597C63.1057 25.6604 63.1061 25.661 63.1064 25.6617C65.1004 29.3779 66.1519 33.534 66.1667 37.7598V43.3363V43.3371L66.1398 97.6667H34.1673Z"
      stroke="white"
      strokeWidth="3"
    />
    <path
      d="M8.41581 77.3416L8.44303 77.3191L8.46917 77.2954C13.06 73.1301 17.1405 68.4526 20.6329 63.3615C20.7831 63.1426 21.0866 63.0116 21.4794 63.1215C21.6643 63.1732 21.8 63.2664 21.879 63.3583C21.9466 63.4369 21.9902 63.531 21.99 63.6744L21.9465 97.1675C21.9462 97.4434 21.7224 97.6668 21.4465 97.6668H3.83354C3.5574 97.6668 3.33354 97.443 3.33354 97.1668L3.33354 88.1375L3.33354 88.1339C3.32858 86.0744 3.78136 84.039 4.65975 82.1734C5.53815 80.3077 6.82059 78.6576 8.41581 77.3416Z"
      stroke="white"
      strokeWidth="3"
    />
    <path
      d="M91.2261 77.2755L91.2519 77.2998L91.2788 77.3229C92.8078 78.6347 94.0413 80.2834 94.8877 82.153C95.7342 84.0228 96.1716 86.0654 96.1668 88.1341V88.1375V97.1668C96.1668 97.443 95.9429 97.6668 95.6668 97.6668H78.8593C78.5833 97.6668 78.3595 97.4432 78.3593 97.1671L78.3386 63.9402C78.3385 63.7966 78.3824 63.7025 78.4515 63.6232C78.5327 63.5301 78.6725 63.4355 78.863 63.3838C79.269 63.2737 79.5758 63.4126 79.7227 63.6332C83.0373 68.6101 86.895 73.1887 91.2261 77.2755Z"
      stroke="white"
      strokeWidth="3"
    />
    <rect x="0.166748" y="103.333" width="97.5" height="2.49999" fill={color} />
  </svg>
);

export default Transaction;
