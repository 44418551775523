import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
}

const RegisterCompanyRegistered: FC<Props> = () => {
  return (
    <RegisterCompanyRegisteredBox>
      <p>Your request has been submitted to our onboarding team.</p>
      <p>Someone from Abra will reach out to you very soon.</p>
    </RegisterCompanyRegisteredBox>
  );
};

const RegisterCompanyRegisteredBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export default RegisterCompanyRegistered;
