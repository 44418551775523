import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const CheckCurrency: FC<ISVG> = ({
  size = 24,
  width = 20,
  height = 12,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M1 5.5L6.67742 11L17 1"
      stroke={color}
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckCurrency;
