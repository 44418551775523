import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const TothemoonIcon: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0001 4.22339C15.8217 4.22811 11.8158 5.89062 8.86124 8.84618C5.90667 11.8017 4.24471 15.809 4.23999 19.9888C4.24353 24.1694 5.90497 28.1778 8.85967 31.1343C11.8144 34.0908 15.821 35.7539 20.0001 35.7587C24.1793 35.7539 28.1859 34.0908 31.1406 31.1343C34.0953 28.1778 35.7567 24.1694 35.7603 19.9888C35.7556 15.809 34.0936 11.8017 31.139 8.84618C28.1844 5.89062 24.1785 4.22811 20.0001 4.22339Z"
      fill="#F47A63"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 35.7587C20.0001 35.7587 20.0001 35.7587 20.0002 35.7587C24.1794 35.7539 28.186 34.0908 31.1407 31.1343C34.0954 28.1778 35.7568 24.1694 35.7603 19.9888C35.7556 15.809 34.0937 11.8017 31.1391 8.84618C28.1845 5.89062 24.1786 4.22811 20.0002 4.22339C20.0001 4.22339 20.0001 4.22339 20 4.22339V35.7587Z"
      fill="#DC6E59"
    />
    <path d="M20.2271 12.8175H19.3354V24.5646H20.2271V12.8175Z" fill="white" />
    <path
      d="M26.9462 12.8175H19.6836V17.1881H26.9462L23.1611 15.0028L26.9462 12.8175Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.8969 26.5839C29.9214 24.6706 25.3456 23.4452 20.2139 23.4452C14.8958 23.4452 10.1749 24.7612 7.21313 26.795C7.88087 28.049 8.73499 29.2091 9.75761 30.2322C12.4741 32.95 16.1577 34.4789 19.9999 34.4832C23.8421 34.4789 27.5257 32.95 30.2422 30.2322C31.322 29.152 32.2139 27.9189 32.8969 26.5839Z"
      fill="white"
    />
  </svg>
);

export default TothemoonIcon;
