import React from 'react';
import { useParams } from 'react-router-dom';
import useAssets from 'shared/useAssets';
import getUsDollar from 'shared/dollarFormat';
import { ExpenseSummary } from 'state/store/statementsApi';
import useCustodyStatement from './useCustodyStatement';
import { Container, Table, TableContainer, TableRow, Th, Td, Title } from './sharedStyles';

const CustodyStatementInvestmentExpenseSummary = () => {
  const {month, year} = useParams();
  const {expenseSummary} = useCustodyStatement({month, year});

  return (
    <Container>
      <Title>
        Investment Expense Summary YTD<small>**</small>
      </Title>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <Th>Category</Th>
              <Th>Amount</Th>
              <Th>Cost Basis</Th>
            </TableRow>
          </thead>
          <tbody>
            {expenseSummary.data && expenseSummary.data.map((record, index) => (
              <CustodySummaryRow key={index} record={record} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const CustodySummaryRow = ({record}: {record: ExpenseSummary}) => {
  const {getPriceFormattedI} = useAssets();
  
  return (
    <TableRow>
      <Td>{record.category}</Td>
      <Td>{getPriceFormattedI(record.amount.asset, record.amount?.amount ?? '0')}</Td>
      <Td>{getUsDollar(record.costBasisAmount?.amount)}</Td>
    </TableRow>
  );
};

export default CustodyStatementInvestmentExpenseSummary;