import React, { FC, forwardRef } from "react";
import * as Icons from "../../../assets/icons";
import { IIconProps } from "interfaces/components/atoms/Atom.interface";
import styled, { css } from "styled-components";
import { palette } from "lib/theme";
import { generateDataCyFullID } from "shared/locale-helper";

const Icon: FC<IIconProps> = forwardRef<HTMLSpanElement, IIconProps>(
  (props, ref) => {
    const {
      size,
      name,
      color = "white",
      width = size,
      height = size,
      bgColor,
      opacity,
      iconProps,
      onMouseEnter,
      onMouseLeave,
      dataCy = "",
      dataCyPrefix = "",
    } = props;
    const IconComponent = Icons[name];

    const generatedDataCy = generateDataCyFullID(
      dataCy,
      dataCyPrefix,
      name,
      "icon"
    );

    return IconComponent ? (
      <SpanStyled
        data-cy={generatedDataCy}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ width, height }}
        {...props}
      >
        <IconComponent
          ref={ref}
          size={size}
          color={color}
          width={width}
          height={height}
          opacity={opacity}
          bgColor={bgColor}
          svgProps={iconProps}
        />
      </SpanStyled>
    ) : (
      <></>
    );
  }
);

const SpanStyled = styled.span<IIconProps>`
  ${(props) =>
    props.changeColorOnHover
      ? css`
          cursor: pointer;
          &:hover {
            svg {
              path {
                fill: ${props.color === palette.darkBackgroundContrast.light70
                  ? palette.white.main
                  : props.color === palette.white.main || "white"
                  ? palette.accent.light
                  : props.color === palette.accent.light
                  ? palette.accent.lighter
                  : props.color};
              }
            }
          }
        `
      : ""}
`;

const IconStyled = styled(Icon)``;

export const IconBackgroundStyled = styled.div`
  height: 2.5rem;
  display: flex;
  flex: 0 0 2.5rem;
  justify-content: space-evenly;
  align-content: center;
  flex-direction: column;
  align-items: center;
  background: ${palette.background.elevation3};
  border-radius: 0.625rem;
  padding: 0.05rem;
  background-image: linear-gradient(
      ${palette.background.elevation3},
      ${palette.background.elevation3}
    ),
    radial-gradient(
      circle at top left,
      ${palette.accent.light15},
      ${palette.accent.light0}
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

export const IconBackground: FC<{ icon: string }> = ({ icon }) => {
  return (
    <IconBackgroundStyled style={{ width: "2.5rem" }}>
      <Icon name={icon} size={24} color={palette.accent.light} />
    </IconBackgroundStyled>
  );
};

export default IconStyled;
