import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const LoanDetailCanceled: FC<ISVG> = ({
  size = 120,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="120"
    height="121"
    viewBox="0 0 120 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M115.375 78.2247L19.184 109.183C17.7968 109.629 16.3104 108.867 15.864 107.48L2.5768 66.1952C2.1304 64.808 2.8928 63.3215 4.28 62.8751L100.471 31.9167C101.858 31.4703 103.345 32.2326 103.791 33.6198L117.078 74.9047C117.525 76.2919 116.762 77.7783 115.375 78.2247Z"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.6856 97.0816L10.1792 67.5449L25.1496 62.7266"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.0671 82.9863L37.0375 78.168"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.624 62.5684L99.5944 57.75"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.044 69.1055L109.475 73.5525L96.2471 77.8102"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M79.5784 45.2107L98.4048 39.1523L100.174 44.6498"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.608 58.3355C14.8248 54.4859 13.1848 49.7556 13.1848 44.6428C13.1848 31.7212 23.66 21.2461 36.5816 21.2461C49.5032 21.2461 59.9784 31.7212 59.9784 44.6428"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M71.7231 29.5316C79.1662 29.5316 85.1999 23.4979 85.1999 16.0549C85.1999 8.61185 79.1662 2.57812 71.7231 2.57812C64.2801 2.57812 58.2463 8.61185 58.2463 16.0549C58.2463 23.4979 64.2801 29.5316 71.7231 29.5316Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.4592 14.9977C62.9832 10.3457 66.9312 6.73047 71.7232 6.73047C73.4832 6.73047 75.1288 7.21763 76.5336 8.06562"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M67.3953 24.3146C65.2025 23.1634 63.5249 21.1651 62.7969 18.7539"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M80.1815 12.1289C80.7359 13.3217 81.0464 14.6521 81.0464 16.0545C81.0464 21.2033 76.8719 25.3777 71.7231 25.3777"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M89.5679 86.5273C94.3727 89.6257 97.5543 95.0258 97.5543 101.168C97.5543 110.783 89.7607 118.576 80.1463 118.576C70.5319 118.576 62.7383 110.783 62.7383 101.168C62.7383 98.9098 63.1687 96.7521 63.9511 94.7721"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M72.5583 111.004C74.6567 112.626 77.2896 113.591 80.1464 113.591C87.0072 113.591 92.5688 108.029 92.5688 101.168C92.5688 96.8228 90.332 93.0052 86.952 90.7852"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M68.5569 96.707C68.0225 98.0926 67.7241 99.5959 67.7241 101.17C67.7241 104.011 68.6785 106.629 70.2833 108.722"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.5815 26.6836C26.6631 26.6836 18.6223 34.7244 18.6223 44.6428C18.6223 47.978 19.5351 51.0988 21.1199 53.7756"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M54.4663 43.0422C53.7519 34.9598 47.6879 28.4142 39.8359 26.9766"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.8329 80.364C54.8393 83.4904 58.1889 85.2096 61.3153 84.2032L68.0209 82.0448C71.1473 81.0384 72.8657 77.6888 71.8601 74.5624C71.0961 72.1872 68.9305 70.5423 66.4377 70.4423L54.5833 69.9671C52.0905 69.8671 49.9249 68.2216 49.1609 65.8472C48.1545 62.7208 49.8737 59.3704 53.0001 58.364L59.7057 56.2056C62.8321 55.1992 66.1825 56.9184 67.1881 60.0448"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M53.6008 56.1133L63.1184 85.6867"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M57.9023 54.7266L67.4199 84.3002"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LoanDetailCanceled;
