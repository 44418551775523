import Layout from 'components/templates/Layout'
import Widget from 'components/templates/Widget'
import React from 'react'
import styled from 'styled-components'
import CustodyHeader from './CustodyHeader'
import CustodyInvestmentSummary from './CustodyInvestmentSummary'
import CustodyNavigationWidget from './CustodyNavigationWidget'
import CustodyPendingActivity from './CustodyPendingActivity'
import CustodyTokenSummary from './CustodyTokenSummary'
import CustodyTransactionDetails from './CustodyTransactionDetails'
import useCustodyRedirect from './extraCustodyHooks/useCustodyRedirect'
import useCustodyTerms from './useCustodyTerms'
import CustodyTermsOfService from './CustodyTermsOfService'
import usePermissions from 'shared/usePermissions'
import { palette } from 'lib/theme'
import CustodyPortfolioLtvNotification from 'pages/DefiBorrow/CustodyPortfolioLtvNotification'

const Custody = () => {

  const { isCustodyInvestEnabled, canBorrowCustody } = usePermissions();
  const { products } = useCustodyRedirect()

  useCustodyTerms()
  return (
  <Layout>
    {products && products.includes("Custody") &&
    <>
    <MarginContainer>
      {canBorrowCustody && <CustodyPortfolioLtvNotification />}
      <CustodyNavigationWidget>
        <CustodyHeader />
      </CustodyNavigationWidget>
    </MarginContainer>
    <MarginContainer>
      <Widget>
        <CustodyTokenSummary />
      </Widget>
    </MarginContainer>
    {isCustodyInvestEnabled ? (
      <MarginContainer>
        <Widget>
          <CustodyInvestmentSummary />
        </Widget>
      </MarginContainer>
    ) : null}
    <MarginContainer>
      <Widget>
        <CustodyPendingActivity />
      </Widget>
    </MarginContainer>
    <MarginContainer>
      <Widget minHeight='650px'>
        <CustodyTransactionDetails />
      </Widget>
    </MarginContainer>
    <CustodyTermsOfService />
    </>}
  </Layout>
  )
}

export const MarginContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
  @media print {
    background-color: white !important;
  }
`

export const ErrorMessage = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: #dadada;
  line-height: 0.7rem;
  padding: 10px;
  border-radius: 10px;
  background: ${palette.red.error};
`;

export const WarningMessage = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: #dadada;
  line-height: 0.7rem;
  padding: 10px;
  border-radius: 10px;
  background: #E9992899;
`;
export default Custody
