import Palette from "../../@types/theme/palette";

const palette: Palette = {
  accent: {
    main: "#6F2ACD",
    dark: "#292749",
    auxiliar: "rgb(131, 91, 208)",
    light: "#A399F6",
    lighter: "#BBB4F8",
    light0: "rgba(163, 153, 246, 0)",
    light10: "rgba(163, 153, 246, 0.1)",
    light15: "rgba(163, 153, 246, 0.15)",
    light20: "rgba(163, 153, 246, 0.20)",
    light25: "rgba(163, 153, 246, 0.25)",
    light80: "rgba(163, 153, 246, 0.8)",
    light100: "rgba(111, 42, 205, 1)",
  },
  night: {
    main: "rgba(212, 207, 255)",
    light10: "rgba(212, 207, 255, 0.1)",
    dark: "rgba(43, 43, 64, 1)",
  },
  secondary: {
    auxiliar: "rgb(87, 84, 114)",
    secondaryFont: "rgba(255, 255, 255, 0.7)",
  },
  black: {
    main: "#07041C",
    second: "rgba(0, 0, 0, 0)",
    light80: "rgba(7, 4, 28, 0.8)",
    light30: "rgba(7, 4, 28, 0.3)",
    light15: "rgba(7, 4, 28, 0.1)",
    light00: "rgba(19, 19, 43, 0)",
    light10: "rgba(19, 19, 43, 1)",
    transparent: "rgba(0,0,0,0)",
    popUpBackground: "#2b293e",
    disabledBackgroundToggle: "rgba(46, 41, 82, 0.5)",
    offBackgroundToggle: "rgba(46, 41, 82, 0.9)",
    onBackgroundProfile: "#13132B",
    mapInfoWindow: "#3b3a4e",
  },
  lightBackgroundContrast: {
    gray: "#B3B2BD",
    light: "#E6E6E8",
  },
  darkBackgroundContrast: {
    white: "#FFFFFF",
    transparent: "rgba(19, 19, 43, 1)",
    light70: "rgba(255, 255, 255, 0.7)",
    light50: "rgba(255, 255, 255, 0.5)",
    light40: "rgba(255, 255, 255, 0.4)",
    light20: "rgba(255, 255, 255, 0.2)",
    light30: "rgba(255, 255, 255, 0.3)",
    light15: "rgba(255, 255, 255, 0.15)",
    light10: "rgba(255, 255, 255, 0.1)",
    light001: "rgba(255, 255, 255, 0.01)",
    light0015: "rgba(255, 255, 255, 0.015)",
    light0030: "rgba(255, 255, 255, 0.030)",
    light5: "rgba(255, 255, 255, 0.05)",
    light0: "rgba(255, 255, 255, 0.0)",
    light01: "rgba(212, 207, 255, 0.1)",
  },
  red: {
    main: "#EC396B",
    light0: "rgba(236, 57, 107, 0)",
    light70: "rgba(236, 57, 107, 0.7)",
    light15: "rgba(236, 57, 107, 0.15)",
    light10: "rgba(236, 57, 107, 1)",
    error: "rgba(120, 52, 53, 1)"
  },
  orange: {
    main: "#E99928",
    light: "rgba(233, 153, 40, 0.2)",
  },
  green: {
    main: "#17B96B",
    night: "#33EA92",
    light: "rgba(23, 185, 107, 0.15)",
    light15: "rgba(23, 185, 107, 0.15)",
  },
  gray: {
    main: "rgba(255, 255, 255, 0.7)",
    second: "rgb(85,84,97)",
    light: "rgba(230, 230, 232, 1)",
    light20: "rgba(10, 10, 10, 0.29)",
  },
  white: {
    main: "#FFFFFF",
    light0: "rgba(255, 255, 255, 0)",
    light015: "rgba(255, 255, 255, 0.015)",
    light: "rgba(255, 255, 255, 0.05)",
    light10: "rgba(255, 255, 255, 0.1)",
    light20: "rgba(255, 255, 255, 0.2)",
    light70: "rgba(255, 255, 255, 0.7)",
    light80: "rgba(255, 255, 255, 0.8)",
  },
  purple: {
    main: "#9f7fff",
    light: "rgba(163, 153, 246, 1)",
    light0: "rgba(160, 127, 255, 0)",
    light80: "rgba(160, 127, 255, 0.08)",
    alternative: "#A399F6"
  },
  background: {
    elevation0: "#07041C",
    elevation1: "#13132B",
    elevation2: "#28283E",
    elevation3: "#1D1D43",
  },
  scrollGradient: {
    main: "rgba(19,19,43,0) 0%",
    secondary: "#13132B 100%",
  },
  charts: {
    balancePieChart: [
      "#17B6AB",
      "#6F2ACD",
      "#3189E1",
      "#F09440",
      "#BD36B1",
      "#15C771",
      "#16B4EB",
      "#EC6B75",
      "#8C2FC3",
      "#4F5DAE",
    ],
    allCryptoLineChartMain: "#7020FF",
    allCryptoLineChartArea: "rgba(112, 32, 255, 0.3)",
    allCryptoLineChartAccent: "#7220FF",
    allCryptoLineChartSecondary: "#A399F6",
    assetsDetails: ["#e13aff", "#6f2acd", "#5565df", "#1b6fcf"],
  },
  campaings: {
    default: "#422F8F",
    default2: "#613A7E",
    default3: "#366B77",
    default4: "#2D4A77",
    default5: "#7D3D78",
    default6: "#3944A7",
  },
};

export default palette;
