import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { MarketData } from "services/models";
import { MarketPlaceControllerApi } from "services/apis/market-place-controller-api";

const initialState = {
  paymentMethods: [],
  paymentMethodCreditDebit: {},
  loading: false,
} as MarketState;

interface MarketState {
  paymentMethods: MarketData[] | null | undefined;
  paymentMethodCreditDebit: {};
  loading: boolean;
}

const MarketService = new MarketPlaceControllerApi();

export const marketPlaceSlice = createSlice({
  name: "marketPlace",
  initialState,
  reducers: {
    setPaymentMethods: (
      state,
      action: PayloadAction<MarketData[] | undefined>
    ) => {
      state.paymentMethods = action.payload;
    },
    setPaymentMethodCreditDebit: (
      state,
      action: PayloadAction<{} | undefined>
    ) => {
      state.paymentMethodCreditDebit = action.payload;
    },
    setLoading: (state, action: any) => {
      state.loading = action.payload;
    },
  },
});

export const getMarketPaymentMethods =
  (country: string) => async (dispatch: any, getState: any) => {
    try {
      const { token } = getState().auth;
      dispatch(setLoading(true));
      const { data } = await MarketService.getPaymentOptions(token, country);
      const paymentCreditDebit = data?.marketplace?.paymentCategories.filter(
        (p) => p?.categoryType === "CreditDebitCard"
      )[0];
      dispatch(setPaymentMethods(data?.marketplace?.paymentCategories));
      dispatch(setPaymentMethodCreditDebit(paymentCreditDebit));
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const { setPaymentMethods, setPaymentMethodCreditDebit, setLoading } =
  marketPlaceSlice.actions;

export const marketPlaceReducer = marketPlaceSlice.reducer;
