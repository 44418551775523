import React, { FC, useCallback, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { palette } from "lib/theme";
import { Text } from "components/atoms/Typography";
import mParticle from "@mparticle/web-sdk";

interface TabProps {
  activeTab: string;
  label: string;
  onClick: any;
  dataCy?: string;
  disabled?: boolean;
  dataCyPrefix?: string;
}
interface TabsProps {
  children: any;
  width?: string;
  height?: string;
  activeTabName?: string;
  onClick?: any;
  tabContentProps?: any;
  reloadTab?: boolean;
  padding?: string;
  dataCy?: string;
  dataCyPrefix?: string;
  isTableParent?: boolean;
  hiddenTabs?: boolean;
}

const TabsHeader = styled.div`
  background: ${palette.accent.light10};
  border-radius: 8px;
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
  display: flex;
  ${({ width }) =>
    width &&
    css`
      max-width: ${width};
    `}
  & + div {
    height: 100%;
  }
`;

const TabHeader = styled.div<{ active: boolean; disabled: boolean }>`
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
    border-radius: 8px;
  }

  ${(props) =>
    props.active &&
    css`
      background: ${palette.accent.light25};
      border-radius: 8px;
    `}
`;

const TabContent = styled.div<{ isTableParent?: boolean }>`
  height: 100%;
  ${({ isTableParent }) =>
    isTableParent &&
    `
    margin: 0 -24px;
    &, & > div {
      max-height: inherit;
    }
  `}
`;

const Tab: FC<TabProps> = ({
  activeTab,
  label,
  onClick,
  dataCy,
  dataCyPrefix,
  disabled = false,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const onTabClick = () => {
    if (!disabled) {
      onClick(label);
    }
  };
  return (
    <TabHeader
      active={activeTab === label}
      disabled={disabled}
      onClick={onTabClick}
      data-cy={
        dataCy
          ? dataCy
          : `${dataCyPrefix ? dataCyPrefix + "_" : ""}${label
              ?.toLowerCase()
              .replace(/ /g, "_")}_tab`
      }
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Text
        color={
          disabled
            ? palette.darkBackgroundContrast.light70
            : isHover
            ? palette.white.main
            : activeTab === label
            ? palette.darkBackgroundContrast.white
            : palette.darkBackgroundContrast.light70
        }
        size="small"
      >
        {label}
      </Text>
    </TabHeader>
  );
};

const Tabs: FC<TabsProps> = ({
  children,
  width,
  height,
  activeTabName,
  onClick,
  tabContentProps,
  padding,
  isTableParent = false,
  dataCyPrefix,
  hiddenTabs,
}) => {
  const [activeTab, setActiveTab] = useState<string>("");
  const handleTabClicked = useCallback(
    (tab) => {
      onClick && onClick(tab);
      setActiveTab(tab);
    },
    [setActiveTab]
  );
  useEffect(() => {
    const tab = activeTabName ?? children[0]?.props?.label;
    setActiveTab(tab);
  }, [activeTabName]);

  return (
    <div style={{ height: "100%" }}>
      <div style={hiddenTabs ? { display: "none" } : { padding }}>
        <TabsHeader width={width || "100%"} height={height || "40px"}>
          {children &&
            Array.isArray(children) &&
            children.map((child: any) => {
              const { label } = child.props;
              const { disabled } = child.props;
              return (
                <Tab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  disabled={disabled}
                  onClick={handleTabClicked}
                  dataCyPrefix={dataCyPrefix}
                />
              );
            })}
          {children && !Array.isArray(children) && (
            <Tab
              activeTab={activeTab}
              key={children.props.label}
              label={children.props.label}
              disabled={children.props.disabled}
              onClick={handleTabClicked}
              dataCyPrefix={dataCyPrefix}
            />
          )}
        </TabsHeader>
      </div>

      <TabContent {...tabContentProps} isTableParent={isTableParent}>
        {children &&
          Array.isArray(children) &&
          children.map((child: any) => {
            if (child.props.label !== activeTab) return undefined;
            return child.props.children;
          })}
        {children && !Array.isArray(children) && children}
      </TabContent>
    </div>
  );
};
export default Tabs;
