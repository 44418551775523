import React from 'react';
import styled from 'styled-components';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from '@types/enums/Loading.enum';

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
`;

const SharedLoadingState = () => (
    <LoadingWrapper>
        <Loading showText={false} size={SpinnerSizeEnum.LARGE} />
    </LoadingWrapper>
);

export default SharedLoadingState;
