import React, { FC, useState, useEffect, forwardRef } from "react";

import Icon from "components/atoms/Icon";
import Image from "components/atoms/Image";
import Input from "components/atoms/Input/Input";
import { Text } from "components/atoms/Typography";
import Loading from "components/atoms/Loading/Loading";
import Alert from "components/molecules/Alert";

import { SpinnerSizeEnum } from "@types/enums/Loading.enum";
import { AlertType } from "components/molecules/Alert/Alert.interface";
import { IInputSelector } from "./interfaces";

import { palette } from "lib/theme";
import styled, { css } from "styled-components";

const InputSelector: FC<IInputSelector> = forwardRef<
  HTMLInputElement,
  IInputSelector
>(
  (
    {
      label,
      selectorLabel,
      additionalText,
      icon,
      className,
      selectorDisabled = false,
      readOnly = false,
      isVisibleRightArrow = true,
      loading = false,
      errorMessage = "",
      warningMessage = "",
      onChange,
      onSelect,
      InputSelectorProps,
      ...props
    },
    ref
  ) => {
    const {
      disabled = false,
      value,
      ...inputSelectorPropsRest
    } = InputSelectorProps || {};

    const [isAlertVisible, setIsAlertVisible] = useState(
      errorMessage?.length > 0 || warningMessage?.length > 0
    );
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlerrType] = useState<AlertType | "">("");

    useEffect(() => {
      const message: string =
        errorMessage?.length > 0
          ? errorMessage
          : warningMessage?.length > 0
          ? warningMessage
          : "";
      const type: AlertType | "" = errorMessage
        ? AlertType.ERROR
        : warningMessage
        ? AlertType.WARNING
        : "";
      setAlertMessage(message);
      setAlerrType(type);
      setIsAlertVisible(Boolean(message?.length > 0));
    }, [errorMessage, warningMessage]);

    return (
      <div>
        {isAlertVisible && (
          <Alert
            message={alertMessage}
            variant={alertType}
            isVisible={isAlertVisible}
          />
        )}
        <Container {...props}>
          <Input
            label={label}
            additionalText={additionalText}
            className={`input suffixSelector ${className ?? ""}`}
            readOnly={readOnly}
            showError={false}
            errorMessage={errorMessage}
            warningMessage={warningMessage}
            inputProps={{
              ...inputSelectorPropsRest,
              type: "text",
              value,
              disabled,
              onChange,
            }}
          />
          <Selector
            readOnly={readOnly}
            disabled={disabled || selectorDisabled}
            loading={loading}
            onClick={!readOnly ? onSelect : () => {}}
            errorMessage={errorMessage}
          >
            {selectorLabel && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                {icon && <Image circle src={icon} width={24} height={24} />}
                <Text
                  color={
                    loading || disabled
                      ? palette.accent.light25
                      : palette.accent.light
                  }
                >
                  {selectorLabel}
                </Text>
              </div>
            )}
            {loading && (
              <Loading
                showText={false}
                size={SpinnerSizeEnum.INPUT}
                showRoundSpinner
              />
            )}
            {!loading || isVisibleRightArrow ? (
              <Icon
                name="RightArrow"
                size={16}
                color={
                  loading || disabled
                    ? palette.accent.light25
                    : palette.accent.light
                }
              />
            ) : (
              <></>
            )}
          </Selector>
        </Container>
      </div>
    );
  }
);

const Container = styled.div`
  display: flex;
  gap: 0.125rem;
  & > div {
    flex: 1 auto;
  }
`;

const Selector = styled.div<{
  loading?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  errorMessage?: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.loading ? "space-between" : "space-between"};
  padding: 10px 8px 8px 8px;
  background: ${({ errorMessage }) =>
    errorMessage ? palette.red.light15 : palette.white.light10};
  border-radius: ${({ errorMessage }) =>
    errorMessage ? "0px 0px 8px 0px" : "0px 8px 8px 0px"};
  cursor: pointer;
  width: 100%;
  max-width: 124px;
  box-sizing: border-box;

  ${(props) =>
    props.disabled || props.readOnly
      ? css`
          background: ${palette.darkBackgroundContrast.light5};
          pointer-events: none;
        `
      : ""}

  ${(props) =>
    props.loading
      ? css`
          gap: 7px;
        `
      : ""}

  span {
    display: flex;
  }

  &:hover {
    ${Text} {
      cursor: ${(props) => (!props.readOnly ? "cursor" : "default")};
      color: ${palette.accent.lighter};
    }
    ${Icon} {
      path {
        fill: ${palette.accent.lighter};
      }
    }
  }
`;

export default InputSelector;
