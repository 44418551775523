import React, { FC, useState } from "react";
import styled, { keyframes } from "styled-components";
import { ImageProps } from "./Image.interface";

const pulse = keyframes`
  0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  }
  
  70% {
      transform: scale(1);
      box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
  }
  
  100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const SkeletonImage = styled.div<ImageProps>`
  background: #ddd;
  border-radius: ${({ circle }) => (circle ? "50%" : "")};
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.125);
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;

  transform: scale(1);
  animation: ${pulse} 2s infinite;
`;

SkeletonImage.defaultProps = {
  height: 32,
  width: 32,
};

const Image: FC<ImageProps> = ({
  src,
  alt,
  width = 32,
  height = 32,
  circle,
}) => {
  const [loaded, setLoaded] = useState(false);

  const handleImageLoaded = () => {
    setLoaded(true);
  };
  return (
    <>
      <img
        src={src}
        alt={alt}
        width={width}
        height={height}
        onLoad={handleImageLoaded}
        onError={handleImageLoaded}
        style={{
          borderRadius: circle ? "50%" : "",
          display: loaded ? "inline" : "none",
          objectFit: "cover",
        }}
      />
      {!loaded ? (
        <SkeletonImage width={width} height={width} circle={circle} />
      ) : null}
    </>
  );
};

export default Image;
