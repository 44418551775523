import { useGetAssetsQuery } from 'state/store/api';

const useAssetFormatHook = () => {

  const { data: assets, isLoading } = useGetAssetsQuery();

  const getAssetFromIdentifier = (identifier: string = '') => {
    return assets?.assets?.find(asset => asset.identifier == identifier.toUpperCase());
  };

  const getAssetFormatted = (identifier: string | undefined, amount: string | undefined | number) => {
    const asset = getAssetFromIdentifier(identifier);
    const digits = asset ? asset.fractionDigits : 8;
    const number = amount ? Number.parseFloat(amount.toString()) : 0;
    return Number(number.toFixed(digits));
  };
  return {
    getAssetFormatted,
    isLoading
  };
};

export default useAssetFormatHook;
