import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const RestrictedCruss: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 151 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M103.75 75.5001L146.83 32.42C148.39 30.86 148.39 28.3299 146.83 26.7699L124.23 4.17C122.67 2.61 120.14 2.61 118.58 4.17L75.5 47.2511L32.42 4.17C30.86 2.61 28.33 2.61 26.77 4.17L4.17098 26.7699C2.61098 28.3299 2.61098 30.86 4.17098 32.42L47.251 75.5001L4.17098 118.58C2.61098 120.14 2.61098 122.67 4.17098 124.23L26.77 146.829C28.33 148.389 30.86 148.389 32.42 146.829L75.5 103.748L118.58 146.829C120.14 148.389 122.67 148.389 124.23 146.829L146.83 124.23C148.39 122.67 148.39 120.14 146.83 118.58L103.75 75.5001Z"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M128.095 91.3738C129.61 86.3478 130.433 81.0229 130.433 75.5029C130.433 69.9839 129.61 64.6578 128.095 59.6328"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.3711 22.9082C86.3451 21.3932 81.0201 20.5703 75.5011 20.5703C69.9821 20.5703 64.6561 21.3932 59.6311 22.9082"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9059 59.6328C21.3919 64.6588 20.5679 69.9839 20.5679 75.5039C20.5679 81.0239 21.3909 86.3488 22.9059 91.3748"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M59.6299 128.095C64.6559 129.609 69.9809 130.432 75.4999 130.432C81.0199 130.432 86.3449 129.609 91.3709 128.094"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RestrictedCruss;
