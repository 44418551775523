import styled, { css } from 'styled-components';
import { palette } from 'lib/theme';
import { Text } from 'components/atoms/Typography';
import { NavigationItemProps } from './NavigationItem.interface';

const NavigationItemStyled = styled.div<NavigationItemProps>`
  color: ${palette.darkBackgroundContrast.white};
  width: 100%;
  ${({isSubFolder}) => isSubFolder ? 'height: 40px;' : 'height: 48px;'}
  ${({isSubFolder}) => isSubFolder ? 'padding-left: 20px;' : ''}
  position: relative;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  background: transparent;
  z-index: 1;
  position: relative;
  margin-bottom: 0.5rem;


  ${(props) =>
    props.active
      ? css`
          cursor: pointer;
          background: linear-gradient(270deg, ${palette.purple.light80} 0%, ${palette.purple.light0} 100%);
          background-repeat: no-repeat;
          &::after {
            content: '';
            position: absolute;
            background-color: '${palette.darkBackgroundContrast.white}';
            width: 1px;
            height: 48px;
            right: -1px;
          }
        `
      : css``}

  &:hover {
    cursor: pointer;

    ${Text} {
      color: ${palette.darkBackgroundContrast.white};
      opacity: 1;
    }
  }
`;

export { NavigationItemStyled };
