interface Navigator {
  userLanguage?: string;
  browserLanguage?: string;
  systemLanguage?: string;
  languages: readonly string[];
  language: string;
}

function uniq(arr: any) {
  return arr.filter(
    (el: any, index: any, self: any) => self.indexOf(el) === index
  );
}

function normalizeLocales(arr: any) {
  return arr.map((el: any) => {
    if (!el || el.indexOf("-") === -1 || el.toLowerCase() !== el) {
      return el;
    }

    const splitEl = el.split("-");
    return `${splitEl[0]}-${splitEl[1].toUpperCase()}`;
  });
}

function getUserLocalesInternal() {
  let languageList: any = [];

  if (typeof window !== "undefined") {
    const navigator: Navigator = window.navigator;

    if (navigator.languages) {
      languageList = languageList.concat(navigator.languages);
    }
    if (navigator.language) {
      languageList.push(navigator.language);
    }

    if (navigator.userLanguage) {
      languageList.push(navigator.userLanguage);
    }
    if (navigator.browserLanguage) {
      languageList.push(navigator.browserLanguage);
    }
    if (navigator.systemLanguage) {
      languageList.push(navigator.systemLanguage);
    }
  }

  languageList.push("en-US"); // Fallback

  return normalizeLocales(uniq(languageList));
}

export const getUserLocales = getUserLocalesInternal;

function getUserLocaleInternal() {
  return getUserLocales()[0];
}

export const getUserLocale = getUserLocaleInternal;

export default getUserLocale;

