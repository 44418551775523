import React from "react";
import Notification from "components/organisms/Notification";
import { Text } from "components/atoms/Typography";
import styled from "styled-components";
import palette from "lib/theme/palette";
import { LtvStatus } from "state/store/loanApi";

export interface Props {
    ltvStatus: LtvStatus | undefined | string;
}

const LtvNotification: React.FC<Props> = ({ ltvStatus }) => {

    let alert = undefined;

    if (ltvStatus === LtvStatus.LIQUIDATION) {
        alert = {
            title: "Abra Liquidation Alert",
            message: "Your loan-to-collateral value has breached the liquidation threshold. Review your loan dashboard to learn more.",
            type: "danger",
            icon: "Notification",
        };
    }
    if (ltvStatus === LtvStatus.WARNING) {
        alert = {
            title: "Abra LTV Alert",
            message: "Your loan-to-collateral value is very high. Post additional collateral or repay loan to avoid liquidation.",
            type: "warning",
            icon: "Notification",
        };
    }

    return (alert? <>
        <NotificationContainer>
            <Notification
                height={"100%"}
                icon={alert.icon}
                type={alert.type}
                backgroundColor={palette.night.light10 as string}
                withBorder>
                <RowContainer flexDirection="row">
                    <RowContainer flexDirection="column">
                    <Text size="large">{alert.title}</Text>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Text opacity={0.7}>
                        <span style={{ whiteSpace: "unset" }}>
                            {alert.message}
                        </span>
                        </Text>
                    </div>
                    </RowContainer>
                </RowContainer>
            </Notification>
        </NotificationContainer>
    </> : null);
}
const RowContainer = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? "row"};
  width: "100%";
`;
const NotificationContainer = styled.div`
  max-height: 230px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 3px;
`;
export default LtvNotification;