import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const QuestionCircle: FC<ISVG> = ({
  size = 14,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M14.25 8.5C14.25 11.9518 11.4518 14.75 8 14.75C4.54822 14.75 1.75 11.9518 1.75 8.5C1.75 5.04822 4.54822 2.25 8 2.25C11.4518 2.25 14.25 5.04822 14.25 8.5Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.5 7.13337C5.5 5.95515 6.61953 5 8 5C9.38047 5 10.5 5.95515 10.5 7.13337C10.5 8.3116 9.38047 9.26695 8 9.26695V10"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.94922 13C7.84922 13 7.74922 12.9789 7.65922 12.9388C7.56922 12.9096 7.48922 12.8484 7.41922 12.7781C7.27922 12.6376 7.19922 12.4468 7.19922 12.247C7.19922 12.1456 7.21922 12.0563 7.25922 11.9559C7.29922 11.8655 7.34922 11.7852 7.41922 11.7149C7.48922 11.6447 7.56922 11.5945 7.65922 11.5543C7.93922 11.4439 8.26922 11.5041 8.47922 11.7149C8.54922 11.7852 8.60922 11.8655 8.63922 11.9559C8.67922 12.0563 8.69922 12.1456 8.69922 12.247C8.69922 12.4468 8.61922 12.6386 8.47922 12.7781C8.33922 12.9197 8.14922 13 7.94922 13Z"
      fill={color}
    />
  </svg>
);

export default QuestionCircle;
