import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Union: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2.5C2.44728 2.5 2 2.94728 2 3.5V16.5C2 17.0527 2.44728 17.5 3 17.5H8C8.55228 17.5 9 17.9477 9 18.5C9 19.0523 8.55228 19.5 8 19.5H3C1.34272 19.5 0 18.1573 0 16.5V3.5C0 1.84272 1.34272 0.5 3 0.5H21C22.6573 0.5 24 1.84272 24 3.5V16.5C24 18.1573 22.6573 19.5 21 19.5H20V22.5C20 23.0523 19.5523 23.5 19 23.5H12C11.4477 23.5 11 23.0523 11 22.5V10.5C11 9.94771 11.4477 9.5 12 9.5H19C19.5523 9.5 20 9.94771 20 10.5V17.5H21C21.5527 17.5 22 17.0527 22 16.5V3.5C22 2.94728 21.5527 2.5 21 2.5H3ZM18 11.5V18.5V21.5H13V11.5H18ZM5 9.5C4.44772 9.5 4 9.94771 4 10.5C4 11.0523 4.44772 11.5 5 11.5H8C8.55228 11.5 9 11.0523 9 10.5C9 9.94771 8.55228 9.5 8 9.5H5Z"
      fill="white"
    />
  </svg>
);

export default Union;
