import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const LTVIndicatorDown: FC<ISVG> = ({
  size = 12,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
    <path
      d='M6 9.45679L-5.64473e-07 2.99997L12 2.99997L6 9.45679Z'
      fill={color}
    />
  </svg>
);

export default LTVIndicatorDown;