import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { palette, zIndex } from 'lib/theme';

import Icon from 'components/atoms/Icon';
import { Text } from 'components/atoms/Typography';

interface SideBarProps {
  show?: boolean;
  title?: string;
  titleMargin?: string;
  bodyMarginTop?: string;
  onClose?: () => void;
}

const Sidebar: FC<SideBarProps> = ({ show, title, onClose, children, titleMargin, bodyMarginTop }) => {
  const handleClose = () => {
    if (onClose) onClose();
  };
  const iconSize = 24;
  return ReactDOM.createPortal(
    <Wrapper className={show ? 'active' : ''}>
      <Container>
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              height: '1.5rem',
            }}
          >
            <div onClick={handleClose} style={{ cursor: 'pointer' }}>
              <Icon size={iconSize} name='CrossModal' />
            </div>
            <Text
              size='large'
              bold
              style={{
                textAlign: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
                letterSpacing: '-0.5px',
                margin: `${titleMargin}`,
              }}
            >
              {title}
            </Text>
            <div></div>
          </div>
          <div
            style={{
              flexGrow: 1,
              marginTop: bodyMarginTop ? bodyMarginTop : '24px',
            }}
          >
            {children}
          </div>
        </div>
      </Container>
    </Wrapper>,
    document.body
  );
};

export default Sidebar;

const Container = styled.div`
  height: calc(100vh - 5.65rem);
`;

const Wrapper = styled.div`
  width: 485px;
  height: calc(100vh - 2.5rem);
  background-color: ${palette.night.light10};
  backdrop-filter: blur(50px);
  padding: 1.5rem;
  margin: 1.25rem;
  border-radius: 0.5rem;

  position: fixed;
  top: 0;
  right: -200%;
  transition: right 0.4s cubic-bezier(0.33, 1, 0.61, 1);

  z-index: ${zIndex.sidebar};

  &.active {
    right: 0%;
  }

`;
