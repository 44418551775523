import React, { FC, useEffect, useState } from 'react';

import { Sizes } from './Borrow';
import { Heading, Text } from 'components/atoms/Typography';
import PanelWithHeader from 'components/molecules/PanelWithHeader';
import Currency from 'components/atoms/Number/Currency';
import { Number } from 'components/atoms/Number';
import AbraTable from 'components/organisms/AbraTable';
import Percent from 'components/atoms/Number/Percent';
import Tabs from 'components/molecules/Tabs';
import { CollateralDto, OrderDto, PricesDto, useGetAssetsQuery, useGetOrdersQuery } from 'state/store/api';
import Icon from 'components/atoms/Icon';
import { palette } from 'lib/theme';
import { Column } from 'components/organisms/AbraTable/interfaces';
import { DefaultSettings, PagesUrl } from 'lib/constants/config.constant';
import { TableSortDirection } from '@types/enums/Table.enum';
import LoadingStage from 'pages/LoadingStage';
import { capitalize } from 'shared';
import { useNavigate } from 'react-router-dom';
import Tooltip from 'components/atoms/Tooltip';
import Row from 'components/atoms/Row/Row';
import getAssetPrice from 'shared/getAssetPrice';
import { pricesSelector } from 'state/slice/prices.slice';
import { useSelector } from 'react-redux';

interface LoanDetailsItem {
  id?: string;
  orderId?: string;
  openDate?: string;
  assetName?: string;
  currentLtv: number;
  marginLineLtv: number;
  warningLtv: number;
  initialPrincipal?: number;
  initialPrincipalPrice?: number;
  currentBalance?: number;
  currentBalancePrice?: number;
  collateralAmount?: number;
  apr?: number;
  status?: string;
  term?: string;
  type?: string;
  duration?: string;
  maturity?: string;
  interestAccrued?: number;
  interestAccruedPrice?: number;
  closedDate?: string;
  currentPrincipal?: number;
  currentPrincipalPrice?: number;
  collaterals?: CollateralDto[];
}
interface LoanDetailsProps extends Sizes {}

const LoanDetails: FC<LoanDetailsProps> = (props) => {
  const prices = useSelector(pricesSelector);

  return (
    <PanelWithHeader width='100%' height='668px' title='Loan details' {...props}>
      <Tabs activeTabName='Active' width='633px' isTableParent>
        <div label='Active'>
          <LoanDetailsTable prices={prices ?? []} status={['accrual', 'reviewing', 'closing']} />
        </div>
        <div label='Closed'>
          <LoanDetailsTable prices={prices ?? []} status={['expired', 'settled', 'off', 'cancelled', 'closed']} closeDetails />
        </div>
      </Tabs>
    </PanelWithHeader>
  );
};

const LoanDetailsTable: FC<{
  prices: PricesDto[];
  status: string[];
  closeDetails?: boolean;
}> = ({ prices, status, closeDetails = false }) => {
  const navigate = useNavigate();
  const [items, setItems] = useState<LoanDetailsItem[]>([]);
  const { data: assets } = useGetAssetsQuery();

  const getAssetFromIdentifier = (identifier?: string) => {
    return assets?.assets?.find((asset) => asset.identifier === identifier);
  };
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('startDate');
  const [sortDirection, setSortDirection] = useState(TableSortDirection.DESCENDING);
  const recordsPerPage = DefaultSettings.RECORDS_PER_PAGE;

  const { data, isLoading, isFetching } = useGetOrdersQuery({
    type: 'loan',
    sortField: sort,
    sortDirection: sortDirection,
    page: page.toString(),
    size: recordsPerPage.toString(),
    status: status,
  });

  useEffect(() => {
    if (!data && !prices) return;

    const response = !data?.orders
      ? []
      : data.orders.map((item: OrderDto) => {
          const price = prices?.find((m) => m.identifier === item.currency)?.price;

          return {
            id: item.id,
            orderId: item.id1Token,
            currentLtv: parseFloat(item.currentLtv ?? '0'),
            marginLineLtv: parseFloat(item.marginLineLtv ?? '0'),
            warningLtv: parseFloat(item.warningLtv ?? '0'),
            openDate: item.startDate,
            assetName: item.currency?.toUpperCase(),
            initialPrincipal: getAssetPrice(getAssetFromIdentifier(item.currency), item.initialPrincipal),
            initialPrincipalPrice: parseFloat(item.initialPrincipal ?? '0') * parseFloat(price ?? '0'),
            currentBalance: getAssetPrice(getAssetFromIdentifier(item.currency), item.balance ?? '0'),
            currentBalancePrice: parseFloat(item.balance ?? '0') * parseFloat(price ?? '0'),
            collateralAmount: item.collateral?.reduce((previous, current) => {
              const collateralPrice = prices?.find((m) => m.identifier === current.moreInfo)?.price ?? '0';

              return previous + parseFloat(current.amount ?? '0') * parseFloat(collateralPrice);
            }, 0),
            collaterals: item.collateral ?? [],
            apr: parseFloat(item.interestRate ?? '0'),
            status: capitalize(item?.status ?? ''),
            term: capitalize(item?.termType ?? ''),
            maturity: item.maturity,
          } as LoanDetailsItem;
        });

    setItems(response);
  }, [data, prices]);

  const [columns, setColumns] = useState<Column[]>([
    {
      title: 'Loan id / Open date',
      enableSort: true,
      accessor: 'orderId',
    },
    {
      title: 'Current Balance',
      enableSort: true,
      accessor: 'currentPrincipal',
    },
    {
      title: 'Initial amount',
      enableSort: true,
      accessor: 'initialPrincipal',
    },
    {
      title: 'Collateral Amount',
      enableSort: false,
      accessor: 'collateralAmount',
    },
    {
      title: 'APR',
      enableSort: true,
      accessor: 'interestRate',
    },
    {
      title: 'Status',
      enableSort: true,
      accessor: 'status',
    },
    {
      title: 'Term',
      enableSort: true,
      accessor: 'termType',
    },
    {
      title: 'Maturity date',
      enableSort: true,
      accessor: 'maturity',
    },
  ]);

  const rows = items.map((item) => {
    const {
      orderId,
      currentLtv,
      marginLineLtv,
      warningLtv,
      openDate,
      assetName,
      initialPrincipal,
      initialPrincipalPrice,
      currentBalance,
      currentBalancePrice,
      apr,
      term,
      maturity,
      status,
      collateralAmount,
      collaterals,
    } = item;

    const hasWarning = currentLtv > marginLineLtv || currentLtv > warningLtv;

    return {
      schema: item,
      orderId: () => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <div>{orderId}</div>
          <Text size='tiny' opacity={0.7}>
            {openDate}
          </Text>
        </div>
      ),
      initialPrincipal: () => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            {initialPrincipal} <Text>{assetName}</Text>
          </div>
          <Text size='tiny' opacity={0.7}>
            <Currency value={initialPrincipalPrice} />
          </Text>
        </div>
      ),
      currentPrincipal: () => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            {currentBalance} <Text>{assetName}</Text>
          </div>
          <Text size='tiny' opacity={0.7}>
            <Currency value={currentBalancePrice} />
          </Text>
        </div>
      ),
      collateralAmount: () => (
        <Row gap='6px' alignItems='center'>
          <Text>
            <Currency value={collateralAmount} />
          </Text>
          {collaterals?.length ?? 0 > 0 ? (
            <Tooltip text={<CollateralTooltip list={collaterals ?? []} />}>
              <Icon size={16} name='WarningCircleSmall' color={palette.gray.main as string} />
            </Tooltip>
          ) : (
            <></>
          )}
        </Row>
      ),
      interestRate: () => (
        <Text>
          <Number value={apr} /> %
        </Text>
      ),
      status: () => (
        <Row gap='6px' alignItems='center'>
          <Text
            color={
              hasWarning
                ? currentLtv > warningLtv
                  ? palette.orange.main
                  : currentLtv > marginLineLtv
                  ? palette.red.main
                  : palette.white.main
                : palette.white.main
            }
          >
            {hasWarning ? (currentLtv > warningLtv ? 'LTV Warning' : currentLtv > marginLineLtv ? 'LTV Call' : status) : status}
          </Text>
          {hasWarning ? (
            <Tooltip
              text={
                currentLtv > warningLtv ? (
                  <LoanWarningTooltip current={currentLtv} limit={warningLtv} />
                ) : (
                  <LoanCallTooltip current={currentLtv} limit={marginLineLtv} />
                )
              }
            >
              <Icon size={16} name='WarningCircleSmall' color={palette.gray.main as string} />
            </Tooltip>
          ) : (
            <></>
          )}
        </Row>
      ),
      termType: () => <Text>{term}</Text>,
      maturity: () => <Text>{maturity ?? '-'}</Text>,
    };
  });

  const handlePaginationChange = (currentPage: number) => {
    setPage(currentPage);
  };

  const handleAscendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key === 'transactions' ? 'startDate' : key);
    setSortDirection(TableSortDirection.ASCENDING);
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: column.accessor === key ? TableSortDirection.ASCENDING : TableSortDirection.UNSORTED,
      }))
    );
    setPage(1);
  };

  const handleDescendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key === 'transactions' ? 'startDate' : key);
    setSortDirection(TableSortDirection.DESCENDING);
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: column.accessor === key ? TableSortDirection.DESCENDING : TableSortDirection.UNSORTED,
      }))
    );
    setPage(1);
  };

  const handleResetSort = (key: string, data: any, setTableData: any) => {
    setSort('startDate');
    setSortDirection(TableSortDirection.DESCENDING);
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: TableSortDirection.UNSORTED,
      }))
    );
    setPage(1);
  };

  const handleOnRowClick = (event: any, data: LoanDetailsItem): void => {
    navigate(`${PagesUrl.BORROW}/${data.id}${PagesUrl.LOAN_DETAILS}`);
  };

  const [showTransaction, setShowTransaction] = useState(false);
  const [transactionId, setTransactionId] = useState(0);
  const transactionRowClick = (_: any, schema: any) => {
    setTransactionId(schema.operationId);
    setShowTransaction(true);
  };

  return isLoading ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <LoadingStage loadingText=' ' />
    </div>
  ) : data?.orders?.length ? (
    <AbraTable
      columns={columns}
      data={rows}
      showPagination={true}
      showPaginationCaption={false}
      isLoading={isLoading}
      isSorting={isFetching}
      recordsPerPage={recordsPerPage}
      totalRecords={data?.paginationResponse?.totalRecords}
      onPaginationChange={handlePaginationChange}
      onAscendingSort={handleAscendingSort}
      onDescendingSort={handleDescendingSort}
      onResetSort={handleResetSort}
      onRowClick={handleOnRowClick}
      longTable
    />
  ) : (
    <EmptyStage />
  );
};

const EmptyStage = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Icon name='NoTransactions' size={150} />
      <div style={{ marginBottom: '8px' }}>
        <Heading variant='h3' color={palette.gray.main as string}>
          No details yet
        </Heading>
      </div>
    </div>
  </div>
);

const LoanWarningTooltip: FC<{ current: number; limit: number }> = ({ current, limit }) => {
  return (
    <Row direction='column'>
      <Text color={palette.gray.main}>Your collateral value is getting low.</Text>
      <Text color={palette.gray.main} bold>
        Current LTV: <Percent value={current} />
      </Text>
      <Text color={palette.gray.main} bold>
        Margin Call Limit: <Percent value={limit} />
      </Text>
      <br />
      <Text color={palette.gray.main}>Add collateral now to maintain a healthy loan. Otherwise we will start liquidating your collateral</Text>
    </Row>
  );
};

const LoanCallTooltip: FC<{ current: number; limit: number }> = ({ current, limit }) => {
  return (
    <Row direction='column'>
      <Text color={palette.gray.main}>Your collateral value is low.</Text>
      <Text color={palette.gray.main} bold>
        Current LTV: <Percent value={current} />
      </Text>
      <Text color={palette.gray.main} bold>
        Margin Call Limit: <Percent value={limit} />
      </Text>
      <br />
      <Text color={palette.gray.main}>Add collateral now to maintain a healthy loan. We are starting to liquidate your collateral</Text>
    </Row>
  );
};

const CollateralTooltip: FC<{ list: CollateralDto[] }> = ({ list }) => {
  return (
    <Row direction='column'>
      <Text color={palette.gray.main}>This is the total amount.</Text>
      <Text color={palette.gray.main}>Here is your collateral distibution:</Text>
      {list.map((item, index) => (
        <Text key={index} color={palette.gray.main} bold>
          {item.amount} {item.moreInfo?.toUpperCase()}
        </Text>
      ))}
    </Row>
  );
};

export default LoanDetails;
