export const isFunction = (fn: any) => typeof fn === 'function'

export const minMax = (items: any[]) => {
  return items.reduce((acc, val) => {
      acc[0] = ( acc[0] === undefined || val < acc[0] ) ? val : acc[0]
      acc[1] = ( acc[1] === undefined || val > acc[1] ) ? val : acc[1]
      return acc;
  }, []);
}

export const getTextWidth = (text: string, font: string | null) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font || getComputedStyle(document.body).font;
  return context?.measureText(text).width;
}