import { QuestionCircle, QuestionCircleBig } from 'assets/icons';
import Button from 'components/atoms/Button';
import Modal from 'components/organisms/Modal';
import { toast, ToastType } from 'components/organisms/Toast';
import { TypesNamesEnum } from 'enums/Button.enum';
import React, { FC, useState } from 'react';
import { TransferAddress, useSetAddressAsVerifiedMutation } from 'state/store/withdrawApi';
import styled from 'styled-components';

interface ConfirmValidAddressProps {
  address?: TransferAddress;
  markAsVerified: (address: TransferAddress) => void;
  button?: boolean;
}
const ConfirmValidAddress: FC<ConfirmValidAddressProps> = ({ address, markAsVerified, button }) => {
  const possibleToValidate = address && !address.verified && address.usedWithdrawCompleted;
  const [confirm, setConfirm] = useState(false);
  const [setAddressAsVerified, requestInformation] = useSetAddressAsVerifiedMutation();

  const confirmAddressVerified = async () => {
    if (address) {
      await setAddressAsVerified(address);
      markAsVerified(address);
      setConfirm(false);
      toast.show({
        type: ToastType.Success,
        title: 'Your address was confirmed',
        content: 'Restrictions regarding that address have been raised',
      });
    }
  };
  return (
    <>
      <Modal visible={confirm} header='Confirm funds received'>
        <Modal.Body>
          <Paragraph>Please confirm the funds have been received by the intended wallet.</Paragraph>
          <Paragraph>Once you confirm, the address will be marked as verified and restrictions will be lifted.</Paragraph>
        </Modal.Body>
        <Modal.Footer>
          <Button height='44' buttonType={TypesNamesEnum.SECONDAY} label='Cancel' onClick={() => setConfirm(false)} />
          <Button height='44' buttonType={TypesNamesEnum.ACCENT} label='Confirm' onClick={confirmAddressVerified} isLoading={requestInformation.isLoading} />
        </Modal.Footer>
      </Modal>
      {button && <ConfirmValidAddressButton possibleToValidate={possibleToValidate} setConfirm={setConfirm} />}
      {!button && <ConfirmValidAddressText possibleToValidate={possibleToValidate} setConfirm={setConfirm} />}
    </>
  );
};

interface ChildProps {
  possibleToValidate?: boolean;
  setConfirm: (arg: boolean) => void;
}
const ConfirmValidAddressButton: FC<ChildProps> = ({ possibleToValidate, setConfirm }) => {
  return possibleToValidate ? (
    <ButtonConfirmFunds onClick={() => setConfirm(true)}>
      <div>
        <p>Confirm Receipt of Funds </p>
      </div>
    </ButtonConfirmFunds>
  ) : null;
};

const ConfirmValidAddressText: FC<ChildProps> = ({ possibleToValidate, setConfirm }) => {
  return possibleToValidate ? <ActionBox onClick={() => setConfirm(true)}>Confirm funds received?</ActionBox> : null;
};

const ButtonConfirmFunds = styled.div`
  display: flex;
  padding: 1vh 1vw;
  cursor: pointer;
  background-color: #d81a50;
  word-wrap: break-word;
  align-items: center;
  min-width: 150px;
  justify-content: center;
  border-radius: 8px;
  gap: 1vw;
  transition: all 1s ease-in;
  flex-wrap: wrap;
  p {
    word-break: break-word;
    word-wrap: break-word;
    font-size: 0.6rem;
    font-weight: bold;
  }
  &:hover {
    background-color: #d81a50bb;
  }
`;
const ButtonConfirmFundsIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  min-height: 25px;
`;
const Paragraph = styled.p`
  font-size: 0.8rem;
  margin-top: 3vh;
  opacity: 0.9;
`;
const ActionBox = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  opacity: 0.9;
  color: #eb92ff;
  cursor: pointer;
  &:hover {
  }
`;
export default ConfirmValidAddress;
