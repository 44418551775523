/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApiError } from '../models';
import { NotificationsResponse } from '../models';
import { ResponseBase } from '../models';
import { UpdateNotificationRequest } from '../models';
/**
 * NotificationsControllerApi - axios parameter creator
 * @export
 */
export const NotificationsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user notifications
         * @param {string} token 
         * @param {string} notificationStatus Notification Status
         * @param {string} [sortField] Sort Field
         * @param {string} [sortDirection] Sort Direction
         * @param {string} [page] Page number
         * @param {string} [size] Number of records per page
         * @param {string} [daysBefore] Number of days before today to fetch the data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (token: string, notificationStatus: string, sortField?: string, sortDirection?: string, page?: string, size?: string, daysBefore?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getNotifications.');
            }
            // verify required parameter 'notificationStatus' is not null or undefined
            if (notificationStatus === null || notificationStatus === undefined) {
                throw new RequiredError('notificationStatus','Required parameter notificationStatus was null or undefined when calling getNotifications.');
            }
            const localVarPath = `/notifications/{notificationStatus}`
                .replace(`{${"notificationStatus"}}`, encodeURIComponent(String(notificationStatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (daysBefore !== undefined) {
                localVarQueryParameter['daysBefore'] = daysBefore;
            }

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update notification status
         * @param {UpdateNotificationRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications: async (body: UpdateNotificationRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNotifications.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling updateNotifications.');
            }
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsControllerApi - functional programming interface
 * @export
 */
export const NotificationsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user notifications
         * @param {string} token 
         * @param {string} notificationStatus Notification Status
         * @param {string} [sortField] Sort Field
         * @param {string} [sortDirection] Sort Direction
         * @param {string} [page] Page number
         * @param {string} [size] Number of records per page
         * @param {string} [daysBefore] Number of days before today to fetch the data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(token: string, notificationStatus: string, sortField?: string, sortDirection?: string, page?: string, size?: string, daysBefore?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<NotificationsResponse>>> {
            const localVarAxiosArgs = await NotificationsControllerApiAxiosParamCreator(configuration).getNotifications(token, notificationStatus, sortField, sortDirection, page, size, daysBefore, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update notification status
         * @param {UpdateNotificationRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotifications(body: UpdateNotificationRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await NotificationsControllerApiAxiosParamCreator(configuration).updateNotifications(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * NotificationsControllerApi - factory interface
 * @export
 */
export const NotificationsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get user notifications
         * @param {string} token 
         * @param {string} notificationStatus Notification Status
         * @param {string} [sortField] Sort Field
         * @param {string} [sortDirection] Sort Direction
         * @param {string} [page] Page number
         * @param {string} [size] Number of records per page
         * @param {string} [daysBefore] Number of days before today to fetch the data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(token: string, notificationStatus: string, sortField?: string, sortDirection?: string, page?: string, size?: string, daysBefore?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<NotificationsResponse>> {
            return NotificationsControllerApiFp(configuration).getNotifications(token, notificationStatus, sortField, sortDirection, page, size, daysBefore, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update notification status
         * @param {UpdateNotificationRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotifications(body: UpdateNotificationRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return NotificationsControllerApiFp(configuration).updateNotifications(body, token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsControllerApi - object-oriented interface
 * @export
 * @class NotificationsControllerApi
 * @extends {BaseAPI}
 */
export class NotificationsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get user notifications
     * @param {string} token 
     * @param {string} notificationStatus Notification Status
     * @param {string} [sortField] Sort Field
     * @param {string} [sortDirection] Sort Direction
     * @param {string} [page] Page number
     * @param {string} [size] Number of records per page
     * @param {string} [daysBefore] Number of days before today to fetch the data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsControllerApi
     */
    public async getNotifications(token: string, notificationStatus: string, sortField?: string, sortDirection?: string, page?: string, size?: string, daysBefore?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<NotificationsResponse>> {
        return NotificationsControllerApiFp(this.configuration).getNotifications(token, notificationStatus, sortField, sortDirection, page, size, daysBefore, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update notification status
     * @param {UpdateNotificationRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsControllerApi
     */
    public async updateNotifications(body: UpdateNotificationRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return NotificationsControllerApiFp(this.configuration).updateNotifications(body, token, options).then((request) => request(this.axios, this.basePath));
    }
}
