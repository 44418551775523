import React from "react";
import LoadingStage from "pages/LoadingStage";

const LoadingScreen = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "608px",
    }}
  >
    <LoadingStage />
  </div>
);

export default LoadingScreen;
