import { useEffect, useState } from 'react';
import { TransactionDetails, useGetTransactionDetailsQuery } from 'state/store/withdrawApi';
import { capitalize } from 'shared';

const useTransaction = (operationId: number) => {
  const transactionDetails = useGetTransactionDetailsQuery(operationId, { skip: !operationId, refetchOnMountOrArgChange: true });
  const [transaction, setTransaction] = useState<TransactionDetails>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const principalMapping = (text: string) => {
    if (text.toLowerCase() == 'principle') return 'principal';
    return text;
  };
  useEffect(() => {
    setIsError(transactionDetails.isError);
    setIsLoading(transactionDetails.isLoading || transactionDetails.isFetching);
    if (transactionDetails.currentData) {
      setTransaction(transactionDetails.currentData.transaction);
    }
  }, [transactionDetails]);

  const getType = (type: string) => {
    return type
      .split('-')
      .map((piece) => principalMapping(piece))
      .map((piece) => capitalize(piece))
      .join(' ');
  };

  return {
    isLoading,
    transaction,
    getType,
    isError,
  };
};

export default useTransaction;
