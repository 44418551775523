import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const NoMatches: FC<ISVG> = ({
  size = 150,
  width = size,
  height = size,
  color = "white",
  svgProps,
}) => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.7339 122.148C95.6184 122.148 121.466 96.301 121.466 64.4166C121.466 32.5321 95.6184 6.68457 63.7339 6.68457C31.8495 6.68457 6.00195 32.5321 6.00195 64.4166C6.00195 96.301 31.8495 122.148 63.7339 122.148Z"
      stroke={color}
      strokeWidth="3.24"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M141.952 141.433L141.248 142.137C138.916 144.469 135.134 144.469 132.802 142.137L107.684 117.018L116.833 107.868L141.952 132.987C144.285 135.32 144.285 139.101 141.952 141.433Z"
      stroke={color}
      strokeWidth="3.24"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M115.839 108.863L108.373 101.398"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.871 115.831L101.405 108.366"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.8408 76.0219L93.4762 44.3857"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.7764 81.6456L67.5879 50.834"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.7334 18.6289C89.0208 18.6289 109.521 39.1284 109.521 64.4166C109.521 77.8197 103.763 89.8774 94.5838 98.2511"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.5674 40.6862C30.401 31.0788 39.6698 23.7879 50.6696 20.5195"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.4537 109.714C68.2602 110.037 66.0166 110.204 63.734 110.204C38.4466 110.204 17.9463 89.704 17.9463 64.4158C17.9463 59.6862 18.6632 55.1244 19.9948 50.833"
      stroke={color}
      strokeWidth="1.62"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NoMatches;
