import QRCode from 'qrcode.react'
import React from 'react'

interface QRCodeProps {
  address: string
}
const CustodyQRCode: React.FC<QRCodeProps> = ({address}) => (
  <QRCode value={address} level='Q' size={250}></QRCode>
)

export default CustodyQRCode
