import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const RightArrowAlt: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => {
  return (
    <svg
      width={svgProps?.width || width}
      height={svgProps?.height || height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M7.00496 11H14.595L11.295 7.71C11.1067 7.52169 11.0009 7.2663 11.0009 7C11.0009 6.7337 11.1067 6.4783 11.295 6.29C11.4833 6.10169 11.7387 5.99591 12.005 5.99591C12.2713 5.99591 12.5267 6.10169 12.715 6.29L17.715 11.29C17.806 11.3851 17.8774 11.4972 17.925 11.62C18.025 11.8635 18.025 12.1365 17.925 12.38C17.8774 12.5027 17.806 12.6149 17.715 12.71L12.715 17.71C12.622 17.8037 12.5114 17.8781 12.3895 17.9289C12.2677 17.9797 12.137 18.0058 12.005 18.0058C11.8729 18.0058 11.7422 17.9797 11.6204 17.9289C11.4985 17.8781 11.3879 17.8037 11.295 17.71C11.2012 17.617 11.1268 17.5064 11.0761 17.3846C11.0253 17.2627 10.9992 17.132 10.9992 17C10.9992 16.868 11.0253 16.7373 11.0761 16.6154C11.1268 16.4936 11.2012 16.383 11.295 16.29L14.595 13H7.00496C6.73974 13 6.48538 12.8946 6.29785 12.7071C6.11031 12.5196 6.00496 12.2652 6.00496 12C6.00496 11.7348 6.11031 11.4804 6.29785 11.2929C6.48538 11.1054 6.73974 11 7.00496 11Z"
        fill={color}
      />
    </svg>
  );
};

export default RightArrowAlt;
