import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Transaction } from "services/models";
import { TransactionsControllerApi } from "services";
import { toast, ToastType } from "components/organisms/Toast";
import { RecurringTransactionsControllerApi } from "services/apis/recurring-transactions-controller-api";
import { OrdersControllerApi } from "services/apis/orders-controller-api";
import { getText } from "shared/locale-helper";

const initialState = {
  loading: false,
  completed: { transactions: [], paginationResponse: {} },
  completedError: false,
  open: { orders: [], paginationResponse: {} },
  openOrdersDetail: [],
  loadingOrderDetail: false,
  openError: false,
  scheduled: { transactions: [], paginationResponse: {} },
  scheduleError: false,
  exported: false,
  interestBreakdown: [],
  earnCompleted: { transactions: [], paginationResponse: {} },
  earnCompletedError: false,
  earnPending: { transactions: [], paginationResponse: {} },
  earnPendingError: false,
} as {
  loading: boolean;
  completed: { transactions: Transaction[]; paginationResponse: any };
  completedError: boolean;
  open: { orders: Order[]; paginationResponse: any };
  openOrdersDetail: OrderDetail[];
  loadingOrderDetail: boolean;
  openError: boolean;
  scheduled: { transactions: Transaction[]; paginationResponse: any };
  scheduleError: boolean;
  exported: boolean;
  interestBreakdown: Transaction[];
  earnCompleted: { transactions: Transaction[]; paginationResponse: any };
  earnCompletedError: boolean;
  earnPending: { transactions: Transaction[]; paginationResponse: any };
  earnPendingError: boolean;
};

export const TRANSACTIONSTYPE = {
  Completed: "completed",
  Pending: "pending",
  Open: "open",
  Scheduled: "scheduled",
};

const TransactionsService = new TransactionsControllerApi();
const RecurringTxrService = new RecurringTransactionsControllerApi();
const OrderService = new OrdersControllerApi();
export const getCompleted =
  (
    asset?: string,
    transactionType?: string,
    sortField?: string,
    sortDirection?: string,
    page: string = "1",
    size: string = "5"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setCompleted({ transactions: [], paginationResponse: {} }));
      const { token } = getState().auth;
      const { data } = await TransactionsService.getTransactions(
        token,
        TRANSACTIONSTYPE.Completed,
        transactionType,
        asset,
        sortField,
        sortDirection,
        page,
        size
      );
      const transactions: Transaction[] = data?.transactions ?? new Array(0);
      const pagination = data?.paginationResponse;

      dispatch(
        setCompleted({
          transactions: transactions,
          paginationResponse: pagination,
        })
      );
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getRecurringTransactions =
  (transactionId: string, limit: string = "1000000") =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setRecurringTransactions([]));
      const { token } = getState().auth;
      const { data } = await RecurringTxrService.getRecentTransactions(
        token,
        transactionId,
        limit
      );
      const transactions: Transaction[] = data?.transactions ?? new Array(0);
      dispatch(setRecurringTransactions(transactions));
    } catch (error) {
      console.warn(error);
    } finally {
      console.warn("finally");
    }
  };

export const getScheduled =
  (
    asset?: string,
    transactionType?: string,
    sortField?: string,
    sortDirection?: string,
    page: string = "1",
    size: string = "5"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setScheduled({ transactions: [], paginationResponse: {} }));
      const { token } = getState().auth;
      const { data } = await TransactionsService.getTransactions(
        token,
        TRANSACTIONSTYPE.Scheduled,
        transactionType,
        asset,
        sortField,
        sortDirection,
        page,
        size
      );
      const transactions: Transaction[] = data?.transactions ?? new Array(0);
      const pagination = data?.paginationResponse;

      dispatch(
        setScheduled({
          transactions: transactions,
          paginationResponse: pagination,
        })
      );
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  export const getEarnCompleted =
  (
    asset?: string,
    transactionType?: string,
    sortField?: string,
    sortDirection?: string,
    page: string = "1",
    size: string = "5"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setEarnCompleted({ transactions: [], paginationResponse: {} }));
      const { token } = getState().auth;
      const { data } = await TransactionsService.getTransactions(
        token,
        TRANSACTIONSTYPE.Completed,
        transactionType,
        asset,
        sortField,
        sortDirection,
        page,
        size
      );
      const transactions: Transaction[] = data?.transactions ?? new Array(0);
      const pagination = data?.paginationResponse;

      dispatch(
        setEarnCompleted({
          transactions: transactions,
          paginationResponse: pagination,
        })
      );
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getEarnPendings =
  (
    asset?: string,
    transactionType?: string,
    sortField?: string,
    sortDirection?: string,
    page: string = "1",
    size: string = "5"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setEarnPending({ transactions: [], paginationResponse: {} }));
      const { token } = getState().auth;
      const { data } = await TransactionsService.getTransactions(
        token,
        TRANSACTIONSTYPE.Pending,
        transactionType,
        asset,
        sortField,
        sortDirection,
        page,
        size
      );
      const transactions: Transaction[] = data?.transactions ?? new Array(0);
      const pagination = data?.paginationResponse;

      dispatch(
        setEarnPending({
          transactions: transactions,
          paginationResponse: pagination,
        })
      );
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export interface Order {
  orderId: string;
  orderType: string;
  orderStatus: string;
  originAmount: {
    currency: string;
    value: string;
  };
  destinationCurrency: string;
  remainingAmount: {
    currency: string;
    value: string;
  };
  submissionTime: string;
  completionTime: string;
  estimatedRewardAmount: {
    currency: string;
    value: string;
  };
  estimatedSteps: {
    firstStepRate: any;
    secondStepRate: any;
  };
}

export interface OrderDetail {
  originAmount: {
    currency: string;
    value: string;
  };
  destinationAmount: {
    currency: string;
    value: string;
  };
  filledAmount: {
    currency: string;
    value: string;
  };
  conversionRate: {
    base: string;
    quote: string;
    rate: string;
  };
  estimatedOriginAmount: {
    currency: string;
    value: string;
  };
  estimatedDestinationAmount: {
    currency: string;
    value: string;
  };
  stepStatus: string;
  creationTime: string;
  transactionId: string;
}
export const getOpen =
  (
    asset?: string,
    transactionType?: string,
    sortField?: string,
    sortDirection?: string,
    page: string = "1",
    size: string = "5"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setOpen({ orders: [], paginationResponse: {} }));
      const { token } = getState().auth;
      const { data } = await OrderService.getOpenOrders(
        token,
        asset,
        sortField,
        sortDirection,
        page,
        size,
        ["open"]
      );

      const orders: Order[] = data?.orders ?? new Array(0);
      const pagination = data?.paginationResponse;

      dispatch(setOpen({ orders: orders, paginationResponse: pagination }));
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getOpenOrderDetail =
  (orderId: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoadingOrderDetail(true));
      dispatch(setOpenOrderDetail({ openOrdersDetail: [] }));
      const { token } = getState().auth;
      const { data } = await OrderService.getOrderById(token, orderId);
      const openOrdersDetail: OrderDetail[] =
        data?.order?.steps ?? new Array(0);
      dispatch(setOpenOrderDetail({ openOrdersDetail }));
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoadingOrderDetail(false));
    }
  };

export const sendTransactionsEmail =
  (email: string = "", year: string = "") =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const { token } = getState().auth;

      const data = await TransactionsService.sendTransactionsEmail(
        { email, year },
        token
      );

      if (data?.message === "ok") {
        dispatch(setExported(true));
      }
    } catch (err) {
      toast.show({
        type: ToastType.Fail,
        title: "Error",
        content: "An error has occurred while processing your request",
      });
    } finally {
      toast.show({
        type: ToastType.Success,
        title: "Your report is on the way!",
        content: getText(
          "check_your_spam_folder_or_contact_support_abra_com_if_you_have_not_received"
        ),
      });
      dispatch(setLoading(false));
    }
  };

export const getInterestBreakdownById =
  (transactionId: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setInterestBreakdown([]));
      const { token } = getState().auth;
      const { data } = await TransactionsService.getInterestBreakdown(
        token,
        transactionId
      );
      const interestList: Transaction[] =
        data?.interestBreakdown ?? new Array(0);
      dispatch(setInterestBreakdown(interestList));
    } catch (error) {
      console.warn(error);
    } finally {
      console.warn("finally");
    }
  };

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setCompleted: (
      state,
      action: PayloadAction<{
        transactions: Transaction[];
        paginationResponse: any;
      }>
    ) => {
      state.completed = action.payload;
    },
    setOpen: (
      state,
      action: PayloadAction<{
        orders: Order[];
        paginationResponse: any;
      }>
    ) => {
      state.open = action.payload;
    },
    setOpenOrderDetail: (
      state,
      action: PayloadAction<{
        openOrdersDetail: OrderDetail[];
      }>
    ) => {
      state.openOrdersDetail = action.payload.openOrdersDetail;
    },
    setLoadingOrderDetail: (state, action: PayloadAction<boolean>) => {
      state.loadingOrderDetail = action.payload;
    },
    setScheduled: (
      state,
      action: PayloadAction<{
        transactions: Transaction[];
        paginationResponse: any;
      }>
    ) => {
      state.scheduled = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setExported: (state, action: PayloadAction<boolean>) => {
      state.exported = action.payload;
    },
    setInterestBreakdown: (state, action: any) => {
      state.interestBreakdown = action.payload;
    },
    setRecurringTransactions: (state, action: any) => {
      state.recurringTransactions = action.payload;
    },
    setEarnCompleted: (
      state,
      action: PayloadAction<{
        transactions: Transaction[];
        paginationResponse: any;
      }>
    ) => {
      state.earnCompleted = action.payload;
    },
    setEarnPending: (
      state,
      action: PayloadAction<{
        transactions: Transaction[];
        paginationResponse: any;
      }>
    ) => {
      state.earnPending = action.payload;
    },
  },
});

export const {
  setCompleted,
  setOpen,
  setScheduled,
  setLoading,
  setExported,
  setInterestBreakdown,
  setRecurringTransactions,
  setOpenOrderDetail,
  setLoadingOrderDetail,
  setEarnCompleted,
  setEarnPending,
} = transactionsSlice.actions;

export const transactionsReducer = transactionsSlice.reducer;
