import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "state/store"

interface PermissionsState {
  isAccountManager: boolean
}
const initialState: PermissionsState = {
  isAccountManager: false
}


export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermission: (state, action: PayloadAction<boolean>) => {
      state.isAccountManager = action.payload
    },
  },
})

export const { setPermission } = permissionsSlice.actions

export const accountManagerSelector = createSelector(
  (state: RootState) => state.permissions,
  (state: PermissionsState) => state.isAccountManager
)

