import { ReactNode } from "react";

export enum AlertType {
  ERROR = "Error",
  WARNING = "Warning",
}

export interface IAlert {
  message: string | ReactNode;
  variant: AlertType;
  isVisible: boolean;
  onDismiss?: () => void;
  closeButton?: boolean;
}
