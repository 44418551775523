import React from 'react';
import styled from 'styled-components';
import { palette } from 'lib/theme';

const CustodyInvestmentFootnotes = () => (
  <FootnotesContainer>
    <FootnoteRow>
      <Asterisk>*</Asterisk>
      <FootnoteText>
        Any unrealized gain/loss prior to Abra deposit is not accounted for
      </FootnoteText>
    </FootnoteRow>
    <FootnoteRow>
      <Asterisk>**</Asterisk>
      <FootnoteText>
        Investment expenses do not increase the cost basis of the investment
      </FootnoteText>
    </FootnoteRow>
    <FootnoteRow>
      <Asterisk>***</Asterisk>
      <FootnoteText>
        This is calculated as the overall cost basis of the investment (closing amount - cost basis - total network fee = realized gain/loss)
      </FootnoteText>
    </FootnoteRow>
  </FootnotesContainer>
);

const FootnotesContainer = styled.div`
  margin: 32px 0;
  padding: 24px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
`;

const FootnoteRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Asterisk = styled.span`
  color: ${palette.purple.light};
  font-weight: 600;
`;

const FootnoteText = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 1.4;
  margin: 0;
`;

export default CustodyInvestmentFootnotes;