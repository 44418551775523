import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const CancelIcon: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.38222 4.96817C7.92191 3.7365 9.87494 3 12 3C16.9706 3 21 7.02944 21 12C21 14.125 20.2636 16.0779 19.032 17.6176L6.38222 4.96817ZM4.96803 6.3824C3.73644 7.92206 3 9.87503 3 12C3 16.9706 7.02944 21 12 21C14.1251 21 16.0781 20.2635 17.6178 19.0318L4.96803 6.3824ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1Z"
      fill={color}
    />
  </svg>
);

export default CancelIcon;
