import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser } from 'state/slice/auth.slice'
import { Organization, SimplifiedOrganizationInformation, api, useChangeOrganizationMutation, useGetAllOrganizationsQuery } from 'state/store/api'
import styled from 'styled-components'
import SimplerSelector from 'components/molecules/SimplerSelector/SimplerSelector'
import usePermissions from 'shared/usePermissions'
import { custodyApi } from 'state/store/custodyApi'
import { investApi } from 'state/store/investApi'
import { statementsApi } from 'state/store/statementsApi'
import { withdrawApi } from 'state/store/withdrawApi'

const OrganizationViewer = () => {
  const user = useSelector(selectCurrentUser)
  const permissions = usePermissions()
  const dispatch = useDispatch()
  const reset = () => {
    dispatch(api.util.resetApiState());
    dispatch(custodyApi.util.resetApiState());
    dispatch(investApi.util.resetApiState());
    dispatch(statementsApi.util.resetApiState());
    dispatch(withdrawApi.util.resetApiState());
  }
  return (
    <ViewerContainer >
      {permissions.isAccountManager ?
        <AccountManagerOrganizationViewer reset={reset} alias={user?.organization?.alias} department={user?.organization?.department}/> :
        <NormalUserOrganizationViewer reset={reset} alias={user?.organization?.alias} department={user?.organization?.department}/>
      }
    </ViewerContainer>)
}

const ViewerContainer = styled.div`
margin-left: 0px;
@media only screen and (min-width: 1200px) {
 margin-left: 300px;
}
`

const AccountManagerOrganizationViewer = ({ alias, department, reset }: OrganizationViewerProps) => {
  const { data } = useGetAllOrganizationsQuery()
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [request] = useChangeOrganizationMutation()

  const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>()
  const selectOrganization = async (organization?: Organization) => {
    setSelectedOrganization(organization)
    if (organization?.id) {
      try {
        await request(organization.id).unwrap()
        const currentLocation = window.location.href
        const newLocation = currentLocation.replace(/(boost|borrow|profile|withdraw|custody|addresses|defi-borrow)(\/.*)/, 'custody')
        window.location.href = newLocation
      } catch(e) {
        localStorage.clear()
      }
    }
  }


  useEffect(() => {
    if (data && data.organizations) {
      const organizations = [...data.organizations] ?? []
      organizations.sort((c1, c2) => c1.alias && c2.alias ? c1.alias.localeCompare(c2.alias) : 0)
      setOrganizations(organizations)
      setSelectedOrganization(organizations?.find(c => c.alias == alias && c.department == department))
    }
  }, [data, alias])

  return <div>
    <SimplerSelector
      label="Select a organization"
      selectedItem={selectedOrganization}
      data={organizations}
      onSelect={(organization) => selectOrganization(organization)}
      labelParameter="organizationName"
      labelFallback='alias'
    />
  </div>
}

interface OrganizationViewerProps {
  alias?: string
  department?: string
  reset: () =>  void
}
const NormalUserOrganizationViewer = ({ alias, reset }: OrganizationViewerProps) => {

  const user = useSelector(selectCurrentUser)
  const [selectedOrganization, setSelectedOrganization] = useState<SimplifiedOrganizationInformation>()
  const [organizations, setOrganizations] = useState<SimplifiedOrganizationInformation[]>([])
  const [request] = useChangeOrganizationMutation()

  useEffect(() => {
    if (user) {
      const organizations = [...user.organizations]
      organizations.sort((c1, c2) => c1.alias && c2.alias ? c1.alias.localeCompare(c2.alias) : 0)
      setOrganizations(organizations)
      setSelectedOrganization(organizations?.find(c => c.alias == alias))
    }
  }, [user])

  const selectOrganization = async (organization?: SimplifiedOrganizationInformation) => {
    setSelectedOrganization(organization)
    if (organization?.organizationId) {
      try {
        await request(organization.organizationId).unwrap()
        const currentLocation = window.location.href
        const newLocation = currentLocation.replace(/(boost|borrow|profile|withdraw|custody|addresses|defi-borrow)(\/.*)/, 'custody')
        window.location.href = newLocation
      } catch(e) {
        localStorage.clear()
      }
    }
  }

  const simplifyOrganizationName = (name: string = "") => {
    const pattern = /(c\d+\s*-\s*)(.+)/i
    const simplifiedName = name.replace(pattern, '$2').trim()
    return simplifiedName ? simplifiedName : name
  }

  return organizations.length && organizations.length > 1 ? (<div>
    <SimplerSelector
      label="Select a organization"
      selectedItem={selectedOrganization}
      data={organizations}
      onSelect={(organization) => selectOrganization(organization)}
      labelParameter="organizationName"
      labelFallback='alias'
      transform={simplifyOrganizationName}
    />
  </div>) : <StyledOrganizationViewer>{simplifyOrganizationName(alias)}</StyledOrganizationViewer>
}

const StyledOrganizationViewer = styled.p`
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
`
export default OrganizationViewer;
