import { intl } from "components/atoms/LanguageWrapper";

function removeSpecials(str : any) {    
  return String(str).replace(/[^a-zA-Z0-9\_]/g, "");
}

export const getTextWithArgs = (messageId: string, values: any) => {
  let processedKey = removeSpecials(String(messageId).toLocaleLowerCase().replace(/ /g, "_"));
  return intl.formatMessage({ id: processedKey, defaultMessage: messageId }, values);
};

// Helper for localization used globally
export const getText = (messageId: string, ...args:any) => {
  if(args.length > 0) {
    return getTextWithArgs(messageId, args[0]); 
  }
  
  let processedKey = removeSpecials(String(messageId).toLocaleLowerCase().replace(/ /g, "_"));
  return intl.formatMessage({ id: processedKey, defaultMessage: messageId });
};

// This is a helper function to generate data-cy IDs for testing based on the component type
export const generateDataCyFullID = (dataCy:string|undefined, dataCyPrefix:string|undefined, label:any, componentType:string) => {
  let fullID = "";
  if(typeof label === "string") {
    fullID = dataCy ? dataCy : `${dataCyPrefix ? dataCyPrefix+"_" : ""}${removeSpecials(label?.toLowerCase().replace(/ /g, '_'))}_${componentType}`;
  } else {
    fullID = dataCy ? dataCy : `${dataCyPrefix ? dataCyPrefix+"_" : ""}generic_${componentType}`;
  }
  
  return fullID;
}


