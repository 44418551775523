import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  AssetResponse,
  Assets,
  PaginationResponse,
  PriceAlert,
  PriceAlertRequest,
  ResponseBase,
} from "../../services/models";
import { PriceAlertsControllerApi, AssetsControllerApi } from "../../services/";
import { toast, ToastType } from "components/organisms/Toast";
import { AxiosError, AxiosResponse } from "axios";
import { RootState } from "state/store";

interface IConversionRate {
  origin: string;
  destination: string;
}

export enum PriceAlertAction {
  Create,
  Update,
  Delete,
}

const initialState = {
  loading: false,
  loadingDelete: false,
  alerts: [],
  assets: [],
  assetsSelection: [],
  current: undefined,
  request: undefined,
  conversionRate: undefined,
  saveSuccesfully: false,
  id: undefined,
  pagination: undefined,
  action: PriceAlertAction.Create,
} as {
  loading: boolean;
  loadingDelete: boolean;
  alerts: PriceAlert[];
  assets: Assets[];
  assetsSelection: Assets[];
  current: Assets | undefined;
  request: PriceAlertRequest | undefined;
  conversionRate: AssetResponse | undefined;
  saveSuccesfully: boolean;
  id: string | undefined;
  pagination: PaginationResponse | undefined;
  action: PriceAlertAction;
};

const PriceAlertsService = new PriceAlertsControllerApi();
const AssetsService = new AssetsControllerApi();

export const getPriceAlerts =
  (
    page: string = "1",
    size: string = "5",
    search?: string,
    sortDirection: string = "desc",
    sort?: string
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const { token } = getState().auth;
      const { data } = await PriceAlertsService.getPriceAlerts(
        token,
        search,
        sort,
        sortDirection,
        page,
        size
      );

      const alerts: PriceAlert[] = data?.priceAlerts ?? new Array(0);
      const pagination = data?.paginationResponse;
      dispatch(setPagination(pagination));
      dispatch(setAlerts(alerts));
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const savePriceAlert = createAsyncThunk(
  "pricealerts/savePriceAlert",
  async (request: PriceAlertRequest, { getState, rejectWithValue }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    try {
      if (request.uid === undefined) {
        await PriceAlertsService.createPriceAlert(request, token);
        return request;
      } else {
        await PriceAlertsService.updatePriceAlert(request, token, request.uid);
        return request;
      }
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.data?.message)
        return rejectWithValue(error.response.data.message);
    }
  }
);

export const deletePriceAlert = createAsyncThunk(
  "pricealerts/deletePriceAlert",
  async (id: string, { getState, rejectWithValue }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    try {
      await PriceAlertsService.deletePriceAlert(token, id);
      return true;
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.data?.message)
        return rejectWithValue(error.response.data.message);
    }
  }
);

export const getConversionRate = createAsyncThunk(
  "pricealerts/getConversionRate",
  async (options: IConversionRate, { getState, rejectWithValue }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    try {
      const response = (await AssetsService.getConversionRate(
        token,
        options.origin,
        options.destination
      )) as AxiosResponse<AssetResponse>;

      return response.data;
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.data?.message)
        return rejectWithValue(error.response.data.message);
    }
  }
);

export const getPriceAlertAssetsExchange =
  (
    sortField: string = "assetName",
    sortDirection: string = "asc",
    page: string = "1",
    size: string = "1000"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      const { token } = getState().auth;

      const { data } = await AssetsService.getAssetsByProperty(
        token,
        "convert_asset_options",
        sortField,
        sortDirection,
        page,
        size
      );

      const assets: Assets[] = data?.assets ?? new Array(0);

      dispatch(setAssets(assets));
    } catch (error) {
      console.warn(error);
    }
  };

export const getPriceAlertAssetsExchangeSelection =
  (
    sortField: string = "assetName",
    sortDirection: string = "asc",
    page: string = "1",
    size: string = "1000"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      const { token } = getState().auth;

      const { data } = await AssetsService.getAssetsByProperty(
        token,
        "convert_asset_options",
        sortField,
        sortDirection,
        page,
        size
      );

      const assets: Assets[] = data?.assets ?? new Array(0);

      dispatch(setAssetsSelection(assets));
    } catch (error) {
      console.warn(error);
    }
  };

export const priceAlertsSlice = createSlice({
  name: "pricealerts",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoadingDelete: (state, action: PayloadAction<boolean>) => {
      state.loadingDelete = action.payload;
    },
    setAction: (state, action: PayloadAction<PriceAlertAction>) => {
      state.action = action.payload;
    },
    setAlerts: (state, action: PayloadAction<PriceAlert[]>) => {
      state.alerts = action.payload;
    },
    setAssets: (state, action: PayloadAction<Assets[]>) => {
      state.assets = action.payload;
    },
    setAssetsSelection: (state, action: PayloadAction<Assets[]>) => {
      state.assetsSelection = action.payload;
    },
    setCurrent: (state, action: PayloadAction<Assets>) => {
      state.current = action.payload;
    },
    setRequest: (state, action: PayloadAction<PriceAlertRequest>) => {
      state.request = action.payload;
    },
    setConversionRate: (state, action: PayloadAction<AssetResponse>) => {
      state.conversionRate = action.payload;
    },
    setSaveSuccesfully: (state, action: PayloadAction<boolean>) => {
      state.saveSuccesfully = action.payload;
    },
    setId: (state, action: PayloadAction<string | undefined>) => {
      state.id = action.payload;
    },
    cleanPriceAlert: (state) => {
      state.saveSuccesfully = false;
      state.conversionRate = undefined;
      state.current = undefined;
      state.request = undefined;
      state.id = undefined;
      state.assets = [];
    },
    resetAssets: (state) => {
      state.assets = new Array(0);
    },
    setPagination: (
      state,
      action: PayloadAction<PaginationResponse | undefined>
    ) => {
      state.pagination = action.payload;
    },
  },
  extraReducers: {
    [getConversionRate.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<AssetResponse>
    ) => {
      state.conversionRate = payload;
    },
    [getConversionRate.rejected.toString()]: (state) => {
      state.conversionRate = undefined;
    },
    [savePriceAlert.pending.toString()]: (state) => {
      // state.loading = true;
    },
    [savePriceAlert.fulfilled.toString()]: (state) => {
      state.loading = false;
    },
    [savePriceAlert.rejected.toString()]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  setLoading,
  setLoadingDelete,
  setAction,
  setAlerts,
  setAssets,
  setAssetsSelection,
  setCurrent,
  setConversionRate,
  setRequest,
  setSaveSuccesfully,
  setId,
  cleanPriceAlert,
  resetAssets,
  setPagination,
} = priceAlertsSlice.actions;

export const priceAlertsReducer = priceAlertsSlice.reducer;
