import React, { FC, useEffect, useState } from "react";
import { Column, Data } from "components/organisms/AbraTable/interfaces";
import {
  StyledRowGroup,
  StyledCell,
} from "components/organisms/AbraTable/table";
import Row from "./Row";

import { ITableRow } from "components/organisms/AbraTable/interfaces";
import { isFunction } from "lib/utils/helpers";

const RowGroup: FC<ITableRow> = ({
  data,
  columns,
  groupBy,
  enableSelect,
  rowHeight,
  onRowSelection,
  onRowClick,
  rowSidePadding,
  customRowGroupOptions,
}) => {
  const [rows, setRows] = useState<{ group: string; children: Column[] }[]>([]);

  const groupData = (rowsCollection: Column[], key: string) => {
    return rowsCollection?.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
  }

  const handleRowClick = (event: any, schema: any) =>
    isFunction(onRowClick) && onRowClick(event, schema);

  useEffect(() => {
    const objectKeys = groupData(data, groupBy[0]);
    const groupedData = Object.keys(objectKeys).map((key) => {
      return {
        group: key,
        children: objectKeys[key]
      }
    })
    setRows(groupedData);
  }, [data]);

  return (
    <React.Fragment>
      {rows.map((row, indx) => (
        <React.Fragment>
          <StyledRowGroup key={`table-row-group-${indx}`} className="row-group">
            <StyledCell width={rowSidePadding ? rowSidePadding : "24px"} />
            <StyledCell colSpan={columns?.filter(col => !groupBy?.includes(col.accessor)).length}>
              <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", alignItems: "center" }}>
                {row?.group}
                {indx === 0 ? isFunction(customRowGroupOptions) && customRowGroupOptions() : null}
              </div>
            </StyledCell>
            <StyledCell width={rowSidePadding ? rowSidePadding : "24px"} />
          </StyledRowGroup>
          {row?.children?.map((item: Data, _indx) => (
            <Row
              index={_indx.toString()}
              key={`table-row-${_indx}`}
              columns={columns?.filter(col => !groupBy?.includes(col.accessor))}
              data={item}
              enableSelect={enableSelect}
              onRowClick={(event: any) => handleRowClick(event, item?.schema)}
              onRowSelection={onRowSelection}
              rowClassName={item?.rowClassName}
              rowHeight={rowHeight}
              disableBoxShadow={_indx === 0 ? true : false}
            />
          ))}
        </React.Fragment>
      ))}
    </React.Fragment>
  )
};

export default RowGroup