import styled from 'styled-components';

interface StyledLabelProps {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  shift?: string;
}

export const StyledLabel = styled.div<StyledLabelProps>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  left: ${(props) => props.left || '0'};
  right: ${(props) => props.right || '0'};
  bottom: ${(props) => props.bottom || '0'};
  transform: ${(props) => `translate(${props.shift || '0, 0'})`};
`;
