import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Phone: FC<ISVG> = ({
  size = 17,
  width = 17,
  height = 16,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M5.71922 1H3.5C2.39543 1 1.5 1.89543 1.5 3V5C1.5 10.5228 5.97715 15 11.5 15H13.5C14.6046 15 15.5 14.1046 15.5 13V11.618C15.5 11.2393 15.286 10.893 14.9472 10.7236L12.5313 9.51564C11.987 9.24349 11.3278 9.51652 11.1354 10.0938L10.8376 10.9871C10.6447 11.5658 10.0512 11.9102 9.45309 11.7906C7.05929 11.3119 5.18814 9.44071 4.70938 7.04691C4.58976 6.44882 4.93422 5.85526 5.51286 5.66238L6.62149 5.29284C7.11721 5.1276 7.40214 4.60855 7.2754 4.10162L6.68937 1.75746C6.57807 1.3123 6.17809 1 5.71922 1Z"
      stroke={color}
      strokeOpacity="0.7"
      strokeWidth="1.5"
    />
  </svg>
);

export default Phone;
