import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const SuccessIcon: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = "#33EA92",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M2 12.5L9 19.5L22 7.5"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SuccessIcon;
