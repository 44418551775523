import { Heading } from 'components/atoms/Typography';
import { Footer } from 'components/organisms/Modal/Modal.styles';
import React from 'react';
import styled from 'styled-components';
import {
  StyleAuthenticationCarouselContainer,
  StyleAuthenticationFormContainer,
  StyledAuthenticationContainer,
  StyledFormWrapper,
  StyledHeading,
} from 'styles/components/authentication';

const Disclaimer = () => {
  return (
    <>
      <StyledAuthenticationContainer>
        <CarrouselContainer>
          <StyleAuthenticationCarouselContainer />
        </CarrouselContainer>
        <StyleAuthenticationFormContainer>
          <FormContainer>
            <StyledFormWrapper>
              <StyledHeading>
                <Heading variant='h2'>Disclaimer</Heading>
              </StyledHeading>
              <StyledForm>
                <p>
                  <strong>INFORMATION ON THIS SITE IS FOR INFORMATIONAL PURPOSES ONLY AND SHOULD NOT BE RELIED UPON AS INVESTMENT ADVICE.</strong> This website
                  is intended to be (and may not be relied on in any manner as) legal, tax, investment, accounting or other advice or as an offer to sell or a
                  solicitation of an offer to buy any securities or any investment product or any investment advisory service. The information contained in this
                  website is superseded by, and is qualified in its entirety by, information contained in your Advisory Agreement or other offering materials.
                </p>
                <p>
                  <strong>AN INVESTMENT IN DIGITAL ASSETS ENTAILS A HIGH DEGREE OF RISK, INCLUDING THE RISK OF LOSS.</strong> Investors must read and understand
                  all the risks described in their Advisory Agreement and in the Form ADV Part 2 on the SEC’s website. The recipient also must consult its own
                  legal, accounting and tax advisors as to the legal, business, tax and related matters concerning the information contained in this document to
                  make an independent determination and consequences of a potential investment, including US federal, state, local and non-US tax consequences.
                </p>
                <p>
                  <strong>PAST PERFORMANCE IS NOT INDICATIVE OF FUTURE RESULTS OR A GUARANTEE OF FUTURE RETURNS.</strong> The performance of any digital asset
                  is not necessarily indicative of future performance, and you should not assume that investments in the future will be profitable or will equal
                  the performance of past investments.{' '}
                </p>
                <p>
                  <strong>DO NOT RELY ON ANY OPINIONS, PREDICTIONS, PROJECTIONS OR FORWARD-LOOKING STATEMENTS CONTAINED HEREIN.</strong> Certain information
                  contained on this website may constitute “forward-looking statements” that are inherently unreliable and actual events or results may differ
                  materially from those reflected or contemplated herein. Abra Capital Management LP (“ACM”) does not make any assurance as to the accuracy of
                  those predictions or forward-looking statements. ACM expressly disclaims any obligation or undertaking to update or revise any such
                  forward-looking statements. The views and opinions expressed herein are those of ACM as of the date hereof and are subject to change based on
                  prevailing market and economic conditions and will not be updated or supplemented.
                </p>
                <p>
                  <strong>EXTERNAL SOURCES.</strong> Certain information contained herein has been obtained from third-party sources. Although ACM believes the
                  information from such sources to be reliable, ACM makes no representation as to its accuracy or completeness.
                </p>
                <ul>
                  <li>
                    This application is provided in connection with Abra Private by Abra Capital Management LP, an SEC-registered investment adviser. Services
                    described as Abra Prime are offered by other Abra entities. All rights reserved.
                  </li>
                  <li>Investments in securities or digital assets are not FDIC insured. Not bank guaranteed. May lose value.</li>
                </ul>
              </StyledForm>
            </StyledFormWrapper>
            <Footer />
          </FormContainer>
        </StyleAuthenticationFormContainer>
      </StyledAuthenticationContainer>
    </>
  );
};

export default Disclaimer;

const StyledForm = styled.div`
  font-size: 1rem;
  overflow-y: scroll;
  height: 80vh;
  padding-right: 15px;

  .input {
    width: 100%;
    margin-bottom: 2vh;
  }
  strong {
    font-weight: 500;
  }
  p {
    margin-bottom: 20px;
  }
  ul {
    padding-left: 15px;
    list-style-type: circle;
  }
  li {
    margin-bottom: 6px;
  }
  &::-webkit-scrollbar {
    width: 12px;
    min-width: 12px;
    max-width: 12px;
    border: 1px;
    border-radius: 8px;
    background-color: #fffc;
    display: block;
  }
  scrollbar-width: 2px;
  -ms-overflow-style: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    height: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
`;

const CarrouselContainer = styled.div`
  width: 50vw;
  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2vh;
`;
