import React from "react"
import Layout from "components/templates/Layout"
import WalletSecurityWidget from "pages/WalletSecurityWidget/WalletSecurityWidget"
import alternativeGridSystem from "lib/theme/alternativeGridSystem"
import { ProfileScreenGridSizes } from "./ProfileScreenGridSizes"
import ApiKeys from "./ApiKeys"
import { useSelector } from "react-redux"
import { selectCurrentUser } from "state/slice/auth.slice"
import ProfileHeader from "components/molecules/ProfileHeader/ProfileHeader"

const ProfilePage = () => {
  return (
    <Layout customGrid={alternativeGridSystem} pageHeader={<ProfileHeader />}>
      <WalletSecurityWidget
        {...ProfileScreenGridSizes[
        [0]?.length > 1
          ? "walletSecurityWithMoreThanOneLinkedAccount"
          : "walletSecurity"
        ]}
      />
    </Layout>
  )
}

export default ProfilePage
