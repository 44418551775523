import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Twitter: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg width="18" height="18" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.75 2.62606C19.0326 2.94456 18.2615 3.15906 17.4522 3.25575C18.2786 2.76094 18.9131 1.97687 19.2113 1.0425C18.4386 1.50075 17.5822 1.83387 16.6706 2.01344C15.9418 1.23587 14.901 0.75 13.7505 0.75C11.1676 0.75 9.26956 3.15987 9.85293 5.66156C6.529 5.495 3.58125 3.9025 1.60769 1.48206C0.559562 3.28012 1.06412 5.63231 2.84512 6.82343C2.19025 6.80231 1.57275 6.62275 1.03406 6.32293C0.990187 8.17624 2.31862 9.91012 4.24262 10.2961C3.67956 10.4488 3.06287 10.4846 2.43562 10.3643C2.94425 11.9536 4.42137 13.1097 6.17312 13.1422C4.49125 14.4609 2.37225 15.05 0.25 14.7997C2.02044 15.9348 4.124 16.597 6.38275 16.597C13.8106 16.597 18.0072 10.3237 17.7537 4.69712C18.5353 4.13243 19.2137 3.428 19.75 2.62606Z" fill="white"/>
</svg>

);

export default Twitter;
