export enum TableSortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
  UNSORTED = ''
}

export enum TableHeadAlignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  JUSTIFY = 'justify'
}