import styled from 'styled-components';

export const TableContainer = styled.div`
    overflow-x: auto;
    @media print {
        overflow: visible;
        page-break-inside: avoid;
        break-inside: avoid;
    }
`;

export const TableRow = styled.tr`
    &:hover {
        background: rgba(255, 255, 255, 0.03);
    }
    @media print {
        background: white !important;
        &:hover {
            background: white !important;
        }
        page-break-inside: avoid;
        page-break-after: auto;
        break-inside: avoid;
    }
`;

export const Title = styled.h2`
    color: white;
    font-size: 20px;
    margin-bottom: 24px;
    @media print {
        color: black !important;
        font-size: 16px;
    }
`;

export const MainText = styled.div<{ negative?: boolean }>`
    color: ${(props) => (props.negative ? '#ff4d4f' : 'white')};
    @media print {
        color: ${(props) => (props.negative ? '#ff0000' : 'black')} !important;
        font-size: 9px;
    }
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
    min-width: 100%;
    @media print {
        background: white !important;
        font-size: 7px;
        page-break-inside: auto;
        thead {
            display: table-header-group;
        }
        table-layout: auto;
        width: 100%;
        font-size: 8px;

        th,
        td {
            padding: 3px 4px;
            &:nth-child(1) {
                width: 10%;
            } 
            &:nth-child(2) {
                width: 5%;
            }
            &:nth-child(3) {
                width: 5%;
            } 
            &:nth-child(4) {
                width: 5%;
            } 
            &:nth-child(5) {
                width: 9%;
            }
            &:nth-child(6) {
                width: 10%;
            } 
            &:nth-child(7) {
                width: 9%;
            }
            &:nth-child(8) {
                width: 15%;
            }
            &:nth-child(9) {
                width: 9%;
            } 
            &:nth-child(10) {
                width: 10%;
            }
            &:nth-child(11) {
                width: 10%;
            }
        }

        tr {
            page-break-inside: avoid;
            border-bottom: 1px solid #ddd;
        }

        td:nth-child(2) {
            .network-info {
                font-size: 7px;
                color: #666;
                display: block;
            }
        }
    }
`;

export const Td = styled.td`
    padding: 8px 12px;
    color: white;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    text-align: 'left';
    font-size: 14px;
    white-space: nowrap;

    @media print {
        padding: 4px 8px;
        color: black !important;
        border-color: #ddd !important;
        min-height: 40px;
        vertical-align: top;
        white-space: normal;
        overflow-wrap: break-word;
        word-break: break-word;
        font-size: 9px;
    }
`;

export const TextGroup = styled.div`
    display: flex;
    flex-direction: column;
    @media print {
        font-size: 9px;
        min-height: 30px;
        gap: 2px;
    }
`;

export const Container = styled.div`
    padding: 24px;
    color: white;
    font-family: system-ui;
    background: rgb(22, 21, 46);
    margin-bottom: 24px;
    @media print {
        padding: 12px;
        color: black !important;
        background: transparent !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
`;

export const SubText = styled.div<{ negative?: boolean }>`
    color: ${(props) =>
        props.negative ? '#ff4d4f' : 'rgba(255, 255, 255, 0.6)'};
    font-size: 12px;
    @media print {
        color: ${(props) => (props.negative ? '#ff0000' : '#333')} !important;
        opacity: 1 !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        font-size: 9px;
    }
`;

export const Th = styled.th`
    text-align: left;
    padding: 8px 12px;
    color: rgb(179, 157, 219);
    font-weight: normal;
    background: rgb(44, 28, 77);
    font-size: 14px;
    white-space: nowrap;
    @media print {
        padding: 4px 8px;
        color: black !important;
        background: #f5f5f5 !important;
        font-weight: 600 !important;
        border-bottom: 1px solid #ddd !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        font-size: 9px;
        white-space: normal;
        word-break: break-word;
    }
`;
