import React, { FC, useEffect, useState } from "react"

import { capitalize } from "shared"
import { Column } from "components/organisms/AbraTable/interfaces"
import { DefaultSettings, PagesUrl } from "lib/constants/config.constant"
import { Heading, Text } from "components/atoms/Typography"
import { Number } from "components/atoms/Number"
import { palette } from "lib/theme"
import { TableSortDirection } from "@types/enums/Table.enum"
import { useNavigate } from "react-router-dom"
import { useGetPaymentScheduleQuery } from "state/store/api"
import AbraTable from "components/organisms/AbraTable"
import Icon from "components/atoms/Icon/Icon"
import LoadingStage from "pages/LoadingStage"
import PanelWithHeader from "components/molecules/PanelWithHeader"
import styled from "styled-components"
import useAssetFormatHook from "shared/useAssetFormatHook"
import { useSelector } from 'react-redux'
import { pricesSelector } from 'state/slice/prices.slice'

const InterestMonthlyWidget = (props) => {
  const navigate = useNavigate()
  const [transactionsItems, setTransactionsItems] = useState([])
  // const [rows, setRows] = useState<any>([]);

  const [page, setPage] = useState(1)
  const [sort, setSort] = useState("status")
  const [sortDirection, setSortDirection] = useState(
    TableSortDirection.DESCENDING
  )
  const recordsPerPage = DefaultSettings.RECORDS_PER_PAGE

  const { data, isLoading, isFetching } = useGetPaymentScheduleQuery({
    orderId: props?.orderId,
    sortField: sort,
    sortDirection: sortDirection,
    page: page.toString(),
    size: recordsPerPage.toString(),
  })

  const prices = useSelector(pricesSelector)

  const assetFormat = useAssetFormatHook()
  useEffect(() => {
    if (!data && !prices) return

    const response = !data?.payments
      ? []
      : data.payments.map((item: PaymentResponse) => {
        const price = prices?.find(
          (m) => m.identifier === item.currency
        )?.price

        return {
          pkDueDate: item?.dueDate,
          paymentDate: item?.paymentDate ?? "-",
          interestAmountPaid:
            parseFloat(item.interestAmountExpected ?? "0") *
            parseFloat(price ?? "0"),
          status: item.status,
          currency: item.currency,
        }
      })

    setTransactionsItems(response)
  }, [data, prices, assetFormat.isLoading])

  const [columns, setColumns] = useState<Column[]>([
    {
      title: "Due Date",
      enableSort: true,
      accessor: "pkDueDate",
    },
    {
      title: "Payment Date",
      enableSort: true,
      accessor: "paymentDate",
    },
    {
      title: "Interest Amount to be paid",
      enableSort: true,
      accessor: "interestAmountPaid",
    },
    {
      title: "Status",
      enableSort: true,
      accessor: "status",
    },
  ])

  const rows = transactionsItems.map((item) => {
    const { pkDueDate, paymentDate, interestAmountPaid, status } = item
    return {
      schema: item,
      pkDueDate: () => (
        <TextContainer>
          <Text>{pkDueDate}</Text>
        </TextContainer>
      ),
      paymentDate: () => (
        <TextContainer>
          <Text>{paymentDate}</Text>
        </TextContainer>
      ),
      interestAmountPaid: () => (
        <>
          {assetFormat.getAssetFormatted(item?.currency, interestAmountPaid)}{" "}
          {item?.currency?.toUpperCase()}
        </>
      ),
      status: () => (
        <TextContainer>
          <Text>{capitalize(status)}</Text>
        </TextContainer>
      ),
    }
  })

  const handlePaginationChange = (currentPage: number) => {
    setPage(currentPage)
  }

  const handleAscendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key === "transactions" ? "status" : key)
    setSortDirection(TableSortDirection.ASCENDING)
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection:
          column.accessor === key
            ? TableSortDirection.ASCENDING
            : TableSortDirection.UNSORTED,
      }))
    )
    setPage(1)
  }

  const handleDescendingSort = (key: string, data: any, setTableData: any) => {
    setSort(key === "transactions" ? "status" : key)
    setSortDirection(TableSortDirection.DESCENDING)
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection:
          column.accessor === key
            ? TableSortDirection.DESCENDING
            : TableSortDirection.UNSORTED,
      }))
    )
    setPage(1)
  }

  const handleResetSort = (key: string, data: any, setTableData: any) => {
    setSort("status")
    setSortDirection(TableSortDirection.DESCENDING)
    setColumns(
      columns.map((column) => ({
        ...column,
        sortDirection: TableSortDirection.UNSORTED,
      }))
    )
    setPage(1)
  }

  return (
    <PanelWithHeader
      width="100%"
      height="563px"
      title="Payment schedule"
      {...props}
    >
      {isLoading ? (
        <LoadingStage loadingText=" " />
      ) : data?.payments?.length ? (
        <AbraTable
          columns={columns}
          data={rows}
          showPagination={true}
          isLoading={isLoading}
          isSorting={isFetching}
          activePageNumber={page}
          recordsPerPage={recordsPerPage}
          totalRecords={data?.paginationResponse?.totalRecords}
          onPaginationChange={handlePaginationChange}
          onAscendingSort={handleAscendingSort}
          onDescendingSort={handleDescendingSort}
          onResetSort={handleResetSort}
          showPaginationCaption={false}
          expandTable
        />
      ) : (
        <EmptyStage />
      )}
    </PanelWithHeader>
  )
}

const EmptyStage = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Icon name="EmptySchedule" size={150} />
      <div style={{ marginBottom: "8px" }}>
        <Heading variant="h3" color={palette.gray.main}>
          No payments in schedule
        </Heading>
      </div>
    </div>
  </div>
)

const TextContainer = styled.div``
export default InterestMonthlyWidget
