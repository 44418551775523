// API_KEY = 3e9590ca-5aed-4842-8102-97f92d923acb

import { UrlIterface } from "../interfaces/reducers/Url.interface";

const COINBASE_PROXY = "/v1/cryptocurrency";
export const ABRA_BE_PROXY = "/webapp-frontend/be";

export const DOMAIN: UrlIterface = {
  COIN_MARKET_CAP: "https://pro-api.coinmarketcap.com",
  NETVERIFY: "https://netverify.com/api",
  ABRA_BACKEND: `https://${process?.env?.HOST_BACKEND}`,
};
// ABRA_BACKEND: "https://web-backend-pfmsub-1-001.pfmsub-int.abra.xyz",


export const URLS: UrlIterface = {
  MARKETCAP_QUOTES_HISTORICAL: `${DOMAIN.COIN_MARKET_CAP}${COINBASE_PROXY}/quotes/historical`,
  MARKETCAP_QUOTES_LATEST: `${DOMAIN.COIN_MARKET_CAP}${COINBASE_PROXY}/quotes/latest`,
  MARKETCAP_LISTING_LATEST: `${DOMAIN.COIN_MARKET_CAP}${COINBASE_PROXY}/listings/latest`,
  MARKETCAP_GAINER_LOSER: `${DOMAIN.COIN_MARKET_CAP}${COINBASE_PROXY}/trending/gainers-losers`,
  NETVERIFY_INITIATE: `${DOMAIN.NETVERIFY}/v4/initiate/`,
  BE_ALL_CRYPTO: `${DOMAIN.ABRA_BACKEND}/all-crypto`,
  BE_TRADE: `${DOMAIN.ABRA_BACKEND}/trade`,
  BE: DOMAIN.ABRA_BACKEND,
  MARKETCAP: DOMAIN.COIN_MARKET_CAP,
  PROXY: "/proxy",
};


