import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const UpArrowSmall: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = "#A399F6",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M17 14.835C16.8126 15.0213 16.5592 15.1258 16.295 15.1258C16.0308 15.1258 15.7774 15.0213 15.59 14.835L12 11.295L8.46001 14.835C8.27265 15.0213 8.0192 15.1258 7.75501 15.1258C7.49082 15.1258 7.23737 15.0213 7.05001 14.835C6.95628 14.742 6.88189 14.6314 6.83112 14.5096C6.78035 14.3877 6.75421 14.257 6.75421 14.125C6.75421 13.993 6.78035 13.8623 6.83112 13.7404C6.88189 13.6186 6.95628 13.508 7.05001 13.415L11.29 9.175C11.383 9.08128 11.4936 9.00688 11.6154 8.95611C11.7373 8.90534 11.868 8.87921 12 8.87921C12.132 8.87921 12.2627 8.90534 12.3846 8.95611C12.5064 9.00688 12.617 9.08128 12.71 9.175L17 13.415C17.0937 13.508 17.1681 13.6186 17.2189 13.7404C17.2697 13.8623 17.2958 13.993 17.2958 14.125C17.2958 14.257 17.2697 14.3877 17.2189 14.5096C17.1681 14.6314 17.0937 14.742 17 14.835Z"
      fill={color}
    />
  </svg>
);

export default UpArrowSmall;
