import React, { FC, useState, useMemo, useEffect } from "react";
import { PaginationTypeEnum } from "@types/enums/Pagination.enum";
import Link from "components/atoms/Link";
import Icon from "components/atoms/Icon";
import {
  StyledPaginationCaption,
  StyledPaginationNav,
  StyledPagination,
  StyledPages,
  StyledPageNumber,
  StyledPageDots,
} from "components/organisms/AbraTable/table";
import { palette } from "lib/theme";
import { minMax } from "lib/utils/helpers";
import { ITablePagination } from "components/organisms/AbraTable/interfaces";
import { Text } from "components/atoms/Typography";
import { getText } from "shared/locale-helper";

export const Pagination: FC<ITablePagination> = ({
  totalRecords,
  perPage,
  handlePagination,
  activePageNumber,
  variant,
  showCaption,
  paginationProps,
}) => {
  const [currentIndex, setCurrentIndex] = useState(activePageNumber);
  const [showArray, setShowArray] = useState([]);
  const [caption, setCaption] = useState("");

  const renderPages = (
    currentIndex: number,
    arr: number[]
  ): number[] | any[] => {
    let length = arr.length;
    if (arr.length >= 8) {
      if (currentIndex <= 4) {
        return [1, 2, 3, 4, "...", arr[length - 1]];
      } else if (currentIndex >= 5 && currentIndex <= arr.length - 4) {
        return [
          1,
          "...",
          currentIndex - 1,
          currentIndex,
          currentIndex + 1,
          "...",
          arr[length - 1],
        ];
      } else {
        return [
          1,
          "...",
          arr[length - 4],
          arr[length - 3],
          arr[length - 2],
          arr[length - 1],
        ];
      }
    } else {
      return arr;
    }
  };

  const indexArray = useMemo(() => {
    if (perPage > 0 && totalRecords > 0) {
      const totalPages = Math.ceil(totalRecords / perPage);
      return Array.from({ length: totalPages }).map((v, i) => i + 1);
    } else {
      return [];
    }
  }, [totalRecords, perPage]);

  useEffect(() => {
    setCurrentIndex(activePageNumber);
  }, [activePageNumber]);

  useEffect(() => {
    const pages = renderPages(currentIndex, indexArray);
    setShowArray(pages);
    setCaption(
      `${getText("shown")} ${perPage * (currentIndex - 1) + 1}-${
        perPage * currentIndex > totalRecords
          ? totalRecords
          : perPage * currentIndex
      } ${getText("of")} ${totalRecords}`
    );
    return () => {};
  }, [perPage, currentIndex, totalRecords, indexArray]);

  const handlePageClick = (value: number) => {
    setCurrentIndex(value);
    handlePagination(value);
  };

  const handleNextClick = () => {
    if (currentIndex + 1 <= indexArray[indexArray.length - 1]) {
      setCurrentIndex((index: number) => index + 1);
      handlePagination(currentIndex + 1);
    }
  };
  const handlePreviousClick = () => {
    if (currentIndex - 1 >= indexArray[0]) {
      setCurrentIndex((index: number) => index - 1);
      handlePagination(currentIndex - 1);
    }
  };

  if (indexArray.length === 0) {
    return null;
  }

  return (
    <StyledPagination {...paginationProps}>
      <StyledPaginationNav onClick={handlePreviousClick} hide={true}>
        <Icon
          name="LeftArrowBig"
          size={16}
          color={
            !(currentIndex !== indexArray[0])
              ? palette.darkBackgroundContrast.light20
              : palette.darkBackgroundContrast.light70
          }
        />
      </StyledPaginationNav>

      {variant === PaginationTypeEnum.DOTS && (
        <StyledPages>
          {showArray.map((value, indx) => {
            return (
              <StyledPageDots
                key={`pagination-dots-${indx}`}
                active={currentIndex === value}
              >
                <svg
                  width={10}
                  height={10}
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handlePageClick(value)}
                >
                  <circle
                    cx="5"
                    cy="5"
                    r="5"
                    fill={
                      currentIndex === value
                        ? palette.white.main
                        : palette.darkBackgroundContrast.light70
                    }
                  />
                </svg>
              </StyledPageDots>
            );
          })}
        </StyledPages>
      )}

      {showCaption && (
        <StyledPaginationCaption>{caption}</StyledPaginationCaption>
      )}

      {variant === PaginationTypeEnum.PAGES && (
        <StyledPages>
          <Text size="small">
            {currentIndex} of {minMax(showArray)[1]}
          </Text>
        </StyledPages>
      )}

      {variant === PaginationTypeEnum.NUMBERS && (
        <StyledPages>
          {showArray.map((value, indx) => {
            return (
              <StyledPageNumber
                key={`pagination-number-${indx}`}
                active={currentIndex === value}
              >
                {typeof value === "number" ? (
                  <Link
                    tabIndex="0"
                    label={value}
                    onClick={() => handlePageClick(value)}
                  />
                ) : (
                  value
                )}
              </StyledPageNumber>
            );
          })}
        </StyledPages>
      )}

      <StyledPaginationNav onClick={handleNextClick} hide={true}>
        <Icon
          name="RightArrowBig"
          size={16}
          color={
            !(currentIndex !== indexArray[indexArray.length - 1])
              ? palette.darkBackgroundContrast.light20
              : palette.darkBackgroundContrast.light70
          }
        />
      </StyledPaginationNav>
    </StyledPagination>
  );
};

export default Pagination;
