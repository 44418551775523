/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApiError } from '../models';
import { RecurringDepositRequest } from '../models';
import { RecurringExchangeRequest } from '../models';
import { ResponseBase } from '../models';
import { TransactionsResponse } from '../models';
/**
 * RecurringTransactionsControllerApi - axios parameter creator
 * @export
 */
export const RecurringTransactionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a recurring deposit transaction.
         * @param {RecurringDepositRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecurringDepositTransaction: async (body: RecurringDepositRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createRecurringDepositTransaction.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling createRecurringDepositTransaction.');
            }
            const localVarPath = `/recurring-transactions/deposit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a recurring exchange transaction.
         * @param {RecurringExchangeRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecurringExchangeTransaction: async (body: RecurringExchangeRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createRecurringExchangeTransaction.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling createRecurringExchangeTransaction.');
            }
            const localVarPath = `/recurring-transactions/exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a recurring transaction.
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecurringTransaction: async (token: string, transactionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling deleteRecurringTransaction.');
            }
            // verify required parameter 'transactionId' is not null or undefined
            if (transactionId === null || transactionId === undefined) {
                throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling deleteRecurringTransaction.');
            }
            const localVarPath = `/recurring-transactions/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the list of recent completed transactions of a given recurring transaction id.
         * @param {string} token 
         * @param {string} transactionId 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentTransactions: async (token: string, transactionId: string, limit?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getRecentTransactions.');
            }
            // verify required parameter 'transactionId' is not null or undefined
            if (transactionId === null || transactionId === undefined) {
                throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling getRecentTransactions.');
            }
            const localVarPath = `/recurring-transactions/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a recurring deposit transaction.
         * @param {RecurringDepositRequest} body 
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecurringDepositTransaction: async (body: RecurringDepositRequest, token: string, transactionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateRecurringDepositTransaction.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling updateRecurringDepositTransaction.');
            }
            // verify required parameter 'transactionId' is not null or undefined
            if (transactionId === null || transactionId === undefined) {
                throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling updateRecurringDepositTransaction.');
            }
            const localVarPath = `/recurring-transactions/deposit/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a recurring exchange transaction.
         * @param {RecurringExchangeRequest} body 
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecurringExchangeTransaction: async (body: RecurringExchangeRequest, token: string, transactionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateRecurringExchangeTransaction.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling updateRecurringExchangeTransaction.');
            }
            // verify required parameter 'transactionId' is not null or undefined
            if (transactionId === null || transactionId === undefined) {
                throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling updateRecurringExchangeTransaction.');
            }
            const localVarPath = `/recurring-transactions/exchange/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RecurringTransactionsControllerApi - functional programming interface
 * @export
 */
export const RecurringTransactionsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a recurring deposit transaction.
         * @param {RecurringDepositRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecurringDepositTransaction(body: RecurringDepositRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TransactionsResponse>>> {
            const localVarAxiosArgs = await RecurringTransactionsControllerApiAxiosParamCreator(configuration).createRecurringDepositTransaction(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a recurring exchange transaction.
         * @param {RecurringExchangeRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecurringExchangeTransaction(body: RecurringExchangeRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TransactionsResponse>>> {
            const localVarAxiosArgs = await RecurringTransactionsControllerApiAxiosParamCreator(configuration).createRecurringExchangeTransaction(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a recurring transaction.
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecurringTransaction(token: string, transactionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await RecurringTransactionsControllerApiAxiosParamCreator(configuration).deleteRecurringTransaction(token, transactionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve the list of recent completed transactions of a given recurring transaction id.
         * @param {string} token 
         * @param {string} transactionId 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentTransactions(token: string, transactionId: string, limit?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TransactionsResponse>>> {
            const localVarAxiosArgs = await RecurringTransactionsControllerApiAxiosParamCreator(configuration).getRecentTransactions(token, transactionId, limit, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a recurring deposit transaction.
         * @param {RecurringDepositRequest} body 
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecurringDepositTransaction(body: RecurringDepositRequest, token: string, transactionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TransactionsResponse>>> {
            const localVarAxiosArgs = await RecurringTransactionsControllerApiAxiosParamCreator(configuration).updateRecurringDepositTransaction(body, token, transactionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a recurring exchange transaction.
         * @param {RecurringExchangeRequest} body 
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecurringExchangeTransaction(body: RecurringExchangeRequest, token: string, transactionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TransactionsResponse>>> {
            const localVarAxiosArgs = await RecurringTransactionsControllerApiAxiosParamCreator(configuration).updateRecurringExchangeTransaction(body, token, transactionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * RecurringTransactionsControllerApi - factory interface
 * @export
 */
export const RecurringTransactionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create a recurring deposit transaction.
         * @param {RecurringDepositRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecurringDepositTransaction(body: RecurringDepositRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TransactionsResponse>> {
            return RecurringTransactionsControllerApiFp(configuration).createRecurringDepositTransaction(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a recurring exchange transaction.
         * @param {RecurringExchangeRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecurringExchangeTransaction(body: RecurringExchangeRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TransactionsResponse>> {
            return RecurringTransactionsControllerApiFp(configuration).createRecurringExchangeTransaction(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a recurring transaction.
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecurringTransaction(token: string, transactionId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return RecurringTransactionsControllerApiFp(configuration).deleteRecurringTransaction(token, transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the list of recent completed transactions of a given recurring transaction id.
         * @param {string} token 
         * @param {string} transactionId 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentTransactions(token: string, transactionId: string, limit?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TransactionsResponse>> {
            return RecurringTransactionsControllerApiFp(configuration).getRecentTransactions(token, transactionId, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a recurring deposit transaction.
         * @param {RecurringDepositRequest} body 
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecurringDepositTransaction(body: RecurringDepositRequest, token: string, transactionId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TransactionsResponse>> {
            return RecurringTransactionsControllerApiFp(configuration).updateRecurringDepositTransaction(body, token, transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a recurring exchange transaction.
         * @param {RecurringExchangeRequest} body 
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRecurringExchangeTransaction(body: RecurringExchangeRequest, token: string, transactionId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TransactionsResponse>> {
            return RecurringTransactionsControllerApiFp(configuration).updateRecurringExchangeTransaction(body, token, transactionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RecurringTransactionsControllerApi - object-oriented interface
 * @export
 * @class RecurringTransactionsControllerApi
 * @extends {BaseAPI}
 */
export class RecurringTransactionsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Create a recurring deposit transaction.
     * @param {RecurringDepositRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringTransactionsControllerApi
     */
    public async createRecurringDepositTransaction(body: RecurringDepositRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TransactionsResponse>> {
        return RecurringTransactionsControllerApiFp(this.configuration).createRecurringDepositTransaction(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create a recurring exchange transaction.
     * @param {RecurringExchangeRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringTransactionsControllerApi
     */
    public async createRecurringExchangeTransaction(body: RecurringExchangeRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TransactionsResponse>> {
        return RecurringTransactionsControllerApiFp(this.configuration).createRecurringExchangeTransaction(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a recurring transaction.
     * @param {string} token 
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringTransactionsControllerApi
     */
    public async deleteRecurringTransaction(token: string, transactionId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return RecurringTransactionsControllerApiFp(this.configuration).deleteRecurringTransaction(token, transactionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Retrieve the list of recent completed transactions of a given recurring transaction id.
     * @param {string} token 
     * @param {string} transactionId 
     * @param {string} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringTransactionsControllerApi
     */
    public async getRecentTransactions(token: string, transactionId: string, limit?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TransactionsResponse>> {
        return RecurringTransactionsControllerApiFp(this.configuration).getRecentTransactions(token, transactionId, limit, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update a recurring deposit transaction.
     * @param {RecurringDepositRequest} body 
     * @param {string} token 
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringTransactionsControllerApi
     */
    public async updateRecurringDepositTransaction(body: RecurringDepositRequest, token: string, transactionId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TransactionsResponse>> {
        return RecurringTransactionsControllerApiFp(this.configuration).updateRecurringDepositTransaction(body, token, transactionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update a recurring exchange transaction.
     * @param {RecurringExchangeRequest} body 
     * @param {string} token 
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RecurringTransactionsControllerApi
     */
    public async updateRecurringExchangeTransaction(body: RecurringExchangeRequest, token: string, transactionId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TransactionsResponse>> {
        return RecurringTransactionsControllerApiFp(this.configuration).updateRecurringExchangeTransaction(body, token, transactionId, options).then((request) => request(this.axios, this.basePath));
    }
}
