import { getText } from "shared/locale-helper";

export const SwapFrequency: { [key: string]: { id: string; text: string } } = {
  off: { id: "", text: getText("just_once") },
  weekly: { id: "weekly", text: getText("weekly") },
  twice_month: { id: "twice_month", text: getText("twice_a_month") },
  monthly: { id: "monthly", text: getText("monthly") },
};

export const TIMEINTERVALS = {
  day: { id: "day", label: "1D" },
  week: { id: "week", label: "7D" },
  month: { id: "month", label: "1M" },
  quarter: { id: "quarter", label: "3M" },
  year: { id: "year", label: "1Y" },
  all_the_time: { id: "all_the_time", label: "All" },
} as {
  [key: string]: {
    id: "day" | "week" | "month" | "quarter" | "year" | "all_the_time";
    label: string;
  };
};

export const PhoneNumberErrors = {
  INVALID_COUNTRY: "Invalid country",
  NOT_A_NUMBER: "Invalid phone number",
  TOO_SHORT: "Phone number too short",
  TOO_LONG: "Phone number too long",
  INVALID_LENGTH: "Invalid lenght",
};

export const InputStatusColors = ["collateral-needed", "failed"];

export const monthNumberToNameMapper: { [key: number]: string } = {
  1: "January",
  2: "Febrary",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "Augost",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const ErrorCodes = {
  15: "This user is not registered on Talos.",
  71: "Invalid status for user registration step",
  74: "ADV Sign document not signed",
  75: "User has already signed the document",
};

export enum ErrorCode {
  UNKNOWN_ERROR = -1,
  TALOS_USER_NOT_REGISTERED = 15,
  ONBOARDING_INVALID_STATUS = 71,
  ONBOARDING_ALREADY_SIGNED = 75,
  ONBOARDING_DOCUMENT_NOT_SIGNED = 74,
  USER_REGISTRATION_FAILED = 72
}
