import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const UpArrowBig: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
    <path
      d='M21.9996 16C21.8016 16 21.6026 15.941 21.4276 15.819L12.0006 9.22001L2.57362 15.819C2.12362 16.135 1.49762 16.026 1.18062 15.573C0.864616 15.121 0.973618 14.497 1.42662 14.18L11.4266 7.18C11.7706 6.94 12.2296 6.94 12.5726 7.18L22.5726 14.18C23.0256 14.496 23.1356 15.12 22.8186 15.573C22.6256 15.852 22.3146 16 21.9996 16Z'
      fill={color}
    />
  </svg>
);

export default UpArrowBig;