import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const MarketUp: FC<ISVG> = ({
  size = 15,
  width = size,
  height = size,
  color = "#17B96B",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <rect
      x="0.5"
      y="0.5"
      width={width}
      height={height}
      rx="9.5"
      fill={color}
      fillOpacity="0.15"
    />
    <path
      d="M15.3941 8.12059V12.6461M15.3941 8.12059H10.8686M15.3941 8.12059L8.32302 15.1917"
      stroke="#17B96B"
      strokeWidth="1.5"
    />
    <rect
      x="0.5"
      y="0.5"
      width={width}
      height={height}
      rx="9.5"
      stroke="#1D1D43"
    />
  </svg>
);

export default MarketUp;
