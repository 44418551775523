import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Assets, HistoricMarketDataResponse, AssetHistoricMarketData } from "services/models";
import { AssetsControllerApi } from "services";

const initialState = {
  loading: false,
  holdings: {},
  holdingsError: false,
  watchlist: {},
  watchlistError: false,
  marketData: {},
  marketDataError: false,
} as {
  loading: boolean;
  holdings: { assets: any[], paginationResponse: any };
  holdingsError: boolean;
  watchlist: { assets: any[], paginationResponse: any };
  watchlistError: boolean;
  marketData: { asset: string, marketData: any[] };
  marketDataError: boolean;
};

const AssetsService = new AssetsControllerApi();

export const getUserHoldingsTxr = createAsyncThunk(
  "assets/getUserHoldingsTxr",
  async (args: any, api: any) => {
    const txr = await AssetsService.getHoldings(
      api?.getState().auth?.token,
      args?.sortField,
      args?.sortDirection,
      args?.page,
      args?.size
    );
    const holdings: Assets[] =
      txr?.data?.assets ?? new Array(0);
    return holdings;
  }
);

export const getUserWatchlistTxr = createAsyncThunk(
  "assets/getUserWatchlistTxr",
  async (args: any, api: any) => {
    const txr = await AssetsService.getWatchlist(
      api?.getState().auth?.token,
      args?.sortField,
      args?.sortDirection,
      args?.page,
      args?.size
    );
    const watchlist: Assets[] =
      txr?.data?.assets ?? new Array(0);
    return watchlist;
  }
);

export const allCryptosSlice = createSlice({
  name: "allCryptos",
  initialState,
  reducers: {
    setUserHoldings: (state, action: PayloadAction<{ assets: any[], paginationResponse: any }>) => {
      state.holdings = action.payload;
    },
    setUserWatchlist: (state, action: PayloadAction<{ assets: any[], paginationResponse: any }>) => {
      state.watchlist = action.payload;
    },
    setMarketData: (state, action: PayloadAction<{ asset: string, marketData: any[] }>) => {
      state.marketData = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getUserHoldingsTxr.pending as any]: (state) => {
      state.loading = true;
      state.holdingsError = false;
    },
    [getUserHoldingsTxr.rejected as any]: (state) => {
      state.loading = false;
      state.holdingsError = true;
    },
    [getUserHoldingsTxr.fulfilled as any]: (state, action) => {
      state.loading = false;
      state.holdingsError = false;
      state.holdings = action.payload;
    },
    [getUserWatchlistTxr.pending as any]: (state) => {
      state.loading = true;
      state.watchlistError = false;
    },
    [getUserWatchlistTxr.rejected as any]: (state) => {
      state.loading = false;
      state.watchlistError = true;
    },
    [getUserWatchlistTxr.fulfilled as any]: (state, action) => {
      state.loading = false;
      state.watchlistError = false;
      state.watchlist = action.payload;
    },
  },
});

export const getUserHoldings =
  (sortField?: string, sortDirection?: string, page: string = "1", size: string = "5") =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setUserHoldings({ assets: [], paginationResponse: {} }))
      const { token } = getState().auth;
      const { data } = await AssetsService.getHoldings(
        token,
        sortField,
        sortDirection,
        page,
        size
      );

      const holdings: any[] = data?.assets ?? new Array(0);
      const pagination = data?.paginationResponse;
      dispatch(setUserHoldings({ assets: holdings, paginationResponse: pagination }));
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  export const getUserWatchlist =
  (sortField?: string, sortDirection?: string, page: string = "1", size: string = "5") =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setUserWatchlist({ assets: [], paginationResponse: {} }))
      const { token } = getState().auth;
      const { data } = await AssetsService.getWatchlist(
        token,
        sortField,
        sortDirection,
        page,
        size
      );
      const watchlist: Assets[] = data?.assets ?? new Array(0);
      const pagination = data?.paginationResponse;

      dispatch(setUserWatchlist({ assets: watchlist, paginationResponse: pagination }));
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  export const getMaketData =
  (asset: Assets, interval: string = "week") =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      if(asset.fiatCurrency || asset.stableCoin) {
        dispatch(setMarketData({ asset: asset.identifier, marketData: [] }))
      } else {
        const { token } = getState().auth;
        const { data } = await AssetsService.getHistoricMarketData(
          token,
          asset.identifier,
          interval,
        );
        dispatch(setMarketData({
          asset: asset.identifier,
          marketData: data?.marketData?.data
        }));
      }
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const { setUserHoldings, setUserWatchlist, setMarketData, setLoading } =
  allCryptosSlice.actions;

export const allCryptosReducer = allCryptosSlice.reducer;
