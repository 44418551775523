import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const RightArrow: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = 'white',
  svgProps,
}) => {
  return (
    <svg
      width={svgProps?.width || width}
      height={svgProps?.height || height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M8.05028 23.0006C7.85228 23.0006 7.65328 22.9416 7.47828 22.8196C7.02528 22.5026 6.91528 21.8796 7.23228 21.4266L13.8303 12.0006L7.23128 2.57361C6.91528 2.12161 7.02428 1.49762 7.47728 1.18062C7.92828 0.864616 8.55328 0.973618 8.87028 1.42662L15.8703 11.4266C16.1113 11.7706 16.1113 12.2286 15.8703 12.5726L8.87028 22.5726C8.67628 22.8526 8.36528 23.0006 8.05028 23.0006Z"
        fill={color}
      />
    </svg>
  );
};

export default RightArrow;
