import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Swap: FC<ISVG> = ({
  size = 20,
  width = size,
  height = size,
  color = "white",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M17.91 13.51H13.38C13.1148 13.51 12.8604 13.6154 12.6729 13.8029C12.4854 13.9904 12.38 14.2448 12.38 14.51C12.38 14.7752 12.4854 15.0296 12.6729 15.2171C12.8604 15.4046 13.1148 15.51 13.38 15.51H15.78C14.6769 16.6627 13.2544 17.4593 11.6952 17.7974C10.1359 18.1355 8.51121 17.9996 7.02978 17.4072C5.54834 16.8149 4.27787 15.7931 3.38159 14.4732C2.48531 13.1532 2.00418 11.5955 2 10C2 9.73478 1.89464 9.48043 1.70711 9.29289C1.51957 9.10536 1.26522 9 1 9C0.734784 9 0.48043 9.10536 0.292893 9.29289C0.105357 9.48043 0 9.73478 0 10C0.00528666 11.9528 0.582221 13.8613 1.6596 15.49C2.73699 17.1187 4.26767 18.3964 6.06274 19.1652C7.85782 19.9341 9.83873 20.1605 11.761 19.8166C13.6833 19.4727 15.4628 18.5735 16.88 17.23V19C16.88 19.2652 16.9854 19.5196 17.1729 19.7071C17.3604 19.8946 17.6148 20 17.88 20C18.1452 20 18.3996 19.8946 18.5871 19.7071C18.7746 19.5196 18.88 19.2652 18.88 19V14.5C18.8775 14.2416 18.7752 13.9943 18.5943 13.8097C18.4135 13.6251 18.1683 13.5177 17.91 13.51ZM10 0C7.43639 0.00731219 4.97349 0.998914 3.12 2.77V1C3.12 0.734784 3.01464 0.48043 2.82711 0.292893C2.63957 0.105357 2.38522 0 2.12 0C1.85478 0 1.60043 0.105357 1.41289 0.292893C1.22536 0.48043 1.12 0.734784 1.12 1V5.5C1.12 5.76522 1.22536 6.01957 1.41289 6.20711C1.60043 6.39464 1.85478 6.5 2.12 6.5H6.62C6.88522 6.5 7.13957 6.39464 7.32711 6.20711C7.51464 6.01957 7.62 5.76522 7.62 5.5C7.62 5.23478 7.51464 4.98043 7.32711 4.79289C7.13957 4.60536 6.88522 4.5 6.62 4.5H4.22C5.32247 3.34787 6.74409 2.5515 8.3024 2.21311C9.86071 1.87472 11.4846 2.00975 12.9656 2.60086C14.4466 3.19198 15.7172 4.21221 16.6142 5.5306C17.5113 6.849 17.9938 8.40539 18 10C18 10.2652 18.1054 10.5196 18.2929 10.7071C18.4804 10.8946 18.7348 11 19 11C19.2652 11 19.5196 10.8946 19.7071 10.7071C19.8946 10.5196 20 10.2652 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0Z"
      fill={color}
    />
  </svg>
);

export default Swap;
