import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  Overlay,
  Wrapper,
  IconContainer,
  Header,
  Body,
  Footer,
  HeaderContainer,
} from "./Modal.styles";
import {
  ModalBodyProps,
  ModalComposition,
  ModalProps,
} from "./Modal.interface";
import Icon from "../../atoms/Icon";
import { Text } from "../../atoms/Typography";
import { palette } from "../../../lib/theme";
import Button from "../../atoms/Button";
import {
  SizeNamesEnum,
  TypesNamesEnum,
} from "../../../@types/enums/Button.enum";
import WhiteSpinner from "components/atoms/Spinner/WhiteSpinner";

const Modal: FC<ModalProps> & ModalComposition = ({
  visible,
  header,
  subTitle,
  onClose,
  onBack,
  children,
  modalProps,
  isScrolled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClose = useCallback(() => {
    setIsOpen(!isOpen);

    if (onClose) onClose();
  }, [isOpen, onClose]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (visible) {
      setIsOpen(true);
    } else {
      timeoutId = setTimeout(() => setIsOpen(false), 150);
    }

    return () => {
      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }
    };
  }, [visible]);

  if (!isOpen) {
    return null;
  }

  const marginLeft = () => {
    if (onBack && onClose) return "0px";
    if (!onBack && onClose) return "24px";
    if (onBack && !onClose) return "-24px";
  };

  return ReactDOM.createPortal(
    <Overlay visible={visible}>
      <Wrapper visible={visible} {...modalProps}>
        <HeaderContainer isScrolled={isScrolled}>
          <div
            className="modal-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "20px",
              marginRight: "24px",
              marginLeft: "20px",
            }}
          >
            {onBack ? (
              <IconContainer onClick={onBack} data-cy="modal_back_button">
                <Icon
                  name="LeftArrowModal"
                  size={24}
                  color={palette.darkBackgroundContrast.white}
                />
              </IconContainer>
            ) : (
              <div></div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginLeft: marginLeft(),
                gap: "0.25rem",
              }}
            >
              <Text size="large" letterSpacing="-0.5" bold>
                {header}
              </Text>
              {subTitle && (
                <Text
                  align="center"
                  letterSpacing="-0.5"
                  color={palette.darkBackgroundContrast.light70}
                >
                  {subTitle}
                </Text>
              )}
            </div>
            {onClose ? (
              <IconContainer
                onClick={handleOnClose}
                data-cy="modal_close_button"
              >
                <Icon
                  name="CrossModal"
                  size={24}
                  color={palette.darkBackgroundContrast.white}
                />
              </IconContainer>
            ) : (
              <div></div>
            )}
          </div>
        </HeaderContainer>
        {children}
      </Wrapper>
    </Overlay>,
    document.body
  );
};

Modal.Header = ({
  headerProps,
  children,
}: {
  headerProps: any;
  children: ReactNode;
}) => {
  return (
    <Header padding={headerProps?.padding} textAling={headerProps?.textAlign}>
      {children}
    </Header>
  );
};

Modal.Body = ({
  confirmText,
  onConfirm,
  onCancel,
  children,
  isTxtModal = false,
  cancelText,
  hasScrollBar = false,
  hasButtonPadding = true,
  loading,
}: ModalBodyProps) => {
  return (
    <Body
      isTxtModal={isTxtModal}
      hasScrollBar={hasScrollBar}
      hasButtonPadding={hasButtonPadding}
    >
      {children}
      {confirmText || onConfirm || cancelText || onCancel ? (
        <div className="modalButtons">
          {(cancelText && cancelText !== "") ||
          (onCancel && cancelText !== "") ? (
            <Button
              label={cancelText}
              buttonType={TypesNamesEnum.SECONDAY}
              size={SizeNamesEnum.DEFAULT}
              onClick={onCancel}
              disabled={loading}
            />
          ) : null}

          {confirmText || onConfirm ? (
            <Button
              label={confirmText}
              buttonType={TypesNamesEnum.ACCENT}
              size={SizeNamesEnum.DEFAULT}
              onClick={cancelText !== "" ? onConfirm : onCancel}
              isLoading={loading}
            ></Button>
          ) : null}
        </div>
      ) : null}
    </Body>
  );
};

Modal.Footer = ({
  children,
  backgroundColor,
  backdropFilter,
}: {
  children: ReactNode;
  backgroundColor?: string;
  backdropFilter?: string;
}) => (
  <Footer backgroundColor={backgroundColor} backdropFilter={backdropFilter}>
    {children}
  </Footer>
);

export default Modal;
