import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const NoFavourites: FC<ISVG> = ({
  size = 151,
  width = 151,
  height = 150,
  color,
  svgProps,
}) => {
  return (
    <svg
      width={svgProps?.width || width}
      height={svgProps?.height || height}
      viewBox="0 0 151 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M78.6016 10.5692L100.15 47.6396L142.144 56.7335C144.553 57.2551 145.499 60.1841 143.851 62.0171L115.183 93.906L119.586 136.704C119.839 139.158 117.342 140.969 115.088 139.965L75.8266 122.5L36.565 139.965C34.3108 140.968 31.8144 139.158 32.0671 136.704L36.4703 93.906L7.80191 62.0171C6.15437 60.1841 7.10128 57.2551 9.50941 56.7335L51.503 47.6396L73.0515 10.5692C74.2892 8.44049 77.3639 8.44049 78.6016 10.5692Z"
        stroke={color}
        strokeWidth="3.24"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M116.888 51.5498L65.9688 102.469"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.4321 105.854L74.6133 122.673"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.4283 111.009L32.2422 136.195"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.1758 56.0029L36.3242 94.8546"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.3998 36.7783L80.9102 50.2681"
        stroke={color}
        strokeWidth="1.62"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NoFavourites;
