import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Perx: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.37138 1.71969C7.70434 0.142163 4.96187 0.82868 4.23542 3.00656C3.98968 3.74324 4.03177 4.48881 4.28735 5.14003H1C0.447715 5.14003 0 5.58775 0 6.14003V12.14C0 12.6923 0.447715 13.14 1 13.14L2 13.14V22.14C2 22.6923 2.44772 23.14 3 23.14H19C19.5523 23.14 20 22.6923 20 22.14V16.14C20 15.5877 19.5523 15.14 19 15.14C18.4477 15.14 18 15.5877 18 16.14V21.14H4V13.14L13 13.14C13.5523 13.14 14 12.6923 14 12.14C14 11.5877 13.5523 11.14 13 11.14H2V7.14003H20V11.14H17C16.4477 11.14 16 11.5877 16 12.14C16 12.6923 16.4477 13.14 17 13.14H21C21.5523 13.14 22 12.6923 22 12.14V6.14003C22 5.58775 21.5523 5.14003 21 5.14003H17.7132C17.9684 4.48904 18.0103 3.74384 17.7647 3.00752C17.0383 0.829671 14.2958 0.143154 12.6288 1.72062L12.6287 1.72066L11.0004 3.26141L9.37142 1.71973L9.37138 1.71969ZM9.54542 4.63809L7.9967 3.17236L7.99666 3.17232C7.39169 2.5999 6.39621 2.84929 6.13266 3.6394L6.13266 3.63941C5.88662 4.37699 6.43584 5.13902 7.21303 5.13902H8.75403C8.76927 5.13902 8.78444 5.13936 8.79951 5.14003H9.01493L9.54542 4.63809ZM15.8675 3.64037C15.6039 2.85025 14.6084 2.60086 14.0034 3.17334L14.0034 3.17338L11.9249 5.14003H13.2372L13.2461 5.13999H14.7871C15.5643 5.13999 16.1135 4.37796 15.8675 3.64039L15.8675 3.64037Z"
      fill={color}
    />
  </svg>
);

export default Perx;
