import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const CryptoDepositDetected: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
   <g clipPath="url(#clip0_6220_489992)">
    <path d="M15.707 3.29325L12.707 0.29325C12.317 -0.09775 11.683 -0.09775 11.293 0.29325L8.29301 3.29325C7.90201 3.68425 7.90201 4.31619 8.29301 4.70719C8.68301 5.09819 9.31701 5.09819 9.70701 4.70719L11 3.41422V7.00022C11 7.55322 11.448 8.00022 12 8.00022C12.552 8.00022 13 7.55322 13 7.00022V3.41422L14.293 4.70719C14.488 4.90219 14.744 5.00022 15 5.00022C15.256 5.00022 15.512 4.90219 15.707 4.70719C16.098 4.31619 16.098 3.68425 15.707 3.29325Z" fill={color}/>
    <path d="M17.366 5.57144C16.901 5.27544 16.282 5.41143 15.985 5.87643C15.688 6.34243 15.825 6.96047 16.291 7.25747C18.613 8.73847 20 11.2595 20 14.0005C20 18.4115 16.411 22.0005 12 22.0005C7.589 22.0005 4 18.4115 4 14.0005C4 11.2595 5.387 8.73847 7.709 7.25747C8.175 6.96047 8.312 6.34243 8.015 5.87643C7.718 5.41043 7.1 5.27244 6.634 5.57144C3.733 7.42144 2 10.5725 2 14.0005C2 19.5145 6.486 24.0005 12 24.0005C17.514 24.0005 22 19.5145 22 14.0005C22 10.5725 20.268 7.42144 17.366 5.57144Z" fill={color}/>
    <path d="M11.8491 11.0002C12.5851 11.0002 13.2951 11.2732 13.8481 11.7692C14.2591 12.1402 14.8911 12.1042 15.2601 11.6932C15.6291 11.2812 15.5941 10.6493 15.1831 10.2812C14.2631 9.45525 13.0791 9.00122 11.8491 9.00122C9.09212 9.00122 6.84912 11.2442 6.84912 14.0012C6.84912 16.7582 9.09212 19.0012 11.8491 19.0012C13.0781 19.0012 14.2631 18.5473 15.1831 17.7213C15.5941 17.3533 15.6291 16.7213 15.2601 16.3093C14.8911 15.8993 14.2591 15.8632 13.8481 16.2332C13.2951 16.7282 12.5851 17.0023 11.8491 17.0023C10.1951 17.0023 8.84912 15.6563 8.84912 14.0023C8.84912 12.3483 10.1941 11.0002 11.8491 11.0002Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_6220_489992">
    <rect width={width} height={height} fill={color}/>
    </clipPath>
    </defs>
  </svg>
);

export default CryptoDepositDetected;