import React, { useEffect, useState } from 'react';
import { Heading } from 'components/atoms/Typography';
import {
  StyledHeading,
  StyledFormWrapper,
  StyledForm,
  StyledAuthenticationContainer,
  StyleAuthenticationFormContainer,
  StyleAuthenticationCarouselContainer,
} from 'styles/components/authentication';
import Footer from 'components/molecules/Footer/Footer';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import onboardingApi, { KYCResponse } from 'state/store/onboardingApi';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading';
import { usePlaidLink } from 'react-plaid-link';
import Button from 'components/atoms/Button'
import { TypesNamesEnum } from 'enums/Button.enum'
import { PagesUrl } from 'lib/constants/config.constant';
import { ErrorCode } from 'constant/constant'
import './RedirectKYC.css'

const RedirectKYC = () => {
  const [searchParams] = useSearchParams();
  const registrationId = searchParams.get('registrationId') ?? '';
  const email = searchParams.get('email') ?? '';
  const [kycResponse, setKycResponse] = useState<KYCResponse>();
  const [startKyc, startKycMeta] = onboardingApi.useStartKYCMutation();
  const [finishedKyc, setFinishedKyc] = useState(false);
  
  const navigate = useNavigate()
  const s = searchParams.toString()
  
  useEffect(() => {
    if(finishedKyc){
      navigate(`${PagesUrl.ONBOARDING}/sign?`+s)
    }
  }, [finishedKyc, navigate, s])

  useEffect(() => {
    startKyc({ email, registrationId })
      .unwrap()
      .then((data) => setKycResponse(data));
  }, [email, registrationId, startKyc]);

  return (
    <StyledAuthenticationContainer>
      <CarrouselContainer>
        <StyleAuthenticationCarouselContainer />
      </CarrouselContainer>
      <StyleAuthenticationFormContainer>
        <FormContainer>
          <StyledFormWrapper>
            <StyledHeading>
              <Heading variant='h2'>Verify  your identity</Heading>
            </StyledHeading>
            <StyledForm>
              {!finishedKyc && (
                <>
                  {startKycMeta.isLoading && <Loading showText={false} size={SpinnerSizeEnum.LARGE} />}
                  {!startKycMeta.isLoading && <>{kycResponse?.token ? <PlaidLink next={() => setFinishedKyc(true)} linkToken={kycResponse?.token} /> : null}</>}
                </>
              )}
            </StyledForm>
          </StyledFormWrapper>
          <Footer />
        </FormContainer>
      </StyleAuthenticationFormContainer>
    </StyledAuthenticationContainer>
  );
};

interface LinkProps {
  linkToken: string | null;
  next: () => void;
}
const PlaidLink: React.FC<LinkProps> = (props: LinkProps) => {
  const [endKyc] = onboardingApi.useEndKYCMutation();
  const [searchParams] = useSearchParams();
  const registrationId = searchParams.get('registrationId') ?? '';
  const email = searchParams.get('email') ?? '';
  const [showButton, setShowButton] = useState(false)
  const navigate = useNavigate()
  
  const onSuccess = async (_: any, metadata: any) => {
    setShowButton(false)
    try {
      const validationId = metadata.link_session_id
      await endKyc({ email, registrationId, validationId }).unwrap();
      props.next();
    } catch(e: any) {
      if(e?.data?.code === ErrorCode.USER_REGISTRATION_FAILED){
        navigate(`${PagesUrl.ONBOARDING}/kyc/failure`)
      } 
      setShowButton(true)
    }
  };

  const openUI = () => {
    open()
    setShowButton(false)
  }
  const onEvent = (event: string) => {
    if(event === "IDENTITY_VERIFICATION_CLOSE_UI") {
      setShowButton(true)
    }
  }
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: props.linkToken!,
    onSuccess,
    onEvent
  };
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if(ready) {
      open()
    }
  }, [ready, open])

  return showButton ? (
    <Button
    height='44'
    width='250'
    buttonType={TypesNamesEnum.ACCENT}
    label='Resume KYC Process'
    onClick={() => openUI()}
  />
  ) : null;
};

const CarrouselContainer = styled.div`
  width: 50vw;
  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2vh;
`;

export default RedirectKYC;
