import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const WarningCircleSmall: FC<ISVG> = ({
  size = 16,
  width = size,
  height = size,
  color,
  svgProps,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M13.25 8C13.25 10.8995 10.8995 13.25 8 13.25C5.10051 13.25 2.75 10.8995 2.75 8C2.75 5.10051 5.10051 2.75 8 2.75C10.8995 2.75 13.25 5.10051 13.25 8Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 11.76C7.9 11.76 7.8 11.7389 7.71 11.6988C7.62 11.6696 7.54 11.6084 7.47 11.5381C7.33 11.3976 7.25 11.2068 7.25 11.007C7.25 10.9056 7.27 10.8163 7.31 10.7159C7.35 10.6255 7.4 10.5452 7.47 10.4749C7.54 10.4047 7.62 10.3545 7.71 10.3143C7.99 10.2039 8.32 10.2641 8.53 10.4749C8.6 10.5452 8.66 10.6255 8.69 10.7159C8.73 10.8163 8.75 10.9056 8.75 11.007C8.75 11.2068 8.67 11.3986 8.53 11.5381C8.39 11.6797 8.2 11.76 8 11.76Z"
        fill={color}
      />
      <line
        x1="8"
        y1="5.75"
        x2="8"
        y2="8.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default WarningCircleSmall;
