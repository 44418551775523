/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, {
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
import { ApiError } from "../models";
import { AppConfigurationResponse } from "../models";
import { ConfigurationInputDataRequest } from "../models";
import { EmailVerificationRequest } from "../models";
import { PhoneVerificationRequest } from "../models";
import { ResponseBase } from "../models";
import { SendPhoneCodeRequest } from "../models";
import { TransactionOptionsResponse } from "../models";
import { UpdateCurrencyRequest } from "../models";
import { UpdateUserRequest } from "../models";
import { UserCurrentLocationResponse } from "../models";
import { UserEmailsResponse } from "../models";
import { UserResponse } from "../models";
/**
 * UsersControllerApi - axios parameter creator
 * @export
 */
export const UsersControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Add new unverified email to user's email list
     * @param {EmailVerificationRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addUnverifiedEmail: async (
      body: EmailVerificationRequest,
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling addUnverifiedEmail."
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling addUnverifiedEmail."
        );
      }
      const localVarPath = `/users/emails/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the configuration for the application of a specific user
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    appConfiguration: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling appConfiguration."
        );
      }
      const localVarPath = `/users/app/config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete user account
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteUserAccount: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling deleteUserAccount."
        );
      }
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify if an email has already been used
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    emailAvailable: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling emailAvailable."
        );
      }
      const localVarPath = `/users/email/email-available`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the configuration for the application of a specific user
     * @param {ConfigurationInputDataRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConfiguration: async (
      body: ConfigurationInputDataRequest,
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling getConfiguration."
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getConfiguration."
        );
      }
      const localVarPath = `/users/app/config`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user's registered emails
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEmails: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getEmails."
        );
      }
      const localVarPath = `/users/emails`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user data
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUser: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getUser."
        );
      }
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get user's current location
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserCurrentLocation: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getUserCurrentLocation."
        );
      }
      const localVarPath = `/users/current-location`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Lookup for Abra's users information
     * @param {string} token
     * @param {string} phoneNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    lookupByPhone: async (
      token: string,
      phoneNumber: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling lookupByPhone."
        );
      }
      // verify required parameter 'phoneNumber' is not null or undefined
      if (phoneNumber === null || phoneNumber === undefined) {
        throw new RequiredError(
          "phoneNumber",
          "Required parameter phoneNumber was null or undefined when calling lookupByPhone."
        );
      }
      const localVarPath = `/users/lookup/{phoneNumber}`.replace(
        `{${"phoneNumber"}}`,
        encodeURIComponent(String(phoneNumber))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Sends one time password to user's Phone
     * @param {SendPhoneCodeRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendCode: async (
      body: SendPhoneCodeRequest,
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling sendCode."
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling sendCode."
        );
      }
      const localVarPath = `/users/phones/send-code`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update user's convenience currency
     * @param {UpdateCurrencyRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCurrency: async (
      body: UpdateCurrencyRequest,
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling updateCurrency."
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling updateCurrency."
        );
      }
      const localVarPath = `/users/convenience-currency`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update user profile image
     * @param {string} token
     * @param {Blob} [image]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateProfileImageForm: async (
      token: string,
      image?: Blob,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling updateProfileImageForm."
        );
      }
      const localVarPath = `/users/image`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new FormData();

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      if (image !== undefined) {
        localVarFormParams.append("image", image as any);
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data";
      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update user
     * @param {UpdateUserRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (
      body: UpdateUserRequest,
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling updateUser."
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling updateUser."
        );
      }
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "PUT",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify user email
     * @param {EmailVerificationRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyEmail: async (
      body: EmailVerificationRequest,
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling verifyEmail."
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling verifyEmail."
        );
      }
      const localVarPath = `/users/emails/verify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verify user phone
     * @param {PhoneVerificationRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyPhone: async (
      body: PhoneVerificationRequest,
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling verifyPhone."
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling verifyPhone."
        );
      }
      const localVarPath = `/users/phones/verify`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersControllerApi - functional programming interface
 * @export
 */
export const UsersControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add new unverified email to user's email list
     * @param {EmailVerificationRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addUnverifiedEmail(
      body: EmailVerificationRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).addUnverifiedEmail(body, token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the configuration for the application of a specific user
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appConfiguration(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<TransactionOptionsResponse>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).appConfiguration(token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Delete user account
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUserAccount(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).deleteUserAccount(token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Verify if an email has already been used
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emailAvailable(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).emailAvailable(token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the configuration for the application of a specific user
     * @param {ConfigurationInputDataRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConfiguration(
      body: ConfigurationInputDataRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<AppConfigurationResponse>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).getConfiguration(body, token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get user's registered emails
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmails(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<UserEmailsResponse>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).getEmails(token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get user data
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<UserResponse>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).getUser(token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get user's current location
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserCurrentLocation(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<UserCurrentLocationResponse>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).getUserCurrentLocation(token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Lookup for Abra's users information
     * @param {string} token
     * @param {string} phoneNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lookupByPhone(
      token: string,
      phoneNumber: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<UserResponse>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).lookupByPhone(token, phoneNumber, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Sends one time password to user's Phone
     * @param {SendPhoneCodeRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendCode(
      body: SendPhoneCodeRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).sendCode(body, token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update user's convenience currency
     * @param {UpdateCurrencyRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCurrency(
      body: UpdateCurrencyRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).updateCurrency(body, token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update user profile image
     * @param {string} token
     * @param {Blob} [image]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProfileImageForm(
      token: string,
      image?: Blob,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<UserResponse>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).updateProfileImageForm(token, image, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Update user
     * @param {UpdateUserRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      body: UpdateUserRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).updateUser(body, token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Verify user email
     * @param {EmailVerificationRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyEmail(
      body: EmailVerificationRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).verifyEmail(body, token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Verify user phone
     * @param {PhoneVerificationRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyPhone(
      body: PhoneVerificationRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await UsersControllerApiAxiosParamCreator(
        configuration
      ).verifyPhone(body, token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * UsersControllerApi - factory interface
 * @export
 */
export const UsersControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Add new unverified email to user's email list
     * @param {EmailVerificationRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addUnverifiedEmail(
      body: EmailVerificationRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return UsersControllerApiFp(configuration)
        .addUnverifiedEmail(body, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get the configuration for the application of a specific user
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async appConfiguration(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<TransactionOptionsResponse>> {
      return UsersControllerApiFp(configuration)
        .appConfiguration(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete user account
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteUserAccount(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return UsersControllerApiFp(configuration)
        .deleteUserAccount(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verify if an email has already been used
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async emailAvailable(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return UsersControllerApiFp(configuration)
        .emailAvailable(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get the configuration for the application of a specific user
     * @param {ConfigurationInputDataRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConfiguration(
      body: ConfigurationInputDataRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<AppConfigurationResponse>> {
      return UsersControllerApiFp(configuration)
        .getConfiguration(body, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user's registered emails
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEmails(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<UserEmailsResponse>> {
      return UsersControllerApiFp(configuration)
        .getEmails(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user data
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUser(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<UserResponse>> {
      return UsersControllerApiFp(configuration)
        .getUser(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get user's current location
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserCurrentLocation(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<UserCurrentLocationResponse>> {
      return UsersControllerApiFp(configuration)
        .getUserCurrentLocation(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Lookup for Abra's users information
     * @param {string} token
     * @param {string} phoneNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async lookupByPhone(
      token: string,
      phoneNumber: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<UserResponse>> {
      return UsersControllerApiFp(configuration)
        .lookupByPhone(token, phoneNumber, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Sends one time password to user's Phone
     * @param {SendPhoneCodeRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendCode(
      body: SendPhoneCodeRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return UsersControllerApiFp(configuration)
        .sendCode(body, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update user's convenience currency
     * @param {UpdateCurrencyRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCurrency(
      body: UpdateCurrencyRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return UsersControllerApiFp(configuration)
        .updateCurrency(body, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update user profile image
     * @param {string} token
     * @param {Blob} [image]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateProfileImageForm(
      token: string,
      image?: Blob,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<UserResponse>> {
      return UsersControllerApiFp(configuration)
        .updateProfileImageForm(token, image, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update user
     * @param {UpdateUserRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      body: UpdateUserRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return UsersControllerApiFp(configuration)
        .updateUser(body, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verify user email
     * @param {EmailVerificationRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyEmail(
      body: EmailVerificationRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return UsersControllerApiFp(configuration)
        .verifyEmail(body, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Verify user phone
     * @param {PhoneVerificationRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyPhone(
      body: PhoneVerificationRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return UsersControllerApiFp(configuration)
        .verifyPhone(body, token, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersControllerApi - object-oriented interface
 * @export
 * @class UsersControllerApi
 * @extends {BaseAPI}
 */
export class UsersControllerApi extends BaseAPI {
  /**
   *
   * @summary Add new unverified email to user's email list
   * @param {EmailVerificationRequest} body
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async addUnverifiedEmail(
    body: EmailVerificationRequest,
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return UsersControllerApiFp(this.configuration)
      .addUnverifiedEmail(body, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get the configuration for the application of a specific user
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async appConfiguration(
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<TransactionOptionsResponse>> {
    return UsersControllerApiFp(this.configuration)
      .appConfiguration(token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Delete user account
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async deleteUserAccount(
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return UsersControllerApiFp(this.configuration)
      .deleteUserAccount(token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Verify if an email has already been used
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async emailAvailable(
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return UsersControllerApiFp(this.configuration)
      .emailAvailable(token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get the configuration for the application of a specific user
   * @param {ConfigurationInputDataRequest} body
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async getConfiguration(
    body: ConfigurationInputDataRequest,
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<AppConfigurationResponse>> {
    return UsersControllerApiFp(this.configuration)
      .getConfiguration(body, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get user's registered emails
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async getEmails(
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<UserEmailsResponse>> {
    return UsersControllerApiFp(this.configuration)
      .getEmails(token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get user data
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async getUser(
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<UserResponse>> {
    return UsersControllerApiFp(this.configuration)
      .getUser(token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get user's current location
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async getUserCurrentLocation(
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<UserCurrentLocationResponse>> {
    return UsersControllerApiFp(this.configuration)
      .getUserCurrentLocation(token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Lookup for Abra's users information
   * @param {string} token
   * @param {string} phoneNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async lookupByPhone(
    token: string,
    phoneNumber: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<UserResponse>> {
    return UsersControllerApiFp(this.configuration)
      .lookupByPhone(token, phoneNumber, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Sends one time password to user's Phone
   * @param {SendPhoneCodeRequest} body
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async sendCode(
    body: SendPhoneCodeRequest,
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return UsersControllerApiFp(this.configuration)
      .sendCode(body, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update user's convenience currency
   * @param {UpdateCurrencyRequest} body
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async updateCurrency(
    body: UpdateCurrencyRequest,
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return UsersControllerApiFp(this.configuration)
      .updateCurrency(body, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update user profile image
   * @param {string} token
   * @param {Blob} [image]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async updateProfileImageForm(
    token: string,
    image?: Blob,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<UserResponse>> {
    return UsersControllerApiFp(this.configuration)
      .updateProfileImageForm(token, image, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Update user
   * @param {UpdateUserRequest} body
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async updateUser(
    body: UpdateUserRequest,
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return UsersControllerApiFp(this.configuration)
      .updateUser(body, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Verify user email
   * @param {EmailVerificationRequest} body
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async verifyEmail(
    body: EmailVerificationRequest,
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return UsersControllerApiFp(this.configuration)
      .verifyEmail(body, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Verify user phone
   * @param {PhoneVerificationRequest} body
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersControllerApi
   */
  public async verifyPhone(
    body: PhoneVerificationRequest,
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return UsersControllerApiFp(this.configuration)
      .verifyPhone(body, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
