import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const SignInBlockedWhite: FC<ISVG> = ({
  size = 88,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="88"
    height="90"
    viewBox="0 0 88 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70.4001 45.9766H18.5778C15.3375 45.9766 12.7112 48.6029 12.7112 51.8432V81.1766C12.7112 84.4169 15.3375 87.0432 18.5778 87.0432H70.4001C73.6404 87.0432 76.2667 84.4169 76.2667 81.1766V51.8432C76.2667 48.6039 73.6404 45.9766 70.4001 45.9766Z"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.4001 45.9773V21.5329C26.4001 11.273 34.7181 2.95508 44.9779 2.95508C55.2377 2.95508 63.5557 11.273 63.5557 21.5329V45.9773"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44 68.4653C46.7 68.4653 48.8889 66.2764 48.8889 63.5764C48.8889 60.8763 46.7 58.6875 44 58.6875C41.2999 58.6875 39.1111 60.8763 39.1111 63.5764C39.1111 66.2764 41.2999 68.4653 44 68.4653Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44 75.7835V68.4668"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SignInBlockedWhite;
