import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  ReactNode,
} from "react";
import { IInputProps } from "interfaces/components/atoms/Atom.interface";
import { SpinnerSizeEnum } from "@types/enums/Loading.enum";
import { StyledInput, StyledInputContainer } from "styles/components/input";
import InputAdditionaInfo from "./InputAddtionalInfo";
import Icon from "components/atoms/Icon";
import Loading from "components/atoms/Loading";
import Alert from "components/molecules/Alert";
import { AlertType } from "components/molecules/Alert/Alert.interface";
import { palette } from "lib/theme";
import classNames from "classnames";

import { getTextWidth, isFunction } from "lib/utils/helpers";
import { InputStatusColors } from "constant/constant";
import { generateDataCyFullID, getText } from "shared/locale-helper";

const Input: FC<IInputProps> = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      label,
      prefix,
      suffix,
      icon,
      iconColor,
      className: containerClassName,
      additionalText = null,
      additionalInfo = null,
      errorMessage,
      warningMessage = "",
      showError = true,
      inputProps,
      dismissAlert,
      dismissAlertButton = true,
      readOnly = false,
      number = false,
      hideLabelOnTyping = false,
      numberProps,
      loading,
      status = "",
      height = "",
      dataCy = "",
      dataCyPrefix = "",
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(inputProps?.value || "");
    const [isFocused, setFocus] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const inputRef = useRef(ref || null);

    const [statusFormatted, setStatusFormatted] = useState(status);
    const [isAutofilled, setAutofill] = useState(false);
    const [isAlertVisible, setIsAlertVisible] = useState(
      errorMessage !== undefined || warningMessage?.length > 0
    );
    const [isBlocked] = useState(readOnly);
    const [alertMessage, setAlertMessage] = useState<
      string | ReactNode | undefined
    >();
    const [alertType, setAlerrType] = useState<AlertType | "">("");

    const {
      className: inputClassName,
      placeholder,
      type,
      disabled,
      whiteDisabled,
      backGroundColor,
      marginTop,
      ...inputPropsRest
    } = inputProps || {};

    const setInputValue = useCallback((current = ""): any => {
      setValue(current);
    }, []);

    useEffect(() => {
      if (isBlocked) {
        return setInputValue(inputProps?.value);
      }

      setInputValue(inputProps?.value);
    }, [inputProps?.value, isBlocked, setInputValue]);

    useEffect(() => {
      if (inputProps?.value) {
        setInputValue(inputProps?.value);
        setFocus(true);
      }
    }, [inputProps?.value, setInputValue]);

    useEffect(() => {
      if (inputRef && inputRef?.current) {
        inputRef?.current?.addEventListener(
          "animationstart",
          () => setAutofill(true),
          false
        );
      }
    }, [inputRef]);

    useEffect(() => {
      const message: any = errorMessage
        ? errorMessage
        : warningMessage?.length > 0
        ? warningMessage
        : "";

      const type: AlertType | "" = errorMessage
        ? AlertType.ERROR
        : warningMessage
        ? AlertType.WARNING
        : "";
      setAlertMessage(message);
      setAlerrType(type);
      setIsAlertVisible(Boolean(message));
    }, [errorMessage, warningMessage]);

    useEffect(() => {
      const formatted = status.trim().toLowerCase().replaceAll(" ", "-");
      if (InputStatusColors.includes(formatted)) {
        setStatusFormatted(formatted);
      } else {
        setStatusFormatted("default");
      }
    }, [status]);

    const handleBlur = (event: any) => {
      setFocus(false);

      if (!value?.length && isAutofilled) setAutofill(false);

      if (isFunction(inputProps?.onBlur)) {
        inputProps?.onBlur(event);
      }
    };

    const handleFocus = (event: any) => {
      setFocus(true);

      if (isFunction(inputProps?.onFocus)) {
        inputProps?.onFocus(event);
      }
    };

    const handleChange = (event: any) => {
      if (!isBlocked) {
        setInputValue(event.target.value);
      }

      if (isFunction(inputProps?.onChange)) {
        inputProps?.onChange(event);
      }
    };

    const handleKeyPress = (event: any) => {
      if (inputProps?.type === "number") {
        const keys = ["e", "E"];
        if (keys.includes(event.key)) {
          event.preventDefault();
        }
      }
      if (isFunction(inputProps?.onKeyPress)) {
        inputProps?.onKeyPress(event);
      }
    };

    const handleDismissInputAlert = () => {
      setIsAlertVisible(false);
      dismissAlert();
    };

    const generatedDataCy = generateDataCyFullID(
      dataCy,
      dataCyPrefix,
      label,
      "input"
    );

    return (
      <div className={containerClassName} data-cy={generatedDataCy}>
        {alertMessage && showError && (
          <Alert
            message={alertMessage}
            variant={alertType}
            isVisible={isAlertVisible}
            onDismiss={handleDismissInputAlert}
            closeButton={dismissAlertButton}
          />
        )}
        <StyledInputContainer
          {...props}
          className={classNames({
            container_alert: Boolean(
              alertMessage !== undefined && alertMessage !== ""
            ),
            show_error: !showError,
            error: alertType === AlertType.ERROR,
            container_focus:
              isFocused || containerClassName?.includes("container_focus"),
            filled:
              value?.length > 0 ||
              numberProps?.value ||
              isAutofilled ||
              placeholder,
            disabled: inputProps?.disabled,
            smallIcon: inputProps?.smallIcon,
            password: type === "password",
            prefix: prefix && prefix(),
            suffix: suffix,
            read_only:
              readOnly && !containerClassName?.includes("readonly_select"),
            readonly_select: containerClassName?.includes("readonly_select"),
            hasList: containerClassName?.includes("hasList"),
            swapUp: containerClassName?.includes("swapUp"),
            swapDown: containerClassName?.includes("swapDown"),
            suffixSelector: containerClassName?.includes("suffixSelector"),
          })}
          marginTop={marginTop ? marginTop : height ? "10px" : ""}
          height={height}
          isFocused={isFocused}
          disabled={inputProps?.disabled}
          readOnly={readOnly}
          whiteDisabled={true}
          isPrefixWithStatus={status ? true : false}
          haveStatus={status ? true : false}
          text={getTextWidth(value, null)}
          isPrefixWithStatus={true}
        >
          <label className="input__container">
            {(hideLabelOnTyping ? label && !value : label) ? (
              <span
                className={`input__label ${
                  prefix ? (status ? "prefix-status" : "prefix") : ""
                } ${value ? "value" : ""}`}
              >
                {label}

                {status ? (
                  <span
                    className={`input__label--show-${statusFormatted}-color  ${
                      prefix ? "prefix" : ""
                    } ${value ? "value" : ""}`}
                  >
                    {` ${status ? ": " + getText(status) : ""}`}
                  </span>
                ) : (
                  ""
                )}
              </span>
            ) : (
              <span
                className={`input__label--hidden ${prefix ? "prefix" : ""} ${
                  value ? "value" : ""
                }`}
              >
                {placeholder}
              </span>
            )}
            <div className="input__wrapper">
              {number ? (
                <StyledInput
                  {...numberProps}
                  ref={inputRef}
                  className={inputClassName}
                  placeholder={placeholder}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  onFocus={handleFocus}
                  onChange={handleChange}
                  disabled={disabled}
                  whiteDisabled={whiteDisabled}
                  readOnly={readOnly}
                  backgroundColor={backGroundColor}
                  height={height}
                />
              ) : (
                <StyledInput
                  {...inputPropsRest}
                  ref={inputRef}
                  placeholder={placeholder}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                  onFocus={handleFocus}
                  onChange={handleChange}
                  className={inputClassName}
                  value={value}
                  type={type === "password" && showPass ? "text" : type}
                  disabled={disabled}
                  whiteDisabled={whiteDisabled}
                  readOnly={readOnly}
                  backgroundColor={backGroundColor}
                  haveStatus={status ? true : false}
                  height={height}
                />
              )}
              {additionalText && (
                <span
                  className="input__additionalText"
                  onClick={(e) => e.preventDefault()}
                >
                  {additionalText}
                </span>
              )}
              {prefix && (
                <span
                  className="input__prefix left"
                  onClick={(e) => e.preventDefault()}
                >
                  {prefix()}
                </span>
              )}
              {suffix && (
                <span
                  className="input__suffix right"
                  onClick={(e) => e.preventDefault()}
                >
                  {loading ? (
                    <Loading
                      showText={false}
                      size={SpinnerSizeEnum.INPUT}
                      showRoundSpinner
                    />
                  ) : (
                    suffix()
                  )}
                </span>
              )}
              {type === "password" && (
                <span
                  className={classNames({
                    input__show: true,
                    [`show-pass`]: showPass,
                  })}
                  onClick={() => {
                    if (inputProps?.onPasswordReveal)
                      inputProps?.onPasswordReveal(showPass);
                    setFocus(true);
                    !disabled && setShowPass(!showPass);
                  }}
                >
                  {iconColor && (
                    <Icon
                      size={24}
                      name={showPass ? "Eye" : "OpenEye"}
                      color={iconColor}
                    />
                  )}
                  {!iconColor && (
                    <Icon
                      size={24}
                      name={showPass ? "Eye" : "OpenEye"}
                      color={
                        (disabled && palette.darkBackgroundContrast.light10) ||
                        (alertType === AlertType.ERROR &&
                          palette.darkBackgroundContrast.light20) ||
                        palette.darkBackgroundContrast.white
                      }
                    />
                  )}
                </span>
              )}
              {icon && (
                <span className="input__show">
                  <Icon
                    size={20}
                    name={icon}
                    color={palette.darkBackgroundContrast.white}
                    iconProps={{
                      style: {
                        position: "relative",
                        top: "3px",
                      },
                    }}
                  />
                </span>
              )}
            </div>
          </label>
        </StyledInputContainer>
        <InputAdditionaInfo {...additionalInfo} />
      </div>
    );
  }
);

export default Input;
