import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const CruiseDisabled: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9927 4.64578C17.3978 4.65227 12.993 6.48161 9.74441 9.73259C6.4958 12.9836 4.66852 17.3908 4.66333 21.9877C4.66852 26.5851 6.49564 30.9928 9.74411 34.2446C12.9926 37.4964 17.3973 39.3268 21.9927 39.3346C26.5909 39.332 31.0002 37.5037 34.2521 34.2512C37.5041 30.9988 39.3328 26.5881 39.3367 21.9877C39.3315 17.3883 37.5022 12.9788 34.2504 9.72739C30.9986 6.47599 26.5901 4.64838 21.9927 4.64578Z"
      fill="#C0C0C0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9976 39.3346C26.594 39.3306 31.0012 37.5025 34.2519 34.2512C37.5038 30.9988 39.3325 26.588 39.3364 21.9877C39.3312 17.3882 37.502 12.9788 34.2502 9.72739C30.9996 6.4772 26.5931 4.64974 21.9976 4.64578V39.3346Z"
      fill="#9D9D9D"
    />
    <path
      d="M22.8214 2.99253C26.5513 3.15401 30.1511 4.41063 33.1718 6.60562C36.1925 8.80061 38.5004 11.8368 39.8075 15.3355L42.6614 14.4083C41.1551 10.3092 38.4647 6.75067 34.9319 4.18511C31.3992 1.61955 27.1838 0.162787 22.8214 0V2.99253Z"
      fill="#C0C0C0"
    />
    <path
      d="M40.3125 16.9004C40.7745 18.5567 41.0088 20.2682 41.0088 21.9877C41.0107 24.85 40.3655 27.6757 39.1214 30.2532C37.8774 32.8307 36.0668 35.0934 33.8251 36.8719L35.5855 39.2953C38.2085 37.2381 40.3293 34.6106 41.7871 31.612C43.245 28.6134 44.0017 25.3222 44 21.9877C44.001 19.9553 43.7187 17.9326 43.1615 15.9781L40.3125 16.9004Z"
      fill="#C0C0C0"
    />
    <path
      d="M4.19262 15.3355C5.49972 11.8368 7.80756 8.80061 10.8283 6.60562C13.8489 4.41063 17.4488 3.15401 21.1787 2.99253V0C16.8168 0.16245 12.6019 1.61915 9.06985 4.18483C5.53784 6.7505 2.84841 10.3092 1.34363 14.4083L4.19262 15.3355Z"
      fill="#EEEEEE"
    />
    <path
      d="M32.4962 37.8432C29.3839 39.9114 25.7289 41.0108 21.9926 41.0025C18.2582 41.0086 14.6054 39.9094 11.494 37.8432L9.73364 40.2667C13.3565 42.7061 17.6256 44.0062 21.9926 44C26.3597 44.0065 30.6289 42.7064 34.2516 40.2667L32.4962 37.8432Z"
      fill="#C0C0C0"
    />
    <path
      d="M10.175 36.8719C7.93327 35.0934 6.12265 32.8307 4.87861 30.2532C3.63458 27.6757 2.98935 24.85 2.9912 21.9877C2.99123 20.2682 3.2255 18.5567 3.68752 16.9004L0.838514 15.9781C0.283852 17.9332 0.00168568 19.9555 2.84339e-06 21.9877C-0.00169283 25.3222 0.755048 28.6134 2.21291 31.612C3.67077 34.6106 5.79152 37.2381 8.41458 39.2953L10.175 36.8719Z"
      fill="#EEEEEE"
    />
    <path
      d="M25.1016 16.2823V15.3011C25.1022 14.5149 24.9067 13.7409 24.5327 13.0494L23.552 11.249L22.2133 8.79607C22.192 8.75832 22.1609 8.72692 22.1235 8.70508C22.086 8.68323 22.0434 8.67172 22 8.67172C21.9567 8.67172 21.9141 8.68323 21.8766 8.70508C21.8391 8.72692 21.8081 8.75832 21.7867 8.79607L20.4578 11.249L19.4771 13.0543C19.1062 13.7486 18.9125 14.5237 18.9132 15.311V16.2921V26.1037H25.1065L25.1016 16.2823ZM20.4922 15.0951C20.4922 14.6905 20.6528 14.3024 20.9388 14.0163C21.2248 13.7301 21.6127 13.5694 22.0172 13.5694C22.2195 13.5694 22.4199 13.6096 22.6065 13.6878C22.7931 13.766 22.9624 13.8806 23.1043 14.0249C23.2462 14.1692 23.3581 14.3403 23.4332 14.5282C23.5084 14.7162 23.5455 14.9172 23.5422 15.1196L20.4922 15.0951Z"
      fill="white"
    />
    <path
      d="M17.3293 17.9601C16.6018 19.2973 15.6573 20.5042 14.5342 21.5315C14.2261 21.7853 13.9783 22.1045 13.8087 22.466C13.639 22.8274 13.5519 23.2221 13.5535 23.6214V25.5837H17.3244L17.3293 17.9601Z"
      fill="white"
    />
    <path
      d="M26.6903 17.9601C27.4177 19.2986 28.3642 20.5058 29.4903 21.5315C29.7973 21.7862 30.0444 22.1056 30.2139 22.4668C30.3835 22.8281 30.4712 23.2223 30.471 23.6214V25.5837H26.7001L26.6903 17.9601Z"
      fill="white"
    />
    <path
      d="M23.3901 26.8935V26.9377L21.9926 30.1215L20.6294 26.9278C20.6294 26.9278 20.6294 26.8984 20.6294 26.8837C20.3199 27.1705 20.1067 27.546 20.0189 27.9588C19.9311 28.3716 19.9731 28.8014 20.139 29.1894L22.0171 33.5801L23.8903 29.1894C24.055 28.8021 24.0954 28.3731 24.0058 27.9618C23.9162 27.5504 23.7011 27.1772 23.3901 26.8935Z"
      fill="white"
    />
    <path d="M29.8923 27.5264H29.1567V30.7544H29.8923V27.5264Z" fill="white" />
    <path d="M27.9505 28.9981H27.215V34.2718H27.9505V28.9981Z" fill="white" />
    <path d="M32.5355 23.2436H31.7999V28.5173H32.5355V23.2436Z" fill="white" />
    <path d="M14.8383 27.5264H14.1028V30.7544H14.8383V27.5264Z" fill="white" />
    <path d="M16.785 28.9981H16.0494V34.2718H16.785V28.9981Z" fill="white" />
    <path d="M12.1953 23.2436H11.4597V28.5173H12.1953V23.2436Z" fill="white" />
  </svg>
);

export default CruiseDisabled;
