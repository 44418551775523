import React from 'react';
import styled from 'styled-components';
import { sharedStyles } from './styles';
import useAssets from 'shared/useAssets';
import { IncomeSummaryRow } from 'state/store/reportApi';
import { AssetAmountPair } from 'interfaces/AssetAmountPair.interface';

interface Props {
    year: number;
    summaryData: IncomeSummaryRow[] | undefined;
    totalIncome: AssetAmountPair | undefined;
}

const CustodyIncomeSummary: React.FC<Props> = ({ year, summaryData, totalIncome }) => {
    const assets = useAssets();
    return (
        <sharedStyles.Container>
            <Header>
                <TaxInfoContainer>
                    <TaxInfoCard>
                        <TaxInfoLabel>Tax Year</TaxInfoLabel>
                        <TaxInfoValue>{year}</TaxInfoValue>
                    </TaxInfoCard>
                    <TaxInfoCard>
                        <TaxInfoLabel>Total Income</TaxInfoLabel>
                        <TaxInfoValue>
                            ${totalIncome ? parseFloat(totalIncome.amount).toFixed(2) : '0.00'}
                        </TaxInfoValue>
                    </TaxInfoCard>
                </TaxInfoContainer>
                <TitleSection>
                    <Title>Income Summary</Title>
                </TitleSection>
            </Header>

            <sharedStyles.TableContainer>
                <sharedStyles.Table>
                    <thead>
                        <tr>
                            <sharedStyles.Th>Strategy Name</sharedStyles.Th>
                            <sharedStyles.Th>Quantity</sharedStyles.Th>
                            <sharedStyles.Th align='right'>Income</sharedStyles.Th>
                        </tr>
                    </thead>
                    <tbody>
                        {summaryData?.map((row, index) => (
                            <sharedStyles.TableRow key={index}>
                                <sharedStyles.Td>{row.strategyName}</sharedStyles.Td>
                                <sharedStyles.Td>
                                    <QuantityCell>
                                        <div>
                                        {assets.getPriceFormattedI(row.quantity.asset, row.quantity.amount)}
                                        </div>
                                    </QuantityCell>
                                </sharedStyles.Td>
                                <sharedStyles.Td>
                                    <div>${parseFloat(row.income.amount).toFixed(2)}</div>
                                </sharedStyles.Td>
                            </sharedStyles.TableRow>
                        ))}
                    </tbody>
                </sharedStyles.Table>
            </sharedStyles.TableContainer>
        </sharedStyles.Container>
    );
};

const Header = styled.div`
    margin-bottom: 32px;
`;

const TitleSection = styled.div`
    margin-bottom: 24px;
`;

const Title = styled.h2`
    color: white;
    font-size: 20px;
    margin-bottom: 8px;
`;

const TaxInfoContainer = styled.div`
    display: flex;
    gap: 24px;
    margin-bottom: 32px;
`;

const TaxInfoCard = styled.div`
    background: rgba(255, 255, 255, 0.05);
    padding: 16px 24px;
    border-radius: 8px;
    min-width: 200px;
`;

const TaxInfoLabel = styled.div`
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    margin-bottom: 4px;
`;

const TaxInfoValue = styled.div`
    color: white;
    font-size: 24px;
    font-weight: 600;
`;

const QuantityCell = styled.div`
    display: flex;
    flex-direction: column;
`;

export default CustodyIncomeSummary;
