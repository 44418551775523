import React, { FC, useEffect, useState, useRef } from 'react';
import Icon from 'components/atoms/Icon';
import Image from 'components/atoms/Image';
import { useScrollingUp } from 'hooks/useScrollingUp';
import { zIndex, breakpoints } from 'lib/theme';
import { BreakpointsEnum } from '@types/enums/GridSystem';
import defaultImage from "/public/assets/png/defaultImage.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { AssetsSelector, MarketSelector } from 'state/slice/assets.slice';
import styled from 'styled-components';
import ProfileMenu from './ProfileMenu';
import { Assets } from 'services/models';
import OrganizationViewer from 'components/atoms/OrganizationViewer/OrganizationViewer';
import OngoingWithdrawAlert from 'pages/Withdraw/OngoingWithdrawAlert';

const Header: FC<{ onToggleSideBar: () => void }> = ({ onToggleSideBar }) => {
  const [status, setStatus] = useState<string>('');
  const [cryptocurrency, setCryptocurrency] = useState<Assets[]>([]);
  const {
    auth: { user },
  }: any = useSelector((state: any) => state);

  const assets = useSelector(AssetsSelector);
  const marketInfo = useSelector(MarketSelector);

  const countRef = useRef(status);
  countRef.current = status;
  const userPhotoSize = user?.rewardsProfile?.member ? 32 : 40;

  useEffect(() => {
    if (assets && marketInfo) {
      const currencies = Object.entries(assets)
        .filter(([key, asset]) => asset.assetType === 'NC')
        .map(([key, asset]) => {
          let market = {} as Assets;

          if (marketInfo.hasOwnProperty(key)) {
            market = marketInfo[key];
          }
          return { ...asset, midPriceAmount: market.midPriceAmount };
        });

      setCryptocurrency(currencies);
    }
  }, [assets, marketInfo]);

  const { scrollingUp, setScrollingUp } = useScrollingUp();
  const dispatch = useDispatch();

  useEffect(() => {
    setScrollingUp(window.scrollY > 50);
  }, [user, cryptocurrency, dispatch, setScrollingUp]);

  return (
    <HeaderContainer scrollingUp={scrollingUp}>
      <HeaderBar scrollingUp={scrollingUp}>
        <MenuAndOrganizationContainer>
          <MenuButton onClick={onToggleSideBar}>
            <Icon name='MenuOutlined' size={24} />
          </MenuButton>
          <OrganizationViewer />
        </MenuAndOrganizationContainer>
        <Menus>
          <OngoingWithdrawAlert />
          <UserContainer className='containers'>
            <ImageProfile>
              <Image circle src={defaultImage} width={userPhotoSize} height={userPhotoSize} />
            </ImageProfile>
            <div style={{ marginTop: '13px', zIndex: '100' }}>
              <ProfileMenu userName={user?.fullName} userEmail={user?.email} userAvatar={user?.photoUrl} scrollingUp={scrollingUp} />
            </div>
          </UserContainer>
        </Menus>
      </HeaderBar>
    </HeaderContainer>
  );
};

const MenuAndOrganizationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;
const HeaderContainer = styled.header<{ scrollingUp: boolean }>`
  background: rgb(7, 4, 28, 0.8);
  top: ${(props) => (props.scrollingUp ? 0 : '')};
  height: 72px;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: ${zIndex.header};
  @media print{
       display:none;
       height: 0px;
  }
`;

const AlertOngoingWithdrawsBox = styled.div`
  background-color: white;
`;
const HeaderBar = styled.div<{ scrollingUp: boolean }>`
  display: flex;
  width: 100%;
  position: relative;
  position: inither;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    backdrop-filter: ${(props) => (props.scrollingUp ? 'blur(50px)' : 'none')};
  }
`;

const ImageProfile = styled.div`
  margin-right: 26.3px;
  @media only screen and (max-width: 1439px) {
    margin-right: 27.3px;
  }
  position: absolute;
`;

const MenuButton = styled.div`
  display: none;
  cursor: pointer;
  @media only screen and (max-width: 1200px) {
    display: inline-block;
    margin-left: 20px;
    width: 24px;
    height: 24px;
  }
`;

const Menus = styled.div`
  flex: 2;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  padding-right: 40px;
  @media only screen and (max-width: ${breakpoints.values[BreakpointsEnum.Medium]}${breakpoints.measurement}) {
    padding-right: 20px;
  }
  justify-content: flex-end;
  div.containers {
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 6.7px;
  padding-left: 40px;
  gap: 6.75px;
`;

export default Header;
