import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Overview: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M9.5 18C14.1944 18 18 14.1944 18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18Z"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.902 8.90918C23.823 12.2482 23.692 17.3272 20.509 20.5102C17.369 23.6502 12.383 23.8202 9.04297 21.0192"
      stroke={color}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.7081 13.421C12.1893 13.15 12.3597 12.5402 12.0887 12.059C11.8177 11.5778 11.2079 11.4074 10.7267 11.6783L11.7081 13.421ZM6.00005 9.48123L7.00004 9.48659L6.00005 9.48123ZM6.47598 7.73782L5.61197 7.23434L5.61197 7.23434L6.47598 7.73782ZM7.75814 6.46423L8.25581 7.33159L7.75814 6.46423ZM9.50469 6L9.50603 5L9.50469 6ZM10.75 7.33494C11.2283 7.61108 11.8399 7.4472 12.116 6.96891C12.3922 6.49062 12.2283 5.87903 11.75 5.60289L10.75 7.33494ZM10.7267 11.6783C10.3453 11.8931 9.91422 12.004 9.47653 11.9999L9.45776 13.9998C10.2456 14.0072 11.0216 13.8076 11.7081 13.421L10.7267 11.6783ZM9.47653 11.9999C9.03885 11.9958 8.60991 11.8768 8.23262 11.6549L7.21872 13.3789C7.89784 13.7783 8.66992 13.9924 9.45776 13.9998L9.47653 11.9999ZM8.23262 11.6549C7.85533 11.433 7.54291 11.116 7.3266 10.7355L5.58789 11.7238C5.97724 12.4088 6.53959 12.9795 7.21872 13.3789L8.23262 11.6549ZM7.3266 10.7355C7.1103 10.3549 6.99769 9.92429 7.00004 9.48659L5.00006 9.47586C4.99584 10.2637 5.19854 11.0389 5.58789 11.7238L7.3266 10.7355ZM7.00004 9.48659C7.00238 9.04889 7.11961 8.61948 7.33998 8.2413L5.61197 7.23434C5.21529 7.91506 5.00429 8.688 5.00006 9.47586L7.00004 9.48659ZM7.33998 8.2413C7.56036 7.86312 7.87616 7.54943 8.25581 7.33159L7.26046 5.59686C6.57709 5.98897 6.00865 6.55362 5.61197 7.23434L7.33998 8.2413ZM8.25581 7.33159C8.63546 7.11376 9.06565 6.99942 9.50335 7L9.50603 5C8.71817 4.99895 7.94383 5.20476 7.26046 5.59686L8.25581 7.33159ZM9.50335 7C9.94106 7.00059 10.3709 7.11608 10.75 7.33494L11.75 5.60289C11.0677 5.20895 10.2939 5.00106 9.50603 5L9.50335 7Z"
      fill={color}
    />
  </svg>
);

export default Overview;
