import Currency from "components/atoms/Number/Currency";
import { Text } from "components/atoms/Typography";
import React from "react";
import { Transaction } from "services/models";
import { Number } from "components/atoms/Number";
import {
  formatNumberDisplay,
  fullDateformatter,
  getDecimalCount,
} from "./helper";
import { Order } from "state/slice/transactions.slice";
import { palette } from "lib/theme";
import { getText } from "./locale-helper";
import { TransactionDto } from "state/store/api";

interface TransactionAmount extends Transaction {
  destinationAssetFractionDigits: any;
  originAssetFractionDigits: any;
}

export const getTransactionAmount = ({
  balanceDelta,
  originAsset,
  destinationAsset,
  type,
  destinationAssetFractionDigits,
  originAssetFractionDigits,
}: TransactionAmount) => {
  const decimalMinValue = getDecimalCount(parseFloat(balanceDelta));

  const text = new Map<string, string>([
    [
      "OrderCredit",
      `${formatNumberDisplay(
        balanceDelta,
        "decimal",
        "auto",
        destinationAssetFractionDigits > decimalMinValue
          ? destinationAssetFractionDigits
          : decimalMinValue,
        decimalMinValue
      )} ${destinationAsset}`,
    ],
    [
      "OrderDebit",
      `${formatNumberDisplay(
        balanceDelta,
        "decimal",
        "auto",
        originAssetFractionDigits > decimalMinValue
          ? originAssetFractionDigits
          : decimalMinValue,
        decimalMinValue
      )} ${originAsset}`,
    ],
    [
      "BankDeposit",
      `${formatNumberDisplay(
        balanceDelta,
        "decimal",
        "auto",
        originAssetFractionDigits > decimalMinValue
          ? originAssetFractionDigits
          : decimalMinValue,
        decimalMinValue
      )} ${originAsset}`,
    ],
    [
      "BankWithdrawal",
      `${formatNumberDisplay(
        balanceDelta,
        "decimal",
        "auto",
        destinationAssetFractionDigits > decimalMinValue
          ? destinationAssetFractionDigits
          : decimalMinValue,
        decimalMinValue
      )} ${destinationAsset}`,
    ],
    [
      "DepositCrypto",
      `${formatNumberDisplay(
        balanceDelta,
        "decimal",
        "auto",
        originAssetFractionDigits > decimalMinValue
          ? originAssetFractionDigits
          : decimalMinValue,
        decimalMinValue
      )} ${originAsset}`,
    ],
    [
      "WithdrawCrypto",
      `${formatNumberDisplay(
        balanceDelta,
        "decimal",
        "auto",
        destinationAssetFractionDigits > decimalMinValue
          ? destinationAssetFractionDigits
          : decimalMinValue,
        decimalMinValue
      )} ${destinationAsset}`,
    ],
    [
      "ReceiveFunds",
      `${formatNumberDisplay(
        balanceDelta,
        "decimal",
        "auto",
        originAssetFractionDigits > decimalMinValue
          ? originAssetFractionDigits
          : decimalMinValue,
        decimalMinValue
      )} ${originAsset}`,
    ],
    [
      "SendFunds",
      `${formatNumberDisplay(
        balanceDelta,
        "decimal",
        "auto",
        destinationAssetFractionDigits > decimalMinValue
          ? destinationAssetFractionDigits
          : decimalMinValue,
        decimalMinValue
      )} ${destinationAsset}`,
    ],
    [
      "ConvertCurrencyCredit",
      `${formatNumberDisplay(
        balanceDelta,
        "decimal",
        "auto",
        destinationAssetFractionDigits > decimalMinValue
          ? destinationAssetFractionDigits
          : decimalMinValue,
        decimalMinValue
      )} ${destinationAsset}`,
    ],
    [
      "ConvertCurrencyDebit",
      `${formatNumberDisplay(
        balanceDelta,
        "decimal",
        "auto",
        originAssetFractionDigits > decimalMinValue
          ? originAssetFractionDigits
          : decimalMinValue,
        decimalMinValue
      )} ${originAsset}`,
    ],
  ]);
  return (
    text.get(type) ??
    `${formatNumberDisplay(
      balanceDelta,
      "decimal",
      "auto",
      originAssetFractionDigits > decimalMinValue
        ? originAssetFractionDigits
        : decimalMinValue,
      decimalMinValue
    )} ${originAsset}`
  );
};

export const OrderTypeReference = {
  "one-time-repay":"One-Time Repayment",
  "matching-repayment": "Equal Principal Payment",
  "matching-service": "Equal Loan Payment",
  "monthly-repay-interest": "Repay principal at due date and pay interest monthly.",
  "period-compounding": "Repay principal at due date & Roll interest into principal.",
}

export const TypeReferenceTxrCompleted = {
  "add-order": {
    icon: "Icon1",
    getReference: () => `Add Order`,
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },

  "add-broker-loan-record": {
    icon: "Icon4",
    getReference: () => "Add Collateral when add order",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "add-principal": {
    icon: "Icon4",
    getReference: () => "Add Principal",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "add-principle": {
    icon: "Icon4",
    getReference: () => "Add Principal",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },

  "repay-in-advance": {
    icon: "Icon2",
    getReference: () => "Repay in advance",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "add-collateral": {
    icon: "Icon4",
    getReference: () => "Add Collateral",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "add-broker-loan-collateral": {
    icon: "Icon4",
    getReference: () => "Add Collateral",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "withdraw-collateral": {
    icon: "IncomingTransaction",
    getReference: () => `Withdraw Collateral`,
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "repay-interest": {
    icon: "Icon5",
    getReference: () => "Repay Interest",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "repay-principle": {
    icon: "Icon5",
    getReference: () => "Repay Principal",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "settle-repay": {
    icon: "Icon2",
    getReference: () => "Repay Principle when settling",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "interest-to-principal": {
    icon: "Icon4",
    getReference: () => "Interest to Principal",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "repay-penalty": {
    icon: "Icon5",
    getReference: () => "Repay penalty",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "repay-commission": {
    icon: "Icon5",
    getReference: () => "Repay Management Fee",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "settle-broker-loan-record": {
    icon: "Icon5",
    getReference: () => "Remove Collateral when settle",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "remove-broker-loan-collateral": {
    icon: "Icon5",
    getReference: () => "Withdraw collateral Remove Collatera",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  "remove-collateral": {
    icon: "Icon5",
    getReference: () => "Remove collateral",
    getDetail: (expresion: TransactionDto) => {
      const entries = {
        "Time created": fullDateformatter(new Date(expresion?.date)),
        "Order ID": expresion?.orderId,
        "Transaction ID": `${expresion?.operationId}`,
      };
      return entries;
    },
  },
  WithdrawCrypto: {
    icon: "Buy",
    getReference: (expresion: Transaction) =>
      `${getText("Withdrawal of")} ${expresion.originAsset}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Sent To": expresion?.counterparty?.blockchainAddress,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      return entries;
    },
  },
  ConvertCurrencyCredit: {
    icon: "Swap",
    getReference: (expresion: Transaction) =>
      `${expresion.destinationAsset} ${getText("received")}`,
    getReferenceDetail: (expresion: Transaction) =>
      `${expresion.originAsset} ${getText("paid")}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Exchange To": expresion?.destinationAsset,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      if (expresion?.networkFeeValue > 0)
        entries["Fee"] = expresion?.networkFeeValue;
      if (expresion?.cashBack > 0)
        entries["Cashback earned"] = expresion?.cashBack;
      return entries;
    },
    getRecurrentTxrView: (expresion: Transaction) => ({
      label: `${expresion?.destinationAsset} buy`,
      date: fullDateformatter(new Date(expresion?.entryDate)),
      amount: expresion?.exchangeRate?.rate,
    }),
  },
  ConfirmTransactionCredit: {
    icon: "Swap",
    getReference: (expresion: Transaction) =>
      `${getText("Exchange from")} ${expresion.originAsset}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Exchange To": expresion?.destinationAsset,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      if (expresion?.networkFeeValue > 0)
        entries["Fee"] = expresion?.networkFeeValue;
      if (expresion?.cashBack > 0)
        entries["Cashback earned"] = expresion?.cashBack;
      return entries;
    },
  },
  OrderDebit: {
    icon: "Swap",
    getReference: (expresion: Transaction) =>
      `${expresion.originAsset} ${getText("paid")}`,
    getReferenceDetail: (expresion: Transaction) =>
      `${expresion.destinationAsset} ${getText("received")}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Exchange To": expresion?.destinationAsset,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      if (expresion?.networkFeeValue > 0)
        entries["Fee"] = expresion?.networkFeeValue;
      if (expresion?.cashBack > 0)
        entries["Cashback earned"] = expresion?.cashBack;
      return entries;
    },
    getRecurrentTxrView: (expresion: Transaction) => ({
      label: `${expresion?.originAsset} ${getText("withdraw")}`,
      date: fullDateformatter(new Date(expresion?.entryDate)),
      amount: expresion?.exchangeRate?.rate,
    }),
  },
  ConvertCurrencyDebit: {
    icon: "Swap",
    getReference: (expresion: Transaction) =>
      `${expresion.originAsset} ${getText("paid")}`,
    getReferenceDetail: (expresion: Transaction) =>
      `${expresion.destinationAsset} ${getText("received")}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Exchange To": expresion?.destinationAsset,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      if (expresion?.networkFeeValue > 0)
        entries["Fee"] = expresion?.networkFeeValue;
      if (expresion?.cashBack > 0)
        entries["Cashback earned"] = expresion?.cashBack;
      return entries;
    },
    getRecurrentTxrView: (expresion: Transaction) => ({
      label: `${expresion?.originAsset} withdraw`,
      date: fullDateformatter(new Date(expresion?.entryDate)),
      amount: expresion?.exchangeRate?.rate,
    }),
  },
  ConfirmTransactionDebit: {
    icon: "Swap",
    getReference: (expresion: Transaction) =>
      `Exchange to ${expresion.destinationAsset}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Exchange To": expresion?.destinationAsset,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      if (expresion?.networkFeeValue > 0)
        entries["Fee"] = expresion?.networkFeeValue;
      if (expresion?.cashBack > 0)
        entries["Cashback earned"] = expresion?.cashBack;
      return entries;
    },
  },
  SendFunds: {
    icon: "Buy",
    getReference: (expresion: Transaction) =>
      `Sent to ${expresion.counterparty?.firstName} ${expresion.counterparty?.lastName}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Sent to ": `${expresion.counterparty?.firstName} ${expresion.counterparty?.lastName}`,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      if (expresion?.networkFeeValue > 0)
        entries["Fee"] = expresion?.networkFeeValue;
      if (expresion?.cashBack > 0)
        entries["Cashback earned"] = expresion?.cashBack;
      return entries;
    },
  },
  BankWithdrawal: {
    icon: "Buy",
    getReference: (expresion: Transaction) =>
      `Withdrawal to ${expresion.counterparty?.name}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Sent to ": `${expresion.counterparty?.name} ${expresion.counterparty?.accountLastFour}`,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      if (expresion?.networkFeeValue > 0)
        entries["Fee"] = expresion?.networkFeeValue;
      if (expresion?.cashBack > 0)
        entries["Cashback earned"] = expresion?.cashBack;
      return entries;
    },
  },
  ReceiveFunds: {
    icon: "Sell",
    getReference: (expresion: Transaction) =>
      `${getText("Receive from")} ${expresion.counterparty?.firstName} ${
        expresion.counterparty?.lastName
      }`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Receive From": `${expresion.counterparty?.firstName} ${expresion.counterparty?.lastName}`,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      if (expresion?.networkFeeValue > 0)
        entries["Fee"] = expresion?.networkFeeValue;
      if (expresion?.cashBack > 0)
        entries["Cashback earned"] = expresion?.cashBack;
      return entries;
    },
  },
  BankDeposit: {
    icon: "Sell",
    getReference: (expresion: Transaction) =>
      `${getText("Deposit from")} ${expresion.counterparty?.name} ${
        expresion.counterparty?.accountLastFour
      }`,
    getRow: ({ amount, rate, originAsset }: Transaction) => {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Currency value={rate} />
          <div>
            <Text size="tiny" opacity={0.7}>
              <Number value={amount} /> {originAsset}
            </Text>
          </div>
        </div>
      );
    },
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Receive From": `${expresion.counterparty?.name} ${expresion.counterparty?.accountLastFour}`,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      if (expresion?.networkFeeValue > 0)
        entries["Fee"] = expresion?.networkFeeValue;
      if (expresion?.cashBack > 0)
        entries["Cashback earned"] = expresion?.cashBack;
      return entries;
    },
    getRecurrentTxrView: (expresion: Transaction) => ({
      label: `${expresion?.asset} deposit`,
      date: fullDateformatter(new Date(expresion?.entryDate)),
      amount: expresion?.exchangeRate?.rate,
    }),
  },
  InterestBearingDepositCredit: {
    icon: "IncomingTransaction",
    getReference: (expresion: Transaction) =>
      getText(`Interest Deposit Credit`),
    getDetail: (expresion: Transaction) => {
      const entries = {
        From: expresion?.destinationAsset,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
      };
      return entries;
    },
  },
  InterestBearingDepositDebit: {
    icon: "OutcomingTransaction",
    getReference: (expresion: Transaction) => getText(`Interest Deposit Debit`),
    getDetail: (expresion: Transaction) => {
      const entries = {
        From: expresion?.destinationAsset,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
      };
      return entries;
    },
  },
  InterestPayment: {
    icon: "CashBack",
    getReference: (expresion: Transaction) =>
      `${getText("Interest Payment")} ${expresion.asset}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        From: expresion?.destinationAsset,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
      };
      return entries;
    },
  },
  InterestBearingWithdrawalCredit: {
    icon: "IncomingTransaction",
    getReference: (expresion: Transaction) =>
      getText(`Interest Withdrawal Credit`),
    getDetail: (expresion: Transaction) => {
      const entries = {
        From: expresion?.destinationAsset,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
      };
      return entries;
    },
  },
  InterestBearingWithdrawalDebit: {
    icon: "OutcomingTransaction",
    getReference: (expresion: Transaction) =>
      getText(`Interest Withdrawal Debit`),
    getDetail: (expresion: Transaction) => {
      const entries = {
        From: expresion?.destinationAsset,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
      };
      return entries;
    },
  },
  LoanInterestPaymentDebit: {
    icon: "Buy",
    getReference: (expresion: Transaction) => `${getText("Interest Payment")}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        From: expresion?.counterparty?.uid,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
      };
      return entries;
    },
  },
  LoanCollateralDepositDebit: {
    icon: "Buy",
    getReference: (expresion: Transaction) =>
      `${getText("Loan Collateral Deposit Debit")} ${expresion.asset}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        From: expresion?.counterparty?.uid,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
      };
      return entries;
    },
  },
  LoanPrincipalDisbursementCredit: {
    icon: "Buy",
    getReference: (expresion: Transaction) =>
      `${getText("Loan Principal Disbursement Credit")} ${expresion.asset}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        From: expresion?.counterparty?.uid,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
      };
      return entries;
    },
  },
  LoanPrincipalRepaymentDebit: {
    icon: "Sell",
    getReference: (expresion: Transaction) =>
      `${getText("Loan Principal RepaymentDebit")} ${expresion.asset}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        From: expresion?.counterparty?.uid,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
      };
      return entries;
    },
  },
  LoanInterestRefundCredit: {
    icon: "Sell",
    getReference: (expresion: Transaction) =>
      `${getText("Loan Interest Refund Credit")} ${expresion.asset}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        From: expresion?.counterparty?.uid,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
      };
      return entries;
    },
  },
  LoanCollateralReturnCredit: {
    icon: "Sell",
    getReference: (expresion: Transaction) =>
      `${getText("Loan Collateral Return Credit")} ${expresion.asset}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        From: expresion?.counterparty?.uid,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
      };
      return entries;
    },
  },
  DepositCrypto: {
    icon: "Sell",
    getReference: (expresion: Transaction) =>
      `${getText("Deposit of")} ${expresion.originAsset}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Receive From": expresion?.counterparty?.blockchainAddress,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      if (expresion?.networkFeeValue > 0)
        entries["Fee"] = expresion?.networkFeeValue;
      if (expresion?.cashBack > 0)
        entries["Cashback earned"] = expresion?.cashBack;
      return entries;
    },
  },
  RewardPayment: {
    icon: "Swap",
    getReference: (expresion: Transaction) => getText(`Cash paid out`),
    getDetail: (expresion: Transaction) => {
      const entries = {
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };
      return entries;
    },
  },
  Perx: {
    icon: "Swap",
    getReference: (expresion: Transaction) => expresion?.destinationAsset,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Accrued Date/Time": expresion?.earnedAt
          ? fullDateformatter(new Date(expresion?.earnedAt))
          : "",
        "Completed Date/Time": expresion?.paidAt
          ? fullDateformatter(new Date(expresion?.paidAt))
          : "",
      };
      return entries;
    },
  },
  OrderCredit: {
    icon: "Buy",
    getReference: (expresion: Transaction) =>
      `${expresion.destinationAsset} ${getText("received")}`,
    getReferenceDetail: (expresion: Transaction) =>
      `${expresion.originAsset} ${getText("paid").toLowerCase()}`,
    getDetail: (expresion: Transaction) => {
      const entries = {
        "Created Date/Time": fullDateformatter(new Date(expresion?.entryDate)),
        "Order ID:": expresion?.uid,
        "Exchange Rate": `1 ${expresion?.exchangeRate.base} = ${expresion?.exchangeRate.rate} ${expresion?.exchangeRate.quote}`,
        Completed: fullDateformatter(new Date(expresion?.entryDate)),
        Price: `1 ${expresion?.exchangeRate?.base} = ${expresion?.exchangeRate?.rate} ${expresion?.exchangeRate?.quote}`,
      };

      if (expresion?.networkFeeValue > 0)
        entries["Fee"] = expresion?.networkFeeValue;
      entries["Est. Cash Back Rewards"] = 0.0;
      return entries;
    },
    getRecurrentTxrView: (expresion: Transaction) => ({
      label: `${expresion?.destinationAsset} ${getText("buy")}`,
      date: fullDateformatter(new Date(expresion?.entryDate)),
      amount: expresion?.exchangeRate?.rate,
    }),
  },
  collateral_liquidation_missed_interest_payment: {
    icon: "OutcomingLoan",
    getReference: () => getText("Collateral Liquidation due Interest"),
  },
  collateral_liquidation_missed_principal_repayment: {
    icon: "OutcomingLoan",
    getReference: () => getText("Collateral Liquidation due Principal"),
  },
  collateral_liquidation_expired_collateral_call: {
    icon: "OutcomingLoan",
    getReference: () =>
      getText("Collateral Liquidation due Expired Collateral Call"),
  },
  collateral_liquidation_ltv_threshold: {
    icon: "OutcomingLoan",
    getReference: () => getText("Collateral Liquidation due LTV Threshold"),
  },
  collateral_liquidation: {
    icon: "OutcomingLoan",
    getReference: () => getText("Collateral Liquidation"),
  },
} as {
  [key: string]: {
    icon: string;
    getReference: (expression: Transaction) => string;
    getReferenceDetail?: (expression: Transaction) => string;
    getDetail?: (expression: Transaction) => object;
    getRecurrentTxrView?: (expression: Transaction) => object;
  };
};

export const TypeReferenceTxrScheduled = {
  exchange: {
    icon: "Clock",
    getReference: (expresion: Transaction) =>
      `${getText("Buy")} ${expresion.destinationAsset}`,
  },
  bank_deposit: {
    icon: "Clock",
    getReference: (expresion: Transaction) =>
      `${getText("Deposit of")}  ${expresion.destinationAsset}`,
  },
  deposit_exchange: {
    icon: "Clock",
    getReference: (expresion: Transaction) =>
      `${getText("Deposit")} ${expresion.destinationAsset}`,
  },
} as {
  [key: string]: {
    icon: string;
    getReference: (expression: Transaction) => string;
  };
};

export const OrderStatus = {
  open: getText("Open"),
  partially_canceled: getText("Partially completed"),
  filled: getText("Completed"),
  canceled: getText("Canceled"),
  failed: getText("Failed"),
};

export const OrderType = {
  market: "Market Order",
  limit_price: "Limit Price Order",
  price_protected: "Price protected",
};
export const TypeReferenceOpen = {
  OpenOrder: {
    icon: "Swap",
    getReference: (expresion: Order) =>
      `${expresion?.originAmount?.currency} ${getText("swapped")} ${
        expresion?.destinationCurrency
      }`,
    getAmount: ({ originAmount }: Order) => {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <Number value={originAmount.value} />
          {originAmount.currency}
        </div>
      );
    },
    getPrice: ({ estimatedSteps }: Order) => {
      return (
        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <Number value={estimatedSteps?.firstStepRate?.rate} />
          {estimatedSteps?.firstStepRate?.quote}
        </div>
      );
    },
    getStatus: (expresion: Order, props: any = {}) => {
      return (
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Text
            {...props}
            opacity={0.7}
            letterSpacing="-0.5"
            color={
              expresion?.orderStatus === "canceled" ||
              expresion?.orderStatus === "failed"
                ? palette.red.main
                : palette.orange.main
            }
          >
            {OrderStatus[expresion?.orderStatus]}
          </Text>
        </div>
      );
    },
  },
} as {
  [key: string]: {
    icon: string;
    getReference: (expression: Order) => string;
    getAmount: (expression: Order) => JSX.Element;
    getPrice: (expression: Order) => JSX.Element;
    getStatus: (expression: Order, props: any) => JSX.Element;
  };
};
