import React, { FC, useEffect } from "react";
import { palette } from "../../../lib/theme";
import { ToastType, ToastProps } from "./Toast.interface";
import Icon from "../../atoms/Icon";
import {
  Wrapper,
  Close,
  Body,
  TitleContainer,
  Title,
  Content,
} from "./Toast.styles";

const getTitleColor = (type: ToastType) => {
  let color = "";
  switch (type) {
    case ToastType.Fail:
      color = palette.red.main;
      break;
    case ToastType.Success:
      color = palette.green.main;
      break;
    case ToastType.Warning:
      color = palette.orange.main;
      break;
    case ToastType.Info:
    default:
      color = palette.darkBackgroundContrast.white;
      break;
  }
  return color;
};

const Toast: FC<ToastProps> = (props) => {
  const {
    type,
    title,
    content,
    destroy = () => {},
    duration = 3000,
    icon = "InfoCircle",
  } = props;

  useEffect(() => {
    const timer = setTimeout(() => destroy(), duration);

    return () => clearTimeout(timer);
  }, [duration, destroy]);

  return (
    <Wrapper>
      <Close onClick={destroy}>
        <Icon
          name="Cross"
          size={18}
          color={palette.darkBackgroundContrast.white}
        />
      </Close>
      <Body>
        <TitleContainer>
          <Icon name={icon} size={20} color={getTitleColor(type)} />
          <Title color={getTitleColor(type)}>{title}</Title>
        </TitleContainer>
        <Content>{content}</Content>
      </Body>
    </Wrapper>
  );
};

const shouldRerender = (prevProps: ToastProps, nextProps: ToastProps) => {
  return prevProps.id === nextProps.id;
};

export default React.memo(Toast, shouldRerender);
