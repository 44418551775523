import React, { FC, useEffect, useRef } from "react"
import * as d3 from "d3"
import { PieArcDatum } from "d3-shape"

import { BalancePieProps } from "./BalanceWidget.interface"
import { palette } from "lib/theme"
import { DefaultSettings } from "lib/constants/config.constant"

const BalancePieChart: FC<BalancePieProps> = ({
  data,
  reverseColors = false,
  boxSize,
  minRadius = DefaultSettings.PIE_CHART_MINIMUN_VALUE,
}) => {
  const ref = useRef(null)
  useEffect(() => {
    if (ref.current) {
      drawChart(ref.current, data, boxSize, reverseColors, minRadius)
    }
  }, [ref, data, boxSize, reverseColors])

  return (
    <div>
      <div ref={ref}></div>
    </div>
  )
}

export const getColor = (index: number, length: number | undefined, colors: string[] | string) => {
  const l = length == undefined ? 0 : length
  const colorScale = d3.scaleSequential(d3.interpolateRainbow)
                       .domain([0, l - colors.length]);
  return index < colors.length ? colors[index] : colorScale(index - colors.length);
};

const drawChart = (
  element: any,
  data: Array<{ value: number }>,
  boxSize: number = 280,
  reverseColors: boolean = false,
  minRadius: number = DefaultSettings.PIE_CHART_MINIMUN_VALUE
) => {
  const colors = reverseColors
    ? (palette.charts.balancePieChart as string[]).reverse()
    : (palette.charts.balancePieChart as string[])

  const width = boxSize
  const height = boxSize
  const radius = Math.min(width, height) / 2

  const svgElement = d3.select(element).select("svg")
  if (svgElement.empty()) {
    const svg = d3.select(element).append("svg")
    const g = svg.append("g")
  }


  const svg = d3
    .select(element)
    .select("svg")
    .attr("preserveAspectRatio", "xMidYMid meet")
    .attr("height", height)
    .attr("width", width)
    .attr("viewBox", `0 0 ${boxSize} ${boxSize}`)
    .select("g")
    .attr("transform", `translate(${width / 2}, ${height / 2})`)

  const sum = data.reduce((previous, current) => previous + current.value, 0)
  const parsedData = data.map((m) => (m.value / sum) * 100)

  let finalData = parsedData.map((m) => {
    const exactValue = m < minRadius ? minRadius : m
    return exactValue
  })

  const pieGenerator = d3.pie<number>().value((d) => d)
  const path = d3
    .arc<PieArcDatum<{ value: number }>>()
    .padAngle(0.022)
    .cornerRadius(radius / 2)
    .innerRadius(radius)
    .outerRadius(radius - 10)

  const pieData = pieGenerator(finalData)

  

  const arch = svg
    .selectAll(".arc")
    .data(pieData)
    .enter()
    .append("g")
    .attr("class", "arc")
    .attr("fill", (d: any, i: number) => getColor(i, data.length, colors))
    .attr("r", 10)

  arch.append("path").attr("d", path)
}

export default BalancePieChart
