import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Apple: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.23632 3.98601C8.01595 3.98601 8.99324 3.45894 9.57522 2.75617C10.1023 2.11929 10.4866 1.22984 10.4866 0.340404C10.4866 0.219615 10.4756 0.0988268 10.4537 0C9.5862 0.0329423 8.54303 0.58198 7.91713 1.31769C7.42299 1.87771 6.97278 2.75617 6.97278 3.65659C6.97278 3.78836 6.99474 3.92013 7.00572 3.96405C7.06063 3.97503 7.14847 3.98601 7.23632 3.98601ZM4.49113 17.2727C5.55626 17.2727 6.02843 16.559 7.35711 16.559C8.70774 16.559 9.00422 17.2508 10.1901 17.2508C11.3541 17.2508 12.1337 16.1747 12.8694 15.1205C13.693 13.9126 14.0334 12.7267 14.0554 12.6718C13.9785 12.6498 11.7494 11.7384 11.7494 9.17991C11.7494 6.9618 13.5063 5.96255 13.6052 5.88568C12.4412 4.21661 10.6733 4.17269 10.1901 4.17269C8.88343 4.17269 7.8183 4.9633 7.14847 4.9633C6.42374 4.9633 5.46842 4.21661 4.3374 4.21661C2.18517 4.21661 0 5.99549 0 9.3556C0 11.4419 0.812576 13.6491 1.81182 15.0766C2.66832 16.2845 3.41502 17.2727 4.49113 17.2727Z"
      fill="white"
    />
  </svg>
);

export default Apple;
