import React from "react";
import Loading from "components/atoms/Loading";

const LoadingStage = ({loadingText}:{loadingText?: string}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
    }}
  >
    <Loading text={loadingText}/>
  </div>
);

export default LoadingStage;
