import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Bin: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 1H11"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.27381 6H3C2.44772 6 2 5.55229 2 5C2 4.44772 2.44772 4 3 4H5.20784C5.21322 3.99996 5.2186 3.99996 5.22397 4H21C21.5523 4 22 4.44772 22 5C22 5.55229 21.5523 6 21 6H6.27749L7.09913 19.5385L7.09914 19.5386C7.13114 20.0667 7.56861 20.478 8.09697 20.478H16.117C16.646 20.478 17.0828 20.067 17.1148 19.5386L17.1148 19.5386L17.7578 8.93945C17.7912 8.38818 18.2652 7.96839 18.8165 8.00184C19.3678 8.03528 19.7876 8.50928 19.7541 9.06056L19.1111 19.6596C19.0152 21.2433 17.7039 22.478 16.117 22.478H8.09697C6.51136 22.478 5.19884 21.2436 5.1028 19.6597L5.1028 19.6596L4.27381 6Z"
      fill="white"
    />
  </svg>
);

export default Bin;
