import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const SignUpLoanContract: FC<ISVG> = ({
  size = 120,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="120"
    height="121"
    viewBox="0 0 120 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100.689 118H17.7109V2H81.6501C82.9973 2 84.2893 2.53519 85.2421 3.48799L99.2005 17.4464C100.153 18.3992 100.689 19.6912 100.689 21.0384V118H100.689Z"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.5801 18.9336H66.8209"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.6455 35.8672H83.7551"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.6455 47.7227H83.7551"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.6455 59.5781H83.7551"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.6455 71.4297H56.6599"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.7617 92.7543C62.1177 86.9967 66.9001 82.4375 72.7481 82.4375C78.8273 82.4375 83.7553 87.3655 83.7553 93.4447C83.7553 99.5239 78.8273 104.452 72.7481 104.452C71.6577 104.452 70.6033 104.294 69.6089 103.998"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M81.2148 7.07812V18.0854C81.2148 19.9558 82.7308 21.4726 84.602 21.4726H100.689"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SignUpLoanContract;
