import React from "react";
import Button from "components/atoms/Button";
import { Heading, Text } from "components/atoms/Typography";
import { SizeNamesEnum, TypesNamesEnum } from "enums/Button.enum";
import { palette } from "lib/theme";
import styled from "styled-components";
import Icon from "components/atoms/Icon";

interface ISharedKeyConfirmation {
  setShowTwoFAModal: (modal: boolean) => void;
  change2FaFlow: boolean;
  header2FASuccess: boolean;
}
export const SharedKeyConfirmation = ({
  setShowTwoFAModal,
  change2FaFlow,
  header2FASuccess,
}: ISharedKeyConfirmation) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          placeContent: "center",
          paddingTop: "34px",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            placeContent: "center",
          }}
        >
          <Icon name="Success" size={100} color={palette.green.main} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            placeContent: "center",
            paddingTop: "24px",
            gap: "16px",
          }}
        >
          <Heading align="center" variant="h3">
            {header2FASuccess
              ? "2FA method has changed"
              : "2FA has been activated"}
          </Heading>
          <Text
            align="center"
            size="medium"
            letterSpacing="-0.5"
            lineHeight="23.2px"
            color={palette.darkBackgroundContrast.light70}
          >
            You're all set. From now on, you'll use an authenticator app to sign
            into your Abra wallet.
          </Text>
        </div>
      </div>
      <div>
        <Button
          buttonType={TypesNamesEnum.ACCENT}
          size={SizeNamesEnum.EXTRA_LARGE_PLUS}
          label={"Done"}
          onClick={() => {
            setShowTwoFAModal(false);
          }}
        ></Button>
      </div>
    </>
  );
};
