import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const SortDown: FC<ISVG> = ({
  size = 12,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M5.9499 7.98955C5.7999 7.98955 5.6489 7.94455 5.5199 7.85355L2.0839 5.44955C1.7449 5.21255 1.6619 4.74455 1.8999 4.40555C2.1369 4.06455 2.6039 3.98255 2.9439 4.22055L5.9499 6.32455L8.95589 4.22055C9.2969 3.98255 9.7639 4.06655 9.9999 4.40555C10.2369 4.74455 10.1549 5.21255 9.8149 5.44955L6.3799 7.85355C6.2509 7.94455 6.0999 7.98955 5.9499 7.98955Z"
      fill={color}
    />
  </svg>
);

export default SortDown;
