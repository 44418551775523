import { palette } from 'lib/theme';
import React from 'react';
import styled from 'styled-components';
import { Text } from '../Typography';
import { useNavigate } from 'react-router-dom'

const EmailUs = () => {
  const navigate = useNavigate()
  const hideRegister = process.env.HIDE_REGISTER === 'true';
  return hideRegister ? null : <Text size="small" letterSpacing="0.18">
    Don't have an account? <Link onClick={() => navigate(`/register`)}>Click here to register</Link>
  </Text >;
};

const Link = styled.a`
  color: ${palette.accent.light};
  cursor: pointer;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: 0.51px;`
  ;
export default EmailUs;
