import React, { FC, useState } from "react";
import { SharedConfirmPassword } from "./SharedConfirmPassword";
import { SharedKeyInfo } from "./SharedKeyInfo";
import { SharedKeyConfirmation } from "./SharedKeyConfirmation";
import { useEffect } from "react";
import { sharedKeySatus } from "./sharedKeyStatus";
import { SharedKeyCode } from "./SharedKeyCode";

const TwoFAModalBody: FC<{
  setShowTwoFAModal: (modal: boolean) => void;
  setSharedKey: (key: string) => void;
  sharedKey: string;
  email: string;
}> = ({ setShowTwoFAModal, sharedKey, email, setSharedKey }) => {
  const [currentStep, setCurrentStep] = useState("SharedConfirmPassword");
  const [change2FaFlow, setChange2FaFlow] = useState(false);
  const [header2FASuccess, setHeader2FASuccess] = useState(false);
  useEffect(() => {
    if (sharedKey === sharedKeySatus.REJECTED) {
      setCurrentStep("SharedConfirmPassword");
    } else {
      setCurrentStep("SharedConfirmPassword");
    }
  }, [sharedKey]);

  const steps: { [key: string]: JSX.Element } = {
    SharedConfirmPassword: (
      <SharedConfirmPassword
        email={email}
        currentStep={currentStep}
        sharedKey={sharedKey}
        setCurrentStep={setCurrentStep}
        setShowTwoFAModal={setShowTwoFAModal}
        change2FaFlow={change2FaFlow}
      />
    ),
    SharedKeyInfo: (
      <SharedKeyInfo
        email={email}
        currentStep={currentStep}
        sharedKey={sharedKey}
        setCurrentStep={setCurrentStep}
        setShowTwoFAModal={setShowTwoFAModal}
        change2FaFlow={change2FaFlow}
      />
    ),
    SharedKeyCode: (
      <SharedKeyCode
        currentStep={currentStep}
        sharedKey={sharedKey}
        setCurrentStep={setCurrentStep}
        setSharedKey={setSharedKey}
        setShowTwoFAModal={setShowTwoFAModal}
        setChange2FaFlow={setChange2FaFlow}
        change2FaFlow={change2FaFlow}
        setHeader2FASuccess={setHeader2FASuccess}
      />
    ),
    SharedKeyConfirmation: (
      <SharedKeyConfirmation
        change2FaFlow={change2FaFlow}
        setShowTwoFAModal={setShowTwoFAModal}
        header2FASuccess={header2FASuccess}
      />
    ),
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1.25rem",
          height: "100%",
        }}
      >
        {steps[currentStep]}
      </div>
    </>
  );
};

export default TwoFAModalBody;
