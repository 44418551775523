import React, { FC, useState, useEffect } from "react";
import { PaginationTypeEnum } from "@types/enums/Pagination.enum";
import { TableSortDirection } from "@types/enums/Table.enum";

import Head from "./table-components/Head";
import Row from "./table-components/Row";
import RowGroup from "./table-components/RowGroup";
import Pagination from "./table-components/Pagination";
import {
  ITableDefaultProps,
  Data,
} from "components/organisms/AbraTable/interfaces";
import { isFunction } from "lib/utils/helpers";
import { getText } from "shared/locale-helper";
import classNames from "classnames";
import {
  StyledTableContainer,
  StyledTable,
  StyledBody,
  StyledFooter,
  StyledTableWrapper,
} from "./table";

const AbraTable: FC<ITableDefaultProps> = ({
  columns,
  data,
  enableSelect,
  showPagination,
  activePageNumber,
  totalRecords,
  recordsPerPage,
  handlePaginationInside,
  className,
  paginationVariant,
  onAllRowsSelect,
  onRowClick,
  onRowSelection,
  onAscendingSort,
  onDescendingSort,
  onResetSort,
  onPaginationChange,
  showPaginationCaption,
  isLoading,
  smallCorners,
  fullScreenTable,
  rowHeight,
  groupRowsBy,
  tableProps,
  rowSidePadding,
  customRowGroupOptions,
  isSorting = false,
  notHideOnOverflow = false,
  expandTable = false,
  longTable = false,
}) => {
  const [tableData, setTableData] = useState(data);
  const [tableColumns, setTableColumns] = useState(columns);
  const [page, setPage] = useState(activePageNumber);
  const [selectAll, setSelectAll] = useState(false);
  const [sort, setSort] = useState(() =>
    tableColumns.map(({ accessor, sortDirection }) => ({
      accessor,
      status: sortDirection || TableSortDirection.UNSORTED,
    }))
  );
  const [scrolling, setScrolling] = useState<{ left: boolean; right: boolean }>(
    { left: false, right: true }
  );

  useEffect(() => {
    setTableData(data);
  }, [data]);

  useEffect(() => {
    if (handlePaginationInside) {
      if (!activePageNumber) {
        activePageNumber = 1;
      }
      const start = (activePageNumber - 1) * recordsPerPage;
      const end = activePageNumber * recordsPerPage;
      setTableData(data.slice(start, end));
    } else {
      setTableData(data);
    }
  }, [tableData, data]);

  useEffect(() => {
    setPage(activePageNumber);
  }, [activePageNumber]);

  const handleRowClick = (event: any, schema: any) =>
    isFunction(onRowClick) && onRowClick(event, schema);

  const handleSort = (key: string, id: number) => {
    setTableData(data);
    if (sort[id].status === TableSortDirection.UNSORTED) {
      setSort(
        sort.map((item) => ({
          ...item,
          status:
            item.accessor === key
              ? TableSortDirection.DESCENDING
              : TableSortDirection.UNSORTED,
        }))
      );
      onDescendingSort(key, data, setTableData);
      setTableColumns(
        columns.map((column) => ({
          ...column,
          sortDirection:
            column.accessor === key
              ? TableSortDirection.DESCENDING
              : TableSortDirection.UNSORTED,
        }))
      );
    } else if (sort[id].status === TableSortDirection.ASCENDING) {
      setSort(
        sort.map((item) => ({ ...item, status: TableSortDirection.UNSORTED }))
      );
      onResetSort(key, data, setTableData);
      setTableColumns(
        columns.map((column) => ({
          ...column,
          sortDirection: TableSortDirection.UNSORTED,
        }))
      );
    } else if (sort[id].status === TableSortDirection.DESCENDING) {
      setSort(
        sort.map((item) => ({
          ...item,
          status:
            item.accessor === key
              ? TableSortDirection.ASCENDING
              : TableSortDirection.UNSORTED,
        }))
      );
      onAscendingSort(key, data, setTableData);
      setTableColumns(
        columns.map((column) => ({
          ...column,
          sortDirection:
            column.accessor === key
              ? TableSortDirection.ASCENDING
              : TableSortDirection.UNSORTED,
        }))
      );
    }
    setPage(1);
  };

  const handleSelectAll = (event: any) => {
    let isSelectedAll;
    if (!selectAll) {
      setSelectAll((v) => !v);
      setTableData((tableData) =>
        tableData.map((obj) => {
          return { ...obj, isSelected: true };
        })
      );
      isSelectedAll = true;
    } else {
      setSelectAll(false);
      setTableData((tableData) =>
        tableData.map((obj) => {
          return { ...obj, isSelected: false };
        })
      );
      isSelectedAll = false;
    }
    isFunction(onAllRowsSelect) && onAllRowsSelect(event, isSelectedAll);
  };

  const onPageChange = (id: number) => {
    setPage(id);
    isFunction(onPaginationChange) && onPaginationChange(id);
  };

  const positionRef = React.useRef(0);
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const x = e.currentTarget.scrollLeft;
    if (x !== positionRef.current) {
      positionRef.current = x;

      const left =
        x > 0 ||
        parseInt(
          (e.currentTarget.offsetWidth + e.currentTarget.scrollLeft).toString()
        ) ===
          e.currentTarget.scrollWidth - 1;

      const right =
        x === 0 ||
        !(
          parseInt(
            (
              e.currentTarget.offsetWidth + e.currentTarget.scrollLeft
            ).toString()
          ) ===
          e.currentTarget.scrollWidth - 1
        );

      setScrolling({ left, right });
    }
  };

  return (
    <StyledTableWrapper
      longTable={longTable}
      leftScrolling={scrolling.left}
      rightScrolling={scrolling.right}
    >
      <StyledTableContainer
        expandTable={expandTable}
        fullScreenTable={fullScreenTable}
        longTable={longTable}
        onScroll={longTable ? handleScroll : undefined}
      >
        {isLoading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {getText("Loading Data")}
          </div>
        ) : (
          <StyledTable
            longTable={longTable}
            className={classNames({
              [className]: className,
            })}
            {...tableProps}
          >
            <Head
              columns={tableColumns.filter(
                (col) => !groupRowsBy?.includes(col.accessor)
              )}
              isAllDataSelected={selectAll}
              enableSelect={enableSelect}
              sortSettings={sort}
              onSelectAll={handleSelectAll}
              onSortColumn={handleSort}
              smallCorners={longTable ? true : smallCorners}
              rowSidePadding={longTable ? "0" : rowSidePadding}
              isSorting={isSorting}
            />
            <StyledBody isSorting={isSorting}>
              {groupRowsBy?.length &&
              tableColumns.some((col) =>
                groupRowsBy?.includes(col.accessor)
              ) ? (
                <RowGroup
                  data={data}
                  groupBy={groupRowsBy}
                  columns={columns}
                  customRowGroupOptions={customRowGroupOptions}
                />
              ) : (
                tableData?.map((item: Data, indx: number) => (
                  <Row
                    index={indx.toString()}
                    key={`table-row-${indx}`}
                    columns={tableColumns.filter(
                      (col) => !groupRowsBy?.includes(col.accessor)
                    )}
                    data={item}
                    enableSelect={enableSelect}
                    onRowClick={(event: any) =>
                      !isSorting && handleRowClick(event, item?.schema)
                    }
                    onRowSelection={onRowSelection}
                    rowClassName={item?.rowClassName}
                    rowHeight={rowHeight}
                    notHideOnOverflow={notHideOnOverflow}
                  />
                ))
              )}
            </StyledBody>
            <StyledFooter
              widgetVariant={Boolean(
                paginationVariant === PaginationTypeEnum.PAGES
              )}
              recordsPerPage={recordsPerPage}
            >
              {showPagination &&
                Boolean(
                  recordsPerPage < (totalRecords ?? Number(data?.length))
                ) && (
                  <tr>
                    <td
                      style={{
                        paddingTop:
                          paginationVariant === PaginationTypeEnum.NUMBERS
                            ? "20px"
                            : "0",
                      }}
                      colSpan={
                        enableSelect
                          ? tableColumns?.length + 3
                          : tableColumns?.length + 2
                      }
                    >
                      <Pagination
                        activePageNumber={page}
                        perPage={recordsPerPage}
                        totalRecords={totalRecords ?? Number(data?.length)}
                        variant={paginationVariant}
                        showCaption={showPaginationCaption}
                        handlePagination={onPageChange}
                      />
                    </td>
                  </tr>
                )}
            </StyledFooter>
          </StyledTable>
        )}
      </StyledTableContainer>
    </StyledTableWrapper>
  );
};

AbraTable.defaultProps = {
  activePageNumber: 1,
  enableSelect: false,
  showPagination: false,
  showPaginationCaption: false,
  paginationVariant: PaginationTypeEnum.PAGES,
  onAscendingSort: (accessor: string, data: any, callback: any) => {
    const result = [...data].sort((a, b) =>
      a[accessor] > b[accessor] ? 1 : -1
    );
    callback(result);
  },
  onDescendingSort: (accessor: string, data: any, callback: any) => {
    const result = [...data].sort((a, b) =>
      a[accessor] < b[accessor] ? 1 : -1
    );
    callback(result);
  },
  onResetSort: (accessor: string, data: any, callback: any) => {
    callback(data);
  },
  isLoading: false,
  fullScreenTable: false,
  groupRowsBy: [],
};

export default AbraTable;
