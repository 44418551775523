import React, { FC, useEffect, useState } from "react";

import { Column } from "components/organisms/AbraTable/interfaces";
import { DefaultSettings, PagesUrl } from "lib/constants/config.constant";
import { Heading, Text } from "components/atoms/Typography";
import { palette } from "lib/theme";
import { api, useDownloadAttachmentMutation } from "state/store/api";
import AbraTable from "components/organisms/AbraTable";
import Currency from "components/atoms/Number/Currency";
import Icon, { IconBackground } from "components/atoms/Icon/Icon";
import LoadingStage from "pages/LoadingStage";
import PanelWithHeader from "components/molecules/PanelWithHeader";
import Row from "components/atoms/Row/Row";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { selectCurrentToken } from "state/slice/auth.slice";

const AttarchmentWidget = (props) => {
  const [dwload] = useDownloadAttachmentMutation();
  const [transactionsItems, setTransactionsItems] = useState([]);
  const token = useSelector(selectCurrentToken);

  const [page, setPage] = useState(1);
  const recordsPerPage = DefaultSettings.RECORDS_PER_PAGE;

  useEffect(() => {
    if (!props?.data) return;

    const response = !props?.data
      ? []
      : props?.data.map((i: any) => {
          return {
            orderId: props?.orderId,
            fileId: i.id,
            alias: i?.alias,
            fileName: i?.filename,
          };
        });

    setTransactionsItems(response);
  }, [props?.data]);

  const [columns, setColumns] = useState<Column[]>([
    {
      title: "Loan ID",
      enableSort: false,
      accessor: "orderId",
    },
    {
      title: "File Id",
      enableSort: false,
      accessor: "fileId",
    },
    {
      title: "Alias",
      enableSort: false,
      accessor: "alias",
    },
    {
      title: "File Name",
      enableSort: false,
      accessor: "fileName",
    },
    {
      title: "",
      enableSort: false,
      accessor: "action",
    },
  ]);

  const downloadAtt = async (item: any) => {
    try {
      const response = await axios.post(
        `/api/order/${+item.orderId}/download/${+item.fileId}`,
        {},
        {
          headers: {
            Authorization: token ?? "",
          },
          responseType: "arraybuffer",
        }
      );
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(new Blob([response.data]));
      link.href = url;
      link.download = `${item.fileName}.pdf`;
      link.click();
    } catch {}
  };

  const rows = transactionsItems.map((item) => {
    const { orderId, fileId, alias, fileName } = item;
    return {
      schema: item,
      orderId: () => <Text>{orderId}</Text>,
      fileId: () => <Text>{fileId}</Text>,
      alias: () => <Text>{alias}</Text>,
      fileName: () => <Text>{fileName}</Text>,
      action: () => (
        <div onClick={() => downloadAtt(item)}>
          <Icon name="DownloadAtt" size={24} />
        </div>
      ),
    };
  });

  return (
    <PanelWithHeader width="100%" height="563px" title="Attachments" {...props}>
      {props?.isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <LoadingStage loadingText=" " />
        </div>
      ) : props?.data?.length ? (
        <AbraTable
          columns={columns}
          data={rows}
          showPagination={false}
          isLoading={props?.isLoading}
          isSorting={props?.isFetching}
          activePageNumber={page}
          recordsPerPage={recordsPerPage}
          totalRecords={props?.data?.length}
          showPaginationCaption={false}
          expandTable
        />
      ) : (
        <EmptyStage />
      )}
    </PanelWithHeader>
  );
};

const EmptyStage = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Icon name="NoTransactions" size={150} />
      <div style={{ marginBottom: "8px" }}>
        <Heading variant="h3" color={palette.gray.main}>
          No Attachments
        </Heading>
      </div>
    </div>
  </div>
);

export default AttarchmentWidget;
