/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, {
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
} from "axios";
import { Configuration } from "../configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  BASE_PATH,
  COLLECTION_FORMATS,
  RequestArgs,
  BaseAPI,
  RequiredError,
} from "../base";
import { ApiError } from "../models";
import { ReferralsResponse } from "../models";
import { ResponseBase } from "../models";
import { RewardsCodeRequest } from "../models";
import { RewardsInfoResponse } from "../models";
import { RewardsTransactionsResponse } from "../models";
/**
 * RewardsControllerApi - axios parameter creator
 * @export
 */
export const RewardsControllerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary Add a referral code to the user
     * @param {RewardsCodeRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addReferralCode: async (
      body: RewardsCodeRequest,
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling addReferralCode."
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling addReferralCode."
        );
      }
      const localVarPath = `/rewards/add`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get the list of referrals of the user
     * @param {string} token
     * @param {string} [status]
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReferrals: async (
      token: string,
      status?: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getReferrals."
        );
      }
      const localVarPath = `/rewards/referrals`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (sortField !== undefined) {
        localVarQueryParameter["sortField"] = sortField;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get reward info including campaigns
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRewardInfo: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getRewardInfo."
        );
      }
      const localVarPath = `/rewards`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get all rewards transactions
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRewardsTransactions: async (
      token: string,
      status?: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling getRewardsTransactions."
        );
      }
      const localVarPath = `/rewards/transactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "GET",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sortField !== undefined) {
        localVarQueryParameter["sortField"] = sortField;
      }

      if (sortDirection !== undefined) {
        localVarQueryParameter["sortDirection"] = sortDirection;
      }

      if (page !== undefined) {
        localVarQueryParameter["page"] = page;
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size;
      }

      if (status !== undefined) {
        localVarQueryParameter["status"] = status;
      }

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Adds current user to rewards program
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    joinRewardsProgram: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling joinRewardsProgram."
        );
      }
      const localVarPath = `/rewards`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Removes current user to rewards program
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leaveRewardsProgram: async (
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling leaveRewardsProgram."
        );
      }
      const localVarPath = `/rewards`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "DELETE",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Validate if the referral code is valid
     * @param {RewardsCodeRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateCode: async (
      body: RewardsCodeRequest,
      token: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      if (body === null || body === undefined) {
        throw new RequiredError(
          "body",
          "Required parameter body was null or undefined when calling validateCode."
        );
      }
      // verify required parameter 'token' is not null or undefined
      if (token === null || token === undefined) {
        throw new RequiredError(
          "token",
          "Required parameter token was null or undefined when calling validateCode."
        );
      }
      const localVarPath = `/rewards/validate`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, "https://example.com");
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions: AxiosRequestConfig = {
        method: "POST",
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (token !== undefined && token !== null) {
        localVarHeaderParameter["token"] = String(token);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      const query = new URLSearchParams(localVarUrlObj.search);
      for (const key in localVarQueryParameter) {
        query.set(key, localVarQueryParameter[key]);
      }
      for (const key in options.params) {
        query.set(key, options.params[key]);
      }
      localVarUrlObj.search = new URLSearchParams(query).toString();
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      const needsSerialization =
        typeof body !== "string" ||
        localVarRequestOptions.headers["Content-Type"] === "application/json";
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(body !== undefined ? body : {})
        : body || "";

      return {
        url:
          localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * RewardsControllerApi - functional programming interface
 * @export
 */
export const RewardsControllerApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Add a referral code to the user
     * @param {RewardsCodeRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addReferralCode(
      body: RewardsCodeRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await RewardsControllerApiAxiosParamCreator(
        configuration
      ).addReferralCode(body, token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get the list of referrals of the user
     * @param {string} token
     * @param {string} [status]
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReferrals(
      token: string,
      status?: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ReferralsResponse>>
    > {
      const localVarAxiosArgs = await RewardsControllerApiAxiosParamCreator(
        configuration
      ).getReferrals(
        token,
        status,
        sortField,
        sortDirection,
        page,
        size,
        options
      );
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get reward info including campaigns
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRewardInfo(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<RewardsInfoResponse>>
    > {
      const localVarAxiosArgs = await RewardsControllerApiAxiosParamCreator(
        configuration
      ).getRewardInfo(token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get all rewards transactions
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRewardsTransactions(
      token: string,
      status?: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<RewardsTransactionsResponse>>
    > {
      const localVarAxiosArgs = await RewardsControllerApiAxiosParamCreator(
        configuration
      ).getRewardsTransactions(
        token,
        status,
        sortField,
        sortDirection,
        page,
        size,
        options
      );
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Adds current user to rewards program
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async joinRewardsProgram(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await RewardsControllerApiAxiosParamCreator(
        configuration
      ).joinRewardsProgram(token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Removes current user to rewards program
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async leaveRewardsProgram(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<ResponseBase>>
    > {
      const localVarAxiosArgs = await RewardsControllerApiAxiosParamCreator(
        configuration
      ).leaveRewardsProgram(token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Validate if the referral code is valid
     * @param {RewardsCodeRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateCode(
      body: RewardsCodeRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => Promise<AxiosResponse<RewardsInfoResponse>>
    > {
      const localVarAxiosArgs = await RewardsControllerApiAxiosParamCreator(
        configuration
      ).validateCode(body, token, options);
      return (
        axios: AxiosInstance = globalAxios,
        basePath: string = BASE_PATH
      ) => {
        const axiosRequestArgs: AxiosRequestConfig = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * RewardsControllerApi - factory interface
 * @export
 */
export const RewardsControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Add a referral code to the user
     * @param {RewardsCodeRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addReferralCode(
      body: RewardsCodeRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return RewardsControllerApiFp(configuration)
        .addReferralCode(body, token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get the list of referrals of the user
     * @param {string} token
     * @param {string} [status]
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReferrals(
      token: string,
      status?: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ReferralsResponse>> {
      return RewardsControllerApiFp(configuration)
        .getReferrals(
          token,
          status,
          sortField,
          sortDirection,
          page,
          size,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get reward info including campaigns
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRewardInfo(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<RewardsInfoResponse>> {
      return RewardsControllerApiFp(configuration)
        .getRewardInfo(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get all rewards transactions
     * @param {string} token
     * @param {string} [sortField]
     * @param {string} [sortDirection]
     * @param {string} [page]
     * @param {string} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRewardsTransactions(
      token: string,
      status?: string,
      sortField?: string,
      sortDirection?: string,
      page?: string,
      size?: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<RewardsTransactionsResponse>> {
      return RewardsControllerApiFp(configuration)
        .getRewardsTransactions(
          token,
          status,
          sortField,
          sortDirection,
          page,
          size,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Adds current user to rewards program
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async joinRewardsProgram(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return RewardsControllerApiFp(configuration)
        .joinRewardsProgram(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Removes current user to rewards program
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async leaveRewardsProgram(
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<ResponseBase>> {
      return RewardsControllerApiFp(configuration)
        .leaveRewardsProgram(token, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Validate if the referral code is valid
     * @param {RewardsCodeRequest} body
     * @param {string} token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateCode(
      body: RewardsCodeRequest,
      token: string,
      options?: AxiosRequestConfig
    ): Promise<AxiosResponse<RewardsInfoResponse>> {
      return RewardsControllerApiFp(configuration)
        .validateCode(body, token, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * RewardsControllerApi - object-oriented interface
 * @export
 * @class RewardsControllerApi
 * @extends {BaseAPI}
 */
export class RewardsControllerApi extends BaseAPI {
  /**
   *
   * @summary Add a referral code to the user
   * @param {RewardsCodeRequest} body
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RewardsControllerApi
   */
  public async addReferralCode(
    body: RewardsCodeRequest,
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return RewardsControllerApiFp(this.configuration)
      .addReferralCode(body, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get the list of referrals of the user
   * @param {string} token
   * @param {string} [status]
   * @param {string} [sortField]
   * @param {string} [sortDirection]
   * @param {string} [page]
   * @param {string} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RewardsControllerApi
   */
  public async getReferrals(
    token: string,
    status?: string,
    sortField?: string,
    sortDirection?: string,
    page?: string,
    size?: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ReferralsResponse>> {
    return RewardsControllerApiFp(this.configuration)
      .getReferrals(
        token,
        status,
        sortField,
        sortDirection,
        page,
        size,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get reward info including campaigns
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RewardsControllerApi
   */
  public async getRewardInfo(
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<RewardsInfoResponse>> {
    return RewardsControllerApiFp(this.configuration)
      .getRewardInfo(token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Get all rewards transactions
   * @param {string} token
   * @param {string} [sortField]
   * @param {string} [sortDirection]
   * @param {string} [page]
   * @param {string} [size]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RewardsControllerApi
   */
  public async getRewardsTransactions(
    token: string,
    status?: string,
    sortField?: string,
    sortDirection?: string,
    page?: string,
    size?: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<RewardsTransactionsResponse>> {
    return RewardsControllerApiFp(this.configuration)
      .getRewardsTransactions(
        token,
        status,
        sortField,
        sortDirection,
        page,
        size,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Adds current user to rewards program
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RewardsControllerApi
   */
  public async joinRewardsProgram(
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return RewardsControllerApiFp(this.configuration)
      .joinRewardsProgram(token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Removes current user to rewards program
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RewardsControllerApi
   */
  public async leaveRewardsProgram(
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<ResponseBase>> {
    return RewardsControllerApiFp(this.configuration)
      .leaveRewardsProgram(token, options)
      .then((request) => request(this.axios, this.basePath));
  }
  /**
   *
   * @summary Validate if the referral code is valid
   * @param {RewardsCodeRequest} body
   * @param {string} token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RewardsControllerApi
   */
  public async validateCode(
    body: RewardsCodeRequest,
    token: string,
    options?: AxiosRequestConfig
  ): Promise<AxiosResponse<RewardsInfoResponse>> {
    return RewardsControllerApiFp(this.configuration)
      .validateCode(body, token, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
