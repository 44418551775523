import SignInCarousel from "components/molecules/SignInCarousel/SignInCarousel";
import styled from "styled-components";
import { palette } from "lib/theme";

export const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 450px;
  flex-direction: column;
  padding-left: 4px;
  padding-right: 4px;
`;

export const StyledNavigation = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
  a {
    color: ${palette.white.main};
    text-decoration: none;
  }
`;

export const StyledFormWrapper = styled.div`
  margin-top: 4.3vh;
  width: 100%;
  padding-left: 0.5rem;
`;

export const StyledHeading = styled.div`
  margin-top: 4vh;
  margin-bottom:3vh;
  alignment: left;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: normal;
  }

  display: flex;
  flex-direction: column;
  gap: 3vh;
`;

export const StyledForm = styled.div`
  
  .input {
    width: 100%;
    margin-bottom: 2vh;
  }
`;

export const StyledBottomLinks = styled.div`
  align-items: end;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
`;

export const StyledAuthenticationContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: ${palette.background.elevation1};
  height: 100vh;
  width: 100vw;
`;

export const StyleAuthenticationFormContainer = styled.div`
  display: flex;
  flex: 1 1 50%;
  background-color: ${palette.background.elevation0};
  justify-content: center;
  padding: 1vh 4vw;
`;

export const StyleAuthenticationCarouselContainer = SignInCarousel;
