import React from 'react';
import { MarginContainer } from '../Custody';
import CustodyNavigationWidget from '../CustodyNavigationWidget';
import Layout from 'components/templates/Layout';
import useCustodyRedirect from '../extraCustodyHooks/useCustodyRedirect';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import useInvest from './useInvest';
import useAssets from 'shared/useAssets';
import Image from 'components/atoms/Image';
import { CustodyMainText, CustodyStyledTable } from '../CustodyStyles';
import { QuestionCircle } from 'assets/icons';
import { DefiBalanceRow } from 'state/store/investApi';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading'
import { Percentage } from 'lib/utils/types';

const InvestSelectStrategy = () => {
  const { asset } = useParams();
  const { defiBalances } = useInvest();
  const { getAssetByIdentifier } = useAssets();
  const balances = defiBalances.data ?? [];

  const assetInformation = getAssetByIdentifier(asset);
  const availableInvestments = balances?.filter((balance) => balance.strategySource === asset);

  const loading = defiBalances.isLoading;
  useCustodyRedirect();

  return (
    <Layout>
      <MarginContainer>
        <CustodyNavigationWidget>
          {loading ? (
            <Loading size={SpinnerSizeEnum.LARGE} showText={false} />
          ) : (
            <>
              <Header>
                <PageTitle>
                  <div>Invest {assetInformation?.name}</div>
                  <div>{assetInformation && <Image src={assetInformation?.icon} />}</div>
                </PageTitle>
              </Header>
              <p>Choose Investment Strategy</p>
              <MarginContainer>
                <CustodyStyledTable>
                  <thead>
                    <tr>
                      <th>Network</th>
                      <th>Strategy</th>
                      <th>Yield</th>
                      <th>Current Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {availableInvestments.map((record, index) => (
                      <CustodySummaryRow key={index} record={record} />
                    ))}
                  </tbody>
                </CustodyStyledTable>
              </MarginContainer>
            </>
          )}
        </CustodyNavigationWidget>
      </MarginContainer>
    </Layout>
  );
};

interface CustodySummaryRowProps {
  record: DefiBalanceRow;
}

const CustodySummaryRow: React.FC<CustodySummaryRowProps> = ({ record }) => {
  const navigate = useNavigate();
  const { getPriceFormattedI } = useAssets();
  const goToStrategyDetails = (strategyIdentifier: string) => {
    navigate(`/custody/invest/details/${strategyIdentifier}`);
  };

  return (
    <tr>
      <td>
        <CustodyMainText>{record.network}</CustodyMainText>
      </td>
      <td onClick={() => goToStrategyDetails(record.identifier)}>
        <CustodyClickableText>
          {record.strategyName} <QuestionCircle color='#9f7fff' />
        </CustodyClickableText>
      </td>
      <td>
        <CustodyMainText>{new Percentage(record.yield?.apr).print()}</CustodyMainText>
      </td>
      <td>
        <CustodyMainText>{getPriceFormattedI(record.currentBalance?.asset, record.currentBalance?.amount)}</CustodyMainText>
      </td>
      <td>
        <ActionButton onClick={() => navigate(`/custody/invest/${record.identifier}`)}>Invest</ActionButton>
      </td>
    </tr>
  );
};

const ActionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #6f2acd;
  border-radius: 0.5vw;
  text-transform: uppercase;
  font-size: 70%;
  padding: 0.5vw 1vw;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #292749;
  }
`;

export const CustodyClickableText = styled.span`
  cursor: pointer;
  color: #9f7fff;
  display: inline;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const PageTitle = styled.h2`
  font-weight: 600;
  color: #ffffff;
  font-size: 28px;
  font-weight: bolder;
  line-height: 130%;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export default InvestSelectStrategy;
