import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Favorite: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0.5C12.3806 0.5 12.7282 0.716099 12.8967 1.05743L15.9703 7.28527L22.8431 8.28395C23.2198 8.33869 23.5328 8.60253 23.6504 8.96454C23.768 9.32655 23.6699 9.72395 23.3974 9.98965L18.4241 14.8373L19.5981 21.6824C19.6625 22.0576 19.5083 22.4367 19.2003 22.6605C18.8924 22.8842 18.4841 22.9137 18.1472 22.7366L12 19.5048L5.85271 22.7366C5.51579 22.9137 5.10753 22.8842 4.79958 22.6605C4.49164 22.4367 4.33741 22.0576 4.40176 21.6824L5.57578 14.8373L0.602555 9.98965C0.329982 9.72395 0.231885 9.32655 0.34951 8.96454C0.467135 8.60253 0.780082 8.33869 1.15677 8.28395L8.02959 7.28527L11.1032 1.05743C11.2717 0.716099 11.6193 0.5 12 0.5ZM12 3.75955L9.5904 8.64185C9.44473 8.93699 9.16317 9.14156 8.83746 9.18889L3.44953 9.9718L7.34827 13.7721C7.58396 14.0019 7.69151 14.3329 7.63587 14.6573L6.7155 20.0234L11.5346 17.4899C11.8259 17.3367 12.174 17.3367 12.4653 17.4899L17.2844 20.0234L16.364 14.6573C16.3084 14.3329 16.4159 14.0019 16.6516 13.7721L20.5504 9.9718L15.1624 9.18889C14.8367 9.14156 14.5552 8.93699 14.4095 8.64184L12 3.75955Z"
      fill={color}
    />
  </svg>
);

export default Favorite;