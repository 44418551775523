import React, { useState } from "react";
import Button from "components/atoms/Button";
import { Text } from "components/atoms/Typography";
import { SizeNamesEnum, TypesNamesEnum } from "enums/Button.enum";
import { palette } from "lib/theme";
import DigitVerification from "components/atoms/DigitVerification";
import { useDispatch, useSelector } from "react-redux";
import { TwoFaModalHeader } from "./TwoFaModalHeader";
import { sharedKeySatus } from "./sharedKeyStatus";
import { getText } from "shared/locale-helper";
import { useValidateMfaMutation } from "state/store/api";
import { selectTokenToValidate, setCredentials } from "state/slice/auth.slice";

interface ISharedKeyCode {
  sharedKey: string;
  currentStep: string;
  change2FaFlow: boolean;
  setCurrentStep: (step: string) => void;
  setSharedKey: (key: string) => void;
  setShowTwoFAModal: (modal: boolean) => void;
  setChange2FaFlow: (secondFlow: boolean) => void;
  setHeader2FASuccess: (headerText: boolean) => void;
}

export const SharedKeyCode = ({
  sharedKey,
  currentStep,
  change2FaFlow,
  setCurrentStep,
  setShowTwoFAModal,
}: ISharedKeyCode) => {
  const [validateCode, { isLoading }] = useValidateMfaMutation();
  const tokenToValidate = useSelector(selectTokenToValidate);
  const dispatch = useDispatch();
  const [code, setCode] = useState([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleEnableKey = async (code: string) => {
    try {
      const data = (await validateCode({
        mfaAuthenticationRequest: {
          mfaToken: tokenToValidate?.accessToken,
          otpCode: code,
        },
      }).unwrap()) as { accessToken: string };
      dispatch(setCredentials({ accessToken: data.accessToken }));
      setCurrentStep("SharedKeyConfirmation");
    } catch (error) {
      setErrorMessage(
        "Something's off. Double-check the code or request a new one."
      );
    }
  };

  const handleOnChange = (event) => {
    setErrorMessage(undefined);
    setCode([...code, event?.target?.value]);
  };

  const handleOnKeyDown = (event, indx) => {
    if (errorMessage) setErrorMessage(undefined);
    if (event.keyCode === 8) {
      if (code[indx]) {
        const updatedCureentValue = code.slice(0, indx);
        setCode(updatedCureentValue);
        return;
      }
      const updatedValue = code.slice(0, indx - 1);
      setCode(updatedValue);
    }
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <TwoFaModalHeader
        sharedKey={sharedKey}
        change2FaFlow={change2FaFlow}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setShowTwoFAModal={setShowTwoFAModal}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: "63px",
        }}
      >
        <Text
          color={palette.darkBackgroundContrast.light70}
          letterSpacing="-0.5"
          align="center"
        >
          Enter the one-time code generated in the authenticator app
        </Text>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingTop: "24.5px",
        }}
      >
        <DigitVerification
          code={code}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          errorMessage={errorMessage}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingBottom: "15px",
          flex: 1,
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Button
          dataCyPrefix="profile_walletsecurity_change2fa"
          buttonType={TypesNamesEnum.ACCENT}
          size={SizeNamesEnum.EXTRA_LARGE_PLUS}
          label="Confirm"
          isLoading={isLoading}
          onClick={() => {
            const verificationCode = code.join("");
            handleEnableKey(verificationCode);
          }}
        ></Button>
        <Text
          display="block"
          size="small"
          color={palette.darkBackgroundContrast.light70}
        >
          Can't access the authenticator app?{" "}
          <Text
            size="small"
            color={palette.accent.light}
            onClick={() => window.open("https://support.abra.com")}
          >
            Contact Customer Support <br />
          </Text>{" "}
          to change 2FA.
        </Text>
      </div>
    </div>
  );
};
