import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const DownArrowSelect: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M12.0001 16.0013C11.8001 16.0013 11.5991 15.9403 11.4271 15.8203L1.42705 8.82033C0.974055 8.50433 0.864052 7.88033 1.18105 7.42733C1.49705 6.97333 2.12006 6.86433 2.57406 7.18133L12.0011 13.7803L21.4281 7.18133C21.8781 6.86533 22.5041 6.97333 22.8211 7.42733C23.1371 7.87933 23.0281 8.50333 22.5751 8.82033L12.575 15.8203C12.401 15.9403 12.2001 16.0013 12.0001 16.0013Z"
        fill={color}
      />
    </svg>
  );
};
export default DownArrowSelect;
