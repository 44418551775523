import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const LeftArrowSmall: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
    <path
      d='M11.29 12L14.83 8.46C15.0163 8.27264 15.1208 8.01919 15.1208 7.755C15.1208 7.49082 15.0163 7.23736 14.83 7.05C14.737 6.95627 14.6264 6.88188 14.5046 6.83111C14.3827 6.78034 14.252 6.7542 14.12 6.7542C13.988 6.7542 13.8573 6.78034 13.7354 6.83111C13.6136 6.88188 13.503 6.95627 13.41 7.05L9.17 11.29C9.07628 11.383 9.00188 11.4936 8.95111 11.6154C8.90035 11.7373 8.87421 11.868 8.87421 12C8.87421 12.132 8.90035 12.2627 8.95111 12.3846C9.00188 12.5064 9.07628 12.617 9.17 12.71L13.41 17C13.5034 17.0927 13.6143 17.166 13.7361 17.2158C13.8579 17.2655 13.9884 17.2908 14.12 17.29C14.2516 17.2908 14.3821 17.2655 14.5039 17.2158C14.6257 17.166 14.7366 17.0927 14.83 17C15.0163 16.8126 15.1208 16.5592 15.1208 16.295C15.1208 16.0308 15.0163 15.7774 14.83 15.59L11.29 12Z'
      fill={color}
    />
  </svg>
);

export default LeftArrowSmall;