import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const RightArrowRefer: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M8.0504 23.0006C7.8524 23.0006 7.65341 22.9416 7.47841 22.8196C7.02541 22.5026 6.9154 21.8796 7.2324 21.4266L13.8304 12.0006L7.2314 2.57361C6.9154 2.12161 7.0244 1.49762 7.4774 1.18062C7.9284 0.864616 8.5534 0.973618 8.8704 1.42662L15.8704 11.4266C16.1114 11.7706 16.1114 12.2286 15.8704 12.5726L8.8704 22.5726C8.6764 22.8526 8.3654 23.0006 8.0504 23.0006Z"
      fill={color}
      fillOpacity="0.7"
    />
  </svg>
);

export default RightArrowRefer;
