import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const TickCircle: FC<ISVG> = ({
  size = 100,
  width = size,
  height = size,
  color,
  svgProps,
}) => {
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.9477 48.4637C27.0991 47.7566 25.838 47.8712 25.1309 48.7198C24.4237 49.5683 24.5384 50.8295 25.3869 51.5366L27.9477 48.4637ZM74.8957 34.5829C75.5857 33.7204 75.4459 32.4618 74.5834 31.7718C73.7208 31.0817 72.4623 31.2216 71.7722 32.0841L74.8957 34.5829ZM45.8836 66.0137L44.6032 67.5502L45.8836 66.0137ZM47.3046 65.8702L45.7429 64.6208L47.3046 65.8702ZM25.3869 51.5366L44.6032 67.5502L47.1639 64.4773L27.9477 48.4637L25.3869 51.5366ZM48.8664 67.1196L74.8957 34.5829L71.7722 32.0841L45.7429 64.6208L48.8664 67.1196ZM50.0006 94.6668C25.3319 94.6668 5.33398 74.6689 5.33398 50.0002H1.33398C1.33398 76.878 23.1228 98.6668 50.0006 98.6668V94.6668ZM94.6673 50.0002C94.6673 74.6689 74.6694 94.6668 50.0006 94.6668V98.6668C76.8785 98.6668 98.6673 76.878 98.6673 50.0002H94.6673ZM50.0006 5.3335C74.6694 5.3335 94.6673 25.3314 94.6673 50.0002H98.6673C98.6673 23.1223 76.8785 1.3335 50.0006 1.3335V5.3335ZM50.0006 1.3335C23.1228 1.3335 1.33398 23.1223 1.33398 50.0002H5.33398C5.33398 25.3314 25.3319 5.3335 50.0006 5.3335V1.3335ZM44.6032 67.5502C45.8941 68.6259 47.8167 68.4317 48.8664 67.1196L45.7429 64.6208C46.0928 64.1834 46.7337 64.1187 47.1639 64.4773L44.6032 67.5502Z"
        fill={color}
      />
    </svg>
  );
};

export default TickCircle;
