import { palette } from "lib/theme";
import styled from "styled-components";

const Divider = styled.hr<{
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
}>`
  height: 1px;
  border-width: 0;
  color: ${palette.darkBackgroundContrast.light10};
  background-color: ${palette.darkBackgroundContrast.light10};
  margin-top: ${(props) => (props.top ? `${props.top}rem` : "none")};
  margin-bottom: ${(props) => (props.bottom ? `${props.bottom}rem` : "none")};
  margin-right: ${(props) => (props.right ? `${props.right}rem` : "none")};
  margin-left: ${(props) => (props.left ? `${props.left}rem` : "none")};
`;

export default Divider;
