import styled from "styled-components"
import { palette } from "lib/theme"

export const Styled2FAContainer = styled.div`
  margin: 5px 0;
`

export const Styled2FAInputWrapper = styled.div<{ error?: boolean }>`
  display: flex;
  width: 100%;
  max-width: 500px;
  justify-content: space-between;
  ${({ error }) => error && `
    background-color: ${palette.red.light15};
    input {
      color: ${palette.darkBackgroundContrast.white};
      background-color: ${palette.red.light0};

      &:focus,
      &:active,
      &.filled {
        border-bottom: 1px solid ${palette.red.light70};
      }
    }
  `}
`

export const Styled2FAInput = styled.input`
  border: 1px solid transparent;
  background-color: transparent;
  background-clip: padding-box;
  display: block;
  width: 100%;
  outline: none;
  color: ${palette.darkBackgroundContrast.white};
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  border-bottom: 1px solid rgba(255,255,255,0.5);
  margin-right: 10px;
  max-width: 48px;
  padding-bottom: 4px;

  &:focus,
  &:active {
    border-bottom: 1px solid ${palette.accent.main};
  }
  &.filled {
    border-bottom: 1px solid ${palette.darkBackgroundContrast.white};
  }
`
