import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Error: FC<ISVG> = ({
  size = 100,
  width = size,
  height = size,
  color = "#EC396B",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33398 50C3.33398 75.7733 24.2274 96.6667 50.0006 96.6667C75.7739 96.6667 96.6673 75.7733 96.6673 50C96.6673 24.2268 75.7739 3.33337 50.0006 3.33337C24.2274 3.33337 3.33398 24.2268 3.33398 50Z"
      stroke="#EC396B"
      strokeWidth="4"
    />
    <path d="M31 69L69 31" stroke="#EC396B" strokeWidth="4" />
    <path d="M69 69L31 31" stroke="#EC396B" strokeWidth="4" />
  </svg>
);

export default Error;
