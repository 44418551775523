import { LanguageWrapperDefaultProps } from "interfaces/components/atoms/Atom.interface";
import React, { createContext, FC } from "react";
import {
  createIntl,
  createIntlCache,
  IntlShape,
  RawIntlProvider,
} from "react-intl";
import { getUserLocale } from "shared/getUserLocale";
import English from "locale/en.json";
import Spanish from "locale/es.json";

export const LanguageContext = createContext(
  {} as {
    intl: IntlShape;
    onHandleSelectLanguage: (selectedLang: { id: string }) => void;
  }
);

const cache = createIntlCache();

const local = getUserLocale();

let lang: any;
if (local.startsWith("es")) {
  lang = Spanish;
} else {
  lang = English;
}

export let intl = createIntl(
  { locale: getUserLocale(), messages: lang },
  cache
);

export const LanguageWrapper: FC<LanguageWrapperDefaultProps> = ({
  children,
}) => {
  const onHandleSelectLanguage = (selectedLang: { id: string }) => {
    if (String(selectedLang.id).startsWith("es")) {
      intl = createIntl({ locale: selectedLang.id, messages: Spanish }, cache);
    } else {
      intl = createIntl({ locale: selectedLang.id, messages: English }, cache);
    }
  };

  return (
    <LanguageContext.Provider value={{ intl, onHandleSelectLanguage }}>
      <RawIntlProvider value={intl}>{children}</RawIntlProvider>
    </LanguageContext.Provider>
  );
};
