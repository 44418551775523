import Divider from 'components/atoms/Divider';
import moment from 'moment';
import Sidebar from 'components/molecules/Sidebar';
import bigDecimal from 'js-big-decimal';
import { palette } from 'lib/theme';
import React, { FC } from 'react';
import getAssetPrice from 'shared/getAssetPrice';
import usePrices from 'shared/pricesUpdater';
import useAssets from 'shared/useAssets';
import { useGetTransactionsQuery } from 'state/store/api';
import styled from 'styled-components';
import useTransaction from './useTransaction';
import { capitalize } from 'shared';
import Spinner from 'components/atoms/Spinner';
import { SpinnerSizeEnum } from '@types/enums/Loading.enum';
import Loading from 'components/atoms/Loading';

interface TransactionSidebarProps {
  transactionId: number;
  show: boolean;
  setShow: (show: boolean) => void;
  setTransactionId: (id: number) => void;
}

const TransactionSidebar: FC<TransactionSidebarProps> = ({ show, setShow, transactionId, setTransactionId }) => {
  const assets = useAssets();
  const prices = usePrices();
  const { transaction, getType, isLoading, isError } = useTransaction(transactionId);
  const asset = assets.assets.find((a) => transaction?.currency && transaction?.currency.toUpperCase() == a.name);
  const price = prices.find((p) => p.identifier?.toUpperCase() == asset?.name?.toUpperCase());
  const isOnBorrow = window.location.href.indexOf('borrow') != -1;
  const isLoan = isOnBorrow && !transaction?.withdrawRequest;

  const convertStatus = (status: string) => {
    if(status.toUpperCase() == 'WAITING_FOR_FUNDS') {
      return "PENDING"
    }
    return capitalize(status)
  }
  const t = (t1: number, t2: number): string => {
    const t1String = t1.toString();
    const t2String = t2.toString();
    const t1HasDecimal = t1String.indexOf('.') != -1;
    const t2HasDecimal = t2String.indexOf('.') != -1;
    if (!t1HasDecimal || !t2HasDecimal) return t1String;
    const t1Trail = t1String.split('.')[1];
    const t2Trail = t1String.split('.')[1];
    const digits = Math.max(t1Trail.length, t2Trail.length);
    return t1.toFixed(digits);
  };

  return (
    <Sidebar show={show} onClose={() => setShow(false)} title={!isLoading ? getType(transaction?.transactionType ?? 'repay-principle') : 'Repay Principal'}>
      {isLoading && (
        <>
          <Loading showText={false} size={SpinnerSizeEnum.LARGE} showRoundSpinner />
        </>
      )}
      {!isLoading && isError && (
        <>
          <p>Your transaction details will be available here in a few minutes.</p>
        </>
      )}
      {!isLoading && !isError && (
        <>
          <AmountBox>
            {transaction?.withdrawRequest?.amount && (
              <h1>{assets.getPriceFormatted(transaction?.withdrawRequest.currency, transaction?.withdrawRequest?.amount)}</h1>
            )}
            {!transaction?.withdrawRequest?.amount && <h1>{assets.getPriceFormatted(transaction?.currency, transaction?.transactionAmount)}</h1>}
          </AmountBox>
          <Divider />
          <DetailsBox>
            {transaction?.orderId && (
              <Details>
                <p>{isLoan ? 'Loan' : 'Deposit'} ID</p>
                <p>#{transaction?.orderId}</p>
              </Details>
            )}
            {transaction?.operationId && (
              <Details>
                <p>Transaction ID</p>
                <p>#{transaction?.operationId}</p>
              </Details>
            )}
            {transaction?.date && (
              <Details>
                <p>Date</p>
                <p>{transaction?.date}</p>
              </Details>
            )}
            <Divider />
            {transaction?.withdrawRequest?.withdrawalFee && transaction.transactionAmount && (
              <Details>
                <p>Amount Transferred</p>
                <p>
                  {t(
                    +bigDecimal.subtract(transaction.transactionAmount, transaction.withdrawRequest.withdrawalFee),
                    +transaction?.withdrawRequest?.withdrawalFee
                  )}{' '}
                  {transaction.withdrawRequest.currency}
                </p>
              </Details>
            )}
            {transaction?.withdrawRequest?.withdrawalFee && (
              <Details>
                <p>Network Fee</p>
                <p>
                  {t(
                    +transaction?.withdrawRequest?.withdrawalFee,
                    +bigDecimal.subtract(transaction.transactionAmount, transaction.withdrawRequest.withdrawalFee)
                  )}{' '}
                  {transaction.withdrawRequest.currency}
                </p>
              </Details>
            )}
            {transaction?.withdrawRequest?.processingDate && (
              <Details>
                <p>Processed Date</p>
                <p>{moment.utc(transaction?.withdrawRequest?.processingDate).format('MM/DD/yyyy HH:mm:ss')} UTC</p>
              </Details>
            )}

            {transaction?.withdrawRequest?.status && (
              <Details>
                <p>Status</p>
                <p>{convertStatus(transaction?.withdrawRequest?.status)}</p>
              </Details>
            )}
            {transaction?.withdrawRequest?.networkTransactionId && (
              <Details>
                <p>Blockchain ID</p>
                <p>{transaction?.withdrawRequest?.networkTransactionId}</p>
              </Details>
            )}
            {transaction?.withdrawRequest?.whitelistAddress && (
              <Details>
                <p>Address</p>
                <p>{transaction?.withdrawRequest?.whitelistAddress}</p>
              </Details>
            )}
            {transaction?.withdrawRequest?.transactionIdRollback && transaction?.withdrawRequest?.transactionId && (
              <>
                {transaction?.operationId == transaction?.withdrawRequest?.transactionIdRollback && (
                  <>
                    <Summary onClick={() => setTransactionId(transaction?.withdrawRequest?.transactionId ?? 0)}>
                      <p>
                        Refund for transaction <TransactionSpan>#{transaction?.withdrawRequest?.transactionId}</TransactionSpan>
                      </p>
                    </Summary>
                  </>
                )}
                {transaction.operationId == transaction?.withdrawRequest?.transactionId && (
                  <Summary onClick={() => setTransactionId(transaction?.withdrawRequest?.transactionIdRollback ?? 0)}>
                    <p>
                      Refund information at transaction <TransactionSpan>#{transaction?.withdrawRequest?.transactionIdRollback}</TransactionSpan>
                    </p>
                  </Summary>
                )}
              </>
            )}
          </DetailsBox>
        </>
      )}
    </Sidebar>
  );
};

const TransactionSpan = styled.span`
  font-weight: bold;
  color: #9f7fff;
  cursor: pointer;
`;
const Summary = styled.div`
  flex-grow: 1;
  display: flex;
  margin-top: 2vh;
  align-items: flex-end;
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0.8);
`;
const Details = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.8);
  font-size: 2vh;
  letter-spacing: 0.1rem;
  gap: 2vw;
  p:nth-child(1) {
    word-break: normal;
    white-space: nowrap;
  }
  p:nth-child(2) {
    word-break: break-all;
    text-align: right;
  }
`;
const DetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2vh;
`;

const AmountBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 1vh;
  h2 {
    color: rgba(255, 255, 255, 0.8);
  }
`;
export default TransactionSidebar;
