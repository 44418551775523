/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApiError } from '../models';
import { CancelRequest } from '../models';
import { OrderInfoResponse } from '../models';
import { OrderStepResponse } from '../models';
import { OrderTemplateRequest } from '../models';
import { OrderTemplateResponse } from '../models';
import { OrderTemplateSubmitRequest } from '../models';
import { OrdersRatesResponse } from '../models';
import { OrdersResponse } from '../models';
import { ResponseBase } from '../models';
import { ValidateDataResponse } from '../models';
import { ValidateOrderResponse } from '../models';
import { ValidateOrderWarningRequest } from '../models';
/**
 * OrdersControllerApi - axios parameter creator
 * @export
 */
export const OrdersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel order by ID
         * @param {CancelRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder: async (body: CancelRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cancelOrder.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling cancelOrder.');
            }
            const localVarPath = `/orders/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create order template
         * @param {OrderTemplateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderTemplate: async (body: OrderTemplateRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOrderTemplate.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling createOrderTemplate.');
            }
            const localVarPath = `/orders/request-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user's orders by status
         * @param {string} token 
         * @param {string} [assetId] Asset Id
         * @param {string} [sortField] Sort Field
         * @param {string} [sortDirection] Sort Direction
         * @param {string} [page] Page number
         * @param {string} [size] Number of records per page
         * @param {Array<string>} [status] Order status when not present return all orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOpenOrders: async (token: string, assetId?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, status?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getOpenOrders.');
            }
            const localVarPath = `/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetId !== undefined) {
                localVarQueryParameter['assetId'] = assetId;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (status) {
                localVarQueryParameter['status'] = status;
            }

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get order by id
         * @param {string} token 
         * @param {string} orderId Order UID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderById: async (token: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getOrderById.');
            }
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling getOrderById.');
            }
            const localVarPath = `/orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get orders rates
         * @param {string} token 
         * @param {string} originCurrency Origin currency
         * @param {string} destinationCurrency Destination currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersRates: async (token: string, originCurrency: string, destinationCurrency: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getOrdersRates.');
            }
            // verify required parameter 'originCurrency' is not null or undefined
            if (originCurrency === null || originCurrency === undefined) {
                throw new RequiredError('originCurrency','Required parameter originCurrency was null or undefined when calling getOrdersRates.');
            }
            // verify required parameter 'destinationCurrency' is not null or undefined
            if (destinationCurrency === null || destinationCurrency === undefined) {
                throw new RequiredError('destinationCurrency','Required parameter destinationCurrency was null or undefined when calling getOrdersRates.');
            }
            const localVarPath = `/orders/order-rates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (originCurrency !== undefined) {
                localVarQueryParameter['originCurrency'] = originCurrency;
            }

            if (destinationCurrency !== undefined) {
                localVarQueryParameter['destinationCurrency'] = destinationCurrency;
            }

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Order Steps
         * @param {string} token 
         * @param {string} orderId Order UID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions1: async (token: string, orderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getTransactions1.');
            }
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling getTransactions1.');
            }
            const localVarPath = `/orders/transaction/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit order by ID
         * @param {OrderTemplateSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitOrderTemplate: async (body: OrderTemplateSubmitRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling submitOrderTemplate.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling submitOrderTemplate.');
            }
            const localVarPath = `/orders/submit-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate Order before send
         * @param {ValidateOrderWarningRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOrderBeforeSend: async (body: ValidateOrderWarningRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling validateOrderBeforeSend.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling validateOrderBeforeSend.');
            }
            const localVarPath = `/orders/validate/warning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validate Order Template Data
         * @param {OrderTemplateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOrderData: async (body: OrderTemplateRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling validateOrderData.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling validateOrderData.');
            }
            const localVarPath = `/orders/validate/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersControllerApi - functional programming interface
 * @export
 */
export const OrdersControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel order by ID
         * @param {CancelRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOrder(body: CancelRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await OrdersControllerApiAxiosParamCreator(configuration).cancelOrder(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create order template
         * @param {OrderTemplateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderTemplate(body: OrderTemplateRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<OrderTemplateResponse>>> {
            const localVarAxiosArgs = await OrdersControllerApiAxiosParamCreator(configuration).createOrderTemplate(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user's orders by status
         * @param {string} token 
         * @param {string} [assetId] Asset Id
         * @param {string} [sortField] Sort Field
         * @param {string} [sortDirection] Sort Direction
         * @param {string} [page] Page number
         * @param {string} [size] Number of records per page
         * @param {Array<string>} [status] Order status when not present return all orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenOrders(token: string, assetId?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, status?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<OrdersResponse>>> {
            const localVarAxiosArgs = await OrdersControllerApiAxiosParamCreator(configuration).getOpenOrders(token, assetId, sortField, sortDirection, page, size, status, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get order by id
         * @param {string} token 
         * @param {string} orderId Order UID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderById(token: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<OrderInfoResponse>>> {
            const localVarAxiosArgs = await OrdersControllerApiAxiosParamCreator(configuration).getOrderById(token, orderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get orders rates
         * @param {string} token 
         * @param {string} originCurrency Origin currency
         * @param {string} destinationCurrency Destination currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersRates(token: string, originCurrency: string, destinationCurrency: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<OrdersRatesResponse>>> {
            const localVarAxiosArgs = await OrdersControllerApiAxiosParamCreator(configuration).getOrdersRates(token, originCurrency, destinationCurrency, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Order Steps
         * @param {string} token 
         * @param {string} orderId Order UID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactions1(token: string, orderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<OrderStepResponse>>> {
            const localVarAxiosArgs = await OrdersControllerApiAxiosParamCreator(configuration).getTransactions1(token, orderId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Submit order by ID
         * @param {OrderTemplateSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitOrderTemplate(body: OrderTemplateSubmitRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await OrdersControllerApiAxiosParamCreator(configuration).submitOrderTemplate(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Validate Order before send
         * @param {ValidateOrderWarningRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateOrderBeforeSend(body: ValidateOrderWarningRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ValidateOrderResponse>>> {
            const localVarAxiosArgs = await OrdersControllerApiAxiosParamCreator(configuration).validateOrderBeforeSend(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Validate Order Template Data
         * @param {OrderTemplateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateOrderData(body: OrderTemplateRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ValidateDataResponse>>> {
            const localVarAxiosArgs = await OrdersControllerApiAxiosParamCreator(configuration).validateOrderData(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrdersControllerApi - factory interface
 * @export
 */
export const OrdersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Cancel order by ID
         * @param {CancelRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelOrder(body: CancelRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return OrdersControllerApiFp(configuration).cancelOrder(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create order template
         * @param {OrderTemplateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderTemplate(body: OrderTemplateRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<OrderTemplateResponse>> {
            return OrdersControllerApiFp(configuration).createOrderTemplate(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user's orders by status
         * @param {string} token 
         * @param {string} [assetId] Asset Id
         * @param {string} [sortField] Sort Field
         * @param {string} [sortDirection] Sort Direction
         * @param {string} [page] Page number
         * @param {string} [size] Number of records per page
         * @param {Array<string>} [status] Order status when not present return all orders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOpenOrders(token: string, assetId?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, status?: Array<string>, options?: AxiosRequestConfig): Promise<AxiosResponse<OrdersResponse>> {
            return OrdersControllerApiFp(configuration).getOpenOrders(token, assetId, sortField, sortDirection, page, size, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get order by id
         * @param {string} token 
         * @param {string} orderId Order UID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderById(token: string, orderId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<OrderInfoResponse>> {
            return OrdersControllerApiFp(configuration).getOrderById(token, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get orders rates
         * @param {string} token 
         * @param {string} originCurrency Origin currency
         * @param {string} destinationCurrency Destination currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrdersRates(token: string, originCurrency: string, destinationCurrency: string, options?: AxiosRequestConfig): Promise<AxiosResponse<OrdersRatesResponse>> {
            return OrdersControllerApiFp(configuration).getOrdersRates(token, originCurrency, destinationCurrency, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Order Steps
         * @param {string} token 
         * @param {string} orderId Order UID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactions1(token: string, orderId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<OrderStepResponse>> {
            return OrdersControllerApiFp(configuration).getTransactions1(token, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit order by ID
         * @param {OrderTemplateSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitOrderTemplate(body: OrderTemplateSubmitRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return OrdersControllerApiFp(configuration).submitOrderTemplate(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate Order before send
         * @param {ValidateOrderWarningRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateOrderBeforeSend(body: ValidateOrderWarningRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ValidateOrderResponse>> {
            return OrdersControllerApiFp(configuration).validateOrderBeforeSend(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validate Order Template Data
         * @param {OrderTemplateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateOrderData(body: OrderTemplateRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ValidateDataResponse>> {
            return OrdersControllerApiFp(configuration).validateOrderData(body, token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersControllerApi - object-oriented interface
 * @export
 * @class OrdersControllerApi
 * @extends {BaseAPI}
 */
export class OrdersControllerApi extends BaseAPI {
    /**
     * 
     * @summary Cancel order by ID
     * @param {CancelRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public async cancelOrder(body: CancelRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return OrdersControllerApiFp(this.configuration).cancelOrder(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create order template
     * @param {OrderTemplateRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public async createOrderTemplate(body: OrderTemplateRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<OrderTemplateResponse>> {
        return OrdersControllerApiFp(this.configuration).createOrderTemplate(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get user's orders by status
     * @param {string} token 
     * @param {string} [assetId] Asset Id
     * @param {string} [sortField] Sort Field
     * @param {string} [sortDirection] Sort Direction
     * @param {string} [page] Page number
     * @param {string} [size] Number of records per page
     * @param {Array<string>} [status] Order status when not present return all orders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public async getOpenOrders(token: string, assetId?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, status?: Array<string>, options?: AxiosRequestConfig) : Promise<AxiosResponse<OrdersResponse>> {
        return OrdersControllerApiFp(this.configuration).getOpenOrders(token, assetId, sortField, sortDirection, page, size, status, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get order by id
     * @param {string} token 
     * @param {string} orderId Order UID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public async getOrderById(token: string, orderId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<OrderInfoResponse>> {
        return OrdersControllerApiFp(this.configuration).getOrderById(token, orderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get orders rates
     * @param {string} token 
     * @param {string} originCurrency Origin currency
     * @param {string} destinationCurrency Destination currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public async getOrdersRates(token: string, originCurrency: string, destinationCurrency: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<OrdersRatesResponse>> {
        return OrdersControllerApiFp(this.configuration).getOrdersRates(token, originCurrency, destinationCurrency, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get Order Steps
     * @param {string} token 
     * @param {string} orderId Order UID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public async getTransactions1(token: string, orderId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<OrderStepResponse>> {
        return OrdersControllerApiFp(this.configuration).getTransactions1(token, orderId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Submit order by ID
     * @param {OrderTemplateSubmitRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public async submitOrderTemplate(body: OrderTemplateSubmitRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return OrdersControllerApiFp(this.configuration).submitOrderTemplate(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Validate Order before send
     * @param {ValidateOrderWarningRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public async validateOrderBeforeSend(body: ValidateOrderWarningRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ValidateOrderResponse>> {
        return OrdersControllerApiFp(this.configuration).validateOrderBeforeSend(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Validate Order Template Data
     * @param {OrderTemplateRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersControllerApi
     */
    public async validateOrderData(body: OrderTemplateRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ValidateDataResponse>> {
        return OrdersControllerApiFp(this.configuration).validateOrderData(body, token, options).then((request) => request(this.axios, this.basePath));
    }
}
