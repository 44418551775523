import React, { FC } from 'react';
import ISVG from '@types/icons/svg';

export const Trash: FC<ISVG> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7 1H8.93701' stroke='#EC396B' strokeOpacity='0.7' strokeWidth='1.5' strokeMiterlimit='10' strokeLinecap='round' strokeLinejoin='round' />
    <path
      d='M2 4H13.937M3.46899 4L4.05701 13.6812C4.09901 14.3812 4.68 14.9268 5.381 14.9268H10.7C11.401 14.9268 11.981 14.3812 12.024 13.6812L12.451 6.65186'
      stroke='#EC396B'
      strokeOpacity='0.7'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Trash;
