import React, { CSSProperties, useEffect, useState } from "react";
import AbraLogo from "assets/logo/NewLogo.svg";

import { BreakpointsEnum } from "@types/enums/GridSystem";
import TradeImage from "/public/assets/background/Trade.png";
import EarnImage from "/public/assets/background/Earn.png";
import BorrowImage from "/public/assets/background/Borrow.png";

import { zIndex, breakpoints } from "lib/theme";
import { Carousel } from "react-responsive-carousel";
import { getText } from "shared/locale-helper";
import styled from "styled-components";
import Image from "components/atoms/Image";
import "./carousel.css";

export const StyledH1 = styled.h1`
  position: static;
  height: 29px;
  left: 200.5px;
  top: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: 0.025em;
  font-feature-settings: "ss02" on;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 8px;
`;

export const Styledp = styled.p`
  position: relative;
  width: 451px;
  height: 36px;
  left: 0px;
  top: -8px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  text-align: center;
  font-feature-settings: "ss02" on, "liga" off;
  color: rgba(255, 255, 255, 0.7);
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 8px 0px;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: 451px;
  height: 73px;
  left: calc(50% - 451px / 2 + 0.5px);
  top: calc(80%);
`;

export const styledImg = styled.img`
  position: absolute;
  width: 64px;
  height: 32px;
  left: 46px;
  top: 32px;
`;

const SignInCarousel = () => {
  if (document)
    document!.getElementById("app")!.setAttribute("style", "overflow: hidden;");

  const indicatorStyles: CSSProperties = {
    background: "#fff",
    width: 8,
    height: 8,
    display: "inline-block",
    margin: 3,
    borderRadius: "50%",
  };

  const renderIndicator = (
    onClickHandler: any,
    isSelected: boolean,
    index: number
  ) => {
    if (isSelected) {
      return (
        <li style={{ ...indicatorStyles, width: "20px", borderRadius: "4px" }} />
      );
    }
    return (
      <li
        style={indicatorStyles}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        key={index}
        role="button"
      />
    );
  };

  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const setWidthHeight = () => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );

    if (vw > breakpoints.values[BreakpointsEnum.ExtraLarge]) {
      setWidth("auto");
      setHeight("758");
    } else {
      setWidth("auto");
      setHeight("1100");
    }
  };

  useEffect(() => {
    setWidthHeight();
  }, [width, height]);

  window.addEventListener(
    "resize",
    (event) => {
      setWidthHeight();
    },
    true
  );

  const tradeTitle = "Custody";
  const tradeDescription =
    "Custody your crypto with Abra in a separately managed account (SMA). Abra is an SEC registered investment advisor (RIA).";

  const earnTitle = "Earn Yield";
  const earnDescription =
    "Generate up to 10% yield on digital assets and US dollar stablecoins compounded daily.";

  const borrowTitle = getText("Borrow");
  const borrowDescription =
    "Borrow U.S. dollar stablecoins with flexible loan terms and Loan-to-Value (LTV) ratio using crypto such as BTC and ETH as collateral.";

  const carouselComponent = (
    <Carousel
      autoPlay={true}
      interval={10000}
      dynamicHeight={false}
      showArrows={false}
      showThumbs={false}
      showStatus={false}
      renderIndicator={renderIndicator}
    >
      <ImageContainer>
        <ImageBackground>
          <Image
            src={TradeImage}
          />
        </ImageBackground>
        <DescriptionContainer>
          <StyledH1>{tradeTitle}</StyledH1>
          <Styledp>{tradeDescription}</Styledp>
        </DescriptionContainer>
      </ImageContainer>
      <ImageContainer>
        <ImageBackground>

          <Image
            src={EarnImage}
          />
        </ImageBackground>
        <DescriptionContainer>
          <StyledH1>{earnTitle}</StyledH1>
          <Styledp>{earnDescription}</Styledp>
        </DescriptionContainer>
      </ImageContainer>
      <ImageContainer>
        <ImageBackground>

          <Image
            src={BorrowImage}
          />
        </ImageBackground>
        <DescriptionContainer>
          <StyledH1>{borrowTitle}</StyledH1>
          <Styledp>{borrowDescription}</Styledp>
        </DescriptionContainer>
      </ImageContainer>
    </Carousel>
  );

  return (
    <div style={{ flex: "1 1 50%" }}>
      <img
        src={AbraLogo}
        width={64}
        height={32}
        alt="Abra Logo"
        style={{
          position: "absolute",
          width: 64,
          height: 32,
          left: "45px",
          top: "30px",
          zIndex: `${zIndex.globalIcon}`,
        }}
      />

      {carouselComponent}
    </div>
  );
};

const ImageContainer = styled.div`
  display: grid;
  grid-template-rows: 4fr 1fr;
  grid-template-columns: 1fr;
`;

const ImageBackground = styled.div`
  grid-row: 1/ 3;
  grid-column: 1 / 2;
`;
const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  grid-row: 2/ 3;
  grid-column: 1 / 2;
`;

export default SignInCarousel;
