import styled, { css } from "styled-components";
import breakpoints from "lib/theme/breakpoints";
import { BreakpointsEnum } from "@types/enums/GridSystem";

export const StyledWidgetContainer = styled.section<{
  xs: any,
  sm: any,
  md: any,
  lg: any,
  xl: any,
  xxl: any,
}>`
${({ xs, sm, md, lg, xl, xxl }) => {
  const { columns: xsColumns = '' , area: xsArea = '' } = xs || {};
  const { columns: smColumns = '' , area: smArea = '' } = sm || {};
  const { columns: mdColumns = '' , area: mdArea = '' } = md || {};
  const { columns: lgColumns = '' , area: lgArea = '' } = lg || {};
  const { columns: xlColumns = '' , area: xlArea = '' } = xl || {};
  const { columns: xxlColumns = '' , area: xxlArea = '' } = xxl || {};
  let styles = `
  grid-area: ${xxlArea};
  grid-column: ${xxlColumns};
  overflow: auto;
  width: 100%;
  @media print {
    background: white !important;
    background-color: white !important;
  }
  `;

  styles += breakpoints.keys.map((key, indx, arr) => {
    const prevBreakpoint = breakpoints.values[breakpoints.keys[indx - 1 ]];

    const mediaQuery = prevBreakpoint
      ? `@media only screen and (min-width: ${prevBreakpoint + 1}${breakpoints.measurement}) and (max-width: ${breakpoints.values[key]}${breakpoints.measurement})` 
      : `@media only screen and (max-width: ${breakpoints.values[key]}${breakpoints.measurement})`;

    const gridStyles = prevBreakpoint ? `{
        ${xxlArea && key === BreakpointsEnum.ExtraExtraLarge ? `grid-area: ${xxlArea};` : ''}
        ${xlArea && key === BreakpointsEnum.ExtraLarge ? `grid-area: ${xlArea};` : ''}
        ${lgArea && key === BreakpointsEnum.Large ? `grid-area: ${lgArea};` : ''}
        ${mdArea && key === BreakpointsEnum.Medium ? `grid-area: ${mdArea};` : ''}
        ${smArea && key === BreakpointsEnum.Small ? `grid-area: ${smArea};` : ''}
        ${xsArea && key === BreakpointsEnum.ExtraSmall ? `grid-area: ${xsArea};` : ''}

        ${xxlColumns && key === BreakpointsEnum.ExtraExtraLarge ? `grid-column: ${xxlColumns};` : ''}
        ${xlColumns && key === BreakpointsEnum.ExtraLarge ? `grid-column: ${xlColumns};` : ''}
        ${lgColumns && key === BreakpointsEnum.Large ? `grid-column: ${lgColumns};` : ''}
        ${mdColumns && key === BreakpointsEnum.Medium ? `grid-column: ${mdColumns};` : ''}
        ${smColumns && key === BreakpointsEnum.Small ? `grid-column: ${smColumns};` : ''}
        ${xsColumns && key === BreakpointsEnum.ExtraSmall ? `grid-column: ${xsColumns};` : ''}
    }` : `{
      ${`grid-area: ${xsArea};`}
      ${`grid-column: ${xsColumns};`}
    }`;

    return mediaQuery + gridStyles;
  }).toString().replaceAll(',', '')
  return styles;
}}
`;

export const StyledWidgetHeading = styled.div<{ marginBottom?: string; }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom = '1.5rem' }) => marginBottom};
`;

export const StyledHeaderAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
