import React from "react";
import { sharedStyles } from "../income/styles";
import styled from "styled-components";
import { ExpensesSummaryRow } from "state/store/reportApi";
import useAssets from "shared/useAssets";

export interface Props {
    year: number;
    summaryData: ExpensesSummaryRow[] | undefined;
}

const CustodyExpensesSummary: React.FC<Props> = ({ year, summaryData }) => {
    const assets = useAssets();
    return (<sharedStyles.Container>
        <Header>
            <TaxInfoContainer>
                <TaxInfoCard>
                    <TaxInfoLabel>Tax Year</TaxInfoLabel>
                    <TaxInfoValue>{year}</TaxInfoValue>
                </TaxInfoCard>
            </TaxInfoContainer>

            <TitleSection>
                <Title>Investment Expenses Summary</Title>
            </TitleSection>
        </Header>

        <sharedStyles.TableContainer>
            <sharedStyles.Table>
                <thead>
                    <tr>
                        <sharedStyles.Th>Category</sharedStyles.Th>
                        <sharedStyles.Th>Amount</sharedStyles.Th>
                        <sharedStyles.Th>Cost Basis</sharedStyles.Th>
                    </tr>
                </thead>
                <tbody>
                    {summaryData?.map((row, index) => (
                        <sharedStyles.TableRow key={index}>
                            <sharedStyles.Td>{row.category}</sharedStyles.Td>
                            <sharedStyles.Td>
                                <QuantityCell>
                                    <div>
                                    {assets.getPriceFormattedI(row.amount.asset, row.amount.amount)}
                                    </div>
                                </QuantityCell>
                            </sharedStyles.Td>
                            <sharedStyles.Td>
                                <QuantityCell>
                                    <div>
                                    {assets.getPriceFormattedI(row.costBasisAmount.asset, row.costBasisAmount.amount)}
                                    </div>
                                </QuantityCell>
                            </sharedStyles.Td>
                        </sharedStyles.TableRow>
                    ))}
                </tbody>
            </sharedStyles.Table>
        </sharedStyles.TableContainer>
    </sharedStyles.Container>);
};


const Header = styled.div`
    margin-bottom: 32px;
`;

const TitleSection = styled.div`
    margin-bottom: 24px;
`;

const Title = styled.h2`
    color: white;
    font-size: 20px;
    margin-bottom: 8px;
`;

const TaxInfoContainer = styled.div`
    display: flex;
    gap: 24px;
    margin-bottom: 32px;
`;

const TaxInfoCard = styled.div`
    background: rgba(255, 255, 255, 0.05);
    padding: 16px 24px;
    border-radius: 8px;
    min-width: 200px;
`;

const TaxInfoLabel = styled.div`
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    margin-bottom: 4px;
`;

const TaxInfoValue = styled.div`
    color: white;
    font-size: 24px;
    font-weight: 600;
`;

const QuantityCell = styled.div`
    display: flex;
    flex-direction: column;
`;

export default CustodyExpensesSummary;