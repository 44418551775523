import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { StatementWithdraw } from 'state/store/statementsApi';
import useAssets from 'shared/useAssets';
import useCustodyStatement from './useCustodyStatement';
import { Container, Table, TableContainer, TableRow, Th, Td, Title } from './sharedStyles';

const CustodyStatementCostBasisWithdrawals = () => {
  const { month, year } = useParams();
  const { realizedWithdrawals } = useCustodyStatement({ month, year });

  return (
    <Container>
      <Title>Cost Basis for Withdrawals</Title>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <Th>Date</Th>
              <Th>Network</Th>
              <Th>Amount</Th>
              <Th>USD Value</Th>
              <Th>Total Network Fee</Th>
              <Th>Overall Cost Basis</Th>
              <Th>Transaction Id</Th>
              <Th>Acquisition Date</Th>
            </TableRow>
          </thead>
          <tbody>
            {realizedWithdrawals?.data?.map((record, index) => (
              <CustodySummaryRow key={index} record={record} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const CustodySummaryRow = ({ record }: { record: StatementWithdraw }) => {
  const { getPriceFormattedI } = useAssets();

  return (
    <TableRow>
      <Td>{record.closingDate}</Td>
      <Td>{record.network}</Td>
      <Td>
        {getPriceFormattedI(record.amount.asset, record.amount.amount)}
      </Td>
      <Td>
        {getPriceFormattedI(record.closingAmount.asset, record.closingAmount.amount)}
      </Td>
      <Td>
        {getPriceFormattedI(record.totalFees.asset, record.totalFees.amount)}
      </Td>
      <Td>
        {getPriceFormattedI(record.realizedAmount.asset, record.realizedAmount.amount)}
      </Td>
      <Td>{record.abraTxUID}</Td>
      <Td>
        <MockText>-</MockText>
      </Td>
    </TableRow>
  );
};

const MockText = styled.span`
  color: #E99928;
`;

export default CustodyStatementCostBasisWithdrawals;