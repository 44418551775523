import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const UpArrow: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = "#A399F6",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2784 8.50262L5.62844 14.1626C5.53471 14.2556 5.46032 14.3662 5.40955 14.488C5.35878 14.6099 5.33264 14.7406 5.33264 14.8726C5.33264 15.0046 5.35878 15.1353 5.40955 15.2572C5.46032 15.3791 5.53471 15.4897 5.62844 15.5826C5.8158 15.7689 6.06925 15.8734 6.33344 15.8734C6.59763 15.8734 6.85108 15.7689 7.03844 15.5826L12.0384 10.6326L16.9884 15.5826C17.1758 15.7689 17.4293 15.8734 17.6934 15.8734C17.9576 15.8734 18.2111 15.7689 18.3984 15.5826C18.4929 15.49 18.5681 15.3796 18.6196 15.2577C18.6711 15.1358 18.6979 15.0049 18.6984 14.8726C18.6979 14.7403 18.6711 14.6094 18.6196 14.4876C18.5681 14.3657 18.4929 14.2552 18.3984 14.1626L12.7484 8.50262C12.6548 8.40112 12.5411 8.32011 12.4146 8.2647C12.2881 8.20929 12.1515 8.18069 12.0134 8.18069C11.8753 8.18069 11.7387 8.20929 11.6122 8.2647C11.4857 8.32011 11.3721 8.40112 11.2784 8.50262V8.50262Z"
      fill={color}
    />
  </svg>
);

export default UpArrow;
