import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const AchRecurringDepositFailed: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
    <path d="M21.0002 20.1011H3.00024C2.44824 20.1011 2.00024 20.5481 2.00024 21.1011C2.00024 21.6541 2.44824 22.1011 3.00024 22.1011H21.0002C21.5522 22.1011 22.0002 21.6541 22.0002 21.1011C22.0002 20.5481 21.5522 20.1011 21.0002 20.1011Z" fill={color}/>
    <path d="M3.33515 11.1429L12.0001 8.06292L20.6651 11.1429C20.7761 11.1819 20.8891 11.2009 21.0001 11.2009C21.4111 11.2009 21.7971 10.9449 21.9421 10.5359C22.1271 10.0149 21.8552 9.44397 21.3351 9.25897L12.3351 6.05993C12.1181 5.98393 11.8821 5.98393 11.6651 6.05993L2.66514 9.25897C2.14414 9.44497 1.87314 10.0159 2.05814 10.5359C2.24314 11.0569 2.81415 11.3279 3.33515 11.1429Z" fill={color}/>
    <path d="M6.87116 6.12901L12.0002 4.07798L17.1292 6.12901C17.2512 6.17801 17.3762 6.19999 17.5002 6.19999C17.8962 6.19999 18.2722 5.96296 18.4292 5.57096C18.6342 5.05796 18.3842 4.47597 17.8712 4.27097L12.3712 2.072C12.1332 1.976 11.8672 1.976 11.6292 2.072L6.12915 4.27097C5.61615 4.47597 5.36615 5.05796 5.57115 5.57096C5.77615 6.08496 6.35816 6.33501 6.87116 6.12901Z" fill={color}/>
    <path d="M18.0002 12.3853V18.1013C18.0002 18.6543 18.4482 19.1013 19.0002 19.1013C19.5522 19.1013 20.0002 18.6543 20.0002 18.1013V12.3853C20.0002 11.8323 19.5522 11.3853 19.0002 11.3853C18.4482 11.3853 18.0002 11.8323 18.0002 12.3853Z" fill={color}/>
    <path d="M14.0002 11.1382V18.1012C14.0002 18.6542 14.4482 19.1012 15.0002 19.1012C15.5522 19.1012 16.0002 18.6542 16.0002 18.1012V11.1382C16.0002 10.5852 15.5522 10.1382 15.0002 10.1382C14.4482 10.1382 14.0002 10.5852 14.0002 11.1382Z" fill={color}/>
    <path d="M8.00024 11.1382V18.1012C8.00024 18.6542 8.44824 19.1012 9.00024 19.1012C9.55224 19.1012 10.0002 18.6542 10.0002 18.1012V11.1382C10.0002 10.5852 9.55224 10.1382 9.00024 10.1382C8.44824 10.1382 8.00024 10.5852 8.00024 11.1382Z" fill={color}/>
    <path d="M4.00024 12.3853V18.1013C4.00024 18.6543 4.44824 19.1013 5.00024 19.1013C5.55224 19.1013 6.00024 18.6543 6.00024 18.1013V12.3853C6.00024 11.8323 5.55224 11.3853 5.00024 11.3853C4.44824 11.3853 4.00024 11.8323 4.00024 12.3853Z" fill={color}/>
  </svg>
);

export default AchRecurringDepositFailed;