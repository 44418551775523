import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Assets } from "services/models";
import { AssetsControllerApi } from "services";

interface EarnAssetDetailState {
  loading: boolean;
  holdings: { assets: Assets[]; paginationResponse: any };
  holdingsError: boolean;
}

const initialState = {
  loading: false,
  holdings: { assets: [], paginationResponse: {} },
  holdingsError: false
} as EarnAssetDetailState;

const AssetsService = new AssetsControllerApi();

export const getUserHoldings =
  (
    sortField?: string,
    sortDirection?: string,
    page: string = "1",
    size: string = "5"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setUserHoldings({ assets: [], paginationResponse: {} }));
      const { token } = getState().auth;
      const { data } = await AssetsService.getHoldingInterest(
        token,
        sortField,
        sortDirection,
        page,
        size
      );

      const holdings: Assets[] = data?.assets ?? new Array(0);
      const pagination = data?.paginationResponse;

      dispatch(
        setUserHoldings({ assets: holdings, paginationResponse: pagination })
      );
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  export const getInterestRates =
  (
    sortField?: string,
    sortDirection?: string,
    page: string = "1",
    size: string = "5"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setUserHoldings({ assets: [], paginationResponse: {} }));
      const { token } = getState().auth;
      const { data } = await AssetsService.getInterestRates(
        token,
        sortField,
        sortDirection,
        page,
        size
      );

      const holdings: Assets[] = data?.assets ?? new Array(0);
      const pagination = data?.paginationResponse;

      dispatch(
        setUserHoldings({ assets: holdings, paginationResponse: pagination })
      );
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const earnAssetDetailSlice = createSlice({
  name: "earnAssetDetail",
  initialState,
  reducers: {
    setUserHoldings: (
      state,
      action: PayloadAction<{ assets: Assets[]; paginationResponse: any }>
    ) => {
      state.holdings = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setUserHoldings,
  setLoading,
} = earnAssetDetailSlice.actions;

export const earnAssetDetailReducer = earnAssetDetailSlice.reducer;

