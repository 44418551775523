import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Tothemoon: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0001 4.22345C15.8217 4.22817 11.8158 5.89068 8.86124 8.84625C5.90667 11.8018 4.24471 15.8091 4.23999 19.9889C4.24353 24.1694 5.90497 28.1778 8.85967 31.1343C11.8144 34.0909 15.821 35.754 20.0001 35.7587C24.1793 35.754 28.1859 34.0909 31.1406 31.1343C34.0953 28.1778 35.7567 24.1694 35.7603 19.9889C35.7556 15.8091 34.0936 11.8018 31.139 8.84625C28.1844 5.89068 24.1785 4.22817 20.0001 4.22345V4.22345Z"
      fill="#F47A63"
    />
    <path
      d="M20.749 2.72048C24.1398 2.869 27.4119 4.01205 30.158 6.00726C32.9041 8.00248 35.0027 10.7617 36.1926 13.9413L38.7829 13.0984C37.4143 9.37233 34.9689 6.13748 31.7577 3.80512C28.5466 1.47277 24.7146 0.14827 20.749 0V2.72048Z"
      fill="#FFA519"
    />
    <path
      d="M36.6518 15.364C37.0681 16.8704 37.2795 18.426 37.2804 19.9888C37.2829 22.5906 36.6971 25.1592 35.5668 27.5025C34.4365 29.8457 32.791 31.9028 30.7534 33.5199L32.3495 35.723C34.7351 33.8534 36.6638 31.465 37.9894 28.7388C39.3149 26.0127 40.0025 23.0204 40 19.9888C40.0008 18.1412 39.7442 16.3024 39.2376 14.5256L36.6518 15.364Z"
      fill="#FFA519"
    />
    <path
      d="M3.80732 13.9413C4.99722 10.7617 7.09588 8.00248 9.84195 6.00726C12.588 4.01205 15.8602 2.869 19.2509 2.72048V0C15.2853 0.14827 11.4534 1.47277 8.24221 3.80512C5.03104 6.13748 2.58569 9.37233 1.21704 13.0984L3.80732 13.9413Z"
      fill="#FFA519"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 35.7587C20.0001 35.7587 20.0001 35.7587 20.0002 35.7587C24.1794 35.754 28.186 34.0909 31.1407 31.1343C34.0954 28.1778 35.7568 24.1694 35.7603 19.9889C35.7556 15.8091 34.0937 11.8018 31.1391 8.84625C28.1845 5.89068 24.1786 4.22817 20.0002 4.22345C20.0001 4.22345 20.0001 4.22345 20 4.22345V35.7587Z"
      fill="#DC6E59"
    />
    <path
      d="M29.563 34.403C26.7359 36.2814 23.4161 37.2808 20.0222 37.2751C16.6282 37.2808 13.3085 36.2814 10.4814 34.403L8.88086 36.6061C12.1746 38.824 16.0562 40.006 20.0266 40C23.9971 40.006 27.8787 38.824 31.1724 36.6061L29.563 34.403Z"
      fill="#FFA519"
    />
    <path
      d="M9.24655 33.5199C7.20899 31.9028 5.56346 29.8457 4.43315 27.5025C3.30284 25.1592 2.71703 22.5906 2.71957 19.9888C2.72045 18.426 2.9319 16.8704 3.3482 15.364L0.762375 14.5256C0.255769 16.3024 -0.000819497 18.1412 6.96151e-06 19.9888C-0.00252391 23.0204 0.685053 26.0127 2.0106 28.7388C3.33615 31.465 5.26486 33.8534 7.65047 35.723L9.24655 33.5199Z"
      fill="#FFA519"
    />
    <path d="M20.2271 12.8175H19.3354V24.5646H20.2271V12.8175Z" fill="white" />
    <path
      d="M26.9462 12.8175H19.6836V17.1881H26.9462L23.1611 15.0028L26.9462 12.8175Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.8969 26.5839C29.9214 24.6706 25.3456 23.4452 20.2139 23.4452C14.8958 23.4452 10.1749 24.7612 7.21313 26.795C7.88087 28.049 8.73499 29.2091 9.75761 30.2322C12.4741 32.95 16.1577 34.4789 19.9999 34.4832C23.8421 34.4789 27.5257 32.95 30.2422 30.2322C31.322 29.152 32.2139 27.9189 32.8969 26.5839Z"
      fill="white"
    />
  </svg>
);

export default Tothemoon;
