import Button from "components/atoms/Button";
import Icon from "components/atoms/Icon";
import { Text } from "components/atoms/Typography";
import { IconContainer } from "components/organisms/Modal/Modal.styles";
import { SizeNamesEnum, TypesNamesEnum } from "enums/Button.enum";
import { palette } from "lib/theme";
import { QRCodeCanvas } from "qrcode.react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { copyTextToClipboard } from "shared";
import { selectTokenToValidate } from "state/slice/auth.slice";
import styled from "styled-components";
import { TwoFaModalHeader } from "./TwoFaModalHeader";

interface ISharedKeyInfo {
  email: string;
  sharedKey: string;
  currentStep: string;
  change2FaFlow: boolean;
  setCurrentStep: (step: string) => void;
  setShowTwoFAModal: (modal: boolean) => void;
}

export const SharedKeyInfo = ({
  email,
  currentStep,
  sharedKey,
  change2FaFlow,
  setCurrentStep,
  setShowTwoFAModal,
}: ISharedKeyInfo) => {
  const tokenToValidate = useSelector(selectTokenToValidate);
  const [showQrCode, setShowQrCode] = useState(false);
  const [showZoomIn, setShowZoomIn] = useState(false);
  return (
    <ModalContainer>
      <div style={{ paddingTop: "62px", gap: "24px", display: "flex" }}>
        <div>
          <TwoFaModalHeader
            sharedKey={sharedKey}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setShowTwoFAModal={setShowTwoFAModal}
            change2FaFlow={change2FaFlow}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "260px",
            }}
          >
            <div>
              {change2FaFlow ? (
                <div style={{ width: "236px", marginTop: "39px" }}>
                  <Text
                    color={palette.darkBackgroundContrast.light70}
                    letterSpacing="-0.5"
                  >
                    To change the 2FA method, scan the QR code or manually enter
                    this key into a new authenticator app
                  </Text>
                </div>
              ) : (
                <>
                  <div style={{ width: "246px" }}>
                    <Text
                      color={palette.darkBackgroundContrast.light70}
                      letterSpacing="-0.5"
                    >
                      {" "}
                      1. Download any authenticator app (we like Google
                      Authenticator){" "}
                    </Text>
                  </div>
                  <div style={{ width: "236px", marginTop: "24px" }}>
                    <Text
                      letterSpacing="-0.5"
                      color={palette.darkBackgroundContrast.light70}
                    >
                      2. Scan the QR code or manually enter this key into the{" "}
                      <br />
                      authenticator app{" "}
                    </Text>
                  </div>
                </>
              )}
            </div>
            <div style={{ marginTop: "8.5px" }}>
              <div
                style={{
                  width: "236px",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Text bold>{tokenToValidate?.secret}</Text>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    copyTextToClipboard(tokenToValidate?.secret ?? "");
                  }}
                >
                  <Icon
                    name="CopyRefer"
                    size={24}
                    color={palette.accent.light}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {showZoomIn && (
          <div
            style={{
              display: "flex",
              width: "176px",
              height: "176px",
              background: "#07041ccc",
              position: "absolute",
              marginLeft: "56%",
              borderRadius: "8px",
            }}
            onMouseLeave={() => setShowZoomIn(false)}
          />
        )}

        <div
          style={{
            backgroundColor: "white",
            padding: "8px",
            borderRadius: "8px",
            width: "176px",
            height: "176px",
            cursor: "pointer",
          }}
          onClick={() => setShowQrCode(true)}
          onMouseOver={() => setShowZoomIn(true)}
        >
          {showZoomIn && (
            <div
              style={{ position: "absolute", display: "flex", margin: "12%" }}
            >
              <Icon name="ZoomIn" size={50} />
            </div>
          )}

          <QRCodeCanvas value={tokenToValidate?.barcodeUri ?? ""} size={160} />
        </div>
      </div>
      <div style={{ paddingTop: "37px", gap: "18px" }}>
        <div>
          <Button
            buttonType={TypesNamesEnum.ACCENT}
            size={SizeNamesEnum.EXTRA_LARGE_PLUS}
            label={"Continue"}
            onClick={() => {
              setCurrentStep("SharedKeyCode");
            }}
          ></Button>
        </div>
      </div>
      {showQrCode && (
        <Wrapper>
          <div style={{ paddingTop: "44px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "245px",
                flexDirection: "column",
                gap: "11px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  placeContent: "end",
                  width: "313px",
                  height: "24px",
                  paddingTop: "4px",
                }}
              >
                <IconContainer
                  onClick={() => setShowQrCode(false)}
                  style={{ width: "auto", height: "auto" }}
                >
                  <Icon
                    name="CrossModal"
                    size="24"
                    opacity={1}
                    color={palette.white.main}
                  />
                </IconContainer>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "8px",
                  borderRadius: "8px",
                  width: "248px",
                  height: "248px",
                }}
              >
                <QRCodeCanvas
                  value={tokenToValidate?.barcodeUri ?? ""}
                  size={231}
                />
              </div>
            </div>
          </div>
        </Wrapper>
      )}
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;

const Wrapper = styled.div<{ width?: string; minHeight?: string }>`
  width: ${({ width }) => (width ? width : 482)}px;
  margin: -18px auto;
  position: absolute;
  border-radius: 8px;
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px`};
  z-index: 1006;
  margin-left: -24px;
  background: #07041ccc;
  height: 100%;
  margin-top: -16px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
