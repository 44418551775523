import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Icon3: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <g clipPath="url(#clip0_1497_36777)">
      <path
        d="M0.0948941 9.42343C0.258894 9.77543 0.612893 9.99942 0.999893 9.99942H16.9999C17.5519 9.99942 17.9999 9.55242 17.9999 8.99942C17.9999 8.44642 17.5519 7.99942 16.9999 7.99942H3.13489L6.76889 3.64041C7.12289 3.21541 7.0649 2.58441 6.6409 2.23141C6.2169 1.87941 5.58589 1.9344 5.23189 2.3594L0.231887 8.3594C-0.0171125 8.6574 -0.0701059 9.07243 0.0948941 9.42343Z"
        fill={color}
      />
      <path
        d="M17.36 21.7684C17.547 21.9234 17.774 21.9994 18 21.9994C18.287 21.9994 18.571 21.8774 18.769 21.6404L23.769 15.6404C24.017 15.3414 24.071 14.9264 23.906 14.5764C23.741 14.2244 23.388 13.9994 23 13.9994H7C6.448 13.9994 6 14.4464 6 14.9994C6 15.5524 6.448 15.9994 7 15.9994H20.865L17.231 20.3584C16.878 20.7834 16.936 21.4144 17.36 21.7684Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1497_36777">
        <rect width="24" height="24" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export default Icon3;
