import React, { FC } from 'react';

import Icon from 'components/atoms/Icon';
import Panel from 'components/atoms/Panel';
import { Heading, Text } from 'components/atoms/Typography';
import { palette } from 'lib/theme';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import { TypesNamesEnum } from 'enums/Button.enum';

const PanelWithHeader: FC<{
  width?: string;
  height?: string;
  minHeight?: string;
  innerHeight?: string;
  title: string;
  marginBottom?: string;
  marginLeft?: string;
  padding?: string;
  panelPadding?: string;
  navigateTo?: () => void;
  fullPage?: boolean;
  smallPage?: boolean;
  icon?: string;
  size?: string;
  button?: React.ReactNode;
  hasBorder?: boolean,
  handleTitleButtonClick?: any,
  buttonTitle?: string
  buttonTitleDisabled?: boolean
}> = ({
  width,
  height,
  minHeight,
  innerHeight,
  title,
  marginBottom,
  marginLeft = '',
  padding,
  panelPadding,
  navigateTo,
  fullPage,
  smallPage,
  children,
  icon = 'RightArrowSmall',
  size = '24',
  button,
  hasBorder,
  handleTitleButtonClick,
  buttonTitle,
  buttonTitleDisabled
}) => {
  return (
    <Panel width={width} padding={padding || panelPadding} minHeight={minHeight} height={height} hasBorder={hasBorder}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            height: fullPage ? '2.0rem' : innerHeight ? innerHeight : '1.5rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: padding ? (marginLeft.length > 0 ? marginLeft : '24px') : '',
            marginRight: padding ? '24px' : '',
            marginBottom: marginBottom ? marginBottom : '24px',
          }}
        >
          {!fullPage && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '-webkit-fill-available',
              }}
            >
              <div>
                <Text size='large' bold letterSpacing='-0.5'>
                  {title}
                </Text>
                {handleTitleButtonClick && buttonTitle &&
                  <Button 
                    buttonType={TypesNamesEnum.PRIMARY} 
                    onClick={() => handleTitleButtonClick()}
                    disabled={buttonTitleDisabled}
                  >
                    {buttonTitle}
                  </Button>
                }
              </div>
            </div>
          )}
          {fullPage && <Heading variant='h2'>{title}</Heading>}
          {navigateTo ? (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigateTo();
              }}
            >
              {icon && (
                <IconContainer>
                  {button && <div>{button}</div>}
                  <Icon name={icon} size={size} color={palette.white.light70} />
                </IconContainer>
              )}
            </div>
          ) : null}
        </div>
        {children}
      </div>
    </Panel>
  );
};

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  font-size: 0.8rem;
  padding-left: 7px;
  white-space: nowrap;
  background-color: rgba(163, 153, 246, 0.4);
  transition: all 0.5s ease-in-out;
  &:hover {
    border-radius: 2px;
    background-color: rgba(163, 153, 246, 0.8);
  }
`;
export default PanelWithHeader;
