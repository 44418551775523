import { useGetTermsServiceCheckedQuery, useSetCheckTermsServiceMutation } from 'state/store/withdrawApi'

const useCustodyTerms = () => {
  const terms = useGetTermsServiceCheckedQuery()
  const [checkTerms, checkTermsMeta] = useSetCheckTermsServiceMutation()
  return {
    terms,
    checkTerms,
    checkTermsMeta
  }
}

export default useCustodyTerms
