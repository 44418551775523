import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/atoms/Button";
import DigitVerification from "components/atoms/DigitVerification";
import Icon from "components/atoms/Icon";
import Input from "components/atoms/Input";
import { Text } from "components/atoms/Typography";
import { SizeNamesEnum, TypesNamesEnum } from "enums/Button.enum";
import {
  AT_LEAST_NINE_CHARACTERS,
  AT_LEAT_ONE_LOWERCASE_LATTER,
  AT_LEAT_ONE_NUMBER,
  AT_LEAT_ONE_UPPERCASE_LATTER,
} from "lib/constants/regexp";
import { palette } from "lib/theme";
import { passwordComplexityValidation } from "lib/utils/validations";
import { getText } from "shared/locale-helper";
import {
  saveTokenToValidate,
  selectCurrentUser,
  selectTokenToValidate,
  setCredentials,
} from "state/slice/auth.slice";
import {
  useLoginMutation,
  useSetupPasswordMutation,
  useUpdatePasswordMutation,
  useValidateMfaMutation,
} from "state/store/api";
import { toast, ToastType } from "components/organisms/Toast";

const ChangePasswordModalBody: FC<{
  setShowModal: (show: boolean) => void;
  setTitle: (title: string) => void;
}> = ({ setShowModal, setTitle }) => {
  const tokenToValidate = useSelector(selectTokenToValidate);
  const user = useSelector(selectCurrentUser);

  const [validateCode, { isLoading }] = useValidateMfaMutation();
  const [login, { isLoading: loginLoading }] = useLoginMutation();
  const [updatePassword, { isLoading: updatePasswordLoading }] =
    useUpdatePasswordMutation();

  const dispatch = useDispatch();

  const [code, setCode] = useState([]);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleOnChange = (event: any) => {
    setErrorMessage(undefined);
    setCode([...code, event?.target?.value]);
  };

  const handleOnKeyDown = (event: any, indx: any) => {
    if (errorMessage) setErrorMessage(undefined);
    if (event.keyCode === 8) {
      if (code[indx]) {
        const updatedCureentValue = code.slice(0, indx);
        setCode(updatedCureentValue);
        return;
      }
      const updatedValue = code.slice(0, indx - 1);
      setCode(updatedValue);
    }
  };

  const validateCurrentPassword = async () => {
    try {
      const loginResponse = await login({
        usernameAndPasswordAuthenticationRequest: {
          username: user?.email,
          password: currentPassword,
        },
      }).unwrap();
      dispatch(saveTokenToValidate(loginResponse));
      return true;
    } catch {
      toast.show({
        title: "An error has ocurred",
        content: "Current password is not correct, please review it.",
        type: ToastType.Fail,
      });
      return false;
    }
  };

  const handleEnableKey = async (code: string) => {
    try {
      if (!validateCurrentPassword()) return;

      const data = (await validateCode({
        mfaAuthenticationRequest: {
          mfaToken: tokenToValidate?.accessToken,
          otpCode: code,
        },
      }).unwrap()) as { accessToken: string };
      dispatch(setCredentials({ accessToken: data.accessToken }));

      await updatePassword({
        updatePasswordRequest: {
          oldPassword: currentPassword,
          newPassword: newPassword,
        },
      }).unwrap();

      setCurrentStep(currentStep + 1);
    } catch (error) {
      toast.show({
        title: "An error has ocurred",
        content: "Current 2FA code is not correct, please verify and try again",
        type: ToastType.Fail,
      });
      setErrorMessage(
        "Something's off. Double-check the code or request a new one."
      );
    }
  };

  const [pwdEnforce, setpwdEnforce] = useState([
    {
      text: getText("n_uppercase_letter", { n: 1 }),
      icon: "Tick",
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAT_ONE_UPPERCASE_LATTER,
    },
    {
      text: getText("n_lowercase_letter", { n: 1 }),
      icon: "Tick",
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAT_ONE_LOWERCASE_LATTER,
    },
    {
      text: getText("n_characters", { n: 9 }),
      icon: "Tick",
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAST_NINE_CHARACTERS,
    },
    {
      text: getText("n_number", { n: 1 }),
      icon: "Tick",
      color: palette.darkBackgroundContrast.light20,
      pattern: AT_LEAT_ONE_NUMBER,
    },
  ]);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");

  const handlePasswordOnChange = (event: any) => {
    const paswordValue = event.target.value;
    const patternCheck = pwdEnforce.map((item) => {
      return {
        ...item,
        color: item.pattern.exec(paswordValue)
          ? palette.accent.main
          : palette.darkBackgroundContrast.light20,
      };
    });
    setNewPassword(paswordValue);
    setpwdEnforce(patternCheck);
  };

  const getPasswordRegexMatchMessage = (_password: any[]): string => {
    return _password
      .filter((el) => el.color === palette.darkBackgroundContrast.light20)
      .map((res) => ` ${res.text} is needed`)
      .join(",")
      .toString();
  };

  const validateChars = (str: string) => {
    if (str && !passwordComplexityValidation(str)) {
      setPasswordError(getPasswordRegexMatchMessage(pwdEnforce));
    } else {
      setPasswordError("");
    }
  };

  useEffect(() => {
    validateChars(newPassword);
  }, [currentPassword, newPassword]);

  const [currentStep, setCurrentStep] = useState(0);
  const steps = [];

  // Step 0
  steps.push(
    <ModalContainer>
      <ModalRow style={{ paddingTop: "24px" }}>
        <Input
          dataCyPrefix="profile"
          label={getText("current_password")}
          className="input"
          iconColor={palette.darkBackgroundContrast.light20}
          inputProps={{
            value: currentPassword,
            id: "currentPassword",
            name: "currentPassword",
            type: "password",
            onBlur: (e) => {
              setCurrentPassword(e.target.value);
              setPasswordError("");
            },
          }}
        />
      </ModalRow>
      <div
        style={{
          display: "flex",
          paddingTop: "8px",
          position: "relative",
          top: "1px",
        }}
      >
        <Text size="small" color={palette.purple.main}>
          {getText("forgot_password")}
        </Text>
      </div>
      <ModalRow
        style={{ paddingTop: "28px", position: "relative", top: "-4px" }}
      >
        <Input
          dataCyPrefix="profile"
          label={getText("new_password")}
          className="input"
          iconColor={palette.darkBackgroundContrast.light20}
          size=""
          inputProps={{
            value: newPassword,
            id: "newPassword",
            name: "newPassword",
            type: "password",
            onChange: (event: any) => {
              handlePasswordOnChange(event);
              if (passwordComplexityValidation(event?.target?.value)) {
                setPasswordError("");
              }
            },
            onBlur: (event: any) => {
              handlePasswordOnChange(event);
              setPasswordError("");
              validateChars(event.target.value);
            },
          }}
          additionalInfo={{
            info: pwdEnforce,
          }}
          errorMessage={passwordError}
          dismissAlert={() => setPasswordError("")}
        />
      </ModalRow>
      <ModalRow>
        <Button
          dataCyPrefix="profile"
          buttonType={TypesNamesEnum.ACCENT}
          size={SizeNamesEnum.EXTRA_LARGE_PLUS}
          label="Change"
          isLoading={loginLoading}
          onClick={async (e) => {
            const validPassword = !(await validateCurrentPassword());
            if (
              currentPassword.length === 0 ||
              newPassword.length === 0 ||
              passwordError !== "" ||
              validPassword
            ) {
              return;
            } else {
              setCurrentStep(currentStep + 1);
            }
          }}
          disabled={Boolean(
            currentPassword.length === 0 ||
              newPassword.length === 0 ||
              passwordError !== ""
          )}
        ></Button>
      </ModalRow>
    </ModalContainer>
  );

  // Step 1
  steps.push(
    <ModalContainer>
      <ModalRow>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: "63px",
          }}
        >
          <Text
            color={palette.darkBackgroundContrast.light70}
            letterSpacing="-0.5"
            align="center"
          >
            Enter the one-time code generated in the authenticator app
          </Text>
        </div>
      </ModalRow>
      <ModalRow style={{ paddingTop: "24px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop: "24.5px",
          }}
        >
          <DigitVerification
            code={code}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            errorMessage={errorMessage}
          />
        </div>
      </ModalRow>
      <ModalRow>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingBottom: "15px",
            flex: 1,
          }}
        ></div>
      </ModalRow>
      <ModalRow>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Button
            buttonType={TypesNamesEnum.ACCENT}
            size={SizeNamesEnum.EXTRA_LARGE_PLUS}
            label="Confirm"
            isLoading={isLoading || updatePasswordLoading}
            onClick={() => {
              const verificationCode = code.join("");
              handleEnableKey(verificationCode);
            }}
          />
          <Text
            display="block"
            size="small"
            color={palette.darkBackgroundContrast.light70}
          >
            Can't access the authenticator app?{" "}
            <Text
              size="small"
              color={palette.accent.light}
              onClick={() => window.open("https://support.abra.com")}
            >
              Contact Customer Support <br />
            </Text>{" "}
            to change 2FA.
          </Text>
        </div>
      </ModalRow>
    </ModalContainer>
  );

  // Step 2
  steps.push(
    <ModalContainer>
      <ModalRow style={{ position: "relative", top: "18px", left: "2px" }}>
        <Icon name="Success" size={96} color={palette.green.main} />
      </ModalRow>
      <ModalRow style={{ position: "relative", top: "37px" }}>
        <Text display="block" bold size="xl">
          Password changed successfully
        </Text>
      </ModalRow>
      <ModalRow style={{ position: "relative", top: "43px", left: "1px" }}>
        <Button
          dataCyPrefix="profile"
          buttonType={TypesNamesEnum.ACCENT}
          size={SizeNamesEnum.EXTRA_LARGE_PLUS}
          label={"Done"}
          onClick={() => {
            setShowModal(false);
            setCurrentStep(0);
          }}
        ></Button>
      </ModalRow>
    </ModalContainer>
  );

  return (
    <div
      id="change-password-modal-body"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1.25rem",
        height: "306px",
      }}
    >
      {steps[currentStep]}
    </div>
  );
};

export const ModalRow = styled.div`
  display: flex;
  justify-content: space-around;

  .input {
    width: 100%;
  }
`;
export const ModalContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export default ChangePasswordModalBody;
