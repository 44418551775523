/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { off, on } from "utils/scrollingUtils";
import { useLocation } from "react-router-dom";
import { PagesUrl } from "lib/constants/config.constant";

interface IUseScrollingUp {
  scrollingUp: boolean;
  setScrollingUp: Dispatch<SetStateAction<boolean>>;
}

const screenLocation: number = 30;

export const useScrollingUp = (): IUseScrollingUp => {
  let prevScroll = window.scrollY;
  const location = useLocation();

  const [scrollingUp, setScrollingUp] = useState(false);
  const handleScroll = () => {
    const currScroll = window.scrollY;
    const isScrolled = prevScroll > screenLocation;
    setScrollingUp(isScrolled);
    prevScroll = currScroll;
  };
  useEffect(() => {
    on(window, { type: "scroll", callback: handleScroll, options: true });
    return () => {
      if (
        (location.pathname === PagesUrl.PRICE_ALERTS &&
          prevScroll < screenLocation) ||
        (location.pathname === PagesUrl.TRANSACTION &&
          prevScroll < screenLocation)
      ) {
        setScrollingUp(false);
      }
      off(window, { type: "scroll", callback: handleScroll, options: true });
    };
  }, [prevScroll, location]);
  return { scrollingUp, setScrollingUp };
};
