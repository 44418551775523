import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const IgniteIcon: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9933 4.22345C15.8162 4.22935 11.8118 5.89238 8.85854 8.84782C5.90526 11.8033 4.2441 15.8098 4.23938 19.9889C4.24409 24.1683 5.90511 28.1753 8.85827 31.1315C11.8114 34.0876 15.8158 35.7516 19.9933 35.7587C24.1736 35.7564 28.182 34.0943 31.1383 31.1375C34.0946 28.1807 35.7571 24.171 35.7606 19.9889C35.7559 15.8075 34.0929 11.7989 31.1367 8.8431C28.1806 5.88728 24.1728 4.22581 19.9933 4.22345V4.22345Z"
      fill="#6636C4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9978 35.7587C24.1765 35.7552 28.1833 34.0933 31.1386 31.1375C34.0949 28.1807 35.7573 24.171 35.7609 19.9889C35.7562 15.8075 34.0932 11.7989 31.137 8.84309C28.1818 5.88827 24.1758 4.22693 19.9978 4.22345V35.7587Z"
      fill="#542DA0"
    />
    <path
      d="M18.2592 11.8096L19.5966 9.36112C19.6345 9.28991 19.691 9.23031 19.7601 9.18869C19.8292 9.14706 19.9082 9.12497 19.9889 9.12475C20.0683 9.12533 20.1462 9.14713 20.2144 9.18791C20.2825 9.22868 20.3386 9.28694 20.3767 9.35666L21.7141 11.8051L22.5031 13.259V10.5296C22.5061 9.93246 22.3524 9.34501 22.0573 8.82594L21.2638 7.45678L20.6665 6.42211C20.5976 6.3115 20.5017 6.22026 20.3879 6.157C20.274 6.09374 20.1459 6.06055 20.0156 6.06055C19.8854 6.06055 19.7573 6.09374 19.6434 6.157C19.5295 6.22026 19.4337 6.3115 19.3648 6.42211L18.7719 7.45678L17.9606 8.83932C17.6659 9.36018 17.5123 9.94895 17.5148 10.5474V13.2233L18.2592 11.8096Z"
      fill="white"
    />
    <path
      d="M16.066 18.9453C15.4441 20.094 14.6333 21.1297 13.6677 22.0091C13.406 22.2271 13.1954 22.4997 13.0506 22.808C12.9058 23.1162 12.8303 23.4525 12.8296 23.7931V25.4566H16.066V18.9453Z"
      fill="white"
    />
    <path
      d="M16.066 17.6073V14.6683C16.0667 14.3879 16.0058 14.1108 15.8877 13.8566L15.5757 13.2055L15.3438 12.7149C15.3235 12.6648 15.2886 12.6219 15.2437 12.5917C15.1989 12.5615 15.146 12.5454 15.092 12.5454C15.0379 12.5454 14.9851 12.5615 14.9402 12.5917C14.8953 12.6219 14.8605 12.6648 14.8401 12.7149L14.6083 13.2099L14.3007 13.8611C14.1844 14.1142 14.1251 14.3897 14.1269 14.6683V19.8551C14.1971 19.7654 14.278 19.6845 14.3676 19.6142"
      fill="white"
    />
    <path
      d="M23.934 18.9453C24.5593 20.0929 25.3713 21.1284 26.3367 22.0091C26.5974 22.2279 26.8074 22.5007 26.9521 22.8088C27.0968 23.1168 27.1728 23.4527 27.1748 23.7931V25.4566H23.9384L23.934 18.9453Z"
      fill="white"
    />
    <path
      d="M23.9385 17.6073V14.6683C23.9378 14.3879 23.9986 14.1108 24.1168 13.8566L24.4244 13.2055L24.6562 12.7149C24.6775 12.6651 24.7129 12.6227 24.7581 12.5929C24.8033 12.5631 24.8562 12.5472 24.9103 12.5472C24.9644 12.5472 25.0173 12.5631 25.0625 12.5929C25.1077 12.6227 25.1431 12.6651 25.1644 12.7149L25.3917 13.2099L25.6993 13.8611C25.8171 14.1138 25.878 14.3894 25.8777 14.6683V19.855C25.8072 19.767 25.728 19.6863 25.6414 19.6142"
      fill="white"
    />
    <path
      d="M22.6681 17.5047V16.6663C22.6649 15.9902 22.4949 15.3253 22.1733 14.7307L21.3308 13.1832L20.1673 11.0692C20.1491 11.0367 20.1225 11.0097 20.0904 10.9909C20.0583 10.972 20.0217 10.9621 19.9845 10.9621C19.9473 10.9621 19.9107 10.972 19.8786 10.9909C19.8465 11.0097 19.8199 11.0367 19.8017 11.0692L18.6561 13.1876L17.818 14.7396C17.4944 15.3337 17.3229 15.9987 17.3187 16.6752V17.5136V25.9516H22.6369L22.6681 17.5047ZM18.7096 16.4834C18.7095 16.1403 18.8439 15.8108 19.0839 15.5656C19.324 15.3205 19.6505 15.1793 19.9934 15.1722C20.1678 15.1687 20.3411 15.2 20.5032 15.2643C20.6653 15.3286 20.8129 15.4246 20.9375 15.5467C21.062 15.6688 21.161 15.8146 21.2285 15.9754C21.296 16.1363 21.3308 16.309 21.3308 16.4834H18.7096Z"
      fill="white"
    />
    <path
      d="M15.1343 28.6944H15.277C15.2585 28.2156 15.0557 27.7625 14.7108 27.4299C14.366 27.0974 13.9059 26.9113 13.427 26.9105C12.9347 26.9116 12.463 27.1081 12.1154 27.4567C11.7677 27.8054 11.5725 28.2777 11.5725 28.7702C11.5743 29.1092 11.6687 29.4414 11.8454 29.7306C12.0222 30.0199 12.2747 30.2553 12.5755 30.4114C12.785 29.9045 13.1397 29.4709 13.5951 29.1654C14.0505 28.8598 14.586 28.6959 15.1343 28.6944Z"
      fill="white"
    />
    <path
      d="M16.1685 28.9084C16.6567 29.1042 17.0789 29.4353 17.3855 29.8628C17.524 29.4432 17.6312 29.0139 17.7065 28.5784L17.8357 27.6864C17.5734 27.505 17.2631 27.4057 16.9442 27.401C16.5998 27.4016 16.265 27.5143 15.9902 27.7221C16.127 28.053 16.1967 28.4077 16.1953 28.7657C16.173 28.8058 16.1685 28.8549 16.1685 28.9084Z"
      fill="white"
    />
    <path
      d="M26.0917 30.0501C26.0789 30.0359 26.0639 30.0238 26.0472 30.0145C25.9337 29.92 25.8095 29.8391 25.6772 29.7736C25.6196 29.7467 25.5599 29.7243 25.4988 29.7067L25.3874 29.671C25.3276 29.652 25.2665 29.6371 25.2046 29.6265C25.1193 29.6107 25.0328 29.6018 24.9461 29.5997H24.8881C24.6419 29.5991 24.3979 29.6471 24.1703 29.741C23.9426 29.8349 23.7357 29.9727 23.5613 30.1467C23.387 30.3207 23.2487 30.5274 23.1543 30.755C23.06 30.9825 23.0114 31.2265 23.0114 31.4728C23.0114 31.4728 22.0396 31.9723 21.4333 28.73L21.2416 27.3921H18.7943L18.6071 28.73C17.9964 31.9723 17.0246 31.4728 17.0246 31.4728C17.0246 30.976 16.8273 30.4996 16.4762 30.1483C16.125 29.797 15.6488 29.5997 15.1523 29.5997H15.0898C15.0031 29.602 14.9167 29.6109 14.8313 29.6265C14.7708 29.6366 14.7112 29.6515 14.653 29.671L14.5371 29.7067C14.476 29.7243 14.4164 29.7467 14.3588 29.7736C14.227 29.8402 14.103 29.921 13.9888 30.0145L13.9486 30.0501C13.7443 30.2238 13.5799 30.4397 13.4668 30.683C13.3537 30.9263 13.2945 31.1911 13.2933 31.4594V32.1329H26.7872V31.4594C26.7814 31.1879 26.7162 30.921 26.596 30.6775C26.4758 30.434 26.3037 30.2198 26.0917 30.0501Z"
      fill="white"
    />
    <path
      d="M26.5732 26.9016C26.0942 26.9023 25.6341 27.0885 25.2893 27.421C24.9445 27.7536 24.7416 28.2067 24.7231 28.6855H24.8703C25.4172 28.6854 25.9518 28.8477 26.4065 29.1517C26.8612 29.4558 27.2155 29.888 27.4246 30.3936C27.7259 30.2377 27.9791 30.0024 28.1566 29.7132C28.3341 29.424 28.4294 29.0918 28.4321 28.7524C28.4297 28.2607 28.2328 27.7899 27.8845 27.4431C27.5361 27.0963 27.0646 26.9015 26.5732 26.9016Z"
      fill="white"
    />
    <path
      d="M22.6189 29.8539C22.9268 29.4297 23.3489 29.1018 23.8359 28.9085C23.8359 28.8639 23.8359 28.8148 23.8359 28.7702C23.8368 28.4124 23.9064 28.0581 24.041 27.7266C23.7665 27.5183 23.4315 27.4055 23.087 27.4055C22.7682 27.411 22.4581 27.5102 22.1954 27.6909L22.3247 28.5829C22.3915 29.0132 22.4898 29.4381 22.6189 29.8539Z"
      fill="white"
    />
  </svg>
);

export default IgniteIcon;
