import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const RewardEarned: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
   <path fillRule="evenodd" clipRule="evenodd" d="M10.3714 1.71969C8.70434 0.142163 5.96187 0.82868 5.23542 3.00656C4.98968 3.74324 5.03177 4.48881 5.28735 5.14003H2C1.44772 5.14003 1 5.58775 1 6.14003V12.14C1 12.6923 1.44772 13.14 2 13.14L3 13.14V22.14C3 22.6923 3.44772 23.14 4 23.14H20C20.5523 23.14 21 22.6923 21 22.14V16.14C21 15.5877 20.5523 15.14 20 15.14C19.4477 15.14 19 15.5877 19 16.14V21.14H5V13.14L14 13.14C14.5523 13.14 15 12.6923 15 12.14C15 11.5877 14.5523 11.14 14 11.14H3V7.14003H21V11.14H18C17.4477 11.14 17 11.5877 17 12.14C17 12.6923 17.4477 13.14 18 13.14H22C22.5523 13.14 23 12.6923 23 12.14V6.14003C23 5.58775 22.5523 5.14003 22 5.14003H18.7132C18.9684 4.48904 19.0103 3.74384 18.7647 3.00752C18.0383 0.829671 15.2958 0.143154 13.6288 1.72062L13.6287 1.72066L12.0004 3.26141L10.3714 1.71973L10.3714 1.71969ZM10.5454 4.63809L8.9967 3.17236L8.99666 3.17232C8.39169 2.5999 7.39621 2.84929 7.13266 3.6394L7.13266 3.63941C6.88662 4.37699 7.43584 5.13902 8.21303 5.13902H9.75403C9.76927 5.13902 9.78444 5.13936 9.79951 5.14003H10.0149L10.5454 4.63809ZM16.8675 3.64037C16.6039 2.85025 15.6084 2.60086 15.0034 3.17334L15.0034 3.17338L12.9249 5.14003H14.2372L14.2461 5.13999H15.7871C16.5643 5.13999 17.1135 4.37796 16.8675 3.64039L16.8675 3.64037Z" fill={color}/>
  </svg>
);

export default RewardEarned;