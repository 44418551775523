import React, { FC } from 'react';
import { StyledLabel } from './styles';

interface LabelProps {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
  shift?: string;
  children: React.ReactNode;
}

const Label: FC<LabelProps> = ({
  children,
  top,
  left,
  right,
  bottom,
  shift
}) => {
  return (
    <StyledLabel
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      shift={shift}>
      {children}
    </StyledLabel>
  );
};

export default Label;
