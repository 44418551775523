import React, { FC, useRef, useState, MouseEvent } from "react";
import Portal from "components/atoms/Portal";
import styled from "styled-components";
import ExportPanel from "components/templates/ExportPanel";

interface ActionProps {
  placement?: "bottom" | "top" | "left" | "right";
  space?: number;
  visible?: boolean;
  displayAsText?: boolean;
  maxWidth?: number;
  autoDismiss?: boolean;
  reportType: string;
}

const position = (placement: "bottom" | "top" | "left" | "right") => ({
  current: placement,
  negate() {
    if (this.current === "left") return "right";
    else if (this.current === "right") return "left";
    else if (this.current === "top") return "bottom";
    else if (this.current === "bottom") return "top";
    else return this.current;
  },
  isHorizontal() {
    return this.current === "left" || this.current === "right";
  },
  isVertical() {
    return this.current === "top" || this.current === "bottom";
  },
});

const defaultPoint = () => ({
  x: 0,
  y: 0,
  reset(position: { x: number; y: number }) {
    this.x = position.x;
    this.y = position.x;
  },
  restrict(boundaries: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  }) {
    if (this.x < boundaries.left) this.x = boundaries.left;
    else if (this.x > boundaries.right) this.x = boundaries.right - 24;

    if (this.y < boundaries.top) this.y = boundaries.top;
    else if (this.y > boundaries.bottom) this.y = boundaries.bottom;
  },
});

const getPoint = (
  element: EventTarget & HTMLDivElement,
  tooltip: React.RefObject<HTMLDivElement>,
  placement: "bottom" | "top" | "left" | "right" = "bottom",
  space: number
): { x: number; y: number } => {
  if (!tooltip.current) return { x: 0, y: 0 };
  let recursiveCount = 0;
  const point = defaultPoint();
  const rect = element.getBoundingClientRect();
  const boundaries = {
    left: space,
    top: space,
    right: document.body.clientWidth - (tooltip.current.clientWidth + space),
    bottom: window.innerHeight - (tooltip.current.clientHeight + space),
  };

  return (function recursive(placement: "bottom" | "top" | "left" | "right"): {
    x: number;
    y: number;
  } {
    recursiveCount++;
    const pos = position(placement);

    const positions = {
      bottom: {
        x: rect.left + (element.offsetWidth - tooltip.current.offsetWidth) / 2,
        y: rect.bottom + space,
      },
      top: {
        x: rect.left + (element.offsetWidth - tooltip.current.offsetWidth) / 2,
        y: rect.top - (tooltip.current.offsetHeight + space),
      },
      left: {
        x: rect.left - (tooltip.current.offsetWidth + space),
        y: rect.top + (element.offsetHeight - tooltip.current.offsetHeight) / 2,
      },
      right: {
        x: rect.right + space,
        y: rect.top + (element.offsetHeight - tooltip.current.offsetHeight) / 2,
      },
    };

    const currentPosition = positions[placement];

    point.x = currentPosition.x;
    point.y = currentPosition.y;

    if (recursiveCount < 3) {
      if (
        (pos.isHorizontal() &&
          (point.x < boundaries.left || point.x > boundaries.right)) ||
        (pos.isVertical() &&
          (point.y < boundaries.top || point.y > boundaries.bottom))
      ) {
        point.reset(recursive(pos.negate()));
      }
    }

    point.restrict(boundaries);

    return point;
  })(placement);
};

const ExportAction: FC<ActionProps> = ({
  placement = "bottom",
  space = 4,
  children,
  visible = true,
  autoDismiss = true,
  reportType,
}) => {
  const [show, setShow] = useState(false);
  const positionRef = useRef({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);
  let timeoutEnter = setTimeout(() => {}, 0);

  const handleOnMouseOver = (e: MouseEvent<HTMLDivElement>) => {
    clearInterval(timeoutEnter);
    setShow((_show) => !_show);
    positionRef.current = getPoint(
      e.currentTarget,
      tooltipRef,
      placement,
      space
    );
  };

  const clonedElement = React.cloneElement(
    children as React.ReactElement<any>,
    {
      onClick: handleOnMouseOver,
    }
  );

  const handleOnClick = () => setShow(false);

  return (
    <>
      {clonedElement}
      <Portal>
        {visible ? (
          <ActionStyled
            ref={tooltipRef}
            positionRef={positionRef}
            show={show}
            placement={placement}
          >
            <ContentContainer
              onMouseLeave={() => {
                if (!autoDismiss) setTimeout(() => setShow(false), 2000);
              }}
            >
              <ExportPanel onClose={() => handleOnClick()} reportType={reportType} />
            </ContentContainer>
          </ActionStyled>
        ) : (
          <></>
        )}
      </Portal>
    </>
  );
};

const ContentContainer = styled.div``;

const ActionStyled = styled.div<{
  show: boolean;
  placement: "bottom" | "top" | "left" | "right";
  positionRef: React.MutableRefObject<{ x: number; y: number }>;
}>`
  position: fixed;
  white-space: pre-wrap;
  ${(props) =>
    props?.positionRef?.current?.x && `left: ${props.positionRef.current.x}px`};
  top: ${(props) => props.positionRef.current.y}px;
  background: transparent;
  z-index: 99999;
  display: inline-block;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};

  transition-property: transform, opacity !important;
  transition-duration: 0.06s !important;
  transition-timing-function: cubic-bezier(0.33, 1, 0.61, 1) !important;
  transition-delay: 0.02s !important;

  transform-origin: ${(props) => position(props.placement).negate()};
  transform: scale(${(props) => (props.show ? 1 : 0.7)});

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

export default ExportAction;
