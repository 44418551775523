import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const NoTierHeader: FC<ISVG> = ({
  size = 40,
  width = size,
  height = size,
  color = "white",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M20.749 2.72048C24.1398 2.869 27.4119 4.01205 30.158 6.00726C32.9041 8.00248 35.0027 10.7617 36.1926 13.9413L38.7829 13.0984C37.4148 9.372 34.9696 6.13682 31.7583 3.80439C28.547 1.47196 24.7148 0.147682 20.749 0V2.72048Z"
      fill={color}
      fill-opacity="0.2"
    />
    <path
      d="M36.6517 15.3638C37.0695 16.87 37.2825 18.4256 37.2848 19.9886C37.2873 22.5904 36.7015 25.1591 35.5712 27.5023C34.4409 29.8455 32.7954 31.9027 30.7578 33.5197L32.3539 35.7228C34.7387 33.8528 36.6665 31.4642 37.9913 28.738C39.3161 26.0119 40.003 23.0198 39.9999 19.9886C40.0007 18.141 39.7441 16.3022 39.2375 14.5254L36.6517 15.3638Z"
      fill={color}
      fill-opacity="0.2"
    />
    <path
      d="M3.80732 13.9413C4.99722 10.7617 7.09587 8.00248 9.84195 6.00726C12.588 4.01205 15.8602 2.869 19.2509 2.72048V0C15.2855 0.148858 11.4538 1.47359 8.24274 3.80586C5.03167 6.13813 2.5862 9.37266 1.21704 13.0984L3.80732 13.9413Z"
      fill={color}
      fill-opacity="0.2"
    />
    <path
      d="M29.5408 34.4028C26.7136 36.2813 23.3939 37.2807 20 37.2749C16.606 37.2807 13.2863 36.2813 10.4592 34.4028L8.85864 36.606C12.1524 38.8239 16.0339 40.0058 20.0044 39.9999C23.9747 40.0042 27.8557 38.8224 31.1502 36.606L29.5408 34.4028Z"
      fill={color}
      fill-opacity="0.2"
    />
    <path
      d="M9.24654 33.5197C7.20898 31.9027 5.56346 29.8455 4.43315 27.5023C3.30284 25.1591 2.71704 22.5904 2.71958 19.9886C2.72046 18.4258 2.93191 16.8702 3.3482 15.3638L0.762375 14.5254C0.255769 16.3022 -0.000819497 18.141 6.96151e-06 19.9886C-0.00252391 23.0202 0.685053 26.0125 2.0106 28.7387C3.33615 31.4648 5.26487 33.8533 7.65047 35.7228L9.24654 33.5197Z"
      fill={color}
      fill-opacity="0.2"
    />
  </svg>
);

export default NoTierHeader;
