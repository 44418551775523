import { PasswordStrengthEnum } from "components/molecules/PasswordStrength/PasswordStrength.interface";
import {
  VALID_EMAIL_REGEXP,
  VALID_PASSWORD_REGEXP,
  VALID_ADDRESS_REGEX,
  VALID_CITY_REGEX,
} from "../constants/regexp";

export const emailValidation = (email: string): RegExpMatchArray | null => {
  return String(email).toLowerCase().match(VALID_EMAIL_REGEXP);
};

export const passwordComplexityValidation = (
  password: string
): RegExpMatchArray | null => {
  const pattern = new RegExp(VALID_PASSWORD_REGEXP);
  return pattern.exec(password);
};

export const emptyStringValidation = (text: string): boolean => {
  return !text || text?.length === 0;
};

export const isNumeric = (number: number) => {
  return !isNaN(parseFloat(number.toString())) && isFinite(number);
};

export const isDigitsOnly = (value: string) =>
  value.split("").every((c) => "0123456789".includes(c));

export const isAddressValid = (value: string) =>
  VALID_ADDRESS_REGEX.test(value);

export const isCityValid = (value: string) => {
  if (VALID_CITY_REGEX.test(value)) {
    return true;
  }
  return false;
};

export const isPostalCodeValid = (value: string) =>
  value.split("").every((c) => "0123456789-".includes(c));

export const strengthValidator = (password: string) => {
  const isWhitespace = /^(?=.*\s)/;
  const validLength = 15;
  const isContainsNumber = /^(?=.*[0-9])/;
  const isContainsLowercase = /^(?=.*[a-z])/;
  const isContainsUppercase = /^(?=.*[A-Z])/;
  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
  if (!password) {
    return PasswordStrengthEnum.STRENGTH;
  }
  
  if (isWhitespace.test(password)) {
    return PasswordStrengthEnum.WEAK;
  }

  if (
    validLength < (password?.length) &&
    isContainsUppercase.test(password) &&
    isContainsLowercase.test(password) &&
    isContainsNumber.test(password) &&
    isContainsSymbol.test(password)
  ) {
    return PasswordStrengthEnum.STRONG;
  }

  if (
    validLength < (password?.length) &&
    isContainsUppercase.test(password) &&
    isContainsLowercase.test(password) &&
    isContainsNumber.test(password)
  ) {
    return PasswordStrengthEnum.GOOD;
  }

  return PasswordStrengthEnum.WEAK;
};
