import { Text } from 'components/atoms/Typography'
import { palette } from 'lib/theme'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { capitalize } from 'shared'
import usePermissions from 'shared/usePermissions'
import { selectCurrentUser } from 'state/slice/auth.slice'
import { useGetUsersFromOrganizationQuery, UsersFromOrganization } from 'state/store/api'
import styled from 'styled-components'

const UsersContainer = () => {
  const user = useSelector(selectCurrentUser)
  const [loadUsers, setLoadUsers] = useState(false)
  const permissions = usePermissions()

  useEffect(() => {
    const organization = user?.organizations.find(c => user?.organization?.id === c.organizationId)
    const isOwner = organization && organization.owner
    const isAccountManager = permissions.isAccountManager
    setLoadUsers(isOwner || isAccountManager)
  }, [user, permissions])

  return loadUsers ? <UsersInfoContainer /> : <></>
}

const UsersInfoContainer = () => {

  return <Container>

  </Container>
}

const UsersBox = styled.div`
  display: flex;
  margin-top: 1vh;
  flex-direction: column;
  gap: 1vh;
`
const Container = styled.div`
  flex-grow: 1;
  display: flex;
  align-self: stretch;
  overflow-y: scroll;
  justify-content: flex-end;
  flex-direction: column;
`
const UserInfoStyled = styled.div`
  display: flex;
  max-width: 330px;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1vw;
  border-bottom: 1px solid rgba(255,255,255,0.2);
  border-top: 1px solid rgba(255,255,255,0.2);
`
export default UsersContainer
