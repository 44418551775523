import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "state/store"
import { WithdrawableOrder } from 'state/store/withdrawApi'

interface WithdrawState {
  withdrawableOrders: WithdrawableOrder[],
  loadingWithdrawableOrders: boolean
  hasFetchedWithdrawableOrders: boolean
}
const initialState: WithdrawState = {
  withdrawableOrders: [],
  loadingWithdrawableOrders: false,
  hasFetchedWithdrawableOrders: false
}


export const withdrawSlice = createSlice({
  name: "withdraws",
  initialState,
  reducers: {
    setWithdrawableOrders: (state, action: PayloadAction<WithdrawableOrder[]>) => {
      state.withdrawableOrders = action.payload
      state.hasFetchedWithdrawableOrders = true
    },
    setLoadingWithdrawableOrders: (state, action: PayloadAction<boolean>) => {
      state.loadingWithdrawableOrders = action.payload
    },
  },
})

export const { setWithdrawableOrders, setLoadingWithdrawableOrders } = withdrawSlice.actions

export const withdrawSelector = createSelector(
  (state: RootState) => state.withdraws,
  (state: WithdrawState) => state
)

