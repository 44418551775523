import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const MarketDown: FC<ISVG> = ({
  size = 15,
  width = size,
  height = size,
  color = "#EC396B",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <rect
      x="1"
      y="0.5"
      width={width}
      height={height}
      rx="9.5"
      fill={color}
      fillOpacity="0.15"
    />
    <path
      d="M15.8941 15.8794V11.3539M15.8941 15.8794H11.3686M15.8941 15.8794L8.82304 8.80834"
      stroke="#EC396B"
      strokeWidth="1.5"
    />
    <rect
      x="1"
      y="0.5"
      width={width}
      height={height}
      rx="9.5"
      stroke="#1D1D43"
    />
  </svg>
);

export default MarketDown;
