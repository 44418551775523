import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import RadioButton from 'components/atoms/RadioButton';
import { TypesNamesEnum } from 'enums/Button.enum';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { emailValidation, symbolsValidation } from 'shared';
import { CustodyUserRequest } from 'state/store/onboardingApi';
import styled from 'styled-components';
import { loadCaptchaEngine , LoadCanvasTemplate, validateCaptcha} from './customCaptchaEngine'
import { ToastType, toast } from 'components/organisms/Toast'

const CAPTCHA_SIZE = 6
interface Props {
  next: () => void;
  information: CustodyUserRequest;
  setInformation: (email: CustodyUserRequest) => void;
}
const RegisterBasicInformation: FC<Props> = ({ next, information, setInformation }) => {
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const navigate = useNavigate();
  const [captchaValidation, setCaptchaValidation] = useState('')
  const [captchaValid, setCaptchaValid] = useState(false)
  useEffect(() => {
    if(captchaValidation.length == CAPTCHA_SIZE){
      const isCaptchaValid = validateCaptcha(captchaValidation)
      if(isCaptchaValid) setCaptchaValid(true)
      else {
        setCaptchaValidation("")
        loadCaptchaEngine(CAPTCHA_SIZE); 
        toast.show({
          type: ToastType.Fail,
          title: "Captcha code is invalid",
          content:  'Please try again',
        })
      }
    }
      else setCaptchaValid(false)
  }, [captchaValidation])

  const back = () => {
    navigate(-1);
  };

  const validateEmail = (email: string) => {
    if (!symbolsValidation('@', email)) {
      setEmailError(true);
    } else if (!emailValidation(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    setEmailError(!symbolsValidation('@', email) && !emailValidation(email));
  };

  const redirectToAcmApplication = () => {
    let acmApplicationUrl = 'https://www.abra.com/acm-application';
    window.location.href = acmApplicationUrl;
  };

  const handleNext = () => {
    if (information.organizationType === 'INSTITUTION') {
      redirectToAcmApplication();
    } else {
      next();
    }
  }

  return (
    <RegisterBasicInformationBox>
      <EmailBox>
        <p>Please provide your email address</p>
        <Input
          label='Email'
          className='input'
          inputProps={{
            value: information.email,
            id: 'email',
            name: 'email',
            type: 'email',
            onChange: (event: any) => {
              validateEmail(event?.target?.value);
              setEmailErrorMessage('');
              setInformation({ ...information, email: event.target.value ?? '' });
            },
            onBlur: (event: any) => {
              setEmailErrorMessage(emailError ? 'invalid email format' : '');
              validateEmail(event?.target?.value);
            },
          }}
          errorMessage={emailErrorMessage}
          dismissAlert={() => setEmailErrorMessage('')}
          dismissAlertButton={false}
        />
      </EmailBox>
      <div>
        <RadioGroup>
          <p>Are you a U.S. Resident or Tax Payer?</p>
          <RadioButton
            size={30}
            name='usResident'
            text='Yes'
            value='Yes'
            checked={information.usResident}
            onClick={() => setInformation({ ...information, usResident: true })}
          />
          <RadioButton
            name='usResident'
            text='No'
            value='No'
            checked={!information.usResident}
            onClick={() => setInformation({ ...information, usResident: false })}
          />
          <p>Are you an Individual or an Organization?</p>
          <RadioButton
            size={30}
            name='clientType'
            text='Individual'
            value='Individual'
            checked={information.organizationType === 'INDIVIDUAL'}
            onClick={() => setInformation({ ...information, organizationType: 'INDIVIDUAL' })}
          />
          <RadioButton
            name='clientType'
            text='Organization'
            value='INSTITUTION'
            checked={information.organizationType === 'INSTITUTION'}
            onClick={() => setInformation({ ...information, organizationType: 'INSTITUTION' })}
          />
        </RadioGroup>
        <CaptchaBox>
          <Input
            label={captchaValid ? 'The code is valid' : 'Please type the captcha code provided'}
            className='input'
            inputProps={{

              value: captchaValidation,
              id: 'captcha',
              disabled: captchaValid, 
              name: 'captcha',
              onChange: (event: any) => {
                setCaptchaValidation(event?.target?.value);
              },
            }}
          />
          <CaptchaTemplate />
        </CaptchaBox>
      </div>
      <Grid>
        <Button buttonType={TypesNamesEnum.SECONDAY} label='Back' onClick={back} type='button' />
        <Button buttonType={TypesNamesEnum.ACCENT} label='Next' onClick={handleNext} type='submit' disabled={(!!emailError || !information.email ) || !captchaValid} />
      </Grid>
    </RegisterBasicInformationBox>
  );
};

const CaptchaTemplate = (props: any) => {
  useEffect(() => {
    loadCaptchaEngine(CAPTCHA_SIZE); 
  }, [])
  
  return <div>
<CaptchaTemplateBox >
  <LoadCanvasTemplate  />
</CaptchaTemplateBox>
</div>
}

const CaptchaTemplateBox = styled.div`
  display: flex;
  font-family: monospace;
  align-items: stretch;

  #reload_href{
    color: #955be5 !important; 
    cursor: pointer;
  }
`
const CaptchaBox = styled.div`
  display: flex;
  gap: 4px;
  #reload_href{
    color: #955be5 !important; 
    cursor: pointer;
  }
`
const Grid = styled.div`
  display: grid;
  gap: 10px;
  height: 50px;
  grid-template-columns: 1fr 1fr;
  Button {
    font-weight: 400;
    font-size: 1.1rem;
  }
`;
const RegisterBasicInformationBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const EmailBox = styled.div`
  display: flex;
  flex-direction: column;
  gap:15px;
`
const RadioGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 2vh;
  margin-top: 0.5vh;
  margin-bottom: 3vh;
`;

export default RegisterBasicInformation;
