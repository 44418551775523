import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const UpArrowWide: FC<ISVG> = ({ size = 24, width = size, height = size, color, svgProps }) => (
  <svg
      width={width}
      height={height}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
    <path
      d="M20.9996 9.00001C20.8016 9.00001 20.6026 8.94101 20.4276 8.81901L11.0006 2.22001L1.57362 8.81901C1.12362 9.13501 0.497616 9.02601 0.180616 8.57301C-0.135384 8.12101 -0.0263819 7.497 0.426618 7.18L10.4266 0.18C10.7706 -0.06 11.2296 -0.06 11.5726 0.18L21.5726 7.18C22.0256 7.496 22.1356 8.12001 21.8186 8.57301C21.6256 8.85201 21.3146 9.00001 20.9996 9.00001Z"
      fill={color}
    />
  </svg>
);

export default UpArrowWide;
