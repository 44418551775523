import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Borrow: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.2071 2.20711C19.5976 1.81658 19.5976 1.18342 19.2071 0.792893C18.8166 0.402369 18.1834 0.402369 17.7929 0.792893L15.0006 3.58521L9.21286 2.03409C8.93564 1.95979 8.63987 2.00819 8.40078 2.16696L2.44679 6.12094C1.98671 6.42647 1.86143 7.04712 2.16696 7.50719C2.47249 7.96727 3.09314 8.09255 3.55321 7.78702L9.13116 4.08277L17.6375 6.36249L19.9172 14.8689L16.213 20.4468C15.9074 20.9069 16.0327 21.5275 16.4928 21.833C16.9529 22.1386 17.5735 22.0133 17.879 21.5532L21.833 15.5992C21.9918 15.3602 22.0402 15.0644 21.9659 14.7872L20.4148 8.99942L23.2071 6.20711C23.5976 5.81658 23.5976 5.18342 23.2071 4.79289C22.8166 4.40237 22.1834 4.40237 21.7929 4.79289L19.817 6.7688L19.4199 5.28716C19.3274 4.9421 19.0579 4.67258 18.7129 4.58011L17.2312 4.18302L19.2071 2.20711ZM2.5 15.5C2.5 12.1863 5.18629 9.5 8.5 9.5C11.8137 9.5 14.5 12.1863 14.5 15.5C14.5 18.8137 11.8137 21.5 8.5 21.5C5.18629 21.5 2.5 18.8137 2.5 15.5ZM8.5 7.5C4.08172 7.5 0.5 11.0817 0.5 15.5C0.5 19.9183 4.08172 23.5 8.5 23.5C12.9183 23.5 16.5 19.9183 16.5 15.5C16.5 11.0817 12.9183 7.5 8.5 7.5Z"
      fill={color}
    />
  </svg>
);

export default Borrow;
