import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const LTVIndicatorUp: FC<ISVG> = ({
  size = 12,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
    <path
      d='M6 2L12 8.45681H0L6 2Z'
      fill={color}
    />
  </svg>
);

export default LTVIndicatorUp;