import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Back: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.499951 7.79999L6.19995 13.4C6.59995 13.8 7.19995 13.8 7.59995 13.4C7.99995 13 7.99995 12.4 7.59995 12L2.69995 6.99999L7.59995 1.99999C7.99995 1.59999 7.99995 0.999987 7.59995 0.599988C7.39995 0.399988 7.19995 0.299988 6.89995 0.299988C6.59995 0.299988 6.39995 0.399988 6.19995 0.599988L0.499951 6.19999C0.0999512 6.69999 0.0999512 7.29999 0.499951 7.79999C0.499951 7.69999 0.499951 7.69999 0.499951 7.79999Z"
      fill="white"
    />
  </svg>
);

export default Back;
