import React, { FC } from "react";
import styled, { css } from "styled-components";
import { palette, typography } from "../../../lib/theme";
import { TextProps } from "./Typography.interface";

const TextBase: FC<TextProps> = ({
  size = typography.fontSize,
  opacity = 1,
  bold = false,
  display = "inline",
  align = "left",
  hasHover = false,
  hasLineHeight = true,
  ellipsis = false,
  onClick,
  children,
  ...otherProps
}) => {
  return (
    <>
      {display === "inline" ? (
        <span onClick={onClick} {...otherProps}>
          {children}
        </span>
      ) : (
        <div onClick={onClick} {...otherProps}>
          {children}
        </div>
      )}
    </>
  );
};

export const Text = styled(TextBase)<TextProps>`
  font-weight: ${(props) => (props.bold ? "600" : "400")};
  color: ${(props) =>
    props.color ? props.color : palette.darkBackgroundContrast.white};
  font-size: ${(props) =>
    typography.fontSizes[props.size] || `${typography.fontSize}px`};

  ${(props) =>
    props.hasLineHeight
      ? css`
          line-height: ${props.lineHeight ||
          typography.lineHeights[props.size] ||
          `${typography.lineHeight}`};
        `
      : ""}

  ${(props) =>
    props.letterSpacing ? `letter-spacing: ${props.letterSpacing}px;` : ""};
  opacity: ${(props) => props.opacity};
  display: ${(props) => props.display};
  text-align: ${(props) => props.align};
  cursor: ${(props) => (props.onClick === undefined ? "inherit" : "pointer")};

  ${(props) =>
    props.ellipsis &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${(props) =>
    props.hasHover &&
    css`
      &:hover {
        color: ${props.color === palette.white.main
          ? palette.accent.light
          : props.color === palette.accent.light
          ? palette.accent.lighter
          : props.color};
      }
    `}
`;

Text.defaultProps = {
  hasLineHeight: true,
};
