import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Magnifier: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 88 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.8217 68.3827C52.5951 68.3827 67.0032 53.9746 67.0032 36.2012C67.0032 18.4278 52.5951 4.01953 34.8217 4.01953C17.0483 4.01953 2.64014 18.4278 2.64014 36.2012C2.64014 53.9746 17.0483 68.3827 34.8217 68.3827Z"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.7806 59.6859C48.0239 59.6859 58.7598 49.1353 58.7598 36.1204M10.8016 36.1203C10.8016 23.1054 21.5374 12.5547 34.7806 12.5547"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M62.0298 55.1367L83.1903 76.2973C85.3444 78.4514 85.3444 81.9611 83.1903 84.1152C81.0363 86.2692 77.5264 86.2692 75.3723 84.1152L54.2119 62.9549"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Magnifier;
