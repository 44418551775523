import { palette } from "lib/theme";
import React from "react";
import { LoanHealthResponse } from "state/store/loanApi";
import styled from "styled-components";

interface HealthFactorMeterProps {
    loanHealth: LoanHealthResponse
}

const HealthFactorMeter = ({loanHealth}: HealthFactorMeterProps) => {
    const {currentHealthFactor, nextHealthFactor} = loanHealth;
    const currentHealth = currentHealthFactor.isInfinity ? Infinity : currentHealthFactor.value
    const nextHealth = nextHealthFactor.isInfinity ? Infinity : nextHealthFactor.value
    return (
        <div>
            <p>
                Health factor change will be
                <HealthFactorWrapper>
                    <HealthDisplayLeft value={currentHealthFactor.status}>{currentHealth}</HealthDisplayLeft> 
                    <HealthDisplayRight value={nextHealthFactor.status}>{nextHealth}</HealthDisplayRight>
                </HealthFactorWrapper>
            </p>
        </div>
    )
}

interface HealthDisplayProps {
    value: string
}

const HealthFactorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
`
  
const HealthDisplayLeft = styled.div<HealthDisplayProps>`
    width: 248px;
    height: 25px;
    background:  ${({value}) => value === "SAFE" ? `${palette.green.main}` : value === "WARNING" ? `${palette.orange.main}` : `${palette.red.main}` };
    border: solid white;
    border-width: 1px 0px 1px 1px;
    position: relative;
    text-align: center;
    z-index: 2;
  
    ::after {
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-left: 16px solid white;
        content: '';
        position: absolute;
        top: 0px;
        left: 245px;
    }
  
    ::before {
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-left: 8px solid  transparent;
        content: '';
        position: absolute;
        top: 0px;
        left: 246px;
    }
`
  
const HealthDisplayRight = styled.div<HealthDisplayProps>`
    width: 248px;
    height: 25px;
    background: ${({value}) => value === "SAFE" ? `${palette.green.main}` : value === "WARNING" ? `${palette.orange.main}` : `${palette.red.main}` };
    border: solid white;
    border-width: 1px 1px 1px 0px;
    position: relative;
    text-align: center;
`

export default HealthFactorMeter