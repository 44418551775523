import React from 'react'
import { PendingTransaction } from 'state/store/custodyApi'
import { useCancelDefiTransactionRequestMutation } from 'state/store/investApi'
import styled from 'styled-components'
import useAssets from 'shared/useAssets'
import { CustodyMainText, CustodyStyledTable } from '../CustodyStyles'
import { useParams } from 'react-router-dom'
import useStrategy from './useStrategy'
import { formatDate } from 'shared'
import LoadingBar from 'components/atoms/Loading/LoadingBar'
import ActionButton from '../CustodyActionButton'
import CustodyInvestCancelModal from '../CustodyInvestCancelModal'


const StrategyDetailsPendingActivity = () => {
  const { strategyIdentifier } = useParams();
  const {pendingTransactions} = useStrategy(strategyIdentifier ?? '')

  const [cancelDefiTransaction, cancelMeta] = useCancelDefiTransactionRequestMutation();
  const [cancelAbraTxUID, setCancelAbraTxUID] = React.useState<string | null>(null);
  const showCancelModalHandler = (abraTx: string) => {
    setCancelAbraTxUID(abraTx);
  }
  const confirmCancelHandler = () => {
    if (cancelAbraTxUID) {
      cancelDefiTransaction(cancelAbraTxUID);
    }
    setCancelAbraTxUID(null);
  }
  const closeCancelModalHandler = () => {
    setCancelAbraTxUID(null);
  }


  return (
    <div>
      <TokenSummaryTitle>Pending Activity</TokenSummaryTitle>
      {pendingTransactions.isLoading && <LoadingBar />}
      {!pendingTransactions.isLoading && pendingTransactions.data?.length! > 0 && (
        <CustodyStyledTable>
          <thead>
            <tr>
              <th>Date</th>
              <th>Network</th>
              <th>Quantity</th>
              <th>Action</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {pendingTransactions.data?.map((record, index) => 
              <TokenSummaryRow key={record.abraTxUID} record={record}
                showCancelModalHandler={showCancelModalHandler} cancelLoading={cancelMeta.isLoading} />)}
          </tbody>
        </CustodyStyledTable>
      )}
      {!pendingTransactions.isLoading && pendingTransactions.data?.length === 0 && (
        <p>Currently there are no pending transactions</p>
      )}
      <CustodyInvestCancelModal abraTxUID={cancelAbraTxUID} showModal={!!cancelAbraTxUID}
        onClose={closeCancelModalHandler} onConfirm={confirmCancelHandler} />
    </div>
  )
}

interface TokenSummaryRowProps {
  record: PendingTransaction,
  showCancelModalHandler: (abraTx: string) => void
  cancelLoading: boolean
}
const TokenSummaryRow: React.FC<TokenSummaryRowProps> = ({record, showCancelModalHandler, cancelLoading}) => {
  
  const {getPriceFormattedI} = useAssets()
  const cancelButton = () => {
    return (
      <ActionButton 
        hidden={!record.cancelable}
        disabled={!record.cancelable || cancelLoading}
        onClick={() => {record.cancelable && showCancelModalHandler(record.abraTxUID)}}>
          Cancel
        </ActionButton>
    );
  }
  return (
    <tr>
      <td>
        <CustodyMainText>
          {formatDate(record.dateTime)}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {record.networkName}
        </CustodyMainText>
      </td>
      <td>
        <CustodyMainText>
          {getPriceFormattedI(record.amount.asset, record.amount.amount)}
        </CustodyMainText>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText>{record.actionName}</CustodyMainText>
        </TextGroup>
      </td>
      <td>
        <TextGroup>
          <CustodyMainText>
            {record.status}
            {cancelButton()}
          </CustodyMainText>
        </TextGroup>
      </td>
    </tr>
  )
}


const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
`

const TokenSummaryTitle = styled.div`
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  letter-spacing: -0.5px;
`
export default StrategyDetailsPendingActivity
