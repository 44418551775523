import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Favorite: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.4482 0.80483C8.36397 0.634168 8.19015 0.526123 7.99984 0.526123C7.80952 0.526123 7.63571 0.634168 7.55147 0.80483L5.40182 5.16025L0.594636 5.85846C0.406295 5.88582 0.249815 6.01772 0.190989 6.19872C0.132162 6.37972 0.181191 6.57842 0.31746 6.71128L3.79594 10.1027L2.97503 14.8895C2.94287 15.0771 3.01997 15.2667 3.17392 15.3785C3.32788 15.4904 3.53199 15.5052 3.70045 15.4166L7.99984 13.1571L12.2992 15.4166C12.4677 15.5052 12.6718 15.4904 12.8258 15.3785C12.9797 15.2667 13.0568 15.0771 13.0246 14.8895L12.2037 10.1027L15.6822 6.71128C15.8185 6.57842 15.8675 6.37972 15.8087 6.19872C15.7499 6.01772 15.5934 5.88582 15.405 5.85846L10.5979 5.16025L8.4482 0.80483Z"
      fill="white"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Favorite;
