import styled from "styled-components";

interface ButtonProps {
  disabled: boolean;
  hidden?: boolean;
}

const ActionButton = styled.div<ButtonProps>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ disabled }) => (disabled ? '#555' : '#6f2acd')};
  display: flex;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  align-items: center;
  margin-left: 1vw;
  justify-content: space-around;
  border-radius: 0.5vw;
  text-transform: uppercase;
  color: #fff;
  font-size: 70%;
  padding: 4px 1vw;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #292749;
  }
`;

export default ActionButton;