import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const DeleteIconBtn: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4725 4.99994H22C22.5523 4.99994 23 5.44765 23 5.99994C23 6.55222 22.5523 6.99994 22 6.99994H20.9079L19.9114 17.2975C19.8404 18.0304 19.7817 18.6377 19.6945 19.1318C19.6037 19.6468 19.4703 20.1162 19.2097 20.5523C18.8033 21.2321 18.2042 21.776 17.4884 22.115C17.0293 22.3324 16.5492 22.42 16.0279 22.4608C15.5276 22.5 14.9176 22.5 14.1812 22.4999H9.81892C9.08255 22.5 8.47235 22.5 7.97215 22.4608C7.4508 22.42 6.97072 22.3324 6.51163 22.115C5.79579 21.776 5.19672 21.2321 4.79033 20.5523C4.5297 20.1162 4.39632 19.6468 4.30548 19.1318C4.21832 18.6377 4.15956 18.0304 4.08863 17.2974L3.0921 6.99994H2C1.44772 6.99994 1 6.55222 1 5.99994C1 5.44765 1.44772 4.99994 2 4.99994H7.52747C7.77622 2.74999 9.68374 1 12 1C14.3163 1 16.2238 2.74999 16.4725 4.99994ZM8.48884 6.99994H5.10145L6.07558 17.066C6.15121 17.8475 6.20312 18.3765 6.27507 18.7844C6.34487 19.1801 6.42158 19.3832 6.50701 19.5261C6.71021 19.866 7.00974 20.138 7.36766 20.3075C7.51814 20.3787 7.72763 20.4355 8.12823 20.4669C8.54116 20.4992 9.07268 20.4999 9.85791 20.4999H14.1421C14.9273 20.4999 15.4588 20.4992 15.8718 20.4669C16.2724 20.4355 16.4819 20.3787 16.6323 20.3075C16.9903 20.138 17.2898 19.866 17.493 19.5261C17.5784 19.3832 17.6551 19.1801 17.7249 18.7844C17.7969 18.3765 17.8488 17.8475 17.9244 17.066L18.8986 6.99994H15.5112C15.5074 6.99998 15.5037 7 15.5 7H8.5C8.49627 7 8.49255 6.99998 8.48884 6.99994ZM14.45 4.99994C14.2183 3.85885 13.2095 3 12 3C10.7905 3 9.78168 3.85885 9.55002 4.99994H14.45Z"
      fill={color}
    />
  </svg>
);

export default DeleteIconBtn;
