import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const LaunchBig: FC<ISVG> = ({
  size = 160,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M82.2819 2.5336C97.823 2.99138 112.867 8.11301 125.459 17.2329C138.051 26.3528 147.609 39.0494 152.891 53.6726"
      stroke="#FFA519"
      strokeWidth="5"
    />
    <path
      d="M154.263 57.835C158.709 72.7334 158.572 88.6248 153.869 103.444C149.166 118.264 140.114 131.326 127.889 140.933"
      stroke="#FFA519"
      strokeWidth="5"
    />
    <path
      d="M124.18 143.674C111.406 152.537 96.2616 157.353 80.7144 157.497C65.1673 157.64 49.9364 153.104 37.0011 144.477"
      stroke="white"
      stroke-opacity="0.2"
      strokeWidth="5"
    />
    <path
      d="M32.6219 141.332C20.3177 131.827 11.1568 118.841 6.32974 104.061C1.50264 89.2817 1.2325 73.392 5.55442 58.4569"
      stroke="white"
      stroke-opacity="0.2"
      strokeWidth="5"
    />
    <path
      d="M7.0635 53.7985C12.32 39.1662 21.856 26.4532 34.4324 17.3116C47.0088 8.1699 62.044 3.02229 77.5843 2.53766"
      stroke="white"
      stroke-opacity="0.2"
      strokeWidth="5"
    />
  </svg>
);

export default LaunchBig;
