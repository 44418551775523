import React, { FC, useEffect, useState } from "react";
import Notification from "components/organisms/Notification";

import { Text } from "components/atoms/Typography";
import AnimatedWidgetStep from "components/atoms/Animated/AnimatedWidgetStep";
import { palette } from "lib/theme";
import { OrderDto } from "state/store/api";
import styled from "styled-components";

const NotificationContainer = styled.div`
  max-height: 230px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
`;
const RowContainer = styled.div<{ flexDirection?: string }>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection ?? "row"};
  width: "100%";
`;
export const NotificationLTV: FC<{ orderResponse: OrderDto }> = ({
  orderResponse,
}) => {
  const [alert, setAlert] = useState<any>();

  const getStatusNotification = (order: OrderDto) => {
    if (!order?.status) {
      return [];
    }

    if (["closed"].includes(order?.status)) {
      return [];
    }

    if (["off", "cancelled"].includes(order?.status)) {
      return [
        {
          title: "Loan canceled",
          message:
            "Unfortunately, your Loan contract canceled because you did not deposit enough collateral in time. You can apply for a new loan at any time.",
          type: "neutral",
          icon: "Notification",
        },
      ];
    }

    if (["settled"].includes(order?.status)) {
      return [
        {
          title: "Loan repayment successful",
          message: "Your collateral will be returned back to you.",
          type: "success",
          icon: "SuccessIcon",
        },
      ];
    }
  };

  useEffect(() => {
    if (
      orderResponse &&
      !["settled", "closed", "off", "cancelled"].includes(orderResponse?.status)
    ) {
      setAlert(getInfoNotification(orderResponse));
    } else if (orderResponse) {
      const statusNotifications = getStatusNotification(orderResponse);

      if (statusNotifications && statusNotifications.length)
        setAlert(statusNotifications[0]);
    }
  }, [orderResponse]);

  return (
    <AnimatedWidgetStep name="loanPayments">
      <NotificationContainer>
        {alert ? (
          <div>
            <Notification
              height={"100%"}
              icon={alert.icon}
              type={alert.type}
              backgroundColor={palette.night.light10 as string}
              withBorder
            >
              <RowContainer flexDirection="row">
                <RowContainer flexDirection="column">
                  <Text size="large">{alert.title}</Text>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Text opacity={0.7}>
                      <span style={{ whiteSpace: "unset" }}>
                        {alert.message}
                      </span>
                    </Text>
                  </div>
                </RowContainer>
              </RowContainer>
            </Notification>
          </div>
        ) : (
          <></>
        )}
      </NotificationContainer>
    </AnimatedWidgetStep>
  );
};

export const getInfoNotification = (order: OrderDto) => {
  let notifications: Array<{
    title: string;
    message: string;
    type: string;
    icon: string;
  }> = [];

  let currentDate = new Date().getTime();
  let dueDate = new Date(order?.loanRepayment?.nextPayment?.dueDate).getTime();

  if (order?.status === "settled") {
    notifications.push({
      title: "Loan repayment successful",
      message: "Your collateral will be returned back to you.",
      type: "success",
      icon: "SuccessIcon",
    });
  }

  if (order?.status === "canceled") {
    notifications.push({
      title: "Loan canceled",
      message:
        "Unfortunately, your Loan contract was canceled because you did not deposit enough collateral in time. You can apply for a new loan at any time.",
      type: "danger",
      icon: "Notification",
    });
  }

  if (parseFloat(order?.currentLtv) > parseFloat(order?.warningLtv)) {
    notifications.push({
      title: "LTV Warning",
      message:
        "Your collateral value is getting low — add collateral now to maintain a healthy loan. An Abra agent will be in touch to discuss the next steps on a loan call if the value falls below a critical value",
      type: "warning",
      icon: "Notification",
    });
  }
  if (parseFloat(order?.currentLtv) > parseFloat(order?.marginLineLtv)) {
    notifications.push({
      title: "LTV Call",
      message: `Your collateral value is low. You must add funds by ${order?.loanRepayment?.nextPayment?.dueDate} to avoid liquidation of your collateral.`,
      type: "danger",
      icon: "Notification",
    });
  }

  if (
    order?.repaymentMethod === "monthly-repay-interest" &&
    currentDate > dueDate
  ) {
    notifications.push({
      title: "Interest payment overdue",
      message: `Your interest payment of ${order?.loanRepayment?.nextPayment?.interestAmountExpected} ${order?.currency} is overdue. Please make the payment soon to avoid liquidation of your collateral.`,
      type: "danger",
      icon: "Notification",
    });
  }
  let sevenDayless = dueDate - 7 * 24 * 60 * 60 * 1000;
  if (
    order?.repaymentMethod === "monthly-repay-interest" &&
    currentDate === sevenDayless
  ) {
    notifications.push({
      title: "Principal repayment overdue",
      message: `Your principal repayment is overdue. Please transfer ${order?.initialPrincipal} ${order?.currency} from your trading Abra account immediately or we will liquidate your collateral to repay the principal.`,
      type: "danger",
      icon: "Notification",
    });
  }

  if (
    order?.repaymentMethod !== "monthly-repay-interest" &&
    currentDate > dueDate
  ) {
    notifications.push({
      title: "Principal repayment overdue",
      message: `Your principal repayment is overdue. Please transfer ${order?.initialPrincipal} ${order?.currency} from your trading Abra account immediately or we will liquidate your collateral to repay the principal.`,
      type: "danger",
      icon: "Notification",
    });
  }

  if (currentDate > dueDate) {
    notifications.push({
      title: "LTV Call",
      message: `Your interest payment of ${order?.loanRepayment?.nextPayment?.interestAmountExpected} ${order?.currency} is due on ${order?.loanRepayment?.nextPayment?.dueDate}. Please make the payment soon to avoid liquidation of your collateral.`,
      type: "danger",
      icon: "Notification",
    });
  }

  return notifications.length > 0
    ? notifications.filter((m) => m.type === "danger")
      ? notifications.find((m) => m.type === "danger")
      : notifications.find((m) => m.type === "warning")
    : null;
};
