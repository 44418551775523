import { useEffect } from "react";
import { PrefetchOptions } from "@reduxjs/toolkit/dist/query/core/module";
import { api } from "state/store/api";
import { useAppDispatch } from "state/store";

export type EndpointNames = keyof typeof api.endpoints;

export function usePrefetchImmediately<T extends EndpointNames>(
  endpoint: T,
  arg: Parameters<typeof api.endpoints[T]["initiate"]>[0],
  options: PrefetchOptions = {}
) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(api.util.prefetch(endpoint, arg as any, options));
  }, []);
}
