import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const CopyCryptoAddress: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = "#A399F6",
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M22 3C22 1.346 20.654 0 19 0H9C7.346 0 6 1.346 6 3V17C6 18.654 7.346 20 9 20H19C20.654 20 22 18.654 22 17V3ZM20 17C20 17.552 19.552 18 19 18H9C8.448 18 8 17.552 8 17V3C8 2.448 8.448 2 9 2H19C19.552 2 20 2.448 20 3V17Z"
      fill={color}
    />
    <path
      d="M2 21C2 22.654 3.346 24 5 24H18C18.552 24 19 23.553 19 23C19 22.447 18.552 22 18 22H5C4.448 22 4 21.552 4 21V5C4 4.447 3.552 4 3 4C2.448 4 2 4.447 2 5V21Z"
      fill={color}
    />
  </svg>
);

export default CopyCryptoAddress;
