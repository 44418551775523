import React, { FC, useEffect, useState } from "react";
import Layout from "components/templates/Layout";
import alternativeGridSystem from "lib/theme/alternativeGridSystem";
import { GridItemSetting } from "components/templates/Widget/interfaces";
import DepositBalance from "./DepositBalance";
import Transactions from "./Transactions";
import PaymentSchedule from "./PaymentSchedule";
import Attachments from "./Attachments";
import { useNavigate, useParams } from "react-router-dom";
import { PagesUrl } from "lib/constants/config.constant";
import { OrderDto, useGetOrderDetailsQuery } from "state/store/api";
import InterestMonthlyWidget from "./InterestMonthlyWidget";
import CompoundWidget from "./CompoundWidget";

export interface Sizes {
  xs?: GridItemSetting;
  sm?: GridItemSetting;
  md?: GridItemSetting;
  lg?: GridItemSetting;
  xl?: GridItemSetting;
  xxl?: GridItemSetting;
}

const DepositDetails: FC = () => {
  const { depositId } = useParams();
  const [deposit, setDeposit] = useState<OrderDto>();

  const navigate = useNavigate();

  if (!depositId) navigate(PagesUrl.BOOST);

  const { data, isLoading, isFetching, isError } = useGetOrderDetailsQuery({
    orderId: parseInt(depositId ?? "-1"),
  });

  useEffect(() => {
    if (data && !isLoading && !isFetching && !isError) setDeposit(data.order);
  }, [data, isLoading, isFetching]);

  return (
    <Layout customGrid={alternativeGridSystem}>
      <DepositBalance
        deposit={deposit}
        isLoading={isLoading}
        isFetching={isFetching}
      />
      <Transactions
        deposit={deposit}
        isLoading={isLoading}
        isFetching={isFetching}
      />
      {deposit?.repaymentMethod === "monthly-repay-interest" && <InterestMonthlyWidget orderId={depositId} />}
      {deposit?.repaymentMethod === "period-compounding" && <CompoundWidget orderId={depositId} />}
      <Attachments
        deposit={deposit}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </Layout>
  );
};

export default DepositDetails;
