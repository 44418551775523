import { combineReducers } from 'redux';
import marketReducer from './market.reducer';
import {
  authSlice,
  authReducer,
  kycSlice,
  kycReducer,
  priceAlertsSlice,
  priceAlertsReducer,
  assetsReducer,
  assetsSlice,
  transactionsSlice,
  transactionsReducer,
  allCryptosSlice,
  allCryptosReducer,
  rewardsSlice,
  rewardsReducer,
  tradeWidgetSlice,
  tradeWidgetReducer,
  assetDetailsSlice,
  assetDetailsReducer,
  sendMoneySlice,
  sendMoneyReducer,
  bankSlice,
  earnSlice,
  earnReducer,
  transferSlice,
  transferReducer,
  earnAssetDetailSlice,
  earnAssetDetailReducer,
  primeTrustSlice,
  primeTrustReducer,
  notificationsSlice,
  notificationsReducer,
  personalInformation,
  creditCardApply,
  creditCardInformation,
  marketPlaceReducer,
  marketPlaceSlice,
} from '../slice';
import { bankReducer } from 'state/slice/bank.slice';
import { api } from 'state/store/api';
import { pricesSlice } from 'state/slice/prices.slice';
import { permissionsSlice } from 'state/slice/permissions.slice';
import { networksSlice } from 'state/slice/networksReducer';
import { withdrawSlice } from 'state/slice/withdrawReducer';
import { alertSlice } from 'state/slice/alertReducer';

const reducers = combineReducers({
  market: marketReducer,
  [authSlice.name]: authReducer,
  [kycSlice.name]: kycReducer,
  [priceAlertsSlice.name]: priceAlertsReducer,
  [assetsSlice.name]: assetsReducer,
  [transactionsSlice.name]: transactionsReducer,
  [allCryptosSlice.name]: allCryptosReducer,
  [rewardsSlice.name]: rewardsReducer,
  [tradeWidgetSlice.name]: tradeWidgetReducer,
  [assetDetailsSlice.name]: assetDetailsReducer,
  [sendMoneySlice.name]: sendMoneyReducer,
  [bankSlice.name]: bankReducer,
  [earnSlice.name]: earnReducer,
  [transferSlice.name]: transferReducer,
  [earnAssetDetailSlice.name]: earnAssetDetailReducer,
  [primeTrustSlice.name]: primeTrustReducer,
  [notificationsSlice.name]: notificationsReducer,
  [personalInformation.name]: personalInformation.reducer,
  [creditCardApply.name]: creditCardApply.reducer,
  [creditCardInformation.name]: creditCardInformation.reducer,
  [marketPlaceSlice.name]: marketPlaceReducer,
  [api.reducerPath]: api.reducer,
  prices: pricesSlice.reducer,
  permissions: permissionsSlice.reducer,
  networks: networksSlice.reducer,
  withdraws: withdrawSlice.reducer,
  alerts: alertSlice.reducer,
});

export default reducers;
