/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApiError } from '../models';
import { InterestBearingWithdrawalRequest } from '../models';
import { InterestBreakdownResponse } from '../models';
import { ResponseBase } from '../models';
import { SendFundsRequest } from '../models';
import { SendFundsSubmitRequest } from '../models';
import { TransactionCryptoWithdrawRequest } from '../models';
import { TransactionCryptoWithdrawSubmitRequest } from '../models';
import { TransactionRateRequest } from '../models';
import { TransactionSubmitRequest } from '../models';
import { TransactionTemplateRequest } from '../models';
import { TransactionTemplateResponse } from '../models';
import { TransactionsEmailRequest } from '../models';
import { TransferDepositProcessRequest } from '../models';
import { TransferRequest } from '../models';
import { TransferTemplateResponse } from '../models';
/**
 * TransactionsControllerApi - axios parameter creator
 * @export
 */
export const TransactionsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Request the crypto withdraw transaction template.
         * @param {TransactionCryptoWithdrawRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoWithdraw: async (body: TransactionCryptoWithdrawRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cryptoWithdraw.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling cryptoWithdraw.');
            }
            const localVarPath = `/transactions/crypto-withdraw-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve a transaction interest breakdown
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestBreakdown: async (token: string, transactionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getInterestBreakdown.');
            }
            // verify required parameter 'transactionId' is not null or undefined
            if (transactionId === null || transactionId === undefined) {
                throw new RequiredError('transactionId','Required parameter transactionId was null or undefined when calling getInterestBreakdown.');
            }
            const localVarPath = `/transactions/{transactionId}/interest-breakdown`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request the transaction rates between the assets provided.
         * @param {TransactionRateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionRate: async (body: TransactionRateRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getTransactionRate.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getTransactionRate.');
            }
            const localVarPath = `/transactions/rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request the transaction template to trade assets initiate in /rate call.
         * @param {TransactionTemplateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionTemplate: async (body: TransactionTemplateRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling getTransactionTemplate.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getTransactionTemplate.');
            }
            const localVarPath = `/transactions/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves the list of transactions completed or scheduled for the user.
         * @param {string} token 
         * @param {string} type 
         * @param {string} [transactionType] 
         * @param {string} [asset] 
         * @param {string} [sortField] 
         * @param {string} [sortDirection] 
         * @param {string} [page] 
         * @param {string} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactions: async (token: string, type: string, transactionType?: string, asset?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getTransactions.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling getTransactions.');
            }
            const localVarPath = `/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (transactionType !== undefined) {
                localVarQueryParameter['transactionType'] = transactionType;
            }

            if (asset !== undefined) {
                localVarQueryParameter['asset'] = asset;
            }

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Withdrawal funds to asset.
         * @param {InterestBearingWithdrawalRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestBearingAssetWithdrawal: async (body: InterestBearingWithdrawalRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling interestBearingAssetWithdrawal.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling interestBearingAssetWithdrawal.');
            }
            const localVarPath = `/transactions/interest-bearing-asset/withdrawal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submits a crypto withdraw transaction to be processed.
         * @param {TransactionCryptoWithdrawSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        process: async (body: TransactionCryptoWithdrawSubmitRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling process.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling process.');
            }
            const localVarPath = `/transactions/crypto-withdraw-submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer funds to earning account.
         * @param {TransferDepositProcessRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processDeposit: async (body: TransferDepositProcessRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling processDeposit.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling processDeposit.');
            }
            const localVarPath = `/transactions/interest-bearing-asset/deposit/process`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiate transaction to send funds to another Abra user
         * @param {SendFundsRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFunds: async (body: SendFundsRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendFunds.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling sendFunds.');
            }
            const localVarPath = `/transactions/send-funds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit the transaction to send funds to another Abra user
         * @param {SendFundsSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendFundsSubmit: async (body: SendFundsSubmitRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendFundsSubmit.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling sendFundsSubmit.');
            }
            const localVarPath = `/transactions/send-funds/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request the list of user's transactions of given year to be sent to given email address.
         * @param {TransactionsEmailRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendTransactionsEmail: async (body: TransactionsEmailRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling sendTransactionsEmail.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling sendTransactionsEmail.');
            }
            const localVarPath = `/transactions/email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submits a transaction to be processed
         * @param {TransactionSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTransaction: async (body: TransactionSubmitRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling submitTransaction.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling submitTransaction.');
            }
            const localVarPath = `/transactions/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer funds to earning account.
         * @param {TransferRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transactionDepositTemplate: async (body: TransferRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling transactionDepositTemplate.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling transactionDepositTemplate.');
            }
            const localVarPath = `/transactions/interest-bearing-asset/deposit/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionsControllerApi - functional programming interface
 * @export
 */
export const TransactionsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Request the crypto withdraw transaction template.
         * @param {TransactionCryptoWithdrawRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoWithdraw(body: TransactionCryptoWithdrawRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TransactionTemplateResponse>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).cryptoWithdraw(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieve a transaction interest breakdown
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterestBreakdown(token: string, transactionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<InterestBreakdownResponse>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).getInterestBreakdown(token, transactionId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Request the transaction rates between the assets provided.
         * @param {TransactionRateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionRate(body: TransactionRateRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TransactionTemplateResponse>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).getTransactionRate(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Request the transaction template to trade assets initiate in /rate call.
         * @param {TransactionTemplateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionTemplate(body: TransactionTemplateRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TransactionTemplateResponse>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).getTransactionTemplate(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retrieves the list of transactions completed or scheduled for the user.
         * @param {string} token 
         * @param {string} type 
         * @param {string} [transactionType] 
         * @param {string} [asset] 
         * @param {string} [sortField] 
         * @param {string} [sortDirection] 
         * @param {string} [page] 
         * @param {string} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactions(token: string, type: string, transactionType?: string, asset?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).getTransactions(token, type, transactionType, asset, sortField, sortDirection, page, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Withdrawal funds to asset.
         * @param {InterestBearingWithdrawalRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interestBearingAssetWithdrawal(body: InterestBearingWithdrawalRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).interestBearingAssetWithdrawal(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Submits a crypto withdraw transaction to be processed.
         * @param {TransactionCryptoWithdrawSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async process(body: TransactionCryptoWithdrawSubmitRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).process(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Transfer funds to earning account.
         * @param {TransferDepositProcessRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processDeposit(body: TransferDepositProcessRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).processDeposit(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Initiate transaction to send funds to another Abra user
         * @param {SendFundsRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendFunds(body: SendFundsRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TransactionTemplateResponse>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).sendFunds(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Submit the transaction to send funds to another Abra user
         * @param {SendFundsSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendFundsSubmit(body: SendFundsSubmitRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).sendFundsSubmit(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Request the list of user's transactions of given year to be sent to given email address.
         * @param {TransactionsEmailRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTransactionsEmail(body: TransactionsEmailRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).sendTransactionsEmail(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Submits a transaction to be processed
         * @param {TransactionSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitTransaction(body: TransactionSubmitRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<ResponseBase>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).submitTransaction(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Transfer funds to earning account.
         * @param {TransferRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionDepositTemplate(body: TransferRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<TransferTemplateResponse>>> {
            const localVarAxiosArgs = await TransactionsControllerApiAxiosParamCreator(configuration).transactionDepositTemplate(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TransactionsControllerApi - factory interface
 * @export
 */
export const TransactionsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Request the crypto withdraw transaction template.
         * @param {TransactionCryptoWithdrawRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoWithdraw(body: TransactionCryptoWithdrawRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TransactionTemplateResponse>> {
            return TransactionsControllerApiFp(configuration).cryptoWithdraw(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve a transaction interest breakdown
         * @param {string} token 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterestBreakdown(token: string, transactionId: string, options?: AxiosRequestConfig): Promise<AxiosResponse<InterestBreakdownResponse>> {
            return TransactionsControllerApiFp(configuration).getInterestBreakdown(token, transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request the transaction rates between the assets provided.
         * @param {TransactionRateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionRate(body: TransactionRateRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TransactionTemplateResponse>> {
            return TransactionsControllerApiFp(configuration).getTransactionRate(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request the transaction template to trade assets initiate in /rate call.
         * @param {TransactionTemplateRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionTemplate(body: TransactionTemplateRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TransactionTemplateResponse>> {
            return TransactionsControllerApiFp(configuration).getTransactionTemplate(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves the list of transactions completed or scheduled for the user.
         * @param {string} token 
         * @param {string} type 
         * @param {string} [transactionType] 
         * @param {string} [asset] 
         * @param {string} [sortField] 
         * @param {string} [sortDirection] 
         * @param {string} [page] 
         * @param {string} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactions(token: string, type: string, transactionType?: string, asset?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return TransactionsControllerApiFp(configuration).getTransactions(token, type, transactionType, asset, sortField, sortDirection, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Withdrawal funds to asset.
         * @param {InterestBearingWithdrawalRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interestBearingAssetWithdrawal(body: InterestBearingWithdrawalRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return TransactionsControllerApiFp(configuration).interestBearingAssetWithdrawal(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submits a crypto withdraw transaction to be processed.
         * @param {TransactionCryptoWithdrawSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async process(body: TransactionCryptoWithdrawSubmitRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return TransactionsControllerApiFp(configuration).process(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer funds to earning account.
         * @param {TransferDepositProcessRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processDeposit(body: TransferDepositProcessRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return TransactionsControllerApiFp(configuration).processDeposit(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiate transaction to send funds to another Abra user
         * @param {SendFundsRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendFunds(body: SendFundsRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TransactionTemplateResponse>> {
            return TransactionsControllerApiFp(configuration).sendFunds(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit the transaction to send funds to another Abra user
         * @param {SendFundsSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendFundsSubmit(body: SendFundsSubmitRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return TransactionsControllerApiFp(configuration).sendFundsSubmit(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request the list of user's transactions of given year to be sent to given email address.
         * @param {TransactionsEmailRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendTransactionsEmail(body: TransactionsEmailRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return TransactionsControllerApiFp(configuration).sendTransactionsEmail(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submits a transaction to be processed
         * @param {TransactionSubmitRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitTransaction(body: TransactionSubmitRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseBase>> {
            return TransactionsControllerApiFp(configuration).submitTransaction(body, token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer funds to earning account.
         * @param {TransferRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transactionDepositTemplate(body: TransferRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<TransferTemplateResponse>> {
            return TransactionsControllerApiFp(configuration).transactionDepositTemplate(body, token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionsControllerApi - object-oriented interface
 * @export
 * @class TransactionsControllerApi
 * @extends {BaseAPI}
 */
export class TransactionsControllerApi extends BaseAPI {
    /**
     * 
     * @summary Request the crypto withdraw transaction template.
     * @param {TransactionCryptoWithdrawRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async cryptoWithdraw(body: TransactionCryptoWithdrawRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TransactionTemplateResponse>> {
        return TransactionsControllerApiFp(this.configuration).cryptoWithdraw(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Retrieve a transaction interest breakdown
     * @param {string} token 
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async getInterestBreakdown(token: string, transactionId: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<InterestBreakdownResponse>> {
        return TransactionsControllerApiFp(this.configuration).getInterestBreakdown(token, transactionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Request the transaction rates between the assets provided.
     * @param {TransactionRateRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async getTransactionRate(body: TransactionRateRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TransactionTemplateResponse>> {
        return TransactionsControllerApiFp(this.configuration).getTransactionRate(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Request the transaction template to trade assets initiate in /rate call.
     * @param {TransactionTemplateRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async getTransactionTemplate(body: TransactionTemplateRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TransactionTemplateResponse>> {
        return TransactionsControllerApiFp(this.configuration).getTransactionTemplate(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Retrieves the list of transactions completed or scheduled for the user.
     * @param {string} token 
     * @param {string} type 
     * @param {string} [transactionType] 
     * @param {string} [asset] 
     * @param {string} [sortField] 
     * @param {string} [sortDirection] 
     * @param {string} [page] 
     * @param {string} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async getTransactions(token: string, type: string, transactionType?: string, asset?: string, sortField?: string, sortDirection?: string, page?: string, size?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return TransactionsControllerApiFp(this.configuration).getTransactions(token, type, transactionType, asset, sortField, sortDirection, page, size, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Withdrawal funds to asset.
     * @param {InterestBearingWithdrawalRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async interestBearingAssetWithdrawal(body: InterestBearingWithdrawalRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return TransactionsControllerApiFp(this.configuration).interestBearingAssetWithdrawal(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Submits a crypto withdraw transaction to be processed.
     * @param {TransactionCryptoWithdrawSubmitRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async process(body: TransactionCryptoWithdrawSubmitRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return TransactionsControllerApiFp(this.configuration).process(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Transfer funds to earning account.
     * @param {TransferDepositProcessRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async processDeposit(body: TransferDepositProcessRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return TransactionsControllerApiFp(this.configuration).processDeposit(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Initiate transaction to send funds to another Abra user
     * @param {SendFundsRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async sendFunds(body: SendFundsRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TransactionTemplateResponse>> {
        return TransactionsControllerApiFp(this.configuration).sendFunds(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Submit the transaction to send funds to another Abra user
     * @param {SendFundsSubmitRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async sendFundsSubmit(body: SendFundsSubmitRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return TransactionsControllerApiFp(this.configuration).sendFundsSubmit(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Request the list of user's transactions of given year to be sent to given email address.
     * @param {TransactionsEmailRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async sendTransactionsEmail(body: TransactionsEmailRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return TransactionsControllerApiFp(this.configuration).sendTransactionsEmail(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Submits a transaction to be processed
     * @param {TransactionSubmitRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async submitTransaction(body: TransactionSubmitRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<ResponseBase>> {
        return TransactionsControllerApiFp(this.configuration).submitTransaction(body, token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Transfer funds to earning account.
     * @param {TransferRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionsControllerApi
     */
    public async transactionDepositTemplate(body: TransferRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<TransferTemplateResponse>> {
        return TransactionsControllerApiFp(this.configuration).transactionDepositTemplate(body, token, options).then((request) => request(this.axios, this.basePath));
    }
}
