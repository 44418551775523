import Icon from 'components/atoms/Icon';
import { Heading } from 'components/atoms/Typography';
import Sidebar from 'components/molecules/Sidebar';
import Layout from 'components/templates/Layout';
import Widget from 'components/templates/Widget';
import { palette } from 'lib/theme';
import React, { useEffect, useState, VFC } from 'react';
import { useParams } from 'react-router-dom';
import { Transaction } from 'services/models';
import { getText } from 'shared/locale-helper';
import styled from 'styled-components';
import Completed from './Completed';
import ExportAction from './ExportAction';
import SidebarTransactionsDetails from './SidebarTransactionsDetails';

const TransactionScreen: VFC = () => {
  const [isCompleateTxrVisible, setIsCompleateTxrVisible] = useState({
    data: {},
    status: false,
  });

  const handleOnClickCompleteTxt = (txr: Transaction) => {
    setIsCompleateTxrVisible({
      data: txr,
      status: true,
    });
  };

  return (
    <Layout>
      <Widget
        xxl={{ area: '1 / 1 / 1 / -1', columns: '1 / -1' }}
        xl={{ area: '1 / 1 / 1 / -1', columns: '1 / -1' }}
        lg={{ area: '1 / 1 / 1 / -1', columns: '1 / -1' }}
        md={{ area: '1 / 1 / 1 / -1', columns: '1 / -1' }}
        sm={{ area: '1 / 1 / 1 / -1', columns: '1 / -1' }}
        xs={{ area: '1 / 1 / 1 / -1', columns: '1 / -1' }}
      >
        <StyledSettingsBar>
          <Heading variant='h2'>{getText('transactions')}</Heading>
          <ExportAction reportType='deposit'>
            <Icon name='Export' size={24} />
          </ExportAction>
        </StyledSettingsBar>
        <StyledTabsContainer>
          <Completed onClick={handleOnClickCompleteTxt} />
        </StyledTabsContainer>
      </Widget>
      <Sidebar
        title={isCompleateTxrVisible?.data?.label}
        show={isCompleateTxrVisible?.status}
        onClose={() =>
          setIsCompleateTxrVisible((_completed) => ({
            ..._completed,
            status: false,
          }))
        }
      >
        <SidebarTransactionsDetails
          data={isCompleateTxrVisible?.data}
          onClick={() =>
            setIsCompleateTxrVisible((_completed) => ({
              ..._completed,
              status: false,
            }))
          }
        />
      </Sidebar>
    </Layout>
  );
};

const StyledSettingsBar = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: space-between;
  align-items: baseline;
  & > button {
    float: right;
  }
`;
const StyledTabsContainer = styled.div`
  flex-grow: 1;
  margin: 0 -24px;
`;

export default TransactionScreen;
