import React, { FC } from "react";
import NumberFormatter from "./NumberFormatter";

const Percent: FC<{
  value: any;
  signDisplay?: "auto" | "exceptZero";
  maximumFractionDigits?: number;
}> = ({ value, signDisplay = "auto", maximumFractionDigits = 2 }) => {
  return (
    <NumberFormatter
      value={value}
      stylenumber="percent"
      signDisplay={signDisplay}
      maximumFractionDigits={maximumFractionDigits}
    />
  );
};

export default Percent;
