import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const ToTheMoon: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0001 4.64578C17.4039 4.65098 12.9974 6.47974 9.74732 9.73086C6.49728 12.982 4.66913 17.39 4.66394 21.9877C4.66783 26.5864 6.49541 30.9956 9.74559 34.2478C12.9958 37.5 17.403 39.3294 22.0001 39.3346C26.5972 39.3294 31.0044 37.5 34.2546 34.2478C37.5048 30.9956 39.3324 26.5864 39.3363 21.9877C39.3311 17.39 37.5029 12.982 34.2529 9.73086C31.0028 6.47974 26.5963 4.65098 22.0001 4.64578Z"
      fill="#F47A63"
    />
    <path
      d="M22.8239 2.99253C26.5537 3.1559 30.153 4.41325 33.1737 6.60799C36.1944 8.80273 38.5029 11.8379 39.8118 15.3355L42.6611 14.4083C41.1556 10.3096 38.4657 6.75122 34.9334 4.18564C31.4011 1.62005 27.186 0.163097 22.8239 0V2.99253Z"
      fill="#FFA519"
    />
    <path
      d="M40.3169 16.9004C40.7749 18.5574 41.0074 20.2686 41.0084 21.9877C41.0112 24.8497 40.3668 27.6752 39.1235 30.2528C37.8801 32.8303 36.0701 35.0932 33.8287 36.8719L35.5844 39.2953C38.2086 37.2388 40.3302 34.6115 41.7883 31.6128C43.2464 28.614 44.0027 25.3224 43.9999 21.9877C44.0008 19.9553 43.7186 17.9326 43.1613 15.9781L40.3169 16.9004Z"
      fill="#FFA519"
    />
    <path
      d="M4.18806 15.3355C5.49694 11.8379 7.80547 8.80273 10.8261 6.60799C13.8468 4.41325 17.4462 3.1559 21.176 2.99253V0C16.8139 0.163097 12.5987 1.62005 9.06643 4.18564C5.53414 6.75122 2.84426 10.3096 1.33875 14.4083L4.18806 15.3355Z"
      fill="#FFA519"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 39.3346C22.0001 39.3346 22.0001 39.3346 22.0002 39.3346C26.5973 39.3294 31.0046 37.5 34.2547 34.2478C37.5049 30.9956 39.3325 26.5864 39.3364 21.9877C39.3312 17.39 37.503 12.982 34.253 9.73086C31.003 6.47974 26.5965 4.65098 22.0002 4.64578C22.0001 4.64578 22.0001 4.64578 22 4.64578V39.3346Z"
      fill="#DC6E59"
    />
    <path
      d="M32.5193 37.8432C29.4094 39.9095 25.7577 41.0088 22.0244 41.0025C18.291 41.0088 14.6393 39.9095 11.5295 37.8432L9.76892 40.2667C13.3921 42.7064 17.6618 44.0065 22.0293 44C26.3968 44.0065 30.6665 42.7064 34.2897 40.2667L32.5193 37.8432Z"
      fill="#FFA519"
    />
    <path
      d="M10.1712 36.8719C7.92988 35.0932 6.11981 32.8303 4.87647 30.2528C3.63313 27.6752 2.98873 24.8497 2.99153 21.9877C2.9925 20.2686 3.22509 18.5574 3.68302 16.9004L0.838612 15.9781C0.281345 17.9326 -0.000901447 19.9553 7.65766e-06 21.9877C-0.0027763 25.3224 0.753559 28.614 2.21166 31.6128C3.66977 34.6115 5.79135 37.2388 8.41552 39.2953L10.1712 36.8719Z"
      fill="#FFA519"
    />
    <path d="M22.25 14.0992H21.2692V27.021H22.25V14.0992Z" fill="white" />
    <path
      d="M29.6407 14.0992H21.6519V18.9069H29.6407L25.4771 16.503L29.6407 14.0992Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.1867 29.2422C32.9136 27.1376 27.8803 25.7897 22.2354 25.7897C16.3855 25.7897 11.1925 27.2373 7.93457 29.4745C8.66908 30.8539 9.60861 32.1301 10.7335 33.2555C13.7216 36.245 17.7736 37.9267 22 37.9315C26.2265 37.9267 30.2784 36.245 33.2665 33.2555C34.4543 32.0672 35.4354 30.7108 36.1867 29.2422Z"
      fill="white"
    />
  </svg>
);

export default ToTheMoon;
