import { Info } from 'assets/icons';
import TransactionSidebar from 'pages/TransactionScreen/TransactionSidebar';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAssets from 'shared/useAssets'
import usePermissions from 'shared/usePermissions';
import { alertSelector, toggleAlert, turnAlertOff } from 'state/slice/alertReducer';
import { useGetOngoingWithdrawRequestsQuery, WithdrawRequest } from 'state/store/withdrawApi';
import styled from 'styled-components';

const OngoingWithdrawAlert = () => {
  const [ongoing, setOngoing] = useState<WithdrawRequest[]>([]);
  const alertState = useSelector(alertSelector);
  const dispatch = useDispatch();
  const permissions = usePermissions();
  const cantCallOngoingWithdraw = !permissions.isOwner;
  const getOngoingWithdrawRequests = useGetOngoingWithdrawRequestsQuery(null, { skip: cantCallOngoingWithdraw, pollingInterval: 60000 });
  const [transactionId, setTransactionId] = useState(0);
  const [showTransactionSidebar, setShowTransactionSidebar] = useState(false);
  const assets = useAssets()
  const select = (id: number) => {
    setShowTransactionSidebar(true);
    setTransactionId(id);
  };
  useEffect(() => {
    const data = getOngoingWithdrawRequests.currentData;
    if (data) {
      setOngoing(data.withdrawRequests);
    }
  }, [getOngoingWithdrawRequests]);

  let timer: NodeJS.Timeout;

  const onMouseLeave = () => {
    if(timer) clearTimeout(timer)
    timer = setTimeout(() => {
      dispatch(turnAlertOff())
    }, 5000)
  }

  const onMouseEnter = () => {
    if(timer) clearTimeout(timer)
  }

  useEffect(() => {
    return () => timer && clearTimeout(timer)
  }, [])

  const isBoost = (withdrawRequest: WithdrawRequest) => {
    return !!withdrawRequest.orderId
  }
  
  const ref = useRef<HTMLDivElement>(null)
  
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as any)) {
        clearTimeout(timer)
        dispatch(turnAlertOff())
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <Container ref={ref}>
      {!!ongoing.length && (
        <>
          <PendingBox onClick={() => dispatch(toggleAlert())} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <InfoBox>
              <Info size={20} />
            </InfoBox>
            <PendingText>
              Pending <br></br>Transactions
            </PendingText>
          </PendingBox>
          {alertState.showWithdrawAlert && (
            <OngoingList onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <TitleProcessing>Withdrawals in Progress</TitleProcessing>
              <SmallParagraph>Once the transactions have been submitted to the network, you will be notified.</SmallParagraph>
              {ongoing.map((withdraw) => (
                <WithdrawBox key={withdraw.id}>
                  <div>
                    <strong>Boost TxID:</strong> #{withdraw.orderId}
                  </div>
                  <div>
                    <strong>Amount:</strong> {assets.getPriceFormattedI(withdraw.currency, withdraw.amount)}
                  </div>
                  {withdraw.transactionId && (
                    <div>
                      <strong>Transaction ID:</strong>{' '}
                      <SelectableTransaction onClick={() => select(withdraw.transactionId ?? 0)}>#{withdraw.transactionId}</SelectableTransaction>
                    </div>
                  )}
                </WithdrawBox>
              ))}
            </OngoingList>
          )}
        </>
      )}
      <TransactionSidebar transactionId={transactionId} setTransactionId={setTransactionId} show={showTransactionSidebar} setShow={setShowTransactionSidebar} />
    </Container>
  );
};

const SelectableTransaction = styled.span`
  color: #9f7fff;
  cursor: pointer;
  font-weight: bold;
  border-bottom: 1px solid #ffffff55;
`;
const SmallParagraph = styled.p`
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.9);
`;
const Container = styled.div`
  position: relative;
  height: 22px;
`;
const PendingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  gap: 1px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: -100% 0, 0 0;
  border: 1px solid #e9992855;
  background-image: linear-gradient(45deg, transparent 20%, transparent 40%, #e9992855 50%, #e9992877 55%, transparent 70%, transparent 100%);
  background-size: 250% 250%, 100% 100%;
  animation: change-color 4s infinite;
  padding: 0px 3px;
  @keyframes change-color {
    0% {
      background-position: 200%0, 0 0;
    }
    50% {
      background-position: -100% 0, 0 0;
    }
  }
`;

const TitleProcessing = styled.p`
  font-size: 0.9rem;
  opacity: 0.8;
  font-weight: bold;
`;
const WithdrawBox = styled.div`
  display: flex;
  z-index: 999;
  gap: 1vw;
  font-size: 0.7rem;
  flex-direction: row;
  margin-bottom: 0.5vh;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: max-content;
  max-width: 100%;
  padding: 0.5vh 0.7vw;
  font-family: 'IBM Plex Sans';
  font-weight: 300;
`;
const OngoingList = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 1vh 1vw;
  background-color: rgba(163, 153, 246, 0.2);
  color: rgba(255, 255, 255, 0.9);
  gap: 1vh;
  top: 36px;
  right: 0;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(50px);
`;
const PendingText = styled.span`
  color: #e99928;
  font-size: 0.6rem;
  text-align: center;
  font-family: 'IBM Plex Sans';
  font-weight: 300;
`;
const InfoBox = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export default OngoingWithdrawAlert;
