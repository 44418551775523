import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const CrossCircle: FC<ISVG> = ({
  size = 100,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}>
    <path
      d="M50 98C76.5097 98 98 76.5097 98 50C98 23.4903 76.5097 2 50 2C23.4903 2 2 23.4903 2 50C2 76.5097 23.4903 98 50 98Z"
      stroke="#EC396B"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31 31L68.5 68.5"
      stroke="#EC396B"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31 68.5L68.5 31"
      stroke="#EC396B"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CrossCircle;
