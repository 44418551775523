import Typography from "../../@types/theme/typography";

const typography: Typography = {
  fontFamily: "IBM Plex Sans, sans-serif",
  fontWeight: 300,
  fontSize: 16,
  lineHeight: "145%",
  fontSizes: {
    extraLarge: "28px",
    xl: "24px",
    large: "18px",
    medium: "16px",
    small: "14px",
    tiny: "12px",
    nano: "7px",
  },
  lineHeights: {
    small: "125%",
    large: "130%",
    tiny: "125%",
  },
  h1: {
    fontSize: "48px",
    fontWeight: "normal",
    letterSpacing: "-0.025rem",
    lineHeight: "120%",
    fontStyle: "normal",
    fontFeatureSettings: "'ss02' on, 'liga' off",
  },
  h2: {
    fontSize: "28px",
    fontWeight: "normal",
    letterSpacing: "-0.025rem",
    lineHeight: "120%",
    fontStyle: "normal",
    fontFeatureSettings: "'ss02' on, 'liga' off",
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: "500",
    letterSpacing: "-0.025rem",
    lineHeight: "120%",
    fontStyle: "normal",
    fontFeatureSettings: "'ss02' on, 'liga' off",
  },
  h4: {
    fontSize: "20px",
    fontWeight: "400",
    letterSpacing: "0.005rem",
    lineHeight: "120%",
    fontStyle: "normal",
    fontFeatureSettings: "'ss02' on, 'liga' off",
  },
  h5: {
    fontSize: "19px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: "0.5px",
  },
  p: {
    fontSize: "16px",
    fontWeight: "normal",
    letterSpacing: "-0.5px",
    lineHeight: "145%",
    fontStyle: "normal",
    fontFeatureSettings: "'ss02' on, 'liga' off",
  },
} as const;

export default typography;
