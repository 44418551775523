import Breakpoints from '@types/theme/breakpoints';
import { BreakpointsEnum } from "@types/enums/GridSystem";

const breakpoints: Breakpoints = {
  keys: [BreakpointsEnum.ExtraSmall, BreakpointsEnum.Small, BreakpointsEnum.Medium, BreakpointsEnum.Large, BreakpointsEnum.ExtraLarge, BreakpointsEnum.ExtraExtraLarge],
  measurement: 'px',
  values: {
    [BreakpointsEnum.ExtraSmall]: 0,
    [BreakpointsEnum.Small]: 768,
    [BreakpointsEnum.Medium]: 1079,
    [BreakpointsEnum.Large]: 1279,
    [BreakpointsEnum.ExtraLarge]: 1439,
    [BreakpointsEnum.ExtraExtraLarge]: 1920,
  },
};

export default breakpoints;