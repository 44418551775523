import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const DownArrowWide: FC<ISVG> = ({ size = 24, width = size, height = size, color, svgProps }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M-0.000616117 1.0504C-0.000616109 0.852396 0.0583839 0.653405 0.180384 0.478405C0.497383 0.0254055 1.12038 -0.0845967 1.57338 0.232403L10.9994 6.83041L20.4264 0.231397C20.8784 -0.0846029 21.5024 0.0243993 21.8194 0.477399C22.1354 0.928399 22.0264 1.5534 21.5734 1.8704L11.5734 8.8704C11.2294 9.1114 10.7714 9.1114 10.4274 8.8704L0.427385 1.8704C0.147385 1.6764 -0.000616131 1.3654 -0.000616117 1.0504Z"
        fill={color}
      />
    </svg>
  );
};
export default DownArrowWide;
