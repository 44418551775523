import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "state/store"
import { LoginResponse, PrimeUser } from "state/store/api"

interface AuthState {
  tokenToValidate: LoginResponse
  token: string | null
  user: PrimeUser | null
}

const initialState = {
  token: localStorage.getItem("prime:token") ? localStorage.getItem("prime:token") : null,
  tokenToValidate: {
    accessToken: localStorage.getItem("prime:tokenToValidate") || null,
  },
  user: null,
} as AuthState

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    saveTokenToValidate: (state, action: PayloadAction<LoginResponse>) => {
      state.tokenToValidate = action.payload

      if (action.payload.accessToken)
        localStorage.setItem(
          "prime:tokenToValidate",
          action.payload.accessToken
        )
    },
    setCredentials: (state, action: PayloadAction<{ accessToken: string }>) => {
      const { accessToken } = action.payload
      state.token = accessToken ?? ""
      localStorage.setItem("prime:token", accessToken)
    },
    logout: (state) => {
      localStorage.clear()
      state.token = null
      state.tokenToValidate.accessToken = undefined
      state.user = null
    },
    setCurrentUser: (state, action: PayloadAction<PrimeUser>) => {
      state.user = action.payload
    },
  },
})

export const { saveTokenToValidate, setCredentials, setCurrentUser, logout } =
  authSlice.actions

export const authReducer = authSlice.reducer

export const selectCurrentUser = createSelector(
  (state: RootState) => state.auth,
  (state: AuthState) => state.user
)

export const selectCurrentToken = createSelector(
  (state: RootState) => state.auth,
  (state: AuthState) => state.token
)

export const selectTokenToValidate = createSelector(
  (state: RootState) => state.auth,
  (state: AuthState) => state.tokenToValidate
)
