import Breakpoints from '@types/theme/breakpoints';
import { BreakpointsTableEnum } from "@types/enums/GridSystem";

const breakpointsTable: Breakpoints = {
  keys: [
    BreakpointsTableEnum['768to768'],
    BreakpointsTableEnum['769to1078'],
    BreakpointsTableEnum['1079to1079'],
    BreakpointsTableEnum['1080to1278'],
    BreakpointsTableEnum['1279to1279'],
    BreakpointsTableEnum['1280to1438'],
    BreakpointsTableEnum['1439to1439'],
    BreakpointsTableEnum['1440to1920'],
  ],
  measurement: 'px',
  values: {
    [BreakpointsTableEnum['768to768']]: 768,
    [BreakpointsTableEnum['769to1078']]: 1078,
    [BreakpointsTableEnum['1079to1079']]: 1079,
    [BreakpointsTableEnum['1080to1278']]: 1278,
    [BreakpointsTableEnum['1279to1279']]: 1279,
    [BreakpointsTableEnum['1280to1438']]: 1438,
    [BreakpointsTableEnum['1439to1439']]: 1439,
    [BreakpointsTableEnum['1440to1920']]: 1920,
  },
};

export default breakpointsTable;