import { Heading } from 'components/atoms/Typography'
import Footer from 'components/molecules/Footer/Footer'
import React from 'react';
import styled from 'styled-components';
import { StyleAuthenticationCarouselContainer, StyleAuthenticationFormContainer, StyledAuthenticationContainer, StyledFormWrapper, StyledHeading } from 'styles/components/authentication'


const KYCFailurePage = () => {
  return (
    <StyledAuthenticationContainer>
      <CarrouselContainer>
        <StyleAuthenticationCarouselContainer />
      </CarrouselContainer>
      <StyleAuthenticationFormContainer>
        <FormContainer>
          <StyledFormWrapper>
            <StyledHeading>
              <Heading variant='h2'>KYC Process Error</Heading>
            </StyledHeading>
            <KYCFailurePageBox>
              <p>Unfortunately, your application encountered an error during the KYC process.</p>
              <p>We will review the submitted information and revert if we need anything.</p>
              <p>Feel free to contact us on <Link href="mailto:support@abra.com">support@abra.com</Link> if you have any questions.</p>
            </KYCFailurePageBox>
          </StyledFormWrapper>
          <Footer />
        </FormContainer>
      </StyleAuthenticationFormContainer>
    </StyledAuthenticationContainer>

  );
};

const Link = styled.a`
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  text-decoration: none;
  color: rgb(163, 153, 246);
  &:visited {
    color: rgb(163,153,246);
  }
  &:link {
    color: rgb(163,153,246);
  }
`
const KYCFailurePageBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  
`

const CarrouselContainer = styled.div`
  width: 50vw;
  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2vh;
`;

export default KYCFailurePage;
