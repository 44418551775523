import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const AppStore: FC<ISVG> = ({
  size = 24,
  width = 160,
  height = 46,
  color,
  svgProps,
}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35.2109 22.7412C35.1762 18.8894 38.3738 17.0155 38.52 16.9283C36.7091 14.2955 33.9022 13.9358 32.9158 13.9071C30.5584 13.6597 28.2716 15.3137 27.0707 15.3137C25.8458 15.3137 23.9966 13.931 22.0034 13.9716C19.4387 14.011 17.0393 15.4918 15.7233 17.7911C13.0075 22.4795 15.033 29.3692 17.6349 33.1589C18.9365 35.0149 20.4574 37.0872 22.4481 37.0143C24.3957 36.9342 25.1231 35.7762 27.4734 35.7762C29.8021 35.7762 30.4852 37.0143 32.5155 36.9677C34.6057 36.9342 35.9216 35.1033 37.1777 33.2306C38.6818 31.1033 39.2858 29.0083 39.3098 28.9008C39.2607 28.884 35.2505 27.3579 35.2109 22.7412Z" fill="white"/>
    <path d="M31.3757 11.4141C32.4232 10.1079 33.1399 8.33075 32.941 6.52734C31.4249 6.59427 29.5288 7.57305 28.437 8.85061C27.471 9.9764 26.6081 11.8216 26.831 13.5569C28.5341 13.6836 30.2827 12.7 31.3757 11.4141Z" fill="white"/>
    <path d="M63.3929 36.7482H60.6711L59.1802 32.0766H53.9978L52.5776 36.7482H49.9277L55.0621 20.8438H58.2333L63.3929 36.7482ZM58.7307 30.1166L57.3824 25.9636C57.2398 25.5394 56.9725 24.5403 56.5782 22.9675H56.5303C56.3733 23.6439 56.1204 24.643 55.7728 25.9636L54.4485 30.1166H58.7307Z" fill="white"/>
    <path d="M76.5968 30.8715C76.5968 32.8219 76.0683 34.3636 75.0112 35.4954C74.0644 36.5028 72.8887 37.006 71.4852 37.006C69.9703 37.006 68.8821 36.4634 68.2193 35.3783H68.1714V41.4195H65.6162V29.0538C65.6162 27.8276 65.5838 26.5692 65.5215 25.2785H67.7687L67.9113 27.0962H67.9592C68.8114 25.7266 70.1046 25.043 71.84 25.043C73.1967 25.043 74.3293 25.5773 75.2353 26.6469C76.1438 27.7177 76.5968 29.1255 76.5968 30.8715ZM73.9937 30.9648C73.9937 29.8485 73.742 28.9283 73.2362 28.2041C72.6837 27.4488 71.9419 27.0711 71.0118 27.0711C70.3814 27.0711 69.8085 27.2815 69.2968 27.6962C68.7838 28.1144 68.4482 28.6606 68.2912 29.337C68.2121 29.6525 68.1726 29.9107 68.1726 30.1138V32.026C68.1726 32.8602 68.429 33.5641 68.942 34.1389C69.455 34.7138 70.1213 35.0006 70.9411 35.0006C71.9035 35.0006 72.6526 34.6301 73.1883 33.8916C73.7252 33.1518 73.9937 32.1766 73.9937 30.9648Z" fill="white"/>
    <path d="M89.8242 30.8715C89.8242 32.8219 89.2956 34.3636 88.2373 35.4954C87.2917 36.5028 86.116 37.006 84.7125 37.006C83.1976 37.006 82.1094 36.4634 81.4478 35.3783H81.3999V41.4195H78.8447V29.0538C78.8447 27.8276 78.8123 26.5692 78.75 25.2785H80.9972L81.1398 27.0962H81.1877C82.0387 25.7266 83.3319 25.043 85.0685 25.043C86.424 25.043 87.5566 25.5773 88.4651 26.6469C89.3699 27.7177 89.8242 29.1255 89.8242 30.8715ZM87.221 30.9648C87.221 29.8485 86.9681 28.9283 86.4624 28.2041C85.9098 27.4488 85.1704 27.0711 84.2391 27.0711C83.6075 27.0711 83.0358 27.2815 82.5229 27.6962C82.0099 28.1144 81.6755 28.6606 81.5185 29.337C81.4406 29.6525 81.3999 29.9107 81.3999 30.1138V32.026C81.3999 32.8602 81.6564 33.5641 82.1669 34.1389C82.6799 34.7126 83.3463 35.0006 84.1684 35.0006C85.1308 35.0006 85.8799 34.6301 86.4156 33.8916C86.9526 33.1518 87.221 32.1766 87.221 30.9648Z" fill="white"/>
    <path d="M104.614 32.2868C104.614 33.6396 104.143 34.7403 103.197 35.59C102.158 36.5186 100.711 36.9823 98.8524 36.9823C97.1361 36.9823 95.7602 36.6525 94.7188 35.9916L95.3108 33.8679C96.4326 34.5443 97.6635 34.8837 99.0046 34.8837C99.967 34.8837 100.716 34.6662 101.254 34.2336C101.79 33.8009 102.057 33.2201 102.057 32.4959C102.057 31.8505 101.837 31.3068 101.394 30.8658C100.955 30.4248 100.22 30.0149 99.194 29.636C96.4014 28.5975 95.0064 27.0761 95.0064 25.0755C95.0064 23.7681 95.4954 22.6961 96.4746 21.8619C97.4501 21.0265 98.7517 20.6095 100.379 20.6095C101.831 20.6095 103.036 20.8616 103.999 21.3648L103.36 23.4418C102.461 22.9542 101.445 22.7104 100.307 22.7104C99.4085 22.7104 98.7062 22.9315 98.2028 23.3713C97.7773 23.7645 97.564 24.2437 97.564 24.8114C97.564 25.44 97.8073 25.9599 98.2963 26.3686C98.7217 26.7463 99.4948 27.155 100.617 27.596C101.989 28.1469 102.997 28.7911 103.645 29.5297C104.291 30.2658 104.614 31.1873 104.614 32.2868Z" fill="white"/>
    <path d="M113.063 27.1916H110.246V32.7596C110.246 34.1757 110.742 34.8832 111.737 34.8832C112.194 34.8832 112.572 34.8438 112.872 34.7649L112.943 36.6998C112.439 36.8874 111.777 36.9818 110.956 36.9818C109.947 36.9818 109.158 36.6747 108.589 36.0616C108.022 35.4473 107.736 34.4172 107.736 32.9699V27.1892H106.059V25.277H107.736V23.1773L110.246 22.422V25.277H113.063V27.1916Z" fill="white"/>
    <path d="M125.771 30.917C125.771 32.6797 125.265 34.127 124.256 35.2587C123.197 36.424 121.793 37.0048 120.042 37.0048C118.354 37.0048 117.011 36.4467 116.009 35.3304C115.007 34.2142 114.506 32.8052 114.506 31.107C114.506 29.3299 115.021 27.8742 116.056 26.7425C117.087 25.6095 118.48 25.043 120.231 25.043C121.919 25.043 123.276 25.6012 124.301 26.7186C125.282 27.8025 125.771 29.202 125.771 30.917ZM123.119 30.9994C123.119 29.9418 122.893 29.0347 122.434 28.2782C121.898 27.3627 121.132 26.9062 120.14 26.9062C119.113 26.9062 118.333 27.3639 117.797 28.2782C117.338 29.0359 117.111 29.9573 117.111 31.0472C117.111 32.1049 117.338 33.012 117.797 33.7673C118.349 34.6827 119.121 35.1392 120.117 35.1392C121.093 35.1392 121.859 34.6731 122.411 33.7434C122.882 32.9725 123.119 32.0559 123.119 30.9994Z" fill="white"/>
    <path d="M134.075 27.5193C133.822 27.4727 133.552 27.4488 133.27 27.4488C132.371 27.4488 131.676 27.787 131.187 28.4646C130.761 29.0622 130.548 29.8175 130.548 30.7293V36.7466H127.994L128.018 28.8901C128.018 27.5683 127.985 26.3648 127.922 25.2797H130.148L130.241 27.4739H130.312C130.581 26.7198 131.007 26.1127 131.589 25.6573C132.159 25.2474 132.773 25.043 133.436 25.043C133.672 25.043 133.886 25.0598 134.075 25.0897V27.5193Z" fill="white"/>
    <path d="M145.502 30.472C145.502 30.9285 145.472 31.3133 145.409 31.6276H137.743C137.773 32.7606 138.143 33.627 138.855 34.2246C139.501 34.7588 140.337 35.0265 141.362 35.0265C142.497 35.0265 143.533 34.846 144.464 34.4839L144.864 36.2527C143.776 36.7259 142.491 36.9613 141.009 36.9613C139.226 36.9613 137.826 36.4379 136.807 35.3922C135.791 34.3465 135.281 32.9422 135.281 31.1807C135.281 29.4514 135.755 28.0113 136.703 26.8628C137.695 25.6366 139.036 25.0235 140.724 25.0235C142.381 25.0235 143.636 25.6366 144.488 26.8628C145.163 27.8368 145.502 29.0414 145.502 30.472ZM143.066 29.8111C143.082 29.0558 142.916 28.4032 142.569 27.8523C142.127 27.1436 141.448 26.7899 140.533 26.7899C139.698 26.7899 139.018 27.1353 138.499 27.8284C138.074 28.3793 137.821 29.0402 137.743 29.8099H143.066V29.8111Z" fill="white"/>
    <path d="M57.888 11.0613C57.888 12.468 57.465 13.5268 56.62 14.2379C55.8374 14.894 54.7252 15.2227 53.2846 15.2227C52.5703 15.2227 51.959 15.1916 51.4473 15.1295V7.44378C52.1148 7.33622 52.8339 7.28125 53.6106 7.28125C54.9828 7.28125 56.0172 7.57883 56.7147 8.17399C57.4961 8.84683 57.888 9.80888 57.888 11.0613ZM56.5637 11.096C56.5637 10.1841 56.3216 9.48501 55.8374 8.99741C55.3532 8.51101 54.6461 8.26721 53.7148 8.26721C53.3193 8.26721 52.9825 8.2935 52.7033 8.34847V14.1913C52.8579 14.2152 53.1408 14.226 53.5518 14.226C54.513 14.226 55.2549 13.9595 55.7775 13.4264C56.3 12.8934 56.5637 12.1166 56.5637 11.096Z" fill="white"/>
    <path d="M64.9104 12.291C64.9104 13.1574 64.6623 13.8673 64.1662 14.4242C63.646 14.9967 62.9569 15.2823 62.0964 15.2823C61.267 15.2823 60.6066 15.0086 60.114 14.4589C59.6226 13.9103 59.377 13.2184 59.377 12.3842C59.377 11.5118 59.6298 10.7959 60.138 10.2402C60.6462 9.68448 61.3293 9.40602 62.1898 9.40602C63.0192 9.40602 63.6856 9.6797 64.1901 10.2282C64.6695 10.7613 64.9104 11.4496 64.9104 12.291ZM63.6077 12.3316C63.6077 11.8118 63.495 11.366 63.2709 10.9943C63.0072 10.5449 62.6321 10.3203 62.1443 10.3203C61.6397 10.3203 61.2562 10.5449 60.9925 10.9943C60.7672 11.366 60.6558 11.8189 60.6558 12.3543C60.6558 12.8742 60.7684 13.32 60.9925 13.6916C61.2646 14.141 61.6433 14.3657 62.1323 14.3657C62.6117 14.3657 62.988 14.1374 63.2589 13.6797C63.4914 13.3008 63.6077 12.8515 63.6077 12.3316Z" fill="white"/>
    <path d="M74.3264 9.51953L72.5586 15.1532H71.408L70.6758 12.7069C70.49 12.0962 70.339 11.4891 70.2215 10.8867H70.1987C70.0897 11.5058 69.9387 12.1117 69.7445 12.7069L68.9667 15.1532H67.8029L66.1406 9.51953H67.4314L68.0702 12.1977C68.2248 12.8311 68.3519 13.4347 68.4537 14.0059H68.4765C68.57 13.5351 68.7246 12.9351 68.9427 12.2097L69.7445 9.52073H70.768L71.5363 12.1523C71.722 12.7941 71.8731 13.412 71.9893 14.0071H72.0241C72.1092 13.4275 72.2374 12.8096 72.4076 12.1523L73.0931 9.52073H74.3264V9.51953Z" fill="white"/>
    <path d="M80.837 15.1541H79.5809V11.9274C79.5809 10.9331 79.2022 10.4359 78.4424 10.4359C78.0696 10.4359 77.7688 10.5721 77.5351 10.8458C77.3038 11.1195 77.1863 11.4422 77.1863 11.8114V15.1529H75.9303V11.1302C75.9303 10.6355 75.9147 10.0989 75.8848 9.51805H76.9886L77.0473 10.3988H77.0821C77.2283 10.1252 77.4464 9.89929 77.7329 9.71883C78.0732 9.50849 78.4543 9.40213 78.8714 9.40213C79.3988 9.40213 79.8374 9.57183 80.1862 9.91244C80.62 10.3295 80.837 10.9522 80.837 11.7792V15.1541Z" fill="white"/>
    <path d="M84.3008 15.1523H83.0469V6.93359H84.3008V15.1523Z" fill="white"/>
    <path d="M91.6956 12.291C91.6956 13.1574 91.4475 13.8673 90.9513 14.4242C90.4312 14.9967 89.7408 15.2823 88.8815 15.2823C88.051 15.2823 87.3906 15.0086 86.8992 14.4589C86.4078 13.9103 86.1621 13.2184 86.1621 12.3842C86.1621 11.5118 86.415 10.7959 86.9232 10.2402C87.4313 9.68448 88.1145 9.40602 88.9738 9.40602C89.8044 9.40602 90.4695 9.6797 90.9753 10.2282C91.4547 10.7613 91.6956 11.4496 91.6956 12.291ZM90.3916 12.3316C90.3916 11.8118 90.279 11.366 90.0548 10.9943C89.7924 10.5449 89.416 10.3203 88.9294 10.3203C88.4237 10.3203 88.0402 10.5449 87.7777 10.9943C87.5524 11.366 87.4409 11.8189 87.4409 12.3543C87.4409 12.8742 87.5536 13.32 87.7777 13.6916C88.0497 14.141 88.4285 14.3657 88.9175 14.3657C89.3969 14.3657 89.772 14.1374 90.0429 13.6797C90.2766 13.3008 90.3916 12.8515 90.3916 12.3316Z" fill="white"/>
    <path d="M97.7749 15.1544H96.6471L96.5536 14.5055H96.5189C96.133 15.023 95.5829 15.2823 94.8686 15.2823C94.3352 15.2823 93.9038 15.1114 93.579 14.772C93.2841 14.4637 93.1367 14.08 93.1367 13.6247C93.1367 12.9363 93.4244 12.4117 94.0032 12.0484C94.5809 11.6851 95.3935 11.507 96.4398 11.5154V11.4102C96.4398 10.668 96.0491 10.2976 95.2665 10.2976C94.7092 10.2976 94.2178 10.4374 93.7935 10.7147L93.5382 9.89243C94.0632 9.56855 94.7116 9.40602 95.4762 9.40602C96.9527 9.40602 97.6934 10.1828 97.6934 11.7365V13.8112C97.6934 14.374 97.721 14.8222 97.7749 15.1544ZM96.4709 13.2184V12.3495C95.0855 12.3256 94.3928 12.7045 94.3928 13.4849C94.3928 13.7789 94.4718 13.9988 94.6336 14.1458C94.7954 14.2928 95.0016 14.3657 95.2473 14.3657C95.5229 14.3657 95.7806 14.2784 96.0155 14.1051C96.2516 13.9307 96.3966 13.7096 96.4506 13.4383C96.4638 13.3773 96.4709 13.3032 96.4709 13.2184Z" fill="white"/>
    <path d="M104.911 15.1523H103.796L103.737 14.2476H103.703C103.347 14.936 102.74 15.2802 101.888 15.2802C101.207 15.2802 100.641 15.0137 100.191 14.4806C99.7417 13.9476 99.5176 13.2557 99.5176 12.4059C99.5176 11.4941 99.7609 10.7555 100.25 10.1914C100.723 9.66559 101.303 9.40267 101.994 9.40267C102.752 9.40267 103.283 9.65722 103.585 10.1675H103.609V6.93359H104.866V13.6345C104.866 14.1831 104.881 14.6886 104.911 15.1523ZM103.609 12.7764V11.8371C103.609 11.6745 103.597 11.5431 103.574 11.4427C103.504 11.1415 103.352 10.8882 103.12 10.6838C102.887 10.4794 102.605 10.3767 102.28 10.3767C101.811 10.3767 101.445 10.5619 101.175 10.9336C100.908 11.3053 100.772 11.7797 100.772 12.3593C100.772 12.9163 100.901 13.368 101.158 13.7158C101.43 14.0863 101.797 14.2715 102.256 14.2715C102.668 14.2715 102.998 14.1173 103.249 13.8078C103.491 13.5222 103.609 13.178 103.609 12.7764Z" fill="white"/>
    <path d="M115.653 12.291C115.653 13.1574 115.405 13.8673 114.908 14.4242C114.388 14.9967 113.7 15.2823 112.839 15.2823C112.01 15.2823 111.35 15.0086 110.856 14.4589C110.365 13.9103 110.119 13.2184 110.119 12.3842C110.119 11.5118 110.372 10.7959 110.88 10.2402C111.388 9.68448 112.071 9.40602 112.933 9.40602C113.761 9.40602 114.429 9.6797 114.932 10.2282C115.412 10.7613 115.653 11.4496 115.653 12.291ZM114.351 12.3316C114.351 11.8118 114.238 11.366 114.014 10.9943C113.749 10.5449 113.375 10.3203 112.886 10.3203C112.383 10.3203 112 10.5449 111.735 10.9943C111.509 11.366 111.398 11.8189 111.398 12.3543C111.398 12.8742 111.511 13.32 111.735 13.6916C112.007 14.141 112.386 14.3657 112.874 14.3657C113.354 14.3657 113.731 14.1374 114.002 13.6797C114.234 13.3008 114.351 12.8515 114.351 12.3316Z" fill="white"/>
    <path d="M122.406 15.1541H121.151V11.9274C121.151 10.9331 120.773 10.4359 120.011 10.4359C119.639 10.4359 119.338 10.5721 119.105 10.8458C118.873 11.1195 118.757 11.4422 118.757 11.8114V15.1529H117.499V11.1302C117.499 10.6355 117.485 10.0989 117.455 9.51805H118.558L118.616 10.3988H118.651C118.799 10.1252 119.017 9.89929 119.302 9.71883C119.644 9.50849 120.023 9.40213 120.442 9.40213C120.968 9.40213 121.407 9.57183 121.755 9.91244C122.19 10.3295 122.406 10.9522 122.406 11.7792V15.1541Z" fill="white"/>
    <path d="M130.861 10.4584H129.478V13.1952C129.478 13.8907 129.723 14.2385 130.21 14.2385C130.435 14.2385 130.622 14.2194 130.77 14.1799L130.802 15.13C130.554 15.2232 130.228 15.2698 129.827 15.2698C129.33 15.2698 128.944 15.1193 128.665 14.8181C128.385 14.5169 128.246 14.0102 128.246 13.2991V10.4584H127.42V9.52023H128.246V8.48767L129.477 8.11719V9.51904H130.86V10.4584H130.861Z" fill="white"/>
    <path d="M137.508 15.1523H136.251V11.9494C136.251 10.9396 135.872 10.434 135.114 10.434C134.531 10.434 134.133 10.7268 133.915 11.3124C133.878 11.4355 133.856 11.5861 133.856 11.763V15.1511H132.602V6.93359H133.856V10.3289H133.88C134.276 9.711 134.843 9.40267 135.577 9.40267C136.098 9.40267 136.528 9.57237 136.869 9.91297C137.295 10.3372 137.508 10.9682 137.508 11.8024V15.1523Z" fill="white"/>
    <path d="M144.369 12.0702C144.369 12.2949 144.352 12.4837 144.322 12.6379H140.555C140.572 13.1948 140.752 13.619 141.1 13.913C141.419 14.176 141.83 14.3074 142.334 14.3074C142.891 14.3074 143.399 14.219 143.857 14.0409L144.053 14.9109C143.518 15.1428 142.887 15.2587 142.157 15.2587C141.282 15.2587 140.593 15.0018 140.095 14.4879C139.594 13.974 139.346 13.2844 139.346 12.4192C139.346 11.5694 139.577 10.8619 140.043 10.2979C140.53 9.69553 141.188 9.39436 142.018 9.39436C142.831 9.39436 143.448 9.69553 143.865 10.2979C144.202 10.7759 144.369 11.3675 144.369 12.0702ZM143.17 11.7463C143.18 11.3746 143.097 11.0544 142.927 10.7843C142.709 10.4365 142.377 10.262 141.927 10.262C141.517 10.262 141.183 10.4317 140.928 10.7723C140.719 11.0436 140.596 11.3675 140.555 11.7463H143.17Z" fill="white"/>
  </svg>
);

export default AppStore;
