import React from "react";
import useAssets from "shared/useAssets";
import { GainLossTransactionResponse } from "state/store/reportApi";
import { sharedStyles } from "../income/styles";
import styled from "styled-components";
import { CustodySubText } from "../../CustodyStyles";
import getUsDollar from "shared/dollarFormat";

interface Props {
    transactions: GainLossTransactionResponse[] | undefined;
}

const CustodyGainLossTransactionDetails: React.FC<Props> = ({ transactions }) => {
    const assets = useAssets();
    
    return (<sharedStyles.Container>
        <Title>Transaction Details</Title>
        <sharedStyles.TableContainer>
            <sharedStyles.Table>
                <thead>
                    <tr>
                        <sharedStyles.Th>Date</sharedStyles.Th>
                        <sharedStyles.Th>Strategy</sharedStyles.Th>
                        <sharedStyles.Th>Action</sharedStyles.Th>
                        <sharedStyles.Th>Transaction Type</sharedStyles.Th>
                        <sharedStyles.Th>Closing Amount</sharedStyles.Th>
                        <sharedStyles.Th>Closing Quantity</sharedStyles.Th>
                        <sharedStyles.Th>Closing Price</sharedStyles.Th>
                        <sharedStyles.Th>Cost Basis</sharedStyles.Th>
                        <sharedStyles.Th>Transaction Id</sharedStyles.Th>
                        <sharedStyles.Th>Duration</sharedStyles.Th>
                        <sharedStyles.Th>Realized Gain/Loss</sharedStyles.Th>
                    </tr>
                </thead>
                <tbody>
                    {transactions?.map((row, index) => (
                        <sharedStyles.TableRow key={index}>
                            <sharedStyles.Td>
                                {new Date(row.closingDate).toLocaleDateString()}
                            </sharedStyles.Td>
                            <sharedStyles.Td>{row.strategyName ? row.strategyName : '--'}</sharedStyles.Td>
                            <sharedStyles.Td>{row.actionName}</sharedStyles.Td>
                            <sharedStyles.Td>{row.transactionCategory}</sharedStyles.Td>
                            <sharedStyles.Td>
                                <QuantityCell>
                                    {assets.getPriceFormattedI(row.closingAmount.asset, row.closingAmount.amount)}
                                </QuantityCell>
                            </sharedStyles.Td>
                            <sharedStyles.Td>
                                <QuantityCell>
                                    {assets.getPriceFormattedI(row.amount.asset, row.amount.amount)}
                                </QuantityCell>
                            </sharedStyles.Td>
                            <sharedStyles.Td>
                                <QuantityCell>
                                    {assets.getPriceFormattedI(row.closingPrice.quoteCurrency, row.closingPrice.rate)}
                                </QuantityCell>
                            </sharedStyles.Td>
                            <sharedStyles.Td>
                                <QuantityCell>
                                    {row.overallCostBasis ?
                                        assets.getPriceFormattedI(row.overallCostBasis.asset, row.overallCostBasis.amount) : '--'}
                                </QuantityCell>
                            </sharedStyles.Td>
                            <sharedStyles.Td>{row.abraTxUID}</sharedStyles.Td>
                            <sharedStyles.Td>{row.duration}</sharedStyles.Td>
                            <sharedStyles.Td>
                                <QuantityCell>
                                    {assets.getPriceFormattedI(row.realizedAmount.asset, row.realizedAmount.amount)}
                                </QuantityCell>
                            </sharedStyles.Td>
                        </sharedStyles.TableRow>
                    ))}
                </tbody>
            </sharedStyles.Table>
        </sharedStyles.TableContainer>
    </sharedStyles.Container>);
}

const Title = styled.h2`
    color: white;
    font-size: 20px;
    margin-bottom: 8px;
`;

const QuantityCell = styled.div`
    display: flex;
    flex-direction: column;
`;

const AssetCell = styled.div`
    display: flex;
    flex-direction: column;
`;

const AssetName = styled.div`
    color: white;
`;

export default CustodyGainLossTransactionDetails;