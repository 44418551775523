import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Assets } from "services/models";
import { AssetsControllerApi, AccreditationControllerApi } from "services";
import { AxiosError } from "axios";
import { RootState } from "state/store";

interface EarnState {
  loading: boolean;
  investorLoading: boolean;
  investorError: boolean;
  holdings: { assets: Assets[]; paginationResponse: any };
  holdingsError: boolean;
  interestRate: { assets: Assets[]; paginationResponse: any };
  interestRateError: boolean;
}

const initialState = {
  loading: false,
  investorLoading: false,
  investorError: false,
  holdings: { assets: [], paginationResponse: {} },
  holdingsError: false,
  interestRate: { assets: [], paginationResponse: {} },
  interestRateError: false,
} as EarnState;

const AssetsService = new AssetsControllerApi();
const AccreditationService = new AccreditationControllerApi();

export const getUserHoldingsTxr = createAsyncThunk(
  "assets/getUserHoldingsTxr",
  async (args: any, api: any) => {
    const txr = await AssetsService.getHoldingInterest(
      api?.getState().auth?.token,
      args?.sortField,
      args?.sortDirection,
      args?.page,
      args?.size
    );
    const holdings: Assets[] = txr?.data?.assets ?? new Array(0);
    return holdings;
  }
);

export const getUserInterestRateTxr = createAsyncThunk(
  "assets/getUserInterestRateTxr",
  async (args: any, api: any) => {
    const txr = await AssetsService.getInterestRates(
      api?.getState().auth?.token,
      args?.sortField,
      args?.sortDirection,
      args?.page,
      args?.size
    );
    const interestRate: Assets[] = txr?.data?.assets ?? new Array(0);
    return interestRate;
  }
);

export const accreditationStartApp = createAsyncThunk(
  "assets/accreditationStartApp",
  async (_,  { getState, rejectWithValue }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    try {
      const txr = await AccreditationService.accreditationStartApp(
        token);
      const updatedUserInfo: any = txr?.data ?? {};
      return updatedUserInfo;
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.data?.message)
        return rejectWithValue(error.response.data.message);
    }
  }
);

export const accreditationStartByEmail = createAsyncThunk(
  "assets/accreditationStartByEmail",
  async (_, { getState, rejectWithValue }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    try {
      const txr = await AccreditationService.accreditationStartByEmail(
        token);
      const updatedUserInfo: any = txr?.data ?? {};
      return updatedUserInfo;
    } catch (err) {
      const error = err as AxiosError;
      if (error?.response?.data?.message)
        return rejectWithValue(error.response.data.message);
    }
  }
);


export const getUserHoldings =
  (
    sortField?: string,
    sortDirection?: string,
    page: string = "1",
    size: string = "5"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setUserHoldings({ assets: [], paginationResponse: {} }));
      const { token } = getState().auth;
      const { data } = await AssetsService.getHoldingInterest(
        token,
        sortField,
        sortDirection,
        page,
        size
      );

      const holdings: Assets[] = data?.assets ?? new Array(0);
      const pagination = data?.paginationResponse;

      dispatch(
        setUserHoldings({ assets: holdings, paginationResponse: pagination })
      );
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getUserInterestRate =
  (
    sortField?: string,
    sortDirection?: string,
    page: string = "1",
    size: string = "5"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      dispatch(setUserInterestRate({ assets: [], paginationResponse: {} }));
      const { token } = getState().auth;
      const { data } = await AssetsService.getInterestRates(
        token,
        sortField,
        sortDirection,
        page,
        size
      );
      const interestRate: Assets[] = data?.assets ?? new Array(0);
      const pagination = data?.paginationResponse;

      dispatch(
        setUserInterestRate({
          assets: interestRate,
          paginationResponse: pagination,
        })
      );
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const earnSlice = createSlice({
  name: "earn",
  initialState,
  reducers: {
    setUserHoldings: (
      state,
      action: PayloadAction<{ assets: Assets[]; paginationResponse: any }>
    ) => {
      state.holdings = action.payload;
    },
    setUserInterestRate: (
      state,
      action: PayloadAction<{ assets: Assets[]; paginationResponse: any }>
    ) => {
      state.interestRate = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [getUserHoldingsTxr.pending as any]: (state) => {
      state.loading = true;
      state.holdingsError = false;
    },
    [getUserHoldingsTxr.rejected as any]: (state) => {
      state.loading = false;
      state.holdingsError = true;
    },
    [getUserHoldingsTxr.fulfilled as any]: (state, action) => {
      state.loading = false;
      state.holdingsError = false;
      state.holdings = action.payload;
    },
    [getUserInterestRateTxr.pending as any]: (state) => {
      state.loading = true;
      state.interestRateError = false;
    },
    [getUserInterestRateTxr.rejected as any]: (state) => {
      state.loading = false;
      state.interestRateError = true;
    },
    [getUserInterestRateTxr.fulfilled as any]: (state, action) => {
      state.loading = false;
      state.interestRateError = false;
      state.interestRate = action.payload;
    },

    [accreditationStartApp.pending as any]: (state) => {
      state.investorLoading = true;
      state.investorError = false;
    },
    [accreditationStartApp.rejected as any]: (state) => {
      state.investorLoading = false;
      state.investorError = true;
    },
    [accreditationStartApp.fulfilled as any]: (state, action) => {
      state.investorLoading = false;
      state.investorError = false;
      state.interestRate = action.payload;
    },
  },
});

export const {
  setUserHoldings,
  setUserInterestRate,
  setCompleted,
  setPending,
  setLoading,
} = earnSlice.actions;

export const earnReducer = earnSlice.reducer;
