import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const CrossModal: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  opacity = 0.7,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M3.29325 20.7073C3.48825 20.9023 3.74425 21.0002 4.00025 21.0002C4.25625 21.0002 4.51225 20.9023 4.70725 20.7073L12.0003 13.4143L19.2932 20.7073C19.4882 20.9023 19.7442 21.0002 20.0002 21.0002C20.2562 21.0002 20.5123 20.9023 20.7073 20.7073C21.0983 20.3163 21.0983 19.6842 20.7073 19.2932L13.4143 12.0003L20.7073 4.70725C21.0983 4.31625 21.0983 3.68425 20.7073 3.29325C20.3173 2.90225 19.6832 2.90225 19.2932 3.29325L12.0003 10.5862L4.70725 3.29325C4.31725 2.90225 3.68325 2.90225 3.29325 3.29325C2.90225 3.68425 2.90225 4.31625 3.29325 4.70725L10.5862 12.0003L3.29325 19.2932C2.90225 19.6842 2.90225 20.3163 3.29325 20.7073Z"
      fill={color}
      fillOpacity={opacity}
    />
  </svg>
);

export default CrossModal;
