import React, { FC, useState, useCallback, useEffect, useRef } from "react"
import { emailValidation } from "shared/helper"
import { emptyStringValidation } from "lib/utils/validations"
import { getText } from "shared/locale-helper"
import { IResetPassword } from "./ResetPassword.interface"
import { palette } from "lib/theme"
import { Text, Heading } from "components/atoms/Typography"
import { ToastType, toast } from "components/organisms/Toast/"
import { TypesNamesEnum } from "enums/Button.enum"
import { useResetPassword1Mutation } from "state/store/api"
import Button from "components/atoms/Button"
import Input from "components/atoms/Input"
import SignInBlockedModal from "../SignIn/SignInBlockedModal"
import styled from "styled-components"

const ResetPassword: FC<IResetPassword> = ({ }) => {
  const [resetPassword, { data, isLoading, isSuccess }] =
    useResetPassword1Mutation()

  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState<string | null>(null)
  const [visible, setVisible] = useState(false)

  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [buttonText, setButtonText] = useState(getText("reset_password"))

  const Ref = useRef(null)

  const [timer, setTimer] = useState("00:00")

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date())
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / 1000 / 60 / 60) % 24)
    return {
      total,
      hours,
      minutes,
      seconds,
    }
  }

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e)
    if (total >= 0) {
      const currentTime =
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      setTimer(currentTime)
      setButtonDisabled(true)
      setButtonText(`${getText("reset_password_in")} ${currentTime}`)
    } else {
      setButtonDisabled(false)
      setButtonText(`${getText("reset_password")}`)
    }
  }

  const clearTimer = (e) => {
    setTimer("05:00")
    if (Ref.current) clearInterval(Ref.current)
    const id = setInterval(() => {
      startTimer(e)
    }, 1000)
    Ref.current = id
  }

  const getDeadTime = () => {
    let deadline = new Date()
    deadline.setMinutes(deadline.getMinutes() + 5)
    return deadline
  }

  const errorsDetails = {
    tooManyAtempsSignUp: {
      icon: "SignInBlocked",
      title: getText("access_suspended"),
      details: `${getText("wrong_2fa_code_signup")}`,
      confirmText: getText("got_it"),
      onConfirm: () => {
        setVisible(false)
      },
    },
    blockedSignUp: {
      icon: "SignInBlocked",
      title: getText("access_suspended"),
      details: getText("wrong_2fa_code_signup_too_many_times"),
      confirmText: getText("contact_customer_support_capital_s"),
      onConfirm: () => { },
    },
  }

  const [errorModal, setErrorModal] = useState<
    | {
      icon: string
      title: string
      details: string
      confirmText: string
      onConfirm: () => void
      cancelText?: string
      onCancel?: () => void
      onClose?: () => void
    }
    | undefined
  >(errorsDetails.tooManyAtempsSignUp)

  const validateEmail = (email: string) => {
    if (emptyStringValidation(email)) {
      setEmailError(getText("add_your_email_address_to_continue"))
      return false
    }

    if (email && !emailValidation(email)) {
      if (email.includes("@")) setEmailError(getText("invalid_email_format"))
      else setEmailError(getText("invalid_email_format_at_symbol"))
      return false
    }

    setEmailError(null)
    return true
  }

  const clickResetPwd = async () => {
    try {
      if (validateEmail(email)) {
        await resetPassword({ emailRequest: { email } }).unwrap()
        toast.show({
          type: ToastType.Success,
          title: "Your password reset request had been received",
          content:
            "Check your inbox for instructions on how to reset your password. Please note, you will only receive an email if the provided email address was also used for account registration..",
        })
      }
    } catch (error) {
      toast.show({
        type: ToastType.Fail,
        title: "Your request has an error",
        content:
          error?.response?.data?.message ||
          "Your request hasn't been execute, try again",
      })
    }
  }

  useEffect(() => {
    if (data && isSuccess && !isLoading) {
      if (data.code !== "0") {
        if (data.code === "4012") {
          clearTimer(getDeadTime())
        }

        const modalData =
          data.code === "4012"
            ? errorsDetails.tooManyAtempsSignUp
            : errorsDetails.blockedSignUp

        setErrorModal(modalData)
        setVisible(true)
        return
      }

      toast.show({
        type: ToastType.Success,
        title: getText("reset_password_sent"),
        content: getText("reset_password_sent_content"),
      })
    }
  }, [data, isSuccess, isLoading])

  return (
    <>
      <Row
        style={{
          gap: "16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Heading variant="h1">Reset password</Heading>
        <Text size="large" color={palette.darkBackgroundContrast.light70}>
          {getText("reset_password_details")}
        </Text>
      </Row>
      <Row
        style={{
          marginTop: "36px",
        }}
      >
        <div style={{ width: "100%" }}>
          <Input
            label={getText("Email")}
            className="input"
            inputProps={{
              value: email,
              id: "email",
              name: "email",
              type: "email",
              onChange: (event: any) => {
                validateEmail(event?.target?.value)
                setEmail(event?.target?.value)
              },
              onBlur: (event: any) => {
                setEmailError("")
                if (
                  event?.target?.value &&
                  !emailValidation(event?.target?.value)
                ) {
                  if (event?.target?.value.includes("@"))
                    setEmailError(getText("invalid_email_format"))
                  else setEmailError(getText("invalid_email_format_at_symbol"))
                }
              },
            }}
            errorMessage={emailError}
            dismissAlert={() => setEmailError("")}
            dismissAlertButton={false}
          />
        </div>
      </Row>
      <Row
        style={{
          marginTop: "36px",
        }}
      >
        <Button
          buttonType={TypesNamesEnum.ACCENT}
          width="100%"
          height={"48"}
          onClick={clickResetPwd}
          disabled={buttonDisabled}
          isLoading={isLoading}
        >
          {buttonText}
        </Button>
      </Row>
      <SignInBlockedModal
        visible={visible}
        loading={isLoading}
        onClose={() =>
          errorModal?.onClose ? errorModal.onClose() : setVisible(false)
        }
        {...errorModal}
      />
    </>
  )
}

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export default ResetPassword
