import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetMilestonesQuery } from 'state/store/custodyApi'
import styled from 'styled-components'

const UserOnboardingStatus = () => {

  const { data, isLoading } = useGetMilestonesQuery()
  
  return (
    <div>
      {!isLoading && <>
        <>
          {!data?.deposits && !data?.invested && <OnboardedNoFunds />}
          {data?.deposits && !data?.invested && <FundedButNotInvested />}
        </>
      </>}
    </div>
  )
}

export default UserOnboardingStatus

const OnboardedNoFunds = () => {
  const navigate = useNavigate()
return <TextAreaContainer>
  <p>
    Thank you for being an Abra SMA client. Please fund your account by clicking on <span> </span> 
    <ClickableAction onClick={() => navigate('/custody/deposit-fiat')}>Deposit USD</ClickableAction> or <ClickableAction onClick={() => navigate('/custody/deposit')}>Deposit Crypto</ClickableAction>
  </p>
  <br />
  <ForMoreGuidanceVideos />
</TextAreaContainer>
}

const FundedButNotInvested = () => {
  const navigate = useNavigate()
  return <TextAreaContainer>
  <p>Invest your assets in yield generating strategies by clicking on <ClickableAction onClick={() => navigate('/custody/invest')}>Invest</ClickableAction></p>
  <br />
  <ForMoreGuidance />
</TextAreaContainer>
}

const ClickableAction = styled.span`
  display: inline-block;
  align-items: center;
  justify-content: space-around;
  background-color: #6f2acd;
  border-radius: 0.5vw;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  font-size: 70%;
  padding: 2px 1vw;
  transition: background-color 0.3s ease-in;
  cursor: pointer;
  &:hover {
    background-color: #292749;
  }
`

const ForMoreGuidance = () => <p>For more guidance, please refer to <ClickableLink target='_blank' href='https://support.abra.com/hc/en-us/categories/25358465694619'>frequently asked questions</ClickableLink></p>
const ForMoreGuidanceVideos = () => <p>For more guidance, please refer to <ClickableLink target='_blank' href='https://support.abra.com/hc/en-us/categories/25358465694619'>frequently asked questions</ClickableLink> or watch this <ClickableLink href='https://support.abra.com/hc/en-us/articles/28652631214619-How-do-I-deposit-crypto-into-my-Abra-account-Video-Tutorial' target="_blank">helpful video</ClickableLink></p>

const ClickableLink = styled.a`
  cursor: pointer;
  color: #eb92ff;
  background-color: #ffffff00;
  transition: all 0.3s ease-in;
  border-radius: 1px;
  &:hover {
    background-color: #ffffff55;
  }
`

const TextAreaContainer = styled.div`
  display: inline-block;
  background-color: rgb(19, 19, 43);
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 2px;
`
