import { AssetAmountPair } from 'interfaces/AssetAmountPair.interface';
import { api } from './api';
import { downloadCsvResult } from './custodyApi';

export const reportApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAnnualIncomeTotal: build.query<AssetAmountPair, number>({
            query: (year) => ({
                url: `/custody/report/income/${year}/total`,
            }),
        }),
        getAnnualIncomeSummary: build.query<IncomeSummaryRow[], number>({
            query: (year) => ({
                url: `/custody/report/income/${year}/summary`,
            }),
        }),
        getAnnualIncomeDetails: build.query<
            AccountTransactionResponse[],
            number
        >({
            query: (year) => ({
                url: `/custody/report/income/${year}/details`,
            }),
        }),
        getIncomeReportCSV: build.mutation<any, number>({
            queryFn: async (year, _api, _extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: `custody/report/income/${year}/csv`,
                    responseHandler: (response) => response.blob(),
                });
                downloadCsvResult(result.data as Blob, `balances_${year}.csv`);
                return { data: null };
            },
        }),
        getAnnualGainLossTotal: build.query<GainLossTotals, number>({
            query: (year) => ({
                url: `/custody/report/gainloss/${year}/totals`,
            }),
        }),
        getAnnualGainLossSummary: build.query<GainLossSummaryRow[], number>({
            query: (year) => ({
                url: `/custody/report/gainloss/${year}/summary`,
            }),
        }),
        getAnnualGainLossDetails: build.query<
            GainLossTransactionResponse[],
            number
        >({
            query: (year) => ({
                url: `/custody/report/gainloss/${year}/details`,
            }),
        }),
        getGainLossReportCSV: build.mutation<any, number>({
            queryFn: async (year, _api, _extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: `custody/report/gainloss/${year}/csv`,
                    responseHandler: (response) => response.blob(),
                });
                downloadCsvResult(result.data as Blob, `gainloss_${year}.csv`);
                return { data: null };
            },
        }),
        getAnnualExpensesSummary: build.query<ExpensesSummaryRow[], number>({
            query: (year) => ({
                url: `/custody/report/expenses/${year}/summary`,
            }),
        }),
        getAnnualExpensesDetails: build.query<
            AccountTransactionResponse[],
            number
        >({
            query: (year) => ({
                url: `/custody/report/expenses/${year}/details`,
            }),
        }),
        getExpensesReportCSV: build.mutation<any, number>({
            queryFn: async (year, _api, _extraOptions, baseQuery) => {
                const result = await baseQuery({
                    url: `custody/report/expenses/${year}/csv`,
                    responseHandler: (response) => response.blob(),
                });
                downloadCsvResult(result.data as Blob, `expenses_${year}.csv`);
                return { data: null };
            },
        }),
    }),
    overrideExisting: false,
});

export interface IncomeSummaryRow {
    identifier: {
        networkId: string;
        protocolId: string;
        poolId: string;
    };
    strategyName: string;
    quantity: AssetAmountPair;
    income: AssetAmountPair;
}

export interface AccountTransactionResponse {
    dateTime: string;
    abraTxUID: string;
    networkName: string;
    actionName: string;
    strategyName: string;
    transactionCategory: string;
    amount: AssetAmountPair;
    amountConvenienceRate: {
        baseCurrency: string;
        quoteCurrency: string;
        rate: string;
    };
    networkFee: AssetAmountPair;
    networkFeeConvenienceRate: {
        from: string;
        to: string;
        rate: string;
    };
    blockchainHash: string;
    blockchainURL: string;
    invoiceYearMonth: string;
}


export interface GainLossSummaryRow {
    description: string;
    longTerm: AssetAmountPair;
    shortTerm: AssetAmountPair;
    unknown: AssetAmountPair;
    total: AssetAmountPair;
}

export interface GainLossTransactionResponse {
    network: string;
    actionName: string;
    strategyName: string;
    transactionCategory: string;
    amount: AssetAmountPair;
    totalFees: AssetAmountPair;
    blockchainHash: string;
    closingDate: string;
    overallCostBasis: AssetAmountPair;
    closingPrice: {
        baseCurrency: string;
        quoteCurrency: string;
        rate: string;
        live: boolean;
    };
    closingAmount: AssetAmountPair;
    duration: string;
    realizedAmount: AssetAmountPair;
    abraTxUID: string;
}

export interface GainLossTotals {
    shortTerm: AssetAmountPair;
    longTerm: AssetAmountPair;
    unknown: AssetAmountPair;
    total: AssetAmountPair;
}

export interface ExpensesSummaryRow {
    category: string;
    amount: AssetAmountPair;
    costBasisAmount: AssetAmountPair;
}

export const {
    useGetAnnualIncomeTotalQuery,
    useGetAnnualIncomeSummaryQuery,
    useGetAnnualIncomeDetailsQuery,
    useGetIncomeReportCSVMutation,
    useGetAnnualGainLossTotalQuery,
    useGetAnnualGainLossSummaryQuery,
    useGetAnnualGainLossDetailsQuery,
    useGetGainLossReportCSVMutation,
    useGetAnnualExpensesSummaryQuery,
    useGetAnnualExpensesDetailsQuery,
    useGetExpensesReportCSVMutation,
} = reportApi;
