import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Launch: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0001 4.64578C17.4039 4.65098 12.9974 6.47974 9.74732 9.73086C6.49729 12.982 4.66913 17.3899 4.66394 21.9877C4.66783 26.5864 6.49541 30.9956 9.74559 34.2478C12.9958 37.4999 17.403 39.3294 22.0001 39.3346C26.5972 39.3294 31.0044 37.4999 34.2546 34.2478C37.5048 30.9956 39.3323 26.5864 39.3362 21.9877C39.331 17.3899 37.5029 12.982 34.2529 9.73086C31.0028 6.47974 26.5963 4.65098 22.0001 4.64578Z"
      fill="#6678F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 39.3346C22.0001 39.3346 22.0001 39.3346 22.0002 39.3346C26.5973 39.3294 31.0045 37.4999 34.2547 34.2478C37.5049 30.9956 39.3325 26.5864 39.3364 21.9877C39.3312 17.3899 37.503 12.982 34.253 9.73086C31.003 6.47974 26.5965 4.65098 22.0002 4.64578C22.0001 4.64578 22.0001 4.64578 22 4.64578V39.3346Z"
      fill="#5462CB"
    />
    <path
      d="M22.8239 2.99253C26.5537 3.1559 30.153 4.41325 33.1737 6.60799C36.1944 8.80273 38.5029 11.8379 39.8118 15.3355L42.6611 14.4083C41.1562 10.3092 38.4664 6.7505 34.934 4.18483C31.4016 1.61915 27.1862 0.16245 22.8239 0V2.99253Z"
      fill="#FFA519"
    />
    <path
      d="M40.317 16.9004C40.7766 18.5572 41.0109 20.2684 41.0134 21.9877C41.0162 24.8497 40.3718 27.6752 39.1285 30.2528C37.8852 32.8303 36.0751 35.0932 33.8337 36.8719L35.5894 39.2953C38.2127 37.2383 40.3333 34.6108 41.7906 31.6121C43.2478 28.6133 44.0034 25.322 44 21.9877C44.0009 19.9553 43.7187 17.9326 43.1614 15.9781L40.317 16.9004Z"
      fill="#FFA519"
    />
    <path
      d="M4.18805 15.3355C5.49694 11.8379 7.80546 8.80273 10.8261 6.60799C13.8468 4.41325 17.4462 3.1559 21.176 2.99253V0C16.814 0.163743 12.5992 1.62095 9.06701 4.18645C5.53484 6.75195 2.84482 10.3099 1.33875 14.4083L4.18805 15.3355Z"
      fill="#D8DFE1"
    />
    <path
      d="M32.4948 37.8432C29.385 39.9095 25.7333 41.0088 22 41.0025C18.2666 41.0088 14.6149 39.9095 11.5051 37.8432L9.74451 40.2667C13.3676 42.7064 17.6373 44.0065 22.0049 44C26.3721 44.0047 30.6413 42.7047 34.2652 40.2667L32.4948 37.8432Z"
      fill="#D8DFE1"
    />
    <path
      d="M10.1712 36.8719C7.92988 35.0932 6.1198 32.8303 4.87646 30.2528C3.63313 27.6752 2.98874 24.8497 2.99154 21.9877C2.99251 20.2686 3.2251 18.5574 3.68303 16.9004L0.838612 15.9781C0.281345 17.9326 -0.000901447 19.9553 7.65766e-06 21.9877C-0.0027763 25.3224 0.753558 28.614 2.21166 31.6128C3.66977 34.6115 5.79135 37.2388 8.41552 39.2953L10.1712 36.8719Z"
      fill="#D8DFE1"
    />
    <path
      d="M13.0205 16.7778L14.4917 21.2764C15.0082 20.7256 15.4743 20.1296 15.8845 19.4956L16.1444 19.0443L15.1636 16.1008L15.0361 15.7132C14.9365 15.4037 14.7689 15.1204 14.5457 14.8842L13.9719 14.2758L13.5403 13.8147C13.4997 13.7654 13.4452 13.7295 13.384 13.7116C13.3227 13.6936 13.2575 13.6945 13.1968 13.714C13.136 13.7336 13.0825 13.7709 13.0432 13.8212C13.0039 13.8715 12.9806 13.9325 12.9763 13.9962L12.8979 14.6241L12.7949 15.4532C12.7535 15.7835 12.7871 16.1188 12.893 16.4344L13.0205 16.7778Z"
      fill="white"
    />
    <path
      d="M25.0895 17.445V16.4638C25.0868 15.6774 24.8897 14.9038 24.5158 14.212L23.5349 12.4067L22.1961 9.95383C22.1751 9.91489 22.1439 9.88236 22.1059 9.85968C22.068 9.83701 22.0246 9.82504 21.9803 9.82504C21.9361 9.82504 21.8927 9.83701 21.8547 9.85968C21.8167 9.88236 21.7855 9.91489 21.7645 9.95383L20.4355 12.4067L19.4547 14.217C19.0798 14.9079 18.8858 15.6826 18.8907 16.4687V17.4499V27.2614H25.0846L25.0895 17.445ZM20.4846 16.2529C20.4846 15.8495 20.6447 15.4627 20.9298 15.1775C21.2149 14.8923 21.6016 14.7321 22.0048 14.7321C22.2049 14.7314 22.4031 14.7703 22.5881 14.8464C22.7731 14.9225 22.9413 15.0345 23.083 15.1758C23.2247 15.317 23.3371 15.4849 23.4138 15.6697C23.4905 15.8546 23.53 16.0527 23.53 16.2529H20.4846Z"
      fill="white"
    />
    <path
      d="M17.3166 19.1227C16.5896 20.4588 15.6448 21.6642 14.5212 22.6892C14.2135 22.9445 13.966 23.2647 13.7965 23.6268C13.6269 23.989 13.5395 24.3841 13.5404 24.784V26.7169H17.3068L17.3166 19.1227Z"
      fill="white"
    />
    <path
      d="M26.6835 19.1227C27.4105 20.4588 28.3553 21.6642 29.4788 22.6892C29.7866 22.9445 30.0341 23.2647 30.2036 23.6268C30.3732 23.989 30.4606 24.3841 30.4597 24.784V26.7169H26.6884L26.6835 19.1227Z"
      fill="white"
    />
    <path
      d="M30.9795 16.7778L29.5083 21.2764C28.9918 20.7256 28.5257 20.1296 28.1155 19.4956L27.8605 19.0443L28.8413 16.1008L28.9688 15.7132C29.0684 15.4037 29.2359 15.1204 29.4592 14.8842L30.033 14.2758L30.4695 13.8147C30.5093 13.7647 30.5634 13.728 30.6246 13.7096C30.6858 13.6912 30.7511 13.6919 30.8119 13.7117C30.8727 13.7314 30.926 13.7692 30.9647 13.8201C31.0034 13.8709 31.0257 13.9324 31.0286 13.9962L31.107 14.6242L31.21 15.4532C31.2529 15.7831 31.2211 16.1185 31.1168 16.4344L30.9795 16.7778Z"
      fill="white"
    />
    <path
      d="M23.3584 28.0415V28.0856L21.995 31.2793L20.6317 28.0856V28.0415C20.3203 28.327 20.1057 28.7026 20.0178 29.1159C19.9299 29.5292 19.9731 29.9596 20.1413 30.3472L22.0147 34.7624L23.8929 30.3472C24.0544 29.955 24.0897 29.5221 23.9939 29.1089C23.8981 28.6957 23.676 28.3226 23.3584 28.0415Z"
      fill="white"
    />
  </svg>
);

export default Launch;
