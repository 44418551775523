import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Icon1: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 1C11.4479 1 10.9999 1.447 10.9999 2V12.919L7.62491 10.218C7.19291 9.87299 6.5639 9.94499 6.2189 10.374C5.8739 10.806 5.94391 11.435 6.37491 11.78L11.3749 15.78C11.5579 15.926 11.7789 15.999 11.9999 15.999C12.2209 15.999 12.4419 15.926 12.6249 15.78L17.6249 11.78C18.0559 11.434 18.1259 10.805 17.7809 10.374C17.4359 9.94299 16.8069 9.87299 16.3749 10.218L12.9999 12.919V2C12.9999 1.447 12.5519 1 11.9999 1Z"
      fill="#A399F6"
    />
    <path
      d="M22 13H18C17.448 13 17 13.447 17 14C17 14.553 17.448 15 18 15H22C22.552 15 23 14.553 23 14C23 13.447 22.552 13 22 13Z"
      fill="#A399F6"
    />
    <path
      d="M22 19H5C4.448 19 4 19.447 4 20C4 20.553 4.448 21 5 21H22C22.552 21 23 20.553 23 20C23 19.447 22.552 19 22 19Z"
      fill="#A399F6"
    />
    <path
      d="M1 22.793C1 23.346 1.448 23.793 2 23.793C2.552 23.793 3 23.346 3 22.793V17C3 15.897 3.897 15 5 15H6C6.552 15 7 14.553 7 14C7 13.447 6.552 13 6 13H5C2.794 13 1 14.794 1 17V22.793Z"
      fill="#A399F6"
    />
  </svg>
);

export default Icon1;
