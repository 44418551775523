import { Alert, Cash, DownArrow, IncomingTransaction, RightArrow, RightArrowSmall } from 'assets/icons';
import { PagesUrl } from 'lib/constants/config.constant';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useWithdrawableOrders from './useWithdrawableOrders';

interface WithdrawButtonProps {
  id?: number;
}
const WithdrawButton: FC<WithdrawButtonProps> = ({ id }) => {
  const withdraw = useWithdrawableOrders();
  const isWithdrawable = withdraw.isWithdrawableOrder(id);
  const navigate = useNavigate();
  const navigateTo = (e: { stopPropagation: Function }) => {
    e.stopPropagation();
    navigate(`${PagesUrl.WITHDRAW}/${id}`);
  };
  return (
    <div>
      {isWithdrawable && (
        <Button onClick={navigateTo}>
          Withdraw <RightArrowSmall />
        </Button>
      )}
    </div>
  );
};

const Button = styled.button`
  cursor: pointer;
  background-color: rgba(163, 153, 246, 0.4);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 0.7rem;
  opacity: 0.9;
  padding-left: 8px;
  border: 1px solid transparent;
  transition: all 0.3s ease-in;
  &:hover {
    border-radius: 2px;
    background-color: rgba(163, 153, 246, 0.8);
  }
`;

export default WithdrawButton;
