import { useState } from 'react'
import { useGetDefiPendingTransactionsQuery, useGetDefiStrategyBalanceQuery, useGetDefiStrategyDetailsQuery, useGetDefiTransactionsInfoQuery, useGetDefiTransactionsQuery, useGetDivestTransactionsInfoQuery } from 'state/store/investApi';

const useStrategy = (strategyIdentifier: string) => {
  const strategyBalance = useGetDefiStrategyBalanceQuery(strategyIdentifier)
  const strategyDetails = useGetDefiStrategyDetailsQuery(strategyIdentifier)
  const [transactionsPage, setTransactionsPage] = useState(1)
  const defiTransactions = useGetDefiTransactionsQuery({page: transactionsPage, strategyIdentifier})
  const transactionsInfo = useGetDefiTransactionsInfoQuery(strategyIdentifier)
  const divestInfo = useGetDivestTransactionsInfoQuery(strategyIdentifier)
  const pendingTransactions = useGetDefiPendingTransactionsQuery(strategyIdentifier)
  
  return {
    strategyBalance,
    strategyDetails,
    defiTransactions,
    pendingTransactions,
    transactionsPage,
    setTransactionsPage,
    transactionsInfo,
    divestInfo
  }
}

export default useStrategy
