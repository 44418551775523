/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ApiError } from '../models';
import { BlockedCountriesResponse } from '../models';
import { UserIdentityInfoRequest } from '../models';
import { UserIdentityInfoResponse } from '../models';
/**
 * IdentityControllerApi - axios parameter creator
 * @export
 */
export const IdentityControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the list of blocked countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockedCountries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/identity/blocked-countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user identity status
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatus: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getStatus.');
            }
            const localVarPath = `/identity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit user's identity to be validated
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submit: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling submit.');
            }
            const localVarPath = `/identity/submit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates user's identity information.
         * @param {UserIdentityInfoRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserInfo: async (body: UserIdentityInfoRequest, token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateUserInfo.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling updateUserInfo.');
            }
            const localVarPath = `/identity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined && token !== null) {
                localVarHeaderParameter['token'] = String(token);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentityControllerApi - functional programming interface
 * @export
 */
export const IdentityControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the list of blocked countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlockedCountries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<BlockedCountriesResponse>>> {
            const localVarAxiosArgs = await IdentityControllerApiAxiosParamCreator(configuration).getBlockedCountries(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get user identity status
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserIdentityInfoResponse>>> {
            const localVarAxiosArgs = await IdentityControllerApiAxiosParamCreator(configuration).getStatus(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Submit user's identity to be validated
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submit(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserIdentityInfoResponse>>> {
            const localVarAxiosArgs = await IdentityControllerApiAxiosParamCreator(configuration).submit(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates user's identity information.
         * @param {UserIdentityInfoRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserInfo(body: UserIdentityInfoRequest, token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserIdentityInfoResponse>>> {
            const localVarAxiosArgs = await IdentityControllerApiAxiosParamCreator(configuration).updateUserInfo(body, token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * IdentityControllerApi - factory interface
 * @export
 */
export const IdentityControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get the list of blocked countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlockedCountries(options?: AxiosRequestConfig): Promise<AxiosResponse<BlockedCountriesResponse>> {
            return IdentityControllerApiFp(configuration).getBlockedCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user identity status
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatus(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserIdentityInfoResponse>> {
            return IdentityControllerApiFp(configuration).getStatus(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit user's identity to be validated
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submit(token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserIdentityInfoResponse>> {
            return IdentityControllerApiFp(configuration).submit(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates user's identity information.
         * @param {UserIdentityInfoRequest} body 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserInfo(body: UserIdentityInfoRequest, token: string, options?: AxiosRequestConfig): Promise<AxiosResponse<UserIdentityInfoResponse>> {
            return IdentityControllerApiFp(configuration).updateUserInfo(body, token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdentityControllerApi - object-oriented interface
 * @export
 * @class IdentityControllerApi
 * @extends {BaseAPI}
 */
export class IdentityControllerApi extends BaseAPI {
    /**
     * 
     * @summary Get the list of blocked countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityControllerApi
     */
    public async getBlockedCountries(options?: AxiosRequestConfig) : Promise<AxiosResponse<BlockedCountriesResponse>> {
        return IdentityControllerApiFp(this.configuration).getBlockedCountries(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get user identity status
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityControllerApi
     */
    public async getStatus(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserIdentityInfoResponse>> {
        return IdentityControllerApiFp(this.configuration).getStatus(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Submit user's identity to be validated
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityControllerApi
     */
    public async submit(token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserIdentityInfoResponse>> {
        return IdentityControllerApiFp(this.configuration).submit(token, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Updates user's identity information.
     * @param {UserIdentityInfoRequest} body 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentityControllerApi
     */
    public async updateUserInfo(body: UserIdentityInfoRequest, token: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserIdentityInfoResponse>> {
        return IdentityControllerApiFp(this.configuration).updateUserInfo(body, token, options).then((request) => request(this.axios, this.basePath));
    }
}
