import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { AssetsControllerApi } from "services";
import {
  AssetHistoricMarketData,
  AssetsAddressResponse,
  HistoricMarketDataResponse,
} from "services/models";
import { Pagination } from "services/models/pagination";
import { sortingSearchBarAssets } from "shared";
import { RootState } from "state/store";
import { Assets } from "state/store/api";

const AssetsService = new AssetsControllerApi();

export const AssetsListSelector = createSelector(
  (state: RootState) => state.assets,
  (assets: AssetsState) => assets.assets
);

export const AssetsSelector = createSelector(
  (state: RootState) => state.assets,
  (assets: AssetsState) => assets.allAsset
);

export const MarketSelector = createDraftSafeSelector(
  (state: RootState) => state.assets,
  (assets: AssetsState) => assets.market
);

export const searchAssetSelector = createSelector(
  (state: any) => state.assets.assets,
  (assets: Assets[]) => assets.filter(({ assetType }) => assetType !== "FC")
);

export const orderedAssets = createSelector(
  (state: any) => state.assets.orderedAssets,
  (orderedAssets: Assets[]) => orderedAssets
);

export const depositCryptoSelector = createSelector(
  (state: any) => state.assets.depositCrypto,
  (depositCrypto: Assets[]) => depositCrypto
);

export const fiatCurrencySelector = createSelector(
  (state: any) => state.assets.convenienceCurrency,
  (convenienceCurrency: Assets[]) => convenienceCurrency
);

export const holdingsAssetsSelector = createSelector(
  (state: any) => state.assets.holdings,
  (holdings: Assets[]) => holdings
);

export const assetAddressSelector = createSelector(
  (state: any) => state.assets.assetAddress,
  (assetAddress: AssetsAddressResponse) => assetAddress
);

export const addToWatchlist = (token: string, idAsset: string) => {
  try {
    AssetsService.addUserAssets(token, idAsset);
    setWatchListAddStatus(true);
  } catch (error) {
    console.warn(error);
    setWatchListAddStatus(false);
  }
};

export const removeFromWatchList = (token: string, idAsset: string) => {
  try {
    AssetsService.deleteUserAssets(token, idAsset);
    setWatchListRemoveStatus(true);
  } catch (error) {
    console.warn(error);
    setWatchListRemoveStatus(false);
  }
};

export const getAllAssets = () => async (dispatch: any, getState: any) => {
  try {
    const {
      auth: { token },
    } = getState();
    dispatch(setLoadingAllAssets(true));
    const response: any = await AssetsService.getAll(
      token,
      undefined,
      undefined,
      "1",
      "2000",
      undefined
    );
    const assets = response?.data?.assets ?? [];
    dispatch(setAllAssets(assets));
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(setLoadingAllAssets(false));
  }
};

export const getOrderedAssetLists =
  (
    sortField: string | undefined,
    sortDirection: string | undefined,
    start: string | undefined,
    recordsPerPage: string | undefined,
    assetType: string | undefined
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      const {
        auth: { token },
      } = getState();

      dispatch(setLoading(true));

      const response: any = await AssetsService.get(
        token,
        sortField,
        sortDirection,
        start,
        recordsPerPage,
        assetType
      );
      dispatch(setOrderedAssets(response?.data));
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getAssetLists =
  (
    sort?: string,
    sortDirection?: string,
    page: string = "1",
    size: string = "10000"
  ) =>
  async (dispatch: any, getState: any) => {
    try {
      const {
        auth: { token },
        assets: { searchBarAsset },
      } = getState();

      dispatch(setLoading(true));

      const response: any = await AssetsService.get(
        token,
        sort,
        sortDirection,
        page,
        size
      );

      dispatch(setAssets(response?.data?.assets));
      !Boolean(searchBarAsset?.length > 0) &&
        dispatch(
          setSearchBarAssets(
            sortingSearchBarAssets(response?.data?.assets || [])
          )
        );
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getAssetDepositCrypto =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const { token } = getState().auth;
      const response: any = await AssetsService.getAssetsByProperty(
        token,
        "deposit_crypto_options",
        "assetLongName",
        "asc",
        "1",
        "1000"
      );
      dispatch(setAssetDepositCrypto(response?.data?.assets));
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getConvenienceCurrency =
  () => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const { token } = getState().auth;
      const { data } = await AssetsService.getAssetsByProperty(
        token,
        "convenience_currency.options",
        "assetLongName",
        "asc",
        "1",
        "1000"
      );

      const assets: Assets[] = data?.assets ?? new Array(0);

      dispatch(setAssetConvenienceCurrency(assets));
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getHoldings = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    const { token } = getState().auth;
    const { data } = await AssetsService.getHoldings(token);
    const assets: Assets[] = data?.assets ?? new Array(0);

    dispatch(setHoldings(assets));
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getFirstHoldings = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    const { token } = getState().auth;
    const { data } = await AssetsService.getHoldings(token);
    const assets: Assets[] = data?.assets ?? new Array(0);

    dispatch(setHoldings(assets));
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const getWatchlists =
  (sortField?: string, sortDirection?: string, page?: string, size?: string) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const { token } = getState().auth;
      const { data } = await AssetsService.getWatchlist(token);
      const assets: Assets[] = data?.assets ?? new Array(0);
      const pagination: Pagination = data?.paginationResponse ?? {};
      dispatch(setWatchlist({ assets, pagination }));
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getAssetAddress =
  (id: string) => async (dispatch: any, getState: any) => {
    try {
      dispatch(setLoading(true));
      const { token } = getState().auth;
      const { data } = await AssetsService.getAssetAddress(token, id);
      const asset: AssetsAddressResponse = data?.address ?? undefined;
      dispatch(setAssetAddress(asset));
    } catch (error) {
      console.warn(error);
    } finally {
      dispatch(setLoading(false));
    }
  };

interface MarketDataParams {
  asset: string;
  interval: "day" | "week" | "month" | "quarter" | "year" | "all_the_time";
}

export const getMarketData = createAsyncThunk(
  "assets/getMarketData",
  async ({ asset, interval }: MarketDataParams, { getState }) => {
    const {
      auth: { token },
    } = getState() as RootState;
    const response = (await AssetsService.getHistoricMarketData(
      token,
      asset,
      interval
    )) as AxiosResponse<HistoricMarketDataResponse>;

    return response.data.marketData;
  }
);

export const getAllHoldings = () => async (dispatch: any, getState: any) => {
  try {
    dispatch(setLoading(true));
    const { token } = getState().auth;
    const { data } = await AssetsService.getHoldings(
      token,
      "allocation",
      "desc",
      "1",
      "1000"
    );
    const assets: Assets[] = data?.assets ?? new Array(0);

    dispatch(setFullHoldings(assets));
  } catch (error) {
    console.warn(error);
  } finally {
    dispatch(setLoading(false));
  }
};

interface AssetsState {
  loading: boolean;
  loadingChart: boolean;
  holdingTotal: number;
  loadingAllAssets: boolean;
  asset: {};
  allAsset: {
    [key: string]: Assets;
  };
  market: {
    [key: string]: Assets;
  };
  assets: Assets[];
  orderedAssets: Assets[];
  orderedAssetsPagination: Pagination;
  holdings: Assets[];
  fullHoldings: Assets[];
  watchlist: { assets: Assets[]; pagination: Pagination };
  depositCrypto: Assets[];
  convenienceCurrency: Assets[];
  assetAddress: AssetsAddressResponse;
  searchBarAsset: Assets[];
  historicMarketData?: AssetHistoricMarketData;
  watchlistAddStatus: boolean;
  watchlistRemoveStatus: boolean;
}

const initialState: AssetsState = {
  loading: false,
  loadingChart: false,
  loadingAllAssets: false,
  holdingTotal: 0,
  asset: {},
  assets: [] as Assets[],
  orderedAssets: [],
  orderedAssetsPagination: {},
  holdings: [] as Assets[],
  fullHoldings: [] as Assets[],
  watchlist: { assets: [] as Assets[], pagination: {} as Pagination },
  depositCrypto: [] as Assets[],
  convenienceCurrency: [] as Assets[],
  assetAddress: {} as AssetsAddressResponse,
  historicMarketData: undefined,
  watchlistAddStatus: false,
  watchlistRemoveStatus: false,
  searchBarAsset: [] as Assets[],
};

export const assetsSlice = createSlice({
  name: "assets",
  initialState,
  reducers: {
    reset: () => initialState,
    setOrderedAssets: (
      state,
      action: PayloadAction<{
        assets: Assets[];
        paginationResponse: Pagination;
      }>
    ) => {
      state.orderedAssets = action.payload.assets;
      state.orderedAssetsPagination = action.payload.paginationResponse;
    },
    setAssets: (state, action: PayloadAction<Assets[]>) => {
      state.assets = action.payload;
    },
    setSearchBarAssets: (state, action: PayloadAction<Assets[]>) => {
      state.searchBarAsset = action.payload;
    },
    setAssetDepositCrypto: (state, action: PayloadAction<Assets[]>) => {
      state.depositCrypto = action.payload;
    },
    setAssetConvenienceCurrency: (state, action: PayloadAction<Assets[]>) => {
      state.convenienceCurrency = action.payload;
    },
    setHoldings: (state, action: PayloadAction<Assets[]>) => {
      state.holdings = action.payload;
    },
    setFullHoldings: (state, action: PayloadAction<Assets[]>) => {
      state.fullHoldings = action.payload;
    },
    setWatchlist: (
      state,
      action: PayloadAction<{ assets: Assets[]; pagination: Pagination }>
    ) => {
      state.watchlist = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setLoadingAllAssets: (state, action: PayloadAction<boolean>) => {
      state.loadingAllAssets = action.payload;
    },
    setHoldingsTotal: (state, action: PayloadAction<number>) => {
      state.holdingTotal = action.payload;
    },
    setAssetAddress: (state, action: PayloadAction<AssetsAddressResponse>) => {
      state.assetAddress = action.payload;
    },
    setWatchListAddStatus: (state, action: PayloadAction<boolean>) => {
      state.watchlistAddStatus = action.payload;
    },
    setWatchListRemoveStatus: (state, action: PayloadAction<boolean>) => {
      state.watchlistRemoveStatus = action.payload;
    },
    setAllAssets: (state, action: PayloadAction<Assets[]>) => {
      state.allAsset = action.payload;
    },
    setMarket: (state, action: PayloadAction<Assets[]>) => {
      state.market = action.payload;
    },
  },
  extraReducers: {
    [getMarketData.pending.toString()]: (state) => {
      if (state.historicMarketData) {
        state.loadingChart = true;
      } else {
        state.loading = true;
      }
    },
    [getMarketData.fulfilled.toString()]: (
      state,
      { payload }: PayloadAction<AssetHistoricMarketData>
    ) => {
      state.historicMarketData = payload;
      state.loadingChart = false;
      state.loading = false;
    },
    [getMarketData.rejected.toString()]: (state) => {
      state.loadingChart = false;
      state.loading = false;
    },
  },
});

export const {
  setAssets,
  setOrderedAssets,
  setAssetDepositCrypto,
  setAssetConvenienceCurrency,
  setHoldings,
  setFullHoldings,
  setWatchlist,
  setLoading,
  setHoldingsTotal,
  setAssetAddress,
  setWatchListAddStatus,
  setWatchListRemoveStatus,
  setSearchBarAssets,
  setAllAssets,
  setMarket,
  setLoadingAllAssets,
} = assetsSlice.actions;

export const assetsReducer = assetsSlice.reducer;
