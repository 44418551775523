import styled, { keyframes } from "styled-components";
import { palette, zIndex } from "../../../lib/theme";

export const toastAnimation = keyframes`
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
`;

export const ToastsContainter = styled.div`
  position: fixed;
  box-sizing: border-box;
  z-index: ${zIndex.toast};
  top: 20px;
  right: 20px;
`;

export const Wrapper = styled.div`
  width: 444px;
  margin: 1.75rem auto;
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid;
  @media print {
    display: none !important;
  }
  background: ${palette.darkBackgroundContrast.light15};
  border: double 1px transparent;
  background-image: linear-gradient(
      ${palette.black.popUpBackground},
      ${palette.black.popUpBackground}
    ),
    radial-gradient(
      circle at top right,
      ${palette.darkBackgroundContrast.white} -14.9%,
      ${palette.darkBackgroundContrast.light50} -14.89%,
      ${palette.darkBackgroundContrast.light0} 119.69%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  z-index: ${zIndex.toast};
  animation: ${toastAnimation} 0.7s;
`;

export const Close = styled.button`
  position: absolute;
  top: 14px;
  right: 10px;
  width: 18px;
  height: 18px;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 52px 16px 14px;
  font-size: 14px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Title = styled.p`
  padding: 0px 0px 0px 14px;
  margin: 0;
  font-weight: 700;
  color: ${(props) => props.color};
`;
export const Content = styled.p`
  padding: 4px 0px 0px 35px;
  margin: 0;
`;
