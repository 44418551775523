let USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const getUsDollar = (value: number | string = 0) => {
  return USDollar.format(+value)
}

export default getUsDollar
