export enum MarketInfoEnum {
    LOADING = 'loading_info',
    GET = 'get_info',
    ERROR = 'error_info',
}

export enum MarketQuotesHistoricalEnum { 
    LOADING = 'loading_quotes_historical',
    GET = 'get_quotes_historical',
    ERROR = 'error_quotes_historical',
}

export enum MarketQuotesLatestEnum { 
    LOADING = 'loading_quotes_latest',
    GET = 'get_quotes_latest',
    ERROR = 'error_quotes_latest',
}

export enum MarketListingLatestEnum { 
    LOADING = 'loading_listing_latest',
    GET = 'get_listing_latest',
    ERROR = 'error_listing_latest',
} 

export enum MarketListingGainerEnum { 
    LOADING = 'loading_listing_gainer',
    GET = 'get_listing_gainer',
    ERROR = 'error_listing_gainer',
} 

export enum MarketListingLooserEnum { 
    LOADING = 'loading_listing_looser',
    GET = 'get_listing_looser',
    ERROR = 'error_listing_looser',
} 

export enum MarketListingLatestVolumenEnum { 
    LOADING = 'loading_listing_volumen',
    GET = 'get_listing_volumen',
    ERROR = 'error_listing_volumen',
} 
export enum CoinIdentifierTypesEnum {
    ID = 'id',
    SYMBOL = 'symbol',
    SLUG = 'slug'
}