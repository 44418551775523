import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const AwaitingContractApproval: FC<ISVG> = ({
  size = 120,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="120"
    height="121"
    viewBox="0 0 120 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M86.5584 96.3369H33.5225V22.1953H74.0185C75.1177 22.1953 76.1712 22.6321 76.948 23.4089L85.3448 31.8057C86.1216 32.5825 86.5584 33.6369 86.5584 34.7353V96.3369V96.3369Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.2832 26.2695V32.9839C73.2832 34.8143 74.7672 36.2983 76.5976 36.2983H86.5584"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M95.4632 14.6099C72.7104 -3.01808 39.8672 -1.40609 18.9808 19.4811C-1.90555 40.3675 -3.51837 73.2106 14.1096 95.9634"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.9019 105.898C46.6747 124.05 79.9362 122.597 101.016 101.517C122.096 80.4368 123.549 47.1751 105.398 24.4023"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M114.774 26.7464L105.398 24.4023L104.226 34.9504"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.74707 93.6056L14.1231 95.9495L15.2951 85.4023"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.8769 69.2784V66.7921C58.8769 58.0417 70.0273 57.4529 70.0273 48.9121C70.0273 35.9121 50.9673 37.2585 50.9673 48.9121"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.877 79.2619C60.2502 79.2619 61.3634 78.1487 61.3634 76.7755C61.3634 75.4023 60.2502 74.2891 58.877 74.2891C57.5038 74.2891 56.3906 75.4023 56.3906 76.7755C56.3906 78.1487 57.5038 79.2619 58.877 79.2619Z"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AwaitingContractApproval;
