import React, { FC } from 'react';
import ISVG from '@types/icons/svg';

export const QuestionCircleBig: FC<ISVG> = ({ size = 24, width = size, height = size, color = 'white', svgProps }) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...svgProps}>
    <path
      d='M12 1C5.935 1 1 5.935 1 12C1 18.065 5.935 23 12 23C18.065 23 23 18.065 23 12C23 5.935 18.065 1 12 1ZM12 21C7.037 21 3 16.963 3 12C3 7.037 7.037 3 12 3C16.963 3 21 7.037 21 12C21 16.963 16.963 21 12 21Z'
      fill={color}
    />

    <path d='M12 16.5C11.448 16.5 11 16.947 11 17.5V18C11 18.553 11.448 19 12 19C12.552 19 13 18.553 13 18V17.5C13 16.947 12.552 16.5 12 16.5Z' fill={color} />

    <path
      d='M12 5C9.243 5 7 7.019 7 9.5C7 10.053 7.448 10.5 8 10.5C8.552 10.5 9 10.053 9 9.5C9 8.121 10.346 7 12 7C13.654 7 15 8.121 15 9.5C15 10.879 13.654 12 12 12C11.448 12 11 12.447 11 13V14.5C11 15.053 11.448 15.5 12 15.5C12.552 15.5 13 15.053 13 14.5V13.909C15.279 13.491 17 11.673 17 9.5C17 7.019 14.757 5 12 5Z'
      fill={color}
    />
  </svg>
);

export default QuestionCircleBig;
