import React, { FC } from "react";
import { motion } from "framer-motion";

const AnimatedWidgetStep: FC<{ name: string, height?:string }> = ({ name, children, height }) => {
  return (
    <motion.div
      key={name}
      initial={{ x: 1000, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{
        ease: [0.33, 1, 0.61, 1],
        duration: 0.4,
      }}
      exit={{ x: 1000, opacity: 0 }}
      style={{ height: height ? height : "100%" }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedWidgetStep;
