import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const LeftArrowBig: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
    <path
      d='M15.0008 22.9996C14.6858 22.9996 14.3748 22.8516 14.1807 22.5726L7.18075 12.5726C6.93975 12.2286 6.93975 11.7706 7.18075 11.4266L14.1807 1.42662C14.4967 0.973618 15.1198 0.864616 15.5738 1.18062C16.0268 1.49762 16.1368 2.12061 15.8198 2.57361L9.22074 11.9996L15.8198 21.4266C16.1358 21.8786 16.0268 22.5026 15.5738 22.8196C15.3988 22.9406 15.1988 22.9996 15.0008 22.9996Z'
      fill={color}
    />
  </svg>
);

export default LeftArrowBig;