import React, { FC, useEffect } from "react"
import Layout from "components/templates/Layout"
import alternativeGridSystem from "lib/theme/alternativeGridSystem"
import { GridItemSetting } from "components/templates/Widget/interfaces"
import Portfolio from "./Portfolio"
import LoanDetails from "./LoanDetails"
import Transactions from "./Transactions"
import {
  useGetLoanSummaryQuery,
  useGetOrdersSummaryQuery,
} from "state/store/api"
import TokenSummary from "./TokenSummary"
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'state/slice/auth.slice'
import { Navigate, useNavigate } from 'react-router-dom'
import { PagesUrl } from 'lib/constants/config.constant'
import usePermissions from 'shared/usePermissions'

export interface Sizes {
  xs?: GridItemSetting
  sm?: GridItemSetting
  md?: GridItemSetting
  lg?: GridItemSetting
  xl?: GridItemSetting
  xxl?: GridItemSetting
}

const Borrow: FC = () => {
  const { data: orders, isLoading, isFetching } = useGetLoanSummaryQuery()

  const user = useSelector(selectCurrentUser)
  const hasProducts = user?.organization?.products?.length
  const hasBorrow = hasProducts && user?.organization?.products?.includes('Borrow')
  const navigate = useNavigate()
  const permissions = usePermissions()
  useEffect(() => {
    if (permissions.isAccountManager) return
    if (hasProducts) {
      if (!hasBorrow) {
        navigate(PagesUrl.BOOST)
      }
    }
  }, [hasProducts, hasBorrow, permissions])

  return (
    <Layout customGrid={alternativeGridSystem}>
      <Portfolio
        isLoading={isLoading}
        isFetching={isFetching}
        orders={orders ?? {}}
      />
      <TokenSummary
        isLoading={isLoading}
        isFetching={isFetching}
        orders={orders ?? {}}
      />
      <LoanDetails />
      <Transactions />
    </Layout>
  )
}

export default Borrow
