import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Select from 'components/molecules/Select';
import { TypesNamesEnum } from 'enums/Button.enum';
import React, { FC, useEffect, useState } from 'react';
import onboardingApi, { CustodyUserRequest } from 'state/store/onboardingApi'
import styled from 'styled-components';

const companyTypes = [
  { id: 'LLC', label: 'LLC' },
  { id: 'PARTNERSHIP', label: 'Partnership' },
  { id: 'CORPORATION', label: 'Corporation' },
  { id: 'TRUST', label: 'Trust' },
];

interface Props {
  information: CustodyUserRequest;
  back: () => void;
  next: () => void;
}
const RegisterCompanyForm: FC<Props> = ({ information, back, next }) => {
  const [isFormValid, setIsFormValid] = useState(false);

  const [companyType, setCompanyType] = useState('');

  const validatePhoneNumber = (phone: string) => {
    const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{2,3})[-. )]*(\d{3,4})[-. ]*(\d{3,4})(?: *x(\d+))?\s*$/;
    return phoneRegex.test(phone);
  }

  const validateForm = () => {
    let valid = true;
    if (!companyType) {
      valid = false;
    }
    if (!name) {
      valid = false;
    }
    if (!phone || phone.length < 4) {
      valid = false;
      setPhoneError('');
    } else if (!validatePhoneNumber(phone)) {
      valid = false;
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }
    setIsFormValid(valid);
  }
  const [name, setName] = useState('');

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    validateForm();
  }, [companyType, name, phone])
  
  const { maxAccreditedValue, minAccreditedValue, email, organizationType, usResident } = information;

  const [createCompany, createCompanyMeta] = onboardingApi.useCreateInstitutionalUserMutation();
  const loading = createCompanyMeta.isLoading;

  const createCompanyAction = async () => {
    try {
      await createCompany({
        organizationType,
        email,
        maxAccreditedValue,
        minAccreditedValue,
        usResident,
        organizationFullName: name,
        organizationPhoneNumber: phone,
        institutionType: companyType,
      }).unwrap();
      next();
    } catch (e) {}
  };


  return (
    <RegisterCompanyFormBox>
      <p>Company Registration Form</p>
      <Input
        label='Email'
        inputProps={{
          value: information.email,
          id: 'email',
          name: 'email',
          type: 'email',
        }}
        readOnly
      />
      <div>
        <Select
          data={companyTypes}
          label='Company Type'
          onSelect={(item) => setCompanyType(item.id)}
        ></Select>
      </div>
      <div>
        <Input
          label='Company Name'
          inputProps={{
            value: name,
            id: 'name',
            name: 'name',
            onChange: (e: any) => setName(e.target.value),
          }}
        />
      </div>
      <Input
        label='Phone Number'
        inputProps={{
          value: phone,
          id: 'phone',
          name: 'phone',
          onChange: (e: any) => setPhone(e.target.value),
        }}
        errorMessage={phoneError}
      />

      <Grid>
        <Button disabled={loading} buttonType={TypesNamesEnum.SECONDAY} label='Back' onClick={back} type='button' />
        <Button disabled={loading || !isFormValid} isLoading={loading} buttonType={TypesNamesEnum.ACCENT} label='Submit' onClick={createCompanyAction} type='submit' />
      </Grid>
    </RegisterCompanyFormBox>
  );
};

const Grid = styled.div`
  display: grid;
  gap: 10px;
  height: 50px;
  margin-top: 20px;
  grid-template-columns: 1fr 1fr;
  Button {
    font-weight: 400;
    font-size: 1.1rem;
  }
`;

const RegisterCompanyFormBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export default RegisterCompanyForm;
