import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Block: FC<ISVG> = ({ size = 16, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_373_10299)">
      <path
        d="M3.40401 3.40381L12.596 12.5962M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8Z"
        stroke={color}
        strokeOpacity="0.7"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_373_10299">
        <rect width="16" height="16" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export default Block;
