import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Send: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C1.34315 0 0 1.34315 0 3V16C0 17.6569 1.34315 19 3 19H16C17.6569 19 19 17.6569 19 16V12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12V16C17 16.5523 16.5523 17 16 17H3C2.44772 17 2 16.5523 2 16V3C2 2.44772 2.44772 2 3 2H8C8.55228 2 9 1.55228 9 1C9 0.447715 8.55228 0 8 0H3ZM16.2072 0.278957C15.8166 -0.0929857 15.1834 -0.0929857 14.7928 0.278957C14.4024 0.650881 14.4024 1.25389 14.7928 1.62581L16.5858 3.33334H13.5C10.4624 3.33334 8 5.67852 8 8.57143V9V9.04762V13C8 13.5523 8.44771 14 9 14C9.55228 14 10 13.5523 10 13V9V8.9993V8.57143C10 6.73048 11.567 5.2381 13.5 5.2381H16.5858L14.7928 6.94562C14.4024 7.31754 14.4024 7.92055 14.7928 8.29248C15.1834 8.66442 15.8166 8.66442 16.2072 8.29248L19.7114 4.95511C19.8052 4.86473 19.876 4.76094 19.9242 4.65027C19.973 4.53798 20 4.41484 20 4.28572C20 4.15659 19.973 4.03345 19.9242 3.92116C19.8754 3.80884 19.803 3.7036 19.7072 3.61229L16.2072 0.278957Z"
      fill={color}
    />
  </svg>
);

export default Send;
