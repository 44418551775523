import React, { FC }from 'react';
import ISVG from '@types/icons/svg';

export const Tick: FC<ISVG> = ({
  size = 16,
  width = size,
  height = size,
  color,
  svgProps
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...svgProps}
  >
    <path
      d="M1 7.2998L5.89999 12.2002L15 3.7998" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"
    />
  </svg>
);

export default Tick;
