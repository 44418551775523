import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const AwaitingFunds: FC<ISVG> = ({
  size = 120,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="120"
    height="121"
    viewBox="0 0 120 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.0913 116.019C27.0913 95.7156 34.0313 71.4036 53.8601 59.506C30.0649 42.6516 27.0913 24.2876 27.0913 3.98438"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M94.5095 116.019C94.5095 95.7156 87.5695 71.4036 67.7407 59.506C91.5359 42.6516 94.5095 24.2876 94.5095 3.98438"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.125 2H98.4754"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.125 118H98.4754"
      stroke="white"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M41.5439 113.164L60.8 93.0703L80.0568 113.164"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.7998 93.072V56.2344"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.3574 30.2773H84.2422"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.1953 113.164H65.8233"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M70.0063 113.164H83.4055"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M84.2422 30.2773C79.219 45.3477 60.7998 56.2317 60.7998 56.2317C60.7998 56.2317 43.2182 46.1853 37.3574 30.2773"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AwaitingFunds;
