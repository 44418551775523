import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const ToTheMoonDisabled: FC<ISVG> = ({
  size = 44,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0001 4.65829C17.4039 4.66349 12.9974 6.49225 9.74732 9.74337C6.49728 12.9945 4.66913 17.4025 4.66394 22.0002C4.66783 26.5989 6.49541 31.0081 9.74559 34.2603C12.9958 37.5125 17.403 39.3419 22.0001 39.3471C26.5972 39.3419 31.0044 37.5125 34.2546 34.2603C37.5048 31.0081 39.3324 26.5989 39.3363 22.0002C39.3311 17.4025 37.5029 12.9945 34.2529 9.74337C31.0028 6.49225 26.5963 4.66349 22.0001 4.65829Z"
      fill="#C0C0C0"
    />
    <path
      d="M22.8239 3.00504C26.5537 3.16842 30.153 4.42576 33.1737 6.6205C36.1944 8.81524 38.5029 11.8504 39.8118 15.348L42.6611 14.4208C41.1556 10.3221 38.4657 6.76373 34.9334 4.19815C31.4011 1.63256 27.186 0.175609 22.8239 0.0125122V3.00504Z"
      fill="#C0C0C0"
    />
    <path
      d="M40.3169 16.9129C40.7749 18.5699 41.0074 20.2811 41.0084 22.0002C41.0112 24.8622 40.3668 27.6877 39.1235 30.2653C37.8801 32.8428 36.0701 35.1057 33.8287 36.8844L35.5844 39.3079C38.2086 37.2513 40.3302 34.624 41.7883 31.6253C43.2464 28.6265 44.0027 25.3349 43.9999 22.0002C44.0008 19.9678 43.7186 17.9452 43.1613 15.9907L40.3169 16.9129Z"
      fill="#C0C0C0"
    />
    <path
      d="M4.18806 15.348C5.49694 11.8504 7.80547 8.81524 10.8261 6.6205C13.8468 4.42576 17.4462 3.16842 21.176 3.00504V0.0125122C16.8139 0.175609 12.5987 1.63256 9.06643 4.19815C5.53414 6.76373 2.84426 10.3221 1.33875 14.4208L4.18806 15.348Z"
      fill="#C0C0C0"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 39.3471C22.0001 39.3471 22.0001 39.3471 22.0002 39.3471C26.5973 39.3419 31.0046 37.5125 34.2547 34.2603C37.5049 31.0081 39.3325 26.5989 39.3364 22.0002C39.3312 17.4025 37.503 12.9945 34.253 9.74337C31.003 6.49225 26.5965 4.66349 22.0002 4.65829C22.0001 4.65829 22.0001 4.65829 22 4.65829V39.3471Z"
      fill="#9D9D9D"
    />
    <path
      d="M32.5193 37.8557C29.4094 39.922 25.7577 41.0213 22.0244 41.015C18.291 41.0213 14.6393 39.922 11.5295 37.8557L9.76892 40.2792C13.3921 42.7189 17.6618 44.019 22.0293 44.0125C26.3968 44.019 30.6665 42.7189 34.2897 40.2792L32.5193 37.8557Z"
      fill="#C0C0C0"
    />
    <path
      d="M10.1712 36.8844C7.92988 35.1057 6.11981 32.8428 4.87647 30.2653C3.63313 27.6877 2.98873 24.8622 2.99153 22.0002C2.9925 20.2811 3.22509 18.5699 3.68302 16.9129L0.838612 15.9907C0.281345 17.9452 -0.000901447 19.9678 7.65766e-06 22.0002C-0.0027763 25.3349 0.753559 28.6265 2.21166 31.6253C3.66977 34.624 5.79135 37.2513 8.41552 39.3079L10.1712 36.8844Z"
      fill="#C0C0C0"
    />
    <path d="M22.25 14.1117H21.2692V27.0336H22.25V14.1117Z" fill="white" />
    <path
      d="M29.6407 14.1117H21.6519V18.9194H29.6407L25.4771 16.5156L29.6407 14.1117Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.1867 29.2548C32.9136 27.1501 27.8803 25.8022 22.2354 25.8022C16.3855 25.8022 11.1925 27.2498 7.93457 29.487C8.66908 30.8664 9.60861 32.1426 10.7335 33.268C13.7216 36.2575 17.7736 37.9392 22 37.944C26.2265 37.9392 30.2784 36.2575 33.2665 33.268C34.4543 32.0797 35.4354 30.7233 36.1867 29.2548Z"
      fill="white"
    />
  </svg>
);

export default ToTheMoonDisabled;
