import React, { FC } from "react";
import ISVG from "@types/icons/svg";
import { palette } from "lib/theme";

export const Download: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color = `${palette.accent.light}`,
  bgColor,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M9.707 14.2931L11 15.586V3.00002H8C7.448 3.00002 7 2.55302 7 2.00002C7 1.44703 7.448 1.00002 8 1.00002H16C16.552 1.00002 17 1.44703 17 2.00002C17 2.55302 16.552 3.00002 16 3.00002H13V15.586L14.293 14.2931C14.488 14.0981 14.744 14 15 14C15.256 14 15.512 14.0981 15.707 14.2931C16.098 14.6841 16.098 15.316 15.707 15.707L12.707 18.707C12.317 19.098 11.683 19.098 11.293 18.707L8.293 15.707C7.902 15.316 7.902 14.6841 8.293 14.2931C8.683 13.9021 9.317 13.9021 9.707 14.2931Z"
      fill={color}
    />
    <path
      d="M17 8.00024H15C14.448 8.00024 14 8.44724 14 9.00024C14 9.55324 14.448 10.0002 15 10.0002H17C19.206 10.0002 21 11.7942 21 14.0002V17.0002C21 19.2062 19.206 21.0002 17 21.0002H7C4.794 21.0002 3 19.2062 3 17.0002V14.0002C3 11.7942 4.794 10.0002 7 10.0002H9C9.552 10.0002 10 9.55324 10 9.00024C10 8.44724 9.552 8.00024 9 8.00024H7C3.691 8.00024 1 10.6912 1 14.0002V17.0002C1 20.3092 3.691 23.0002 7 23.0002H17C20.309 23.0002 23 20.3092 23 17.0002V14.0002C23 10.6912 20.309 8.00024 17 8.00024Z"
      fill={color}
    />
  </svg>
);

export default Download;
