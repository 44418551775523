import React, { useEffect, useMemo, useRef, useState } from "react";
import Modal from 'components/organisms/Modal';
import bigDecimal from "js-big-decimal";
import StrongText from "components/atoms/StrongText";
import { AaveInformationMapper, BalanceModalMapper, ErrorMapper, isCollateralRemoveOrPrincipalBorrow, loanTransactionMapper, Operation, ReviewModalMapper } from "pages/DefiBorrow/DefiBorrow.constants";
import styled from "styled-components";
import LoadingStage from "pages/LoadingStage";
import Tooltip from "components/atoms/Tooltip";
import { Info } from "assets/icons";
import { ValueInput } from "components/atoms/ValueInput/ValueInput";
import { ErrorMessage } from "components/pages/custody/Custody";
import ReviewButton from "components/atoms/ReviewButton";
import FormInputBox from "components/atoms/FormInputBox";
import Link from "components/atoms/Link";
import { openInNewTab } from "shared/openInNewTab";
import { palette } from "lib/theme";
import { SelectedAsset } from "interfaces/SelectedAsset.interface";
import useAssets from "shared/useAssets";
import HealthFactorMeter from "components/molecules/HealthFactorMeter";
import { useGetLoanHealthQuery, useGetLoanInfoQuery } from "state/store/loanApi";
import GetEstimatedFee from "components/molecules/GetEstimatedFee/GetEstimatedFee";

interface OperationModalProps {
    openModal: boolean;
    selectedAsset: SelectedAsset
    investmentAmount: string
    isPrincipal: boolean
    operation: Operation
    setInvestmentAmount: (value: string) => void
    handleOperationModalClose: () => void
    handleClick: () => void
}
  
const LoanOperationModal = ({openModal, selectedAsset, investmentAmount, isPrincipal, operation, setInvestmentAmount, handleOperationModalClose, handleClick}: OperationModalProps) => {
    const spanDollarPrice = useRef<HTMLSpanElement>(null)
    const [minError, setMinError] = useState(false)
    const [maxError, setMaxError] = useState(false);
    const [nextHealthFactorError, setnextHealthFactorError] = useState(false);
    const [currentHealthFactorError, setCurrentHealthFactorError] = useState(false);
    const [feeError, setFeeError] = useState(false);

    const { getPriceFormattedI } = useAssets();
    const loanHealth = useGetLoanHealthQuery({
      amount: new bigDecimal(investmentAmount).getValue(), 
      asset: selectedAsset.asset?.identifier as string, 
      network: selectedAsset.details.network, 
      transactionType: loanTransactionMapper[operation][isPrincipal ? 'principal' : 'collateral']
    })
  
    const loanInfo = useGetLoanInfoQuery({
      asset: selectedAsset?.asset?.identifier as string,
      network: selectedAsset.details.network,
      transactionType: loanTransactionMapper[operation][isPrincipal ? 'principal' : 'collateral']
    });
    
    const maximumAmount = useMemo(() => {
      if(loanInfo.data) {
        const {maxTransactionAmount} = loanInfo.data;
        const maxDueToFee = new bigDecimal(maxTransactionAmount.amount)
        return maxDueToFee;
      }
      return new bigDecimal(0);
    }, [loanInfo.data]);
  
    const minimumAmount = useMemo(() => {
      if(loanInfo.data) {
        const {minimumTransactionAmount} = loanInfo.data;
        const minimumDueToFee = new bigDecimal(minimumTransactionAmount.amount)
        return minimumDueToFee;
      }
      return new bigDecimal(0);
    }, [loanInfo.data]);

    useEffect(() => {
      setnextHealthFactorError(loanHealth?.data?.nextHealthFactor.status === "DANGER");
    }, [loanHealth.data]);

    useEffect(() => {
      if (loanInfo?.data?.availableAmountInFeeAsset) {
        setFeeError(loanInfo?.data?.availableAmountInFeeAsset.amount 
          < loanInfo?.data?.totalEstimatedFeeAmount.amount);
      }
    }, [loanInfo.data]);

    useEffect(() => {
      if (isCollateralRemoveOrPrincipalBorrow(isPrincipal ? 'principal' : 'collateral', operation)) {
        setCurrentHealthFactorError(loanHealth?.data?.currentHealthFactor.status === "DANGER");
      }
    }, [loanHealth.data, isPrincipal, operation]);
  
    useEffect(() => {
      setMinError(investmentAmount !== '0' && new bigDecimal(investmentAmount).compareTo(minimumAmount) < 0); 
      setMaxError(new bigDecimal(investmentAmount).compareTo(maximumAmount) > 0);
    }, [investmentAmount, minimumAmount, maximumAmount])

    const changeInvestmentAmount = (value: string) => {
      const num = value.replace(/[^\.0-9]/g, '');
      setInvestmentAmount(num);
    }
  
    const setMax = () => {
      if(maximumAmount.compareTo(new bigDecimal(0)) > 0) {
        setInvestmentAmount(maximumAmount.getValue());
      }
    };
  
    return (
      <Modal visible={openModal} onClose={() => handleOperationModalClose()} header={`${ReviewModalMapper[isPrincipal ? "principal" : "collateral"][operation]} ${!isPrincipal ? "Collateral" : "Asset"}`} >
        <ModalWrapper>
          <Header>
            <PageTitle>
                {ReviewModalMapper[isPrincipal ? "principal" : "collateral"][operation]} {selectedAsset?.asset?.longName} ({selectedAsset?.asset?.name})
            </PageTitle>
          </Header>
          {(loanInfo.isLoading) && 
            <div id="modal-loader">
              <LoadingStage loadingText=' ' />
            </div>
          }
          {!loanInfo.isLoading && (
            <Content>
              <FormInformation>
                <p>
                    {`${AaveInformationMapper[isPrincipal ? "principal" : "collateral"][operation]}`} For more information, please visit {' '}
                    <Link
                        label='aave'
                        onClick={() => {
                        openInNewTab(`https://aave.com/faq`);
                        }}
                    />
                </p>
                <Space />
                <p>
                  {`${BalanceModalMapper[isPrincipal ? "principal" : "collateral"][operation]}`} is <StrongText>{getPriceFormattedI(loanInfo?.data?.totalAmountInAsset?.asset, loanInfo?.data?.totalAmountInAsset?.amount)}</StrongText>
                  {' '}({loanInfo?.data?.availableAmountValue.amount} {loanInfo?.data?.availableAmountValue.asset})
                  <Tooltip text={`Your balance available for ${ReviewModalMapper[isPrincipal ? "principal" : "collateral"][operation].toLowerCase()} may be lower than the actual account balance because of estimated network fee as well as management fee and gas fee reserve requirements`}>
                    <strong> <Info color={`${palette.white.main}`} size={12} /></strong>
                  </Tooltip>
                </p>
                <p>
                  Minimum amount is <StrongText >{getPriceFormattedI(loanInfo?.data?.minimumTransactionAmount.asset, minimumAmount.getValue())}</StrongText>
                </p>
                <p>
                  Maximum amount available for {`${ReviewModalMapper[isPrincipal ? "principal" : "collateral"][operation].toLowerCase()}`} is <StrongText>{getPriceFormattedI(selectedAsset?.asset?.identifier, maximumAmount.getValue())}</StrongText> 
                </p>
                <Space />
                <FormInputBox>
                  <p>{`${ReviewModalMapper[isPrincipal ? "principal" : "collateral"][operation]}`} Amount
                    <Tooltip text='Actual gas fee may be different from the estimate below. Therefore, Aave may receive a slightly different amount.'>
                        <strong>{' '}<Info color={`${palette.white.main}`} size={12} /></strong>
                    </Tooltip>
                  </p> 
                  <ValueInput 
                    assetName={selectedAsset?.asset?.name}
                    changeInvestmentAmount={changeInvestmentAmount}
                    dollarPrice={"-"}
                    investmentAmount={investmentAmount}
                    renderMaxButton={true}
                    setMax={setMax}
                    spanDollarPrice={spanDollarPrice}
                    error={minError || maxError}
                  />
                </FormInputBox>
                <GetEstimatedFee totalEstimatedFeeAmount={loanInfo?.data?.totalEstimatedFeeAmount} totalEstimatedFeeInConvenience={loanInfo?.data?.totalEstimatedFeeInConvenience} />
                <Space />
                {loanHealth.isLoading && <LoadingStage loadingText='' />}
                {!loanHealth.isLoading && loanHealth.data && <HealthFactorMeter loanHealth={loanHealth.data} />}
  
              </FormInformation>
              <RightAlignedBox>
                <ReviewButton 
                  handleOnClick={() => handleClick()}
                  isDisabled={minError || maxError || nextHealthFactorError || currentHealthFactorError || investmentAmount === '0' || loanHealth.isFetching}
                  text='Review'
                />
              </RightAlignedBox>
              {minError && <ErrorMessage>Your invest amount has to be equal or greater than the minimum invest value of {getPriceFormattedI(selectedAsset?.asset?.identifier, minimumAmount.getValue())}</ErrorMessage>}
              {maxError && <ErrorMessage>Exceeds maximum amount of {getPriceFormattedI(selectedAsset?.asset?.identifier, maximumAmount.getValue())}</ErrorMessage>}
              {nextHealthFactorError && !currentHealthFactorError && <ErrorMessage>This {ErrorMapper[isPrincipal ? "principal" : "collateral"][operation]} will increase the risk of collateral liquidation. Consider using a smaller amount to maintain health of your loan.</ErrorMessage>}
              {currentHealthFactorError && <ErrorMessage>You are unable to {ErrorMapper[isPrincipal ? "principal" : "collateral"][operation]} {isPrincipal ? "principal" : "collateral"} because health factor of your loan is too low and puts your collateral at risk of getting liquidated.</ErrorMessage>}
              {feeError && <ErrorMessage> This transaction request will fail since the SMA account balance available for fee is lower than the estimated network
                fee. Please add funds to fee asset before retrying the transaction</ErrorMessage>}
            </Content>
          )}
        </ModalWrapper>
        
      </Modal>
    )
}

const FormInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const RightAlignedBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ModalWrapper = styled.div`
  padding: 16px;
`

const PageTitle = styled.h2`
  font-weight: 600;
  color: #ffffff;
  font-size: 18px;
  line-height: 130%;
  letter-spacing: -0.5px;
  margin-bottom: 30px;
`;

const Content = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
`;

const Space = styled.div`
  margin-bottom: 6px;
`

export default LoanOperationModal