import useUserConvenienceCurrency from "hooks/useUserConvenienceCurrency";
import React, { FC } from "react";
import NumberFormatter from "./NumberFormatter";

const Currency: FC<{
  value: any;
  maximumFractionDigits?: number;
  minimunFractionDigits?: number;
  showUserCurrency?: boolean;
  avoidDecimalValue?: boolean;
}> = ({
  value,
  maximumFractionDigits = 2,
  minimunFractionDigits = 2,
  showUserCurrency = false,
  avoidDecimalValue = false,
}) => {
  const { asset } = useUserConvenienceCurrency();
  return (
    <span>
      {showUserCurrency ? asset?.symbol || "" : "$"}
      <NumberFormatter
        value={value}
        maximumFractionDigits={maximumFractionDigits}
        minimunFractionDigits={minimunFractionDigits}
        avoidDecimalValue={avoidDecimalValue}
        stylenumber="decimal"
      />
    </span>
  );
};

export default Currency;
