import { useState, useEffect } from 'react'
import { CustodyBalance, useGetCustodyBalanceQuery } from 'state/store/custodyApi'

const useBalance = () => {
  const [balance, setBalance] = useState<CustodyBalance[]>([])
  const [loadingBalance, setLoadingBalance] = useState(true)

  const getCustodyBalance = useGetCustodyBalanceQuery(null, { pollingInterval: 60000 })

  useEffect(() => {
    setLoadingBalance(getCustodyBalance.isLoading)
  }, [getCustodyBalance])

  useEffect(() => {
    if(getCustodyBalance.currentData) {
      const balance = [...getCustodyBalance.currentData]
      const sortByAmount = !!balance.filter(a => a.availableValue?.amount).length
      if(sortByAmount) {
        balance.sort((b1, b2) => +b2.availableValue?.amount - +b1.availableValue?.amount)
      } else {
        balance.sort((b1, b2) => +b2.availableBalance?.amount - +b1.availableBalance?.amount)
      }
      setBalance(balance)
    }
  }, [getCustodyBalance])

  return { balance, isLoadingBalance: loadingBalance }
}

export default useBalance
