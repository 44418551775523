import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Alert: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 14V22C9.5257 22.0029 7.13816 21.0884 5.29894 19.4333C3.45971 17.7781 2.29941 15.4999 2.04233 13.039C1.78526 10.5781 2.44966 8.10922 3.90711 6.1097C5.36455 4.11018 7.51154 2.72199 9.93304 2.21347C12.3545 1.70496 14.8786 2.11224 17.0172 3.35657C19.1559 4.60091 20.7573 6.59394 21.5119 8.95039C22.2664 11.3068 22.1206 13.8594 21.1025 16.1145C20.0845 18.3697 18.2665 20.1674 16 21.16"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 11C12.5523 11 13 10.5523 13 10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10C11 10.5523 11.4477 11 12 11Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Alert;
