import Icon from "components/atoms/Icon";
import { Text } from "components/atoms/Typography";
import { palette } from "lib/theme";
import React from "react";
import { getText } from "shared/locale-helper";
import { sharedKeySatus } from "./sharedKeyStatus";

interface ITwoFaModalHeader {
  sharedKey: string;
  currentStep: string;
  change2FaFlow: boolean;
  setCurrentStep: (step: string) => void;
  setShowTwoFAModal: (modal: boolean) => void;
}

export const TwoFaModalHeader = ({
  sharedKey,
  currentStep,
  change2FaFlow,
  setCurrentStep,
  setShowTwoFAModal,
}: ITwoFaModalHeader) => {
  return (
    <div
      style={{
        width: "-webkit-fill-available",
        position: "absolute",
        display: "flex",
        bottom: "88%",
        left: "1.6%",
      }}
    >
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          marginLeft:
            sharedKey !== sharedKeySatus.REJECTED && !change2FaFlow
              ? "32%"
              : "32%",
        }}
      >
        <Text size="large" letterSpacing="-0.5" bold>
          {sharedKey === sharedKeySatus.REJECTED
            ? "Change 2FA method"
            : change2FaFlow
            ? "Change 2FA method"
            : "Change 2FA method"}
        </Text>
      </div>
      <div
        style={{
          cursor: "pointer",
          left:
            sharedKey === sharedKeySatus.REJECTED || change2FaFlow
              ? "88.8%"
              : "89%",
          paddingTop: "8px",
          position: "absolute",
        }}
        onClick={() => setShowTwoFAModal(false)}
      >
        <Icon
          name="CrossModal"
          size={24}
          color={palette.darkBackgroundContrast.white}
        />
      </div>
    </div>
  );
};
