import { palette } from "lib/theme"
import styled from "styled-components"

export const Container = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  margin: 0;
  padding: 0;
  position: absolute;
  box-sizing: border-box;
  background-clip: border-box;
`

export const Options = styled.div`
  position: relative;
  width: 100%;
  max-height: 17.6875rem;
  background: #07041CCC;
  border-radius: 0px 0px 8px 8px;
  overflow-y: scroll;
  z-index: 1006;

  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.2);
`

export const OptionRow = styled.div<{ seleted: boolean }>`
  height: 3.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(100px);
  z-index: -1;

  &:hover {
    background: ${palette.darkBackgroundContrast.light5};
  }
`
