import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import useAssets from 'shared/useAssets';
import { TransactionYearMonth } from 'state/store/statementsApi';
import useCustodyStatement from './useCustodyStatement';
import InkindAssetRenderer from "../../../atoms/asset/InkindAssetRenderer";
import { Container, Table, TableContainer, TableRow, Th, Td, Title } from './sharedStyles';

const TransactionRow = ({ transaction }: { transaction: TransactionYearMonth }) => {
  const { getAssetByIdentifier } = useAssets();
  const asset = getAssetByIdentifier(transaction.amount.asset);

  return (
    <TableRow>
      <Td>{moment.utc(transaction.dateTime).format('YYYY-MM-DD')}</Td>
      <Td>
        <AssetCell>
          <div>{asset?.name}</div>
          <SubText>{transaction.networkName}</SubText>
        </AssetCell>
      </Td>
      <Td >{transaction.strategyName}</Td>
      <Td >{transaction.actionName}</Td>
      <Td>{transaction.transactionCategory}</Td>
      <Td>
        <InkindAssetRenderer balance={transaction.amount} />
      </Td>
      <Td>
        <InkindAssetRenderer balance={transaction.networkFee} />
      </Td>
      <Td>
        <CryptoAddressBox title={transaction.blockchainHash}>
          <CryptoAddress>{transaction.blockchainHash}</CryptoAddress>
        </CryptoAddressBox>
      </Td>
      <Td>{transaction.abraTxUID}</Td>
    </TableRow>
  );
};

const CustodyStatementTransaction = () => {
  const { month, year } = useParams();
  const { transactions } = useCustodyStatement({ year, month });

  return (
    <Container>
      <Title>Transaction Details</Title>
      <TableContainer>
        <Table>
          <thead>
            <TableRow>
              <Th>Date</Th>
              <Th>Asset</Th>
              <Th>Strategy</Th>
              <Th>Action</Th>
              <Th>Type</Th>
              <Th>Quantity</Th>
              <Th>Network Fee</Th>
              <Th>Blockchain Id</Th>
              <Th>Transaction Id</Th>
            </TableRow>
          </thead>
          <tbody>
            {transactions.data?.map((record, index) => (
              <TransactionRow key={index} transaction={record} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

const AssetCell = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubText = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
`;

const CryptoAddressBox = styled.div`
  cursor: pointer;
  max-width: 120px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CryptoAddress = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px; 
  @media print {
    font-size: 9px;
  }
`;

export default CustodyStatementTransaction;