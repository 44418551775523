import { createAsyncThunk, createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "state/store";
import {
  CreditCardFormFields,
  CreditCardApplyState,
  FinancialInformation,
  PersonalInformation,
} from "./creditCard.interface";

const creditApi = {
  applyForCreditCard: (formData: CreditCardFormFields): Promise<{ data: any }> =>
    Promise.resolve({ data: "test data" }),
  getUpdatesForCreditCard: (userId: string): Promise<{ data: any }> =>
    Promise.resolve({ data: "test data" }),
};

export const applyForCreditCard = createAsyncThunk(
  "creditCardApply/applyForCredit",
  async (formData: CreditCardFormFields) => {
    const response = await creditApi.applyForCreditCard(formData);
    return response.data;
  }
);

export const notifyOnUpdateCredit = createAsyncThunk(
  "creditCardApply/getUpdatesForCreditCard",
  async (userId: string) => {
    const response = await creditApi.getUpdatesForCreditCard(userId);
    return response.data;
  }
);

const getCreditCardApplyState = (state: RootState) => state.creditCardApply;
export const getIsTermsAccepted = createSelector(
  getCreditCardApplyState,
  (state) => state.acceptedTerms
);
export const getCreditCardApplyIsLoading = createSelector(
  getCreditCardApplyState,
  ({ isLoading }) => isLoading
);
export const getCreditCardApplyIError = createSelector(
  getCreditCardApplyState,
  ({ error }) => error
);
export const getCreditCardApplyResponse = createSelector(
  getCreditCardApplyState,
  ({ data }) => data
);
export const getApplyForCreditFields = createSelector(
  getCreditCardApplyState,
  ({ personalInformation, financialInformation, acceptedTerms }) => ({
    personalInformation,
    financialInformation,
    acceptedTerms,
  })
);

const initialState: CreditCardApplyState = {
  personalInformation: {
    fullName: "",
    phoneNumber: "",
    emailAddress: "",
    snn: "",
    address: {
      line1: "",
      city: "",
      postalCode: "",
      region: "",
      country: "",
    },
    mailingAddress: null,
  },
  financialInformation: {
    incomeType: "",
    totalAnnualIncome: "",
    employmentStatus: "",
    housingStatus: "",
    totalMonthlyHousingExpenses: "",
  },
  acceptedTerms: false,
  data: null,
  isLoading: false,
  error: null,
};

export const creditCardApply = createSlice({
  name: "creditCardApply",
  initialState,
  reducers: {
    updatePersonalInformation: (state, action: PayloadAction<PersonalInformation>) => {
      state.personalInformation = action.payload;
    },
    updateFinancialInformation: (state, action: PayloadAction<FinancialInformation>) => {
      state.financialInformation = action.payload;
    },
    updateAcceptingTerms: (state) => {
      state.acceptedTerms = !state.acceptedTerms;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(applyForCreditCard.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(applyForCreditCard.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.error = null;
    });
    builder.addCase(applyForCreditCard.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const { updatePersonalInformation, updateFinancialInformation, updateAcceptingTerms } =
  creditCardApply.actions;

export const creditCardApplyReducer = creditCardApply.reducer;
