import React, { useEffect, useState } from 'react';
import { Heading } from 'components/atoms/Typography';
import {
  StyledHeading,
  StyledFormWrapper,
  StyledForm,
  StyledAuthenticationContainer,
  StyleAuthenticationFormContainer,
  StyleAuthenticationCarouselContainer,
} from 'styles/components/authentication';
import Footer from 'components/molecules/Footer/Footer';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import onboardingApi from 'state/store/onboardingApi';
import Loading from 'components/atoms/Loading';
import { SpinnerSizeEnum } from 'components/atoms/Loading/Loading';

import HelloSign from 'hellosign-embedded';
import { ErrorCode } from 'constant/constant';
import { PagesUrl } from 'lib/constants/config.constant';

const client = new HelloSign();



const HelloSignScreen = () => {

  const navigate = useNavigate()
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') ?? '';
  const [signUrl, setSignUrl] = useState("")
  const registrationId = searchParams.get('registrationId') ?? '';
  const [helloSignMutation, meta] = onboardingApi.useStartHelloSignMutation();
  const [endHelloSign, metaEnd] = onboardingApi.useEndHelloSignMutation();
  const [loadingTimeout, setLoadingTimeout] = useState(true)

  const [clientId, setClientId] = useState("")

  const receivedSignature = (retry = 0) => {
    setLoadingTimeout(true)
    if(retry === 10)
      navigate(`${PagesUrl.AUTHENTICATION}/sign-in?onboarding=failed`)
    else {
      setTimeout( async () => {
        endHelloSign({ email, registrationId})
        .unwrap()
        .then(() => {
          setFinishedHelloSign(true);
          setLoadingTimeout(false);
        })
        .catch(() => receivedSignature(retry+1));
      }, 3000)
    }
  }

  const [finishedHelloSign, setFinishedHelloSign] = useState(false);
  useEffect(() => {
    if (signUrl && clientId) {
      client.open(signUrl, {
        clientId,
        requestingEmail: email,
        skipDomainVerification: true,
        allowCancel: false,
      });
      client.on('sign', () => {
        receivedSignature()
      })
    }
    return () => {
      client.isOpen && client.close() 
    }
  }, [signUrl, email, navigate, endHelloSign, registrationId, clientId])

  const s = searchParams.toString()
  useEffect(() => {
    if (finishedHelloSign) {
      navigate(`${PagesUrl.ONBOARDING}?`+s);
    }
  }, [finishedHelloSign, s, navigate])

  useEffect(() => {
    helloSignMutation({ email, registrationId })
      .unwrap()
      .then((data) => {
        const signUrl = new URL(data.signUrl);
        const clientId = signUrl.searchParams.get('client_id') ?? '';
        signUrl.searchParams.delete('client_id');

        setSignUrl(signUrl.toString());
        setClientId(clientId);
      })
      .catch((e) => {
        const errorCode = e.data?.code;
        if (errorCode === ErrorCode.ONBOARDING_ALREADY_SIGNED
            || errorCode === ErrorCode.ONBOARDING_INVALID_STATUS) {
          navigate(`${PagesUrl.ONBOARDING}?`+s);
        }
      });
  }, [email, registrationId, helloSignMutation, navigate, s]);

  return (
    <StyledAuthenticationContainer>
      <CarrouselContainer>
        <StyleAuthenticationCarouselContainer />
      </CarrouselContainer>
      <StyleAuthenticationFormContainer>
        <FormContainer>
          <StyledFormWrapper>
            <StyledHeading>
              <Heading variant='h2'>Sign your Advisory Agreement</Heading>
            </StyledHeading>
            <StyledForm>
              {!finishedHelloSign && (
                <>
                  {(meta.isLoading || metaEnd.isLoading || loadingTimeout) && <Loading showText={false} size={SpinnerSizeEnum.LARGE} />}
                </>
              )}
            </StyledForm>
          </StyledFormWrapper>
          <Footer />
        </FormContainer>
      </StyleAuthenticationFormContainer>
    </StyledAuthenticationContainer>
  );
};

const CarrouselContainer = styled.div`
  width: 50vw;
  @media screen and (max-width: 850px) {
    display: none;
  }
`;

const FormContainer = styled.div`
  display: flex;
  align-items: stretch;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2vh;
`;

export default HelloSignScreen;
