import React, { VFC, useState, useEffect } from "react"
import { Text } from "../../atoms/Typography"
import Button from "../../atoms/Button"
import Link from "../../atoms/Link"
import DigitVerification from "../../atoms/DigitVerification"
import {
  SizeNamesEnum,
  TypesNamesEnum,
} from "../../../@types/enums/Button.enum"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { PagesUrl } from "lib/constants/config.constant"
import SignInBlockedModal from "../SignIn/SignInBlockedModal"
import { useValidateMfaMutation } from "state/store/api"
import { selectTokenToValidate, setCredentials } from "state/slice/auth.slice"
import styled from 'styled-components'
import { ToastType, toast } from 'components/organisms/Toast'


const TurnOn2FA: VFC = ({ }) => {
  const [validateCode, { isLoading }] = useValidateMfaMutation()
  const tokenToValidate = useSelector(selectTokenToValidate)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )

  useEffect(() => {
    if (!tokenToValidate?.accessToken) {
      navigate(`${PagesUrl.AUTHENTICATION}${PagesUrl.SIGN_IN}`);
    }
  }, [tokenToValidate]);

  const [code, setCode] = useState([])

  const [visible, setVisible] = useState(false)

  const errorsDetails = {
    default: {
      icon: "TickCircleBig",
      title: "2FA has been activated",
      details:
        "You're all set. From now on, you'll use an authenticator app to sign in to your Abra wallet.",
      confirmText: "Done",
      onConfirm: () => navigate(`${PagesUrl.CUSTODY}`),
      onClose: () => navigate(`${PagesUrl.CUSTODY}`),
    },
  }

  const [errorModal, setErrorModal] = useState<
    | {
      icon: string
      title: string
      details: string
      confirmText: string
      onConfirm: () => void
      cancelText?: string
      onCancel?: () => void
      onClose?: () => void
    }
    | undefined
  >(errorsDetails.default)

  const handleOnSubmit = async () => {
    try {
      const data = (await validateCode({
        mfaAuthenticationRequest: {
          mfaToken: tokenToValidate?.accessToken,
          otpCode: code.join(""),
        },
      }).unwrap()) as { accessToken: string }

      dispatch(setCredentials({ accessToken: data.accessToken }))
      setVisible(true)
    } catch (error) { 
      toast.show({
        title: "Error",
        content: "Invalid otp code, please review and try again.",
        type: ToastType.Fail,
      })
      if(!tokenToValidate.otpIsActive){
        navigate(`${PagesUrl.AUTHENTICATION}${PagesUrl.ENABLE_2FA}`)
      }
    }
  }

  const handleOnChange = (event) => {
    setCode([...code, event?.target?.value])
  }

  const handleOnKeyDown = (event, indx) => {
    setErrorMessage(undefined)
    if (event.keyCode === 8) {
      if (code[indx]) {
        const updatedCureentValue = code.slice(0, indx)
        setCode(updatedCureentValue)
        return
      }
      const updatedValue = code.slice(0, indx - 1)
      setCode(updatedValue)
    }
  }

  const handleShowModalBlockSignIn = () => {
    setErrorModal(errorsDetails.blockSignIn)
    setVisible(true)
  }

  const handleBlockSignIn = () => { }

  return (
    <React.Fragment>
      <div style={{ marginBottom: "36px" }}>
        <DigitVerification
          code={code}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          errorMessage={errorMessage}
        />
      </div>
      <ButtonArea>
        {!tokenToValidate.otpIsActive && (
          <Button
            buttonType={TypesNamesEnum.SECONDAY}
            height='40'
            width='100%'
            onClick={async (e) => await navigate(-1)}
            disabled={isLoading}
          >
            Go Back
          </Button>
          )}
        <Button
          buttonType={TypesNamesEnum.ACCENT}
          height='40'
          onClick={handleOnSubmit}
          disabled={code?.length < 6}
          isLoading={isLoading}
          width='100%'
        >
          Confirm
        </Button>
      </ButtonArea>

      <SignInBlockedModal
        visible={visible}
        loading={false}
        onClose={() =>
          errorModal?.onClose ? errorModal.onClose() : setVisible(false)
        }
        {...errorModal}
      />
    </React.Fragment>
  )
}

const ButtonArea = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
  justify-content: stretch;
  width: 100%;
`
export default TurnOn2FA
