import styled from "styled-components";

import { palette } from "lib/theme";

const Panel = styled.div<{
  width?: string;
  height?: string;
  minHeight?: string;
  background?: string;
  padding?: string;
  margin?: string;
  hasBorder?: boolean;
}>`
  background: ${({ background }) =>
    background || palette.background.elevation1};
  width: ${(props) => props.width || ""};
  height: ${(props) => props.height || ""};
  min-height: ${(props) => props.minHeight || "99px"};
  padding: ${(props) => props.padding || "24px"};
  margin: ${(props) => props.margin || "10px 0"};
  overflow: hidden;
  border-radius: 8px;
  @media print {
    background: white !important;
  }
  ${({ hasBorder }) =>
    hasBorder && `border: 1px solid rgba(255, 255, 255, 0.1)`};
`;

export default Panel;
