import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Pending: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <g clipPath="url(#clip0_126_6996)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108 73.5V21C108 18.7909 106.209 17 104 17L21 17C18.7909 17 17 18.7909 17 21L17 123.5C17 125.709 18.7909 127.5 21 127.5L70 127.5V124.5L21 124.5C20.4477 124.5 20 124.052 20 123.5L20 21C20 20.4477 20.4477 20 21 20L104 20C104.552 20 105 20.4477 105 21V73.5H108Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 37C32 36.1716 32.6716 35.5 33.5 35.5H92.5C93.3284 35.5 94 36.1716 94 37C94 37.8284 93.3284 38.5 92.5 38.5H33.5C32.6716 38.5 32 37.8284 32 37Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 60C32 59.1716 32.6716 58.5 33.5 58.5H92.5C93.3284 58.5 94 59.1716 94 60C94 60.8284 93.3284 61.5 92.5 61.5H33.5C32.6716 61.5 32 60.8284 32 60Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 82.5C32 81.6716 32.5633 81 33.2581 81H82.7419C83.4367 81 84 81.6716 84 82.5C84 83.3284 83.4367 84 82.7419 84H33.2581C32.5633 84 32 83.3284 32 82.5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 106C32 105.172 32.6716 104.5 33.5 104.5H68.5C69.3284 104.5 70 105.172 70 106C70 106.828 69.3284 107.5 68.5 107.5H33.5C32.6716 107.5 32 106.828 32 106Z"
        fill={color}
      />
      <circle
        cx="106.5"
        cy="111"
        r="30"
        fill={color}
        stroke={color}
        strokeWidth="3"
      />
      <path
        d="M106 91.5V114L124.5 107"
        stroke="#13132B"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_126_6996">
        <rect
          width="121"
          height="125.5"
          fill={color}
          transform="translate(17 17)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Pending;
