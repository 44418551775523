import React from 'react';
import styled from 'styled-components';
import { AccountTransactionResponse } from 'state/store/reportApi';
import useAssets from 'shared/useAssets';
import { CustodyMainText, CustodySubText } from '../../CustodyStyles';
import { sharedStyles } from '../income/styles';
import { toast, ToastType } from 'components/organisms/Toast';
import palette from 'lib/theme/palette';

interface Props {
    transactions: AccountTransactionResponse[] | undefined
}

const CustodyExpensesTransactionDetails: React.FC<Props> = ({ transactions }) => {
    const assets = useAssets();
    return (
        <sharedStyles.Container>
            <Title>Transaction Details</Title>
            <sharedStyles.TableContainer>
                <sharedStyles.Table>
                    <thead>
                        <tr>
                            <sharedStyles.Th>Date</sharedStyles.Th>
                            <sharedStyles.Th>Asset</sharedStyles.Th>
                            <sharedStyles.Th>Strategy</sharedStyles.Th>
                            <sharedStyles.Th>Action</sharedStyles.Th>
                            <sharedStyles.Th>Type</sharedStyles.Th>
                            <sharedStyles.Th>Network Fee</sharedStyles.Th>
                            <sharedStyles.Th>Transaction Id</sharedStyles.Th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactions?.map((row, index) => (
                            <sharedStyles.TableRow key={index}>
                                <sharedStyles.Td>
                                    {new Date(row.dateTime).toLocaleDateString()}
                                </sharedStyles.Td>
                                <sharedStyles.Td>
                                    <AssetCell>
                                        <AssetName>
                                            {assets.getAssetByIdentifier(row.amount.asset)?.name}
                                        </AssetName>
                                        <CustodySubText>{row.networkName}</CustodySubText>
                                    </AssetCell>
                                </sharedStyles.Td>
                                <sharedStyles.Td>{row.strategyName}</sharedStyles.Td>
                                <sharedStyles.Td>{row.actionName}</sharedStyles.Td>
                                <sharedStyles.Td>{row.transactionCategory}</sharedStyles.Td>
                                <sharedStyles.Td>
                                    <QuantityCell>
                                        {assets.getPriceFormattedI(row.amount.asset, row.amount.amount)}
                                    </QuantityCell>
                                </sharedStyles.Td>
                                <sharedStyles.Td>
                                    {row.abraTxUID}
                                </sharedStyles.Td>
                            </sharedStyles.TableRow>
                        ))}
                    </tbody>
                </sharedStyles.Table>
            </sharedStyles.TableContainer>
        </sharedStyles.Container>
    );
};


const Title = styled.h2`
    color: white;
    font-size: 20px;
    margin-bottom: 8px;
`;

const QuantityCell = styled.div`
    display: flex;
    flex-direction: column;
`;

const AssetCell = styled.div`
    display: flex;
    flex-direction: column;
`;

const AssetName = styled.div`
    color: white;
`;


export default CustodyExpensesTransactionDetails;
