import React, { FC } from "react";
import ISVG from "@types/icons/svg";

export const Sell: FC<ISVG> = ({
  size = 24,
  width = size,
  height = size,
  color,
  svgProps,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...svgProps}
  >
    <path
      d="M11.9999 17.999C12.5519 17.999 12.9999 17.552 12.9999 16.999V4.07999L16.3749 6.78101C16.5599 6.92801 16.7799 7 16.9989 7C17.2929 7 17.5829 6.871 17.7809 6.625C18.1259 6.193 18.0559 5.56399 17.6249 5.21899L12.6249 1.21899C12.5789 1.18299 12.5259 1.16499 12.4769 1.13699C12.4369 1.11499 12.4009 1.08899 12.3579 1.07199C12.2469 1.02899 12.1309 1.00399 12.0139 1.00299C12.0089 1.00299 12.0049 1 11.9999 1C11.9949 1 11.9909 1.00299 11.9859 1.00299C11.8689 1.00399 11.7529 1.02999 11.6419 1.07199C11.5989 1.08899 11.5629 1.11499 11.5229 1.13699C11.4739 1.16399 11.4199 1.18199 11.3749 1.21899L6.37491 5.21899C5.94391 5.56499 5.8739 6.194 6.2189 6.625C6.5639 7.056 7.19291 7.12701 7.62491 6.78101L10.9999 4.07999V16.999C10.9999 17.552 11.4479 17.999 11.9999 17.999Z"
      fill={color}
    />
    <path
      d="M22 12.999H15C14.448 12.999 14 13.446 14 13.999C14 14.552 14.448 14.999 15 14.999H22C22.552 14.999 23 14.552 23 13.999C23 13.446 22.552 12.999 22 12.999Z"
      fill={color}
    />
    <path
      d="M4 19.999C4 20.552 4.448 20.999 5 20.999H22C22.552 20.999 23 20.552 23 19.999C23 19.446 22.552 18.999 22 18.999H5C4.448 18.999 4 19.446 4 19.999Z"
      fill={color}
    />
    <path
      d="M1 22.792C1 23.345 1.448 23.792 2 23.792C2.552 23.792 3 23.345 3 22.792V16.999C3 15.896 3.897 14.999 5 14.999H9C9.552 14.999 10 14.552 10 13.999C10 13.446 9.552 12.999 9 12.999H5C2.794 12.999 1 14.793 1 16.999V22.792Z"
      fill={color}
    />
  </svg>
);

export default Sell;
